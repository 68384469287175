import { PRODUCTS_ALLOWED } from 'constants/restrictions'

const formatProductsDefaultValues = categories => {
  const productDefaultValues = categories.map(item => ({
    [item.categoryId]: {
      categoryId: item.categoryId,
      applyForAllProductsInCategory: item.applyForAllProductsInCategory,
      isAllow: item.isAllow ? String(item.isAllow) : PRODUCTS_ALLOWED.ALLOW,
      ...(item.applyForAllProductsInCategory && {
        dailyMaxValue: item.categoryDetails.categoryLimits.daily?.dailyMaxValue || '',
        dailyMaxVolume: item.categoryDetails.categoryLimits.daily?.dailyMaxVolume || '',
        txnMaxValue: item.categoryDetails.categoryLimits.transaction?.txnMaxValue || '',
        txnMaxVolume: item.categoryDetails.categoryLimits.transaction?.txnMaxVolume || ''
      }),
      products: !item.applyForAllProductsInCategory
        ? Object.assign(
            {},
            ...item.categoryDetails.products.map(product => ({
              [product.productServiceId]: {
                productId: product.productServiceId,
                setRestriction:
                  !!product.productDetails.daily?.dailyMaxValue ||
                  !!product.productDetails.daily?.dailyMaxVolume ||
                  !!product.productDetails.transaction?.txnMaxValue ||
                  !!product.productDetails.transaction?.txnMaxVolume ||
                  !!product.isAllow,
                isAllow: product.isAllow ? PRODUCTS_ALLOWED.ALLOW : PRODUCTS_ALLOWED.NOT_ALLOW,
                dailyMaxValue: product.productDetails.daily?.dailyMaxValue || '',
                dailyMaxVolume: product.productDetails.daily?.dailyMaxVolume || '',
                txnMaxValue: product.productDetails.transaction?.txnMaxValue || '',
                txnMaxVolume: product.productDetails.transaction?.txnMaxVolume || ''
              }
            }))
          )
        : {}
    }
  }))

  return productDefaultValues
}

export default formatProductsDefaultValues
