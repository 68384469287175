import useContent from 'hooks/useContent'

const EmptyRows = () => {
  const content = useContent('table')

  return (
    <div style={{ justifyContent: 'center', display: 'flex', height: 'calc(100% - 40px)', alignItems: 'center' }}>
      {content?.no_results ?? 'No results found'}
    </div>
  )
}

export default EmptyRows
