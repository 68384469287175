import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Button } from '@bp-digital/component-button'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { queryClient } from 'src'
import { useRootStore } from 'contexts/StoreContext'

const root = document.getElementById('root')

const DeleteRestrictionProfile = ({
  content = {},
  profileId,
  profileName,
  profileReadOnly,
  authorityId,
  serviceCode
}) => {
  const { cardsRestrictionsStore } = useRootStore()
  const history = useHistory()
  const [confirmationText, setConfirmationText] = useState(
    content.restriction_profiles_update_message || 'restriction_profiles_update_message'
  )
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  // for content changes / page refresh
  useEffect(() => {
    setConfirmationText(content.restriction_profiles_update_message || 'restriction_profiles_update_message')
  }, [content])

  const handleDeleteProfile = async () => {
    setIsDeleting(true)

    try {
      const params = {
        accessLevel: 'A',
        accessLevelCode: authorityId
      }

      await axios({
        method: 'put',
        url: '/api/cards/restrictions/profile',
        data: {
          cardRestrictionDetails: [
            {
              cardId: null,
              isUnlink: false,
              isDelete: true,
              isProfileNameChanged: false,
              profileId: +profileId,
              profileName,
              parentId: null,
              groupId: null,
              authorityId,
              userLevel: 'A',
              accessId: `A${authorityId}`,
              authorityServiceCode: +serviceCode,
              parentServiceCode: null
            }
          ]
        },
        params
      })

      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      cardsRestrictionsStore.reset()

      history.push(ROUTE_CARDS_RESTRICTIONS, {
        globalNotification: {
          data: {
            id: Math.floor(Math.random() * 9999),
            title: profileName,
            content:
              content?.restriction_profiles_delete_notification_success_singular ||
              'restriction_profiles_delete_notification_success_singular',
            position: 'right-bottom',
            autoClose: 5000,
            hasCloseButton: false,
            icon: 'CheckLargeSolid'
          }
        }
      })
    } catch (e) {
      setIsDeleting(false)
      setConfirmationText(
        content.restriction_profiles_delete_notification_error_singular ||
          'restriction_profiles_delete_notification_error_singular'
      )
      console.error(e)
    }
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        dataAttributes={{ 'data-content-key': 'restriction_profiles_delete_profile' }}
        disabled={profileReadOnly}
        size='lg'
        iconName='RemoveLarge'
      >
        {content?.restriction_profiles_delete_profile}
      </Button>

      {ReactDOM.createPortal(
        <AlertModal
          visible={isOpen}
          size='md'
          state='warning'
          title={content.restriction_profiles_delete_message_singular || 'restriction_profiles_delete_message_singular'}
          text={confirmationText}
          onDismiss={() => setIsOpen(false)}
          primaryAction={{
            text: content.restriction_removal_confirm || 'Confirm',
            onClick: handleDeleteProfile,
            isLoading: isDeleting
          }}
          secondaryAction={{
            text: content.restriction_removal_cancel || 'Cancel',
            appearance: 'secondary',
            onClick: () => setIsOpen(false)
          }}
        />,
        root
      )}
    </>
  )
}

export default DeleteRestrictionProfile
