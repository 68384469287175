import { ArchivedInvoice, SummaryStatement } from 'hooks/api/useApiGetArchivedInvoices'

const mapToDownloadFormat = (invoice: ArchivedInvoice | SummaryStatement) => {
  const date = invoice.invoiceDate.split('T')[0]

  const type = invoice.invoiceType?.toLowerCase().includes('summary-statement')
    ? 'SUMMARY-STATEMENT'
    : invoice.invoiceType
  const countryData = [
    { name: 'Austria', iso: 'AT' },
    { name: 'Belgium', iso: 'BE' },
    { name: 'Poland', iso: 'PL' },
    { name: 'The Netherlands', iso: 'NL' },
    { name: 'United Kingdom', iso: 'GB' },
    { name: 'Germany', iso: 'DE' },
    { name: 'Portugal', iso: 'PT' },
    { name: 'Luxembourg', iso: 'LU' }
  ]
  const countryNameToIso = (countryName: string) => countryData.find(country => country.name == countryName)?.iso

  const clientNr = invoice.clientNr || ''
  return {
    documentNumber: +invoice.invoiceNumber,
    clientISOCode: invoice.issuerCountry.length == 2 ? invoice.issuerCountry : countryNameToIso(invoice.issuerCountry),
    invoiceType: type,
    clientNumber: +clientNr,
    invoiceDate: date
  }
}

export default mapToDownloadFormat
