import { Heading, Text } from '@bp-digital/component-typography'
import buildProfileDetails from './helpers/buildProfileDetails'
import { TextField } from '@bp-digital/component-text-field'
import { Button } from '@bp-digital/component-button'
import { ButtonWrapper, Wrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import AllRestrictionDetails from 'pages/Cards/Restrictions/components/AllRestrictionDetails'
import { Spacer } from 'styles/common.styled'
import { StateMessage } from '@bp-digital/component-state-message'

const Summary = ({
  data,
  content,
  onBack,
  onSubmit,
  isSubmitting,
  profileName,
  setProfileName,
  restrictionTypes,
  currency,
  error
}) => {
  const profileDetails = buildProfileDetails({ data, restrictionTypes })

  return (
    <Wrapper>
      <Heading as='h3'>{content?.summary_title}</Heading>
      <Text size='lg'>{content?.restriction_title}</Text>
      <AllRestrictionDetails profileDetails={profileDetails} currency={currency} content={content} />
      <Spacer />
      <TextField
        required
        label={content.restriction_profile_name_label || '...'}
        defaultValue={profileName}
        textChangeHandler={value => {
          setProfileName(value)
        }}
        dataAttributes={{
          'data-testid': 'input-profile-name',
          'data-content-key': 'restriction_profile_name_label'
        }}
      />
      {error && (
        <>
          <Spacer />
          <StateMessage iconName='Alert' state='danger' text={error} />
        </>
      )}
      <ButtonWrapper>
        <Button
          onClick={onBack}
          disabled={isSubmitting}
          appearance='tertiary'
          dataAttributes={{ 'data-content-key': 'previous_step' }}
        >
          {content?.previous_step}
        </Button>
        <Button
          onClick={onSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting || profileName === ''}
          dataAttributes={{ 'data-content-key': 'create_profile' }}
        >
          {content?.create_profile}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default Summary
