import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  @media only screen and ${breakpoints.device.sm} {
    padding-top: 0;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 75%;
    flex-wrap: wrap;
  }
`