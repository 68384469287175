import { BrowserRouter as Router } from 'react-router-dom'
import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { ROUTE_CARDS_VIEW } from 'constants/routes'

const AuthorityStopped = ({ content }) => {
  return (
    <div className='container'>
      <Heading as='h2' data-content-key='authority_stopped_heading'>
        {content?.authority_stopped_heading || 'authority_stopped_heading'}
      </Heading>

      <Text as='p'>{content?.authority_stopped_warning_message || 'authority_stopped_warning_message'}</Text>

      <div className='button-wrapper'>
        <Router>
          <Button appearance='primary' to={ROUTE_CARDS_VIEW} data-content-key='authority_stopped_manage_card'>
            {content?.authority_stopped_manage_card || 'authority_stopped_manage_card'}
          </Button>
        </Router>
      </div>
    </div>
  )
}

export default AuthorityStopped
