import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { Text } from '@bp-digital/component-typography'
import { DropDown } from '@bp-digital/component-drop-down'
import { Modal } from '@bp-digital/component-modal'
import { StateMessage } from '@bp-digital/component-state-message'
import AllRestrictionDetails from 'pages/Cards/Restrictions/components/AllRestrictionDetails'
import useApiGetHierarchyProfileDetails from 'hooks/api/useApiGetHierarchyProfileDetails'
import useApiGetRestrictionBaseProfiles from 'hooks/api/useApiGetRestrictionBaseProfiles'
import useApiGetRestrictionProfile from 'hooks/api/useApiGetRestrictionProfile'
import { SelectionWrapper, SelectionMessage, HelpMessage } from './SetBaseProfile.styled'
import { Icon } from '@bp-digital/component-icon'
import { Flowchart } from '../Flowchart'

const SetBaseProfile = ({ content = {}, authorityId, onClose }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [flowchartVisible, setFlowchartVisiblity] = useState(false)
  const [error, setError] = useState(null)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const queryClient = useQueryClient()
  const { data: currentDetails } = useApiGetHierarchyProfileDetails(authorityId)
  const { data: baseProfiles } = useApiGetRestrictionBaseProfiles(
    {
      authorityId,
      serviceCode: [currentDetails?.serviceCode]
    },
    {
      enabled: !!authorityId && !!currentDetails?.serviceCode
    }
  )

  const { data: profileDetails, isLoading: isGettingProfile } = useApiGetRestrictionProfile(selectedProfile, {
    enabled: !!selectedProfile,
    onError: () => {
      setError('Failed to get profile')
    }
  })

  const handleUpdate = async () => {
    setIsUpdating(true)

    try {
      const params = {
        accessLevel: 'A',
        accessLevelCode: authorityId
      }

      await axios({
        method: 'put',
        url: '/api/cards/restrictions/profile',
        data: {
          cardRestrictionDetails: [
            {
              authorityId,
              cardId: null,
              isUnlink: false,
              isDelete: false,
              isProfileNameChanged: false,
              profileId: +selectedProfile,
              profileName: baseProfiles.find(({ profileId }) => profileId === selectedProfile)?.profileName,
              authorityServiceCode: currentDetails.serviceCode,
              parentId: '',
              parentServiceCode: 0
            }
          ]
        },
        params
      })

      queryClient.invalidateQueries('restrictions/authority-profile-details')
      setIsUpdating(false)
      onClose()
    } catch (e) {
      console.warn('Error updating base profile', e)
      setIsUpdating(false)
      setError(content.restriction_base_profiles_update_notification_failure_singular)
    }
  }

  useEffect(() => {
    if (currentDetails?.baseProfileId && baseProfiles) {
      setSelectedProfile(currentDetails?.baseProfileId)
    }
  }, [currentDetails?.baseProfileId, baseProfiles])

  return ReactDOM.createPortal(
    <Modal
      visible
      title={content.restriction_profiles_set_base_profiles || 'Set base profiles'}
      onDismiss={onClose}
      size='lg'
      primaryAction={{
        text: content.set_base_profile_apply || 'Apply',
        isLoading: isUpdating,
        disabled: isUpdating,
        onClick: handleUpdate,
        dataAttributes: {
          'data-testid': 'button-update-base-profile',
          'data-content-key': 'set_base_profile_apply'
        }
      }}
      secondaryAction={{
        text: content.set_base_profile_cancel || 'Cancel',
        onClick: onClose,
        appearance: 'tertiary',
        dataAttributes: {
          'data-testid': 'button-cancel-base-profile-update',
          'data-content-key': 'set_base_profile_cancel'
        }
      }}
    >
      {!baseProfiles && <Skeleton width='100%' height={60} />}
      {baseProfiles && (
        <>
          <HelpMessage size='md' onClick={() => setFlowchartVisiblity(toggle => !toggle)}>
            <div data-content-key='flowChart_title'>{content.flowChart_title}</div>
            <Icon name='Info' appearance='medium' size='md' state='neutral' />
          </HelpMessage>
          {flowchartVisible && <Flowchart />}
          <SelectionWrapper>
            <DropDown
              label={content.restriction_profiles_account_profiles}
              placeholder='Select an option...'
              options={baseProfiles.map(profile => ({
                id: profile.profileId,
                label: profile.profileName
              }))}
              onChange={newId => {
                setError(null)
                setSelectedProfile(newId)
              }}
              defaultSelectedId={currentDetails?.baseProfileId}
            />
          </SelectionWrapper>

          {isGettingProfile ? (
            <>
              <Skeleton width='100%' height={60} style={{ margin: '16px 0 8px 0' }} />
              <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
              <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
              <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
              <Skeleton width='100%' height={60} style={{ margin: '8px 0 16px 0' }} />
            </>
          ) : profileDetails ? (
            <AllRestrictionDetails profileDetails={profileDetails.restrictiondetails} content={content} />
          ) : (
            <SelectionMessage>
              <Text>{content.restriction_profiles_account_profiles}</Text>
            </SelectionMessage>
          )}
        </>
      )}
      {error && <StateMessage state='danger' iconName='Alert' text={error} />}
    </Modal>,
    document.getElementById('root')
  )
}

export default SetBaseProfile
