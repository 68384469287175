import styled from 'styled-components'

export const SummaryTitle = styled.div`
  width: 100%;
  display: flex;
  height: 65px;
  align-items: center;
  padding: 16px;
  justify-content: center;
  padding-left: 8px;
  border-bottom: var(--structure-stroke-weight-normal) solid var(--palette-state-neutral);
`

export const SummaryActions = styled.div`
  display: flex;
  gap: 8px;
`

export const LoadingSkeletonContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 10px;
`
