import Cookies from 'js-cookie'
import { FUNCTIONAL_COOKIE, PERFORMANCE_COOKIE } from 'constants/cookieConsentTypes'
import getConsentCookies from 'helpers/getConsentCookies'

const setConsentCookies = (setPerfomanceCookies = true) => {
  const { functionalCookies, performanceCookies } = getConsentCookies()
  const setPerformanceCookie = !performanceCookies && setPerfomanceCookies
  const removePerformanceCookie = performanceCookies && !setPerfomanceCookies

  if (!functionalCookies) Cookies.set(FUNCTIONAL_COOKIE, 'true', { expires: 365, secure: true })
  if (setPerformanceCookie) Cookies.set(PERFORMANCE_COOKIE, 'true', { expires: 365, secure: true })
  if (removePerformanceCookie) Cookies.remove(PERFORMANCE_COOKIE)
}

export default setConsentCookies
