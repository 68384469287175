import { ReactElement, useEffect, useState, useRef } from 'react'
import { useOnClickOutside, useMatchMedia } from '@bp-digital/hooks'

import breakpoints from 'src/styles/breakpoints'
import UserMenuItem from './UserMenuItem'
import { UserMenuItemProps } from './UserMenu.types'
import {
  UserMenuContainer,
  UserMenuToggle,
  UserMenuProfileImage,
  UserMenuLinksContainer,
  UserMenuName
} from './UserMenu.style'
import defaultProfileImg from 'assets/images/profile/default_profile_200_v2.png'

export interface UserMenuProps {
  title: string
  name?: string
  image?: string
  items: UserMenuItemProps[]
  onOpen?: () => void
  onClose?: () => void
}

const UserMenu = ({ title, name, image = '', items, onClose, onOpen }: UserMenuProps): ReactElement => {
  const ref = useRef(null)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const isMobile = useMatchMedia(`(max-width: ${breakpoints.size.lg})`)

  useOnClickOutside(ref, () => setIsMenuVisible(false))

  useEffect(() => {
    if (isMenuVisible) {
      onOpen && onOpen()
    } else {
      onClose && onClose()
    }
  }, [isMenuVisible, onOpen, onClose])

  return (
    <UserMenuContainer data-testid='user-menu' ref={ref}>
      <UserMenuToggle $image={image} onClick={() => setIsMenuVisible(!isMenuVisible)} aria-label='user-menu'>
        {name && !isMobile && <UserMenuName>{name}</UserMenuName>}
        <UserMenuProfileImage alt={title} title={title} src={image || defaultProfileImg} />
      </UserMenuToggle>
      {isMenuVisible && (
        <UserMenuLinksContainer onClick={() => setIsMenuVisible(false)} aria-label='user-menu-links'>
          {items.map((item, index) => (
            <UserMenuItem key={`user-menu-item-${index}`} {...item} />
          ))}
        </UserMenuLinksContainer>
      )}
    </UserMenuContainer>
  )
}

export default UserMenu
