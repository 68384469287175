export const AUDIT_DOMAINS = [
  {
    domain: 'card',
    label: 'Card',
    permissionKey: 'ReadCard'
  },
  {
    domain: 'login',
    label: 'Login',
    permissionKey: 'SiteAccess'
  },
  {
    domain: 'user',
    label: 'User',
    permissionKey: 'SiteAccess' // note - cannot use ReadUser as relies on last selected authority permission (ReadUser missing from Evis perms)
  }
]

export const AUDIT_ACTION_TYPES = [
  {
    domain: 'card',
    key: 'NCRQ',
    label: 'New Card Request'
  },
  {
    domain: 'card',
    key: 'NCRP',
    label: 'New Card Response'
  },
  {
    domain: 'card',
    key: 'RCRQ',
    label: 'Replace Card Request'
  },
  {
    domain: 'card',
    key: 'RCRP',
    label: 'Replace Card Response'
  },
  {
    domain: 'card',
    key: 'SCRQ',
    label: 'Stop Card Request'
  },
  {
    domain: 'card',
    key: 'SCRP',
    label: 'Stop Card Response'
  },
  {
    domain: 'login',
    key: 'USML',
    label: 'Main Login'
  },
  {
    domain: 'login',
    key: 'USHS',
    label: 'Hierarchy Selection'
  },
  {
    domain: 'user',
    key: 'NUSR',
    label: 'User Invitation'
  }
]
