import { CssVars } from '@bp-digital/theme'
import { ReactNode } from 'react'
import { MapFlyoutContainer } from './MapFlyout.styled'

interface Props {
  color?: string
  children: ReactNode
}

const MapFlyout = ({ color = CssVars.palette.neutral.default, children }: Props) => {
  return <MapFlyoutContainer $color={color}>{children}</MapFlyoutContainer>
}

export default MapFlyout
