import { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import CardPreview from 'components/cards/CardPreview'
import { getCardImage } from 'components/cards/helpers/'
import {
  CARD_DRIVER_PIN,
  CARD_DRIVER_PIN_TYPE,
  CARD_CUSTOMER_PIN,
  CARD_CUSTOMER_WIDE_PIN,
  CARD_OFFER_TYPES,
  CARD_STATUS,
  CARD_STATUS_ID
} from 'constants/cards'
import useApiGetCardTransactions from 'hooks/api/useApiGetCardTransactions'
import useApiGetCardDetails from 'hooks/api/useApiGetCardDetails'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'
import FullCardDetail from '../FullCardDetail'
import EditCardDetail from '../EditCardDetail'
import {
  CardDetailWrapper,
  CardDetailsGrid,
  CardDetails,
  CardDetailsHeader,
  CardDetailsActions,
  Label,
  Value,
  TransactionMapContainer
} from './ViewCardDetail.styled'
import { ROUTE_TRANSACTIONS } from 'constants/routes'
import CardStatusHistory from '../CardStatusHistory'
import StopCard from '../StopCard'
import EditCardPin from '../EditCardPin'
import EditCardRestrictions from '../EditCardRestrictions'
import ReissueCard from 'pages/Cards/CardsForReissuePage/components/ReissueCard'
import TransactionMap from '../TransactionMap/TransactionMap'

const MODALS = {
  StopCard: 'StopCard',
  EditPin: 'EditPin',
  Reissue: 'Reissue',
  EditRestrictions: 'EditRestrictions',
  FullDetail: 'FullDetail',
  EditDetail: 'EditDetail',
  StatusHistory: 'StatusHistory'
}

const getCardTitle = ({ displayCardProgramName, networkProgramName } = {}) => {
  if (displayCardProgramName && networkProgramName) {
    return `${displayCardProgramName} (${networkProgramName})`
  } else if (displayCardProgramName) {
    return displayCardProgramName
  }

  return ''
}

const ViewCardDetail = ({
  authorityId,
  authorityStop,
  content = {},
  cardId,
  cardNumber,
  cardStatus,
  cardStatusText,
  cardStatusId,
  cardStatusIdNumber, // temp workaround as cardStatusId is React comp
  cardSerialNumber,
  pinMethod,
  pinReq,
  profileId,
  currentCardStatusId,
  cardProgram,
  cardEmbossedName1,
  cardEmbossedName3,
  cardEmbossName2,
  cardHolderName,
  replaceCardOrderedOn,
  countryCode,
  expiryDate = '',
  serviceCode,
  lastTransactionDate,
  internationalInd,
  setToastData,
  hasReissue = false,
  isPinAllowedToEdit = true,
  showEditRestrictionsButton
}) => {
  const refCardDetailWrapper = useRef(null)

  useEffect(() => {
    refCardDetailWrapper?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }, [])

  const [activeModal, setActiveModal] = useState(null)
  //const { data, isLoading, error } = useApiGetCardTransactions(authorityId, cardId)

  const { data, isLoading } = useApiGetCardTransactions(authorityId, cardId)
  const { data: authorityDetails } = useApiGetAuthorityDetails(authorityId, {
    enabled: !!authorityId
  })
  const {
    data: cardDetails,
    isSuccess: cardDetailsSuccess,
    isLoading: cardDetailsLoading,
    error: cardDetailsError
  } = useApiGetCardDetails(authorityId, cardNumber, {
    enabled: !!authorityId && !!cardNumber
  })
  const hasDisabledActions =
    authorityStop === 'Y' ||
    cardStatus === CARD_STATUS.LOST ||
    cardStatus === CARD_STATUS.DESTROYED ||
    cardStatus === CARD_STATUS.STOLEN
  const internationalContent = content?.advancedFilters_international_ind_Y
  const nationalContent = content?.advancedFilters_international_ind_N
  const internationalUse = internationalInd === 'Y' ? internationalContent : nationalContent
  const cardOfferMap = {
    'BP KOMFORT': CARD_OFFER_TYPES.KOMFORT,
    'BP PULSE': CARD_OFFER_TYPES.PULSE
  }
  const displayCardProgramName = data?.cardImage?.displayCardProgramName
  const isNotForReissue = cardStatusIdNumber === CARD_STATUS_ID.NOT_FOR_REISSUE
  const isRecentlyUnused = cardStatusIdNumber === CARD_STATUS_ID.UNUSED_RECENTLY
  const cardImage = getCardImage({
    cardProgramNumber: cardProgram?.cardProgramNumber,
    countryCode: countryCode?.trim() || authorityDetails.countryCode,
    plasticTypes: authorityDetails?.plasticTypes || [],
    cardOfferType:
      authorityDetails?.cardOfferType || cardOfferMap[displayCardProgramName] || CARD_OFFER_TYPES.NON_KOMFORT
  })

  const isEditPinDisabled =
    hasDisabledActions ||
    (pinMethod === CARD_DRIVER_PIN && pinReq === CARD_DRIVER_PIN_TYPE) ||
    pinMethod === CARD_CUSTOMER_PIN ||
    pinMethod === CARD_CUSTOMER_WIDE_PIN ||
    !isPinAllowedToEdit

  return (
    <CardDetailWrapper ref={refCardDetailWrapper}>
      {activeModal === MODALS.FullDetail && (
        <FullCardDetail
          cardDetails={cardDetails}
          isLoading={cardDetailsLoading}
          error={cardDetailsError}
          cardNumber={cardNumber}
          cardStatus={cardStatusText}
          lastUsed={lastTransactionDate}
          internationalUse={internationalUse}
          content={content}
          onEditDetail={() => setActiveModal(MODALS.EditDetail)}
          onClose={() => setActiveModal('')}
        />
      )}
      {activeModal === MODALS.EditDetail && (
        <EditCardDetail
          cardDetails={cardDetails}
          isSuccess={cardDetailsSuccess}
          isLoading={cardDetailsLoading}
          embossingLines={[cardEmbossedName1, cardEmbossName2, cardEmbossedName3]}
          authorityId={authorityId}
          cardId={cardId}
          cardNumber={cardNumber}
          authorityDetails={authorityDetails}
          content={content}
          onBack={() => setActiveModal(MODALS.FullDetail)}
        />
      )}
      {activeModal === MODALS.StatusHistory && (
        <CardStatusHistory
          cardId={cardId}
          cardSerialNumber={cardSerialNumber}
          content={content}
          onClose={() => setActiveModal('')}
        />
      )}
      {activeModal === MODALS.StopCard && (
        <StopCard
          cardId={cardId}
          cardDetails={cardDetails}
          isSuccess={cardDetailsSuccess}
          isLoading={cardDetailsLoading}
          countryCode={countryCode}
          cardStatusId={currentCardStatusId}
          authorityId={authorityId}
          content={content}
          setToastData={setToastData}
          onClose={() => setActiveModal('')}
        />
      )}
      {activeModal === MODALS.EditPin && (
        <EditCardPin
          authorityId={authorityId}
          cardId={cardId}
          content={content}
          onClose={() => setActiveModal('')}
          pinMethod={pinMethod}
          pinReq={pinReq}
          setToastData={setToastData}
        />
      )}
      {activeModal === MODALS.Reissue && (
        <ReissueCard
          cardId={cardId}
          cardStatus={cardStatusText}
          cardStatusId={cardStatusIdNumber}
          countryCode={countryCode}
          content={content}
          isRecentlyUnused={isRecentlyUnused}
          onClose={() => setActiveModal('')}
          setToastData={setToastData}
        />
      )}
      {activeModal === MODALS.EditRestrictions && (
        <EditCardRestrictions
          content={content}
          cardSerialNumber={cardSerialNumber}
          onClose={() => setActiveModal('')}
          cardId={cardId}
          currentProfileId={profileId ? Number(profileId) : profileId}
          authorityId={authorityId}
          serviceCode={serviceCode}
          setToastData={setToastData}
        />
      )}
      <CardDetailsHeader>
        <Text size='lg'>{getCardTitle(data?.cardImage)}</Text>
        <div style={{ display: 'flex', gap: '8px' }}>
          {hasReissue && (
            <Button
              disabled={isNotForReissue || hasDisabledActions}
              iconName='SwapPositions'
              onClick={() => setActiveModal(MODALS.Reissue)}
              dataAttributes={{ 'data-testid': 'button-card-reissue' }}
            >
              {isNotForReissue || isRecentlyUnused ? content?.manage_cards_reissue : content?.manage_cards_dont_reissue}
            </Button>
          )}
          <Button
            disabled={hasDisabledActions}
            iconName='RemoveLarge'
            onClick={() => setActiveModal(MODALS.StopCard)}
            dataAttributes={{ 'data-testid': 'button-card-stop' }}
          >
            {content?.manage_cards_stop_card}
          </Button>
          <Button
            disabled={isEditPinDisabled}
            iconName='Edit'
            onClick={() => setActiveModal(MODALS.EditPin)}
            dataAttributes={{ 'data-testid': 'button-card-edit-pin' }}
          >
            {content?.manage_cards_edit_pin}
          </Button>
          <Button
            disabled={hasDisabledActions || showEditRestrictionsButton == 'N'}
            iconName='Breadsticks'
            onClick={() => setActiveModal(MODALS.EditRestrictions)}
            dataAttributes={{ 'data-testid': 'button-card-edit-restrictions' }}
          >
            {content?.manage_cards_edit_restrictions}
          </Button>
        </div>
      </CardDetailsHeader>
      <CardDetailsGrid>
        <CardDetails>
          <Label>{content?.card_summary_details_status}</Label>
          <Value>{content[`advancedFilters_cardStatusId_${currentCardStatusId}`] || '-'}</Value>
          <Label>{content?.card_summery_details_last_settled_transaction}</Label>
          <Value>{lastTransactionDate || '-'}</Value>
          <Label>{content?.card_summary_details_last_used}</Label>
          <Value>{lastTransactionDate || '-'}</Value>
        </CardDetails>
        <span></span>
        <CardDetails>
          <CardPreview
            image={cardImage?.default}
            cardNumber={cardNumber}
            cardStatusId={cardStatusId}
            statusStickerText={content[`advancedFilters_cardStatusId_${currentCardStatusId}`] || cardStatus}
            stopped={hasDisabledActions}
            expiryMonth={expiryDate ? expiryDate.split('/')[0] : ''}
            expiryYear={expiryDate ? expiryDate.split('/')[1] : ''}
            embossingLines={[cardEmbossedName1, cardEmbossName2, cardEmbossedName3]}
          />
        </CardDetails>
        {replaceCardOrderedOn && (
          <CardDetails $align='left'>
            <Text size='lg'>
              {content?.card_summary_replace_card_ordered} {replaceCardOrderedOn}
            </Text>
          </CardDetails>
        )}
      </CardDetailsGrid>
      <CardDetailsActions>
        <Button
          onClick={() => setActiveModal(MODALS.FullDetail)}
          dataAttributes={{ 'data-testid': 'button-view-card-details' }}
        >
          {content?.card_summary_view_full_details}
        </Button>
        <Button
          onClick={() => setActiveModal(MODALS.StatusHistory)}
          dataAttributes={{ 'data-testid': 'button-view-card-status-history' }}
        >
          {content?.card_summary_view_status_history}
        </Button>
        <Button
          to={`${ROUTE_TRANSACTIONS}?searchTerm=${cardNumber}`}
          dataAttributes={{ 'data-testid': 'button-view-card-transactions' }}
        >
          {content?.card_summary_view_transaction_details}
        </Button>
      </CardDetailsActions>
      <TransactionMapContainer>
        {isLoading ? (
          <Skeleton height={'100%'} />
        ) : (
          <TransactionMap
            cardholderName={cardHolderName}
            cardNo={cardNumber}
            recentTransactions={data.recentTransactions}
            alertTransactions={data.alertedTransactions}
          />
        )}
      </TransactionMapContainer>
    </CardDetailWrapper>
  )
}

export default ViewCardDetail
