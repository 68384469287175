import { Controller } from 'react-hook-form'
import { LabelWrapper, InputWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Icon } from '@bp-digital/component-icon'

const YES = 'Y'
const NO = 'N'

const CardOdometerAddInfo = ({ control, content, isLoading }) => {
  return (
    <>
      <InputWrapper>
        <Controller
          name='pin.odometer'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <RadioButtonGroup
              id={name}
              label={
                <LabelWrapper>
                  <Text
                    display='inline-block'
                    dataAttributes={{ 'data-content-key': 'manage_cards_full_details_labels_odometer' }}
                  >
                    {content?.manage_cards_full_details_labels_odometer}
                  </Text>

                  <Popover
                    appearance='dark'
                    text={content?.manage_cards_edit_full_details_general_odometer_tooltip}
                    position='top'
                    dataAttributes={{ ['data-content-key']: 'manage_cards_edit_full_details_general_odometer_tooltip' }}
                  >
                    <Icon name='Info' appearance='dark' size='md' state='neutral' />
                  </Popover>
                </LabelWrapper>
              }
              options={[
                { id: YES, label: content?.manage_cards_yes },
                { id: NO, label: content?.manage_cards_no }
              ]}
              onChange={onChange}
              defaultSelectedId={value}
              disabled={isLoading}
              horizontal
            />
          )}
        />
      </InputWrapper>
      <InputWrapper>
        <Controller
          name='pin.addinfo'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <RadioButtonGroup
              id={name}
              label={
                <LabelWrapper>
                  <Text
                    display='inline-block'
                    dataAttributes={{ 'data-content-key': 'manage_cards_edit_full_details_general_add_info' }}
                  >
                    {content?.manage_cards_edit_full_details_general_add_info}
                  </Text>

                  <Popover
                    appearance='dark'
                    text={content?.manage_cards_edit_full_details_general_add_info_tooltip}
                    position='top'
                    dataAttributes={{ ['data-content-key']: 'manage_cards_edit_full_details_general_add_info_tooltip' }}
                  >
                    <Icon name='Info' appearance='dark' size='md' state='neutral' />
                  </Popover>
                </LabelWrapper>
              }
              options={[
                { id: YES, label: content?.manage_cards_yes },
                { id: NO, label: content?.manage_cards_no }
              ]}
              onChange={id => onChange(id)}
              defaultSelectedId={value}
              disabled={isLoading}
              horizontal
            />
          )}
        />
      </InputWrapper>
    </>
  )
}

export default CardOdometerAddInfo
