import { PIN_METHOD, PIN_OPTION } from 'constants/pin'

const getPinLabel = pinMethod => {
  switch (parseInt(pinMethod, 10)) {
    case PIN_METHOD.CHOOSE_OWN_PIN:
      return PIN_OPTION.CHOOSE_OWN_PIN

    case PIN_METHOD.PIN_BY_POST:
      return PIN_OPTION.RANDOM_BY_POST

    case PIN_METHOD.CUSTOMER_WIDE_DEFAULT_PIN:
    case PIN_METHOD.CUSTOMER_WIDE_SELECTED_PIN:
      return PIN_OPTION.CUSTOMER_WIDE_PIN

    default:
      return PIN_OPTION.INVALID
  }
}

export default getPinLabel
