export const RESTRICTION_TYPES = {
  SPENDING_LIMIT: 'spendingLimit',
  PRODUCTS_AND_SERVICES: 'productAndServices',
  SITE_LOCATIONS: 'siteLocations',
  DAY_AND_TIME: 'dayTime',
  ODOMETER: 'odometerAndDistance'
}

export const CATEGORY_TYPES = {
  100: 'diesel',
  200: 'motor_spirits',
  300: 'lubricants',
  400: 'vehicle_services',
  600: 'shop_goods',
  800: 'vehicle_wash'
}

export const VIOLATION_TYPE = {
  ACCEPT: 1,
  ACCEPT_AND_ALERT: 2,
  REJECT_AND_ALERT: 3
}

export const PRODUCT_RESTRICTION_SERVICE_CODE = 65

export const CARD_SETUP_TYPES = {
  WEEKEND: 'W',
  WEEKDAYS: 'B',
  CUSTOM: 'C'
}

export const DAYS = ['', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] // API DAY index starts at 1

export const WEEKDAYS = 'monFri'
export const WEEKENDS = 'satSun'
export const CUSTOM = 'custom'

export const ALLOWED_DAYS = {
  ALLOW: 1,
  DONT_ALLOW: 0
}

export const PRODUCTS_ALLOWED = {
  ALLOW: '1',
  NOT_ALLOW: '0'
}

export const PRODUCTS_APPLY = {
  ALL: true,
  EACH: false
}

export const PRODUCTS_NOT_LISTED = {
  ALLOW: '1',
  NOT_ALLOW: '0'
}

export const PRODUCTS_STEP = {
  ONE: 1,
  TWO: 2
}

export const PRODUCT_DAILY_MAX_VOLUME = 99999
export const PRODUCT_DAILY_MAX_QUANTITY = 9999
export const PRODUCT_DAILY_MAX_VALUE = 9999999
export const PRODUCT_TXN_MAX_VOLUME = 999
export const PRODUCT_TXN_MAX_VALUE = 99999

export const PRODUCT_SERVICE_TYPE = {
  FUEL: 'F',
  NOT_FUEL: 'N'
}

export const START_TIME = '00:00'
export const END_TIME = '23:59'

export const MODE = {
  VIEW: 'view',
  CREATE: 'create',
  EDIT: 'edit'
}
