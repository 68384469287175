import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import useContent from 'hooks/useContent'
import { BRAND_ARAL } from 'constants/brands'
import { Logo, LogoWrapper, Content, ButtonWrapper, AlertWrapper, ErrorMessage } from './RegisterPage.styled'
import bpLogo from 'assets/logos/bp-logo-colour.svg'
import aralLogo from 'assets/logos/aral-logo.svg'
import { ROUTE_REGISTER_DETAILS } from 'constants/routes'
import useApiVerifyInvite from 'hooks/api/useApiVerifyInvite'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import Skeleton from 'react-loading-skeleton'
import { Alert } from '@bp-digital/component-alert'
import PublicLayout from 'components/layout/PublicLayout'
import getFallbackLanguage from 'helpers/getFallbackLanguage'
import { getCIPLoginPath } from 'helpers/getForgeRockvalue'

const RegisterPage = () => {
  const content = useContent('registration')
  const brand = getBrandFromDomain()
  const token = useUrlSearchParams().get('status')
  const { isLoading, isError, isSuccess, data } = useApiVerifyInvite(token, { staleTime: 120000 })
  async function url() {
    window.location.href = await getCIPLoginPath()
  }

  return (
    <PublicLayout>
      <LogoWrapper>
        <Logo src={brand === BRAND_ARAL ? aralLogo : bpLogo} />
      </LogoWrapper>
      {isLoading && (
        <>
          <Content>
            <Skeleton height={10} width={270} count={5} />
          </Content>
          <ButtonWrapper>
            <Skeleton height={50} width={125} />
            <Skeleton height={50} width={125} />
          </ButtonWrapper>
        </>
      )}
      {isError && (
        <AlertWrapper>
          <Alert text={content?.error_default_message || 'error_default_message'} visible={true} state='error' />
        </AlertWrapper>
      )}
      {isSuccess && (
        <>
          <Content>
            <Text>
              {content?.landing_message_p1} {content?.landing_message_p2}
            </Text>
          </Content>
          {data?.otapExpired ? (
            <>
              <ErrorMessage>
                <Text>{content?.otap_otapExpired}</Text>
              </ErrorMessage>
              <ButtonWrapper>
                  <Button
                    size='lg'
                    appearance='secondary'
                    onClick={async () => {
                      await url()
                    }}
                  >
                    {content?.landing_login_button}
                  </Button>
                <Button disabled={true} size='lg'>
                  {content?.landing_register_button}
                </Button>
              </ButtonWrapper>
            </>
          ) : data?.otapAlreadyUsed ? (
            <>
              <ErrorMessage>
                <Text>{content?.otap_otapAlreadyUsed}</Text>
              </ErrorMessage>
              <ButtonWrapper>
                  <Button
                    size='lg'
                    appearance='secondary'
                    onClick={async () => {
                      await url()
                    }}
                  >
                    {content?.landing_login_button}
                  </Button>
                <Button disabled={true} size='lg'>
                  {content?.landing_register_button}
                </Button>
              </ButtonWrapper>
            </>
          ) : !data?.otapValidated ? (
            <>
              <ErrorMessage>
                <Text>{content?.otapValidated}</Text>
              </ErrorMessage>
              (
              <ButtonWrapper>
                  <Button
                    size='lg'
                    appearance='secondary'
                    onClick={async () => {
                      await url()
                    }}
                  >
                    {content?.landing_login_button}
                  </Button>
                <Button disabled={true} size='lg'>
                  {content?.landing_register_button}
                </Button>
              </ButtonWrapper>
              )
            </>
          ) : (
            <ButtonWrapper>
                <Button
                  size='lg'
                  appearance='secondary'
                  onClick={async () => {
                    await url()
                  }}
                >
                  {content?.landing_login_button}
                </Button>
              <Button
                size='lg'
                href={`/${getFallbackLanguage()}${ROUTE_REGISTER_DETAILS}?status=${encodeURIComponent(token)}`}
              >
                {content?.landing_register_button}
              </Button>
            </ButtonWrapper>
          )}
        </>
      )}
    </PublicLayout>
  )
}

export default RegisterPage
