import breakpoints from 'src/styles/breakpoints'

const getPaginationSiblingCount = () => {
  const wideEnoughBreakpoint = parseInt(breakpoints.size.sm, 10)
  const estimatedWindowSize = window.innerWidth

  if (estimatedWindowSize >= wideEnoughBreakpoint) {
    return 2
  }

  return 0
}

export default getPaginationSiblingCount
