import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { FILTER_IDS, INSIGHTS_DATES } from '../constants'

type FilterOption = {
  id: string
  label: string
}

interface IContent {
  cpo_charger_details_insight_current_month: string
  cpo_charger_details_insights_quarter: string
  cpo_charger_details_insight_custom_date_range: string
}

export const getDateFilters = (content: IContent): FilterOption[] => [
  {
    id: FILTER_IDS.CURRENT_MONTH,
    label: content.cpo_charger_details_insight_current_month
  },
  { id: FILTER_IDS.LAST_MONTH, label: INSIGHTS_DATES.LAST_MONTH },
  {
    id: FILTER_IDS.LAST_QUARTER,
    label: replaceVariablesInString(content.cpo_charger_details_insights_quarter, {
      quarter: INSIGHTS_DATES.LAST_QUARTER_NUMBER
    })
  },
  {
    id: FILTER_IDS.CUSTOM_DATE_RANGE,
    label: content.cpo_charger_details_insight_custom_date_range
  }
]
