import GoogleMapReact from 'google-map-react'
import mapStyles from 'components/dataDisplay/Map/mapStyles'
import MapMarker from 'components/dataDisplay/Map/MapMarker'
import { ROUTE_CHARGE_POINT } from 'constants/routes'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import {
  STATUS_AVAILABLE,
  STATUS_CHARGING,
  STATUS_PARTIALLY_AVAILABLE,
  STATUS_PLANNED,
  STATUS_RESERVED,
  STATUS_UNKNOWN
} from 'src/constants/chargerStatuses'
import {
  SingleChargePointWrapper,
  ChargePointDetailsContainer,
  Title,
  Values,
  Label,
  Value
} from './SingleChargePoint.styled'
import { Button } from '@bp-digital/component-button'

const defaultCenter = {
  lat: 41.27396379749638,
  lng: 45.240973003125376
}

const statusToColour = status => {
  if (status === STATUS_AVAILABLE) {
    return 'green'
  } else if (
    status === STATUS_CHARGING ||
    status === STATUS_PLANNED ||
    status === STATUS_RESERVED ||
    status === STATUS_UNKNOWN ||
    status === STATUS_PARTIALLY_AVAILABLE
  ) {
    return 'yellowDark'
  }

  return 'red'
}

const SingleChargePoint = ({
  siteId,
  siteAddress,
  sitePostCode,
  siteCity,
  siteCountry,
  chargePoint,
  index,
  content
}) => {
  const cords = chargePoint?.coordinates || {}
  const address = [siteAddress, sitePostCode, siteCity, siteCountry].filter(field => !!field).join(', ') || ''
  const pinColour = statusToColour(chargePoint.chargePointStatus)
  return (
    <SingleChargePointWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyACfHLktkDCMANQyOksH_B66z9WZS7mWwQ',
          language: 'en'
        }}
        options={{
          styles: mapStyles
        }}
        defaultCenter={defaultCenter}
        defaultZoom={12}
        center={{ lat: cords.lat, lng: cords.lng }}
      >
        <MapMarker
          lat={cords?.lat || defaultCenter.lat}
          lng={cords?.lng || defaultCenter.lng}
          marker={{ colour: pinColour }}
        />
      </GoogleMapReact>
      <ChargePointDetailsContainer>
        <Title>
          {replaceVariablesInString(content?.cpo_view_details_title || 'cpo_view_details_title', {
            title: chargePoint.chargePointId
          })}
        </Title>
        <Values>
          <Label data-testid={`power-range-label-${index}`}>{content.cpo_power_range_label || ''}</Label>
          <Value data-testid={`power-range-value-${index}`}>
            {chargePoint.lowestConnectorPower + 'kW'} to {chargePoint.highestConnectorPower + 'kW'}
          </Value>
          <Label data-testid={`site-address-label-${index}`}>{content.cpo_site_address_label || ''}</Label>
          <Value data-testid={`site-address-value-${index}`}>{address}</Value>
          <Label data-testid={`total-vehicles-label-${index}`}>{content.cpo_total_vehicles_label || ''}</Label>
          <Value data-testid={`total-vehicles-value-${index}`}>{chargePoint.totalVehiclesAllowed}</Value>
        </Values>
        <Button to={`${ROUTE_CHARGE_POINT}?siteId=${siteId}&chargerId=${chargePoint.chargePointId}`}>
          {content?.cpo_view_details_button || 'cpo_view_details_button'}
        </Button>
      </ChargePointDetailsContainer>
    </SingleChargePointWrapper>
  )
}

export default SingleChargePoint
