const URL_PARAM_TO_LANGUAGE_MAPPING = {
  en: 'en',
  nl: 'nl',
  de: 'de',
  es: 'es',
  pt: 'pt',
  pl: 'pl'
}

const DEFAULT_LANGUAGE = URL_PARAM_TO_LANGUAGE_MAPPING.en

const getCIPRegistrationLanguageFromURL = () => {
  const langParam = window.location.pathname.split('/')[1]
  const lang = URL_PARAM_TO_LANGUAGE_MAPPING[langParam] || DEFAULT_LANGUAGE

  return lang
}

export default getCIPRegistrationLanguageFromURL
