import React, { useState, useRef, useContext, useEffect } from 'react'
import { DatePicker } from '@bp-digital/component-date-picker'
import { FilterWrapper } from '../InvoiceArchiveFilters.styled'
import { format } from 'light-date'
import { Filter } from 'hooks/api/useApiGetArchivedInvoices'
import { FilterContext, InvoiceFilterType } from 'pages/Transactions/ArchivedInvoicesPage/ArchivedInvoicesPage'
import { getFilterText } from 'pages/Transactions/ArchivedInvoicesPage/helpers/getAllAppliedFilterBadges'
import getFallbackLanguage from 'helpers/getFallbackLanguage'
import { InvoiceFilterProps } from '../InvoiceArchiveFilters'

export const FILTER_NAME = 'invoiceDate'

type DateOption = {
  dateString: string | null
  dateArray: (Date | string | number)[]
}

type LocaleKey = 'en' | 'es' | 'pt' | 'pl' | 'de' | 'nl'

const DateRangeFilter = ({ removedFilterIds, setRemoveFilterId, content }: InvoiceFilterProps) => {
  const locale = getFallbackLanguage()
  const { filterData, setFilterData } = useContext(FilterContext)

  const calendarRef = useRef<{ clear: () => void }>()
  const [dateRange, setDateRange] = useState<DateOption | null>(null)

  useEffect(() => {
    if (removedFilterIds?.includes(FILTER_NAME)) {
      setRemoveFilterId(ids => ids.filter(id => id !== FILTER_NAME))
      calendarRef.current?.clear()
    }
  }, [removedFilterIds, setRemoveFilterId])

  const selectedDateFilter = filterData?.filter(filter => filter.type === FILTER_NAME)[0]
  const { from: defaultFromDate, to: defaultToDate } = selectedDateFilter?.payload?.keyGroup?.[0]?.values?.[0] ?? {}

  const resetDateRange = () => {
    setDateRange(null)

    setFilterData(filters => {
      return filters.filter(item => {
        return item.type !== FILTER_NAME
      })
    })
  }
  const today = new Date()
  return (
    <FilterWrapper>
      <DatePicker
        hideBoxShadow
        locale={locale as LocaleKey}
        options={{
          dateFormat: 'd/m/Y',
          defaultDate: dateRange?.dateArray ?? [defaultFromDate ?? Date.now(), defaultToDate ?? Date.now()],
          minDate: new Date(today.getFullYear() - 10, 0, 1, 0, 0, 0),
          maxDate: 'today',
          onReady: (_, __, instance) => {
            if (!calendarRef.current?.clear) calendarRef.current = { clear: instance?.clear }
          },
          onChange: (selectedDates, dateString) => {
            if (selectedDates?.length === 2) {
              const [from, to] = [
                format(selectedDates[0], '{dd}/{MM}/{yyyy}'),
                format(selectedDates[1], '{dd}/{MM}/{yyyy}')
              ]
              setDateRange({
                dateArray: [from, to],
                dateString: dateString.replace('to', '-')
              })

              const payload: Filter = {
                name: FILTER_NAME,
                keyGroup: [
                  {
                    keys: ['from', 'to'],
                    values: [
                      {
                        from,
                        to,
                        [FILTER_NAME]: 'dateRange'
                      }
                    ]
                  }
                ]
              }

              setFilterData((filters: InvoiceFilterType[]) => [
                ...filters,
                {
                  type: FILTER_NAME,
                  text: getFilterText(payload, content),
                  payload
                }
              ])
            } else {
              resetDateRange()
            }
          }
        }}
      />
    </FilterWrapper>
  )
}

export default DateRangeFilter
