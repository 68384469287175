import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media only screen and ${breakpoints.device.lg} {
    grid-template-columns: 1fr 1fr;

    > div:first-child {
      grid-row-start: 1;
      grid-row-end: 3;
    }

    > div:last-child {
      grid-column: span 2;
    }
  }
`

export const BackButtonWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 8px;

  & > a {
    display: flex;
    align-items: center;
    margin-left: -8px;
    color: ${CssVars.palette.neutral.white};

    &:visited {
      color: ${CssVars.palette.neutral.white};
    }
  }
`
