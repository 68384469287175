import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
export const QUERY_KEY = 'invoices/archived'

type paramType = string | null

interface ApiResult {
  count: number
  archive: (ArchivedInvoice | ArchivedInvoice[])[] | null
}

export interface ArchivedInvoiceResponse {
  count: number
  summaries: SummaryStatement[]
  invoices: ArchivedInvoice[]
}

export interface ArchivedInvoice {
  supplyCountry: string
  invoiceType: string
  issuerCountry: string
  amount: string
  customer: string
  clientISOCode: string
  clientNr: string
  authorityNumber: string
  invoiceNumber: string
  invoiceDate: string
}

export interface SummaryStatement extends ArchivedInvoice {
  invoices: ArchivedInvoice[]
}

type TObj = { [key: string]: string | number }

export interface Filter {
  name: string
  keyGroup: {
    keys: string[]
    values: {
      [key: string]: paramType
    }[]
  }[]
  meta?: { [key: string]: TObj | string | number } | undefined
}

export interface Payload {
  includedAuthorityId: paramType[]
  filters: Filter[]
}

interface QueryParams {
  authorityId: string
  authorityNumber: string
  countryISO: string
  invoiceLevel: string
  customFilters?: Filter[]
}

const buildPayload = (
  authorityId: paramType,
  authorityNumber: paramType,
  countryISO: paramType,
  invoiceLevel: paramType,
  customFilters?: Filter[]
) => {
  const authorityNo = `${countryISO}-${authorityNumber}`

  return {
    includedAuthorityId: [authorityId],
    filters: [
      {
        filter: {
          name: 'authorityNumber',
          keyGroup: [
            {
              keys: ['authorityNumber'],
              values: [
                {
                  authorityNumber: authorityNo,
                  invoiceLevel
                }
              ]
            }
          ]
        }
      },
      ...(customFilters?.length
        ? customFilters.map(filter => ({
            filter: {
              ...filter
            }
          }))
        : [])
    ]
  }
}

const buildFilterQueryKey = (filters: Filter[]): string => {
  let filterQuery = ''
  filters.forEach((filter): void => {
    const entries = Object.entries(filter.keyGroup[0]?.values ?? [])

    filterQuery += entries.reduce((previousItem, currentItem, index, arr) => {
      const values = currentItem[1]
      const keyValue = Object.entries(values)
        .map(entry => entry.join('='))
        .join('&')

      return `${previousItem}${keyValue}${index !== arr.length - 1 ? '--' : ''}`
    }, `filterName=${filter.name}&`)
  })

  return filterQuery
}

const mapDataToSummary = (dataItem: ArchivedInvoice[]): SummaryStatement => {
  const summaryIndex = dataItem.findIndex(groupItem => groupItem.invoiceType === 'SUMMARY-STATEMENT')
  const invoices = dataItem.filter(groupItem => groupItem.invoiceType !== 'SUMMARY-STATEMENT')

  return {
    ...dataItem[summaryIndex],
    invoices
  }
}

const queryFn = async (
  authorityId: string,
  authorityNumber: string,
  countryISO: string,
  invoiceLevel: string,
  customFilters: QueryParams['customFilters']
): Promise<ArchivedInvoiceResponse> => {
  if (!authorityId || !authorityNumber || !invoiceLevel || !countryISO)
    throw new Error(
      'No Authority Id, Authority Number, Country ISO or Invoice Level given to /invoices/searcharchive endpoint'
    )

  const formatDate = (date: Date): string => date.toISOString()

  interface ReqConfig {
    params: Record<string, any>
  }

  const reqConfig: ReqConfig = {
    params: {}
  }

  const parsedBody = buildPayload(authorityId, authorityNumber, countryISO, invoiceLevel, customFilters)

  if (parsedBody.includedAuthorityId.length !== 1) {
    throw new Error()
  }

  reqConfig.params.authorityIds = parsedBody.includedAuthorityId[0]
  // TODO -- PARENT / GROUP SUPPORT
  parsedBody.filters.forEach(fltr => {
    if (fltr.filter.name === 'invoiceNumber' && fltr.filter.keyGroup[0].keys[0] === 'invoiceNumber') {
      reqConfig.params.invoiceNumber = fltr.filter.keyGroup[0].values[0].invoiceNumber
    }
    if (fltr.filter.name === 'invoiceType' && fltr.filter.keyGroup[0].keys[0] === 'invoiceType') {
      reqConfig.params.invoiceType = fltr.filter.keyGroup[0].values[0].invoiceType
    }

    if (
      fltr.filter.name === 'invoiceDate' &&
      fltr.filter.keyGroup[0].keys[0] === 'from' &&
      fltr.filter.keyGroup[0].keys[1] === 'to'
    ) {
      reqConfig.params.invoiceDateFrom = formatDate(
        new Date(
          parseInt(fltr.filter.keyGroup[0].values[0].from?.substring(6, 10) || '0'),
          parseInt(fltr.filter.keyGroup[0].values[0].from?.substring(3, 5) || '1') - 1,
          parseInt(fltr.filter.keyGroup[0].values[0].from?.substring(0, 2) || '1'),
          0,
          0,
          0,
          0
        )
      )

      reqConfig.params.invoiceDateTo = formatDate(
        new Date(
          parseInt(fltr.filter.keyGroup[0].values[0].to?.substring(6, 10) || '0'),
          parseInt(fltr.filter.keyGroup[0].values[0].to?.substring(3, 5) || '1') - 1,
          parseInt(fltr.filter.keyGroup[0].values[0].to?.substring(0, 2) || '1'),
          23,
          59,
          59,
          0
        )
      )
    }

    if (
      fltr.filter.name === 'amount' &&
      fltr.filter.keyGroup[0].keys[0] === 'from' &&
      fltr.filter.keyGroup[0].keys[1] === 'to' &&
      fltr.filter.keyGroup[0].keys[2] === 'currency'
    ) {
      reqConfig.params.InvoiceAmountFrom = fltr.filter.keyGroup[0].values[0].from
      reqConfig.params.InvoiceAmountTo = fltr.filter.keyGroup[0].values[0].to
      reqConfig.params.InvoiceCurrency = fltr.filter.keyGroup[0].values[0].currency
    }

    if (fltr.filter.name === 'supplyCountry' && fltr.filter.keyGroup[0].keys[0] === 'supplyCountry') {
      fltr.filter.keyGroup[0].values.forEach((countryFilter, index) => {
        reqConfig.params[`InvoiceIsoSupplyCountries[${index}]`] = countryFilter.countryCode
      })
    }
  })

  const response = await axios.get(`/api/transactions/invoices/searcharchive`, {
    params: reqConfig.params
  })

  let apiResult: ApiResult = response.data

  const isNationalOrInternationalInvoiceFilterApplied = customFilters?.some(
    item => item.name === 'invoiceType' && item.keyGroup[0].values[0]?.invoiceType !== 'SUMMARY-STATEMENT'
  )

  if (isNationalOrInternationalInvoiceFilterApplied) {
    apiResult = {
      ...apiResult,
      archive: apiResult?.archive ?? null
    }
  }
  // eslint-disable-next-line
  const isSummaryInvoiceType = (value: any) => {
    return value.some((val: { invoiceType: string }) => val.invoiceType == 'SUMMARY-STATEMENT')
  }

  const invoiceOutput = <ArchivedInvoice[]>apiResult.archive?.filter(i => !isSummaryInvoiceType(i)).flat()

  const summaryOutput = <SummaryStatement[]>(
    apiResult.archive?.filter(i => isSummaryInvoiceType(i)).map(m => mapDataToSummary(<ArchivedInvoice[]>m))
  )
  return {
    count: apiResult.count,
    invoices: invoiceOutput ?? [],
    summaries: summaryOutput ?? []
  }
}

const useApiGetArchivedInvoices = (
  { authorityId, authorityNumber, countryISO, invoiceLevel, customFilters = [] }: QueryParams,
  options: {
    enabled?: boolean
    onSuccess?: (arg0: ArchivedInvoiceResponse) => void
    onError?: (arg0: AxiosError) => void
    onSettled?: () => void
  }
) => {
  const filters = customFilters.length ? { filters: buildFilterQueryKey(customFilters) } : {}

  return useQuery(
    [QUERY_KEY, { params: { authorityNumber, ...filters } }],
    () => queryFn(authorityId, authorityNumber, countryISO, invoiceLevel, customFilters),
    {
      staleTime: 120000,
      ...options
    }
  )
}

export default useApiGetArchivedInvoices
