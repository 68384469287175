import { useEffect, useState } from 'react'
import DataGrid, { Row, SelectColumn } from 'react-data-grid'
import getLoadingRows from 'src/helpers/getLoadingRows'
import getPaginationSiblingCount from 'src/helpers/getPaginationSiblingCount'
import CellExpanderFormatter from 'src/components/dataDisplay/DataTable/components/CellExpander'
import TableControls from 'src/components/dataDisplay/TableControls'
import TablePagination from 'src/components/dataDisplay/TablePagination'
import EmptyRows from './components/EmptyRows'

const defaultHeightOfTableRowInPixels = 80
const defaultExpandedRowHeight = 350
const defaultChildRowHeight = 40

const DataTable = ({
  rowHeight = defaultHeightOfTableRowInPixels,
  expandedRowHeight = defaultExpandedRowHeight,
  childRowHeight = defaultChildRowHeight,
  hasChildRows = false,
  isLoading = false,
  initialSortColumn = '',
  onSort,
  rows: rowsData,
  onRowClick,
  childRows,
  renderDetail,
  columns = [],
  search = {},
  actions = [],
  pagination = {},
  hasSelection = true
}) => {
  const [rows, setRows] = useState(rowsData)
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [sortColumn, setSortColumn] = useState(initialSortColumn ? { columnKey: initialSortColumn, direction: 'desc' } : {})
  const dataColumns = columns || []

  if (hasSelection) {
    dataColumns.unshift(SelectColumn)
  }

  if (renderDetail) {
    dataColumns.push({
      key: 'expanded',
      name: '',
      minWidth: 42,
      width: 42,
      formatter({ row, isCellSelected, onRowChange }) {
        return (
          <CellExpanderFormatter
            expanded={row.expanded}
            isCellSelected={isCellSelected}
            onCellExpand={() => {
              onRowChange({ ...row, expanded: !row.expanded })
            }}
          />
        )
      }
    })
  }

  const loadingRows = getLoadingRows(
    dataColumns.map(col => col.key),
    15
  )

  const onRowsChange = (rows, { indexes }) => {
    const rowsToModify = rows.slice()

    indexes.forEach(index => {
      const row = rows[index]
      const indexInModifiedRows = rowsToModify.findIndex(modRow => modRow.key === row.key)

      if (row.type === 'MASTER') {
        if (!row.expanded) {
          if (hasChildRows) {
            const childData = childRows[row.key]
            let numberOfRows = childData?.length

            if (!childData) {
              const reg = /\(([^)]+)\)/ // find value in between (brackets)
              const predictedNumber = parseInt(reg.exec(row.invoiceType)[1], 10)
              numberOfRows = predictedNumber
            }

            rowsToModify.splice(indexInModifiedRows + 1, numberOfRows + 1)
          } else {
            rowsToModify.splice(indexInModifiedRows + 1, 1)
          }
        } else {
          if (hasChildRows) {
            const childData = childRows[row.key]
            let numberOfRows = childData?.length

            // Get precicted number of child rows for given parent based on row name (invoiceType)
            if (!childData) {
              const reg = /\(([^)]+)\)/ // find value in between (brackets)
              const predictedNumber = parseInt(reg.exec(row.invoiceType)[1], 10) // hardcoded to invoice for now, only one with childRows
              numberOfRows = predictedNumber
            }

            // for each child row, whether predicted or real with data,
            // splice the child into the rows array
            for (let i = 0; i < numberOfRows; i++) {
              const value = childData?.[i] || {}

              rowsToModify.splice(indexInModifiedRows + 1 + i, 0, {
                type: 'CHILD',
                key: `${row.key}-${i}-child`,
                parentKey: row.key,
                ...value
              })
            }

            // splice the detail row (transactions map, or invoice summary actions, or card view)
            rowsToModify.splice(indexInModifiedRows + 1 + numberOfRows, 0, {
              type: 'DETAIL',
              key: `${row.key}-detail`,
              parentKey: row.key,
              detail: {
                ...row
              }
            })
          } else {
            rowsToModify.splice(indexInModifiedRows + 1, 0, {
              type: 'DETAIL',
              key: `${row.key}-detail`,
              parentKey: row.key,
              detail: {
                ...row
              }
            })
          }
        }
      }
    })

    setRows(rowsToModify)
  }

  const renderRow = props => {
    const getTopOfRow = rowIdx => {
      const currentRows = isLoading ? loadingRows : rows
      const rowsToIdx = currentRows.slice(0, rowIdx)
      const childRowCount = rowsToIdx.filter(({ type }) => type === 'CHILD').length
      const detailRowCount = rowsToIdx.filter(({ type }) => type === 'DETAIL').length
      const masterRowCount = rowsToIdx.filter(({ type }) => type === 'MASTER').length
      const headerRowHeight = 35
      const top =
        childRowHeight * childRowCount +
        detailRowCount * expandedRowHeight +
        masterRowCount * rowHeight +
        headerRowHeight
      return top
    }

    if (props.row?.type === 'DETAIL') {
      const { height, rowIdx, handleDragEnter, className } = props
      // Review use of memo
      return (
        <div
          key='row-custom'
          role='row'
          tabIndex={-1}
          className={className}
          onMouseEnter={handleDragEnter}
          style={{
            top: getTopOfRow(rowIdx),
            '--row-height': `${height}px`,
            height: `${height}px`,
            position: 'absolute',
            width: '100%',
            maxWidth: 'calc(100vw - 32px - 2px)',
            display: 'flex',
            borderBottom: hasChildRows ? 'none' : '1px solid var(--border-color)',
            borderTop: hasChildRows ? '1px solid var(--border-color)' : 'none'
          }}
        >
          {renderDetail?.(props.row.detail)}
        </div>
      )
    }

    const isChildRow = props.row?.type === 'CHILD'
    const className = isChildRow ? 'rdg-row-child' : ''

    return (
      <Row
        {...props}
        onRowClick={onRowClick}
        height={isChildRow ? childRowHeight : rowHeight}
        className={className}
        data-testid={isLoading ? 'row-loading' : 'row-data'}
        top={getTopOfRow(props.rowIdx)}
      />
    )
  }

  useEffect(() => {
    // This effect prevents detail rows from being removed
    // on re-render (e.g. when modals are opened, etc.)
    const getHashOfRows = rows => {
      const arrayOfKeys = rows.filter(row => row && row.type !== 'DETAIL' && row.type !== 'CHILD').map(({ key }) => key)

      return arrayOfKeys.join()
    }

    const previousRows = getHashOfRows(rows)
    const incomingRows = getHashOfRows(rowsData)

    if (previousRows != incomingRows) {
      setRows(rowsData)
    } else if (hasChildRows) {
      // Code for invoices, so it forceable re-renders the table
      // when new children rows arrive
      const newChildren = rows.filter(row => !!row.parentKey && row.type === 'CHILD' && Object.keys(row).length === 3)

      if (newChildren.length) {
        const newRows = rows.map(row => {
          // if child row in current state is missing data, we need to merge the new childRows prop
          // data with the rows state in DataTable
          const isEmptyChild = !!row.parentKey && row.type === 'CHILD' && Object.keys(row).length === 3

          if (isEmptyChild) {
            const childData = childRows[row.parentKey]
            const indexOfCurrentChild = parseInt(row.key.split('-')[1], 10) // gets middle index from ''N1508123160-0-child'

            if (selectedRows.has(row.parentKey)) {
              // auto-select new empty children if parent is selected
              selectedRows.add(row.key)
            }

            return {
              ...row,
              ...childData?.[indexOfCurrentChild]
              // expanded: row.expanded || !!rows.find(r => r.parentKey === row.key) // if childs parentKey matches current row key, must be expanded
            }
          }

          return row
        })

        setRows(newRows)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsData, childRows, hasChildRows])

  const tableActions = actions?.map(action => {
    if (action.enableOnSelection) {
      action.disabled = !selectedRows.size
    }

    return action
  })

  const handleRowSelect = newlySelectedRows => {
    if (hasChildRows) {
      let rowsCopy = rows // for when multi-rows selected at once
      let rowIndexesChanged = []

      newlySelectedRows.forEach(rowKey => {
        const hasExpandedSet = !!rowsCopy.find(({ key }) => key === rowKey).expanded
        const hasChildRendered = !!rowsCopy.find(({ parentKey }) => parentKey === rowKey)
        const isRowExpanded = hasExpandedSet || hasChildRendered

        if (!isRowExpanded) {
          rowsCopy = rowsCopy.map(row => ({
            ...row,
            expanded: row.expanded || row.key === rowKey
          }))

          const rowIdx = rowsCopy.findIndex(row => row.key === rowKey)
          rowIndexesChanged.push(rowIdx)
        }
      })

      if (rowIndexesChanged.length) {
        onRowsChange(rowsCopy, {
          indexes: rowIndexesChanged
        })
      }

      setSelectedRows(newlySelectedRows)
    } else {
      setSelectedRows(newlySelectedRows)
    }
  }

  const getTableHeight = () => {
    const numberOfRows = isLoading ? loadingRows.length + 1 : rows.length + 1

    if (!isLoading) {
      if (!rows.length) return 320

      const childRowCount = rows.filter(({ type }) => type === 'CHILD').length
      const detailRowCount = rows.filter(({ type }) => type === 'DETAIL').length
      const masterRowCount = rows.filter(({ type }) => type === 'MASTER').length
      const headerRowHeight = 38
      return (
        childRowHeight * childRowCount +
        detailRowCount * expandedRowHeight +
        masterRowCount * rowHeight +
        headerRowHeight
      )
    }

    return numberOfRows * rowHeight - 38
  }

  return (
    <>
      <TableControls
        search={search}
        actions={tableActions}
        selectedRows={selectedRows}
      />

      <DataGrid
        className='rdg-light'
        rowKey='key'
        columns={dataColumns}
        rows={isLoading ? loadingRows : rows}
        rowKeyGetter={row => row.key}
        rowRenderer={renderRow}
        noRowsFallback={<EmptyRows />}
        rowsCount={isLoading ? loadingRows.length : rows.length}
        onSelectedRowsChange={handleRowSelect}
        selectedRows={selectedRows}
        sortColumns={onSort && [sortColumn]}
        onRowsChange={onRowsChange}
        // rowHeight={rowHeight}
        rowHeight={args =>
          args.type === 'ROW' && args.row.type === 'DETAIL' ? expandedRowHeight : defaultHeightOfTableRowInPixels
        }
        onSortColumnsChange={onSort && ((val) => {
          if (val.length) {
            setSortColumn(val[0])
            const direction = val[0].direction
            const key = val[0].columnKey
            onSort(key, direction)
          } else {
            setSortColumn({})
            onSort()
          }
        })}
        defaultColumnOptions={{
          resizable: true,
          sortable: true
        }}
        style={{
          height: `${getTableHeight()}px`
        }}
      />

      <TablePagination
        defaultPage={pagination.currentPage}
        count={pagination.count || 1}
        siblingCount={getPaginationSiblingCount()}
        disabled={pagination.disabled}
        onChange={pagination.onChange}
      />
    </>
  )
}

export default DataTable
