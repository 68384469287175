export const CARD_OFFER_TYPES = {
  KOMFORT: 'KO',
  NON_KOMFORT: 'ZZ',
  PULSE: 'PU'
}

export const CARDS = {
  BP_PLUS_CARD: 'BP_PLUS_CARD',
  BP_BONUS: 'BP_BONUS',
  BP_KOMFORT_PREPAID: 'BP_KOMFORT_PREPAID',
  BP_KOMFORT: 'BP_KOMFORT',
  ARAL_PLUS: 'ARAL_PLUS',
  ARAL_KOMFORT: 'ARAL_KOMFORT',
  BP_PULSE: 'BP_PULSE'
}

export const CARD_STATUS = {
  LOST: 'Lost',
  DISPATCHED: 'Card Dispatched',
  DESTROYED: 'Destroyed',
  STOLEN: 'Stolen',
  ACTIVE: 'Active'
}

export const CARD_STATUS_CODE = {
  ALL: 'default',
  PENDING: 'pendingReq',
  AWAITING_EMBOSSING: 'awaitingEmb',
  RECENT: 'recentOrders',
  AWAITING_REISSUE: 'awaitReissue',
  NOT_FOR_REISSUE: 'notForReissue'
}

export const CARD_STATUS_ID = {
  LOST: 3,
  STOLEN: 2,
  DESTROYED: '30_31_32',
  ACTIVE: 100,
  AWAITING_EMBOSSING: 910,
  CARD_DESTROYED_BP: 11,
  REISSUE_NOT_PRODUCED: 34,
  AWAITING_REISSUE_NEW: 930,
  AWAITING_PRODUCTION: 940,
  REPLACED: 950,
  REISSUED: 960,
  NOT_FOR_REISSUE: 990,
  CARD_DISPATCHED: 350,
  CARD_EMBOSSED: 370,
  UNUSED_RECENTLY: 980
}

export const CARD_DRIVER_PIN = '0'
export const CARD_DRIVER_PIN_TYPE = '4'
export const CARD_CUSTOMER_PIN = '2'
export const CARD_CUSTOMER_PIN_THREE = '3'
export const CARD_CUSTOMER_WIDE_PIN = '3'

export const STOP_CARD_REASONS = [
  { name: 'stolen', id: 2 },
  { name: 'lost', id: 3 },
  { name: 'damaged', id: 31 }
]

export const CARD_GASOLEO_PROFESIONAL = 'GASOLEO PROFESIONAL'
export const CARD_REISSUESTATUS_SUCCESS_MESSAGE = 'cards status updated successfully'
export const CARD_GASOLEO_PROFESIONAL_Label = 'Gasoleo Profesional'
