import { CARDS, CARD_OFFER_TYPES } from 'constants/cards'

const CARD_IMAGE_NAME = {
  [CARDS.BP_PLUS_CARD]: 'bp_plus',
  [CARDS.BP_BONUS]: 'bp_bonus',
  [CARDS.BP_KOMFORT_PREPAID]: 'bp_komfort_prepaid',
  [CARDS.BP_KOMFORT]: 'bp_komfort',
  [CARDS.ARAL_PLUS]: 'aral_plus',
  [CARDS.ARAL_KOMFORT]: 'aral_komfort',
  [CARDS.BP_PULSE]: 'bp_pulse',
  DEFAULT_ACTIVE: 'fuel-card-active',
  DEFAULT_IN_ACTIVE: 'fuel-card-inactive'
}

type PlasticType = {
  [key: string]: string
}

interface Card {
  cardProgramNumber: number
  countryCode: string
  cardOfferType: string
  plasticTypes: PlasticType[]
}

const loadAsset = (name: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logo = require(`src/assets/cards/${name}.svg`)
    return logo
  } catch (err) {
    console.error(`Failed loading ${name}.svg`)
  }
}

export const getCardImage = ({ cardProgramNumber, countryCode, cardOfferType, plasticTypes }: Card) => {
  if (
    cardProgramNumber &&
    cardProgramNumber === 6 &&
    countryCode &&
    countryCode !== 'DE' &&
    cardOfferType !== CARD_OFFER_TYPES.KOMFORT &&
    cardOfferType !== CARD_OFFER_TYPES.PULSE &&
    (plasticTypes.length === 0 || plasticTypes.every(item => item.plasticType !== 'BPK'))
  ) {
    // BP Plus
    return loadAsset(CARD_IMAGE_NAME[CARDS.BP_PLUS_CARD])
  } else if (
    countryCode &&
    countryCode !== 'DE' &&
    (cardOfferType === CARD_OFFER_TYPES.KOMFORT ||
      (plasticTypes.length && plasticTypes.some(item => item.plasticType === 'BPK')))
  ) {
    // BP Komfort
    return loadAsset(CARD_IMAGE_NAME[CARDS.BP_KOMFORT])
  } else if (cardProgramNumber && countryCode && countryCode === 'DE') {
    if (cardProgramNumber === 6) {
      // Aral Plus
      return loadAsset(CARD_IMAGE_NAME[CARDS.ARAL_PLUS])
    }

    if (cardProgramNumber === 32) {
      // Aral Komfort
      return loadAsset(CARD_IMAGE_NAME[CARDS.ARAL_KOMFORT])
    }
  } else if (cardOfferType === CARD_OFFER_TYPES.PULSE) {
    // BP Pulse
    return loadAsset(CARD_IMAGE_NAME[CARDS.BP_PULSE])
  }

  return loadAsset(CARD_IMAGE_NAME.DEFAULT_ACTIVE)
}
