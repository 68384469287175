import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'

const NoAuthority = ({ content }) => (
  <div className='container' data-testid='no-authority-warning'>
    <Heading as='h2' data-content-key='setup_no_authority_heading'>
      {content?.setup_no_authority_heading || 'setup_no_authority_heading'}
    </Heading>

    <Text as='p' data-content-key='setup_no_authority_copy'>
      {content?.setup_no_authority_copy || 'setup_no_authority_copy'}
    </Text>
  </div>
)

export default NoAuthority
