/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { observer } from 'mobx-react'
import debounce from 'lodash.debounce'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import evBanner from 'src/assets/headers/ev-banner.jpg'
import TokenRowExpander from './components/TokenRowExpander'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@bp-digital/component-button'
import { ROUTE_DEPOT_PERMISSIONS_SELECT_DEPOTS } from 'constants/routes'
import { Alert } from '@bp-digital/component-alert'
import { SteppedProgress } from '@bp-digital/component-stepped-progress'
import useContent from 'hooks/useContent'
import { FEATURE_FLAGS } from 'constants/featureFlags'
import { ROUTE_ADMIN } from 'constants/routes'
import { Redirect } from 'react-router-dom'

const SelectCards = () => {
  const { tokensStore, contentStore, userStore } = useRootStore()
  const content = contentStore.getPage('chargepointoperations-select-cards')
  const depotsContent = useContent('chargepointoperations-select-depots')
  const mountedRef = useRef(true)
  // const authorityId = userStore.selectedAuthorityId
  const selectedHierarchy = userStore.selectedHierarchy
  const showSearch = FEATURE_FLAGS.SHOW_SELECT_CARDS_SEARCH
  const loadingRow = tokensStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  useEffect(() => {
    if (!mountedRef.current) return null
    if (userStore.isAdmin && !userStore.impersonatedUser) setRedirectAdmin(true)
  }, [userStore.isAdmin, userStore.impersonatedUser])

  useEffect(() => {
    // authorityId changed, refetch cards
    tokensStore.getCards({ selectedHierarchy })
    tokensStore.setSelectedRows([])
  }, [selectedHierarchy]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!content) {
      contentStore.getContent('chargepointoperations-select-cards')
    }

    if (!depotsContent) {
      contentStore.getPage('chargepointoperations-select-depots')
    }
  }, [content, depotsContent, contentStore])

  const handleSubmitSearch = async () => {
    await tokensStore.getCards({ selectedHierarchy })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      tokensStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    tokensStore.setSearchTerm('')
    tokensStore.setSearchLimits({})
    tokensStore.getCards({ selectedHierarchy })
  }

  const tableSearchProps = {
    searchTerm: tokensStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.select_cards_search_placeholder || 'Search for [Criteria]',
    inputAriaLabel: content?.select_cards_search_placeholder || 'Search for [Criteria]',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search',
    minLength: 3,
    tooltipMessage:
      content?.select_cards_search_tooltip || 'Only alphanumeric characters and date format DD/MM/YYYY are allowed'
  }

  const tablePagination = {
    currentPage: tokensStore.currentPage,
    count: tokensStore.numberOfPages || 1,
    disabled: !tokensStore.data,
    onChange: page => tokensStore.getCards({ selectedHierarchy, page })
  }

  const data = useMemo(
    () =>
      tokensStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : tokensStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokensStore.rows, tokensStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      tokensStore.isFetchingData
        ? tokensStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : tokensStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokensStore.columns, tokensStore.isFetchingData, tablePagination.currentPage]
  )

  const handleRowSelected = useCallback(
    rows => {
      tokensStore.setSelectedRows(rows)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokensStore.setSelectedRows]
  )

  useEffect(() => {
    let timer
    if (tokensStore.responseStatus || tokensStore.responseData) {
      timer = setTimeout(() => {
        tokensStore.setResponseStatus('')
        tokensStore.setResponseData({})
      }, 3000)
    }
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensStore.responseStatus, tokensStore.responseData])

  const handleSort = useCallback(
    (key, desc) => {
      const sortTokens = async (key = 'rfid', desc = true) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        tokensStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!tokensStore.results.length && tokensStore.searchTerm.length) {
          await tokensStore.getCards({
            selectedHierarchy,
            columnSorting,
            searchTerm: ''
          })
        }
        await tokensStore.getCards({
          selectedHierarchy,
          columnSorting
        })
      }
      if (tokensStore.rows.length > 0) {
        sortTokens(key, desc)
      }
    },
    [selectedHierarchy, tokensStore]
  )

  const ShowAlert = () => {
    const isSuccess = tokensStore.responseStatus === 'success'
    const alertMsg = isSuccess ? content?.depot_permissions_save_success : content?.depot_permissions_save_fail
    const state = isSuccess ? 'success' : 'danger'
    return <Alert text={alertMsg} state={state} id='test' visible={tokensStore.responseStatus} />
  }
  if (redirectAdmin) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.depot_permissions_timeline_select_cards || '...'}
        subtitle={content?.select_cards_view_title || '...'}
        headerImage={evBanner}
        brand={userStore.brand}
      />
      <SteppedProgress
        items={[
          content?.select_cards_view_title || 'Step 1: Select cards',
          depotsContent?.select_depots_view_title || 'Step 2: Select depots'
        ]}
        selectedIndex={0}
        dataAttributes={{ 'data-testid': 'depot-permissions-step-one' }}
      />
      <InnerPageWrapper>
        <ShowAlert />
        <ReactTable
          name='select-cards'
          columns={columns}
          data={data}
          renderDetail={row => <TokenRowExpander content={content} row={row} />}
          expandedRowHeight={246}
          expandSubRowOnSelect={false}
          pagination={tablePagination}
          isLoading={tokensStore.isFetchingData}
          initiallySelectedRows={tokensStore.selectedRows}
          onSelectedRowsChange={handleRowSelected}
          noResultsMessage={content?.select_cards_no_results}
          search={showSearch && tableSearchProps}
          onSort={handleSort}
        />

        <Button
          appearance='primary'
          borderRadius='pill'
          to={ROUTE_DEPOT_PERMISSIONS_SELECT_DEPOTS}
          disabled={!tokensStore.hasSelectedRows}
        >
          {content?.next_step}
        </Button>
      </InnerPageWrapper>
    </>
  )
}

export default observer(SelectCards)
