export const IS_ADMIN = 'bpf-is-admin'
export const ACCESS_LVL = 'bpf-lvl'
export const ACCESS_LVL_CODE = 'bpf-lvl-code'
export const EXPIRY_ID = 'bpf-id-expiry'
export const IDP_TOKEN = 'bpf-idp-token'
export const IDP_REFRESH = 'bpf-idp-refresh'
export const ONEFLEETMIGRATIONSTATUS = 'bpf-onefleetmigration-status'
export const ISONEFLEETMIGRATED = 'bpf-isonefleet-migrated'
export const USER_FIRSTNAME = 'bpf-user-firstname'
export const IMPERSONATED_USER_FirstNAME = 'bpf-imp-user-firstname'
