import { format } from 'light-date'
import getDateSeparator from 'helpers/getDateSeparator'

const getAsDate = dateString => {
  const isDateTime = dateString.includes('T')
  if (isDateTime) {
    return new Date(dateString)
  } else {
    // converts to US mm/dd/yy for plain JS Date
    const separator = getDateSeparator(dateString)
    const [DD, MM, YYYY] = dateString.split(separator)
    return new Date(`${MM}/${DD}/${YYYY}`)
  }
}

export const getUserFormattedDate = (dateAsString, preference = 'DD/MM/YYYY hh:mm:ss', hideTime = false) => {
  if (!dateAsString) return ''
  if (typeof dateAsString !== 'string') return dateAsString
  const separator = getDateSeparator(dateAsString)

  if (hideTime) {
    // splits date object to exclude the time and only return DD/MM/YYYY values
    preference = preference.split(' ')[0]
  }
  if (dateAsString.split(separator).length === 3) {
    const date = getAsDate(dateAsString)
    return format(date, preference).replace(' 00:00:00', '')
  } else {
    return dateAsString.replace(separator, getDateSeparator(preference))
  }
}
