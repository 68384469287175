import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'register/user'
type deepLinkType = string | null

const queryFn = async (deepLink: deepLinkType) => {
  if (!deepLink) throw new Error('No DeepLink given to /api/users/user/otap endpoint')

  const response = await axios.get(`/api/users/users/otap`, {
    params: {
      Token: deepLink
    }
  })

  return { ...response.data, invitedEmail: response.data.eMail }
}

const useApiGetRegisterUser = (deepLink: deepLinkType, options: { staleTime: number | typeof Infinity }) => {
  return useQuery([QUERY_KEY, { deepLink: deepLink }], () => queryFn(deepLink), {
    ...options
  })
}

export default useApiGetRegisterUser
