export const getSpendingLimits = (spendingLimits, currency, content) => {
  let limits = []
  const transactions = content?.summary_restriction_transactions

  if (spendingLimits?.daily?.dailySpendLimit) {
    let value = `${currency}${spendingLimits.daily.dailySpendLimit}`

    if (spendingLimits.daily.dailyTxnLimit) {
      value = `${value}, ${spendingLimits.daily.dailyTxnLimit} ${transactions}`
    }

    limits.push({
      label: content?.restriction_spending_limit_daily,
      value
    })
  }

  if (spendingLimits?.weekly?.weeklySpendLimit) {
    let value = `${currency}${spendingLimits.weekly.weeklySpendLimit}`

    if (spendingLimits.weekly.weeklyTxnLimit) {
      value = `${value}, ${spendingLimits.weekly.weeklyTxnLimit} ${transactions}`
    }

    limits.push({
      label: content?.restriction_spending_limit_weekly,
      value
    })
  }

  if (spendingLimits?.monthly?.monthlySpendLimit) {
    let value = `${currency}${spendingLimits.monthly.monthlySpendLimit}`

    if (spendingLimits.monthly.monthlyTxnLimit) {
      value = `${value}, ${spendingLimits.monthly.monthlyTxnLimit} ${transactions}`
    }

    limits.push({
      label: content?.restriction_spending_limit_monthly,
      value
    })
  }

  if (spendingLimits?.rollingLimit?.rollingDaysCount) {
    limits.push({
      label: content?.restriction_spending_limit_rolling,
      value: `${spendingLimits.rollingLimit.rollingDaysCount} ${content?.restriction_profiles_spending_limit_rolling_day}, ${currency}${spendingLimits.rollingLimit.rollingDaysSpendLimit}`
    })
  }

  // dirty hack to simplify all of these components
  // renames value to values and makes array
  limits = limits.map(limit => ({ label: limit.label, values: [limit.value] }))

  return limits
}
