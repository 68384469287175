import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;

    span {
      font-size: 0.85rem;
      text-transform: none;
    }
  }

  padding-bottom: 1em;
`

export const LogBody = styled.div`
  padding: 20px;
`
