import Paper from 'components/surfaces/Paper'
import { Wrapper, Container, Row, Column } from './AccountAccess.styled'
import Skeleton from 'react-loading-skeleton'
import useApiGetAuthoritiesWithRoles from 'hooks/api/useApiGetAuthoritiesWithRoles'
import Authority from 'components/account/Authority'
import { Text } from '@bp-digital/component-typography'

const AccountAccess = ({ authorityId, userId, userRole, content }) => {
  const {
    data: authorities,
    isSuccess,
    isLoading
  } = useApiGetAuthoritiesWithRoles(authorityId, userId, userRole, { staleTime: 120000, enabled: !!userId })

  return (
    <Paper title={content.myAccess || '...'}>
      <Wrapper>
        <Container>
          {isLoading && (
            <>
              <Row>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
                <Column>
                  <Skeleton width={100} />
                </Column>
              </Row>
            </>
          )}
          {isSuccess &&
            authorities.map(item => (
              <Authority
                key={item.authorityId}
                authorityId={item.authorityId}
                authorityName={item.authorityName}
                secondAuthorityName={item.secondAuthorityName}
                readOnly={true}
              >
                <Text
                  as='span'
                  dataAttributes={{ 'data-content-key': `user_permission_role_${item.defaultSelectedId}` }}
                >
                  {content?.[`user_permission_role_${item.defaultSelectedId}`]}
                </Text>
              </Authority>
            ))}
        </Container>
      </Wrapper>
    </Paper>
  )
}

export default AccountAccess
