import { useState, useEffect, memo } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import { SteppedProgress } from '@bp-digital/component-stepped-progress'
import useContent from 'hooks/useContent'
import UserDetails from './components/UserDetails'
import { useRootStore } from 'contexts/StoreContext'
import AccessSetup from './components/AccessSetup'
import PermissionRoles from './components/PermissionRoles'
import Summary from './components/Summary'
import InvitationSent from './components/InvitationSent'
import getCountryCodeList from 'helpers/getCountryCodeList'
import { Alert } from '@bp-digital/component-alert'
import { AlertWrapper } from './AddUserPage.styled'
import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN } from 'src/constants/routes'

const AddUserPage = () => {
  const content = useContent('access-users')
  const steps = [
    content?.add_user_steps_user_details || 'add_user_steps_user_details',
    content?.add_user_steps_access || 'add_user_steps_access',
    content?.add_user_steps_permissions || 'add_user_steps_permissions',
    content?.add_user_steps_summary || 'add_user_steps_summary'
  ]
  const { userStore, accessAdminUserStore } = useRootStore()
  const { user, selectedAuthorityId } = userStore
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [authoritiesWithRoles, setAuthoritiesWithRoles] = useState([])
  const [alert, setAlert] = useState({
    visible: false,
    text: '',
    state: ''
  })

  useEffect(() => {
    if (!user) userStore.getUserDetails()
  }, [user, userStore])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const selectedAuthorites = authoritiesWithRoles.map(authority => ({
        roleId: authority.selectedRole.id,
        isCustom: false,
        category: [],
        authorityId: authority.authorityId
      }))

      // changing lambda functions to microservices by naveen as of task 6205097
      const result = await axios({
        method: 'put',
        url: '/api/users/users',
        data: {
          group: [],
          parent: [],
          Authority: selectedAuthorites,
          user: {
            uId: null,
            userName: formData?.userDetails.email,
            ...formData?.userDetails
          }
        }
      })

      if (result.status === 200) {
        accessAdminUserStore.reset()
        setActiveStep(4)
      }
    } catch (e) {
      setAlert({
        visible: true,
        text: content?.error_default_message || 'error_default_message',
        state: 'error'
      })
      console.warn(e)
    }
    setIsLoading(false)
  }

  const handleBack = () => setActiveStep(activeStep - 1)

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <UserDetails
            authorityId={selectedAuthorityId}
            countryList={getCountryCodeList}
            setFormData={setFormData}
            formData={formData}
            setActiveStep={setActiveStep}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            content={content}
          />
        )
      case 1:
        return (
          <AccessSetup
            authorityId={selectedAuthorityId}
            userId={user?.uId}
            setFormData={setFormData}
            formData={formData}
            setAuthoritiesWithRoles={setAuthoritiesWithRoles}
            setActiveStep={setActiveStep}
            onBack={handleBack}
            userRole={user?.role[0]}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            content={content}
          />
        )
      case 2:
        return (
          <PermissionRoles
            onBack={handleBack}
            setActiveStep={setActiveStep}
            setFormData={setFormData}
            formData={formData}
            setAuthoritiesWithRoles={setAuthoritiesWithRoles}
            authoritiesWithRoles={authoritiesWithRoles}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            content={content}
          />
        )
      case 3:
        return (
          <Summary
            userDetails={formData?.userDetails}
            selectedAuthorities={authoritiesWithRoles}
            onSubmit={handleSubmit}
            onBack={handleBack}
            isLoading={isLoading}
            content={content}
          />
        )
      case 4:
        return <InvitationSent content={content} />
      default:
        return ''
    }
  }

  const MemoizedSteppedProgress = memo(SteppedProgress)

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.add_a_user || 'add_a_user'}
        subtitle={steps[activeStep]}
        breadcrumbs={[
          { to: '/', title: 'User administration', ariaLabel: 'User administration' },
          { title: 'Add a user', ariaLabel: 'Add a user' }
        ]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <MemoizedSteppedProgress items={steps} selectedIndex={activeStep} />
        <AlertWrapper $isVisible={alert.visible}>
          <Alert
            text={alert.text}
            visible={alert.visible}
            state={alert.state}
            iconPrefix
            onClose={() => setAlert({ ...alert, visible: false })}
          />
        </AlertWrapper>
        {renderSteps()}
      </InnerPageWrapper>
    </>
  )
}

export default observer(AddUserPage)
