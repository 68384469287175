import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import { Button } from '@bp-digital/component-button'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { ROUTE_DASHBOARD, ROUTE_LOGOUT } from 'src/constants/routes'
import { AdminMenuContainer, BackButtonWrapper, ScenarioWrapper, DropDownWrapper } from '../AdminPage/AdminPage.style'
import { Icon } from '@bp-digital/component-icon'
import AdminDecisionPanel from '../AdminPage/components/AdminDecisionPanel/AdminDecisionPanel'
import { DropDown } from '@bp-digital/component-drop-down'

const scenarios = [
  {
    code: 'email-not-found',
    title: 'No result and searchterm is not of authorityId format'
  },
  {
    code: 'authorityid-not-found',
    title: 'No result and searchterm is of authorityId format'
  },
  {
    code: 'user-is-active',
    title: 'User is found, status is active'
  },
  {
    code: 'user-is-invited-w-uniqueId',
    title: 'User is found, status is Invited or Link Expired, has a unique Id'
  },
  {
    code: 'user-is-invited-no-uniqueId',
    title: 'User is found, status is Invited, has no unique Id'
  },
  {
    code: 'user-is-expired-no-uniqueId',
    title: 'User is found, status is Link Expired, has no unique Id'
  },
  {
    code: 'user-unknown-issue',
    title: '[none of the above]'
  }
]

const AdminScenariosPage = () => {
  const { userStore } = useRootStore()
  const [selectedScenario, setSelectedScenario] = useState('email-not-found')
  const history = useHistory()
  const logout = async () => {
    window.isLoggingOut = true
    history.push(ROUTE_LOGOUT)
  }

  if (!userStore.isAdmin) return <Redirect to={ROUTE_DASHBOARD} />

  return (
    <>
      <AdminMenuContainer>
        <Button
          appearance='tertiary'
          iconName='User'
          onClick={async () => {
            await logout()
          }}
          dataAttributes={{ 'data-testid': 'button-logout' }}
        >
          Logout
        </Button>
      </AdminMenuContainer>

      <PageHeader
        title='Admin Scenarios'
        subtitle='Listing defined scenarios for review & training. Should not be used outside those as buttons are disabled'
      >
        <BackButtonWrapper data-testid={`charger-details-back`}>
          <Link onClick={history.goBack} to='/admin'>
            <Icon name='LeftSmall' size='md' />
            Back
          </Link>
        </BackButtonWrapper>
      </PageHeader>

      <DropDownWrapper>
        <DropDown
          id='table-pagesize'
          selectedId={selectedScenario}
          onChange={id => {
            setSelectedScenario(id)
          }}
          options={scenarios.map(scenario => ({ id: scenario.code, label: scenario.title }))}
        />
      </DropDownWrapper>

      <>
        {scenarios.map(
          scenario =>
            scenario.code == selectedScenario && (
              <ScenarioWrapper>
                <div>
                  <span>Data condition:</span>
                  {scenario.title}
                </div>
                <div>
                  <AdminDecisionPanel initialStatementCode={scenario.code} />
                </div>
              </ScenarioWrapper>
            )
        )}
      </>
    </>
  )
}

export default observer(AdminScenariosPage)
