import axios from 'axios'
import { useQuery, QueryOptions } from 'react-query'

const QUERY_KEY = 'cards/restriction-profiles'

type paramsType = {
  authorityId: string
  searchText: null
  serviceCode: string[]
}

const queryFn = async (params: paramsType) => {
  const response = await axios({
    method: 'get',
    url: '/api/cards/profile/baseprofiles',
    params: {
      accessLevel: 'A',
      accessLevelCode: params.authorityId,
      searchText: params.searchText,
      serviceCode: params.serviceCode
    }
  })

  return response.data?.baseProfiles
}

const useApiGetRestrictionBaseProfiles = (params: paramsType, options: QueryOptions = {}) => {
  return useQuery(QUERY_KEY, () => queryFn(params), options)
}

export default useApiGetRestrictionBaseProfiles
