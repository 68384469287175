const STANDARD_MAX = 6
const FAST_MAX = 43
const RAPID_MAX = 149

const getChargerType = (power = 0, content = {}) => {
  if (!power) return '-'

  const powerAsInt = parseInt(power)

  if (powerAsInt < STANDARD_MAX) {
    return content.cpo_charger_type_standard || 'Standard'
  }

  if (powerAsInt < FAST_MAX) {
    return content.cpo_charger_type_fast || 'Fast'
  }

  if (powerAsInt < RAPID_MAX) {
    return content.cpo_charger_type_rapid || 'Rapid'
  }

  if (powerAsInt > RAPID_MAX) {
    return content.cpo_charger_type_ultra_fast || 'Ultra fast'
  }
}

export default getChargerType
