const replaceVariablesInString = (string = '', variables = {}) => {
  const keys = Object.keys(variables)

  keys.forEach(key => {
    if (string.includes(`{{${key}}}`)) {
      const value = variables[key]
      string = string.replace(new RegExp(`{{${key}}}`, 'g'), value)
    }
  })

  return string
}

export default replaceVariablesInString
