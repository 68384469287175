import ReactDOM from 'react-dom'
import { useState } from 'react'
import axios from 'axios'
import { Modal } from '@bp-digital/component-modal'
import { PinEntry } from '@bp-digital/component-pin-entry'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { StateMessage } from '@bp-digital/component-state-message'
import { getIsWeakPin } from 'helpers/getIsWeakPin'
import { MessageContainer, PinForm } from './EditCardPin.styled'
import { useRootStore } from 'src/contexts/StoreContext'
import { EMAILADDRESS } from 'constants/onefleetmigrated'
import { Text } from '@bp-digital/component-typography'

const root = document.getElementById('root')

const EditCardPin = ({ cardId, onClose, content, pinMethod, pinReq, setToastData, authorityId }) => {
  const [choosingOwnPin, setChoosingOwnPin] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isWeakPin, setIsWeakPin] = useState(false)
  const [isNonNumbericPin, setIsNonNumbericPin] = useState(false)
  const [updateFailed, setUpdateFailed] = useState(false)
  const [pin, setPin] = useState('')
  const { viewManageCardStore, userStore: {  onefleetmigratedstatus } } = useRootStore()
  const noChangepinallowed = !(onefleetmigratedstatus > 2)
  const warningText = updateFailed
    ? content.manage_cards_pin_failure_update
    : choosingOwnPin
    ? content.manage_cards_single_pin_remeber
    : content.manage_cards_edit_full_details_general_random_pin_warning
  const hasPinByPost = pinMethod === '0'

  return ReactDOM.createPortal(
    <>
    {noChangepinallowed ? (
    <Modal
      title={content.manage_cards_edit_pin}
      primaryAction={{
        text: content.manage_cards_save,
        onClick: async () => {
          setIsSaving(true)

          try {
            const response = await axios({
              method: 'patch',
              url: '/api/cards/cardpin/updatepin',
              params: {
                accessLevel: 'A',
                accessLevelCode: authorityId
              },
              data: {
                pinDetails: [
                  {
                    cardId,
                    pin,
                    pinReq,
                    pinDerivation: hasPinByPost && choosingOwnPin ? '1' : pinMethod
                  }
                ]
              }
            })

            if (response.data && response.data.success) {
              onClose()
              setToastData({
                id: Math.floor(Math.random() * 9999),
                position: 'right-bottom',
                autoClose: 5000,
                hasCloseButton: false,
                title: content.manage_cards_single_pin_success_update,
                icon: 'CheckLargeSolid'
              })
              viewManageCardStore.getCards({ forceRefresh: true })
            } else {
              setIsSaving(false)
              setUpdateFailed(true)
            }
          } catch (e) {
            console.warn('Pin failed to update', e)
            setUpdateFailed(true)
            setIsSaving(false)
          }
        },
        iconName: 'Save',
        isLoading: isSaving,
        disabled: isWeakPin || pin.length < 4 || isNonNumbericPin,
        dataAttributes: {
          'data-testid': 'button-edit-pin-save',
          'data-content-key': 'manage_cards_save'
        }
      }}
      secondaryAction={{
        text: content.manage_cards_actions_cancel,
        onClick: onClose,
        iconName: 'RemoveLarge',
        appearance: 'tertiary',
        dataAttributes: {
          'data-testid': 'button-edit-pin-cancel',
          'data-content-key': 'manage_cards_actions_cancel'
        }
      }}
      onDismiss={onClose}
      visible
      size='md'
    >
      {hasPinByPost && (
        <RadioButtonGroup
          id='pin-method'
          horizontal
          label={content.manage_cards_single_pin_subheading}
          defaultSelectedId='choose'
          onChange={() => setChoosingOwnPin(!choosingOwnPin)}
          options={[
            {
              id: 'choose',
              label: content.manage_cards_pin_pin_choose_own
            },
            {
              id: 'post',
              label: content.manage_cards_pin_pin_random
            }
          ]}
        />
      )}

      {choosingOwnPin && (
        <PinForm>
          <PinEntry
            label={!hasPinByPost ? content.manage_cards_pin_pin_choose_own : undefined}
            error={isWeakPin || isNonNumbericPin}
            errorMessage={
              isNonNumbericPin
                ? content?.manage_cards_edit_bpMe_pin_numeric_only || 'manage_cards_edit_bpMe_pin_numeric_only'
                : content?.manage_cards_edit_bpMe_pin_weak_pin || 'manage_cards_edit_bpMe_pin_weak_pin'
            }
            onChange={(pin = '') => {
              setUpdateFailed(false)

              if (pin.length === 4) {
                const isWeak = getIsWeakPin(pin)
                const isNotNumeric = () => !pin.match(/^[0-9]+$/)

                setIsWeakPin(isWeak)
                setIsNonNumbericPin(isNotNumeric)
              }

              setPin(pin)
            }}
          />
        </PinForm>
      )}

      <MessageContainer>
        <StateMessage
          text={warningText}
          state={updateFailed ? 'danger' : 'info'}
          iconName={updateFailed ? 'Alert' : 'Info'}
        />
      </MessageContainer>
    </Modal>
    ) : (<Modal
      title={content.manage_cards_edit_pin}
      primaryAction={{
        text: content.manage_cards_actions_cancel,
        onClick: onClose,
        appearance: 'tertiary',
        dataAttributes: {
          'data-testid': 'button-stop-card-cancel',
          'data-content-key': 'manage_cards_actions_cancel'
        }
      }}
      visible
      size='sm'
    >
      <Text>
        {content?.onefleetmigrated_block_updatepin_message || 'content?.onefleetmigrated_block_updatepin_message'}
        <a href ={`mailto:${EMAILADDRESS}`}>{EMAILADDRESS}</a>
      </Text>
    </Modal>)}
    </>,
    root
  )
}

export default EditCardPin
