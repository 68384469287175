export const getOdometerReading = (restriction = {}, distanceUnit = 'KMS', content = {}, keyPrefix) => {
  const limits = []

  limits.push({
    label: content[`${keyPrefix}max_distance`],
    values: [`${restriction.maximumDistance} ${distanceUnit}`]
  })

  return limits
}
