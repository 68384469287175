import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 0;

  @media only screen and ${breakpoints.device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

export const AuthorityMessage = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    letter-spacing: var(--typography-spacing-compact);
    font-weight: 500;
    font-stretch: 100%;

    &:last-child {
      font-weight: bold;
      padding-top: 10px;
    }
  }
`
