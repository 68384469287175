import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

interface PayloadType {
  transactionId: string
  [key: string]: unknown
}

const mutationFn = async (payload: PayloadType) => {
  payload.fieldValue = payload.fieldValue ?? toString()
  const response = await axios.post(`/api/transactions/transactions/${payload.transactionId.substring(0, 36)}`, payload)
  return response.data
}

const useApiPostTransactionUpdate = (transactionId: string, onSuccess: () => void, onError: () => void) => {
  const queryClient = useQueryClient()

  return useMutation(mutationFn, {
    mutationKey: 'transaction-update',
    onSuccess: (): void => {
      queryClient.invalidateQueries(`transaction/id-${transactionId}`)
      onSuccess()
    },
    onError: (error: AxiosError): void => {
      const { message } = error
      console.error(message)
      onError()
    }
  })
}

export default useApiPostTransactionUpdate
