import {
  ROUTE_DASHBOARD,
  ROUTE_INVOICES,
  ROUTE_TRANSACTIONS,
  ROUTE_CARDS,
  ROUTE_ACCESS_ADMINISTRATION_USER,
  ROUTE_ACCESS_ADMINISTRATION_GROUP,
  ROUTE_CARDS_ORDER,
  ROUTE_CARDS_REISSUE,
  ROUTE_CARDS_RESTRICTIONS,
  ROUTE_CARDS_SPENDING_LIMITS,
  ROUTE_CARDS_VIEW,
  ROUTE_CHARGE_POINTS,
  ROUTE_DEPOT_PERMISSIONS,
  ROUTE_CHARGE_HISTORY,
  ROUTE_EV_PRICE_LIST
} from 'src/constants/routes'
import {
  PERMISSION_TO_VIEW_TRANSACTIONS,
  PERMISSION_TO_VIEW_INVOICES,
  PERMISSION_TO_VIEW_CARDS,
  PERMISSION_TO_VIEW_USER_MENU,
  PERMISSION_TO_VIEW_GROUP_MENU,
  PERMISSION_TO_VIEW_EV_MENU
} from 'src/constants/permissions'
import { useRootStore } from 'contexts/StoreContext'

export const OVERVIEW_NODE = content => {
  return {
    title: content.menu_top_menu_title_dashboard || 'Overview',
    icon: 'Template',
    path: ROUTE_DASHBOARD,
    requiredPermissions: [
      PERMISSION_TO_VIEW_TRANSACTIONS,
      PERMISSION_TO_VIEW_INVOICES,
      PERMISSION_TO_VIEW_CARDS,
      PERMISSION_TO_VIEW_USER_MENU
    ],
    testId: 'menu-dashboard'
  }
}

export const TRANSACTIONS_PARENT = content => {
  const evPriceListScreen = true

  const childNodes = [
    {
      title: content.sub_menu_invoice_transcations_transcations || 'Transactions',
      path: ROUTE_TRANSACTIONS,
      testId: 'menu-transactions'
    },
    {
      title: content.sub_menu_invoice_transcations_invoice || 'Invoices',
      path: ROUTE_INVOICES,
      testId: 'menu-invoices',
      requiredPermissions: [PERMISSION_TO_VIEW_INVOICES]
    }
  ]

  if (evPriceListScreen) {
    childNodes.push({
      title: content.sub_menu_invoice_transcations_ev_price_list || 'EV-PriceList',
      path: ROUTE_EV_PRICE_LIST,
      testId: 'menu-evpricelist'
    })
  }

  return {
    title: content.menu_top_menu_title_invoice_transcations || 'Transactions & Invoices',
    icon: 'BulletList',
    path: ROUTE_TRANSACTIONS,
    testId: 'menu-transactions-invoices',
    requiredPermissions: [PERMISSION_TO_VIEW_TRANSACTIONS],
    children: childNodes
  }
}

export const CARD_ADMINISTRATION_PARENT = content => {
  const { userStore } = useRootStore()
  const allowwritepermissions = !(userStore.onefleetmigratedstatus > 0)
  const ackStatus = userStore.isAck
  // const childNodes = [ORDER_CARD_NODE(content), VIEW_CARDS_NODE(content), CARD_REISSUE_NODE(content), CARD_RESTRICTION_PROFILES_NODE(content), ACCOUNT_SPENDING_LIMITS_NODE(content)]
  const childNodes = [
    {
      title: content.sub_menu_card_administration_order || 'Order a new card',
      path: ROUTE_CARDS_ORDER,
      testId: 'menu-cards-order'
    },
    {
      title: content.sub_menu_card_administration_view || 'View/manage cards',
      path: ROUTE_CARDS_VIEW,
      testId: 'menu-cards-view'
    },
    {
      title: content.sub_menu_card_administration_due_reissue || 'Cards due for reissue',
      path: ROUTE_CARDS_REISSUE,
      testId: 'menu-cards-reissue'
    }
  ]
  if (allowwritepermissions && ackStatus != 'N') {
    childNodes.push({
      title: content.sub_menu_card_administration_restriction_profile || 'Restriction profiles',
      path: ROUTE_CARDS_RESTRICTIONS,
      testId: 'menu-cards-restrictions'
    })
  }
  if (allowwritepermissions) {
    childNodes.push({
      title: content.sub_menu_card_administration_spending_limit || 'Account spending limits',
      path: ROUTE_CARDS_SPENDING_LIMITS,
      testId: 'menu-cards-spending-limits'
    })
  }
  return {
    title: content.menu_top_menu_title_card_administration || 'Card Administration',
    icon: 'CvcCvv',
    path: ROUTE_CARDS,
    testId: 'menu-cards',
    requiredPermissions: [PERMISSION_TO_VIEW_CARDS],
    children: childNodes
  }
}

export const ACCESS_ADMINISTRATION_PARENT = content => {
  const childNodes = [ADMIN_USER_NODE(content), ADMIN_GROUP_NODE(content)]
  return {
    title: content.menu_top_menu_title_access_administration || 'Access Administration',
    icon: 'UserSettingsSlider',
    path: ROUTE_ACCESS_ADMINISTRATION_USER,
    requiredPermissions: [PERMISSION_TO_VIEW_USER_MENU],
    testId: 'menu-access-admin',
    children: childNodes
  }
}

const ADMIN_USER_NODE = content => ({
  title: content.sub_menu_access_administration_user || '',
  path: ROUTE_ACCESS_ADMINISTRATION_USER,
  testId: 'menu-access-admin-user',
  requiredPermissions: [PERMISSION_TO_VIEW_USER_MENU]
})

const ADMIN_GROUP_NODE = content => ({
  title: content.sub_menu_access_administration_groups || '',
  path: ROUTE_ACCESS_ADMINISTRATION_GROUP,
  testId: 'menu-access-admin-group',
  requiredPermissions: [PERMISSION_TO_VIEW_GROUP_MENU]
})

export const DEPOT_MANAGEMENT_PARENT = content => {
  const childNodes = [CHARGE_POINTS_NODE(content), DEPOT_PERMISSIONS_NODE(content), CHARGING_HISTORY_NODE(content)]
  return {
    title: content.menu_top_menu_title_depot_management || 'Depot Management',
    icon: 'WorldPin',
    path: '',
    requiredPermissions: [PERMISSION_TO_VIEW_EV_MENU],
    testId: 'menu-depot-management',
    children: childNodes
  }
}

export const CHARGING_HISTORY_NODE = content => ({
  title: content.sub_menu_charge_point_operations_charging_history || 'Charging History',
  icon: 'SpreadsheetGeneric',
  path: ROUTE_CHARGE_HISTORY,
  requiredPermissions: [PERMISSION_TO_VIEW_EV_MENU],
  testId: 'menu-charging-history'
})

export const CHARGE_POINTS_NODE = content => ({
  title: content.sub_menu_view_manage_charge_point_operations || 'View / Manage Charge Points',
  icon: 'WorldPin',
  path: ROUTE_CHARGE_POINTS,
  requiredPermissions: [PERMISSION_TO_VIEW_EV_MENU],
  testId: 'menu-charge-points-view'
})

export const DEPOT_PERMISSIONS_NODE = content => ({
  title: content.menu_top_menu_title_set_depot_permissions || 'Set Depot Permissions',
  icon: 'Security',
  path: ROUTE_DEPOT_PERMISSIONS,
  requiredPermissions: [PERMISSION_TO_VIEW_EV_MENU],
  testId: 'menu-depot-permissions'
})
