import { memo } from 'react'
import { Table } from '@bp-digital/component-table'
import Paper from 'components/surfaces/Paper'

const ChargePointConnectors = ({ content, chargePoints = [] }) => {
  // const chargePoint = site?.chargePoints?.[0] || {}

  return (
    <Paper title={content?.cpo_charger_details_charge_points_information_title || '...'}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header role='columnheader' scope='col' data-testid='col-header-connector-type'>
              {content?.cpo_charger_details_charge_points_information_connector_type_column || '...'}
            </Table.Header>
            <Table.Header role='columnheader' scope='col' data-testid='col-header-status-id'>
              {content?.cpo_charger_details_status_label || '...'}
            </Table.Header>
            <Table.Header role='columnheader' scope='col' data-testid='col-header-power'>
              {content?.cpo_charger_details_charge_points_information_power_column || '...'}
            </Table.Header>
            <Table.Header role='columnheader' scope='col' data-testid='col-header-connectors-info'>
              {content?.cpo_charger_details_charge_points_information_evse_column || '...'}
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body stripedRowStyle>
          {chargePoints.map(point =>
            point?.evses?.map(evse =>
              evse?.connectors?.map(connector => (
                <Table.Row key={connector.id}>
                  <Table.Cell>
                    {content?.[`cpo_connector_type_${connector.standard}`] ||
                      content?.cpo_charger_details_unknown ||
                      'cpo_charger_details_unknown'}
                  </Table.Cell>
                  <Table.Cell>
                    {content[`cpo_charger_status_${connector.status.toUpperCase()}`] ||
                      content?.cpo_charger_status_UNKNOWN ||
                      '-'}
                  </Table.Cell>
                  <Table.Cell>{connector.power ? connector.power + 'kW' : '-'}</Table.Cell>
                  <Table.Cell>{evse.evseId}</Table.Cell>
                </Table.Row>
              ))
            )
          )}
        </Table.Body>
      </Table>
    </Paper>
  )
}

export default memo(ChargePointConnectors)
