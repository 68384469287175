import { Truncated } from 'src/styles/common.styled'
import { Transaction } from '../../../TransactionMap.types'
import get from 'lodash/get'

export interface TransMapAddressProps {
  transaction: Transaction
}

export const TransMapAddress = ({ transaction }: TransMapAddressProps) => {
  const delimit = (trans: Transaction, separator: string, path: string, ...keys: string[]) => {
    return keys
      .map(k => get(trans.mapDetails, `${path}.${k}`, ''))
      .filter(k => !!k)
      .join(separator)
  }

  return (
    <>
      <Truncated>{delimit(transaction, ', ', 'address', 'addressLine1', 'addressLine2', 'addressLine3')}</Truncated>
      <Truncated>{delimit(transaction, ', ', 'address', 'townCity', 'postCode')}</Truncated>
    </>
  )
}
