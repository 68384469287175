import { Heading } from '@bp-digital/component-typography'
import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const Days = styled.ol`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.lg};
`

export const DayHeading = styled(Heading)`
  margin: 0 0 ${CssVars.structure.spacing.xxl1};
`
export const SliderContainer = styled.div`
  margin: ${CssVars.structure.spacing.md} 0 0 ${CssVars.structure.spacing.lg};

  > div {
    width: calc(100% - ${CssVars.structure.spacing.lg});
  }
`

export const CustomControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${CssVars.structure.spacing.xxl4};
`
