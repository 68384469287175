import { format } from 'light-date'
import useApiGetChargerTransactions from 'hooks/api/useApiGetChargerTransactions'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { TableTitle } from './ChargePointSessions.styled'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import { getHoursAndMinutes } from 'helpers/getHoursAndMinutes'
import getDateSeparator from 'helpers/getDateSeparator'
import { useMemo } from 'react'

const ChargePointSessions = ({
  content = {},
  selectedHierarchy,
  siteId,
  siteName,
  chargePointName,
  chargePointId,
  type = 'site',
  testId = 'sessions-testid',
  noResultsMessage
}) => {
  const { data, isLoading } = useApiGetChargerTransactions(
    {
      accessLevel: selectedHierarchy?.accessLevel,
      accessLevelCode: selectedHierarchy?.accessLevelCode,
      siteId,
      chargePointId
    },
    {
      enabled: !!selectedHierarchy?.accessLevelCode && (!!siteId || !!chargePointId)
    }
  )
  const chargeData = data?.chargeData || []

  const sortStringDate = (rowA, rowB, columnId, _) => {
    const valA = rowA.values[columnId]
    const valB = rowB.values[columnId]

    if (valA === valB) return 0

    const separator = getDateSeparator(valA)
    const splitA = valA.split(separator)
    const splitB = valB.split(separator)

    if (splitA[2] < splitB[2]) return -1
    if (splitA[2] > splitB[2]) return 1

    if (splitA[1] < splitB[1]) return -1
    if (splitA[1] > splitB[1]) return 1

    if (splitA[0] < splitB[0]) return -1
    if (splitA[0] > splitB[0]) return 1
  }

  const tableData = chargeData.map((charge, i) => {
    const totalTimeAsHoursAndMinutes = getHoursAndMinutes(charge?.totalTime)
    const formattedTotalTime = `${
      replaceVariablesInString(content?.cpo_charger_details_insight_average_duration_hours, {
        hours: totalTimeAsHoursAndMinutes.hours
      }) || 'hours'
    } ${
      replaceVariablesInString(content?.cpo_charger_details_insight_average_duration_minutes, {
        minutes: totalTimeAsHoursAndMinutes.minutes
      }) || 'mins'
    }`
    return {
      type: 'MASTER',
      id: `${charge.siteId}_${i}`,
      date: format(new Date(charge.sessionStart), '{dd}/{MM}/{yy}'),
      key: `${charge.siteId}_${i}`,
      chargeType: charge.chargePointId,
      rfid: charge.rfid,
      started: format(new Date(charge.sessionStart), '{HH}:{mm}'),
      ended: format(new Date(charge.sessionEnd), '{HH}:{mm}'),
      duration: formattedTotalTime,
      totalTime: charge.totalEnergy.toFixed(2) + 'kWh'
    }
  })

  const columns = [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    {
      accessor: 'date',
      Header: content?.cpo_site_transactions_date_column || '...',
      sortType: useMemo(() => sortStringDate, [])
    },
    { accessor: 'chargeType', Header: content.cpo_site_transactions_charger_id_column || '...' },
    { accessor: 'rfid', Header: content?.cpo_site_transactions_rfid_column || '...' },
    { accessor: 'started', Header: content?.cpo_site_transactions_started_column || '...', disableSortBy: true },
    { accessor: 'ended', Header: content?.cpo_site_transactions_ended_column || '...', disableSortBy: true },
    { accessor: 'duration', Header: content?.cpo_site_transactions_duration_column || '...' },
    { accessor: 'totalTime', Header: content?.cpo_site_transactions_total_energy_column || '...' }
  ]

  var headerContent = {
    siteName,
    siteId,
    chargePointId,
    chargerName: chargePointName
  }

  if (type === 'site') {
    headerContent = {
      siteName
    }
  }

  return (
    <>
      <TableTitle data-testid={testId}>
        {replaceVariablesInString(content[`cpo_${type}_transactions_title`] || '', headerContent)}
      </TableTitle>
      <ReactTable
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        noResultsMessage={noResultsMessage}
        hideCheckboxColumn={true}
      />
    </>
  )
}

export default ChargePointSessions
