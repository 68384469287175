import { Redirect, useHistory, useParams } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@bp-digital/component-button'
import useContent from 'hooks/useContent'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import SpendingLimit from 'pages/Cards/Restrictions/components/EditRestriction/EditRestrictionSpendingLimit'
import Odometer from 'pages/Cards/Restrictions/components/EditRestriction/EditRestrictionOdometer'
import ProductsAndServices from 'pages/Cards/Restrictions/components/EditRestriction/EditRestrictionProductsAndServices'
import SiteLocations from 'pages/Cards/Restrictions/components/EditRestriction/EditRestrictionSiteLocations'
import DayAndTime from 'pages/Cards/Restrictions/components/EditRestriction/EditRestrictionDayAndTime'
import useApiGetRestrictionProfile from 'hooks/api/useApiGetRestrictionProfile'
import { ROUTE_CARDS_RESTRICTIONS, ROUTE_ADMIN } from 'constants/routes'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'
import { useRootStore } from 'contexts/StoreContext'

// Cheerio-owned "type" to content key
const contentKeyMapper = {
  'spending-limits': 'restriction_edit_spending',
  sites: 'restriction_edit_site',
  'products-and-services': 'restriction_edit_product',
  odometer: 'restriction_edit_odometer',
  'day-and-time': 'restriction_edit_day_time'
}

const EditRestrictionProfilePage = () => {
  const content = useContent('cards-restrictions') || {}
  const history = useHistory()
  const { userStore } = useRootStore()
  const { profileId, type } = useParams()
  const authorityId = userStore.selectedAuthorityId
  const { data: profileDetails, isLoading } = useApiGetRestrictionProfile(profileId, {
    enabled: !!profileId
  })
  const { data: authorityDetails, isLoading: isGettingAuthority } = useApiGetAuthorityDetails(authorityId, {
    enabled: !!authorityId
  })
  const restriction = profileDetails?.restrictiondetails || {}
  const sharedEditProps = {
    profileId,
    profileName: restriction.profileName
  }

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  if (profileId && !type) {
    return <Redirect to={`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`} />
  } else if (!profileId) {
    return <Redirect to={ROUTE_CARDS_RESTRICTIONS} />
  }

  const renderEditRestriction = type => {
    if (type === 'spending-limits') {
      return (
        <SpendingLimit
          authorityId={authorityId}
          content={content}
          data={{
            ...sharedEditProps,
            spendingLimitRestriction: restriction.spendingLimitRestriction
          }}
          currency={authorityDetails.restrictions?.currency}
        />
      )
    } else if (type === 'odometer') {
      return (
        <Odometer
          authorityId={authorityId}
          content={content}
          data={{
            ...sharedEditProps,
            distanceUnit: authorityDetails?.restrictions?.distanceUnit,
            accept: restriction?.odometerAndDistanceRestriction?.acceptOdometerViolation,
            alert: restriction?.odometerAndDistanceRestriction?.alertOdometerViolation,
            maximumDistance: restriction?.odometerAndDistanceRestriction?.maximumDistance
          }}
        />
      )
    } else if (type === 'products-and-services') {
      return (
        <ProductsAndServices
          authorityId={authorityId}
          content={content}
          data={{
            ...sharedEditProps,
            currency: authorityDetails?.restrictions?.currency,
            serviceCode: authorityDetails?.serviceCode,
            productAndServicesRestriction: restriction.productAndServicesRestriction
          }}
        />
      )
    } else if (type === 'sites') {
      return (
        <SiteLocations
          authorityId={authorityId}
          content={content}
          data={{
            ...sharedEditProps,
            customerId: restriction.customerId,
            sitelocationRestrictions: restriction.sitelocationRestrictions,
            region:
              authorityDetails?.configuration?.region?.region?.shown === 'Y' ? 'Nationalinternational' : 'National',
            countryCode: authorityDetails?.countryCode,
            opuCode: authorityDetails?.opuCode,
            customerNumber: String(authorityDetails?.customerNumber)
          }}
        />
      )
    } else if (type === 'day-and-time') {
      return (
        <DayAndTime
          authorityId={authorityId}
          content={content}
          data={{
            ...sharedEditProps,
            dayTimeRestriction: restriction.dayTimeRestriction
          }}
        />
      )
    }

    // No type matched
    return <Redirect to={`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`} />
  }

  return (
    <>
      <PageHeader
        title={content[contentKeyMapper[type]] || 'Edit'}
        subtitle={restriction.profileName || '...'}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <Button
          dataAttributes={{ 'data-content-key': 'masthead_back', 'data-testid': 'button-back' }}
          size='lg'
          iconName='LeftLarge'
          appearance='tertiary'
          onClick={history.goBack}
        >
          {content?.masthead_back}
        </Button>

        {isLoading || isGettingAuthority ? <Skeleton width={200} height={60} /> : renderEditRestriction(type)}
      </InnerPageWrapper>
    </>
  )
}

export default EditRestrictionProfilePage
