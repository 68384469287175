import styled from 'styled-components'

export const Form = styled.form`
  padding: 0 16px;
  width: 100%;

  > * {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
`

export const Content = styled.p`
  padding: 16px;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  width: auto;

  > * {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const AlertWrapper = styled.div`
  margin: 16px;
`
