import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const MapFlyoutContainer = styled.div<{ $color: string }>`
  width: 300px;
  padding: ${CssVars.structure.spacing.lg};
  background-color: ${CssVars.palette.neutral.white};
  box-shadow: ${CssVars.elevation.light.sm};
  border-radius: ${CssVars.structure.borderRadius.default};
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.lg};
  border-right: solid ${CssVars.structure.spacing.md} ${({ $color }) => $color};
`
