import axios from 'axios'

const GetEVPriceList = async (priceType, date, origin, authorityId) => {
  try {
    const response = await axios.get(
      `/api/transactions/EvPriceList/PriceList?priceType=${priceType}&date=${date}&origin=${origin}&authorityIds=${authorityId}`
    )
    return response.data
  } catch (error) {
    console.error('Error while fetching EV price list', error)
    throw error
  }
}

export default GetEVPriceList
