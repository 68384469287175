import { Button } from '@bp-digital/component-button'
import { ROUTE_TRANSACTIONS } from 'constants/routes'
import { useRootStore } from 'contexts/StoreContext'
import {
  TotalExVat,
  Totals,
  TotalVat,
  WidgetActions,
  WidgetBody,
  WidgetHeader,
  WidgetTitle,
  WidgetTitles,
  ZeroMessage
} from './UninvoicedWidget.styled'

export const ErrorWidget = () => {
  const { contentStore } = useRootStore()
  const content = contentStore.getPage('userdashboard') || {}
  return (
    <>
      <WidgetHeader>
        <WidgetTitles>
          <WidgetTitle>{content?.uninvoiced_widget_title}</WidgetTitle>
        </WidgetTitles>
        <WidgetActions>
          <Button to={ROUTE_TRANSACTIONS}>{content?.transactions_title}</Button>
        </WidgetActions>
      </WidgetHeader>
      <Totals>
        <TotalVat $isOverall>-</TotalVat>
        <TotalExVat>-</TotalExVat>
      </Totals>
      <WidgetBody data-testid='uninvoiced-widget-error'>
        {/* Something went wrong */}
        <ZeroMessage>{content?.transactions_error}</ZeroMessage>
      </WidgetBody>
    </>
  )
}
