import BaseDataStore from '../BaseDataStore'

class GroupAdministrationStore extends BaseDataStore {
  get rows() {
    return this.results.map(user => ({
      type: 'MASTER',
      key: user.uId?.toString(),
      name: user.name,
      email: user.email,
      mobile: user.mobileNumber,
      status: user.status,
      groups: user.groupsCount,
      parents: user.parentsCount,
      authorities: user.authoritiesCount,
      login: new Date(user.lastLogin).toLocaleDateString()
    }))
  }

  async getGroups(searchTerm, page = 1, limit = this.limits?.nextPageLimit || 15) {
    const payload = {
      columnSorting: [],
      limit,
      searchCriteria: null,
      start: (page - 1) * limit + 1
    }

    if (searchTerm) {
      payload.searchColumn = []
      payload.searchText = searchTerm
      payload.searchCriteria = {
        searchColumn: payload.searchColumn,
        searchText: payload.searchText
      }
    }

    await super.getData({
      method: 'post',
      url: '/xapi/bp-ds-userservices-rio-xapi/api/groupadmin',
      payload,
      totalRecordsKey: 'totalRecords',
      responseKey: 'groups',
      page,
      searchTerm
    })
  }
}

export default GroupAdministrationStore
