import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'

const queryFn = async (payload: unknown) => {
  const items: number[] = []
  const reqConfig = {
    params: {
      siteTypeGroupIds: items,
      countryId: items,
      areaIds: ''
    }
  }
  const parsedBody = JSON.parse(JSON.stringify(payload))
  reqConfig.params.siteTypeGroupIds = parsedBody.siteGroupIds ? parsedBody.siteGroupIds[0] : []
  reqConfig.params.countryId = parsedBody.countryId
  reqConfig.params.areaIds = parsedBody.areaIds ?? parsedBody.areaIds
  const response = await axios.get('/api/sites/sites', reqConfig)
  return response.data
}

const useApiGetSites = (onSuccess: (arg0: Response) => void, onError: (message: string) => void) => {
  return useMutation<Response, AxiosError>(queryFn, {
    mutationKey: 'cards/sites',
    onSuccess: response => {
      onSuccess(response)
    },
    onError: error => {
      const { message } = error
      console.error(message)
      onError(message)
    },
    retry: false
  })
}

export default useApiGetSites
