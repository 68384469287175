import { Chip } from '@bp-digital/component-chip'
import { useRootStore } from 'contexts/StoreContext'

import { AUDIT_ACTION_TYPES } from './../AuditFilters/AuditFiltersNodes'

const AuditChip = props => {
  const { userStore } = useRootStore()
  // filter out filtermode and empty filters
  if (props.filterKey == 'filtermode' || !props.value.length) return <></>

  let text
  let iconName
  const myProps = {}
  switch (props.filterKey) {
    case 'actors':
      if (userStore.user.uniqueId == props.value[0]) {
        text = 'My Logs'
      } else {
        text = `${props.value.join(',')}`
      }
      iconName = 'User'
      break
    case 'hierarchy':
      if (props.value[0].substr(0, 1) == 'A') text = `${props.value[0].substr(1)} (Authority)`
      if (props.value[0].substr(0, 1) == 'P') text = `${props.value[0].substr(1)} (Parent)`
      iconName = 'Diamond'
      if (!props.actionPageFilter) myProps.onClick = props.onClick
      break
    case 'useractiontypes':
      text = props.value
        .map(val => {
          const label = AUDIT_ACTION_TYPES.find(action => action.key == val.substr(0, 4))
            ? AUDIT_ACTION_TYPES.find(action => action.key == val.substr(0, 4)).label
            : val
          const success = val.substr(5, 1) == '1' ? 'Success' : 'Failed'
          return `${label} ${success}`
        })
        .join(', ')
      if (text.length > 30) text = `${text.substr(0, 30).trim()}...`
      iconName = 'FileDefault'
      myProps.onClick = props.onClick
      break
    case 'date':
      text = `${props.value.map(date => new Date(date).toLocaleString()).join(' -> ')}`
      iconName = 'Date'
      break
    default:
      text = `${props.value.join(', ')}`
      iconName = 'RemoveLarge'
      myProps.onClick = props.onClick
  }

  return <Chip {...myProps} text={text} iconName={iconName} state='neutral' appearance='primary'></Chip>
}

export default AuditChip
