import PublicLayout from 'components/layout/PublicLayout'
import styled from 'styled-components'
import { Button } from '@bp-digital/component-button'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import { BRAND_ARAL } from 'constants/brands'
import useContent from 'hooks/useContent'
import { EMAILADDRESS,NONPROD_ONEFLEETURL,PROD_ONEFLEETURL } from 'constants/onefleetmigrated'
import { getEnvironment } from 'helpers/getEnvironment'
import { PROD_ENV } from 'constants/environments'
//import replaceVariablesInString from 'helpers/replaceVariablesInString'
import bpLogo from 'assets/logos/bp-logo-colour.svg'
import aralLogo from 'assets/logos/aral-logo.svg'
//import { Text } from '@bp-digital/component-typography'
import { ACCESS_LVL_CODE, USER_FIRSTNAME } from 'constants/localStorage'
import { Heading } from '@bp-digital/component-typography'

const Content = styled.p`
  padding: 2px 0;
  text-align: left;

  > span:first-child {
    font-weight: bold;
  }`

  const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`
export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
`

export const Logo = styled.img`
  width: 100px;
`


const MigratedUsersPage = (props) => {
  const env = getEnvironment()
  const content = useContent('migratedusers')

  const onefleeturl =  env == PROD_ENV ? PROD_ONEFLEETURL : NONPROD_ONEFLEETURL
  const brand = getBrandFromDomain()
//const displayBrand = brand === BRAND_ARAL ? brand.charAt(0).toUpperCase() + brand.slice(1) : brand
//const content = useContent('login')

//let loginTitle = replaceVariablesInString(content?.landing_Welcome_Heading, { brand: displayBrand })
    return (
<PublicLayout>
      <LogoWrapper>
        <Logo src={brand === BRAND_ARAL ? aralLogo : bpLogo} />
      </LogoWrapper>
      {/* <Content>
        <Text size='lg'>{loginTitle}</Text>
      </Content> */}
      <Content>
        <Heading size='h5'> 
             {content?.onefleetmigration_staticpage_header ||  'content?.onefleetmigration_staticpage_header'}	
        </Heading>
        <span>{props.username || localStorage.getItem(USER_FIRSTNAME)} {props.authorityID || localStorage.getItem(ACCESS_LVL_CODE)}</span>
        </Content>
        <Content>{content?.onefleetmigration_staticpage_paragraph1 || 'content?.onefleetmigration_staticpage_paragraph1' }</Content>
        <ButtonWrapper>
        <Button
          href={onefleeturl}
          target='_blank'
          dataAttributes={{ 'data-testid': 'button-onefleet' }}
          >
          {content?.onefleetmigration_staticpage_paragraph6 || 'content?.onefleetmigration_staticpage_paragraph6'}
        </Button>
      </ButtonWrapper>   <p> {content?.onefleetmigration_staticpage_paragraph2 ||'content?.onefleetmigration_staticpage_paragraph2'}</p>
      <Content>
        {content?.onefleetmigration_staticpage_paragraph3 || 'content?.onefleetmigration_staticpage_paragraph3'}
        <a target='_blank' rel='noreferrer' href={onefleeturl}>{onefleeturl}</a>
      </Content>

<Content>
  {content?.onefleetmigration_staticpage_paragraph4||'content?.onefleetmigration_staticpage_paragraph4'}
  <a  href={`mailto:${EMAILADDRESS}`}>{EMAILADDRESS}</a>
</Content>
<Content>
{content?.onefleetmigration_staticpage_paragraph5 ||
'content?.onefleetmigration_staticpage_paragraph5'}
</Content>
    </PublicLayout>)
}


export default MigratedUsersPage