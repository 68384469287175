import { Controller } from 'react-hook-form'
import { DropDown } from '@bp-digital/component-drop-down'
import { getCardTypeContent } from 'pages/Cards/CardOrderPage/helpers'

const CardType = ({ control, content, isLoading, cardTypes, trigger }) => {
  return (
    <Controller
      name='cardDetails.cardTypeId'
      control={control}
      rules={{ required: true }}
      render={({ field: { name, value, onChange } }) => (
        <DropDown
          id={name}
          label={content?.manage_cards_full_details_labels_card_type}
          options={cardTypes.map(({ cardTypeId }) => ({
            id: cardTypeId,
            label: getCardTypeContent(cardTypeId, 'card_type', content)
          }))}
          selectedId={value}
          onChange={id => {
            trigger('cardDetails')
            onChange(id)
          }}
          disabled={isLoading}
        />
      )}
    />
  )
}

export default CardType
