import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const ItemContainer = styled.div<{
  $isSelected: boolean
  $color: string
}>`
  border: solid 1px ${({ $isSelected, $color }) => ($isSelected ? $color : CssVars.palette.controls.stroke)};
  border-left: solid 5px ${({ $color }) => $color};
  padding: ${CssVars.structure.spacing.md};
  border-radius: ${CssVars.structure.borderRadius.default};
  display: flex;
  cursor: pointer;
  gap: 20px;
  :hover {
    background-color: ${CssVars.palette.neutral.lightest};
  }
`

export const ItemIcon = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
`

export const ItemContent = styled.div``

export const ItemSubtext = styled.div`
  color: ${CssVars.palette.neutral.default};
`

export const ItemAmount = styled.div``
