import { Route, Redirect } from 'react-router-dom'
import { EXPIRY_ID, ISONEFLEETMIGRATED } from 'constants/localStorage'
import MigratedUsersPage from 'pages/Authentication/MigratedUsersPage'

export interface AuthenticatedRouteProps {
  children: Node
}

const AuthenticatedRoute = ({ children, ...props }: AuthenticatedRouteProps) => {
  const isLoggedIn = !!window.localStorage.getItem(EXPIRY_ID)
  if( isLoggedIn && window.localStorage.getItem(ISONEFLEETMIGRATED) == 'true'){
    return <MigratedUsersPage /> 
  }
  return <Route {...props} render={() => (isLoggedIn ? children : <Redirect to='/login' />)} />
}

export default AuthenticatedRoute
