import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { format } from 'light-date'
import xlsx from 'json-as-xlsx'
import debounce from 'lodash.debounce'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import PreferencesEditor from 'src/components/dataDisplay/PreferencesEditor'
import { useRootStore } from 'src/contexts/StoreContext'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import getValueOrFallback from 'src/helpers/getValueOrFallback'
import getQueryParameter from 'helpers/getQueryParameter'
import ViewCardDetail from './components/ViewCardDetail'
// import columnsForCardDownload from './columnsForCardDownload'
import { Toast } from '@bp-digital/component-toast'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import { ROUTE_ADMIN } from 'src/constants/routes'

const ViewManageCardsPage = () => {
  const location = useLocation()
  const history = useHistory()

  const { viewManageCardStore, contentStore, userStore } = useRootStore()
  const [isEditingColumns, setIsEditingColumns] = useState(false)
  const content = contentStore.getPage('cards-view')
  const authorityId = userStore.selectedAuthorityId
  const removecolumn = userStore.onefleetmigratedstatus > 0
  const initialSearchTerm = getQueryParameter('searchTerm')
  const loadingRow = viewManageCardStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  const [toastData, setToastData] = useState({})

  useEffect(() => {
    const fetch = async () => {
      if (authorityId && userStore.preferences) {
        if (initialSearchTerm) viewManageCardStore.setSearchTerm(initialSearchTerm)

        viewManageCardStore.getCards({ authorityId })
      }
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityId, userStore.preferences, initialSearchTerm])

  useEffect(() => {
    return () => {
      viewManageCardStore.setSearchTerm('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('cards-view')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const handleSubmitSearch = async () => {
    await viewManageCardStore.getCards({ authorityId })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      viewManageCardStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    viewManageCardStore.setSearchTerm('')
    viewManageCardStore.setSearchLimits({})
    viewManageCardStore.getCards({ authorityId })

    if (initialSearchTerm)
      history.push({
        ...location,
        search: null
      })
  }

  const handlePreferenceChange = async newlySelectedColumns => {
    await userStore.updatePreferences('cards', newlySelectedColumns)
    await userStore.getPreferences()
    await viewManageCardStore.getCards({ authorityId, forceRefresh: true })
  }

  const getCardsByIds = (cardIds = []) => {
    const rawData = toJS(viewManageCardStore.data)
    const cardPages = Object.values(rawData)
    const columns = viewManageCardStore.columns
    const columnKeys = columns.map(col => col.key)

    const cards = cardIds.map(id => {
      return cardPages.map(pageOfCards => pageOfCards.find(card => card.cardId === id)).find(val => !!val)
    })

    const cardsWithContent = cards.map(card => {
      const renamedCard = {}
      const keys = Object.keys(card)

      keys.forEach(originalKey => {
        if (columnKeys.includes(originalKey)) {
          const friendlyName = columns.find(column => column.key === originalKey)?.name || originalKey

          if (originalKey === 'cardStatusId') {
            originalKey = 'cardStatus'
          }

          renamedCard[friendlyName] = getValueOrFallback(card[originalKey])
        }
      })

      return renamedCard
    })

    return {
      sheet: 'Cards',
      columns: columns.map(col => ({ label: col.name, value: col.name })),
      content: cardsWithContent
    }
  }

  const tableSearchProps = {
    searchTerm: viewManageCardStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.search_placeholder || '...',
    inputAriaLabel: content?.search_placeholder || '...',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  const tableActionProps = [
    {
      text: content?.tableFilter_ctaColumn || 'Columns',
      iconName: 'Edit',
      size: 'lg',
      disabled: viewManageCardStore.isFetchingData,
      onClick: () => {
        setIsEditingColumns(true)
      },
      dataAttributes: {
        'data-testid': 'button-edit-columns'
      }
    },
    {
      text: content?.downloadModal_ctaDownload || 'Download',
      enableOnSelection: true,
      iconName: 'Download',
      size: 'lg',
      dataAttributes: {
        'data-testid': 'button-download-cards'
      },
      onClick: selectedRows => {
        const cards = getCardsByIds(selectedRows)
        const date = format(new Date(), '{dd}{MM}{yy}')

        xlsx([cards], {
          fileName: `Card_${authorityId}_${date}`,
          extraLength: 3,
          writeOptions: {}
        })
      }
    }
  ]

  const tablePagination = {
    currentPage: viewManageCardStore.currentPage,
    count: viewManageCardStore.numberOfPages || 1,
    disabled: !viewManageCardStore.data,
    onChange: value => viewManageCardStore.getCards({ authorityId, page: value })
  }

  const data = useMemo(
    () =>
      !content || viewManageCardStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : viewManageCardStore.rows.map(row => {
            return {
              ...row,
              fuelAndCharge:
                row.fuelAndCharge === 'Y' ? content?.fuel_and_charge_yes || 'Yes' : content?.fuel_and_charge_no || 'No',
              ivmhScheme:
                row.gasoleoProfessional === 'Y'
                  ? content?.fuel_and_charge_yes || 'Yes'
                  : content?.fuel_and_charge_no || 'No'
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewManageCardStore.rows, viewManageCardStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      viewManageCardStore.isFetchingData
        ? viewManageCardStore.columns.map(col => ({
            Header: col?.name || '...',
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : viewManageCardStore.columns.map(col => {
            return {
              Header: col?.name || '...',
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewManageCardStore.columns, viewManageCardStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortCards = async (key = 'cardSerialNumber', desc = false) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        viewManageCardStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!viewManageCardStore.results.length && viewManageCardStore.searchTerm.length) {
          await viewManageCardStore.getCards({
            authorityId,
            columnSorting,
            searchTerm: ''
          })
        }
        await viewManageCardStore.getCards({
          authorityId,
          columnSorting
        })
      }
      if (viewManageCardStore.rows.length > 0) {
        sortCards(key, desc)
      }
    },
    [authorityId, viewManageCardStore]
  )

  const numberOfCards = viewManageCardStore.rows.length
  const totalNumberOfCards =
    viewManageCardStore.keyedSearchLimits?.totalRecords ?? viewManageCardStore.limits?.totalRecords ?? 0

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />
  return (
    <>
      <PageHeader
        title={content?.manage_cards_hero_title || '...'}
        subtitle={replaceVariablesInString(content?.hero_table_details || '...', {
          numberOfCards,
          totalNumberOfCards
        })}
        breadcrumbs={[{ to: '/cards/view', title: 'Cards', ariaLabel: 'Cards' }]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        {isEditingColumns && content && (
          <PreferencesEditor
            initialSelectedColumns={viewManageCardStore.selectedColumns}
            setIsOpen={setIsEditingColumns}
            onUpdate={handlePreferenceChange}
            sectionName='cards'
            getAvailableColumns={() => viewManageCardStore.getAvailableColumns(authorityId, removecolumn)}
            content={content}
            locale={userStore.locale}
          />
        )}
        <ReactTable
          name='view-manage-cards'
          columns={columns}
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          showSelectAllCheckbox={true}
          expandSubRowOnSelect={false}
          renderDetail={({ original }) => {
            const cardStatusKey = `advancedFilters_cardStatusId_${original?.currentCardStatusId}`
            const cardStatusText = content[cardStatusKey] || cardStatusKey

            return (
              <ViewCardDetail
                content={content}
                {...original}
                cardStatusText={cardStatusText}
                authorityId={authorityId}
                setToastData={setToastData}
                showEditRestrictionsButton={viewManageCardStore?.isAck}
              />
            )
          }}
          onSort={handleSort}
          isLoading={viewManageCardStore?.isFetchingData}
          hasError={viewManageCardStore?.responseFailure}
        />
        <Toast appearance='dark' toast={toastData} />
      </InnerPageWrapper>
    </>
  )
}

export default observer(ViewManageCardsPage)
