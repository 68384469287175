const weakPinList = [
  '1234',
  '1111',
  '0000',
  '1212',
  '7777',
  '1004',
  '2000',
  '4444',
  '2222',
  '6969',
  '9999',
  '3333',
  '5555',
  '6666',
  '1122',
  '1313',
  '8888',
  '4321',
  '2001',
  '1010',
  '0258',
  '8520'
]

export const getIsWeakPin = (pin = '') => weakPinList.includes(pin)
