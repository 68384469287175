import AccessAdminUserStore from './AccessAdministration/AccessAdminUserStore'
import AuditStore from './Audit/AuditStore'
import TransactionsStore from './Transactions/TransactionsStore'
import InvoicesStore from './Transactions/InvoicesStore'
import ViewManageCardStore from './Cards/ViewManageCardStore'
import CardsReissueStore from './Cards/CardsReissueStore'
import CardsRestrictionProfilesStore from './Cards/CardsRestrictionProfilesStore'
import CardsSpendingLimitStore from './Cards/CardsSpendingLimitStore'
import UserStore from './UserStore'
import ContentStore from './ContentStore'
import GroupAdministrationStore from './AccessAdministration/GroupAdministrationStore'
import ChargeHistoryStore from './Electric/ChargeHistoryStore'
import ChargePointsStore from './Electric/ChargePointsStore'
import TokensStore from './Electric/TokensStore'
import DepotsStore from './Electric/DepotsStore'
import { EXPIRY_ID } from 'constants/localStorage'

class RootStore {
  constructor() {
    const isLoggedIn = !!window.localStorage.getItem(EXPIRY_ID)

    this.adminStore = new AccessAdminUserStore(this)
    this.contentStore = new ContentStore(this)
    this.userStore = new UserStore(this)
    this.auditStore = new AuditStore(this)
    this.transactionsStore = new TransactionsStore(this)
    this.accessAdminUserStore = new AccessAdminUserStore(this)
    this.groupAdminStore = new GroupAdministrationStore(this)
    this.invoicesStore = new InvoicesStore(this)
    this.viewManageCardStore = new ViewManageCardStore(this)
    this.cardReissueStore = new CardsReissueStore(this)
    this.cardsRestrictionsStore = new CardsRestrictionProfilesStore(this)
    this.cardsSpendingLimitStore = new CardsSpendingLimitStore(this)
    this.chargePointsStore = new ChargePointsStore(this)
    this.chargeHistoryStore = new ChargeHistoryStore(this)
    this.tokensStore = new TokensStore(this)
    this.depotsStore = new DepotsStore(this)

    if (isLoggedIn) {
      this.init()
    }
  }

  async init() {
    await this.userStore.init()
  }
}

export default RootStore
