import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const SingleChargePointWrapper = styled.div`
  position: relative;
  height: 372px;
  display: flex;
  width: 100%;
`
export const Values = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px 2px;
  padding-top: 8px;
`

export const Label = styled.p`
  color: var(--color-grey-regular-500);
  margin: 0;
`

export const Value = styled.p`
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`

export const ChargePointDetailsContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 20px;
  background: ${CssVars.palette.neutral.white};
  padding: 40px;
  max-width: 420px;
  width: 100%;

  & > a {
    margin-top: 20px;
  }
`

export const Title = styled.h3``
