import styled from 'styled-components'

export const ExitImpersonateButton = styled.button`
  position: absolute;
  left: 0;
  z-index: 1000;
  background-color: #880808;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  height: fit-content;
  padding: 12px 22px;
  margin: 12px 0 0 5px;
  border: none;

  :hover {
    cursor: pointer;
    background-color: #800020;
  }
`
