const getSiteGroupIds = siteTypes => {
  const groupIds = []
  for (let siteId = 0; siteId < siteTypes.length; siteId += 1) {
    if (siteTypes[siteId].value) {
      groupIds.push((siteId + 1).toString())
    }
  }
  return groupIds
}

export default getSiteGroupIds
