import { action, makeObservable, observable } from 'mobx'
import BaseDataStore from '../BaseDataStore'
import { FEATURE_FLAGS } from 'constants/featureFlags'
const showDate = FEATURE_FLAGS.SHOW_CDR_DATE_COLUMN

const getCdrType = (type, content = {}) => {
  if (type === 'public') {
    return content.cpo_transactions_public || type
  } else if (type === 'depot') {
    return content.cpo_transactions_depot || type
  }

  return type
}

export const MAX_ROW_SELECTION = 20000

class ChargeHistoryStore extends BaseDataStore {
  columnSorting = ['endDate', 'desc']
  simpleFilter = ''

  selectedRows = {}

  constructor(root) {
    super(root, 'charginghistory', 'chargepointoperations-charginghistory')

    makeObservable(this, {
      selectedRows: observable,
      setSelectedRows: action
    })
  }

  setSelectedRows(rowIDs) {
    this.selectedRows = rowIDs
  }

  get rows() {
    return this.results.slice(0, this.pageSize).map(result => ({
      type: 'MASTER',
      key: result.id,
      ...result,
      chargePointId: result.site ? result.site.chargePointId : '-',
      cdrType: getCdrType(result.cdrType, this.content),
      date: result.startDate,
      startDate: result.sessionStart,
      endDate: result.sessionEnd,
      quantity: result.totalEnergy.toFixed(2) + ' kWh',
      // chargerName: result.site ? (result.site.siteAlias ? result.site.siteAlias : result.site.chargePointId) : '-',
      chargerName: result.site ? (result.site.siteAlias ? result.site.siteAlias : '') : '-',
      siteId: result.site ? result.site.id : '-',
      siteName: result.site ? result.site.name : '-',
      evseId: result.site ? result.site.evseId : '-',
      siteAddress: result.site ? `${result.site.address}, ${result.site.city}, ${result.site.country}` : '-',
      connectorSpeed: result.site ? result.site.connectorPower : '0',
      mapCenter: result.site ? { lat: result.site.chargePointLatitude, lng: result.site.chargePointLongitude } : {}
    }))
  }

  get columns() {
    return showDate
      ? [
          {
            key: 'rfid',
            name: this.content?.cpo_transactions_rfid_columnheader || '[cpo_transactions_rfid_columnheader]'
          },
          {
            key: 'cardTitle',
            name: this.content?.cpo_transactions_card_title_columnheader || '[cpo_transactions_card_title_columnheader]'
          },
          {
            key: 'cardHolderName',
            name:
              this.content?.cpo_transactions_card_holder_name_columnheader ||
              '[cpo_transactions_card_holder_name_columnheader]'
          },
          {
            key: 'cardNumberEnding',
            name:
              this.content?.cpo_transactions_card_ending_columnheader || '[cpo_transactions_card_ending_columnheader]'
          },
          {
            key: 'date',
            name: this.content?.cpo_transactions_date_columnheader || '[cpo_transactions_date_columnheader]'
          },
          {
            key: 'startDate',
            name: this.content?.cpo_transactions_start_columnheader || '[cpo_transactions_start_columnheader]'
          },
          {
            key: 'endDate',
            name: this.content?.cpo_transactions_end_columnheader || '[cpo_transactions_end_columnheader]'
          },
          { key: 'siteName', name: this.content?.cpo_site_label || '[cpo_site_label]' },
          {
            key: 'evseId',
            name: this.content?.cpo_transactions_evseid_columnheader || '[cpo_transactions_evseid_columnheader]'
          },
          { key: 'chargerName', name: this.content?.cpo_charger_name || '[cpo_charger_name]' },
          {
            key: 'quantity',
            name: this.content?.cpo_transactions_quantity_columnheader || '[cpo_transactions_quantity_columnheader]'
          },
          {
            key: 'cdrType',
            name: this.content?.cpo_transactions_cdr_type_columnheader || '[cpo_transactions_cdr_type_columnheader]'
          }
        ]
      : [
          {
            key: 'rfid',
            name: this.content?.cpo_transactions_rfid_columnheader || '[cpo_transactions_rfid_columnheader]'
          },
          {
            key: 'cardTitle',
            name: this.content?.cpo_transactions_card_title_columnheader || '[cpo_transactions_card_title_columnheader]'
          },
          {
            key: 'cardNumberEnding',
            name:
              this.content?.cpo_transactions_card_ending_columnheader || '[cpo_transactions_card_ending_columnheader]'
          },
          {
            key: 'startDate',
            name: this.content?.cpo_transactions_start_columnheader || '[cpo_transactions_start_columnheader]'
          },
          {
            key: 'endDate',
            name: this.content?.cpo_transactions_end_columnheader || '[cpo_transactions_end_columnheader]'
          },
          {
            key: 'siteName',
            name: this.content?.cpo_transactions_site_name_columnheader || '[cpo_transactions_site_name_columnheader]'
          },
          {
            key: 'evseId',
            name: this.content?.cpo_transactions_evseid_columnheader || '[cpo_transactions_evseid_columnheader]'
          },
          { key: 'chargerName', name: this.content?.cpo_charger_name || '[cpo_charger_name]' },
          {
            key: 'quantity',
            name: this.content?.cpo_transactions_quantity_columnheader || '[cpo_transactions_quantity_columnheader]'
          },
          {
            key: 'cdrType',
            name: this.content?.cpo_transactions_cdr_type_columnheader || '[cpo_transactions_cdr_type_columnheader]'
          }
        ]
  }

  async getHistory({
    selectedHierarchy,
    searchTerm = this.searchTerm,
    columnSorting = this.columnSorting,
    page = this.currentPage || 1,
    pageSize = this.pageSize,
    forceRefresh = false
  }) {
    if (!columnSorting.length) {
      columnSorting = ['transactionDateTime', 'desc']
    }

    let paginationStart = (page - 1) * pageSize
    const params = {
      accessLevel: selectedHierarchy.accessLevel,
      accessLevelCode: selectedHierarchy.accessLevelCode,
      dateFrom: new Date(new Date('1983-01-01T01:01:01+01:00').toUTCString()).toISOString(),
      cdrType: this.simpleFilter,
      dateTo: new Date(new Date().toUTCString()).toISOString(),
      sortBy: columnSorting[0],
      sortOrder: columnSorting[1] === 'asc' ? 'asc' : 'desc',
      SearchBy: searchTerm,
      sort: {},
      pageSize: pageSize,
      page: Math.floor(paginationStart / pageSize)
    }

    await super.getData({
      method: 'get',
      url: '/api/evis/v1/charge-data',
      payload: {},
      params,
      totalRecordsKey: 'totalChargeDataRecords',
      responseKey: 'chargeData',
      page,
      searchTerm,
      pageSize,
      forceRefresh
    })

    this.setIsFetchingData(false)
  }
}

export default ChargeHistoryStore
