import { useEffect } from 'react'

import Paper from 'components/surfaces/Paper'
import TwoColumn from 'components/layout/TwoColumn'
import { TextField } from '@bp-digital/component-text-field'
import Skeleton from 'react-loading-skeleton'
//import { Button } from '@bp-digital/component-button'
import { PhoneField } from '@bp-digital/component-phone-field'
import { Controller, useForm } from 'react-hook-form'

//import styled from 'styled-components'

// const ButtonWrapper = styled.div`
//   display: flex;
//   align-items: flex-end;
//   padding-bottom: var(--structure-spacing-md);

const AccountDetails = ({
  title,
  showUserName = true,
  userNameLabel,
  firstNameLabel,
  lastNameLabel,
  jobTitleLabel,
  emailLabel,
  mobileNumberLabel,
  landlineNumberLabel,
  //changePasswordLabel = 'Change password',
  content,
  countryList,
  setUserDetails,
  onErrorsChanged,
  user,
  button
}) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.jobTitle || ''
    }
  })
  const regex = /^[^\\="<>/:;]+$/i
  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('jobTitle', user.jobTitle)
    }
  }, [user, setValue])

  useEffect(() => {
    onErrorsChanged(isValid)
  }, [isValid, onErrorsChanged])

  return (
    <Paper title={title || '...'} button={button}>
      {user ? (
        <>
          {showUserName && (
            <TwoColumn>
              <TextField
                label={userNameLabel || '...'}
                name='userName'
                defaultValue={user.userName}
                disabled
                readOnly
              />
              {/* <ButtonWrapper>
                <Button appearance='secondary' iconName='Security' onClick={onChangePassword}>
                  {changePasswordLabel}
                </Button>
              </ButtonWrapper> */}
            </TwoColumn>
          )}
          <TwoColumn>
            <Controller
              name='firstName'
              control={control}
              rules={{
                value: true,
                required: content?.add_user_field_required || 'add_user_field_required',
                pattern: {
                  value: regex,
                  message:
                    content?.advancedFilters_validation_special_character ||
                    'advancedFilters_validation_special_character'
                }
              }}
              render={({ field }) => (
                <TextField
                  label={firstNameLabel || '...'}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value)
                    trigger('firstName')
                    value = value.trim().replace(/\s{2,}/g, ' ')
                    setUserDetails([{ firstName: value }])
                  }}
                  error={!!errors.firstName}
                  errorMessage={errors.firstName?.message}
                />
              )}
            />
            <Controller
              name='lastName'
              control={control}
              rules={{
                value: true,
                required: content?.add_user_field_required || 'add_user_field_required',
                pattern: {
                  value: regex,
                  message:
                    content?.advancedFilters_validation_special_character ||
                    'advancedFilters_validation_special_character'
                }
              }}
              render={({ field }) => (
                <TextField
                  label={lastNameLabel || '...'}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value)
                    trigger('lastName')
                    value = value.trim().replace(/\s{2,}/g, ' ')
                    setUserDetails([{ lastName: value }])
                  }}
                  error={!!errors.lastName}
                  errorMessage={errors.lastName?.message}
                />
              )}
            />
            <Controller
              name='jobTitle'
              control={control}
              rules={{
                pattern: {
                  value: regex,
                  message:
                    content?.advancedFilters_validation_special_character ||
                    'advancedFilters_validation_special_character'
                }
              }}
              render={({ field }) => (
                <TextField
                  label={jobTitleLabel || '...'}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value)
                    trigger('jobTitle')
                    value = value.trim().replace(/\s{2,}/g, ' ')
                    setUserDetails([{ jobTitle: value }])
                  }}
                  error={!!errors.jobTitle}
                  errorMessage={errors.jobTitle?.message}
                />
              )}
            />
            <TextField label={emailLabel || '...'} name='email' defaultValue={user.email} disabled readOnly />
            <PhoneField
              label={mobileNumberLabel || '...'}
              prefixItems={countryList}
              onChange={value =>
                setUserDetails([{ mobileNumberIsdCode: value.prefix }, { mobileNumber: value.number }])
              }
              defaultPrefix={user.mobileNumberIsdCode}
              defaultValue={user.mobileNumber}
              maxLength={10}
            />
            <PhoneField
              label={landlineNumberLabel || '...'}
              prefixItems={countryList}
              onChange={value =>
                setUserDetails([{ landlineNumberIsdCode: value.prefix }, { landlineNumber: value.number }])
              }
              defaultPrefix={user.landlineNumberIsdCode}
              defaultValue={user.landlineNumber}
              maxLength={10}
            />
          </TwoColumn>
        </>
      ) : (
        <>
          {showUserName && (
            <TwoColumn>
              <Skeleton height={70} />
              <Skeleton height={70} />
            </TwoColumn>
          )}
          <TwoColumn>
            <Skeleton count={3} height={70} style={{ margin: '8px 0' }} />
            <Skeleton count={3} height={70} style={{ margin: '8px 0' }} />
          </TwoColumn>
        </>
      )}
    </Paper>
  )
}

export default AccountDetails
