import axios from 'axios'
import { useQuery } from 'react-query'

/**
 * Gets stats on the provided authority, including:
 * - Current "base restriction profile" and corresponding ID
 * - Total active cards under authority
 * - Total active cards without a restriction profile assigned
 */
const QUERY_KEY = 'restrictions/authority-profile-details'

type paramType = string | null

const queryFn = async (authorityId: paramType) => {
  if (!authorityId) throw new Error('No Authority Id given to /restrictionprofiles/hierarchyprofiledetails endpoint')
    
      const response = await axios.get(`/api/cards/profile/stats?AuthorityIds=${authorityId}`)

  // not a group with multi-authorities & base profiles so simpifying to [0] for now
  return response.data?.cardProfileStats?.[0]
}

const useApiGetHierarchyProfileDetails = (authorityId: paramType, options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY, () => queryFn(authorityId), {
    staleTime: 120000,
    enabled: !!authorityId,
    ...options
  })
}

export default useApiGetHierarchyProfileDetails
