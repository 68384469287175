import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;
  // margin-bottom: ${props => (props.$isVisible ? '16px' : '0px')};
  margin-bottom: 16px;

  @media only screen and ${breakpoints.device.sm} {
    width: fit-content;
  }
`
