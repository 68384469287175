import getChargerType from 'helpers/getChargerType'
import getChargePointStatus from 'helpers/getChargePointStatus'

export const getDetails = (site, content) => {
  const chargePoint = site?.chargePoints?.[0] || {}
  let lastUpdated = null
  const statusAr = []
  if (chargePoint.evses && chargePoint.evses.length) {
    chargePoint.evses.forEach(evse => {
      if (evse.lastUpdated && (!lastUpdated || new Date(evse.lastUpdated).getTime() > lastUpdated.getTime())) {
        lastUpdated = new Date(evse.lastUpdated)
      }
      if (evse.connectors && evse.connectors.length) {
        evse.connectors.forEach(connector => statusAr.push(connector.status))
      }
    })
    if (site?.lastUpdate) lastUpdated = new Date(site?.lastUpdate)
  }

  const result = []

  return result.concat([
    { label: content.cpo_designation_columnheader, value: chargePoint.chargePointName },
    { label: content.cpo_charger_details_id_label, value: chargePoint.chargePointId },
    { label: content.cpo_authority_name_columnheader, value: site.authorityName },
    {
      label: content.cpo_charger_details_type_label,
      value: getChargerType(chargePoint.highestConnectorPower, content)
    },
    {
      label: content.cpo_charger_details_status_label,
      value: getChargePointStatus(statusAr, content)
    },
    {
      label: content.cpo_charger_details_status_date_label,
      value: lastUpdated ? lastUpdated.toLocaleString() : content.cpo_charger_details_unknown
    }
  ])
}
