import { Icon } from '@bp-digital/component-icon'
import { Row } from 'react-table'

export function ExpanderCell<T extends object>({ row }: { row: Row<T> }) {
  return (
    <span
      {...row.getToggleRowExpandedProps({ style: { display: 'flex' } })}
      className='expand-icon-container'
      data-testid={`table-item-expand-${row.index}`}
    >
      <Icon name={row.isExpanded ? 'DownLarge' : 'RightLarge'}></Icon>
    </span>
  )
}
