import { useState } from 'react'
import { useHistory, Redirect } from 'react-router'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import { useRootStore } from 'contexts/StoreContext'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import RestrictionTypes from './components/RestrictionTypes'
import useContent from 'hooks/useContent'
import { SteppedProgress } from '@bp-digital/component-stepped-progress'
import SpendingLimit from '../components/EditRestriction/EditRestrictionSpendingLimit'
import ProductsAndServices from '../components/EditRestriction/EditRestrictionProductsAndServices'
import SiteLocations from '../components/EditRestriction/EditRestrictionSiteLocations'
import DayAndTime from '../components/EditRestriction/EditRestrictionDayAndTime'
import Odometer from '../components/EditRestriction/EditRestrictionOdometer'
import Summary from './components/Summary'
import buildRequest from './helpers/buildRequest'
import { MODE, RESTRICTION_TYPES } from 'constants/restrictions'
import { ROUTE_CARDS_RESTRICTIONS, ROUTE_CARDS_ORDER, ROUTE_ADMIN } from 'constants/routes'
import useUrlSearchParams from 'hooks/useUrlSearchParams'

const NewRestrictionProfilePage = () => {
  const history = useHistory()
  const content = useContent('cards-restrictions')
  const isOrderingCard = !!useUrlSearchParams().get('orderCard')
  const { userStore, cardsRestrictionsStore } = useRootStore()
  const { selectedAuthorityId } = userStore
  const [steps, setSteps] = useState([
    content?.restriction_profiles_step_set_restriction || 'restriction_profiles_step_set_restriction',
    content?.restriction_profiles_step_summary || 'restriction_profiles_step_summary'
  ])
  const [activeStep, setActiveStep] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formData, setFormData] = useState({})
  const [restrictionTypes, setRestrictionTypes] = useState([])
  const dateTimeToNumber = new Date().toJSON().slice(0, 19).split(/-|T|:/).join('')
  const [profileName, setProfileName] = useState(`${selectedAuthorityId}_${dateTimeToNumber}`)
  const [error, setError] = useState(null)

  const { data: authorityDetails } = useApiGetAuthorityDetails(selectedAuthorityId, {
    enabled: !!selectedAuthorityId
  })

  const {
    restrictions: { currency, distanceUnit } = {},
    serviceCode,
    countryCode,
    opuCode,
    customerNumber
  } = authorityDetails || {}

  const getSteps = () => {
    const stepsArray = [content?.restriction_profiles_step_set_restriction]

    if (restrictionTypes.includes(RESTRICTION_TYPES.SPENDING_LIMIT))
      stepsArray.push(content?.restriction_spending_limit_title)
    if (restrictionTypes.includes(RESTRICTION_TYPES.PRODUCTS_AND_SERVICES))
      stepsArray.push(content?.restriction_products_title)
    if (restrictionTypes.includes(RESTRICTION_TYPES.SITE_LOCATIONS)) stepsArray.push(content?.restriction_site_title)
    if (restrictionTypes.includes(RESTRICTION_TYPES.DAY_AND_TIME)) stepsArray.push(content?.restriction_date_time_title)
    if (restrictionTypes.includes(RESTRICTION_TYPES.ODOMETER)) stepsArray.push(content?.restriction_odometer_title)

    stepsArray.push(content?.restriction_profiles_step_summary)
    return stepsArray
  }

  const handleOnNext = (type, data) => {
    setFormData({ ...formData, [type]: data })
    setActiveStep(activeStep + 1)
  }

  const handleOnBack = () => setActiveStep(activeStep - 1)

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      if (isOrderingCard) {
        history.push(`${ROUTE_CARDS_ORDER}?profileName=${profileName}`)
      } else {
        cardsRestrictionsStore.reset()
        history.push(ROUTE_CARDS_RESTRICTIONS)
      }
    },
    e => {
      setError(content?.error || 'Sorry, an error occurred')
      setIsSubmitting(false)
      console.error(e)
    },
    selectedAuthorityId
  )

  const handleSubmit = () => {
    setIsSubmitting(true)
    const payload = buildRequest({ authorityId: selectedAuthorityId, profileName, restrictionTypes, data: formData })
    submitForm(payload)
  }

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.restriction_profiles_create_profile}
        subtitle={content?.restriction_profiles_step_set_restriction}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <SteppedProgress items={getSteps()} selectedIndex={activeStep} />
        {activeStep === 0 && (
          <RestrictionTypes
            content={content}
            onBack={history.goBack}
            onNext={() => {
              setSteps(getSteps())
              setActiveStep(activeStep + 1)
            }}
            restrictionTypes={restrictionTypes}
            setRestrictionTypes={setRestrictionTypes}
          />
        )}
        {steps[activeStep] === content?.restriction_spending_limit_title && (
          <SpendingLimit
            authorityId={selectedAuthorityId}
            content={content}
            currency={currency}
            mode={MODE.CREATE}
            onBack={handleOnBack}
            onNext={data => handleOnNext(RESTRICTION_TYPES.SPENDING_LIMIT, data.restrictionDetail.spendingLimit)}
            defaultValues={formData.spendingLimit || null}
            title={content?.restriction_spending_limit_title || 'restriction_spending_limit_title'}
          />
        )}
        {steps[activeStep] === content?.restriction_products_title && (
          <ProductsAndServices
            authorityId={selectedAuthorityId}
            content={content}
            mode={MODE.CREATE}
            data={{
              serviceCode,
              currency
            }}
            onBack={handleOnBack}
            onNext={data => handleOnNext(RESTRICTION_TYPES.PRODUCTS_AND_SERVICES, data)}
            defaultValues={formData.productAndServices || null}
            title={content?.restriction_products_title || 'restriction_products_title'}
          />
        )}
        {steps[activeStep] === content?.restriction_site_title && (
          <SiteLocations
            authorityId={selectedAuthorityId}
            content={content}
            mode={MODE.CREATE}
            data={{
              countryCode,
              opuCode,
              customerNumber: String(customerNumber),
              customerId: `${opuCode}${customerNumber}`,
              region: authorityDetails?.configuration.region.region.shown === 'Y' ? 'Nationalinternational' : 'National'
            }}
            onBack={handleOnBack}
            onNext={data => handleOnNext(RESTRICTION_TYPES.SITE_LOCATIONS, data)}
            defaultValues={formData.siteLocations || null}
            title={content?.restriction_site_title || 'restriction_site_title'}
          />
        )}
        {steps[activeStep] === content?.restriction_date_time_title && (
          <DayAndTime
            authorityId={selectedAuthorityId}
            content={content}
            mode={MODE.CREATE}
            onBack={handleOnBack}
            onNext={data => handleOnNext(RESTRICTION_TYPES.DAY_AND_TIME, data)}
            defaultValues={formData.dayTime?.defaultValues || null}
            title={content?.restriction_date_time_title || 'restriction_date_time_title'}
          />
        )}
        {steps[activeStep] === content?.restriction_odometer_title && (
          <Odometer
            authorityId={selectedAuthorityId}
            content={content}
            mode={MODE.CREATE}
            data={{
              distanceUnit
            }}
            onBack={handleOnBack}
            onNext={data => handleOnNext(RESTRICTION_TYPES.ODOMETER, data)}
            defaultValues={formData.odometerAndDistance?.restrictionDetail?.odometerAndDistance || null}
            title={content?.restriction_odometer_title || 'restriction_odometer_title'}
          />
        )}
        {steps[activeStep] === steps[steps.length - 1] && (
          <Summary
            data={formData}
            content={content}
            onBack={handleOnBack}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            profileName={profileName}
            setProfileName={setProfileName}
            restrictionTypes={restrictionTypes}
            currency={currency}
            error={error}
          />
        )}
      </InnerPageWrapper>
    </>
  )
}

export default NewRestrictionProfilePage
