import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'
import { TextField } from '@bp-digital/component-text-field'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { PinEntry } from '@bp-digital/component-pin-entry'
import { Popover } from '@bp-digital/component-popover'
import { Alert } from '@bp-digital/component-alert'
import { Button } from '@bp-digital/component-button'
import { Icon } from '@bp-digital/component-icon'
import { StateMessage } from '@bp-digital/component-state-message'
import Skeleton from 'react-loading-skeleton'
import useApiCardOrderSubmit from 'hooks/api/useApiCardOrderSubmit'
import TwoColumn from 'components/layout/TwoColumn'
import Restrictions from './Restrictions'
import DeliveryAddress from '../DeliveryAddress'
import PINByDelivery from './PINByDelivery'
import CardTypes from '../common/CardTypes'
import {
  getPoolCardType,
  getUserPinOptionsAndDefaultPin,
  isUserEV as selectIsUserEV,
  validateRequiredVehicleRegistration,
  validateRequiredDriverName,
  validateNonEmptyVehicleOrDriverField,
  validateNonValidCharacters,
  validateAlphaNumericAndSpecialCharacters,
  validateAlphaNumericAndSpecialCharactersExtended,
  validateAlphaNumericAndSpecialCharactersUpdated,
  submitRequest,
  submitReplacementCardRequest,
  validateDeliveryAddress
} from '../../helpers'
import { getIsWeakPin } from 'helpers/getIsWeakPin'
import { CARD_ORDER_MODE, CARD_TYPE, SEND_TO_ADDRESS, NATIONAL, INTERNATIONAL } from '../../constants/constants'
import { InputWrapper, Form, PinWrapper } from './Express.styled'
import { InputControlWrapper, LabelWrapper, RestrictionsProfileWrapper } from '../common/CardOrder.styled'
import { Spacer } from 'styles/common.styled'
import { ROUTE_CARDS_ORDER, ROUTE_CARDS_ORDER_CONFIRMATION, ROUTE_CARDS_RESTRICTIONS_NEW } from 'constants/routes'
import FLAGS from 'constants/launchDarklyFeatureFlags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CARD_GASOLEO_PROFESIONAL, CARD_GASOLEO_PROFESIONAL_Label } from 'constants/cards'
import { useRootStore } from 'contexts/StoreContext'

const YES = 'Y'
const NO = 'N'
const HEIGHT = '1.75rem'

const ALLOW_PIN_SELECTION_ID = 1

const Express = ({
  content,
  authority,
  authorityDetails: {
    cardTypes = [],
    configuration: { cardSetup, cardDetails, costCenter, region, pin, fuelAndCharge, gasoleoProfessional } = {},
    countryCode,
    deliveryAddress,
    embossingLines,
    pinOption,
    sendToAddress,
    serviceCode,
    issuerNumber
  } = {},
  authorityDetails,
  isReplacementCardEnabled,
  replacementCardDetails,
  profileName,
  isLoading
}) => {
  const localStorage = window.localStorage
  const history = useHistory()
  const {
    userStore: { onefleetmigratedstatus }
  } = useRootStore()
  const blockItemsForACK = onefleetmigratedstatus > 0
  const { mutate: submitForm } = useApiCardOrderSubmit(
    ({ deliveryType }) => {
      setIsSubmitting(false)
      setSubmitErrorMessage('')
      reset()
      history.push({
        pathname: ROUTE_CARDS_ORDER_CONFIRMATION,
        state: {
          referrer: ROUTE_CARDS_ORDER,
          deliveryType,
          countryCode,
          authorityDetails,
          isReplacementCardEnabled
        }
      })
    },
    (message, showValidationMessage) => {
      setIsSubmitting(false)
      const finalMessage = !showValidationMessage ? (
        <>
          {message} <br />
          {authority?.authorityId[0] === 'K'
            ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
            : content?.validation_alph_num_special || 'validation_alph_num_special'}
        </>
      ) : (
        message
      )

      setSubmitErrorMessage(finalMessage)
    }
  )

  useEffect(() => {
    const enteredValues = JSON.parse(localStorage.getItem('entered-card-details'))
    if (profileName && enteredValues) {
      setValue('cardSetup', enteredValues.cardSetup)
      setValue('cardDetails', enteredValues.cardDetails)
      setValue('cardEmbossing', enteredValues.cardEmbossing)
      setValue('region', enteredValues.region)
      setValue('pin', enteredValues.pin)
      setValue('pin.pin', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileName])

  const flags = useFlags()

  const [isAddressDifferent, setIsAddressDifferent] = useState(false)
  const isUserEV = selectIsUserEV(authorityDetails)
  const poolCardType = getPoolCardType(authorityDetails)
  let [pinOptions, defaultPin] = getUserPinOptionsAndDefaultPin(pinOption, content)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  const value = deliveryAddress.addressLine4 || deliveryAddress.addressLine3 || embossingLines.line2.content
  const embossLine2 = value.length > 27 ? value.substring(0, 27) : value
  const isAckCustomer = issuerNumber == 700669 ? true : false
  const showCreatenewProfileButton = isReplacementCardEnabled ? false : isAckCustomer == true ? false : true

  const isReplacementGasoleoCard = isReplacementCardEnabled && replacementCardDetails.gasoleoProfessional === YES
  const showGasoleo = gasoleoProfessional && gasoleoProfessional.shown === 'Y'
  if (!showGasoleo && isReplacementCardEnabled) {
    replacementCardDetails.gasoleoProfessional = NO
  }

  const defaultValues = {
    ...(!isLoading &&
      authorityDetails && {
        cardSetup: {
          existingcard: cardSetup.restriction.defaultValue,
          restriction: (profileName && YES) || cardSetup.restriction.defaultValue,
          differentAddress: NO
        },
        cardDetails: {
          address: {
            saveAddress: true,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            postCode: ''
          },
          cardTypeId: isUserEV && poolCardType?.length ? poolCardType?.cardTypeId : cardTypes?.[0]?.cardTypeId,
          vehicleregistration: cardDetails.vehicleReg.defaultValue,
          drivername: cardDetails.driverName.defaultValue,
          ...(isReplacementCardEnabled && {
            cardTypeId: replacementCardDetails.cardTypeId,
            vehicleregistration: replacementCardDetails.vehicleRegistration,
            drivername: replacementCardDetails.driverName
          })
        },
        cardEmbossing: {
          line1: embossingLines.line1.content,
          line2: embossLine2,
          line3: cardDetails.vehicleReg.defaultValue || cardDetails.driverName.defaultValue,
          ...(isReplacementCardEnabled && {
            line2:
              replacementCardDetails.line2 ||
              deliveryAddress.addressLine4 ||
              deliveryAddress.addressLine3 ||
              embossingLines.line2.content,
            line3: replacementCardDetails.line3
          })
        },
        region: {
          region: region.region.defaultValue,
          austriantax: region.austriantax.defaultValue,
          costcenterName: '',
          subcostcenter: '',
          ...(isReplacementCardEnabled && {
            region: replacementCardDetails.region === YES ? INTERNATIONAL : NATIONAL
          })
        },
        pin: {
          addinfo: (isUserEV && NO) || pin.addInfo.defaultValue,
          odometer: (isUserEV && NO) || pin.odometer.defaultValue,
          pin: '',
          active: defaultPin,
          ...(isReplacementCardEnabled && {
            addinfo: replacementCardDetails.addinfo,
            odometer: replacementCardDetails.odometer
          })
        },
        fuelAndCharge: fuelAndCharge?.defaultValue || NO,
        ...(isReplacementCardEnabled && {
          fuelAndCharge: replacementCardDetails.fuelAndCharge
        }),
        gasoleoProfessional: gasoleoProfessional?.defaultValue || NO,
        ...(isReplacementCardEnabled && {
          gasoleoProfessional: replacementCardDetails.gasoleoProfessional
        })
      })
  }

  if (!isLoading && authorityDetails && showGasoleo) {
    if (isReplacementGasoleoCard) {
      defaultValues.gasoleoProfessional = YES
      defaultValues.cardEmbossing.line2 = CARD_GASOLEO_PROFESIONAL
    } else {
      defaultValues.gasoleoProfessional = gasoleoProfessional?.defaultValue || NO
    }
  }

  // eslint-disable-next-line
  const { handleSubmit, reset, setValue, getValues, control, unregister, watch } = useForm({
    mode: 'onBlur',
    defaultValues
  })

  // Card Restrictions
  const showRestrictions = cardSetup?.restriction?.shown === YES
  const showRestrictionProfiles = watch('cardSetup.restriction') === YES

  // Card Type
  const cardTypeId = watch('cardDetails.cardTypeId')

  // Card F&C
  const showFuelAndCharge = flags[FLAGS.FUEL_AND_CHARGE] && fuelAndCharge?.shown === YES

  // Card Gasoleo Professional
  const isGasoleoProfessionalCard = watch('gasoleoProfessional') === YES

  // Vehicle Registration
  const vehicleRegistration = watch('cardDetails.vehicleregistration')
  const showVehicleRegistration = [
    CARD_TYPE.POOL_CARD.id,
    CARD_TYPE.VEHICLE_CARD.id,
    CARD_TYPE.DRIVER_VEHICLE_CARD.id,
    CARD_TYPE.DRIVER_PIN_CARD.id
  ].includes(cardTypeId)

  // Driver name
  const driverName = watch('cardDetails.drivername')
  const addressLine1 = watch('cardDetails.address.addressLine1')
  const addressLine2 = watch('cardDetails.address.addressLine2')
  const addressLine3 = watch('cardDetails.address.addressLine3')
  const postCode = watch('cardDetails.address.postCode')

  const showDriverName = [CARD_TYPE.POOL_CARD.id, CARD_TYPE.DRIVER_CARD.id, CARD_TYPE.DRIVER_VEHICLE_CARD.id].includes(
    cardTypeId
  )

  const ShowDeliveryAddress = isReplacementCardEnabled
    ? sendToAddress === SEND_TO_ADDRESS.CARD_HOLDER
      ? true
      : false
    : true

  // Emboss
  const embossLine3 = cardTypeId === 2 ? driverName : vehicleRegistration?.trim() || driverName

  // Region
  const showRegionOptions = region?.region?.shown === YES

  // Austrian Road Tax
  const showAustrianTax = region?.austriantax?.shown === YES

  // Cost + SubCost centre
  const showCostCenter = costCenter?.shown === YES

  // PIN
  const showPinControls = getValues('pin.active') !== undefined
  const activePinOption = watch('pin.active')
  const showPinEntry = activePinOption === ALLOW_PIN_SELECTION_ID
  const showPinByPost =
    activePinOption === 4 || (activePinOption === 0 && pinOption.pinDerivation === '0' && pinOption.pinReq !== '4')

  // Odometer
  const showOdometer = isUserEV ? false : pin?.odometer?.shown === YES

  // Additional Information
  const showAdditionalInformation = isUserEV ? false : pin?.addInfo?.shown === YES

  const handleCreateProfile = () => {
    localStorage.setItem('entered-card-details', JSON.stringify(getValues()))
    history.push(`${ROUTE_CARDS_RESTRICTIONS_NEW}?orderCard=true`)
  }

  const onSubmit = async formData => {
    if (isReplacementCardEnabled) {
      setIsSubmitting(true)
      const { response } = await submitReplacementCardRequest(
        formData,
        replacementCardDetails,
        authority,
        authorityDetails,
        flags
      )

      if (response.deliveryType) {
        setIsSubmitting(false)
        setSubmitErrorMessage('')
        reset()
        localStorage.removeItem('entered-card-details')
        localStorage.removeItem('replacement-card-details')
        history.push({
          pathname: ROUTE_CARDS_ORDER_CONFIRMATION,
          state: {
            referrer: ROUTE_CARDS_ORDER,
            deliveryType: response.deliveryType,
            countryCode,
            authorityDetails,
            isReplacementCardEnabled
          }
        })
      } else {
        setIsSubmitting(false)
        setSubmitErrorMessage(response.errorDetails.errorMessage)
      }
    } else {
      const payload = submitRequest({ formData, authority, authorityDetails, flags })
      setIsSubmitting(true)
      submitForm(payload)
    }
  }

  const gasoleoPropChange = val => {
    val === YES
      ? setValue('cardEmbossing.line2', CARD_GASOLEO_PROFESIONAL)
      : setValue('cardEmbossing.line2', embossLine2)
  }

  return (
    <>
      {!isReplacementCardEnabled && (
        <>
          <Heading as='h2' size='h5' dataAttributes={{ ['data-content-key']: 'setup_heading_express' }}>
            {content?.setup_heading_express}
          </Heading>

          <DeliveryAddress
            content={content}
            isLoading={isLoading}
            authority={authority}
            deliveryAddress={deliveryAddress}
            sendTo={sendToAddress}
            mode={CARD_ORDER_MODE.EXPRESS}
          />
        </>
      )}

      <Heading as='h2' size='h5' dataAttributes={{ ['data-content-key']: 'setup_how_setup' }}>
        {content?.setup_how_setup}
      </Heading>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {ShowDeliveryAddress && (
          <TwoColumn>
            {sendToAddress !== SEND_TO_ADDRESS.CARD_HOLDER ? (
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'setup_set_restriction' }}>
                  {content?.setup_heading_different_address || 'Delivery address differs from current address?'}
                </Text>
                <Popover
                  appearance='dark'
                  text={content?.card_delivery_address_tooltip || 'card_delivery_address_tooltip'}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'card_delivery_address_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            ) : (
              <LabelWrapper $align='top'>
                <Text dataAttributes={{ 'data-content-key': 'delivery_address_title' }}>
                  {content?.card_sendto_address_form_title || 'card_sendto_address_form_title'}
                </Text>
                <Popover
                  appearance='dark'
                  text={content?.card_delivery_address_tooltip || 'card_delivery_address_tooltip'}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'card_delivery_address_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            )}
            <div>
              {sendToAddress !== SEND_TO_ADDRESS.CARD_HOLDER && (
                <Controller
                  name='cardSetup.differentAddress'
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <RadioButtonGroup
                      id={name}
                      options={[
                        { id: YES, label: content?.yes },
                        { id: NO, label: content?.no }
                      ]}
                      selectedId={value}
                      onChange={id => {
                        onChange(id)
                        if (id === NO) {
                          setIsAddressDifferent(false)
                          setValue('cardDetails.address.addressLine1', '')
                          setValue('cardDetails.address.addressLine2', '')
                          setValue('cardDetails.address.addressLine3', '')
                          setValue('cardDetails.address.country', '')
                          setValue('cardDetails.address.postCode', '')
                        } else {
                          setIsAddressDifferent(true)
                        }
                      }}
                      disabled={isLoading}
                      size='sm'
                      horizontal
                    />
                  )}
                />
              )}
              {(isAddressDifferent || sendToAddress === SEND_TO_ADDRESS.CARD_HOLDER) && (
                <InputWrapper style={{ marginTop: '1rem' }}>
                  {isLoading ? (
                    <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
                  ) : (
                    <Controller
                      name='cardDetails.address.addressLine1'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: content?.validation_required || 'validation_required'
                        },
                        validate: validateDeliveryAddress(
                          sendToAddress,
                          addressLine1,
                          addressLine2,
                          addressLine3,
                          postCode,
                          'line1'
                        ),

                        maxLength: {
                          value: 35,
                          message: content?.validation_max_length.replace('{{length}}', 35)
                        },
                        pattern: {
                          value:
                            authority.authorityId[0] === 'K'
                              ? validateAlphaNumericAndSpecialCharactersExtended
                              : validateAlphaNumericAndSpecialCharactersUpdated,
                          message:
                            authority.authorityId[0] === 'K'
                              ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
                              : content?.validation_alph_num_special || 'validation_alph_num_special'
                        }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextField
                          label={content?.card_sendto_address_form_name || 'card_sendto_address_form_name'}
                          placeholder={
                            content?.card_sendto_address_form_name_placeholder ||
                            'card_sendto_address_form_name_placeholder'
                          }
                          value={value}
                          textChangeHandler={onChange}
                          disabled={isLoading}
                          maxLength={35}
                          error={!!error?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
                  ) : (
                    <Controller
                      name='cardDetails.address.addressLine2'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: content?.validation_required || 'validation_required'
                        },
                        validate: validateDeliveryAddress(
                          sendToAddress,
                          addressLine1,
                          addressLine2,
                          addressLine3,
                          postCode,
                          'line2'
                        ),

                        maxLength: {
                          value: 35,
                          message: content?.validation_max_length.replace('{{length}}', 35)
                        },
                        pattern: {
                          value:
                            authority.authorityId[0] === 'K'
                              ? validateAlphaNumericAndSpecialCharactersExtended
                              : validateAlphaNumericAndSpecialCharactersUpdated,
                          message:
                            authority.authorityId[0] === 'K'
                              ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
                              : content?.validation_alph_num_special || 'validation_alph_num_special'
                        }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextField
                          label={content?.card_sendto_address_form_street || 'card_sendto_address_form_street'}
                          placeholder={
                            content?.card_sendto_address_form_street_placeholder ||
                            'card_sendto_address_form_street_placeholder'
                          }
                          value={value}
                          textChangeHandler={onChange}
                          disabled={isLoading}
                          maxLength={35}
                          error={!!error?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
                  ) : (
                    <Controller
                      name='cardDetails.address.addressLine3'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: content?.validation_required || 'validation_required'
                        },
                        validate: validateDeliveryAddress(
                          sendToAddress,
                          addressLine1,
                          addressLine2,
                          addressLine3,
                          postCode,
                          'line3'
                        ),

                        maxLength: {
                          value: 35,
                          message: content?.validation_max_length.replace('{{length}}', 35)
                        },
                        pattern: {
                          value:
                            authority.authorityId[0] === 'K'
                              ? validateAlphaNumericAndSpecialCharactersExtended
                              : validateAlphaNumericAndSpecialCharactersUpdated,
                          message:
                            authority.authorityId[0] === 'K'
                              ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
                              : content?.validation_alph_num_special || 'validation_alph_num_special'
                        }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextField
                          label={content?.card_sendto_address_form_city || 'card_sendto_address_form_city'}
                          placeholder={
                            content?.card_sendto_address_form_city_placeholder ||
                            'card_sendto_address_form_city_placeholder'
                          }
                          value={value}
                          textChangeHandler={onChange}
                          disabled={isLoading}
                          error={!!error?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
                  ) : (
                    <Controller
                      name='cardDetails.address.country'
                      control={control}
                      rules={{
                        maxLength: {
                          value: 35,
                          message: content?.validation_max_length.replace('{{length}}', 35)
                        },
                        pattern: {
                          value:
                            authority.authorityId[0] === 'K'
                              ? validateAlphaNumericAndSpecialCharactersExtended
                              : validateAlphaNumericAndSpecialCharactersUpdated,
                          message:
                            authority.authorityId[0] === 'K'
                              ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
                              : content?.validation_alph_num_special || 'validation_alph_num_special'
                        }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextField
                          label={content?.card_sendto_address_form_country || 'card_sendto_address_form_country'}
                          placeholder={
                            content?.card_sendto_address_form_country_placeholder ||
                            'card_sendto_address_form_country_placeholder'
                          }
                          value={value}
                          textChangeHandler={onChange}
                          disabled={isLoading}
                          error={!!error?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
                  ) : (
                    <Controller
                      name='cardDetails.address.postCode'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: content?.validation_required || 'validation_required'
                        },
                        validate: validateDeliveryAddress(
                          sendToAddress,
                          addressLine1,
                          addressLine2,
                          addressLine3,
                          postCode,
                          'postCode'
                        ),

                        maxLength: {
                          value: 10,
                          message: content?.validation_max_length.replace('{{length}}', 10)
                        },
                        pattern: {
                          value:
                            authority.authorityId[0] === 'K'
                              ? validateAlphaNumericAndSpecialCharactersExtended
                              : validateAlphaNumericAndSpecialCharactersUpdated,
                          message:
                            authority.authorityId[0] === 'K'
                              ? content?.validation_alph_num_poland || 'validation_alph_num_poland'
                              : content?.validation_alph_num_special || 'validation_alph_num_special'
                        }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextField
                          label={content?.card_sendto_address_form_postcode || 'card_sendto_address_form_postcode'}
                          value={value}
                          textChangeHandler={onChange}
                          placeholder={
                            content?.card_sendto_address_form_postcode_placeholder ||
                            'card_sendto_address_form_postcode_placeholder'
                          }
                          textTransform='uppercase'
                          disabled={isLoading}
                          error={!!error?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  )}
                  {/* {isLoading ? (
              <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
            ) : (
              <Controller
                name='cardDetails.address.saveAddress'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Checkbox
                    id={name}
                    label={
                      (content?.card_sendto_address_form_save_as || 'card_sendto_address_form_save_as') +
                      ' ' +
                      driverName
                    }
                    onChange={onChange}
                    defaultChecked={isChecked}
                    selectedId={value}
                    disabled={isLoading}
                  />
                )}
              />
            )} */}
                </InputWrapper>
              )}
            </div>
          </TwoColumn>
        )}
        {showRestrictions && !blockItemsForACK && (
          <TwoColumn>
            <LabelWrapper>
              <Text display='inline-block' dataAttributes={{ 'data-content-key': 'setup_set_restriction' }}>
                {content?.setup_set_restriction}
              </Text>

              <Popover
                appearance='dark'
                text={content?.express_mode_set_restrictions_tooltip}
                position='top'
                dataAttributes={{ ['data-content-key']: 'express_mode_set_restrictions_tooltip' }}
              >
                <Icon name='Info' appearance='dark' size='md' state='neutral' />
              </Popover>
            </LabelWrapper>

            <div>
              <Controller
                name='cardSetup.restriction'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={[
                      { id: YES, label: content?.yes },
                      { id: NO, label: content?.no }
                    ]}
                    selectedId={value}
                    onChange={id => {
                      onChange(id)
                      if (id === NO) unregister('restrictionDetails')
                    }}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />

              {showRestrictionProfiles && (
                <Controller
                  name='restrictionDetails'
                  control={control}
                  render={({ field: { onChange } }) => {
                    const isProfileSelected = getValues('restrictionDetails')

                    return (
                      <>
                        <LabelWrapper>
                          <Text as='p' display='block' dataAttributes={{ 'data-content-key': 'setup_set_restriction' }}>
                            {content?.setup_set_restriction}
                          </Text>
                          <Popover text={content?.setup_set_restriction_tooltip} appearance='dark' position='top'>
                            <Icon name='Info' appearance='dark' size='md' state='neutral' />
                          </Popover>
                        </LabelWrapper>
                        <InputControlWrapper>
                          <RestrictionsProfileWrapper>
                            <Restrictions
                              content={content}
                              data={{
                                currency: authorityDetails?.restrictions?.currency,
                                distanceUnit: authorityDetails?.restrictions?.distanceUnit,
                                params: {
                                  authorityId: authority.authorityId,
                                  groupId: null,
                                  parentId: null,
                                  searchText: null,
                                  serviceCode: [serviceCode]
                                }
                              }}
                              defaultProfileName={profileName}
                              onChange={onChange}
                            />
                            {showCreatenewProfileButton && (
                              <Button onClick={handleCreateProfile}>
                                {content?.setup_card_restriction_new_profile || 'setup_card_restriction_new_profile'}
                              </Button>
                            )}
                          </RestrictionsProfileWrapper>
                        </InputControlWrapper>
                        {!isProfileSelected && (
                          <>
                            <Spacer size='lg' />
                            <StateMessage iconName='Alert' state='danger' text={content?.validation_required} />
                          </>
                        )}
                      </>
                    )
                  }}
                />
              )}
            </div>
          </TwoColumn>
        )}
        {showFuelAndCharge && fuelAndCharge !== null && !blockItemsForACK && (
          <TwoColumn>
            <LabelWrapper>
              <Text display='inline-block' dataAttributes={{ 'data-content-key': 'setup_set_fuel_and_charge' }}>
                {content?.fuel_and_charge}
              </Text>
            </LabelWrapper>
            <div>
              <Controller
                name='fuelAndCharge'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={[
                      { id: YES, label: content?.yes },
                      { id: NO, label: content?.no }
                    ]}
                    selectedId={value}
                    onChange={id => {
                      onChange(id)
                    }}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />
            </div>
          </TwoColumn>
        )}
        {showGasoleo && (
          <TwoColumn>
            <LabelWrapper>
              <Text display='inline-block' dataAttributes={{ 'data-content-key': 'setup_set_gasoleo_professional' }}>
                {CARD_GASOLEO_PROFESIONAL_Label}
              </Text>
            </LabelWrapper>
            <div>
              <Controller
                name='gasoleoProfessional'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={[
                      { id: YES, label: content?.yes },
                      { id: NO, label: content?.no }
                    ]}
                    selectedId={value}
                    onChange={id => {
                      onChange(id)
                      gasoleoPropChange(id)
                    }}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />
            </div>
          </TwoColumn>
        )}
        <TwoColumn>
          <Text dataAttributes={{ 'data-content-key': 'card_details_card_type_label' }}>
            {content?.card_details_card_type_label}
          </Text>

          <InputControlWrapper>
            {isLoading || !getValues('cardDetails.cardTypeId') ? (
              <Skeleton height={HEIGHT} />
            ) : (
              <Controller
                name='cardDetails.cardTypeId'
                control={control}
                rules={{ required: true }}
                render={({ field: { name, value, onChange } }) => (
                  <CardTypes
                    cardTypes={cardTypes}
                    cardTypeId={cardTypeId}
                    name={name}
                    value={value}
                    onChange={onChange}
                    content={content}
                  />
                )}
              />
            )}
          </InputControlWrapper>
        </TwoColumn>
        <TwoColumn>
          <div>
            <Text dataAttributes={{ 'data-content-key': 'card_details_title' }}>{content?.card_details_title}</Text>

            {showVehicleRegistration && showDriverName && (
              <Text as='p' dataAttributes={{ 'data-content-key': 'card_details_heading_express' }} size='md'>
                {content?.card_details_heading_express}
              </Text>
            )}
          </div>

          <div>
            {isLoading && <Skeleton height={HEIGHT} />}
            {showVehicleRegistration && !isLoading && (
              <Controller
                name='cardDetails.vehicleregistration'
                control={control}
                rules={{
                  required:
                    (validateRequiredVehicleRegistration({ cardTypeId, driverName }) &&
                      (content?.card_details_vehicle_registration_validation_required ||
                        'card_details_vehicle_registration_validation_required')) ||
                    (validateRequiredDriverName({ cardTypeId, driverName }) &&
                      (content?.card_details_drivers_name_validation_required ||
                        'card_details_drivers_name_validation_required')),
                  pattern: {
                    value: validateNonValidCharacters,
                    message: content?.card_details_vehicledriver_validation
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    label={
                      <LabelWrapper>
                        <Text dataAttributes={{ 'data-content-key': content?.card_details_vehicle_registration_label }}>
                          {content?.card_details_vehicle_registration_label}
                        </Text>

                        <Popover
                          appearance='dark'
                          text={content?.card_details_vehicle_registration_tooltip}
                          position='top'
                          dataAttributes={{ ['data-content-key']: 'card_details_vehicle_registration_tooltip' }}
                        >
                          <Icon name='Info' appearance='dark' size='md' state='neutral' />
                        </Popover>
                      </LabelWrapper>
                    }
                    textChangeHandler={text => {
                      onChange(text)
                      setValue('cardEmbossing.line3', text)
                    }}
                    textTransform='uppercase'
                    value={value}
                    maxLength={14}
                    disabled={isLoading}
                    placeholder={
                      content?.card_details_vehicle_registration_placeholder ||
                      'card_details_vehicle_registration_placeholder'
                    }
                    error={!!error?.message}
                    errorMessage={error?.message}
                  />
                )}
              />
            )}

            <Spacer size='lg' />

            {showDriverName && !isLoading && (
              <Controller
                name='cardDetails.drivername'
                control={control}
                rules={{
                  required:
                    (validateRequiredDriverName({ cardTypeId, countryCode, sendToAddress }) &&
                      (content?.card_details_drivers_name_validation_required ||
                        'card_details_drivers_name_validation_required')) ||
                    (validateNonEmptyVehicleOrDriverField(vehicleRegistration) &&
                      (content?.card_details_drivers_name_validation || 'card_details_drivers_name_validation')),
                  pattern: {
                    value: validateNonValidCharacters,
                    message: content?.card_details_vehicledriver_validation || 'card_details_vehicledriver_validation'
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <TextField
                      label={
                        <LabelWrapper>
                          <Text dataAttributes={{ 'data-content-key': content?.card_details_drivers_name_label }}>
                            {content?.card_details_drivers_name_label}
                          </Text>
                          <Popover
                            appearance='dark'
                            text={content?.card_details_drivers_name_tooltip}
                            position='top'
                            dataAttributes={{ ['data-content-key']: 'card_details_drivers_name_tooltip' }}
                          >
                            <Icon name='Info' appearance='dark' size='md' state='neutral' />
                          </Popover>
                        </LabelWrapper>
                      }
                      textChangeHandler={text => {
                        onChange(text)
                        !vehicleRegistration.trim() && setValue('cardEmbossing.line3', text)
                      }}
                      textTransform='uppercase'
                      value={value}
                      maxLength={14}
                      disabled={isLoading}
                      placeholder={
                        content?.card_details_drivers_name_placeholder || 'card_details_drivers_name_placeholder'
                      }
                      error={!!error?.message}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            )}
          </div>
        </TwoColumn>
        <TwoColumn>
          <Text dataAttributes={{ 'data-content-key': 'card_embossing_title' }}>{content?.card_embossing_title}</Text>

          <InputWrapper>
            {isLoading ? (
              <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
            ) : (
              <Controller
                name='cardEmbossing.line1'
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    label={content?.card_embossing_customer_name_label || 'card_embossing_customer_name_label'}
                    readOnly
                    value={value}
                    textTransform='uppercase'
                    disabled={isLoading}
                  />
                )}
              />
            )}
            {isLoading ? (
              <Skeleton height={HEIGHT} style={{ marginBottom: '.5rem' }} />
            ) : (
              <Controller
                name='cardEmbossing.line2'
                control={control}
                rules={{
                  pattern: {
                    value: validateNonValidCharacters,
                    message: content?.card_embossing_town_city_validation
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <TextField
                      label={content?.card_embossing_town_city_label || 'card_embossing_town_city_label'}
                      textChangeHandler={onChange}
                      value={value}
                      disabled={isLoading || isGasoleoProfessionalCard}
                      placeholder={content?.card_embossing_town_city_placeholder}
                      textTransform='uppercase'
                      maxLength={27}
                      error={!!error?.message}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            )}
            {isLoading ? (
              <Skeleton height={HEIGHT} />
            ) : (
              <Controller
                name='cardEmbossing.line3'
                control={control}
                render={() => (
                  <TextField
                    label={
                      content?.card_embossing_vehicle_registration_label || 'card_embossing_vehicle_registration_label'
                    }
                    value={embossLine3}
                    disabled={isLoading}
                    readOnly
                  />
                )}
              />
            )}
          </InputWrapper>
        </TwoColumn>
        <TwoColumn>
          <LabelWrapper>
            <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_region' }}>
              {content?.region_region}
            </Text>

            <Popover
              appearance='dark'
              text={content?.region_region_tooltip.replace('[Country]', countryCode)}
              position='top'
              dataAttributes={{ 'data-content-key': 'region_region_tooltip' }}
            >
              <Icon name='Info' appearance='dark' size='md' state='neutral' />
            </Popover>
          </LabelWrapper>

          {(isLoading && <Skeleton height={HEIGHT} />) ||
            (showRegionOptions && (
              <Controller
                name='region.region'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={[
                      { id: NATIONAL, label: content?.region_national || 'region_national' },
                      {
                        id: INTERNATIONAL,
                        label: content?.region_national_internation || 'region_national_internation'
                      }
                    ]}
                    onChange={onChange}
                    selectedId={value}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />
            )) || (
              <Text>
                {getValues('region.region') === NATIONAL
                  ? content?.region_national || 'region_national'
                  : content?.region_national_internation || 'region_national_internation'}
              </Text>
            )}
        </TwoColumn>
        {showAustrianTax && (
          <TwoColumn>
            <LabelWrapper>
              <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_austrian_tax' }}>
                {content?.region_austrian_tax}
              </Text>

              <Popover
                appearance='dark'
                text={content?.region_austrian_tax_tooltip}
                position='top'
                dataAttributes={{ 'data-content-key': 'region_austrian_tax_tooltip' }}
              >
                <Icon name='Info' appearance='dark' size='md' state='neutral' />
              </Popover>
            </LabelWrapper>

            <Controller
              name='region.austriantax'
              control={control}
              rules={{
                required: content?.validation_required || 'validation_required'
              }}
              render={({ field: { name, value, onChange } }) => (
                <RadioButtonGroup
                  id={name}
                  options={[
                    { id: YES, label: content?.yes },
                    {
                      id: NO,
                      label: content?.no
                    }
                  ]}
                  onChange={onChange}
                  selectedId={value}
                  disabled={isLoading || !authorityDetails?.columnContext?.austrianRoadTax}
                  size='sm'
                  horizontal
                />
              )}
            />
          </TwoColumn>
        )}
        {showCostCenter && (
          <>
            <TwoColumn>
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_cost_centre' }}>
                  {content?.region_cost_centre}
                </Text>

                <Popover
                  appearance='dark'
                  text={content?.region_cost_centre_tooltip}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'region_cost_centre_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>

              <Controller
                name='region.costcenterName'
                control={control}
                rules={{
                  required:
                    sendToAddress === SEND_TO_ADDRESS.COST_CENTRE
                      ? content?.validation_required || 'validation_required'
                      : false,
                  maxLength: {
                    value: 20,
                    message: content?.validation_max_length.replace('{{length}}', 20)
                  },
                  pattern: {
                    value: validateAlphaNumericAndSpecialCharacters,
                    message: content?.validation_alph_num_special || 'validation_alph_num_special'
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    textChangeHandler={onChange}
                    value={value}
                    disabled={isLoading}
                    error={!!error?.message}
                    errorMessage={error?.message}
                  />
                )}
              />
            </TwoColumn>
            <TwoColumn>
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_sub_cost_centre' }}>
                  {content?.region_sub_cost_centre}
                </Text>

                <Popover
                  appearance='dark'
                  text={content?.region_sub_cost_centre_tooltip}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'region_sub_cost_centre_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>

              <Controller
                name='region.subcostcenter'
                control={control}
                rules={{
                  maxLength: {
                    value: 20,
                    message: content?.validation_max_20
                  },
                  pattern: {
                    value: validateAlphaNumericAndSpecialCharacters,
                    message: content?.validation_alph_num_special || 'validation_alph_num_special'
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    textChangeHandler={onChange}
                    value={value}
                    disabled={isLoading}
                    error={!!error?.message}
                    errorMessage={error?.message}
                  />
                )}
              />
            </TwoColumn>
          </>
        )}
        <TwoColumn>
          <div>
            <Text display='inline-block' dataAttributes={{ 'data-content-key': 'pin_title' }}>
              {content?.pin_title}
            </Text>
          </div>

          <div>
            {showPinControls && (
              <Controller
                name='pin.active'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={pinOptions.map(({ id, label }) => ({ id, label }))}
                    onChange={id => {
                      onChange(id)
                      if (id !== ALLOW_PIN_SELECTION_ID) setValue('pin.pin', '')
                    }}
                    defaultSelectedId={value}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />
            )}
            {showPinEntry && (
              <Controller
                name='pin.pin'
                control={control}
                rules={{
                  validate: {
                    required: v => (v.length !== 4 ? content?.pin_pin_required || 'pin_pin_required' : null),
                    pinStrength: v => (getIsWeakPin(v) ? content?.pin_pin_week || 'pin_pin_week' : null),
                    numericOnly: v => (!v.match(/^[0-9]+$/) ? content?.pin_numeric_only || 'pin_numeric_only' : null)
                  }
                }}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <PinWrapper>
                    <PinEntry
                      label={content?.pin_pin_label || 'pin_pin_label'}
                      error={!!error}
                      errorMessage={error?.message}
                      onChange={onChange}
                    />
                  </PinWrapper>
                )}
              />
            )}
            {showPinByPost && <PINByDelivery content={content} deliveryAddress={deliveryAddress} />}
          </div>
        </TwoColumn>
        {showOdometer && showAdditionalInformation && (
          <>
            <TwoColumn>
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'pin_odometer' }}>
                  {content?.pin_odometer}
                </Text>

                <Popover
                  appearance='dark'
                  text={content?.pin_odometer_tooltip}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'pin_odometer_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>

              <Controller
                name='pin.odometer'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <RadioButtonGroup
                    id={name}
                    options={[
                      { id: YES, label: content?.yes },
                      { id: NO, label: content?.no }
                    ]}
                    onChange={onChange}
                    selectedId={value}
                    disabled={isLoading}
                    size='sm'
                    horizontal
                  />
                )}
              />
            </TwoColumn>

            <TwoColumn>
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'pin_additional_info' }}>
                  {content?.pin_additional_info}
                </Text>

                <Popover
                  appearance='dark'
                  text={content?.pin_additional_info_tooltip}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'pin_additional_info_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>

              {isLoading ? (
                <Skeleton />
              ) : (
                <Controller
                  name='pin.addinfo'
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <RadioButtonGroup
                      id={name}
                      options={[
                        { id: YES, label: content?.yes },
                        { id: NO, label: content?.no }
                      ]}
                      onChange={id => onChange(id)}
                      selectedId={value}
                      disabled={isLoading}
                      size='sm'
                      horizontal
                    />
                  )}
                />
              )}
            </TwoColumn>
          </>
        )}
        <Button
          type='submit'
          isLoading={isSubmitting}
          disabled={isLoading || isSubmitting}
          dataAttributes={{ 'data-content-key': 'summary_primary_button', 'data-testid': 'button-confirm-order' }}
        >
          {content?.summary_primary_button}
        </Button>
        <Spacer size='xl' />
        <Alert
          visible={isSubmitting || submitErrorMessage}
          state={(isSubmitting && 'info') || (submitErrorMessage && 'error' && 'danger')}
          text={isSubmitting ? content?.summary_submitting : submitErrorMessage}
          iconPrefix
          onClose={() => setSubmitErrorMessage('')}
        />
      </Form>
    </>
  )
}

export default Express
