import { useEffect, useMemo, useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import evBanner from 'src/assets/headers/ev-banner.jpg'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import { ROUTE_DEPOT_PERMISSIONS } from 'constants/routes'
import { ButtonWrapper } from './SelectDepots.styled'
import { Button } from '@bp-digital/component-button'
import { SteppedProgress } from '@bp-digital/component-stepped-progress'
import useContent from 'hooks/useContent'

const SelectDepots = () => {
  const [hasData, setHasData] = useState(false)
  const history = useHistory()
  const { depotsStore, tokensStore, contentStore, userStore } = useRootStore()
  const content = contentStore.getPage('chargepointoperations-select-depots')
  const cardsContent = useContent('chargepointoperations-select-cards')
  const selectedHierarchy = userStore.selectedHierarchy
  const loadingRow = depotsStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  useEffect(() => {
    // authorityId changed, force restart of process
    if (hasData) history.push(ROUTE_DEPOT_PERMISSIONS)
  }, [selectedHierarchy]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!content) {
      contentStore.getContent('chargepointoperations-select-depots')
    }
    if (!cardsContent) {
      contentStore.getContent('chargepointoperations-select-cards')
    }

    if (selectedHierarchy) {
      depotsStore.getDepots({ selectedHierarchy })
      depotsStore.setSelectedRows(data)
      setHasData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHierarchy, content, cardsContent, contentStore, depotsStore.data, depotsStore.isFetchingData])

  const tablePagination = {
    currentPage: depotsStore.currentPage,
    count: depotsStore.numberOfPages || 1,
    disabled: !depotsStore.data,
    onChange: page => depotsStore.getDepots({ selectedHierarchy, page })
  }

  const data = useMemo(
    () =>
      depotsStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : depotsStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depotsStore.rows, depotsStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      depotsStore.isFetchingData
        ? depotsStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : depotsStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depotsStore.columns, depotsStore.isFetchingData, tablePagination.currentPage]
  )

  const handleRowSelected = useCallback(
    rows => {
      depotsStore.setSelectedRows(rows)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depotsStore.setSelectedRows]
  )

  const handleSubmitChanges = () => {
    const selectedTokens = Object.keys(tokensStore.selectedRows)
    const selectedDepots = depotsStore.selectedRows && Object.keys(depotsStore.selectedRows)

    tokensStore.postAuthorisations(selectedHierarchy, selectedTokens, selectedDepots)
  }

  if (!tokensStore.hasSelectedRows || tokensStore.responseStatus) {
    return <Redirect to={ROUTE_DEPOT_PERMISSIONS} />
  }

  return (
    <>
      <PageHeader
        title={content?.depot_permissions_timeline_select_depots || '...'}
        subtitle={content?.select_depots_view_title || '...'}
        headerImage={evBanner}
        brand={userStore.brand}
      />
      <SteppedProgress
        items={[
          cardsContent?.select_cards_view_title || 'Step 1: Select cards',
          content?.select_depots_view_title || 'Step 2: Select depots'
        ]}
        selectedIndex={1}
        dataAttributes={{ 'data-testid': 'depot-permissions-step-two' }}
      />
      <InnerPageWrapper>
        <ReactTable
          name='select-depots'
          autoResetSelectedRows
          columns={columns}
          data={
            data && data.length
              ? data.map(el => ({
                  ...el,
                  chargerName: el.siteAlias ? el.siteAlias : el.chargePoints?.[0]?.chargePointId || '-'
                }))
              : data
          }
          pagination={tablePagination}
          isLoading={depotsStore.isFetchingData}
          initiallySelectedRows={depotsStore.selectedRows}
          onSelectedRowsChange={handleRowSelected}
          noResultsMessage={content?.select_depots_no_results}
        />
        <ButtonWrapper>
          <Button appearance='secondary' borderRadius='pill' to={ROUTE_DEPOT_PERMISSIONS}>
            {content?.previous_step}
          </Button>
          <Button
            appearance='primary'
            borderRadius='pill'
            onClick={handleSubmitChanges}
            disabled={depotsStore.isFetchingData}
          >
            {content?.save_step}
          </Button>
        </ButtonWrapper>
      </InnerPageWrapper>
    </>
  )
}

export default observer(SelectDepots)
