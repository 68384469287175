import { CARD_STATUS_ID } from 'constants/cards'

export const getStateFromCardStatus = (statusId: number) => {
  const {
    AWAITING_REISSUE_NEW,
    AWAITING_PRODUCTION,
    CARD_DISPATCHED,
    CARD_DESTROYED_BP,
    CARD_EMBOSSED,
    REISSUED,
    REPLACED,
    LOST,
    STOLEN,
    ACTIVE,
    DESTROYED,
    NOT_FOR_REISSUE
  } = CARD_STATUS_ID

  if (
      statusId === ACTIVE ||
      statusId === REISSUED ||
      statusId === AWAITING_REISSUE_NEW ||
      statusId === AWAITING_PRODUCTION || 
      statusId === CARD_EMBOSSED ||
      statusId === CARD_DISPATCHED
    ) {
    return 'success'
  } else if (
    statusId === STOLEN ||
    statusId === LOST ||
    statusId === NOT_FOR_REISSUE ||
    statusId === CARD_DESTROYED_BP ||
    statusId === REPLACED ||
    DESTROYED.split('_').includes(String(statusId))
  ) {
    return 'danger'
  }

  return 'warning'
}
