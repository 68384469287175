import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'register/verify-invite'
type tokenType = string | null

const queryFn = async (token: tokenType) => {
  if (!token) throw new Error('No token given to /invite/verify/otap endpoint')

  const result = await axios.get('/api/users/users/otap', {
    params: {
      Token: token
    }
  })

  return result.data
}

const useApiVerifyInvite = (token: tokenType, options: { staleTime: number | typeof Infinity }) => {
  return useQuery([QUERY_KEY, { token: token }], () => queryFn(token), {
    ...options
  })
}

export default useApiVerifyInvite
