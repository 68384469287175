import styled from 'styled-components'
import FlexibleLink from '../FlexibleLink'
import breakpoints from 'src/styles/breakpoints'

interface IconLinkProps {
  $isMobileOnly?: boolean
}

export const heightOfMobileTopNav = '66px'

export const Wrapper = styled.div`
  display: flex;
  height: calc(${heightOfMobileTopNav} - 1px);
  width: 100%;
  background-color: var(--color-grey-regular-700);
  border-bottom: 1px solid #ccc;
  position: fixed;
  z-index: 800;

  @media only screen and ${breakpoints.device.lg} {
    background-color: #fff;
    width: calc(100% - 300px);
  }
`

export const Logo = styled.img`
  height: 100%;
  padding: 8px;

  @media only screen and ${breakpoints.device.lg} {
    display: none;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  height: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row-reverse;
  }
`

export const IconLink = styled(FlexibleLink)`
  display: inherit;

  &:hover {
    cursor: pointer;
  }

  @media only screen and ${breakpoints.device.lg} {
    display: ${(props: IconLinkProps) => (props.$isMobileOnly ? 'none' : 'inherit')};
  }
`

export const MobileAuthoritySelectionWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--color-grey-regular-300);
  margin-top: 65px;

  @media only screen and ${breakpoints.device.lg} {
    display: none;
  }
`

export const DesktopAuthoritySelectionWrapper = styled.div`
  display: none;

  @media only screen and ${breakpoints.device.lg} {
    display: flex;
  }
`

export const PageOverlay = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 800;

  @media only screen and ${breakpoints.device.lg} {
    display: none;
  }
`
