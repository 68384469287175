export const setDataLayerValue = (key, value, eventType = 'customer_attributes') => {
  const dlObject = {
    event: eventType,
    [key]: value
  }

  setDataLayer(dlObject)
}

export const setDataLayerMultipleValues = (pairs, eventType = 'customer_attributes') => {
  const dlObject = {
    ...pairs,
    event: eventType
  }

  setDataLayer(dlObject)
}

const setDataLayer = dlObject => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dlObject)
}
