import { useState, useEffect } from 'react'
import { DropDown } from '@bp-digital/component-drop-down'
import useApiGetRestrictionBaseProfiles from 'hooks/api/useApiGetRestrictionBaseProfiles'
import RestrictionDetails from './RestrictionDetails'
import { RestrictionsWrapper } from '../common/CardOrder.styled'

const Restrictions = ({ data: { currency, distanceUnit, params }, content, unregister, onChange }) => {
  const { data: baseProfiles } = useApiGetRestrictionBaseProfiles(params, { retry: 1 })

  const [selectedProfileId, setSelectedProfileId] = useState(null)

  useEffect(() => {
    return () => {
      setSelectedProfileId(null)
      unregister()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RestrictionsWrapper>
      <DropDown
        label={
          content?.setup_card_restriction_existing_profile_choose || 'setup_card_restriction_existing_profile_choose'
        }
        options={
          baseProfiles?.map(({ profileId, profileName }) => ({
            id: `${profileId}-${profileName}`,
            label: profileName
          })) || []
        }
        placeholder={content?.dropdown_select || 'dropdown_select'}
        onChange={id => {
          const [profileId, profileName] = id.split('-')
          setSelectedProfileId(Number(profileId))
          onChange({ profileId, profileName })
        }}
      />

      {selectedProfileId && (
        <RestrictionDetails
          content={content}
          profileId={selectedProfileId}
          currency={currency}
          distanceUnit={distanceUnit}
        />
      )}
    </RestrictionsWrapper>
  )
}

export default Restrictions
