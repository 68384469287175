import React, { ReactElement } from 'react'
import { Icon } from '@bp-digital/component-icon'
import { UserMenuItemProps } from '../UserMenu.types'
import { UserMenuDisabledLink, UserMenuLink, UserMenuButton, UserMenuItemIconContainer } from './UserMenuItem.style'

const UserMenuItem = ({
  iconName,
  text,
  to,
  href,
  onClick,
  dataAttributes,
  target = '_self',
  disabled = false,
  ariaLabel = `${text}-user-menu-link`
}: UserMenuItemProps): ReactElement => {
  const handleOnClick = () => !disabled && onClick && onClick()

  const renderDisabledLink = () => (
    <UserMenuDisabledLink aria-label={ariaLabel} aria-disabled={true} {...dataAttributes}>
      <UserMenuItemIconContainer>
        <Icon name={iconName} size='md' />
      </UserMenuItemIconContainer>
      {text}
    </UserMenuDisabledLink>
  )

  const renderLink = () => {
    return to || href ? (
      <UserMenuLink
        onClick={handleOnClick}
        to={to}
        href={href}
        target={target}
        aria-label={ariaLabel}
        {...dataAttributes}
      >
        <UserMenuItemIconContainer>
          <Icon name={iconName} size='md' />
        </UserMenuItemIconContainer>
        {text}
      </UserMenuLink>
    ) : (
      <UserMenuButton type='button' onClick={handleOnClick} aria-label={ariaLabel} {...dataAttributes}>
        <UserMenuItemIconContainer>
          <Icon name={iconName} size='md' />
        </UserMenuItemIconContainer>
        {text}
      </UserMenuButton>
    )
  }

  return disabled ? renderDisabledLink() : renderLink()
}

export default UserMenuItem
