import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ROUTE_ADMIN } from 'constants/routes'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { format } from 'light-date'
import xlsx from 'json-as-xlsx'
import debounce from 'lodash.debounce'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import evBanner from 'src/assets/headers/ev-banner.jpg'
import getValueOrFallback from 'src/helpers/getValueOrFallback'
import SingleChargerView from './components/SingleChargerView'
import columnsForCdrDownload, { contentKeyMap } from './columnsForCdrDownload'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { FEATURE_FLAGS } from 'constants/featureFlags'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import { getTimeFromDate } from 'helpers/getTimeFromDate'

const ChargeHistory = () => {
  const { chargeHistoryStore, contentStore, userStore } = useRootStore()
  const content = contentStore.getPage('chargepointoperations-charginghistory')
  const mountedRef = useRef(true)
  // const authorityId = userStore.selectedAuthorityId
  const selectedHierarchy = userStore.selectedHierarchy
  const userLocale = userStore.locale
  const showDownload = FEATURE_FLAGS.SHOW_CDR_DOWNLOAD
  const showDate = FEATURE_FLAGS.SHOW_CDR_DATE_COLUMN
  const loadingRow = chargeHistoryStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  const simpleFilters = [
    { id: '', label: content?.cpo_charging_history_all_sessions || 'All Sessions' },
    { id: 'depot', label: content?.cpo_charging_history_depot_only || 'Depot Only' },
    { id: 'public', label: content?.cpo_charging_history_on_the_go_only || 'Public only' }
  ]
  const activeFilter = simpleFilters.find(item => item.id === chargeHistoryStore.simpleFilter) || simpleFilters[0]

  const downloadProps = {
    text: content?.cpo_charging_history_download_button || 'Download',
    enableOnSelection: true,
    iconName: 'Download',
    size: 'lg',
    dataAttributes: {
      'data-testid': 'button-download-cdrs'
    },
    onClick: selectedRows => {
      const cdrs = getCdrsByIds(selectedRows)
      const date = format(new Date(), '{dd}{MM}{yy}')

      xlsx([cdrs], {
        fileName: `${content?.cpo_charging_history_title || 'ChargingHistory'}_${
          selectedHierarchy?.accessLevelCode
        }_${date}`,
        extraLength: 3,
        writeOptions: {}
      })
    }
  }
  const filterProps = {
    title: activeFilter.label,
    items: simpleFilters,
    onChange: newFilter => {
      chargeHistoryStore.setCurrentPage(1)
      chargeHistoryStore.setSimpleFilter(newFilter)
      chargeHistoryStore.getHistory({ selectedHierarchy })
    },
    size: 'lg'
  }
  const tableActionProps = [filterProps]
  showDownload && tableActionProps.push(downloadProps)
  const [redirectAdmin, setRedirectAdmin] = useState(false)

  useEffect(() => {
    if (!mountedRef.current) return null
    if (userStore.isAdmin && !userStore.impersonatedUser) setRedirectAdmin(true)
  }, [userStore.isAdmin, userStore.impersonatedUser])
  useEffect(() => {
    if (!mountedRef.current) return null
    if (selectedHierarchy?.accessLevel && selectedHierarchy?.accessLevelCode) {
      chargeHistoryStore.getHistory({ selectedHierarchy })
    }
  }, [selectedHierarchy, contentStore, userStore, chargeHistoryStore])

  useEffect(() => {
    if (!mountedRef.current) return null
    if (!content) {
      contentStore.getContent('chargepointoperations-charginghistory')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const getCdrsByIds = (cdrIds = []) => {
    const rawData = toJS(chargeHistoryStore.data)
    const cdrPages = Object.values(rawData)

    const cdrs = cdrIds.map(id => {
      return cdrPages.map(pageOfCdrs => pageOfCdrs.find(cdr => cdr.id === id)).find(val => !!val)
    })

    const cdrsWithContent = cdrs.map(cdr => {
      const renamedCdr = {}
      var keys1 = Object.keys(cdr)
      var keys2 = Object.keys(cdr.site)
      const keys = [...keys1, ...keys2]

      keys.forEach(originalKey => {
        if (columnsForCdrDownload.includes(originalKey)) {
          const friendlyName = columns.find(column => column.key === originalKey)?.name || originalKey
          if (cdr[originalKey]) renamedCdr[friendlyName] = getValueOrFallback(cdr[originalKey])
          else renamedCdr[friendlyName] = getValueOrFallback(cdr.site[originalKey])
        }
      })

      return renamedCdr
    })

    return {
      sheet: content?.cpo_charging_history_title || 'Charging history',
      columns: columnsForCdrDownload.map(col => ({ label: content?.[contentKeyMap[col]] || col, value: col })),
      content: cdrsWithContent
    }
  }

  const handleSubmitSearch = async () => {
    await chargeHistoryStore.getHistory({ selectedHierarchy })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      chargeHistoryStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    chargeHistoryStore.setSearchTerm('')
    chargeHistoryStore.setSearchLimits({})
    chargeHistoryStore.getHistory({ selectedHierarchy })
  }

  const tableSearchProps = {
    searchTerm: chargeHistoryStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.cpo_charging_history_search_placeholder || 'Search for [Criteria]',
    inputAriaLabel: content?.cpo_charging_history_search_placeholder || 'Search for [Criteria]',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search',
    minLength: 3,
    tooltipMessage:
      content?.cpo_charging_history_search_tooltip ||
      'Only alphanumeric characters and date format DD/MM/YYYY are allowed'
  }

  const tablePagination = {
    currentPage: chargeHistoryStore.currentPage,
    count: chargeHistoryStore.numberOfPages || 1,
    pageSize: chargeHistoryStore.pageSize,
    disabled: !chargeHistoryStore.data,
    onChange: page =>
      chargeHistoryStore.getHistory({
        selectedHierarchy,
        page,
        pageSize: chargeHistoryStore.pageSize
      }),
    onSizeChange: pageSize =>
      chargeHistoryStore.getHistory({
        selectedHierarchy,
        page: 1,
        pageSize
      })
  }

  const data = useMemo(
    () =>
      chargeHistoryStore.isFetchingData
        ? Array(tablePagination.pageSize)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : chargeHistoryStore.rows.map(row => {
            return showDate
              ? {
                  ...row,
                  date: getUserFormattedDate(row.startDate, userStore.dateFormat, true),
                  startDate: getTimeFromDate(getUserFormattedDate(row.startDate, userStore.dateFormat)),
                  endDate: getTimeFromDate(getUserFormattedDate(row.endDate, userStore.dateFormat))
                }
              : {
                  ...row,
                  startDate: getUserFormattedDate(row.startDate, userStore.dateFormat),
                  endDate: getUserFormattedDate(row.endDate, userStore.dateFormat)
                }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargeHistoryStore.rows, chargeHistoryStore.isFetchingData, tablePagination.currentPage, tablePagination.pageSize]
  )

  const columns = useMemo(
    () =>
      chargeHistoryStore.isFetchingData
        ? chargeHistoryStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : chargeHistoryStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key,
              disableSortBy: ['startDate', 'endDate'].includes(col.key)
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargeHistoryStore.columns, chargeHistoryStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortChargeHistory = async (key = 'endDate', desc = true) => {
        key = key === 'date' ? 'startDate' : key
        key = key === 'chargerName' ? 'siteAlias' : key
        const columnSorting = [key, desc ? 'desc' : 'asc']
        chargeHistoryStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!chargeHistoryStore.results.length && chargeHistoryStore.searchTerm.length) {
          await chargeHistoryStore.getHistory({
            selectedHierarchy,
            columnSorting,
            searchTerm: ''
          })
        }
        await chargeHistoryStore.getHistory({
          selectedHierarchy,
          columnSorting
        })
      }
      if (chargeHistoryStore.rows.length > 0) {
        sortChargeHistory(key, desc)
      }
    },
    [selectedHierarchy, chargeHistoryStore]
  )

  const handleRowSelected = useCallback(
    rowIDs => {
      chargeHistoryStore.setSelectedRows(rowIDs)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargeHistoryStore.setSelectedRows]
  )

  if (!mountedRef.current) return
  if (redirectAdmin) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.cpo_charging_history_title || '...'}
        subtitle={content?.masthead_all || '...'}
        headerImage={evBanner}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <ReactTable
          initiallySelectedRows={chargeHistoryStore.selectedRows}
          onSelectedRowsChange={handleRowSelected}
          columns={columns}
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          isLoading={chargeHistoryStore.isFetchingData}
          expandSubRowOnSelect={false}
          totalRecords={chargeHistoryStore.limits.totalRecords}
          noResultsMessage={replaceVariablesInString(content?.cpo_charging_history_none_found || '...', {
            searchTerm: chargeHistoryStore.searchTerm || ''
          })}
          renderDetail={({
            original: { chargePointId, siteAddress, evseId, connectorSpeed, mapCenter, siteId, cdrType }
          }) => (
            <SingleChargerView
              content={content}
              address={siteAddress}
              evseId={evseId}
              speed={connectorSpeed}
              mapCenter={mapCenter}
              chargePointId={chargePointId}
              siteId={siteId}
              locale={userLocale}
              cdrType={cdrType}
            />
          )}
          onSort={handleSort}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(ChargeHistory)
