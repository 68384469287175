import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertModal } from '@bp-digital/component-alert-modal'
import debounce from 'lodash.debounce'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import {
  ROUTE_ACCESS_ADMINISTRATION_USER_ADD,
  ROUTE_ACCESS_ADMINISTRATION_USER,
  ROUTE_ADMIN
} from 'src/constants/routes'
import { useHistory, Redirect } from 'react-router-dom'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import { Text } from '@bp-digital/component-typography'
import { EMAILADDRESS } from 'constants/onefleetmigrated'


const AccessUserPage = ({ displayHeader }) => {
  const history = useHistory()
  const { accessAdminUserStore, userStore, contentStore } = useRootStore()
  const [showEditUserWarningModal, setShowEditUserWarningModal] = useState(false)
  const content = contentStore.getPage('access-users')
  const blockuserinvitationflow = parseInt(userStore.onefleetmigratedstatus) > 0
  accessAdminUserStore.setAdminView(false)

  const loadingRow = accessAdminUserStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  const simpleFilters = [
    { id: '', label: content?.status_all_status || 'All' },
    { id: 'active', label: content?.status_active || 'Active' },
    { id: 'invited', label: content?.status_invited || 'Invited' },
    { id: 'link expired', label: content?.status_link_expired || 'Link Expired' }
  ]
  const activeFilter = simpleFilters.find(item => item.id === accessAdminUserStore.simpleFilter) || simpleFilters[0]

  useEffect(() => {
    accessAdminUserStore.setSearchTerm('')
    accessAdminUserStore.setSimpleFilter('')

    accessAdminUserStore.getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('access-users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  // below is for page reload - to solve the local storage key problem and lazy loadng of selectedHierarchy
  useEffect(() => {
    accessAdminUserStore.getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.selectedHierarchy])

  const handleSubmitSearch = async () => {
    await accessAdminUserStore.getUsers()
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      accessAdminUserStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    accessAdminUserStore.setSearchTerm('')
    accessAdminUserStore.setSearchLimits({})
    accessAdminUserStore.getUsers()
  }

  const tableSearchProps = {
    searchTerm: accessAdminUserStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.search_users || '...',
    inputAriaLabel: content?.search_users || '...',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }
  const tableActionProps = [
    {
      title: activeFilter.label,
      items: simpleFilters,
      onChange: newFilter => {
        accessAdminUserStore.setSimpleFilter(newFilter)
        accessAdminUserStore.getUsers()
      },
      size: 'lg'
    },
    {
      to: ROUTE_ACCESS_ADMINISTRATION_USER_ADD,
      text: content?.new_user || '...',
      iconName: 'User',
      size: 'lg',
      disabled : blockuserinvitationflow,
      dataAttributes: {
        'data-testid': 'button-new-user'
      }
    }
  ]

  const tablePagination = {
    currentPage: accessAdminUserStore.currentPage,
    count: accessAdminUserStore.numberOfPages || 1,
    disabled: !accessAdminUserStore.data,
    onChange: value => accessAdminUserStore.getUsers({ page: value })
  }

  const handleRowClick = useCallback(
    (_event, { key }) => {
      history.push(`${ROUTE_ACCESS_ADMINISTRATION_USER}/${key}`)
    },
    [history]
  )

  const data = useMemo(
    () => {
      return accessAdminUserStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : accessAdminUserStore.rows
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessAdminUserStore.rows, accessAdminUserStore.isFetchingData, tablePagination.currentPage]
  )

  const disabledSortColumnKeys = ['groupselection_description', 'mobileNumber']

  const columns = useMemo(
    () =>
      accessAdminUserStore.isFetchingData
        ? accessAdminUserStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />,
            disableSortBy: !!disabledSortColumnKeys.find(key => key === col.key)
          }))
        : accessAdminUserStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            disableSortBy: !!disabledSortColumnKeys.find(key => key === col.key)
          })),
    [accessAdminUserStore.isFetchingData] //eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortUsers = async (key, desc) => {
        // No default column sort key in on initial fetch,
        // so default to empty [] when no sorting applied to return initial data
        const columnSorting = key ? [key, desc ? 'desc' : 'asc'] : []
        accessAdminUserStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!accessAdminUserStore.results.length && accessAdminUserStore.searchTerm.length) {
          await accessAdminUserStore.getUsers({
            columnSorting,
            searchTerm: ''
          })
        }
        await accessAdminUserStore.getUsers({
          columnSorting
        })
      }
      if (accessAdminUserStore.rows.length > 0) {
        sortUsers(key, desc)
      }
    },
    [accessAdminUserStore]
  )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      {displayHeader && (
        <PageHeader
          title={content?.users_title || '...'}
          subtitle={`${
            accessAdminUserStore.searchTerm && accessAdminUserStore.searchResults
              ? accessAdminUserStore.searchLimits?.totalRecords
              : accessAdminUserStore.limits?.totalRecords || '...'
          } ${content?.users_title?.toLowerCase() || ''}`}
          breadcrumbs={[{ to: '/access/users', title: 'User administration', ariaLabel: 'User administration' }]}
          brand={userStore.brand}
        />
      )}
      <AlertModal
        state='warning'
        visible={showEditUserWarningModal}
        primaryActionText='OK'
        title='Too many users selected'
        text='Select a single user to edit'
        onDismiss={() => setShowEditUserWarningModal(false)}
        onPrimaryAction={() => setShowEditUserWarningModal(false)}
      />

      <InnerPageWrapper>
        { blockuserinvitationflow && (
          <>
          <Text>
            {content?.onefleetmigrated_block_newuser_inviteJourney_message || 'content?.onefleetmigrated_block_newuser_inviteJourney_message'}
          </Text>
          <Text>
            <a href={`mailto:${EMAILADDRESS}`}>{EMAILADDRESS}</a>
          </Text>
        </>
        )}
        <ReactTable
          name='users-administration'
          columns={columns}
          hideCheckboxColumn
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          handleRowClick={handleRowClick}
          onSort={handleSort}
          isLoading={accessAdminUserStore.isFetchingData}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(AccessUserPage)
