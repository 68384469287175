import { PRODUCT_TYPE } from 'constants/products'

const replaceKeywords = (contentKey, value, currency, content) => {
  return content[contentKey]?.replace('{{value}}', value)?.replace('{{currency}}', currency)
}

export const formatProduct = (product, currency, content, keyPrefix) => {
  const values = []

  if (!product.isAllow) {
    values.push(content[`${keyPrefix}product_not_allowed`])
  } else {
    const { productServiceType, productDetails: { daily, monthly, transaction } = {} } = product

    if (productServiceType === PRODUCT_TYPE.fuel && Number(daily.dailyMaxVolume) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_daily_max_volume`, daily.dailyMaxVolume, currency, content)
      values.push(value)
    }

    if (productServiceType === PRODUCT_TYPE.nonFuel && Number(daily.dailyMaxVolume) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_daily_max_quantity`, daily.dailyMaxVolume, currency, content)
      values.push(value)
    }

    if (Number(daily.dailyMaxValue) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_daily_max_value`, daily.dailyMaxValue, currency, content)
      values.push(value)
    }

    if (productServiceType === PRODUCT_TYPE.fuel && Number(monthly.monthlyMaxVolume) !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_monthly_max_volume`,
        monthly.monthlyMaxVolume,
        currency,
        content
      )
      values.push(value)
    }

    if (productServiceType === PRODUCT_TYPE.nonFuel && Number(monthly.monthlyMaxVolume) !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_monthly_max_qunatity`,
        monthly.monthlyMaxVolume,
        currency,
        content
      )
      values.push(value)
    }

    if (Number(monthly.monthlyMaxValue) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_monthly_max_value`, monthly.monthlyMaxValue, currency, content)
      values.push(value)
    }

    if (productServiceType === PRODUCT_TYPE.fuel && Number(transaction.txnMaxVolume) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_txn_max_volume`, transaction.txnMaxVolume, currency, content)
      values.push(value)
    }

    if (productServiceType === PRODUCT_TYPE.nonFuel && Number(transaction.txnMaxVolume) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_txn_max_quantity`, transaction.txnMaxVolume, currency, content)
      values.push(value)
    }

    if (Number(transaction.txnMaxValue) !== 0) {
      const value = replaceKeywords(`${keyPrefix}product_txn_max_value`, transaction.txnMaxValue, currency, content)
      values.push(value)
    }

    if (
      daily.dailyMaxValue === null &&
      daily.dailyMaxVolume === null &&
      monthly.monthlyMaxValue === null &&
      monthly.monthlyMaxVolume === null &&
      transaction.txnMaxValue === null &&
      transaction.txnMaxVolume === null
    ) {
      const value = content[`${keyPrefix}product_allowed`]
      values.push(value)
    }
  }

  return values
}
