import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import debounce from 'lodash.debounce'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import PreferencesEditor from 'src/components/dataDisplay/PreferencesEditor'
import { useRootStore } from 'src/contexts/StoreContext'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import { ROUTE_CARDS_REISSUE, ROUTE_ADMIN } from 'src/constants/routes'
import ViewCardDetail from '../ViewManageCards/components/ViewCardDetail'
import Skeleton from 'react-loading-skeleton'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import { Toast } from '@bp-digital/component-toast'
import { Redirect } from 'react-router-dom'


const CardsForReissuePage = () => {
  const { cardReissueStore, contentStore, viewManageCardStore, userStore } = useRootStore()
  const [isEditingColumns, setIsEditingColumns] = useState(false)
  const content = contentStore.getPage('cards-reissue')
  const authorityId = userStore.selectedAuthorityId
  const loadingRow = cardReissueStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  const simpleFilters = [
    { id: 'notForReissue', label: content?.advancedFilters_not_for_reissue || 'Not for re-issue' },
    { id: 'awaitReissue', label: content?.advancedFilters_awaiting_reissue || 'Awaiting re-issue' }
  ]
  const activeFilter = simpleFilters.find(item => item.id === cardReissueStore.simpleFilter) || simpleFilters[0]
  const [toastData, setToastData] = useState({})
  useEffect(() => {
    return () => {
      cardReissueStore.setSearchTerm('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authorityId && userStore.preferences && content) {
      cardReissueStore.getCards({ authorityId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityId, userStore.preferences, content])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('cards-reissue')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const handleSubmitSearch = async () => {
    await cardReissueStore.getCards({ authorityId })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      cardReissueStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    cardReissueStore.setSearchTerm('')
    cardReissueStore.setSearchLimits({})
    cardReissueStore.getCards({ authorityId })
  }

  const handlePreferenceChange = async newlySelectedColumns => {
    await userStore.updatePreferences('cards', newlySelectedColumns)
    await userStore.getPreferences()
    await cardReissueStore.getCards({ authorityId })
  }

  const tableSearchProps = {
    searchTerm: cardReissueStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.manage_cards_reissue_find_byname ?? 'Find card by holder name',
    inputAriaLabel: content?.manage_cards_reissue_find_byname ?? 'Find card by holder name',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  const tableActionProps = [
    {
      title: activeFilter.label,
      items: simpleFilters,
      onChange: newFilter => {
        cardReissueStore.setSimpleFilter(newFilter)
        cardReissueStore.getCards({ authorityId })
      },
      size: 'lg',
      dataAttributes: {
        'data-testid': 'button-filter-reissue-status'
      }
    },
    {
      text: content?.tableFilter_ctaColumn || 'Columns',
      iconName: 'Edit',
      size: 'lg',
      onClick: () => setIsEditingColumns(true),
      dataAttributes: {
        'data-testid': 'button-edit-columns',
        'data-content-key': 'tableFilter_ctaColumn'
      }
    }
  ]

  const tablePagination = {
    currentPage: cardReissueStore.currentPage,
    count: cardReissueStore.numberOfPages || 1,
    disabled: !cardReissueStore.data,
    onChange: value => cardReissueStore.getCards({ authorityId, page: value })
  }

  const data = useMemo(
    () =>
      cardReissueStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : cardReissueStore.rows.map(row => {
            return {
              ...row,
              fuelAndCharge:
                row.fuelAndCharge === 'Y' ? content?.fuel_and_charge_yes || 'Yes' : content?.fuel_and_charge_no || 'No'
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardReissueStore.rows, cardReissueStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      cardReissueStore.isFetchingData
        ? cardReissueStore.columns.map(col => ({
            Header: col?.name || '...',
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : cardReissueStore.columns.map(col => {
            return {
              Header: col?.name || '...',
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardReissueStore.columns, cardReissueStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortCards = async (key = 'cardSerialNumber', desc = false) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        cardReissueStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!cardReissueStore.results.length && cardReissueStore.searchTerm.length) {
          await cardReissueStore.getCards({
            authorityId,
            columnSorting,
            searchTerm: ''
          })
        }
        await cardReissueStore.getCards({
          authorityId,
          columnSorting
        })
      }
      if (cardReissueStore.rows.length > 0) {
        sortCards(key, desc)
      }
    },
    [authorityId, cardReissueStore]
  )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.manage_cards_reissue_hero_title || '...'}
        subtitle={replaceVariablesInString(content?.reissue_showing || '...', {
          amount: cardReissueStore.rows.length,
          totalProfiles: cardReissueStore.limits?.totalRecords || '0'
        })}
        breadcrumbs={[
          {
            to: ROUTE_CARDS_REISSUE,
            title: content?.manage_cards_reissue_hero_title,
            ariaLabel: content?.manage_cards_reissue_hero_title
          }
        ]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        {isEditingColumns && content && (
          <PreferencesEditor
            initialSelectedColumns={viewManageCardStore.selectedColumns}
            setIsOpen={setIsEditingColumns}
            onUpdate={handlePreferenceChange}
            sectionName='cards'
            getAvailableColumns={() => viewManageCardStore.getAvailableColumns(authorityId)}
            content={content}
            locale={userStore.locale}
          />
        )}
        <ReactTable
          name='reissue-cards'
          columns={columns}
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          showSelectAllCheckbox={true}
          expandSubRowOnSelect={false}
          renderDetail={({ original }) => (
            <ViewCardDetail
              content={content}
              {...original}
              authorityId={authorityId}
              setToastData={setToastData}
              hasReissue
            />
          )}
          onSort={handleSort}
          isLoading={cardReissueStore.isFetchingData}
        />
        <Toast appearance='dark' toast={toastData} />
      </InnerPageWrapper>
    </>
  )
}

export default observer(CardsForReissuePage)
