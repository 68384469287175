import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  flex: 1 0 auto;

  @media only screen and ${breakpoints.device.lg} {
    padding-top: 65px;
  }
`
