import { useState } from 'react'
import { useHistory } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { Heading } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Switch } from '@bp-digital/component-switch'
import { TimeRangeSlider } from '@bp-digital/component-time-range-slider'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import buildRequest from './helpers/buildRequest'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import TwoColumn from 'components/layout/TwoColumn'
import { StateMessage } from '@bp-digital/component-state-message'
import { getDayAndTimeByType, getViolationId, formatTime } from 'helpers/restrictions'
import {
  ALLOWED_DAYS,
  CUSTOM,
  DAYS,
  END_TIME,
  START_TIME,
  VIOLATION_TYPE,
  WEEKDAYS,
  WEEKENDS,
  MODE
} from 'constants/restrictions'
import { ButtonWrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { Spacer } from 'styles/common.styled'
import { CustomControls, DayHeading, Days, SliderContainer } from './EditRestrictionDayAndTime.styled'

const defaultRestrictionSettings = Array(7)
  .fill()
  .reduce((accumulator, _, index) => {
    accumulator[index + 1] = { isDisabled: false, value: true, isDirty: false }
    return accumulator
  }, {})

const DayAndTime = ({ authorityId, data, content, onBack, onNext, mode = MODE.EDIT, defaultValues, title }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { profileId, profileName, dayTimeRestriction } = data || {}

  const [showCustomSetup, setShowCustomSetup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const {
    handleSubmit,
    reset,
    setValue: setFormValue,
    control,
    watch
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      daySelection: getDayAndTimeByType(dayTimeRestriction?.setupType) || WEEKDAYS,
      customDays: dayTimeRestriction?.setupType === 'C' ? [...dayTimeRestriction.daysWithTime] : [],
      selectedDayIndex:
        dayTimeRestriction?.daysWithTime?.sort((a, b) => a.dayOfWeek - b.dayOfWeek)?.[0]?.dayOfWeek || 1,
      restrictionSettings: defaultRestrictionSettings,
      violationId:
        getViolationId(dayTimeRestriction?.acceptDayTimeViolation, dayTimeRestriction?.alertDayTimeViolation) ||
        VIOLATION_TYPE.ACCEPT_AND_ALERT,
      ...(defaultValues ? defaultValues : {})
    }
  })

  const violationId = watch('violationId')
  const daySelection = watch('daySelection')
  const customDays = watch('customDays')
  const selectedDayIndex = watch('selectedDayIndex')
  const restrictionSettings = watch('restrictionSettings')

  const newDayItem = (index, startTime = START_TIME, endTime = END_TIME, isAllow = ALLOWED_DAYS.ALLOW) => ({
    dayOfWeek: index,
    isAllow,
    startTime,
    endTime
  })

  const findCustomDay = (index, from, to, isAllow) => {
    const item = customDays.find(item => item.dayOfWeek === index)

    if (item) {
      if (from) item.startTime = from
      if (to) item.endTime = to
      if (isAllow !== undefined) item.isAllow = isAllow
    }

    return item ? item : newDayItem(index, from, to, isAllow)
  }

  const handleTimeRange = ({ from, to }) => {
    const selectedDay = findCustomDay(selectedDayIndex, from, to)
    setFormValue('customDays', [...customDays.filter(day => day !== selectedDay), selectedDay])
    setFormValue('restrictionSettings', {
      ...restrictionSettings,
      [selectedDayIndex]: { ...restrictionSettings[selectedDayIndex], isDirty: true }
    })
  }

  const handleApplyToAllDays = () => {
    const selectedDay = findCustomDay(selectedDayIndex)
    const setDay = (day, index) => ({ ...day, dayOfWeek: index + 1 })

    const updatedDays = Array(7).fill(selectedDay).map(setDay)
    const updatedRestrictionSettings = {}

    for (const day in restrictionSettings) {
      updatedRestrictionSettings[day] = {
        ...restrictionSettings[day],
        isDirty: true
      }
    }
    setFormValue('customDays', updatedDays)
    setFormValue('restrictionSettings', updatedRestrictionSettings)
  }

  const handleCustomPermission = isAllow => {
    const selectedDay = findCustomDay(selectedDayIndex, undefined, undefined, isAllow)
    setFormValue('customDays', [...customDays.filter(day => day !== selectedDay), selectedDay])
    setFormValue('restrictionSettings', {
      ...restrictionSettings,
      [selectedDayIndex]: { ...restrictionSettings[selectedDayIndex], isDirty: true }
    })
  }

  const markForRemoval = item => {
    if (item.dayOfWeek === selectedDayIndex) {
      item.selectedForRemoval = true
    }
    return item
  }

  const deleteMarkForRemoval = item => {
    if (item.dayOfWeek === selectedDayIndex) {
      delete item.selectedForRemoval
    }
    return item
  }

  const handleSwitch = value => {
    if (!value) setFormValue('customDays', customDays.map(markForRemoval))
    else setFormValue('customDays', customDays.map(deleteMarkForRemoval))

    setFormValue('restrictionSettings', {
      ...restrictionSettings,
      [selectedDayIndex]: { isDisabled: !value, value, isDirty: true }
    })
  }

  const renderCustomSelection = () => {
    const isCustomSetup = dayTimeRestriction?.setupType === 'C'

    const DayAndTimeRestrictions = ({ dayIndex }) =>
      dayIndex === selectedDayIndex && (
        <>
          <DayHeading as='h3'>{content?.[`restriction_date_time_${DAYS[selectedDayIndex]}`]}</DayHeading>

          <RadioButtonGroup
            defaultSelectedId={findCustomDay(selectedDayIndex).isAllow}
            id='permissions'
            options={[
              {
                id: ALLOWED_DAYS.ALLOW,
                label: content?.restriction_date_time_allow || 'restriction_date_time_allow'
              },
              {
                id: ALLOWED_DAYS.DONT_ALLOW,
                label: content?.restriction_date_time_dont_allow || 'restriction_date_time_dont_allow'
              }
            ]}
            onChange={handleCustomPermission}
            horizontal
            size='sm'
            disabled={restrictionSettings[selectedDayIndex].isDisabled}
          />

          <Spacer size='xxl4' />

          <SliderContainer>
            <TimeRangeSlider
              defaultValue={{
                from: formatTime(findCustomDay(selectedDayIndex).startTime),
                to: formatTime(findCustomDay(selectedDayIndex).endTime)
              }}
              from={START_TIME}
              to={END_TIME}
              onChange={handleTimeRange}
              disabled={restrictionSettings[selectedDayIndex].isDisabled}
            />
          </SliderContainer>

          <CustomControls>
            <Button disabled={restrictionSettings[selectedDayIndex].isDisabled} onClick={handleApplyToAllDays}>
              {content?.restriction_date_time_apply_all || 'restriction_date_time_apply_all'}
            </Button>

            <Switch
              id='setResriction'
              name='setRestriction'
              defaultValue={restrictionSettings[selectedDayIndex].value}
              onChange={handleSwitch}
              text={content?.restriction_date_time_set_restriction || 'restriction_date_time_set_restriction'}
              alternateStyle={true}
            />
          </CustomControls>
        </>
      )

    return (
      <>
        <Spacer size='xl' />

        <TwoColumn>
          <Days>
            {DAYS.map((day, index) => {
              return (
                day && (
                  <li key={day}>
                    <Button
                      appearance='tertiary'
                      onClick={() => {
                        setFormValue('selectedDayIndex', index)
                        setShowCustomSetup(true)
                      }}
                      iconPosition='end'
                      iconName={restrictionSettings[index]?.isDirty && 'Bullet'}
                    >
                      {content?.[`restriction_date_time_${[day]}`]}
                    </Button>
                  </li>
                )
              )
            })}
          </Days>

          <div>
            {isCustomSetup || showCustomSetup
              ? DAYS.map((day, index) => <DayAndTimeRestrictions key={day} dayIndex={index + 1} />)
              : null}
          </div>
        </TwoColumn>
      </>
    )
  }

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      reset()
      setIsSubmitting(false)
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`)
    },
    error => {
      setIsSubmitting(false)
      setError(
        content?.restriction_profiles_update_notification_error_singular ||
          'restriction_profiles_update_notification_error_singular'
      )
      console.error(error)
    },
    authorityId
  )

  const onSubmit = formData => {
    const payload = buildRequest({
      profileId,
      profileName,
      formData
    })
    setIsSubmitting(true)
    submitForm(payload)
  }

  const onSubmitCreate = formData => {
    const payload = buildRequest({
      profileId,
      profileName,
      formData
    })
    onNext({ ...payload, defaultValues: formData })
  }

  return (
    <>
      {title && <Heading as='h3'>{title}</Heading>}
      {violationId && (
        <ViolationsSelector
          onChange={id => setFormValue('violationId', id)}
          defaultSelectedId={violationId}
          content={content}
        />
      )}

      <form>
        {daySelection && (
          <Controller
            name='daySelection'
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Heading
                  as='h2'
                  size='h5'
                  dataAttributes={{ 'data-content-key': 'content?.restriction_date_time_heading' }}
                >
                  {content?.restriction_date_time_heading}
                </Heading>

                <RadioButtonGroup
                  id='day-and-time-option'
                  defaultSelectedId={value}
                  horizontal
                  onChange={onChange}
                  options={[
                    {
                      id: WEEKDAYS,
                      label: content?.restriction_date_time_weekday || 'restriction_date_time_weekday'
                    },
                    {
                      id: WEEKENDS,
                      label: content?.restriction_date_time_weekend || 'restriction_date_time_weekend'
                    },
                    {
                      id: CUSTOM,
                      label: content?.restriction_date_time_custom || 'restriction_date_time_custom'
                    }
                  ]}
                />
              </>
            )}
          />
        )}

        {daySelection === CUSTOM && renderCustomSelection()}

        {error && (
          <>
            <Spacer />
            <StateMessage iconName='Alert' state='danger' text={error} />
          </>
        )}

        <Spacer size='xxl2' />

        {mode === MODE.EDIT && (
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            dataAttributes={{ 'data-content-key': 'restriction_profiles_rename_profile_primary_button' }}
          >
            {content?.restriction_profiles_rename_profile_primary_button}
          </Button>
        )}

        {mode === MODE.CREATE && (
          <ButtonWrapper>
            <Button onClick={onBack} appearance='tertiary' dataAttributes={{ 'data-content-key': 'previous_step' }}>
              {content?.previous_step}
            </Button>

            <Button
              onClick={handleSubmit(onSubmitCreate)}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              dataAttributes={{ 'data-content-key': 'next_step' }}
            >
              {content?.next_step}
            </Button>
          </ButtonWrapper>
        )}
      </form>
    </>
  )
}

export default DayAndTime
