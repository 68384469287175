import { Text } from '@bp-digital/component-typography'
import { Switch } from '@bp-digital/component-switch'
import { Wrapper, FieldWrapper, List } from './CookiePreferencesContent.styled'

const CookiePreferencesContent = ({ setEnablePerformanceCookies, enablePerformanceCookies, content }) => {
  return (
    <>
      <Text>{content?.modal_paragraph1}</Text>
      <br />
      <Text>{content?.modal_paragraph2}</Text>
      <br />
      <Text>{content?.modal_paragraph3}</Text>
      <br />
      <Text size='lg'>{content?.modal_paragraph4}</Text>
      <br />
      <Text size='lg'>{content?.modal_heading1}</Text>
      <br />
      <Text>{content?.modal_paragraph5}</Text>
      <Wrapper>
        <Text size='lg'>{content?.modal_heading2}</Text>
        <FieldWrapper>
          <Switch
            id='performanceCookies'
            onChange={value => setEnablePerformanceCookies(value)}
            defaultValue={enablePerformanceCookies}
          />
          <Text>{enablePerformanceCookies ? content?.modal_toggle_on : content?.modal_toggle_off}</Text>
        </FieldWrapper>
      </Wrapper>
      <Text>{content?.modal_paragraph6}</Text>
      <br />
      <Text size='lg'>{content?.modal_subheading1}</Text>
      <br />
      <Text>{content?.modal_paragraph7}</Text>
      <br />
      <List>
        <li>
          <Text>{content?.modal_listitem1}</Text>
        </li>
        <li>
          <Text>{content?.modal_listitem2}</Text>
        </li>
        <li>
          <Text>{content?.modal_listitem3}</Text>
        </li>
        <li>
          <Text>{content?.modal_listitem4}</Text>
        </li>
        <li>
          <Text>{content?.modal_listitem5}</Text>
        </li>
        <li>
          <Text>{content?.modal_listitem6}</Text>
        </li>
      </List>
      <br />
      <Text>{content?.modal_paragraph9}</Text>
      <br />
      <Text size='lg'>{content?.modal_subheading2}</Text>
      <br />
      <Text>{content?.modal_paragraph10}</Text>
      <br />
      <Text size='lg'>{content?.modal_subheading3}</Text>
      <br />
      <Text>{content?.modal_paragraph11}</Text>
    </>
  )
}

export default CookiePreferencesContent
