import axios from 'axios'
import { useQuery } from 'react-query'
import refactorPreferencesData from '../../helpers/refactorPreferencesData'

const QUERY_KEY = 'account/preferences'

const queryFn = async (authorityId: string) => {
  try {
    const params = {
      accessLevel: 'A',
      accessLevelCode: authorityId
    }
    const { data } = await axios.get(`/api/users/users/preferences`, { params })

    return refactorPreferencesData(data)
  } catch (e) {
    return console.warn(e)
  }
}

const useApiGetPreferences = (
  authorityId: string,
  options: { enabled?: boolean, staleTime: number | typeof Infinity }
) => {
  return useQuery(QUERY_KEY + authorityId, () => queryFn(authorityId), {
    ...options
  })
}

export default useApiGetPreferences
