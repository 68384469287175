import CountryFlag from 'components/dataDisplay/DataTable/components/CountryFlag'
import BaseDataStore from '../BaseDataStore'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'

const getCurrencyFromString = (value = '') => {
  if (typeof value !== 'string') return '€'

  if (value.includes('EUR')) {
    return '€'
  } else if (value.includes('GBP')) {
    return '£'
  }

  return '€'
}

class InvoicesStore extends BaseDataStore {
  columnSorting = ['invoiceDate', 'desc']
  defaultColumns = [
    'invoiceNumber',
    'invoiceDate',
    'status',
    'amount',
    'customer',
    'authorityNumber',
    'invoiceType',
    'supplyCountry'
  ]

  constructor(root) {
    super(root, 'invoices', 'transactions-invoices')
  }

  get rows() {
    return this.results.map(invoice => {
      const amount = invoice.amount || ''
      const currency = getCurrencyFromString(amount)
      const numericalAmount = Number(amount.replace(/[^0-9.-]+/g, '')).toFixed(2)

      return {
        type: 'MASTER',
        key: invoice.invoiceNumber,
        ...invoice,
        amount: `${currency}${numericalAmount}`,
        invoiceType: invoice.invoiceType?.replace('Summary', this.content?.invoiceType_summary),
        supplyCountry: invoice.supplyCountry?.replace('Multiple', this.content?.invoiceCountry_multi),
        authorityNumber: invoice.authorityOrParentNumber || invoice.authorityNumber,
        invoiceDate: getUserFormattedDate(invoice.invoiceDate, this.root.userStore.dateFormat),
        issuerCountry: (
          <CountryFlag
            countryCode={invoice.issuerCountry}
            altTag={invoice.issuerCountryName || invoice.issuerCountry}
          />
        ),
        subRows: this.detail[invoice.summaryStatementId]?.map((detail, index) => ({
          ...detail,
          amount: `${getCurrencyFromString(detail.amount)}${Number(detail.amount.replace(/[^0-9.-]+/g, '')).toFixed(
            2
          )}`,
          invoiceType: detail.invoiceType
            ?.replace('INTERNATIONAL-INVOICE', this.content?.invoiceType_international_invoice)
            .replace('NATIONAL-INVOICE', this.content?.invoiceType_national_invoice),
          key: `${invoice.summaryStatementId}-child-${index}` // used in row selection for selectedRowIds react-table
        }))
      }
    })
  }

  async getAvailableColumns() {
    return super.getAvailableColumns({
      method: 'get',
      url: '/api/transactions/invoices/viewcols',
      availableColumnsKey: 'invoiceHeader',
      headers: {
        'Content-Type': 'application/json'
      },
      payload: {}
    })
  }

  async getInvoices({
    authorityId,
    columnSorting = this.columnSorting,
    searchTerm = this.searchTerm,
    page = 1,
    pageSize = this.pageSize
  }) {
    let columns = this.selectedColumns
    const hasCountry = !!columns.find(item => item === 'issuerCountry')
    const hasType = !!columns.find(item => item === 'invoiceType')

    if (!hasCountry) {
      columns = [...columns, 'issuerCountry']
    }

    if (!hasType) {
      columns = [...columns, 'invoiceType']
    }

    if (!columnSorting.length) {
      columnSorting = ['invoiceDate', 'desc']
    }

    const payload = {
      activeFilters: [{ filterProfileName: '', filterProfileId: '', filters: [] }],
      authorityId,
      columnSorting,
      download: { required: false, all: false, invoices: [] },
      listOfColumns: [...this.defaultColumns, ...columns],
      searchCriteria: { columnName: '', searchDate: '', searchText: '' },
      start: (page - 1) * pageSize + 1,
      to: pageSize
    }

    if (searchTerm) {
      let searchDate = ''
      const dateRegex = /(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/
      const searchTermContainsDate = dateRegex.test(searchTerm)

      if (searchTermContainsDate) {
        // convert from DD/MM/YYYY to YYYY-MM-DD (horrible)
        const date = searchTerm.match(dateRegex)[0].replace(/\//g, '-').replace(/\./g, '-')
        searchDate = date.split('-').reverse().join('-')
      }

      payload.searchCriteria = {
        columnName: '',
        searchDate,
        searchText: searchDate ? '' : searchTerm
      }
    }

    const formatDate = date => date.toISOString()
    let parsedBody = JSON.parse(JSON.stringify(payload))
    let fromDateTime
    let toDateTime
    let reqConfig = {
      params: {}
    }

    if (parsedBody.authorityId) reqConfig.params.authorityIds = parsedBody.authorityId
    reqConfig.params.pageSize = parsedBody.to
    reqConfig.params.page = (parsedBody.start - 1) / parsedBody.to + 1
    reqConfig.params.sortBy = parsedBody.columnSorting[0]
    reqConfig.params.sortOrder = parsedBody.columnSorting[1]

    if (parsedBody.searchCriteria && parsedBody.searchCriteria.searchDate) {
      // date search
      reqConfig.params.filterby = 'invoiceDate'
      fromDateTime = formatDate(
        new Date(
          parsedBody.searchCriteria.searchDate.substr(0, 4),
          parsedBody.searchCriteria.searchDate.substr(5, 2) - 1,
          parsedBody.searchCriteria.searchDate.substr(8, 2),
          0,
          0,
          0,
          0
        )
      )
      toDateTime = formatDate(
        new Date(
          parsedBody.searchCriteria.searchDate.substr(0, 4),
          parsedBody.searchCriteria.searchDate.substr(5, 2) - 1,
          parsedBody.searchCriteria.searchDate.substr(8, 2),
          23,
          59,
          59,
          0
        )
      )
      reqConfig.params.filterCriteria = `${fromDateTime},${toDateTime}`
    } else if (parsedBody.searchCriteria && parsedBody.searchCriteria.searchText) {
      // text search
      reqConfig.params.searchBy = parsedBody.searchCriteria.searchText
    }

    let filterQuerString = ''

    filterQuerString = parsedBody.activeFilters
      .map(
        (filter, index) =>
          `filters[${index}].filterBy=${filter.filterBy}&filters[${index}].filterCriteria=${filter.filterCriterias}`
      )
      .join('&')

    const params = reqConfig.params

    await super.getData({
      method: 'get',
      url: `/api/transactions/invoices?${filterQuerString}`,
      payload,
      params,
      totalRecordsKey: 'totalRecords',
      extraKeysToStore: ['totalNumberOfInvoices', 'totalNumberOfSummaries'],
      responseKey: 'summaries',
      page,
      pageSize,
      searchTerm
    })
  }

  async getSummary(summaryStatementId) {
    const payload = {
      summaryStatementId,
      listOfColumns: [
        'invoiceNumber',
        'invoiceDate',
        'invoiceType',
        'amount',
        'customer',
        'status',
        'supplyCountry',
        'issuerCountry',
        'authorityNumber'
      ]
    }

    await super.getDetail({
      method: 'get',
      url: `/api/transactions/invoices/summaries/${summaryStatementId}`,
      payload,
      keyForResult: summaryStatementId,
      responseKey: 'invoices'
    })
  }
}

export default InvoicesStore
