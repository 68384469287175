import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY_PREFIX = 'cards/product-categories'

type paramType = string | null

const queryFn = async (serviceCode: paramType, authorityId: string) => {
  if (!serviceCode) throw new Error('No serviceCode param given to productcategories/external endpoint')
  if (!authorityId) throw new Error('No authorityId param given to productcategories/external endpoint')

  const params = {
    accessLevel: 'A',
    accessLevelCode: authorityId,
    serviceCode
  }

  const response = await axios({
    method: 'get',
    url: '/api/cards/cards/products',
    params
  })

  return response.data.categories
}

const useApiGetProductCategories = (serviceCode: paramType, authorityId: string, options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY_PREFIX + serviceCode, () => queryFn(serviceCode, authorityId), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetProductCategories
