import { useCallback, useEffect, useState, memo, useRef } from 'react'
import debounce from 'lodash.debounce'
import chunk from 'lodash.chunk'
import Skeleton from 'react-loading-skeleton'
import { Switch } from '@bp-digital/component-switch'
import { DropDown } from '@bp-digital/component-drop-down'
import { Pagination } from '@bp-digital/component-pagination'
import useApiGetAuthoritiesWithRoles from 'hooks/api/useApiGetAuthoritiesWithRoles'
import Authority from 'components/account/Authority'
import Paper from 'components/surfaces/Paper'
import { Wrapper, Container, Row, Column } from './UserAccess.styled'
import { Spacer } from 'styles/common.styled'
import { NoResults } from './NoResults'
import { SearchInputWrapper } from './SearchInput.styled'

const CHUNK_SIZE = 15
const MIN_LENGTH = 2
const authorityIdRegExp = /^[a-z]\d+$/i

const Loader = () => {
  return (
    <>
      <Row>
        <Column>
          <Skeleton width={200} />
        </Column>
        <Column>
          <Skeleton width={100} />
        </Column>
        <Column>
          <Skeleton width={250} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Skeleton width={200} />
        </Column>
        <Column>
          <Skeleton width={100} />
        </Column>
        <Column>
          <Skeleton width={250} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Skeleton width={200} />
        </Column>
        <Column>
          <Skeleton width={100} />
        </Column>
        <Column>
          <Skeleton width={250} />
        </Column>
      </Row>
    </>
  )
}

const MemoizedLoader = memo(Loader)

const UserAccess = ({ authorityId, content, title, userId, userRole, onSave, isSubmitting, saveText }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [originalData, setOriginalDataSet] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)

  const [userChanges, setUserChanges] = useState([])
  const [canSave, setCanSave] = useState(false)

  const inputRef = useRef()
  const inputValue = inputRef.current?.value ?? ''

  const {
    data: userAuthorities,
    isLoading,
    isSuccess
  } = useApiGetAuthoritiesWithRoles(authorityId, userId, userRole, {
    onSuccess: data => {
      setUserChanges(data)
      if (!originalData.length) {
        const chunks = chunk(data, CHUNK_SIZE)
        setOriginalDataSet(chunks)
      }
    },
    staleTime: 0,
    enabled: !!userId
  })

  const trackChanges = useCallback(() => {
    let changedAuthority

    let added = []
    let deleted = []
    let updated = []

    userAuthorities.forEach(userAuthority => {
      changedAuthority = userChanges.find(item => item.authorityId === userAuthority.authorityId)

      if (!changedAuthority) {
        deleted.push(userAuthority.authorityId)
        return
      }

      if (changedAuthority.roleId !== undefined) {
        if (changedAuthority.isEnabled !== userAuthority.isEnabled) {
          added.push(changedAuthority)
        } else if (changedAuthority.roleId !== userAuthority.defaultSelectedId) {
          updated.push(changedAuthority)
        }
      }
    })

    return {
      added,
      deleted,
      updated
    }
  }, [userAuthorities, userChanges])

  const resetChanges = useCallback(() => {
    setUserChanges(userAuthorities)
  }, [userAuthorities])

  useEffect(() => {
    if (!userAuthorities) return
    const { added, deleted, updated } = trackChanges()
    setCanSave(!!added.length || !!deleted.length || !!updated.length)
  }, [userChanges, userAuthorities, trackChanges])

  const handleFilter = filterText => {
    let filteredOutput

    if (authorityIdRegExp.test(filterText)) {
      filteredOutput = userAuthorities.filter(({ authorityId }) => authorityId.startsWith(filterText))
    } else {
      filteredOutput = userAuthorities.filter(({ authorityName }) => authorityName.includes(filterText))
    }

    setFilteredData(filteredOutput.length ? filteredOutput : [])
    setIsFiltering(false)
  }

  const filterSearch = event => {
    const searchTerm = event.target.value.toUpperCase()

    if (searchTerm.length > MIN_LENGTH) {
      setIsFiltering(true)
      debouncedFilter(searchTerm)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    debounce(searchTerm => handleFilter(searchTerm), 150),
    [handleFilter]
  )

  const handleKeyInput = event => {
    const text = event.target.value

    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (text === '' || text.length <= MIN_LENGTH) {
        setFilteredData([])
        setIsFiltering(false)
        return
      }
    }
  }

  const handleToggle = userAuthority => {
    return toggleValue => {
      setUserChanges(changes => {
        const changedAuthority = changes.find(item => item.authorityId === userAuthority.authorityId)

        if (toggleValue) {
          if (changedAuthority === undefined) {
            return [...changes, { ...userAuthorities.find(item => item.authorityId === userAuthority.authorityId) }]
          }
          return changes.map(item => {
            if (item.authorityId === userAuthority.authorityId) {
              return { ...item, isEnabled: toggleValue }
            }
            return item
          })
        } else {
          if (userAuthority.isEnabled === undefined) {
            return changes.map(item => {
              if (item.authorityId === userAuthority.authorityId) {
                return { ...userAuthorities.find(item => item.authorityId === userAuthority.authorityId) }
              }
              return item
            })
          }
          if (changedAuthority === undefined) {
            return [...changes, { ...userAuthorities.find(item => item.authorityId === userAuthority.authorityId) }]
          }

          return changes.filter(item => item.authorityId !== userAuthority.authorityId)
        }
      })
    }
  }

  const handleSelection = authority => {
    return selectedId => {
      setUserChanges(changes =>
        changes.map(item => {
          if (item.authorityId === authority.authorityId) {
            return { ...item, roleId: selectedId }
          }
          return item
        })
      )
    }
  }

  const button = {
    text: saveText,
    iconName: 'Save',
    disabled: isLoading || isSubmitting || !canSave,
    onClick: () => {
      const changes = trackChanges()
      onSave(changes)
      resetChanges()
    },
    isLoading: isSubmitting,
    dataAttributes: {
      'data-testid': 'button-save-access'
    }
  }

  const renderResults = () => {
    let data = []

    if (isLoading || isFiltering) return <MemoizedLoader />

    if (isSuccess && !isFiltering) {
      if (inputValue !== '' && inputValue.length > MIN_LENGTH) {
        if (filteredData.length === 0) {
          return <NoResults key='noResult' content={content} filterText={inputValue} />
        } else {
          data = filteredData
        }
      } else if (originalData.length) {
        data = originalData[currentPage]
      }
    }

    return (
      <>
        <Spacer size='lg' />

        {data.map(authority => {
          const changedAuthority = userChanges?.find(changed => changed.authorityId === authority.authorityId)

          return (
            <Authority
              key={authority.authorityId}
              authorityId={authority.authorityId}
              authorityName={authority.authorityName}
              secondAuthorityName={authority.secondAuthorityName}
              readOnly={false}
            >
              <Switch onChange={handleToggle(authority)} value={changedAuthority?.isEnabled ?? false} />
              <DropDown
                id={name}
                options={authority.roles}
                onChange={handleSelection(authority)}
                defaultSelectedId={authority.defaultSelectedId}
                disabled={!changedAuthority?.isEnabled ?? true}
              />
            </Authority>
          )
        })}
      </>
    )
  }

  return (
    <form autoComplete='off'>
      <Paper title={title || '...'} button={button}>
        <Wrapper>
          <Container>
            <SearchInputWrapper>
              <input
                type='text'
                name='searchAuthorities'
                onChange={filterSearch}
                onKeyUp={handleKeyInput}
                defaultValue={inputValue}
                placeholder={content?.search_authorities_input_placeholder ?? 'search_authorities_input_placeholder'}
                disabled={!isSuccess}
                ref={inputRef}
              />
            </SearchInputWrapper>

            {renderResults()}

            {isSuccess && (
              <>
                <Spacer size='xxl2' />
                <Pagination
                  currentPage={currentPage + 1}
                  count={inputValue !== '' ? 1 : originalData.length}
                  disabled={inputValue !== '' || !originalData.length || isFiltering}
                  siblingCount={2}
                  onChange={number => setCurrentPage(number - 1)}
                />
              </>
            )}
          </Container>
        </Wrapper>
      </Paper>
    </form>
  )
}

export default UserAccess
