// To be removed once Feature Flagging tool has been implemented
import { DEV_ENV, TEST_ENV, PREPROD_ENV, PROD_ENV } from 'constants/environments'

export const getEnvironment = () => {
  const host = window.location.host || 'fleet.bp.com'

  if (host.includes('dev') || host.includes('localhost')) {
    return DEV_ENV
  } else if (host.includes('test')) {
    return TEST_ENV
  } else if (host.includes('preprod')) {
    return PREPROD_ENV
  }

  return PROD_ENV
}
