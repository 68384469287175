import { Button } from '@bp-digital/component-button'
import { CommandMenu } from '@bp-digital/component-command-menu'
import { Icon } from '@bp-digital/component-icon'
import { SearchBox } from '@bp-digital/component-search-box'
import { Tooltip } from '@bp-digital/component-tooltip'
import getQueryParameter from 'helpers/getQueryParameter'
import useContent from 'hooks/useContent'
import { useHistory } from 'react-router'
import ButtonsWrapper from 'src/components/layout/ButtonsWrapper'
import { ControlsWrapper, SearchWithTooltipWrapper } from './TableControls.styled'
import { useMatchMedia } from '@bp-digital/hooks'
import breakpoints from 'styles/breakpoints'

const TableControls = ({ search = {}, actions = {}, selectedRows }) => {
  const history = useHistory()
  const content = useContent('table')
  const hasSearch = Object.keys(search).length > 0
  const { searchTerm, onSubmit, onChange, onClear, placeholder = '', minLength, tooltipMessage, hasBacklink } = search
  const searchFromUrlQuery = getQueryParameter('searchTerm')
  const minWidthSm = useMatchMedia(breakpoints.device.sm)
  return (
    <ControlsWrapper>
      {hasSearch && !hasBacklink && (
        <SearchWithTooltipWrapper>
          <SearchBox
            placeholder={placeholder}
            value={searchFromUrlQuery || searchTerm}
            onChange={onChange}
            onSubmit={onSubmit}
            onClear={onClear}
            minLength={minLength}
          />
          {tooltipMessage && (
            <Tooltip appearance='dark' text={tooltipMessage} position={`${minWidthSm ? 'top' : 'left'}`}>
              <Icon name='Info' appearance='medium' size='md' state='neutral' />
            </Tooltip>
          )}
        </SearchWithTooltipWrapper>
      )}
      {hasBacklink && (
        <Button size='lg' iconName='LeftLarge' appearance='tertiary' onClick={history.goBack}>
          {content?.back_button || 'Back'}
        </Button>
      )}

      {!!actions.length && (
        <ButtonsWrapper data-testid='table-actions'>
          {actions.map(({ text, ...rest }, index) => {
            const key = `table-control-${index}`

            if (rest.onClick) {
              const originalFunc = rest.onClick
              rest.onClick = () => originalFunc(Array.from(selectedRows))
            }

            if (rest.items) {
              return <CommandMenu key={key} {...rest} />
            }

            return (
              <Button key={key} size='md' {...rest}>
                {text}
              </Button>
            )
          })}
        </ButtonsWrapper>
      )}
    </ControlsWrapper>
  )
}

export default TableControls
