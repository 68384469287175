import axios from 'axios'
import { useQuery } from 'react-query'

type Payload = {
  accessLevel: string
  accessLevelCode: string
  chargerId: string
  siteId: string
  usageDateFrom: string
  usageDateTo: string
}

const fetchSiteDetailsRequest = async (payload: Payload) => {
  const usageDateFrom = payload.usageDateFrom.slice(0, -1)
  const usageDateFromUtc = new Date(new Date(usageDateFrom).toUTCString()).toISOString()
  const usageDateTo = payload.usageDateTo.slice(0, -1)
  const usageDateToUtc = new Date(new Date(usageDateTo).toUTCString()).toISOString()

  const response = await axios({
    method: 'get',
    url: '/api/evis/v1/sites/details',
    params: {
      ChargePointCDRCount: 0,
      SiteCDRCount: 0,
      accessLevel: payload?.accessLevel,
      accessLevelCode: payload?.accessLevelCode,
      chargePointId: payload?.chargerId,
      siteId: payload?.siteId,
      usageDateFrom: usageDateFromUtc,
      usageDateTo: usageDateToUtc
    }
  })

  return response.data
}

export const useSiteDetails = (payload: Payload) => {
  const detailsQueryId = `charger-${payload.chargerId}`
  const response = useQuery([detailsQueryId, payload], () => fetchSiteDetailsRequest(payload), {
    staleTime: 120000,
    enabled: !!payload.accessLevelCode
  })

  return response
}
