import { Icon } from '@bp-digital/component-icon'
import { StyledVehiclesBadge, StyledCardPinBadge, StyledDriversPinBadge } from './CardOrder.styled'

export const VehiclesBadge = () => (
  <StyledVehiclesBadge>
    <Icon name='Delivery' className='vehicle1' />
    <Icon name='Delivery' className='vehicle2' />
  </StyledVehiclesBadge>
)

export const CardPinBadge = () => (
  <StyledCardPinBadge>
    <Icon name='CvcCvv' className='card' />
    <Icon name='Security' className='pin' />
  </StyledCardPinBadge>
)

export const DriversPinBadge = () => (
  <StyledDriversPinBadge>
    <Icon name='People' className='drivers' />
    <Icon name='Security' className='pin' />
  </StyledDriversPinBadge>
)
