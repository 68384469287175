import {
  PERMISSION_TO_VIEW_TRANSACTIONS,
  PERMISSION_TO_VIEW_EV_MENU
} from 'src/constants/permissions'

import { 
  OVERVIEW_NODE,
  TRANSACTIONS_PARENT,
  CARD_ADMINISTRATION_PARENT,
  ACCESS_ADMINISTRATION_PARENT,
  DEPOT_MANAGEMENT_PARENT,
  CHARGE_POINTS_NODE,
  DEPOT_PERMISSIONS_NODE,
  CHARGING_HISTORY_NODE
} from './navigationNodes'

import { FEATURE_FLAGS } from 'constants/featureFlags'

const getNavigationItems = (content = {}, userPermissions) => {
  const showMergedSideNav = FEATURE_FLAGS.SHOW_MERGED_SIDE_NAV

  const originalNavigation = [
    OVERVIEW_NODE(content),
    TRANSACTIONS_PARENT(content, false),
    CARD_ADMINISTRATION_PARENT(content),
    ACCESS_ADMINISTRATION_PARENT(content),
    CHARGE_POINTS_NODE(content),
    DEPOT_PERMISSIONS_NODE(content),
    CHARGING_HISTORY_NODE(content)
  ]

  return showMergedSideNav ? buildNewNavigation(content, userPermissions) : originalNavigation
}

const buildNewNavigation = (content, userPermissions) => {
  const isDepotUser = userPermissions?.includes(PERMISSION_TO_VIEW_EV_MENU)
  const isFuelUser = userPermissions?.includes(PERMISSION_TO_VIEW_TRANSACTIONS)

  let navItems = []
  // Hybrid users with both Fuel & Depot permissions
  if (isFuelUser && isDepotUser) {
    navItems = [
      OVERVIEW_NODE(content),
      TRANSACTIONS_PARENT(content),
      CARD_ADMINISTRATION_PARENT(content),
      ACCESS_ADMINISTRATION_PARENT(content),
      DEPOT_MANAGEMENT_PARENT(content)
    ]
  // Pure Depot users
  } else if (isDepotUser) {  
    navItems = [
      CHARGE_POINTS_NODE(content),
      DEPOT_PERMISSIONS_NODE(content),
      CHARGING_HISTORY_NODE(content)
    ]
  // all other users without Depot permissions
  } else {
    navItems = [
      OVERVIEW_NODE(content),
      TRANSACTIONS_PARENT(content),
      CARD_ADMINISTRATION_PARENT(content),
      ACCESS_ADMINISTRATION_PARENT(content)
    ]
  }
  return navItems
}

export default getNavigationItems
