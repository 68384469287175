import styled from 'styled-components'
import bpLogo from '../../../assets/logos/bp-logo.svg'
import aralLogo from '../../../assets/logos/aral-logo.svg'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import { BRAND_ARAL } from 'constants/brands'

const Splash = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #222;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled.img`
  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.1, 1.1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  width: 200px;
  max-width: 60%;
  animation: pulse 1s linear infinite;
`

const Text = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.2em;
  color: white;
`

interface props {
  text: string
}

const SplashScreen = (props: props) => {
  const brand = getBrandFromDomain()

  return (
    <Splash>
      {props.text && <Text>{props.text}</Text>}
      <Logo src={brand === BRAND_ARAL ? aralLogo : bpLogo} />
    </Splash>
  )
}

export default SplashScreen
