import { BRAND_BP, BRAND_ARAL } from 'constants/brands'

const getBrandFromDomain = () => {
  const domain = window.location.hostname.toLowerCase()

  if (domain.includes(BRAND_ARAL.toLowerCase())) {
    return BRAND_ARAL
  }

  return BRAND_BP
}

export default getBrandFromDomain
