import { Heading, Text } from '@bp-digital/component-typography'

const InvitationSent = ({ content }) => (
  <>
    <Heading as='h3'>{content?.add_user_summary_invitation_title || 'add_user_summary_invitation_title'}</Heading>
    <Text size='lg'>{content?.add_user_summary_invitation_step3 || 'add_user_summary_invitation_step3'}</Text>
  </>
)

export default InvitationSent
