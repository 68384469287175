import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const LocationPin = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;

  // temporary change to icon
  > *[name='Pin'] svg > g {
    fill: #fff;

    path:nth-child(2) {
      stroke: ${props => (props.colour ? CssVars.palette.accent[props.colour] : CssVars.palette.accent.green)};
    }
  }
`

export const BrandLogo = styled.img`
  width: 4em;
  max-height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

export const MapMarkerStyled = styled.div`
  cursor: pointer;
  @keyframes fadeInTrans {
    from {
      opacity: 0;
      transform: translate(-10%, -50%);
    }
    to {
      opacity: 1;
      transform: translate(0%, -50%);
    }
  }

  @keyframes popFade {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  position: relative;

  ${BrandLogo} {
    animation: popFade 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    scale: 0;

    transform: translate(-50%, -50%) scale(0);
  }

  & > div.marker {
    display: none;
    margin-left: 1rem;
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.2);
    animation: fadeInTrans 0.5s;
    animation-delay: 0;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translate(-10%, -50%);
    background: #fff;
    position: absolute;
    left: 4em;
    width: 12vw;
    padding: 1.2em 1.6em;
    z-index: 3;
    top: 50%;
    line-height: 1.5;

    @media only screen and ${breakpoints.device.lg} {
      display: block;
    }

    h3 {
      font-size: 16px;
      margin-top: 0;
      color: #555;
    }

    p {
      color: #555;
      margin: 0;
    }

    a {
      color: #555;
      margin-top: 1em;
      display: block;
    }

    &:before {
      box-shadow: -3px 4px 5px -2px rgba(0, 0, 0, 0.2);
      z-index: 2;
      content: '';
      width: 2.2em;
      height: 2.2em;
      background: #fff;
      position: absolute;
      left: -1.9em;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
    }
  }
`

export const ButtonCloseStyled = styled.div.attrs(() => ({ role: 'button' }))`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`
