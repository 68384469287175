import replaceVariablesInString from 'helpers/replaceVariablesInString'

const getTimeFromDateString = dateString => {
  const formattedTime = new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  return formattedTime
}

export const getOpeningTime = (openingTimes, index, content) => {
  const regularHour = openingTimes?.regularHours.find(regularHour => regularHour.weekDay === index + 1)

  if (!regularHour) return content.cpo_charger_details_unknown

  return replaceVariablesInString(content.cpo_charger_opening_times, {
    periodBegin: getTimeFromDateString(regularHour.periodBegin),
    periodEnd: getTimeFromDateString(regularHour.periodEnd)
  })
}
