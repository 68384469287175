import { formatLocation } from '.'

export const getSiteLocationRestrictions = (restriction = {}, content, keyPrefix) => {
  const getSiteType = () => {
    const { allowedSiteGroupIds, deniedSiteGroupIds } = restriction

    const limits = []

    limits.push(
      {
        label: content?.[`${keyPrefix}site_type_allowed`],
        value: allowedSiteGroupIds ? allowedSiteGroupIds.map(type => content?.[`${keyPrefix}site_group_${type}`]) : '-'
      },
      {
        label: content?.[`${keyPrefix}site_type_not_allowed`],
        value: deniedSiteGroupIds?.length
          ? deniedSiteGroupIds.map(type => content[`${keyPrefix}site_group_${type}`])
          : '-'
      }
    )

    return limits
  }

  const getSites = () => {
    const { isAllowNotListed, siteLocations = [] } = restriction

    const location = [
      { allow: true, label: content?.[`${keyPrefix}site_allowed`] },
      { allow: false, label: content?.[`${keyPrefix}site_not_allowed`] }
    ]

    const limits = location.map(({ label, allow }) => ({
      label,
      value: formatLocation(siteLocations, allow, isAllowNotListed, content, keyPrefix)
    }))

    return limits
  }

  const siteLocationRestrictions = [...getSiteType(), ...getSites()]
  return siteLocationRestrictions.map(restriction => ({
    label: restriction.label,
    values: restriction.value
  }))
}
