const countryNameToKeyFormat = (countryName: string): string => countryName.trim().toLowerCase().replaceAll(' ', '_')

type TCountry = {
  countryName: string
}

const countryNameToVernacularName = (
  englishCountries: Array<TCountry>,
  vernacularCountryNames: Record<string, string>
): Array<TCountry> =>
  englishCountries.map((country: TCountry) => {
    country.countryName = vernacularCountryNames[countryNameToKeyFormat(country.countryName)] || country.countryName
    return country
  })

export default countryNameToVernacularName
