import styled from 'styled-components'

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
`

export const Logo = styled.img`
  width: 100px;
`

export const Content = styled.p`
  padding: 16px;
  text-align: center;
`
export const ErrorMessage = styled.p`
  padding: 16px;
  text-align: center;
  color: red;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  width: auto;

  > * {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const AlertWrapper = styled.div`
  margin: 16px;
`
