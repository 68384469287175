import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 8px;

  @media only screen and ${breakpoints.device.lg} {
    margin: 12px;
    margin-left: 32px;
  }
`

export const Authority = styled.button`
  display: flex;
  position: relative;
  z-index: 200;
  text-transform: uppercase;
  padding: 0 16px;
  border-radius: 5px;
  background-color: var(--color-grey-dark-400);
  color: #fff;
  outline: none;
  border: none;
  align-items: center;
  font-size: 15px;
  min-width: 300px;
  width: 100%;
  height: 40px;
  justify-content: space-between;

  &:hover {
    background-color: var(--color-grey-regular-800);
    cursor: pointer;
  }

  @media only screen and ${breakpoints.device.lg} {
    max-width: 400px;
  }
`

export const SkeletonWrapper = styled.div`
  border-radius: 10px;
  align-items: center;
  padding: 0 14px;

  @media only screen and ${breakpoints.device.lg} {
    display: flex;
  }
`

export const Text = styled.span`
  padding-right: 8px;
`

export const ModalContentWrapper = styled.div`
  &.full-height {
    height: calc(100vh - 64px);
  }
`

export const OverviewWrapper = styled.div`
  margin: 0 3em;
  padding-bottom: 0.5em;

  button {
    width: 100%;
  }
`

export const SearchWrapper = styled.div`
  margin: 0 3em;
  padding-bottom: 0.5em;

  div,
  input {
    margin: 0;
  }
`

export const HierarchyWrapper = styled.div`
  padding-top: 0.25em;
  padding-bottom: 0.5em;

  h3 {
    font-size: 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 10px 15px;
  }
`

export const HierarchyList = styled.ul`
  width: 100%;

  top: 38px;
  background-color: #fff;
`

export const HierarchyListItem = styled.li`
  display: flex;
  gap: 10px;

  &:nth-child(even) {
    background-color: var(--color-grey-regular-100);
  }

  button {
    flex: 0 0 100px;
    padding: 5px 2px;
    margin: 2px 0;

    &.hierarchy-group-selector {
      flex: 0 0 50px;
    }

    &.hierarchy-detail {
      flex: 0 0 50px;
      padding: 0;
      border: 0;
      cursor: pointer;
      background: transparent;

      > span {
        border-radius: 5px;
        background-color: var(--color-grey-regular-300);
        &:hover {
          background-color: var(--color-grey-regular-400);
        }

        > svg > g {
          fill: rgb(238, 238, 238);
        }
      }
    }
  }

  &.selected {
    background-color: var(--color-grey-regular-300);

    button.hierarchy-detail > span {
      background-color: var(--color-grey-regular-100);
      &:hover {
        background-color: var(--color-grey-regular-200);
      }
    }
  }
`

export const Label = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

export const SelectableAuthority = styled.a`
  display: block;
  padding: 12px;
  text-decoration: none;
  color: var(--color-grey-dark-300);

  &:hover {
    background-color: var(--color-grey-regular-100);
    cursor: pointer;
  }
`

export const AuthoritySearch = styled.input`
  width: 100%;
  border: 0;
  border-top: 1px solid var(--color-grey-regular-100);
  padding: 16px;
  outline: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &:focus-visible {
    outline: 2px solid var(--color-blue-regular-800);
  }
`
