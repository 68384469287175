import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'access/user-authorities'
type userIdType = number | null

interface Role {
  category: []
  isCustom: boolean
  roleId: number
  roleName: string
}

interface Authority {
  authorityId: string
  authorityName: string
  authorityNumber: string
  cardOfferType?: string
  countryIsoCode: string
  invoiceLevel?: string
  parentId?: string | null
  parentName?: string | null
  parentNumber?: string | null
  displayAuthorityNumber: string
  permission: Array<Role> | null
  roles: number[]
  secondAuthorityName: string
}

type RoleType = {
  roleId: number
  roleName: string
  offerType: string
}

interface Options {
  enabled: boolean
  staleTime: number | typeof Infinity
}

const queryFn = async (authorityId: string, userId: userIdType) => {
  const {
    data: { authorities }
  } = await axios.get(`/api/users/users/${userId}/authorities`, {
    params: {
      accessAreaLevel: 'Indirect',
      sortBy: 'authorityNumber',
      sortOrder: 'desc',
      directToGroupAccess: false,
      groupIds: [],
      parentId: [],
      invoiceLevelFilter: 'All',
      permissionRole: false,
      pageSize: 500,
      page: (1 - 1) / 500 + 1,
      IncludePermissions: false
    }
  })

  // /api/users/users/roles
  // TODO: start here construct the request & test (everything else in place)
  const params = {
    accessLevelCode: authorityId,
    isDefaultPermission: false,
    removedGroupIds: [],
    removedParentIds: [],
    removedAuthorityIds: []
  }

  const {
    data: { authorities: roleAuthorities, roleNames }
  } = await axios.get('/api/users/users/roles', { params })

  if (authorities && roleAuthorities) {
    const output = roleAuthorities.map((item: Authority) => {
      const roles: RoleType[] = []
      const authority = authorities.find((authority: Authority) => authority.authorityId === item.authorityId)
      const isEnabled = authority === undefined ? undefined : !!authority

      item.roles.forEach((roleId: number) => {
        const matchedRole = roleNames.find(
          (role: RoleType) => role.roleId === roleId && role.offerType === item.cardOfferType
        )
        if (matchedRole) roles.push(matchedRole)
      })

      return {
        authorityId: item.authorityId,
        authorityName: item.authorityName,
        secondAuthorityName: item.secondAuthorityName,
        roles: roles.map((role: RoleType) => ({
          id: role.roleId,
          label: role.roleName
        })),
        defaultSelectedId: roles.some(role => role.roleId === authority?.permission?.roleId)
          ? authority.permission.roleId
          : isEnabled
          ? roles[0]?.roleId
          : undefined,
        isEnabled
      }
    })

    return output
  }
}

const useApiGetAuthoritiesWithRoles = (authorityId: string, userId: userIdType, userRole: string, options: Options) => {
  return useQuery([QUERY_KEY, { userId }], () => queryFn(authorityId, userId), {
    ...options
  })
}

export default useApiGetAuthoritiesWithRoles
