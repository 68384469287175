import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const InsightsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 16px 0;
  position: relative;

  @media only screen and ${breakpoints.device.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Insight = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 16px;
  background-color: var(--color-grey-regular-100);
`

export const WeightedText = styled.p`
  font-weight: bold;
  margin: 0;
  padding-bottom: 8px;
`

export const DatePickerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: -210px;
  right: 200px;
`
