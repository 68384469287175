import { Badge } from '@bp-digital/component-badge'
import CountryFlag from 'components/dataDisplay/DataTable/components/CountryFlag'
import BrandIcon from 'components/dataDisplay/BrandIcon'
import BaseDataStore from '../BaseDataStore'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import DATE_FILTERS from 'constants/dateFilters'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import axios from 'axios'
import { MAX_TRANSACTION_COUNT_DOWNLOAD } from 'src/constants/transactions'
import pako from 'pako'
import qs from 'qs'
dayjs.extend(customParseFormat)

const getStateFromStatus = (status = '') => {
  if (status === 'invoiced' || status === 'authorised') {
    return 'success'
  } else if (status === 'declined' || status === 'unauthorised') {
    return 'danger'
  }

  return 'warning'
}

const createDateFilter = ({ from = '', to = '', dateType = null } = {}) => [
  {
    filterProfileName: '',
    filterProfileId: '',
    filters: [
      {
        filter: {
          name: 'transactionDateTime',
          keyGroup: [
            {
              keys: ['dateType', 'from', 'to'],
              values: [
                {
                  from,
                  to,
                  dateType
                }
              ]
            }
          ]
        }
      }
    ]
  }
]

class TransactionsStore extends BaseDataStore {
  columnSorting = ['transactionDateTime', 'desc']
  defaultColumns = [
    'transactionStatus',
    'fullCardNumber',
    'siteName',
    'vehicleRegistrationNumber',
    'quantity',
    'grossValue'
  ]
  simpleFilter = ''

  constructor(root) {
    super(root, 'transactions', 'transactions-transactions')
  }

  get rows() {
    return this.results.map(transaction => {
      const status = transaction.transactionStatus?.toLowerCase()
      const statusContent = this.content?.[`transactionStatus_${status}`]

      return {
        type: 'MASTER',
        key: transaction.transactionUniqueId,
        ...transaction,
        transactionStatus: <Badge text={statusContent} state={getStateFromStatus(status)} />,
        supplyCountry: transaction.supplyCountry ? (
          <CountryFlag countryCode={transaction.supplyCountry} />
        ) : (
          transaction.supplyCountry
        ),
        transactionDateTime: transaction.transactionDateTime
          ? getUserFormattedDate(transaction.transactionDateTime, this.root.userStore.dateFormat)
          : '',
        brand: transaction.brand ? <BrandIcon brand={transaction.brand} /> : ''
      }
    })
  }

  async getAvailableColumns(authorityId) {
    const params = {
      accessLevel: 'A',
      accessLevelCode: authorityId
    }

    const response = await super.getAvailableColumns({
      method: 'get',
      url: '/api/transactions/transactioncolumns',
      availableColumnsKey: 'transactionHeader.transactionPreferenceColumn',
      extractNameFromPreferences: true,
      params
    })

    return response
  }

  getTransactionCallPayload({
    selectedHierarchy,
    dateRange = null,
    invoiceDate,
    searchTerm = this.searchTerm,
    searchColumnName = this.searchColumnName,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting,
    page,
    pageSize
  }) {
    let columns = this.selectedColumns
    const hasBrand = !!columns.find(item => item === 'brand')

    if (!hasBrand) {
      columns = [...columns, 'brand']
    }

    if (!columnSorting.length) {
      columnSorting = ['transactionDateTime', 'desc']
    }

    const payload = {
      activeFilters: [],
      authorityId: selectedHierarchy.accessLevel == 'A' ? selectedHierarchy.accessLevelCode : '',
      parentId: selectedHierarchy.accessLevel == 'P' ? selectedHierarchy.accessLevelCode : '',
      groupId: selectedHierarchy.accessLevel == 'G' ? selectedHierarchy.accessLevelCode : '',
      columnSorting,
      queries: [],
      transactionStatus,
      listOfColumns: columns,
      limit: pageSize,
      start: (page - 1) * pageSize + 1
    }

    if (columnSorting[0] === 'transactionStatus') {
      // implement custom search order to allow for 'translated status'
      const cstSort = ['Authorised', 'Declined', 'Uninvoiced', 'Invoiced']
        .map(status => ({ status, display: this.content?.[`transactionStatus_${status.toLowerCase()}`] || status }))
        .sort((a, b) => {
          if (a.display < b.display) return columnSorting[1] === 'desc' ? 1 : -1
          if (a.display > b.display) return columnSorting[1] === 'desc' ? -1 : 1
          return 0
        })
      payload.sortOrderer = {}
      cstSort.forEach(sortItem => {
        payload.sortOrderer[sortItem.status] = cstSort.findIndex(sortItem2 => sortItem.status === sortItem2.status) + 1
      })
    }

    const dateRegex = /(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/
    const searchTextOrDate = dateRegex.test(searchTerm)
      ? { searchDate: searchTerm.replaceAll('-', '/').replaceAll('.', '/'), searchText: '' }
      : { searchDate: '', searchText: searchTerm }

    if (searchTerm) {
      payload.searchCriteria = {
        columnName: searchColumnName || '',
        ...searchTextOrDate
      }
    }

    if (this.simpleFilter) {
      const isCustomDate = (this.simpleFilter === DATE_FILTERS.CUSTOM_DATE && dateRange) || null
      const lastYear = (this.simpleFilter === DATE_FILTERS.TWELVE_MONTHS && 'lastYear') || null
      payload.activeFilters = createDateFilter({
        from: isCustomDate && dateRange?.dateArray[0],
        to: isCustomDate && dateRange?.dateArray[1],
        dateType: lastYear
      })
    }

    if (invoiceDate) {
      const to = dayjs(invoiceDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
      const from = dayjs(invoiceDate, 'DD/MM/YYYY').subtract(60, 'day').format('DD/MM/YYYY')
      payload.activeFilters = createDateFilter({
        from: from,
        to,
        dateType: null
      })
    }
    return payload
  }
  createReqConfig(reqConfig, payload) {
    const formatDate = date => date.toISOString()

    let sortOrdererqs = ''
    try {
      const parsedBody = payload
      if (!parsedBody.authorityId && !parsedBody.parentId && !parsedBody.groupId) throw new Error()
      if (parsedBody.authorityId) reqConfig.params.authorityIds = parsedBody.authorityId
      if (parsedBody.parentId) reqConfig.params.parentIds = [parsedBody.parentId]
      if (parsedBody.groupId && parsedBody.groupId != 'ALL') reqConfig.params.groupIds = [parsedBody.groupId]
      reqConfig.params.pageSize = parsedBody.limit
      reqConfig.params.page = (parsedBody.start - 1) / parsedBody.limit + 1
      reqConfig.params.sortBy = parsedBody.columnSorting[0]
      reqConfig.params.sortOrder = parsedBody.columnSorting[1]

      if (parsedBody.sortOrderer) {
        // need to inject as qs as using different serialisation
        parsedBody.sortOrderer.Authorised = parsedBody.sortOrderer.Authorised ? parsedBody.sortOrderer.Authorised : 1
        parsedBody.sortOrderer.Declined = parsedBody.sortOrderer.Declined ? parsedBody.sortOrderer.Declined : 2
        parsedBody.sortOrderer.Uninvoiced = parsedBody.sortOrderer.Uninvoiced ? parsedBody.sortOrderer.Uninvoiced : 3
        parsedBody.sortOrderer.Invoiced = parsedBody.sortOrderer.Invoiced ? parsedBody.sortOrderer.Invoiced : 4
        sortOrdererqs = `?sortOrderer[Authorised]=${parsedBody.sortOrderer.Authorised}&amp;sortOrderer[Declined]=${parsedBody.sortOrderer.Declined}&amp;sortOrderer[Uninvoiced]=${parsedBody.sortOrderer.Uninvoiced}&amp;sortOrderer[Invoiced]=${parsedBody.sortOrderer.Invoiced}`
      }

      // text search
      if (parsedBody.searchCriteria && parsedBody.searchCriteria.searchText)
        reqConfig.params.searchBy = parsedBody.searchCriteria.searchText
      // date search & filters
      const now = new Date()
      let from
      let to

      if (parsedBody.searchCriteria && parsedBody.searchCriteria.searchDate) {
        from = formatDate(
          new Date(
            parsedBody.searchCriteria.searchDate.substr(6, 4),
            parsedBody.searchCriteria.searchDate.substr(3, 2) - 1,
            parsedBody.searchCriteria.searchDate.substr(0, 2),
            0,
            0,
            0,
            0
          )
        )
        to = formatDate(
          new Date(
            parsedBody.searchCriteria.searchDate.substr(6, 4),
            parsedBody.searchCriteria.searchDate.substr(3, 2) - 1,
            parsedBody.searchCriteria.searchDate.substr(0, 2),
            23,
            59,
            59,
            0
          )
        )
      } else {
        // date filters
        // need to verify with cedric for the activefilter logic as it was greaterthan 0
        if (parsedBody.activeFilters.length > 0) {
          const dateSegment = parsedBody.activeFilters[0].filters[0].filter.keyGroup[0].values[0].dateType
          from = parsedBody.activeFilters[0].filters[0].filter.keyGroup[0].values[0].from
          to = parsedBody.activeFilters[0].filters[0].filter.keyGroup[0].values[0].to
          if (dateSegment === 'lastYear') {
            // last year selection (from 01/01 to 31/12)
            from = formatDate(new Date(now.getFullYear() - 1, 0, 1, 0, 0, 0, 0))
            to = formatDate(new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 0))
          } else if (from && to) {
            // custom date ("DD/MM/YYYY")
            from = formatDate(new Date(from.substr(6, 4), from.substr(3, 2) - 1, from.substr(0, 2), 0, 0, 0, 0))
            to = formatDate(new Date(to.substr(6, 4), to.substr(3, 2) - 1, to.substr(0, 2), 23, 59, 59, 0))
          }
        } else {
          // default is last 3 months + current
          from = formatDate(new Date(now.getFullYear(), now.getMonth() - 3, 1, 0, 0, 0, 0))
          to = formatDate(now)
        }
      }
      reqConfig.params.filterby = 'transactionDateTime'
      reqConfig.params.filterdatatype = 'Date'
      reqConfig.params.filterCriteria = from === to ? from : `${from},${to}`
    } catch (e) {
      return console.warn(e)
    }
    return { reqConfig, sortOrdererqs }
  }
  async getDownloadData({
    selectedHierarchy,
    dateRange,
    invoiceDate,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting
  }) {
    const payload = this.getTransactionCallPayload({
      selectedHierarchy,
      dateRange,
      invoiceDate,
      searchTerm: this.searchTerm,
      searchColumnName: this.searchColumnName,
      transactionStatus,
      columnSorting,
      pageSize: MAX_TRANSACTION_COUNT_DOWNLOAD,
      page: 1
    })
    const reqConfig = {
      params: {},
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'indices', allowDots: true, encode: false })
      }
    }

    const val = this.createReqConfig(reqConfig, payload)
    try {
      const response = await axios.get(`/api/transactions/transactions${val.sortOrdererqs}`, val.reqConfig)

      if (response.headers && response.headers['content-type'] === 'gzip') {
        const gzipedData = atob(response.data)
        const gzipedDataArray = Uint8Array.from(gzipedData, c => c.charCodeAt(0))
        const ungzipedData = pako.ungzip(gzipedDataArray)
        let binary = ''
        var bytes = new Uint8Array(ungzipedData)
        // use a for loop to avoid the max stack limit for large responses
        var len = bytes.byteLength
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        return JSON.parse(binary).transactions
      }

      return response.data.transactions
    } catch (e) {
      return console.warn(e)
    }
  }

  async getTransactions({
    selectedHierarchy,
    dateRange,
    invoiceDate,
    searchTerm = this.searchTerm,
    searchColumnName = this.searchColumnName,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting,
    page = 1,
    pageSize = this.pageSize,
    forceRefresh
  }) {
    const payload = this.getTransactionCallPayload({
      selectedHierarchy,
      dateRange,
      invoiceDate,
      searchTerm,
      searchColumnName,
      transactionStatus,
      columnSorting,
      pageSize,
      page
    })

    payload.download = { required: 'false' }
    const reqConfig = {
      params: {},
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    const val = this.createReqConfig(reqConfig, payload)
    const params = val.reqConfig.params
    await super.getData({
      method: 'get',
      url: `/api/transactions/transactions${val.sortOrdererqs}`,
      payload,
      params,
      totalRecordsKey: 'totalNumberOfTransactions',
      responseKey: 'transactions',
      page,
      pageSize,
      searchTerm,
      searchColumnName,
      forceRefresh
    })
  }

  async getSingleTransaction(uniqueId) {
    const productId = +uniqueId.substr(36)
    const payload = {
      transactionUniqueId: uniqueId
    }

    await super.getDetail({
      method: 'get',
      url: `/api/transactions/transactions/${payload.transactionUniqueId.substr(
        0,
        36
      )}?format=v1_summary&productId=${productId}`,
      keyForResult: uniqueId,
      responseKey: 'transaction'
    })
  }

  // not found any references below method that's why commenting

  // getFullTransaction = async uniqueId => {
  //   const payload = {
  //     transactionUniqueId: uniqueId,
  //     download: false
  //   }

  //   await super.getFullDetail({
  //     method: 'post',
  //     url: '/xapi/bp-ds-transactionservice-rio-xapi/api/transactions/transaction/external',
  //     payload,
  //     keyForResult: uniqueId
  //   })
  // }
}

export default TransactionsStore
