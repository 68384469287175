import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { StateMessage } from '@bp-digital/component-state-message'
import { Surface } from '@bp-digital/component-surface'
import { Button } from '@bp-digital/component-button'
import { useRootStore } from 'src/contexts/StoreContext'
import { ROUTE_CARDS_VIEW } from 'src/constants/routes'
import { Map } from '@bp-digital/component-map'
import MapMarker from 'components/dataDisplay/Map/MapMarker'
import {
  Heading,
  TransactionCardWithMap,
  TransactionDetail,
  TransactionDetailHeader,
  TransactionDetailActions,
  TransactionDetailBody,
  TransactionDetailItem,
  Label,
  Value,
  MapContainer,
  MapMarkerStyled
} from './SingleTransactionView.styled'
import { parseValue } from 'src/helpers/parseValue'
import getValueOrFallback from 'src/helpers/getValueOrFallback'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'

const unpricedFields = [
  'authorisationType',
  'transactionVoucherNumber',
  'authorisationCode',
  'odometer',
  'driverName',
  'vehicle_Drivercode',
  'additionalInformation',
  'cardHolderName',
  'vehicleRegistrationNumber',
  'netValueInSupplyCurrency',
  'vatValueInSupplyCurrency',
  'grossValueInSupplyCurrency',
  'originalGrossValueInSupplyCurrency',
  'transactionType',
  'typeOfTransaction'
]

const pricedFields = [
  'authorisationType',
  'transactionVoucherNumber',
  'authorisationCode',
  'odometer',
  'driverName',
  'vehicle_Drivercode',
  'additionalInformation',
  'cardHolderName',
  'vehicleRegistrationNumber',
  'netUnitListPriceInSupplyCurrency',
  'netValueInSupplyCurrency',
  'vatValueInSupplyCurrency',
  'grossValueInSupplyCurrency',
  'originalGrossValueInSupplyCurrency',
  'transactionType',
  'typeOfTransaction',
  'invoiceNumber',
  'invoiceDueDate'
]

const SingleTransactionView = ({ id, onExpand, content = {}, brand, cardNumber }) => {
  const { transactionsStore } = useRootStore()
  const data = transactionsStore.detail[id]
  const isPriced = data?.isPriced?.toLowerCase() === 'y'
  const fieldsToShow = isPriced ? pricedFields : unpricedFields
  const [isLoading, setIsLoading] = useState(!data)

  useEffect(() => {
    const getTransaction = async () => {
      await transactionsStore.getSingleTransaction(id)
      setIsLoading(false)
    }

    if (isLoading) {
      getTransaction()
    }
  }, [id, transactionsStore, isLoading])

  const mapDetails = data?.mapDetails || {}
  const lat = mapDetails.latitude
  const long = mapDetails.longitude
  const defaultCenter = {
    lat: 41.27396379749638,
    lng: 45.240973003125376
  }
  const mapCenter = {
    lat: lat ? parseFloat(lat) : defaultCenter.lat,
    lng: long ? parseFloat(long) : defaultCenter.lng
  }

  if (!data) return <></>

  return (
    <>
      <TransactionCardWithMap>
        <TransactionDetail>
          <Surface elevation='md'>
            <TransactionDetailHeader>
              <Heading>{content.tableRows_transactionDetail_subhead}</Heading>
              {!data?.transactionStatus && <Skeleton width={60} height={23} />}
              {data && data?.transactionStatus !== 'Declined' && (
                <StateMessage
                  text={isPriced ? content.isPriced || 'Priced' : content.isPriced_not || 'Pending pricing'}
                  state={isPriced ? 'success' : 'warning'}
                  iconName={isPriced ? 'CheckLargeSolid' : 'Alert'}
                />
              )}
            </TransactionDetailHeader>

            <TransactionDetailBody>
              {fieldsToShow.map(field => (
                <TransactionDetailItem key={field}>
                  <Label>
                    {content?.[`tableRows_transactionDetail_${field}`] || `tableRows_transactionDetail_${field}`}
                  </Label>
                  <Value>
                    {!data?.transactionStatus ? (
                      <Skeleton width={60} height={23} />
                    ) : field === 'invoiceDate' ? (
                      getUserFormattedDate(data?.[field])
                    ) : field === 'netUnitListPriceInSupplyCurrency' ? (
                      data?.[field] ? (
                        parseFloat(data[field], 10).toFixed(2)
                      ) : (
                        '-'
                      )
                    ) : (
                      content?.[
                        `tableRows_transactionDetail_${field}_${getValueOrFallback(parseValue(data?.[field]))
                          .toString()
                          .toLowerCase()}`
                      ] || getValueOrFallback(parseValue(data?.[field]))
                    )}
                  </Value>
                </TransactionDetailItem>
              ))}
            </TransactionDetailBody>

            <TransactionDetailActions>
              <Button
                appearance='tertiary'
                to={`${ROUTE_CARDS_VIEW}?searchTerm=${cardNumber}`}
                dataAttributes={{ 'data-testid': 'transaction-manage-card' }}
              >
                {content.tableRows_transactionDetail_button2_label}
              </Button>
              <Button onClick={() => onExpand()} dataAttributes={{ 'data-testid': 'transaction-full-details' }}>
                {content.tableRows_transactionDetail_button1_label}
              </Button>
            </TransactionDetailActions>
          </Surface>
        </TransactionDetail>
        {mapCenter.lat != defaultCenter.lat ? (
          <MapContainer>
            <Map
              location={mapCenter}
              cluster={false}
              zoom={12}
              height='inherit'
              renderMarker={data => <MapMarker {...data} />}
              markers={[
                {
                  id: 'brandMarker',
                  lat: mapCenter.lat,
                  lng: mapCenter.lng,
                  // <MapMarker/> component props
                  data: {
                    lat: mapCenter.lat,
                    lng: mapCenter.lng,
                    brand,
                    marker:
                      mapCenter.lat != 0
                        ? {
                            title: mapDetails.siteName,

                            address: [
                              mapDetails.siteStreetAndNumber,
                              mapDetails.siteTown,
                              mapDetails.siteType,
                              mapDetails.sitePostCode,
                              mapDetails.siteRegion,
                              mapDetails.siteTypeDescription
                            ],
                            phoneNumber: mapDetails.siteTelephoneNumber
                          }
                        : { title: 'No Site Details found' }
                  }
                }
              ]}
            />
          </MapContainer>
        ) : (
          <MapMarkerStyled>
            {content?.transactions_nositedetails != null
              ? content?.transactions_nositedetails
              : 'transactions_nositedetails'}
          </MapMarkerStyled>
        )}
      </TransactionCardWithMap>
    </>
  )
}

export default SingleTransactionView
