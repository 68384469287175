import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px 16px;
  background-color: var(--color-grey-regular-100);

  @media only screen and ${breakpoints.device.lg} {
    padding: 56px 32px;
  }
`

export const Heading = styled.h1`
  font-size: 32px;
  font-weight: var(--font-weight-light);
`
