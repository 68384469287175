import TwoColumn from 'components/layout/TwoColumn'
import { TextField } from '@bp-digital/component-text-field'
import { Controller } from 'react-hook-form'
import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import {
  PRODUCT_SERVICE_TYPE,
  PRODUCT_DAILY_MAX_VOLUME,
  PRODUCT_DAILY_MAX_QUANTITY,
  PRODUCT_DAILY_MAX_VALUE,
  PRODUCT_TXN_MAX_VOLUME,
  PRODUCT_TXN_MAX_VALUE
} from 'constants/restrictions'
import replaceVariablesInString from 'helpers/replaceVariablesInString'

const ProductFields = ({
  control,
  fieldPrefix,
  productServiceType = PRODUCT_SERVICE_TYPE.FUEL,
  content,
  onReset,
  disabled,
  currency,
  isProductsAll
}) => {
  const isFuel = productServiceType === PRODUCT_SERVICE_TYPE.FUEL

  const decimalWithDot = value => {
    if (!value) return

    const decimalPlace = content?.restriction_valiation_decimal_place || 'restriction_valiation_decimal_place'
    return !/^(-{0,1}\d+(?:[\\.]\d{1,2})?)$/.test(value.toString()) ? decimalPlace : null
  }
  const positiveNumber = value => {
    const negativeValue = content?.validation_positive_number || 'validation_positive_number'
    return value < 0 ? negativeValue : null
  }

  const maxValue = (value, max) => {
    const maxValue = content?.restriction_valiation_max || 'restriction_valiation_max'
    return value > max
      ? replaceVariablesInString(maxValue, {
          max: max
        })
      : null
  }

  const number = value => {
    if (!value) return

    const numericValue = content?.restriction_valiation_numeric || 'restriction_valiation_numeric'
    return !/^[0-9]\d*$/.test(value.toString()) ? numericValue : null
  }

  const dailyMaxVolumeLabel = isProductsAll
    ? content?.restriction_products_max_volumn_fuel
    : isFuel
    ? content?.restriction_products_max_volumn
    : content?.restriction_products_max_quantity_non_fuel

  const txnMaxVolumeLabel = isProductsAll
    ? content?.restriction_products_max_volumn_fuel
    : isFuel
    ? content?.restriction_products_max_volumn
    : content?.restriction_products_max_quantity_non_fuel

  return (
    <>
      <Text>{content?.restriction_products_daily || 'restriction_products_daily'}</Text>
      <TwoColumn>
        <Controller
          name={`${fieldPrefix}.dailyMaxVolume`}
          control={control}
          rules={{
            validate: {
              pattern: v => (isProductsAll ? number(v) : isFuel ? decimalWithDot(v) : number(v)),
              positive: v => positiveNumber(v),
              range: v => maxValue(v, isFuel ? PRODUCT_DAILY_MAX_VOLUME : PRODUCT_DAILY_MAX_QUANTITY)
            }
          }}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <TextField
              textChangeHandler={onChange}
              defaultValue={value}
              label={dailyMaxVolumeLabel}
              type='number'
              name={name}
              disabled={disabled}
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name={`${fieldPrefix}.dailyMaxValue`}
          control={control}
          rules={{
            validate: {
              pattern: v => decimalWithDot(v),
              positive: v => positiveNumber(v),
              range: v => maxValue(v, PRODUCT_DAILY_MAX_VALUE)
            }
          }}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <TextField
              textChangeHandler={onChange}
              defaultValue={value}
              label={replaceVariablesInString(
                isProductsAll ? content?.restriction_products_max_value_all : content?.restriction_products_max_value,
                {
                  currency: currency
                }
              )}
              type='number'
              name={name}
              disabled={disabled}
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />
      </TwoColumn>
      <Text>{content?.restriction_products_transaction || 'restriction_products_transaction'}</Text>
      <TwoColumn>
        <Controller
          name={`${fieldPrefix}.txnMaxVolume`}
          control={control}
          rules={{
            validate: {
              pattern: v => (isProductsAll ? number(v) : isFuel ? decimalWithDot(v) : number(v)),
              positive: v => positiveNumber(v),
              range: v => (!isFuel ? maxValue(v, PRODUCT_TXN_MAX_VOLUME) : null)
            }
          }}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <TextField
              textChangeHandler={onChange}
              defaultValue={value}
              label={txnMaxVolumeLabel}
              type='number'
              name={name}
              disabled={disabled}
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name={`${fieldPrefix}.txnMaxValue`}
          control={control}
          rules={{
            validate: {
              pattern: v => decimalWithDot(v),
              positive: v => positiveNumber(v),
              range: v => maxValue(v, PRODUCT_TXN_MAX_VALUE)
            }
          }}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <TextField
              textChangeHandler={onChange}
              defaultValue={value}
              label={replaceVariablesInString(
                isProductsAll ? content?.restriction_products_max_value_all : content?.restriction_products_max_value,
                {
                  currency: currency
                }
              )}
              type='number'
              name={name}
              disabled={disabled}
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />
      </TwoColumn>
      <Button appearance='secondary' onClick={onReset} disabled={disabled}>
        {content?.restriction_products_reset || 'restriction_products_reset'}
      </Button>
    </>
  )
}

export default ProductFields
