import { useContext, useState, useEffect } from 'react'
import { TextField } from '@bp-digital/component-text-field'
import { Button } from '@bp-digital/component-button'

import { FilterContext, InvoiceFilterType } from 'pages/Transactions/ArchivedInvoicesPage/ArchivedInvoicesPage'
import { getFilterText } from 'pages/Transactions/ArchivedInvoicesPage/helpers/getAllAppliedFilterBadges'

import { InvoiceFilterProps } from '../InvoiceArchiveFilters'

const FILTER_NAME = 'invoiceNumber'
const MIN_DIGITS = 4
const MAX_DIGITS = 10

const InvoiceNumberFilter = ({ removedFilterIds, setRemoveFilterId, content }: InvoiceFilterProps) => {
  const { setFilterData } = useContext(FilterContext)

  const [invoiceNumberValue, setInvoiceNumberValue] = useState<string>('')
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [errormessage, setErrormessage] = useState<string>(content?.error_default_message || 'error_default_message')

  useEffect(() => {
    if (removedFilterIds?.includes(FILTER_NAME)) {
      setRemoveFilterId(ids => ids.filter(id => id !== FILTER_NAME))
      setInvoiceNumberValue('')
      setBtnDisabled(true)
    }
  }, [removedFilterIds, setRemoveFilterId])

  const handleTextChange = (value: string) => {
    if (value.length < MIN_DIGITS || value.length > MAX_DIGITS) {
      setBtnDisabled(true)

      if (value.length < MIN_DIGITS) {
        setHasError(true)
        setErrormessage(String(content?.invoiceNumber_validation_minDigits || 'invoiceNumber_validation_minDigits'))
      }

      if (value.length > MAX_DIGITS) {
        setHasError(true)
        setErrormessage(String(content?.invoiceNumber_validation_maxDigits || 'invoiceNumber_validation_maxDigits'))
      }
    } else {
      setBtnDisabled(false)
      setHasError(false)
    }
    setInvoiceNumberValue(value)
  }

  const addFilterHandler = () => {
    if (invoiceNumberValue) {
      const payload = {
        name: FILTER_NAME,
        keyGroup: [
          {
            keys: [`${FILTER_NAME}`],
            values: [
              {
                invoiceNumber: `${invoiceNumberValue}`
              }
            ]
          }
        ]
      }

      setFilterData((filters: InvoiceFilterType[]) => [
        ...filters,
        {
          type: FILTER_NAME,
          text: getFilterText(payload, content),
          payload
        }
      ])
    }
  }

  return (
    <>
      <TextField
        error={hasError}
        errorMessage={errormessage}
        label={content?.advancedFilters_addInvoiceNumber_help || 'advancedFilters_addInvoiceNumber_help'}
        name='tf-invoiceNumber'
        placeholder={content?.advancedFilters_addInvoiceNumber || 'advancedFilters_addInvoiceNumber'}
        value={invoiceNumberValue}
        textChangeHandler={handleTextChange}
      />

      <Button appearance='primary' onClick={addFilterHandler} disabled={btnDisabled}>
        {content?.advancedFilters_add || 'advancedFilters_add'}
      </Button>
    </>
  )
}

export default InvoiceNumberFilter
