import styled from 'styled-components'

export const FiltersContainer = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 2rem;
`

export const AppliedFilters = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-self: start;
  gap: .75rem;
  width: 100%;
  font-size: 0.75rem;
  
  > div  > span {
    font-size: 0.85rem;
    letter-spacing: normal;
    text-transform: capitalize;	
  }
  span > svg {
    width: 22px;
    height: 22px;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  align - items: center;
`
