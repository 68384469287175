import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 16px 0;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`
