import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const Spacer = styled.span`
  display: inline-block;
  width: 100%;
  margin-top: ${({ size = 'md' }) => CssVars.structure.spacing[size]};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ align = 'start' }) => align};
  gap: ${({ gap = 'md' }) => CssVars.structure.spacing[gap]};
`

export const Truncated = styled.p`
  display: block;
  overflow: hidden;
  text-overflow: hidden;
  margin: 0;
`
