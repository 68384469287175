import { BRAND_BP } from 'constants/brands'
import getBrandFromDomain from '../getBrandFromDomain'
import { getEnvironment } from '../getEnvironment'
import { PROD_ENV } from 'constants/environments'
import {
  nonProdClientId,
  prodClientId,
  nonProd_Aral_BaseURL,
  nonProd_BP_BaseURL,
  prod_Aral_BaseURL,
  prod_BP_BaseURL
} from 'constants/idpenv'

const brand = getBrandFromDomain()
const env = getEnvironment()

export function getForgeRockBase() {
  if (env == PROD_ENV) return brand.toUpperCase() === BRAND_BP ? prod_BP_BaseURL : prod_Aral_BaseURL
  else return brand.toUpperCase() === BRAND_BP ? nonProd_BP_BaseURL : nonProd_Aral_BaseURL
}

export function getClientvalue() {
  return env == PROD_ENV ? prodClientId : nonProdClientId
}
