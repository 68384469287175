import styled from 'styled-components'
// import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--color-grey-regular-300);
`

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px dashed var(--color-grey-regular-300);
  padding-bottom: 16px;
  min-height: 57px;
`

export const Title = styled.h3`
  width: 100%;
  margin: 0;
  font-size: 20px;
  font-weight: normal;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
