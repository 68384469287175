/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'

const sanitisePayload = (payload: any) => {
  if (payload.restrictionDetail.existingProfile === false) {
    payload.restrictionDetail.profileId = 0
  }

  payload.restrictionDetail.profileId = parseInt(payload.restrictionDetail.profileId)

  if (payload.restrictionDetail && payload.restrictionDetail.siteLocations) {
    payload.restrictionDetail.siteLocations.acceptSitesViolation =
      !!+payload.restrictionDetail.siteLocations.acceptSitesViolation
    payload.restrictionDetail.siteLocations.alertSitesViolation =
      !!+payload.restrictionDetail.siteLocations.alertSitesViolation
    payload.restrictionDetail.siteLocations.isAllowNotListed =
      !!+payload.restrictionDetail.siteLocations.isAllowNotListed
    payload.restrictionDetail.siteLocations.sites.forEach((siteData: any) => {
      siteData.isAllow = !!+siteData.isAllow
      siteData.site.siteTypeGroupId = siteData.site.siteGroupId
      delete siteData.site.siteGroupId
    })
  }

  if (payload.restrictionDetail && payload.restrictionDetail.odometerAndDistance) {
    payload.restrictionDetail.odometerAndDistance.acceptOdometerViolation =
      !!+payload.restrictionDetail.odometerAndDistance.acceptOdometerViolation
    payload.restrictionDetail.odometerAndDistance.alertOdometerViolation =
      !!+payload.restrictionDetail.odometerAndDistance.alertOdometerViolation
  }

  if (payload.restrictionDetail && payload.restrictionDetail.spendingLimit) {
    payload.restrictionDetail.spendingLimit.acceptSpendingLimitViolation =
      !!+payload.restrictionDetail.spendingLimit.acceptSpendingLimitViolation
    payload.restrictionDetail.spendingLimit.alertSpendingLimitViolation =
      !!+payload.restrictionDetail.spendingLimit.alertSpendingLimitViolation
    if (!payload.restrictionDetail.spendingLimit.restrictions.daily.dailySpendLimit) {
      payload.restrictionDetail.spendingLimit.restrictions.daily.dailySpendLimit = null
    }
    if (!payload.restrictionDetail.spendingLimit.restrictions.weekly.weeklySpendLimit) {
      payload.restrictionDetail.spendingLimit.restrictions.weekly.weeklySpendLimit = null
    }
    if (!payload.restrictionDetail.spendingLimit.restrictions.monthly.monthlySpendLimit) {
      payload.restrictionDetail.spendingLimit.restrictions.monthly.monthlySpendLimit = null
    }
    if (!payload.restrictionDetail.spendingLimit.restrictions.rollingLimit.rollingDaysSpendLimit) {
      payload.restrictionDetail.spendingLimit.restrictions.rollingLimit.rollingDaysSpendLimit = null
    }
    if (!payload.restrictionDetail.spendingLimit.restrictions.rollingLimit.rollingDaysCount) {
      payload.restrictionDetail.spendingLimit.restrictions.rollingLimit.rollingDaysCount = null
    }
  }

  if (payload.restrictionDetail && payload.restrictionDetail.productAndServices) {
    payload.restrictionDetail.productAndServices.acceptProductServiceViolation =
      !!+payload.restrictionDetail.productAndServices.acceptProductServiceViolation
    payload.restrictionDetail.productAndServices.alertProductServiceViolation =
      !!+payload.restrictionDetail.productAndServices.alertProductServiceViolation
    payload.restrictionDetail.productAndServices.categories.forEach((category: any) => {
      category.categoryDetails.isAllow = !!+category.categoryDetails.isAllow
      if (category.categoryDetails.products) {
        category.categoryDetails.products.forEach((product: any) => (product.isAllow = !!+product.isAllow))
      }
    })
    payload.restrictionDetail.productAndServices.isAllowNotListed =
      !!+payload.restrictionDetail.productAndServices.isAllowNotListed
  }

  if (payload.restrictionDetail && payload.restrictionDetail.dayTime) {
    payload.restrictionDetail.dayTime.acceptDayTimeViolation =
      !!+payload.restrictionDetail.dayTime.acceptDayTimeViolation
    payload.restrictionDetail.dayTime.alertDayTimeViolation = !!+payload.restrictionDetail.dayTime.alertDayTimeViolation
    payload.restrictionDetail.dayTime.daysWithTime.forEach((dayWithTime: any) => {
      if (dayWithTime) dayWithTime.isAllow = !!+dayWithTime.isAllow
    })
  }

  return payload
}

const queryFn = async (payload: unknown, authorityId: string) => {
  if (!payload) throw new Error('No serviceCode param given to productcategories/external endpoint')
  if (!authorityId) throw new Error('No authorityId param given to productcategories/external endpoint')
  const params = {
    accessLevel: 'A',
    accessLevelCode: authorityId
  }

  const response = await axios({
    method: 'post',
    url: '/api/cards/profile/restrictions',
    data: sanitisePayload(payload),
    params
  })

  return response.data
}

const useApiPostRestrictionProfileUpdate = (
  onSuccess: (arg0: Response) => void,
  onError: (message: string) => void,
  authorityId: string
) => {
  return useMutation<Response, AxiosError>(payload => queryFn(payload, authorityId), {
    mutationKey: 'restriction-profile-update',
    onSuccess: response => {
      onSuccess(response)
    },
    onError: error => {
      const { message } = error
      onError(message)
    },
    retry: false
  })
}

export default useApiPostRestrictionProfileUpdate
