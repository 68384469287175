import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'
import { TwoColumnLayout as TwoColumn } from 'components/layout/TwoColumn/TwoColumn.styled'

export const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  color: ${CssVars.palette.state.dangerDark};
  font-size: ${CssVars.typography.font.h6};
  margin-top: 0;
`

export const InputWrapper = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`

export const PinWrapper = styled.div`
  margin-top: 1.5rem;
`

export const Form = styled.form`
  ${TwoColumn} {
    padding: ${CssVars.structure.spacing.xl};
  }

  ${TwoColumn}:nth-child(2n + 1) {
    background-color: ${CssVars.palette.neutral.lightest};
  }

  button[type='submit'] {
    margin-top: 2.5rem;
  }
`
