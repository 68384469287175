import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: column;
  margin-top: ${props => (props.$includeMarginTop ? '16px' : '0px')};
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;

  &:nth-child(even) {
    background-color: var(--color-grey-regular-50);
  }

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 5px 0;
  flex: 1;

  &:last-child {
    flex: 0.7;
  }

  @media only screen and ${breakpoints.device.sm} {
    margin: 0;
  }
`

export const AlertWrapper = styled.div`
  width: 100%;

  @media only screen and ${breakpoints.device.sm} {
    width: fit-content;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  width: auto;

  > * {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
