import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'invoices/invoicefilters-supply-country-'

const queryFn = async (authorityId: string) => {
  if (!authorityId) throw new Error('No Authority Id given to /authoritydetails endpoint')

  interface ReqConfig {
    params: Record<string, any>
  }
  const reqConfig: ReqConfig = { params: {} }

  reqConfig.params.AuthorityIds = authorityId
  reqConfig.params.FilterCode = 'supplyCountry'

  const response = await axios.get('/api/transactions/invoices/searcharchive/filter', reqConfig)

  return response.data.dataFilters[0].filters[0].filter.keyGroup.values
}

const useApiGetSupplyCountries = (authorityId: string) => useQuery(QUERY_KEY + authorityId, () => queryFn(authorityId))

export default useApiGetSupplyCountries
