import { ListWrapper, ListItem, DepotInfo, DepotStatus } from './TokenExpanderList.styled'
import { Icon } from '@bp-digital/component-icon'

const depotStatus = {
  active: {
    icon: 'CheckLarge',
    color: 'green'
  },
  inactive: {
    icon: 'RemoveLarge',
    color: 'red'
  }
}

const isDepotActive = (siteId, cardDepotsAllowed) => {
  const currentDepot = cardDepotsAllowed?.includes(siteId) ? depotStatus.active : depotStatus.inactive
  return <Icon name={currentDepot.icon} size='xl' state='neutral' />
}

const TokenExpanderList = ({ depotsInfo, tokenDepotsAllowedAt }) => {
  return (
    <ListWrapper>
      {depotsInfo.map(({ siteId, siteAlias, chargePoints }) => (
        <ListItem key={`token-item-${siteId}`}>
          <DepotInfo>{siteAlias ? siteAlias : chargePoints?.[0]?.chargePointId || '-'}</DepotInfo>
          <DepotStatus>{isDepotActive(siteId, tokenDepotsAllowedAt)}</DepotStatus>
        </ListItem>
      ))}
    </ListWrapper>
  )
}

export default TokenExpanderList
