import { Alert } from '@bp-digital/component-alert'
import useContent from 'hooks/useContent'
import styled from 'styled-components'

const StyledMessage = styled.tbody`
  tr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
`

export const ErrorMessage = () => {
  const content = useContent('table')

  return (
    <StyledMessage data-testid='api-error'>
      <tr>
        <td>
          <Alert state='danger' text={content?.request_failure_message ?? 'request_failure_message'} />
        </td>
      </tr>
    </StyledMessage>
  )
}
