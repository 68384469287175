const FEPLIMIT = 100

const getFEP = restrictionList => {
  let count = 0
  const restrictionSites = restrictionList && restrictionList.filter(l => typeof l.selectedSite !== 'undefined')

  if (restrictionSites) {
    count = restrictionSites
      .map(s => (s.selectedSite.fepIds ? s.selectedSite.fepIds.length : 0))
      .reduce((a, b) => a + b, 0)
  }

  return count
}

const checkFEPLimitReached = (site, restrictionList) => {
  if (site.fepIds && site.fepIds.length > 0 && getFEP(restrictionList) >= FEPLIMIT) {
    return true
  }

  return false
}

export default checkFEPLimitReached
