import { createGlobalStyle } from 'styled-components'
import RobotoLightWoff2 from 'src/assets/fonts/Roboto-Light.woff2'
import RobotoLightWoff from 'src/assets/fonts/Roboto-Light.woff'
// import RobotoThinWoff2 from 'src/assets/fonts/Roboto-Thin.woff2'
// import RobotoThinWoff from 'src/assets/fonts/Roboto-Thin.woff'
import RobotoRegularWoff2 from 'src/assets/fonts/Roboto-Regular.woff2'
import RobotoRegularWoff from 'src/assets/fonts/Roboto-Regular.woff'

import animations from './animations'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, p, a, button {
    font-family: var(--typography-font-default), 'Roboto-Light', Arial, sans-serif;
  }

  p {
    font-size: 14px;
  }

  #root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }
  
  ${animations}
   @font-face {
    font-family: 'Roboto-Light';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
        font-weight: 200;
}
  
 @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegularWoff2}) format('woff2'),
      url(${RobotoRegularWoff}) format('woff');
    
}
  
  @font-face {
    font-family: 'Roboto-Light';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
       
}

 @font-face {
    font-family: 'Roboto-Light';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
       
}
`

export default GlobalStyle
