import { getEnvironment } from 'helpers/getEnvironment'

const KEYS = {
  DEV: '62debeb32c8b46117152f439',
  TEST: '6278f1a177d6c10c8f8a5e3c',
  PREPROD: '62dfcac05ba42811bd504d86',
  PROD: '6278f1a177d6c10c8f8a5e3d'
}

export const getLaunchDarklyClientId = () => {
  const env = getEnvironment()

  return KEYS[env]
}