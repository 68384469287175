import { Row, Column, Wrapper } from './Authority.styled'
import { Text } from '@bp-digital/component-typography'

const Authority = ({ authorityId, authorityName, secondAuthorityName, readOnly = true, children }) => {
  return (
    <Row>
      <Column>
        <Text as='span'>{authorityName}</Text>
        {secondAuthorityName && <Text as='small'>{secondAuthorityName}</Text>}
      </Column>
      <Column>
        <Text as='span'>{authorityId}</Text>
      </Column>
      <Column>{readOnly ? children : <Wrapper>{children}</Wrapper>}</Column>
    </Row>
  )
}

export default Authority
