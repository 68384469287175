import { useEffect, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import { ROUTE_CARDS_SPENDING_LIMITS, ROUTE_ADMIN } from 'src/constants/routes'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import Skeleton from 'react-loading-skeleton'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import { useHistory, Redirect } from 'react-router-dom'

const CardsSpendingLimitPage = () => {
  const history = useHistory()
  const { cardsSpendingLimitStore, contentStore, userStore } = useRootStore()
  const content = contentStore.getPage('cards-limits')
  const authorityId = userStore.selectedAuthorityId
  const loadingRow = cardsSpendingLimitStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  useEffect(() => {
    if (!content) {
      contentStore.getContent('cards-limits')
    }

    //!cardsSpendingLimitStore.data && removed this condition from the below line of code by naveen for to reload data when changing authority
    if (!cardsSpendingLimitStore.isFetchingPreferences && authorityId) {
      cardsSpendingLimitStore.getSpendingLimits({ authorityId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityId, cardsSpendingLimitStore.isFetchingPreferences])

  // const handleSubmitSearch = async () => {
  //   await cardsSpendingLimitStore.getSpendingLimits(authorityId, cardsSpendingLimitStore.searchTerm)
  // }

  // const handleClearSearch = () => {
  //   cardsSpendingLimitStore.setSearchTerm('')
  //   cardsSpendingLimitStore.setSearchLimits({})
  // }

  const handleRowClick = useCallback(
    (_event, data) => {
      history.push(`${ROUTE_CARDS_SPENDING_LIMITS}/${data.key}`, {
        data: data
      })
    },
    [history]
  )

  const tablePagination = {
    currentPage: cardsSpendingLimitStore.currentPage,
    count: cardsSpendingLimitStore.numberOfPages || 1,
    disabled: !cardsSpendingLimitStore.data,
    onChange: value => cardsSpendingLimitStore.getSpendingLimits({ authorityId, page: value })
  }

  const data = useMemo(
    () =>
      cardsSpendingLimitStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : cardsSpendingLimitStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardsSpendingLimitStore.rows, cardsSpendingLimitStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      cardsSpendingLimitStore.isFetchingData
        ? cardsSpendingLimitStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : cardsSpendingLimitStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardsSpendingLimitStore.columns, cardsSpendingLimitStore.isFetchingData, tablePagination.currentPage]
  )

  // API Unstable
  // const handleSort = useCallback(
  //   (key, desc) => {
  //     const sortSpendingLimits = async (key = 'parentNumber', desc = false) => {
  //       const columnSorting = [key, desc ? 'desc' : 'asc']
  //       cardsSpendingLimitStore.columnSorting = columnSorting
  //       await cardsSpendingLimitStore.getSpendingLimits({
  //         authorityId,
  //         columnSorting
  //       })
  //     }
  //     if (cardsSpendingLimitStore.rows.length > 0) {
  //       sortSpendingLimits(key, desc)
  //     }
  //   },
  //   [authorityId, cardsSpendingLimitStore]
  // )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.accountspendinglimits || '...'}
        subtitle={replaceVariablesInString(content?.spending_limits_showing || '...', {
          amount: cardsSpendingLimitStore.rows.length,
          totalSpendingLimits: cardsSpendingLimitStore.limits?.totalRecords || '0'
        })}
        breadcrumbs={[
          {
            to: ROUTE_CARDS_SPENDING_LIMITS,
            title: content?.accountspendinglimits,
            ariaLabel: content?.accountspendinglimits
          }
        ]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <ReactTable
          name='account-spending-limits'
          columns={columns}
          data={data}
          pagination={tablePagination}
          // onSort={handleSort}
          handleRowClick={handleRowClick}
          isLoading={cardsSpendingLimitStore.isFetchingData}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(CardsSpendingLimitPage)
