import { Button } from '@bp-digital/component-button'
import { Container, Row, Column, ButtonWrapper } from '../../AddUserPage.styled'
import { Heading, Text } from '@bp-digital/component-typography'
import { Controller } from 'react-hook-form'
import { Switch } from '@bp-digital/component-switch'
import Skeleton from 'react-loading-skeleton'
import { useForm } from 'react-hook-form'
import useApiGetAuthoritiesWithRoles from 'hooks/api/useApiGetAuthoritiesWithRoles'

const AccessSetup = ({
  authorityId,
  userId,
  userRole,
  onBack,
  setActiveStep,
  setAuthoritiesWithRoles,
  setFormData,
  formData,
  setIsLoading,
  isLoading,
  content
}) => {
  const defaultValues = {
    ...formData?.authority
  }
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid }
  } = useForm({ defaultValues, mode: 'onChange' })
  const { data: authorities, isSuccess } = useApiGetAuthoritiesWithRoles(authorityId, userId, userRole, { staleTime: 120000 })

  const handleStep = async authority => {
    setIsLoading(true)
    setFormData({ ...formData, authority: authority })

    let selectedAuthoritiesIds = []
    Object.entries(authority).forEach(([key, value]) => {
      if (value === true) selectedAuthoritiesIds = [...selectedAuthoritiesIds, key]
    })

    const filteredAuthorities = authorities.filter(item => selectedAuthoritiesIds.find(id => id === item.authorityId))
    setAuthoritiesWithRoles(filteredAuthorities)
    setIsLoading(false)
    setActiveStep(2)
  }

  return (
    <form>
      <Heading as='h3'>
        {`${content?.add_user_user_access_title || 'add_user_user_access_title'} ${formData?.userDetails?.firstName} ${
          formData?.userDetails?.lastName
        }` || 'add_user_user_access_title'}
      </Heading>
      <Text size='lg'>{content?.add_user_user_access_description || 'add_user_user_access_description'}</Text>
      <Container $includeMarginTop={true}>
        {isSuccess ? (
          authorities.map(item => (
            <Row key={item.authorityId}>
              <Column>
                <Text as='span'>{item.authorityName}</Text>
                {item.secondAuthorityName && <Text as='small'>{item.secondAuthorityName}</Text>}
              </Column>
              <Column>
                <Text as='span'>{item.authorityId}</Text>
              </Column>
              <Column>
                <Controller
                  name={item.authorityId}
                  control={control}
                  rules={{
                    validate: () => Object.values(getValues()).filter(value => value).length > 0
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Switch name={item.authorityId} id={item.authorityId} onChange={onChange} defaultValue={value} />
                  )}
                />
              </Column>
            </Row>
          ))
        ) : (
          <Row>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
          </Row>
        )}
      </Container>
      <ButtonWrapper>
        <Button appearance='secondary' onClick={onBack} dataAttributes={{ 'data-testid': 'button-new-user-step-1' }}>
          {content?.add_user_back || 'add_user_back'}
        </Button>
        <Button
          isLoading={isLoading}
          disabled={!isValid || isLoading}
          onClick={handleSubmit(handleStep)}
          dataAttributes={{ 'data-testid': 'button-new-user-step-3' }}
        >
          {content?.add_user_next || 'add_user_next'}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default AccessSetup
