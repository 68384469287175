import styled, { css } from 'styled-components'
import { CssVars } from '@bp-digital/theme'

const SharedInputStyles = css`
  font-family: inherit;
  font-size: ${CssVars.typography.size.md};
  line-height: ${CssVars.typography.lineHeight.lg};
  letter-spacing: ${CssVars.typography.spacing.compact};
  border: ${CssVars.structure.strokeWeight.normal} solid ${CssVars.palette.controls.stroke};
  color: ${CssVars.palette.content.default};
  margin-bottom: ${CssVars.structure.spacing.md};
  margin-bottom: 0;

  text-transform: uppercase;
  &::placeholder {
    opacity: 0.7;
    text-transform: none;
  }
  &::-moz-placeholder {
    opacity: 0.5;
  }
  :focus {
    outline-color: ${CssVars.palette.controls.focus};
  }
`

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${CssVars.typography.font.default};

  input {
    ${SharedInputStyles}
    padding: ${CssVars.structure.spacing.md} ${CssVars.structure.spacing.lg};
    cursor: auto;
  }
`

export { SearchInputWrapper }
