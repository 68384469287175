import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  border-bottom: 1px dashed var(--color-grey-regular-300);
  padding-bottom: 16px;

  @media only screen and ${breakpoints.device.lg} {
    grid-template-columns: 1fr 1fr;
  }
`
