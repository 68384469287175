import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const Message = styled.div`
  display: flex;
  place-items: center left;
  width: 90%;
  color: ${CssVars.palette.accent.orangeDark};

  .message {
    margin: 0 0 0 0.5rem;
    line-height: ${CssVars.typography.lineHeight.lg};
  }
`

export const LabelWrapper = styled.div`
  display: inline-flex;
`

export const InputWrapper = styled.div`
  > div {
    margin-bottom: 20px;
  }
`
