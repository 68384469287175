import { formatCategoriesAndProducts } from 'helpers/restrictions'
import { RESTRICTION_TYPES } from 'constants/restrictions'

const checkEmptyValue = value => (value && !!value.toString() ? value : null)

const buildProfileDetails = ({ data, restrictionTypes }) => {
  const categoriesData =
    data.productAndServices &&
    formatCategoriesAndProducts(data.productAndServices.categories, data.productAndServices.productCategories)

  const profileDetails = {
    restrictionTypes: null,
    ...(restrictionTypes.includes(RESTRICTION_TYPES.SPENDING_LIMIT) && {
      spendingLimitRestriction: {
        acceptSpendingLimitViolation: data.spendingLimit.acceptSpendingLimitViolation === '1' ? true : false,
        alertSpendingLimitViolation: data.spendingLimit.alertSpendingLimitViolation === '1' ? true : false,
        ...data.spendingLimit.restrictions
      }
    }),
    ...(restrictionTypes.includes(RESTRICTION_TYPES.PRODUCTS_AND_SERVICES) && {
      productAndServicesRestriction: {
        acceptProductServiceViolation: data.productAndServices.violationId === 3 ? false : true,
        alertProductServiceViolation: data.productAndServices.violationId === 1 ? false : true,
        isAllowNotListed: data.productAndServices.isAllowNotListed,
        categories: categoriesData
          .filter(item => data.productAndServices.selectedCategoryIds.includes(Number(item.categoryId)))
          .map(item => ({
            categoryId: Number(item.categoryId),
            applyForAllProductsInCategory: item.applyForAllProductsInCategory,
            categoryDetails: {
              categoryLimits: item.applyForAllProductsInCategory
                ? {
                    daily: {
                      dailyMaxVolume: checkEmptyValue(item.dailyMaxVolume),
                      dailyMaxValue: checkEmptyValue(item.dailyMaxValue)
                    },
                    monthly: {
                      monthlyMaxValue: null,
                      monthlyMaxVolume: null
                    },
                    transaction: {
                      txnMaxVolume: checkEmptyValue(item.txnMaxVolume),
                      txnMaxValue: checkEmptyValue(item.txnMaxValue)
                    }
                  }
                : null,
              isAllow: item.applyForAllProductsInCategory ? Number(item.isAllow) : null,
              products: item.applyForAllProductsInCategory
                ? null
                : item.products.map(product => ({
                    productServiceId: Number(product.productId),
                    productServiceName: product.name,
                    productServiceType: product.type,
                    isAllow: Number(product.isAllow),
                    ...(Number(product.isAllow) === 1 && {
                      productDetails: {
                        daily: {
                          dailyMaxVolume: checkEmptyValue(product.dailyMaxVolume),
                          dailyMaxValue: checkEmptyValue(product.dailyMaxValue)
                        },
                        monthly: {
                          monthlyMaxValue: null,
                          monthlyMaxVolume: null
                        },
                        transaction: {
                          txnMaxVolume: checkEmptyValue(product.txnMaxVolume),
                          txnMaxValue: checkEmptyValue(product.txnMaxValue)
                        }
                      }
                    })
                  }))
            }
          }))
      }
    }),
    ...(restrictionTypes.includes(RESTRICTION_TYPES.ODOMETER) && {
      odometerAndDistanceRestriction: {
        acceptOdometerViolation:
          data.odometerAndDistance.restrictionDetail.odometerAndDistance.acceptOdometerViolation === '1' ? true : false,
        alertOdometerViolation:
          data.odometerAndDistance.restrictionDetail.odometerAndDistance.alertOdometerViolation === '1' ? true : false,
        maximumDistance: data.odometerAndDistance.restrictionDetail.odometerAndDistance.maximumDistance
      }
    }),
    ...(restrictionTypes.includes(RESTRICTION_TYPES.DAY_AND_TIME) && {
      dayTimeRestriction: {
        acceptDayTimeViolation: data.dayTime.restrictionDetail.dayTime.acceptDayTimeViolation === '1' ? true : false,
        alertDayTimeViolation: data.dayTime.restrictionDetail.dayTime.alertDayTimeViolation === '1' ? true : false,
        setupType: data.dayTime.restrictionDetail.dayTime.type,
        daysWithTime: data.dayTime.restrictionDetail.dayTime.daysWithTime
      }
    }),
    ...(restrictionTypes.includes(RESTRICTION_TYPES.SITE_LOCATIONS) && {
      sitelocationRestrictions: {
        acceptSitesViolation: data.siteLocations.violationId === 3 ? false : true,
        alertSitesViolation: data.siteLocations.violationId === 1 ? false : true,
        isAllowNotListed: data.siteLocations.allowNotListed ? true : false,
        allowedSiteGroupIds: data.siteLocations.siteTypes
          .filter(type => type.value)
          .map(({ siteGroupId }) => siteGroupId),
        deniedSiteGroupIds: data.siteLocations.siteTypes
          .filter(type => !type.value)
          .map(({ siteGroupId }) => siteGroupId),
        siteLocations: data.siteLocations.restrictionList.map(site => ({
          isAllow: site.allow ? true : false,
          countryCode: site.selectedCountry.countryCode,
          countryId: site.selectedCountry.countryId,
          countryName: site.selectedCountry.countryName,
          areaId: site.selectedRegion.areaId,
          areaName: site.selectedRegion.region,
          site: {
            globalSiteId: site.selectedSite.globalSiteId,
            siteTypeId: site.selectedSite.siteTypeId || null,
            siteGroupId: site.selectedSite.siteGroupTypeId || null
          }
        }))
      }
    })
  }

  return profileDetails
}

export default buildProfileDetails
