import { Pagination } from '@bp-digital/component-pagination'
import { DropDown } from '@bp-digital/component-drop-down'
import { PaginationWrapper } from './TablePagination.styled'

const TablePagination = props => {
  const pageSizes = [15, 30, 60, 120]
  return (
  <PaginationWrapper>  
    <div>
      <Pagination {...props} dataAttributes={{ 'data-testid': 'table-pagination' }} />
    </div>
    <div>
    {props.pageSize && (
      <DropDown 
        id='table-pagesize'
        selectedId={props.pageSize}
        onChange={props.onSizeChange}
        options={pageSizes.map(ps => ({ id: ps, label: ps.toString() }))}
      />
      )}
    </div>
  </PaginationWrapper>
  )
}

export default TablePagination
