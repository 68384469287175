import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const TransactionsWrapper = styled.div`
  padding: 16px;

  @media only screen and ${breakpoints.device.lg} {
    padding: 40px 32px;
  }
`

// react-table renderDetail view
export const SingleTransactionViewWrapper = styled.div`
  position: relative;
  height: 350px;
  display: flex;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  margin: 1rem 0 2rem;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: column;
  }

  @media only screen and ${breakpoints.device.lg} {
    margin: 2rem 0 4rem;
    display: flex;
    justify-content: center;
    column-gap: 2rem;
  }
`

export const DownloadWarning = styled.div`
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
`

export const AlertTypes = styled.div`
  width: 100%;
  display: flex;
`

export const AlertTypeLabel = styled.div`
  display: flex;
  gap: ${CssVars.structure.spacing.md};
  align-items: center;
  font-size: ${CssVars.typography.size.sm};
`

export const DownloadWaiting = styled.div`
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
`
