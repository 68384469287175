import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media only screen and ${breakpoints.device.lg} {
    grid-template-columns: 1fr 1fr;
  }
`

export const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 8px 0;
`
export const Block = styled.div`
  border-left: 1px dashed var(--color-grey-regular-500);
  padding-left: 16px;
`

export const DropDownWrapper = styled.div`
  width: 70%;
`

export const DetailHeading = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  height: 44px;
`
