const SITE_DESCRIPTION_VALUES = {
  LINKS: 'LINKS',
  SERVICE_STN: 'SERVICE STN',
  DEPOT: 'DEPOT',
  TRUCK_INN: 'TRUCK INN',
  HOMEBASE: 'HOMEBASE',
  RESELLERS: 'RESELLERS',
  MWSA_S_S: 'MWSA S/S',
  MWSA_SERVED: 'MWSA SERVED',
  FLEET_S_S: 'FLEET S/S',
  FLEET_SERVED: 'FLEET SERVED',
  TRUCK_S_S: 'TRUCK S/S',
  TRUCK_SERVED: 'TRUCK SERVED',
  AUTOMAT: 'automat',
  THIRDRDPTY_Other: '3RDPTY Other',
  THIRDRDPTY_MoWay: '3RDPTY MoWay',
  THIRDRDPTY_R4T: '3RDPTY R4T',
  THIRDRDPTY_C_V: '3RDPTY C&V',
  THIRDRDPTY_Autom: '3RDPTY Autom',
  Truck_DO_SP: 'Truck DO -SP',
  TICKETS_B_C: 'ttickets B2C',
  BP_TOLLBOX: 'BP Tollbox +',
  NETWORK: 'NETWORK'
}

const getSiteDescription = (content = {}, key = '') => {
  switch (key) {
    case SITE_DESCRIPTION_VALUES.LINKS:
      return content.siteTypeDesc_links
    case SITE_DESCRIPTION_VALUES.SERVICE_STN:
      return content.siteTypeDesc_service
    case SITE_DESCRIPTION_VALUES.DEPOT:
      return content.siteTypeDesc_depot
    case SITE_DESCRIPTION_VALUES.TRUCK_INN:
      return content.siteTypeDesc_truckInn
    case SITE_DESCRIPTION_VALUES.HOMEBASE:
      return content.siteTypeDesc_homebase
    case SITE_DESCRIPTION_VALUES.RESELLERS:
      return content.siteTypeDesc_resellers
    case SITE_DESCRIPTION_VALUES.MWSA_S_S:
      return content.siteTypeDesc_mwsaSS
    case SITE_DESCRIPTION_VALUES.MWSA_SERVED:
      return content.siteTypeDesc_mwsaServed
    case SITE_DESCRIPTION_VALUES.FLEET_S_S:
      return content.siteTypeDesc_fleetSS
    case SITE_DESCRIPTION_VALUES.FLEET_SERVED:
      return content.siteTypeDesc_fleetServed
    case SITE_DESCRIPTION_VALUES.TRUCK_S_S:
      return content.siteTypeDesc_truckSS
    case SITE_DESCRIPTION_VALUES.TRUCK_SERVED:
      return content.siteTypeDesc_truckServed
    case SITE_DESCRIPTION_VALUES.AUTOMAT:
      return content.siteTypeDesc_automat
    case SITE_DESCRIPTION_VALUES.THIRDRDPTY_Other:
      return content.siteTypeDesc_3rdptyOther
    case SITE_DESCRIPTION_VALUES.THIRDRDPTY_MoWay:
      return content.siteTypeDesc_3rdptyMoWay
    case SITE_DESCRIPTION_VALUES.THIRDRDPTY_R4T:
      return content.siteTypeDesc_3rdptyR4T
    case SITE_DESCRIPTION_VALUES.THIRDRDPTY_C_V:
      return content.siteTypeDesc_3rdptyCV
    case SITE_DESCRIPTION_VALUES.THIRDRDPTY_Autom:
      return content.siteTypeDesc_3rdptyAutom
    case SITE_DESCRIPTION_VALUES.Truck_DO_SP:
      return content.siteTypeDesc_truckDO_SP
    case SITE_DESCRIPTION_VALUES.TICKETS_B_C:
      return content.siteTypeDesc_tticketsB2C
    case SITE_DESCRIPTION_VALUES.BP_TOLLBOX:
      return content.siteTypeDesc_bpTollbox
    case SITE_DESCRIPTION_VALUES.NETWORK:
      return content.siteTypeDesc_network
    default:
      return '-'
  }
}

export default getSiteDescription
