export const getUserPinOptionsAndDefaultPin = ({ pinDerivation, pinReq } = {}, content = {}) => {
  let defaultPIN = 4

  if (pinReq === '4') {
    defaultPIN = 0

    return [
      [
        { id: 0, label: content?.pin_pin_driver },
        {
          id: 1,
          label: content?.pin_pin_choose_own
        }
      ],
      defaultPIN
    ]
  } else if (pinDerivation === '0') {
    defaultPIN = 1

    return [
      [
        {
          id: 1,
          label: content?.pin_pin_choose_own
        },
        { id: 0, label: content?.pin_pin_random }
      ],
      defaultPIN
    ]
  } else if (pinDerivation === '1') {
    defaultPIN = 1

    return [
      [
        {
          id: 1,
          label: content?.pin_pin_choose_own
        }
      ],
      defaultPIN
    ]
  } else if (pinDerivation === '2') {
    defaultPIN = 1

    return [
      [
        {
          id: 1,
          label: content?.pin_pin_choose_own
        },
        {
          id: 2,
          label: content?.pin_pin_customer
        }
      ],
      defaultPIN
    ]
  } else if (pinDerivation === '3') {
    defaultPIN = 1

    return [
      [
        {
          id: 1,
          label: content?.pin_pin_choose_own
        },
        {
          id: 3,
          label: content?.pin_pin_customer
        }
      ],
      defaultPIN
    ]
  }

  return [[], defaultPIN]
}
