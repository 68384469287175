import { Icon } from '@bp-digital/component-icon'
import { Text } from '@bp-digital/component-typography'
import { Messaging } from '../common/CardOrder.styled'
import { Address } from '../DeliveryAddress.styled'

const PinByDelivery = ({ content, deliveryAddress }) => {
  return (
    <>
      <Address>
        <ol>
          <li>{deliveryAddress?.addressLine1}</li>
          <li>{deliveryAddress?.addressLine2}</li>
          {deliveryAddress?.addressLine3 && <li>{deliveryAddress?.addressLine3}</li>}
          {deliveryAddress?.addressLine4 && <li>{deliveryAddress?.addressLine4}</li>}
          <li>{deliveryAddress?.postCode}</li>
          <li>{deliveryAddress?.country}</li>
        </ol>
      </Address>
      <Messaging>
        <Icon name='Info' size='lg' className='icon' />
        <Text as='p' className='message' dataAttributes={{ 'data-content-key': 'pin_pin_random_warning' }}>
          {content?.pin_pin_random_warning}
        </Text>
      </Messaging>
    </>
  )
}

export default PinByDelivery
