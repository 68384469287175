import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { Wrapper, Logo, SideNavItems, StyledSkeleton, LoadingWrapper } from './SideNav.styled'
import bpLogo from 'src/assets/logos/bp-logo.svg'
import aralLogo from 'src/assets/logos/aral-logo.svg'
import SideNavItem from './SideNavItem'
import getNavigationItems from './getNavigationItems'
import { BRAND_BP } from 'src/constants/brands'
import { ROUTE_REDIRECT } from 'src/constants/routes'
import useContent from 'src/hooks/useContent'

const SideNav = ({ location, isOpenOnMobile = false, brand = BRAND_BP, userPermissions = [], toggleMobileMenu }) => {
  const content = useContent('header')
  const navigationItems = getNavigationItems(content, userPermissions)

  return (
    <Wrapper isOpenOnMobile={isOpenOnMobile}>
      {userPermissions?.length ? (
        <Link to={ROUTE_REDIRECT} data-testid='menu-logo'>
          <Logo src={brand.toUpperCase() === BRAND_BP ? bpLogo : aralLogo} />
        </Link>
      ) : (
        <StyledSkeleton circle width={150} height={150} />
      )}
      <SideNavItems>
        {userPermissions?.length ? (
          navigationItems
            .filter(item => {
              const hasPermissionForNavItem = item.requiredPermissions.every(requiredPermission =>
                userPermissions.includes(requiredPermission)
              )
              return hasPermissionForNavItem
            })
            .map(item => {
              const isActive = location.pathname === item.path
              const hasOpenChild = item.children?.find(child => window.location.pathname.includes(child.path))

              return (
                <SideNavItem
                  title={item.title}
                  to={item.path}
                  icon={item.icon}
                  key={item.title}
                  testId={item.testId}
                  isActive={isActive || hasOpenChild}
                  isChildOpen={isActive || hasOpenChild}
                  toggleMobileMenu={toggleMobileMenu}
                  subItems={item.children}
                  brand={brand}
                  userPermissions={userPermissions}
                />
              )
            })
        ) : (
          <LoadingWrapper>
            <Skeleton height={33} width={250} count={5} style={{ margin: '8px' }} />
          </LoadingWrapper>
        )}
      </SideNavItems>
    </Wrapper>
  )
}

export default withRouter(SideNav)
