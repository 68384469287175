import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from '@bp-digital/component-text-field'
import TwoColumn from 'components/layout/TwoColumn'
import { Text, Heading } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { PhoneField } from '@bp-digital/component-phone-field'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Wrapper } from './UserDetails.styled'

const UserDetails = ({
  authorityId,
  countryList,
  setActiveStep,
  setFormData,
  formData,
  setIsLoading,
  isLoading,
  content
}) => {
  const defaultValues = {
    ...formData?.userDetails,
    mobileNumber: { prefix: formData?.userDetails?.mobileNumberIsdCode, number: formData?.userDetails?.mobileNumber },
    landlineNumber: {
      prefix: formData?.userDetails?.landlineNumberIsdCode,
      number: formData?.userDetails?.landlineNumber
    }
  }
  const ignoredChars = /^[^\\="<>/:;]+$/i

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, errors }
  } = useForm({ defaultValues, mode: 'onChange' })

  const sanitizedInputValue = value => {
    return value.trim().replace(/\s{2,}/g, ' ')
  }

  const handleStep = async user => {
    setIsLoading(true)

    const sanitizedUser = {
      ...user,
      firstName: sanitizedInputValue(user.firstName),
      lastName: sanitizedInputValue(user.lastName),
      jobTitle: sanitizedInputValue(user.jobTitle)
    }

    if (Object.keys(errors).length > 0) {
      setIsLoading(false)
      return //exit if there are validation errors
    }

    const userExist = await checkUserExist(user.email)
    if (!userExist) {
      const { landlineNumber, mobileNumber } = sanitizedUser

      const mappedUser = {
        ...sanitizedUser,
        landlineNumber: (landlineNumber && landlineNumber.number) || '',
        landlineNumberIsdCode: (landlineNumber && landlineNumber.prefix) || '',
        mobileNumber: (mobileNumber && mobileNumber.number) || '',
        mobileNumberIsdCode: (mobileNumber && mobileNumber.prefix) || ''
      }

      setFormData({ ...formData, userDetails: mappedUser })
      setIsLoading(false)
      setActiveStep(1)
    } else {
      setError('email', {
        type: 'manual',
        message: content?.add_user_error_email_exists || 'add_user_error_email_exists'
      })
      setIsLoading(false)
    }
  }

  const checkUserExist = async email => {
    try {
      const params = {
        accessLevel: 'A',
        accessLevelCode: authorityId,
        email
      }
      const { data } = await axios.get(`/api/users/users/exists`, { params })

      return data
    } catch (e) {
      console.warn(e)
    }
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('')

  return (
    <form>
      <Heading as='h3'>{content?.add_user_steps_user_details || 'add_user_steps_user_details'}</Heading>
      <Text size='lg'>{content?.add_user_user_details_description || 'add_user_user_details_description'}</Text>
      <Wrapper>
        <TwoColumn>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: {
                value: true,
                message: content?.add_user_field_required || 'add_user_field_required'
              },
              pattern: {
                value: ignoredChars,

                message:
                  content?.advancedFilters_validation_special_character ||
                  'advancedFilters_validation_special_character'
              }
            }}
            defaultValue=''
            render={({ field: { value, onChange } }) => (
              <TextField
                name='firstName'
                value={firstName}
                onChange={value => setFirstName(value)}
                error={errors?.firstName && true}
                errorMessage={errors?.firstName?.message}
                label={content?.add_user_name || 'add_user_name'}
                textChangeHandler={onChange}
                defaultValue={value}
                required
              />
            )}
          />

          <Controller
            name='lastName'
            control={control}
            rules={{
              required: {
                value: true,
                message: content?.add_user_field_required || 'add_user_field_required'
              },
              pattern: {
                value: ignoredChars,
                message:
                  content?.advancedFilters_validation_special_character ||
                  'advancedFilters_validation_special_character'
              }
            }}
            defaultValue=''
            render={({ field: { value, onChange } }) => (
              <TextField
                name='lastName'
                value={lastName}
                onChange={value => setLastName(value)}
                error={errors?.lastName && true}
                errorMessage={errors?.lastName?.message}
                label={content?.add_user_last_name || 'add_user_last_name'}
                textChangeHandler={onChange}
                defaultValue={value}
                required
              />
            )}
          />

          <Controller
            name='jobTitle'
            control={control}
            rules={{
              required: false,
              pattern: {
                value: ignoredChars,
                message:
                  content?.advancedFilters_validation_special_character ||
                  'advancedFilters_validation_special_character'
              }
            }}
            defaultValue=''
            render={({ field: { value, onChange } }) => (
              <TextField
                name='jobTitle'
                value={jobTitle}
                onChange={value => setJobTitle(value)}
                error={errors?.jobTitle && true}
                errorMessage={errors?.jobTitle?.message}
                label={content?.add_user_job_title || 'add_user_job_title'}
                textChangeHandler={onChange}
                defaultValue={value}
              />
            )}
          />

          <Controller
            name='email'
            control={control}
            defaultValue=''
            rules={{
              required: {
                value: true,
                message: content?.add_user_field_required || 'add_user_field_required'
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: content?.add_user_error_email_format || 'add_user_error_email_format'
              }
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                name='email'
                label={content?.add_user_email || 'add_user_email'}
                textChangeHandler={onChange}
                error={errors?.email && true}
                errorMessage={errors?.email?.message}
                defaultValue={value}
                required
              />
            )}
          />
          <Controller
            name='mobileNumber'
            control={control}
            defaultValue=''
            rules={{
              validate: value => {
                if (!value || !value.number) return true

                const regex = new RegExp(/^([0-9]{1,10})$|^$/)

                return (
                  regex.test(value.number) ||
                  content?.add_user_error_mobile_number_format ||
                  'Mobile number format not valid'
                )
              },
              required: false
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneField
                prefixItems={countryList}
                label={content?.add_user_mobile_number || 'add_user_mobile_number'}
                onChange={onChange}
                defaultPrefix={value.prefix}
                defaultValue={value.number}
                error={errors?.mobileNumber && true}
                errorMessage={errors?.mobileNumber?.message}
                maxLength={10}
              />
            )}
          />
          <Controller
            name='landlineNumber'
            control={control}
            defaultValue=''
            rules={{
              validate: value => {
                if (!value || !value.number) return true

                const regex = new RegExp(/^([0-9]{1,10})$|^$/)

                return (
                  regex.test(value.number) ||
                  content?.add_user_error_landline_number_format ||
                  'Landline number format not valid'
                )
              },
              required: false
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneField
                prefixItems={countryList}
                label={content?.add_user_landline || 'add_user_landline'}
                onChange={onChange}
                defaultPrefix={value.prefix}
                defaultValue={value.number}
                error={errors?.landlineNumber && true}
                errorMessage={errors?.landlineNumber?.message}
                maxLength={10}
              />
            )}
          />
        </TwoColumn>
      </Wrapper>
      <Button
        isLoading={isLoading}
        disabled={!isValid || isLoading}
        onClick={handleSubmit(handleStep)}
        dataAttributes={{ 'data-testid': 'button-new-user-step-2' }}
      >
        {content?.add_user_next || 'add_user_next'}
      </Button>
    </form>
  )
}

export default UserDetails
