import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 800px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 2.5rem;
`

export const TileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const TextPopoverWrapper = styled.div`
  display: flex;
`

export const TwoColumnWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 16px;
`

export const TagsWrapper = styled.div`
  margin: 16px 0;
`

export const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`
