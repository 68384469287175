import { memo } from 'react'
import { Tabs } from '@bp-digital/component-tabs'
import { Wrapper, Heading, SubHeading, InnerPageHeader } from './PageHeader.styled'
const PageHeader = ({
  breadcrumbs = null,
  title,
  subtitle,
  children = null,
  tabs = null,
  defaultTabId = null,
  onTabChange = null,
  headerImage = null,
  testId = 'page-header',
  brand
}) => {
  return (
    <Wrapper>
      {/* {breadcrumbs && (
        <BreadcrumbsWrapper>
          <Breadcrumbs items={breadcrumbs} dataTestId='breadcrumbs' />
        </BreadcrumbsWrapper>
      )} */}

      <InnerPageHeader $hasBreadcrumbs={!!breadcrumbs} headerImage={headerImage} brand={brand}>
        <Heading $hasSubtitle={!!subtitle} data-testid={`${testId}-title`}>
          {title}
        </Heading>
        {subtitle && <SubHeading data-testid={`${testId}-subtitle`}>{subtitle}</SubHeading>}
        {children}
      </InnerPageHeader>
      {tabs && <Tabs id='tabbed-navigation' items={tabs} defaultSelectedId={defaultTabId} onChange={onTabChange} />}
    </Wrapper>
  )
}

export default memo(PageHeader)
