import { Icon } from '@bp-digital/component-icon'
import {
  CardTemplate,
  CardNumber,
  Line,
  Inline,
  Expiry,
  StatusSticker,
  CardProgramName,
  NetworkProgramName
} from './CardPreview.styled'

const CardPreview = ({
  image,
  cardNumber,
  cardProgramName,
  cardStatusId,
  embossingLines,
  countryCode,
  content,
  expiryMonth,
  expiryYear,
  multiple,
  networkProgramName,
  showCode,
  statusStickerText,
  stopped
}) => (
  <CardTemplate image={image} stopped={stopped} multiple={multiple} data-testid='card-image'>
    {cardProgramName && <CardProgramName>{cardProgramName}</CardProgramName>}

    {networkProgramName && <NetworkProgramName>{networkProgramName}</NetworkProgramName>}

    <CardNumber>{cardNumber}</CardNumber>

    {embossingLines &&
      embossingLines.map((line, index) => (
        <Line lineNumber={index + 1} key={`${line}-${index}`}>
          {line}
        </Line>
      ))}
    <div>
      {showCode && <Inline>{countryCode}</Inline>}
      {!showCode && (
        <Expiry data-content-key='manage_card_stopped_card_expiry_date'>
          {content?.manage_card_stopped_card_expiry_date}
        </Expiry>
      )}
      <Inline>{expiryMonth}</Inline>/<Inline>{expiryYear}</Inline>
    </div>
    {cardStatusId && stopped && statusStickerText && (
      <StatusSticker>
        <Icon name='Alert' size='md' />
        {statusStickerText}
      </StatusSticker>
    )}
  </CardTemplate>
)

export default CardPreview
