import { useState } from 'react'
import { useRootStore } from 'contexts/StoreContext'
import { Modal } from '@bp-digital/component-modal'
import { ButtonGroup } from '@bp-digital/component-button-group'
import { AccordionPanel } from '@bp-digital/component-accordion-panel'
import { TabContainer, FiltersContainer, ModalContentWrapper, AppliedFilters } from './AuditFilters.styled'
import AuditChip from './../AuditChip/AuditChip'

import HierarchyFilter from '../HierarchyFilter/HierarchyFilter'
import UserActionTypeFilter from '../UserActionTypeFilter/UserActionTypeFilter'

const AuditFilters = props => {
  const { userStore } = useRootStore()

  const tabDefinition = [
    {
      id: 'MY_LOGS',
      label: 'My logs'
    },
    {
      id: 'ALL_LOGS',
      label: 'All logs'
    }
  ]

  const [auditFilters, setAuditFilters] = useState(props.filters)
  const [selectedTab, setSelectedTab] = useState(
    props.filters.find(fltr => fltr.filterBy == 'filtermode')
      ? props.filters.find(fltr => fltr.filterBy == 'filtermode').filterCriteria[0]
      : tabDefinition[0].id
  )

  const checkForm = () => {
    // Hierarchy selection required for 'ALL_LOGS' (exempt if only access to single auth/parent)
    if (
      selectedTab === 'ALL_LOGS' &&
      userStore.hierarchy.parents.length + userStore.hierarchy.authorities.length != 1 &&
      !auditFilters.find(fltr => fltr.filterBy == 'hierarchy' && fltr.filterCriteria.length)
    )
      return true
    return false
  }

  const setSelectedTabAction = tabId => {
    setSelectedTab(tabId)
    const filters = auditFilters.filter(fltr => fltr.filterBy != 'filtermode')
    filters.push({
      filterBy: 'filtermode',
      filterCriteria: [tabId]
    })
    // add/remove actors filter value as per tab // temporary -> update journey when actor filter is completed
    filters.find(fltr => fltr.filterBy == 'actors').filterCriteria =
      tabId == 'MY_LOGS' ? [userStore.user?.uniqueId] : []
    setAuditFilters(filters)
  }

  const sendFilters = () => {
    const filterData = auditFilters

    if (
      selectedTab === 'ALL_LOGS' &&
      userStore.hierarchy.parents.length + userStore.hierarchy.authorities.length == 1
    ) {
      // automaticly add only available authority item to the filter
      filterData.push({
        filterBy: 'hierarchy',
        filterCriteria: [`A${userStore.hierarchy.authorities[0]}`]
      })
    }
    props.setFilters(filterData)
  }

  return (
    <Modal
      title='Filters'
      primaryAction={{
        text: 'Apply',
        iconName: 'Save',
        appearance: 'primary',
        disabled: checkForm(),
        onClick: () => sendFilters()
      }}
      secondaryAction={{
        text: 'Cancel',
        iconName: 'RemoveLarge',
        appearance: 'tertiary',
        onClick: props.onDismiss
      }}
      onDismiss={props.onDismiss}
      visible
      size='lg'
    >
      <ModalContentWrapper>
        <TabContainer>
          <ButtonGroup
            id={'Tab_Selector'}
            items={tabDefinition}
            onChange={setSelectedTabAction}
            defaultSelectedId={selectedTab}
          />
        </TabContainer>

        <FiltersContainer>
          {userStore.hierarchy.parents.length + userStore.hierarchy.authorities.length > 1 && (
            <AccordionPanel
              title={`Hierarchy${selectedTab === 'ALL_LOGS' ? ' (Required)' : ''}`}
              iconName='Diamond'
              iconState={
                selectedTab === 'ALL_LOGS'
                  ? !auditFilters.find(fltr => fltr.filterBy == 'hierarchy') ||
                    !auditFilters.find(fltr => fltr.filterBy == 'hierarchy').filterCriteria
                    ? 'danger'
                    : 'neutral'
                  : 'neutral'
              }
              iconAppearance='dark'
              borderState='dark'
            >
              <HierarchyFilter
                filters={auditFilters}
                setFilters={setAuditFilters}
                required={selectedTab === 'ALL_LOGS'}
              ></HierarchyFilter>
            </AccordionPanel>
          )}

          <AccordionPanel
            title='Log Type'
            iconName='Attach'
            iconState='neutral'
            iconAppearance='dark'
            borderState='dark'
          >
            <UserActionTypeFilter
              filters={auditFilters}
              displayLogins={selectedTab === 'ALL_LOGS'}
              setFilters={setAuditFilters}
            ></UserActionTypeFilter>
          </AccordionPanel>
        </FiltersContainer>

        <AppliedFilters>
          {auditFilters.length > 0 &&
            auditFilters.map(fltr => {
              return (
                <AuditChip
                  key={fltr.filterBy}
                  filterKey={fltr.filterBy}
                  value={fltr.filterCriteria}
                  onClick={() =>
                    setAuditFilters(
                      auditFilters
                        .filter(fltr2 => fltr.filterBy != fltr2.filterBy)
                        .concat({ filterBy: fltr.filterBy, filterCriteria: [] })
                    )
                  }
                />
              )
            })}
        </AppliedFilters>
      </ModalContentWrapper>
    </Modal>
  )
}

export default AuditFilters
