import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 1rem 0;
`

export const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
`

export const InputControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const RestrictionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const RestrictionsProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: end;
`

export const Messaging = styled.div`
  display: flex;
  place-items: center left;
  width: 90%;
  color: ${CssVars.palette.accent.orangeDark};

  .message {
    margin: 0 0 0 0.5rem;
    line-height: ${CssVars.typography.lineHeight.lg};
  }
`

export const WhatsThisMessage = styled.span`
  font-family: ${CssVars.typography.font.default};
  font-weight: ${CssVars.typography.weight.light};
  font-size: ${CssVars.typography.size.md};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Footnote = styled.div`
  margin-top: 1em;
`

export const KeyItemContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const KeyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`

export const StyledVehiclesBadge = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  .vehicle1 {
    transform: translateY(5px);
  }
  .vehicle2 {
    transform: translateY(-5px);
  }
`

export const StyledCardPinBadge = styled.div`
  .card {
    transform: translateX(5px) translateY(3px);
  }
  .pin {
    transform: translateX(-2px) translateY(-6px) scale(0.8);
  }
  margin-right: -10px;
`

export const StyledDriversPinBadge = styled.div`
  .drivers {
    transform: translateX(5px) translateY(1px);
  }
  .pin {
    transform: translateX(-9px) translateY(-7px) scale(0.8);
  }
  margin-right: -10px;
`

export const CellWithIconAndText = styled.div`
  width: 110px;
  padding: 5px 0;
`

export const IconRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px;
  margin: 10px 0 0 -4px;
`
