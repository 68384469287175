import axios from 'axios'
import { useQuery, QueryOptions } from 'react-query'

export const QUERY_KEY = 'cards/restriction-profile-'

const queryFn = async (profileId: number) => {
  const response = await axios({
    method: 'get',
    url: `/api/cards/profile/restrictions/${profileId}`
  })

  return response.data
}

const useApiGetRestrictionProfile = (profileId: number, options: QueryOptions = {}) => {
  return useQuery(QUERY_KEY + profileId, () => queryFn(profileId), options)
}

export default useApiGetRestrictionProfile
