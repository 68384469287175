import ReactDOM from 'react-dom'
import { useQueryClient } from 'react-query'
import { AlertModal } from '@bp-digital/component-alert-modal'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import replaceVariablesInString from 'helpers/replaceVariablesInString'

const root = document.getElementById('root')

const RemoveRestrictionModal = ({ selectedAuthorityId, profileId, profileName, type, content = {}, onClose }) => {
  const queryClient = useQueryClient()
  const { mutate: profileUpdate, isLoading } = useApiPostRestrictionProfileUpdate(
    () => {
      // restriction_profiles_delete_notification_success_singular
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      onClose()
    },
    error => {
      // restriction_profiles_update_notification_error_singular
      console.error(error)
    },
    selectedAuthorityId
  )
  const contentKeyMapper = {
    spendingLimit: 'spending_limit',
    sitelocation: 'site',
    dayTime: 'day_time',
    odometerAndDistance: 'odometer_title',
    productAndServices: 'products_services'
  }

  return ReactDOM.createPortal(
    <AlertModal
      visible
      onDismiss={onClose}
      text={replaceVariablesInString(content.restriction_removal_warning, {
        restrictionName: content[`restriction_profiles_${contentKeyMapper[type]}`]
      })}
      state='warning'
      primaryAction={{
        text: content.restriction_profiles_remove || 'Remove',
        onClick: () =>
          profileUpdate({
            restrictionDetail: {
              [type]: null,
              restrictionTypes: [type],
              profileId,
              profileName,
              saveProfile: true,
              existingProfile: true
            }
          }),
        isLoading,
        disabled: isLoading,
        dataAttributes: {
          'data-testid': 'button-confirm-remove-restriction',
          'data-content-key': 'restriction_profiles_remove'
        }
      }}
      secondaryAction={{
        text: content.cancel || 'Cancel',
        onClick: onClose,
        dataAttributes: {
          'data-testid': 'button-cancel-remove-restriction',
          'data-content-key': 'cancel'
        }
      }}
    />,
    root
  )
}

export default RemoveRestrictionModal
