import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'
// import breakpoints from 'styles/breakpoints'

export const SingleChargerContainer = styled.div`
  width: 100%;
`
export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
export const ChargerDetailsContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 20px;
  background: ${CssVars.palette.neutral.white};
  padding: 40px;
  max-width: 420px;
  width: 100%;

  & > h4 {
    margin: 0 0 16px;
  }
`
export const ChargerDetails = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;

  & > p {
    margin-top: 0;
  }

  & > p:nth-child(even) {
    text-align: right;
  }
`

export const ChargerDetailsRow = styled.div`
  grid: grid-row;
`
// react-table renderDetail view
export const SingleChargerViewWrapper = styled.div`
  position: relative;
  height: 372px;
  display: flex;
  width: 100%;
`
