import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
    margin-bottom: 16px;
  }
`

export const SearchWithTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  @media only screen and ${breakpoints.device.sm} {
    width: 300px;
  }

  /* Tooltip */
  span {
    flex-basis: 0;
  }

  span[aria-hidden='false'] {
    white-space: normal;
    width: 80vw;
  }

  @media only screen and ${breakpoints.device.sm} {
    span[aria-hidden='false'] {
      white-space: normal;
      width: 500px;
      max-width: 50vw;
    }
  }
`
