import styled from 'styled-components'

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const BrandImage = styled.img`
  max-height: 40px;
`
