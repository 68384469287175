import styled from 'styled-components'
import FlexibleLink from '../../FlexibleLink'
import { BRAND_ARAL} from 'src/constants/brands'

export const Wrapper = styled.li`
  display: block;
  width: 100%;
`

export const Link = styled(FlexibleLink)`
  display: flex;
  width: 100%;
  border-left: ${props => props.$isActive ? `10px solid ${props.$brand === BRAND_ARAL ? 'var(--color-blue-regular-400)' : 'var(--color-green-light-300)'}` : '10px solid transparent'};
  padding: 16px 8px;
  text-decoration: none;
  color: #fff;
  align-items: center;

  &:hover {
    background-color: ${props => props.$brand === BRAND_ARAL ? 'var(--color-blue-regular-400)' : 'var(--color-green-light-300)'};
    cursor: pointer;
    animation: ${props => (props.$isActive ? 'none' : 'fadeIn .2s linear')};
    color: var(--color-grey-regular-900);
  }

  > *:first-child {
    padding-right: 8px;
  }

  > *:nth-child(2) {
    margin-left: auto;
  }
`

export const SubSideNav = styled.ul`
  list-style: none;
  display: ${props => (props.isOpen ? 'inherit' : 'none')};
`

export const SubSideNavItem = styled.li`
  width: 100%;
`

export const SubSideNavItemLink = styled(FlexibleLink)`
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 56px;
  text-decoration: none;
  color: #fff;
  align-items: center;

  &:hover {
    background-color: ${props => props.$isActive ? 'transparent' : `${props.$brand === BRAND_ARAL ? 'var(--color-blue-regular-400)' : 'var(--color-green-light-300)'}`};
    cursor: pointer;
    animation: ${props => (props.$isActive ? 'none' : 'fadeIn .2s linear')};
    color: ${props => (props.$isActive ? '#fff' : 'var(--color-grey-regular-900)')};
  }

  &::before {
    content: '';
    visibility: ${props => (props.$isActive ? 'visible' : 'hidden')};
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-color: ${props => props.$brand === BRAND_ARAL ? 'var(--color-blue-regular-400)' : 'var(--color-green-light-300)'};
    border-radius: 50%;
  }
`
