import { useEffect, useMemo, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import { useRootStore } from 'src/contexts/StoreContext'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import PageHeader from 'src/components/layout/PageHeader'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import evBanner from 'src/assets/headers/ev-banner.jpg'
import SingleChargePoint from './SingleChargePoint'
import { Redirect } from 'react-router-dom'
import { PERMISSION_TO_VIEW_EV_MENU } from 'constants/permissions'
import { ROUTE_ADMIN, ROUTE_DASHBOARD } from 'constants/routes'

const ViewChargePoints = () => {
  const { chargePointsStore, contentStore, userStore } = useRootStore()
  const content = contentStore.getPage('chargepointoperations-view')
  const [redirectDashboard, setRedirectDashboard] = useState(false)
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  const mountedRef = useRef(true)

  /// const authorityId = userStore.selectedAuthorityId
  const selectedHierarchy = userStore.selectedHierarchy

  const loadingRow = chargePointsStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  useEffect(() => {
    if (!mountedRef.current) return null
    const isNotEVUser =
      userStore.userPermissions &&
      userStore.userPermissions.length &&
      !userStore.userPermissions.includes(PERMISSION_TO_VIEW_EV_MENU)
    if (isNotEVUser) setRedirectDashboard(true)
    if (userStore.isAdmin && !userStore.impersonatedUser) setRedirectAdmin(true)
  }, [userStore.userPermissions, userStore.isAdmin, userStore.impersonatedUser])

  useEffect(() => {
    if (!mountedRef.current) return null
    if (selectedHierarchy) {
      chargePointsStore.getChargePoints({ selectedHierarchy })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHierarchy])

  useEffect(() => {
    if (!mountedRef.current) return null
    if (!content) {
      contentStore.getContent('chargepointoperations-view')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const tablePagination = {
    currentPage: chargePointsStore.currentPage,
    count: chargePointsStore.numberOfPages || 1,
    disabled: !chargePointsStore.data,
    onChange: value => chargePointsStore.getChargePoints({ selectedHierarchy, page: value })
  }

  const data = useMemo(
    () =>
      chargePointsStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : chargePointsStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargePointsStore.rows, chargePointsStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      chargePointsStore.isFetchingData
        ? chargePointsStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : chargePointsStore.columns.map(col => {
            if (col.key === 'chargerStatus') {
              return {
                Header: col.name,
                accessor: col.key,
                sortType: (rowA, rowB) => {
                  if (rowA.original['chargerStatusValue'] > rowB.original['chargerStatusValue']) return 1
                  if (rowB.original['chargerStatusValue'] > rowA.original['chargerStatusValue']) return -1
                  return 0
                }
              }
            } else {
              return {
                Header: col.name,
                accessor: col.key
              }
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargePointsStore.columns, chargePointsStore.isFetchingData, tablePagination.currentPage]
  )

  if (!mountedRef.current) return
  if (redirectAdmin) return <Redirect to={ROUTE_ADMIN} />
  if (redirectDashboard) return <Redirect to={ROUTE_DASHBOARD} />

  return (
    <>
      <PageHeader
        title={content?.cpo_view_manage_title || '...'}
        subtitle={content?.masthead_all || '...'}
        headerImage={evBanner}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <ReactTable
          columns={columns}
          data={data}
          pagination={tablePagination}
          isLoading={chargePointsStore.isFetchingData}
          renderDetail={props => (
            <SingleChargePoint
              siteId={props.original.siteId}
              siteAddress={props.original.siteAddress}
              sitePostCode={props.original.sitePostCode}
              siteCity={props.original.siteCity}
              siteCountry={props.original.siteCountry}
              chargePoint={props.original.chargePoints?.[0]}
              index={props.index}
              content={content}
            />
          )}
          hideCheckboxColumn
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(ViewChargePoints)
