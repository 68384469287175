import { ReactElement } from 'react'
import { Icon } from '@bp-digital/component-icon'
import { ItemContainer, ItemContent, ItemIcon, ItemAmount, ItemSubtext } from './ListItem.styled'
import { Transaction } from '../../../../../TransactionMap.types'
import { getTransactionOptions } from '../../../../../TransactionMap.utils'
import getValueOrFallback from 'helpers/getValueOrFallback'

export interface BaseItemProps {
  cardholderName: string
  transaction: Transaction
  isSelected: boolean
  onSelectTransaction?: (transaction: Transaction) => void
}

const ListItem = ({ cardholderName, transaction, isSelected, onSelectTransaction }: BaseItemProps): ReactElement => {
  const handleOnClick = () => {
    onSelectTransaction && onSelectTransaction(transaction)
  }

  const options = getTransactionOptions(transaction)

  return (
    <ItemContainer $isSelected={isSelected} onClick={handleOnClick} $color={options.color}>
      <ItemIcon>
        <Icon name={options.iconName} state={options.iconState} appearance='dark' size='lg' />
      </ItemIcon>
      {options.alertType !== 'cleared' ? (
        <ItemContent>
          {transaction.oacResultCodeDescription} <br />
          {transaction.transactionDateTime}
          <br />
          {transaction.mapDetails.siteName}
        </ItemContent>
      ) : (
        <>
          <ItemContent>
            {cardholderName}
            <ItemSubtext>{transaction.transactionDateTime}</ItemSubtext>
          </ItemContent>
          <ItemAmount>{`${transaction.amount?.supplyCurrency} ${getValueOrFallback(
            transaction.amount?.amount
          )}`}</ItemAmount>
        </>
      )}
    </ItemContainer>
  )
}

export default ListItem
