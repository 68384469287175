import { ALLOWED_DAYS, CARD_SETUP_TYPES, DAYS } from 'constants/restrictions'
import { formatTime } from '.'

export const getDayTimeRestrictions = (restriction = {}, content, keyPrefix) => {
  const limits = []

  const allowedAllDay = (day, content, keyPrefix) => day && `${day} (${content[`${keyPrefix}day_time_all_day`]})`

  const notAllowedAllDay = (day, content, keyPrefix) =>
    day && `${day} (${content[`${keyPrefix}day_time_not_allowed`]} : ${content[`${keyPrefix}day_time_all_day`]})`

  const allowedTimes = (day, start, end) => day && `${day} (${start} - ${end})`

  const notAllowedTimes = (day, start, end, content, keyPrefix) =>
    day && `${day} (${content[`${keyPrefix}day_time_not_allowed`]} : ${start} - ${end})`

  const weekdaysAllDay = (content, keyPrefix) =>
    DAYS.map((day, index) =>
      index > 5
        ? notAllowedAllDay(content[`${keyPrefix}day_time_${day}`], content, keyPrefix)
        : allowedAllDay(content[`${keyPrefix}day_time_${day}`], content, keyPrefix)
    )

  const weekendAllDay = (content, keyPrefix) =>
    DAYS.map((day, index) =>
      index < 6
        ? notAllowedAllDay(content[`${keyPrefix}day_time_${day}`], content, keyPrefix)
        : allowedAllDay(content[`${keyPrefix}day_time_${day}`], content, keyPrefix)
    )

  const customSetup = (daysWithTime, content, keyPrefix) =>
    DAYS.map((day, index) => {
      const dayName = content[`${keyPrefix}day_time_${day}`]
      const findDay = daysWithTime.find(({ dayOfWeek }) => dayOfWeek === index)
      const isDayAllowed = findDay && findDay.isAllow === ALLOWED_DAYS.ALLOW

      let string = notAllowedAllDay(dayName, content, keyPrefix)

      if (findDay) {
        const isAllDay =
          formatTime(String(findDay.startTime).replace(/\s/g, '')) === '00:00' &&
          formatTime(String(findDay.endTime).replace(/\s/g, '')) === '23:59'

        if (isDayAllowed) {
          if (isAllDay) {
            string = allowedAllDay(dayName, content, keyPrefix)
          } else {
            string = allowedTimes(
              dayName,
              formatTime(String(findDay.startTime).replace(/\s/g, '')),
              formatTime(String(findDay.endTime).replace(/\s/g, '')),
              keyPrefix
            )
          }
        } else {
          string = ''

          if (isAllDay) {
            string = notAllowedAllDay(dayName, content, keyPrefix)
          } else {
            string = notAllowedTimes(
              dayName,
              formatTime(String(findDay.startTime).replace(/\s/g, '')),
              formatTime(String(findDay.endTime).replace(/\s/g, '')),
              content,
              keyPrefix
            )
          }
        }
      } else {
        string = notAllowedAllDay(dayName, content, keyPrefix)
      }

      return string
    })

  if (restriction.setupType === CARD_SETUP_TYPES.WEEKDAYS) {
    limits.push({
      label: content[`${keyPrefix}day_time_weekdays`],
      values: weekdaysAllDay(content, keyPrefix).filter(Boolean)
    })
  } else if (restriction.setupType === CARD_SETUP_TYPES.WEEKEND) {
    limits.push({
      label: content[`${keyPrefix}day_time_weekends`],
      values: weekendAllDay(content, keyPrefix).filter(Boolean)
    })
  } else if (restriction.setupType === CARD_SETUP_TYPES.CUSTOM) {
    limits.push({
      label: content[`${keyPrefix}day_time_setup`],
      values: customSetup(restriction.daysWithTime, content, keyPrefix).filter(Boolean)
    })
  }

  return limits
}
