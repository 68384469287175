import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const InvoiceSummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media only screen and ${breakpoints.device.lg} {
    grid-template-columns: 1fr 1fr;
  }
`

export const InvoiceSummaryBlock = styled.div`
  border-left: 1px dashed var(--color-grey-regular-500);
  padding-left: 16px;

  > p:first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    height: 44px;
  }

  > p {
    margin: 0;
  }

  > span > * {
    display: block;
    margin: 8px 0;
  }
`
