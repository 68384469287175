import styled from 'styled-components'

export const Wrapper = styled.div``

export const Subtitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  > *:first-child {
    padding-right: 8px;
  }
`

export const TimelineWrapper = styled.div`
  > * {
    margin-bottom: 16px;
  }
`
