import { CountryFlagWrapper } from './CountryFlag.styled'

const CountryFlag = ({ countryCode = '', altTag = countryCode }) => {
  return (
    <CountryFlagWrapper>
      <img src={`/assets/flags/${countryCode.toLowerCase()}.svg`} alt={altTag} />
    </CountryFlagWrapper>
  )
}

export default CountryFlag
