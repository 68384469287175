export const STATUS_AVAILABLE = 'AVAILABLE'
export const STATUS_BLOCKED = 'BLOCKED'
export const STATUS_CHARGING = 'CHARGING'
export const STATUS_INOPERATIVE = 'INOPERATIVE'
export const STATUS_NOT_AVAILABLE = 'NOT_AVAILABLE'
export const STATUS_OUTOFORDER = 'OUTOFORDER'
export const STATUS_PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE'
export const STATUS_PLANNED = 'PLANNED'
export const STATUS_REMOVED = 'REMOVED'
export const STATUS_RESERVED = 'RESERVED'
export const STATUS_UNKNOWN = 'UNKNOWN'
