
// Transactions
export const PERMISSION_TO_VIEW_TRANSACTIONS = 'transactionsSearchView'

// Invoices
export const PERMISSION_TO_VIEW_INVOICES = 'invoiceSearchView'

// Cards
export const PERMISSION_TO_VIEW_CARDS = 'viewCardsSearchCards'

// Access administration
export const PERMISSION_TO_VIEW_USER_MENU = 'viewUserMenu'
export const PERMISSION_TO_VIEW_GROUP_MENU = 'viewGroups'

// EV - only single check for entire EV menu currently
export const PERMISSION_TO_VIEW_EV_MENU = 'isEVUser'

// download and manage files
export const PERMISSION_TO_DOWNLOAD_PRICELIST = 'downloadPriceList'
export const PERMISSION_TO_MANAGE_PRICELIST = 'managePriceList'
