import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import banner from 'src/assets/headers/banner.jpg'
import aralbanner from 'src/assets/headers/aral-banner.jpg'
import { CssVars } from '@bp-digital/theme'
import {BRAND_BP } from 'constants/brands'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export const InnerPageHeader = styled.div`
  width: 100%;
  position: relative;
  padding: 24px 16px;
  background-color: var(--color-grey-regular-100);
  background-image: url(${props => (!props.brand ? '' : ( props.brand.toUpperCase() === BRAND_BP ? banner : aralbanner ))});
  background-size: cover;
  background-position: center;
  color: #fff;

  &:after {
    content: '';
    display: block;
    background-color: rgba(17, 17, 17, 0.65);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  @media only screen and ${breakpoints.device.lg} {
    padding: 56px 32px;
  }
`

export const Heading = styled.h1`
  font-size: ${CssVars.typography.size.xl};
  font-weight: ${CssVars.typography.weight.light};
  margin-bottom: ${props => (props.$hasSubtitle ? '0' : 'initial')};
  position: relative;
  z-index: 5;
`

export const SubHeading = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 5;
  font-size: ${CssVars.typography.size.lg};
  font-weight: ${CssVars.typography.weight.light};
  margin-top: 0.5rem;
`

export const BreadcrumbsWrapper = styled.div`
  width: 100%;
  background-color: var(--color-grey-regular-100);
  padding: 8px 16px;

  @media only screen and ${breakpoints.device.lg} {
    padding: 8px 32px;
  }
`
