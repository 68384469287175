import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'
// import { IconStyled } from 'elements/Icon/Icon.styled';

export const CardTemplate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-image: ${props => (props.image ? `url(${props.image})` : 'none')};
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.95em;
  width: 22.5em;
  height: 14em;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  color: ${CssVars.palette.neutral.white};
  letter-spacing: 0.25em;
  text-align: right;
  padding: 1.4em;
  padding-bottom: 1.1em;
  font-size: 1rem;
  text-shadow: -1px 0 2px ${CssVars.palette.neutral.black};
  ${props =>
    props.stopped &&
    `
      filter: grayscale(100);
      background-color: ${CssVars.palette.neutral.white};
      color: ${CssVars.palette.neutral.black};
      text-shadow: none;
  `}
  ${props =>
    props.multiple &&
    `
      position: relative;
      overflow: visible;
      box-shadow: 0 0 5px rgba(0,0,0,0.4);
      background-size: cover;

      &:after,
      &:before {
        width: 20.5em;
        height: 14em;
        display: block;
        position: absolute;
        content: '';
        top: -0.7em;
        left: 1em;
        border-radius: 0.95em;
        background-image: ${props.image ? `url(${props.image})` : 'none'};
        background-size: cover;
        z-index: -1;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
      }

      &:before {
        width: 18.2em;
        top: -1.4em;
        left: 2.1em;
        z-index: -2;
      }
  `}
`

const getY = ({ lineNumber }) => {
  if (lineNumber === 2) {
    return '13.5em'
  }
  if (lineNumber === 3) {
    return '15.5em'
  }
  return '11.5em'
}

export const CardNumber = styled.span`
  position: absolute;
  left: 1.5em;
  top: 7.3em;
  letter-spacing: 0.6em;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
  text-shadow: none;
`

export const Inline = styled.span`
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-left: 0.875em;
  }
`

export const Expiry = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 0.875rem;
`

export const Line = styled(CardNumber)`
  top: ${getY};
  font-size: 0.75rem;
  left: 1.7em;
  letter-spacing: 0.2em;
  text-shadow: inherit;
`

export const StatusSticker = styled.span`
  position: absolute;
  top: 0.5em;
  right: 2em;
  border: 2px solid ${CssVars.palette.state.dangerDark};
  border-radius: 4px;
  background-color: ${CssVars.palette.neutral.white};
  color: ${CssVars.palette.state.dangerDark};
  padding: 0.25em 0.5em;
  text-transform: none;
  letter-spacing: normal;
  text-shadow: none;

  [data-testid='icon-wrapper'] {
    vertical-align: bottom;
  }
`

export const CardProgramName = styled.span`
  text-transform: none;
  position: absolute;
  top: 2.8em;
  left: 5em;
`

export const NetworkProgramName = styled(CardProgramName)`
  right: 2em;
`
