export const DATALAYER_KEYS = {
  CUSTOMER_TYPE: 'customer_type',
  BRAND: 'brand',
  CUSTOMER_ID: 'customer_id',
  PARENT_ID: 'parent_id',
  USER_ID: 'user_id'
}

export const DATALAYER_VALUES = {
  CUSTOMER_TYPE_EV: 'EV',
  CUSTOMER_TYPE_FUEL: 'Fuel'
}
