import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const LocationPin = styled.div<{ $color?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;

  // temporary change to icon
  > *[name='Pin'] svg > g {
    fill: ${({ $color }) => $color ?? CssVars.palette.state.neutral};

    path:nth-child(2) {
      stroke: #fff;
    }
  }
`
