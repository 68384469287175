const getSelectedInvoices = (selectedRows) => {
  const selectedChildrenWithoutSelectedParent = []

  selectedRows.forEach(row => {
    if (row.includes('child')) {
      const parentKey = row.split('-')[0]

      if (!selectedRows.includes(parentKey)) {
        selectedChildrenWithoutSelectedParent.push(row)
      }
    }
  })

  return selectedChildrenWithoutSelectedParent
}

export default getSelectedInvoices
