import React from 'react'
import { StateMessage } from '@bp-digital/component-state-message'
import { Badge } from '@bp-digital/component-badge'
import { Text } from '@bp-digital/component-typography'
import TwoColumn from 'components/layout/TwoColumn'
import { BadgeList } from '../../RestrictionProfilePage/RestrictionProfilePage.styled'

const getViolationState = ({
  accept = false,
  alert = false,
  content = {
    'restriction_profiles_violation_option_1': '',
    'restriction_profiles_violation_option_2': '',
    'restriction_profiles_violation_option_3': ''
  },
  isLoading = false
}) => {
  if (accept && !alert) {
    return (
      <StateMessage
        state='success'
        iconName='Info'
        text={content.restriction_profiles_violation_option_1}
        dataAttributes={{ 'data-content-key': content.restriction_profiles_violation_option_1 }}
      />
    )
  } else if (accept && alert) {
    return (
      <StateMessage
        state='warning'
        iconName='Alert'
        text={content.restriction_profiles_violation_option_2}
        dataAttributes={{ 'data-content-key': content.restriction_profiles_violation_option_2 }}
      />
    )
  } else if (!accept && alert) {
    return (
      <StateMessage
        state='danger'
        iconName='Alert'
        text={content.restriction_profiles_violation_option_3}
        dataAttributes={{ 'data-content-key': content.restriction_profiles_violation_option_3 }}
      />
    )
  }

  return isLoading ? '...' : null
}

const getBadgeState = (violationName, value, content) => {
  switch(violationName) {
    case 'DayTime':
      return value?.includes(content['restriction_profiles_day_time_not_allowed']) ? 'danger' : 'success'
    default:
      return (
        value === content[`restriction_profiles_product_allowed`] && 'success') ||
        (value === content[`restriction_profiles_product_not_allowed`] && 'danger') ||
        'info'
  }
}

const SingleRestrictionDetails = ({ isLoading = false, restriction, violationName, content = {}, formattedRestrictions = [] }) => (
  <>
    {getViolationState({
      accept: restriction?.[`accept${violationName}Violation`],
      alert: restriction?.[`alert${violationName}Violation`],
      content,
      isLoading
    })}
    <TwoColumn>
      {formattedRestrictions.map(
        (item, index) => {
          return (
            <React.Fragment key={index}>
              <Text>{item.label}</Text>
              <BadgeList direction='vertical'>
                {Array.isArray(item.values) && item.values[0] !== '-' ? (item.values || []).map(value => {
                  return (
                    <Badge
                      key={value}
                      state={getBadgeState(violationName, value, content)}
                      text={Array.isArray(value) ? value.join(', ') : value}
                    />
                )}) : item.values}
              </BadgeList>
            </React.Fragment>
          )
        }
      )}
    </TwoColumn>
  </>
)

export default SingleRestrictionDetails
