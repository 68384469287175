import { useEffect } from 'react'
import { DropDown } from '@bp-digital/component-drop-down'
import { ButtonGroup } from '@bp-digital/component-button-group'
import { Button } from '@bp-digital/component-button'
import getSiteLocationLabel from '../../helpers/getSiteLocationLabel'
import { Controller, useForm } from 'react-hook-form'
import { Spacer } from 'styles/common.styled'
import Skeleton from 'react-loading-skeleton'

const ALL = 0

const SelectLocationForm = ({
  content,
  onSubmit,
  onUpdate,
  isEditing,
  countries,
  countriesSuccess,
  updatedValues,
  setUpdatedValues,
  locationData,
  dropDownDataLoading,
  fetchLocationData
}) => {
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { isValid },
    reset
  } = useForm({
    defaultValues: {
      country: '',
      region: '',
      site: '',
      isAllow: 1,
      selectedCountry: {},
      selectedRegion: {
        region: content?.restriction_site_all_region,
        areaId: ALL
      },
      selectedSite: {
        siteName: content?.restriction_site_all_site,
        globalSiteId: ALL
      }
    },
    mode: 'onBlur'
  })

  useEffect(() => {
    if (updatedValues) {
      setValue('editIndex', updatedValues.editIndex)
      setValue('country', updatedValues.country)
      setValue('region', updatedValues.region)
      setValue('site', updatedValues.site)
      setValue('isAllow', updatedValues.isAllow)
      setValue('selectedCountry', updatedValues.selectedCountry)
      setValue('selectedRegion', updatedValues.selectedRegion)
      setValue('selectedSite', updatedValues.selectedSite)
      setUpdatedValues(null)
    }
  }, [setValue, updatedValues, setUpdatedValues])

  const watchCountryField = watch('country')
  const watchRegionField = watch('region')
  const watchSiteField = watch('site')

  const handleCountryChange = value => {
    if (!value) return false

    const selectedCountry = countries.find(c => c.countryId == value)
    setValue('selectedCountry', selectedCountry)
    setValue('country', value, { shouldValidate: true })
    fetchLocationData(value, null)
  }

  const handleRegionChange = value => {
    setValue('region', value, { shouldValidate: true })
    if (value == ALL) {
      setValue('selectedRegion', {
        region: content?.restriction_site_all_region,
        areaId: ALL
      })
    } else {
      const selectedRegion = locationData.distinctRegions.find(r => r.areaId == value)
      setValue('selectedRegion', selectedRegion)
      fetchLocationData(getValues('country'), value)
    }
  }

  const handleSiteChange = value => {
    setValue('site', value, { shouldValidate: true })
    if (value == ALL) {
      setValue('selectedSite', {
        siteName: content?.restriction_site_all_site,
        globalSiteId: ALL
      })
    } else {
      const selectedSite = locationData.sites.find(s => s.globalSiteId == value)
      setValue('selectedSite', selectedSite)
    }
  }

  const isSelected = input => (typeof input === 'string' ? false : true)

  const addHandler = value => {
    onSubmit(value)
    setValue('country', null)
    reset()
  }

  return (
    <>
      {countriesSuccess ? (
        <Controller
          name='country'
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, name } }) => (
            <DropDown
              id={name}
              label={content?.restriction_site_country}
              placeholder={content?.dropdown_select}
              onChange={value => handleCountryChange(value)}
              defaultSelectedId={value}
              selectedId={value}
              options={[
                { id: '', label: content?.restriction_site_country || 'restriction_site_country' },
                ...countries.map(c => ({
                  id: c.countryId,
                  label: c.countryName
                }))
              ]}
            />
          )}
        />
      ) : (
        <Skeleton height={50} />
      )}
      <Spacer />
      {!dropDownDataLoading ? (
        <Controller
          name='region'
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, name } }) => (
            <DropDown
              id={name}
              disabled={watchCountryField === ''}
              placeholder={content?.dropdown_select}
              label={content?.restriction_site_region}
              onChange={value => handleRegionChange(value)}
              defaultSelectedId={value}
              selectedId={value}
              options={[
                {
                  id: ALL,
                  label: content?.restriction_site_all_region
                },
                ...locationData.distinctRegions.map(r => ({
                  id: r.areaId,
                  label: r.region
                }))
              ]}
            />
          )}
        />
      ) : (
        <Skeleton height={50} />
      )}
      <Spacer />
      {!dropDownDataLoading ? (
        <Controller
          name='site'
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, name } }) => (
            <DropDown
              disabled={watchRegionField === ''}
              placeholder={content?.dropdown_select}
              label={content?.restriction_site_site}
              onChange={value => handleSiteChange(value)}
              defaultSelectedId={value}
              selectedId={value}
              options={[
                {
                  id: ALL,
                  label: content?.restriction_site_all_site
                },
                ...locationData.sites.map(s => ({
                  id: s.globalSiteId,
                  label: getSiteLocationLabel(s)
                }))
              ]}
              id={name}
            />
          )}
        />
      ) : (
        <Skeleton height={50} />
      )}
      <Spacer />
      <Controller
        name='isAllow'
        control={control}
        render={({ field: { value, onChange, name } }) => (
          <ButtonGroup
            id={name}
            items={[
              {
                id: 1,
                label: content?.restriction_site_allow || 'restriction_site_allow'
              },
              {
                id: 2,
                label: content?.restriction_site_not_allow || 'restriction_site_not_allow'
              }
            ]}
            defaultSelectedId={value}
            onChange={onChange}
          />
        )}
      />
      <Spacer />
      {isEditing ? (
        <Button disabled={!isValid} onClick={handleSubmit(onUpdate)}>
          {content?.restriction_site_update}
        </Button>
      ) : (
        <Button
          disabled={
            !isValid || !(isSelected(watchCountryField) && isSelected(watchRegionField) && isSelected(watchSiteField))
          }
          onClick={handleSubmit(addHandler)}
        >
          {content?.restriction_site_add}
        </Button>
      )}
    </>
  )
}

export default SelectLocationForm
