import { useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { CARD_REISSUESTATUS_SUCCESS_MESSAGE, CARD_STATUS_CODE, CARD_STATUS_ID } from 'constants/cards'
import { useRootStore } from 'contexts/StoreContext'

const root = document.getElementById('root')

const ReissueCard = ({
  isRecentlyUnused,
  cardId,
  cardStatus,
  cardStatusId,
  countryCode,
  onClose,
  content = {},
  setToastData
}) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { viewManageCardStore, cardReissueStore, userStore } = useRootStore()
  const newCardStatus = isRecentlyUnused ? CARD_STATUS_CODE.AWAITING_REISSUE : CARD_STATUS_CODE.NOT_FOR_REISSUE
  const newCardStatusId = isRecentlyUnused ? CARD_STATUS_ID.AWAITING_REISSUE_NEW : CARD_STATUS_ID.NOT_FOR_REISSUE
  const authorityId = userStore.selectedAuthorityId

  const reissue = async () => {
    setIsUpdating(true)

    try {
      const response = await axios({
        method: 'post',
        url: '/api/cards/card-action/card-status',
        data: {
          cardStatuses: [
            {
              cardId,
              countryCode,
              prevCardStatus: cardStatus,
              prevCardStatusId: cardStatusId,
              setCardStatus: newCardStatus,
              setCardStatusId: newCardStatusId
            }
          ]
        }
      })
      if (
        Object.prototype.hasOwnProperty.call(response.data, 'cardResponses')
          ? response.data?.cardResponses[0]?.transStatus
          : response.data?.message == CARD_REISSUESTATUS_SUCCESS_MESSAGE
      ) {
        await viewManageCardStore.getCards({ forceRefresh: true, authorityId })
        await cardReissueStore.getCards({ forceRefresh: true, authorityId }) // fetch with current filter

        const newFilter =
          newCardStatus === CARD_STATUS_CODE.NOT_FOR_REISSUE
            ? CARD_STATUS_CODE.NOT_FOR_REISSUE
            : CARD_STATUS_CODE.AWAITING_REISSUE
        cardReissueStore.setSimpleFilter(newFilter)
        await cardReissueStore.getCards({ forceRefresh: true, authorityId }) // fetch with new filter
      } else {
        onClose()
        setToastData({
          id: 'card_reissue_failed',
          position: 'right-bottom',
          autoClose: 5000,
          hasCloseButton: false,
          title: content?.card_reissue_pendingstatus_message || 'card_reissue_pendingstatus_message',
          icon: 'Alert'
        })
      }
    } catch (e) {
      onClose()
      setToastData({
        id: 'card_reissue_exception',
        position: 'right-bottom',
        autoClose: 5000,
        hasCloseButton: false,
        title: content?.card_reissue_exception_message || 'card_reissue_exception_message',
        icon: 'Alert'
      })
      console.warn('Failed to update card state', e)
    }
  }

  return ReactDOM.createPortal(
    <AlertModal
      title={isRecentlyUnused ? content.manage_cards_reissue : content.manage_cards_dont_reissue}
      text={
        (isRecentlyUnused
          ? content.manage_cards_reissue_message_singular
          : content.manage_cards_dont_reissue_message_singular) || 'Are you sure?'
      }
      primaryAction={{
        text: content.manage_cards_yes,
        onClick: () => reissue(),
        isLoading: isUpdating
      }}
      secondaryAction={{
        text: content.manage_cards_no,
        onClick: onClose,
        appearance: 'tertiary'
      }}
      onDismiss={onClose}
      state='warning'
      visible
      size='sm'
    />,
    root
  )
}

export default ReissueCard
