import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import useContent from 'hooks/useContent'
import { BRAND_ARAL } from 'constants/brands'
import { Logo, LogoWrapper, Content, Message, MessageText, ButtonWrapper } from './LoginPage.styled'
import PublicLayout from 'components/layout/PublicLayout'
import bpLogo from 'assets/logos/bp-logo-colour.svg'
import aralLogo from 'assets/logos/aral-logo.svg'
import { getCIPLoginPath } from 'helpers/getForgeRockvalue'
import FLAGS from 'constants/launchDarklyFeatureFlags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Cookies from 'js-cookie'
import { getUserIdentityPlatform } from 'helpers/getUserIdentityPlatform'
import { Icon } from '@bp-digital/component-icon'
import getFallbackLanguage from 'helpers/getFallbackLanguage'
import { getAzureLoginPath } from 'helpers/getAzureADValue'
import { Identity_Platform } from 'constants/identityPlatforms'

/********************************************
Temporary warning text about the IDP switch
*********************************************/
const warningEmails = [
  { code: 'bp', email: 'accounts-team-ces@bp.com' },
  { code: 'aral', email: 'meinaral-ces@bp.com' }
]

const warningTexts = [
  {
    code: 'en',
    text: `Please note we are making Technical & Security improvements so you will notice a change to the login pages. You can still use the same credentials to login.
    Also note that email communications from the Fleet Portal will come from {{emailAddress}}.`
  },
  {
    code: 'nl',
    text: `Let op: we zijn bezig met technische en beveiligingsverbeteringen, je zult hierdoor een verandering zien op de inlogpagina's. De bestaande en bekende inloggegevens blijven onaangetast en kunnen nog steeds worden gebruikt.
    Houd er ook rekening mee dat e-mailcommunicatie van het Fleet portaal afkomstig zal zijn van {{emailAddress}}.`
  },
  {
    code: 'de',
    text: `Aufgrund technischer Optimierungen ändert sich das Aussehen sowie die Webadresse der Anmeldeseite zum Aral Kundenportal. Die vorhandenen und bekannten Login Details bleiben davon unberührt und können weiterhin verwendet werden.
    Sollten Sie E-Mails vom System erhalten, so ändert sich die Absenderadresse in {{emailAddress}}.`
  },
  {
    code: 'pl',
    text: `Pamiętaj, że wprowadzamy ulepszenia techniczne i bezpieczeństwa, więc zauważysz zmiany na stronach logowania. Nadal możesz użyć tych samych danych uwierzytelniających, aby się zalogować.
    Należy również pamiętać, że wiadomości e-mail z portalu floty będą pochodzić z {{emailAddress}}.`
  },
  {
    code: 'pt',
    text: `Devido a melhorias técnicas, a apresentação e o endereço web da página de registo no portal do cliente estão a sofrer alterações. Os detalhes de login existentes permanecem inalterados e podem continuar a ser usados.
    Caso receba emails do sistema, o endereço do remetente passará a ser de {{emailAddress}}.`
  }
]
/*********************************************/

const LoginPage = () => {
  const flags = useFlags()

  const isInternalUser = window.location.pathname.includes('/login/internal')
  if (isInternalUser) {
    if (!window.location.pathname.startsWith('/en/')) {
      history.pushState({}, '', '/en/login/internal')
    }
    window.localStorage.setItem('isInternal', 'true')
  } else {
    window.localStorage.removeItem('isInternal')
  }
  const userIdentityPlatform = getUserIdentityPlatform(window.localStorage.getItem('isInternal') ?? 'false')
  Cookies.set('bpf-idp', userIdentityPlatform, { secure: true })

  const content = useContent('login')

  const host = window.location.host || 'fleet.bp.com'
  if (host.includes('localhost')) Cookies.set('islocal', 'true')

  const brand = getBrandFromDomain()
  const displayBrand = brand === BRAND_ARAL ? brand.charAt(0).toUpperCase() + brand.slice(1) : brand

  const warningEmail = warningEmails.find(we => we.code.toUpperCase() === brand.toUpperCase())?.email || ''
  const language = getFallbackLanguage()
  let warningText = warningTexts.find(wt => wt.code === language)?.text.replace('{{emailAddress}}', warningEmail) || ''

  if (isInternalUser) {
    warningText = 'Internal User Login Portal'
  }

  async function url() {
    window.location.href = await getCIPLoginPath()
  }
  async function azure_URL() {
    window.location.href = await getAzureLoginPath()
  }
  let loginTitle = replaceVariablesInString(content?.landing_Welcome_Heading, { brand: displayBrand })
  let loginDetails = replaceVariablesInString(content?.landing_message_p3, { brand: displayBrand })
  if (isInternalUser) {
    loginTitle = 'Internal Users Login Portal'
    loginDetails = ''
  }

  return (
    <PublicLayout>
      {flags[FLAGS.ForgeRockEnabled] && (
        <Message>
          <Icon name='Alert' size='lg' state='neutral' />
          <MessageText>{warningText}</MessageText>
        </Message>
      )}
      <LogoWrapper>
        <Logo src={brand === BRAND_ARAL ? aralLogo : bpLogo} />
      </LogoWrapper>
      <Content>
        <Text size='lg'>{loginTitle}</Text>
        <Text>{loginDetails}</Text>
      </Content>
      <ButtonWrapper>
        {userIdentityPlatform == Identity_Platform.CIP ? (
          <Button
            onClick={async () => {
              await url()
            }}
            dataAttributes={{ 'data-testid': 'button-login', 'data-content-key': 'landing_login_button' }}
            size='lg'
          >
            {content?.landing_login_button}
          </Button>
        ) : (
          <Button
            onClick={async () => {
              await azure_URL()
            }}
            dataAttributes={{ 'data-testid': 'button-login', 'data-content-key': 'landing_login_button' }}
            size='lg'
          >
            {content?.landing_login_button}
          </Button>
        )}

        {isInternalUser && (
          <Button appearance='secondary' onClick={() => (window.location.href = '/')} size='lg'>
            Not An Internal User
          </Button>
        )}
      </ButtonWrapper>
    </PublicLayout>
  )
}

export default LoginPage
