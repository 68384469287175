import ReactDOM from 'react-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Modal } from '@bp-digital/component-modal'
import { Alert } from '@bp-digital/component-alert'
import { Timeline } from '@bp-digital/component-timeline'
import { Icon } from '@bp-digital/component-icon'
import { Text } from '@bp-digital/component-typography'
import { Wrapper, Subtitle, TimelineWrapper } from './CardStatusHistory.styled'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { useRootStore } from 'contexts/StoreContext'

const root = document.getElementById('root')

const fetchStatusHistory = async (cardId, authorityId) => {
  const accessLevel = 'A'
  const response = await axios({
    method: 'get',
    url: `/api/cards/cards/${cardId}/history`,
    params: {
      accessLevel,
      accessId: `${accessLevel}${authorityId}`,
      accessLevelCode: authorityId,
      userLevel: accessLevel
    }
  })

  return formatStatusHistory(response.data.cardStatus)
}

const formatStatusHistory = cardStatus => {
  if (!cardStatus) return
  const cardData = structuredClone(cardStatus)

  cardData.forEach(cardStatus => {
    cardStatus.statusHistory.forEach(statusHistory => {
      statusHistory.dateTime = `${statusHistory.dateTime.substr(11, 5)} ${statusHistory.dateTime.substr(
        8,
        2
      )}/${statusHistory.dateTime.substr(5, 2)}/${statusHistory.dateTime.substr(0, 4)}`
    })
  })

  return cardData
}

const CardStatusHistory = ({ onClose, content, cardId, cardSerialNumber }) => {
  const { userStore } = useRootStore()
  const authorityId = userStore.selectedAuthorityId
  const { data, isLoading, error } = useQuery('history' + cardId, () => fetchStatusHistory(cardId, authorityId))

  console.info(error, isLoading)

  return ReactDOM.createPortal(
    <Modal
      title={content?.manage_cards_view_card_history_title}
      primaryAction={{
        text: content?.manage_cards_full_details_buttons_close,
        onClick: onClose
      }}
      onDismiss={onClose}
      visible
      size='md'
    >
      <Subtitle>
        <Icon name='CvcCvv' size='md' />
        <Text size='lg'>
          {replaceVariablesInString(content?.manage_cards_view_card_history_sub_title || '', {
            cardNo: cardSerialNumber
          })}
        </Text>
      </Subtitle>
      {error ? (
        <Alert text='Something went wrong. Please close and try again.' state='error' iconPrefix />
      ) : (
        <Wrapper>
          <TimelineWrapper>
            {data &&
              data.map(({ cardSerialNumber, statusHistory }, index) => (
                <Timeline
                  key={index}
                  hasStartMarker={index === 0}
                  title={`${content?.manage_cards_card || 'Card'} ${cardSerialNumber}`}
                  items={statusHistory.map(item => ({
                    text: content?.[`advancedFilters_cardStatusId_${item.cardStatusId}`],
                    subtext: item.dateTime
                  }))}
                />
              ))}
          </TimelineWrapper>
        </Wrapper>
      )}
    </Modal>,
    root
  )
}

export default CardStatusHistory
