const animations = `
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
  transform: scale(1, 1);
  }

  50% {
  transform: scale(1.1, 1.1);
  }

  100% {
  transform: scale(1, 1);
  }
}
`

export default animations
