import { Icon } from '@bp-digital/component-icon'
import { CssVars } from '@bp-digital/theme'
import { LocationPin } from './MapPin.styled'

interface Props {
  color?: string
}

const MapPin = ({ color = CssVars.palette.state.neutral }: Props) => {
  return (
    <LocationPin $color={color}>
      <Icon name='Pin' size='xxl' />
    </LocationPin>
  )
}

export default MapPin
