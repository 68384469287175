import { Wrapper, Box } from './PublicLayout.styled'

const PublicLayout = ({ children }) => {
  return (
    <Wrapper>
      <Box>{children}</Box>
    </Wrapper>
  )
}

export default PublicLayout
