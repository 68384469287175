import { Table } from '@bp-digital/component-table'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import {
  Cell,
  Column,
  HeaderProps,
  IdType,
  Row,
  useExpanded,
  useFlexLayout,
  useMountedLayoutEffect,
  useResizeColumns,
  UseRowSelectInstanceProps,
  useSortBy,
  useTable
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import TableControls from 'src/components/dataDisplay/TableControls'
import TablePagination from 'src/components/dataDisplay/TablePagination'
import getPaginationSiblingCount from 'src/helpers/getPaginationSiblingCount'
import { customUseRowSelect, rowIsSelectable } from './hooks/customUseRowSelect'
import { HeaderCell } from './HeaderCell'
import { NoResults } from './NoResults'
import { ExpanderCell } from './ExpanderCell'
import { TableCheckbox } from './TableCheckbox'
import { Truncate } from './Truncate'
import MultiSelector from './MultiSelect'
import { TableAlert } from './Table.styled'
import { ErrorMessage } from './Error'
import './style.css'

// TODO: import from bp-digital/component-command-menu
interface MenuItem {
  id: string
  label: string
  disabled?: boolean
  enableOnSelection?: boolean
}

interface MultiSelectType {
  key?: string
  text: string
  count: number
  action: () => void
}

export interface TableAction {
  text: string
  iconName: string
  size: string
  enableOnSelection?: boolean
  disabled?: boolean
  items?: MenuItem[]
  onClick?: () => void
  dataAttributes?: {
    [P: string]: string
  }
  to?: string
}
interface Props<T extends object> {
  columns: Column<T>[]
  data: T[]
  /*
   * Subcomponent renderer on row expansion, passed original data values
   */
  renderDetail?: (
    row: Row<T>,
    /*
     * Used in subComponent once newly loaded subrow data comes in e.g InvoicesSummary
     */
    instanceToggleRowSelected: UseRowSelectInstanceProps<T>['toggleRowSelected']
  ) => JSX.Element
  pagination: {
    currentPage: number
    count: number
    disabled: boolean
    pageSize?: number | undefined
    onChange: (value: number) => void
    onSizeChange?: (value: number) => void | undefined
  }
  search?: {
    searchTerm: string
    onChange: (searchTerm: string) => void
    onSubmit: () => Promise<void>
    onClear: () => void
    placeholder: string
    inputAriaLabel: string
    clearAriaLabel: string
    submitAriaLabel: string
    minLength?: number | undefined
    tooltipMessage?: string | undefined
    hasBacklink?: boolean
  }
  actions?: TableAction[]
  alert?: JSX.Element
  showSelectAllCheckbox?: boolean
  disableRowSelection?: boolean
  expandSubRowOnSelect?: boolean
  /*
   * General click handler for an action e.g history.push route on click e.g AccessUserPage, CardsRestrictionsPage
   */
  handleRowClick?: (event: React.MouseEvent<HTMLElement>, row: T) => void
  /*
   * Server side sorting func in a useCallback,
   * used with react-table sorting logic to determine if arrow should be up, down, or neutral.
   * neutral should be when store.columnSorting values defaults to get initial fetch results
   */
  onSort?: (key: string, direction: boolean | undefined) => void
  /*
   * Set to true if rows[0].subRows are included e.g InvoicesStore & InvoicesPage
   */
  hasSubRows?: boolean
  /*
   * Set to true if api response returns a non 2xx status code
   */
  hasError?: boolean
  /*
   * Uses data-test-id='loading-row' with skeleton
   */
  isLoading?: boolean
  /*
   * Should be true when using controlled state e.g ChargingHistory, SelectDepots, false for Invoices with subrow selection on load
   */
  autoResetSelectedRows?: boolean
  hideCheckboxColumn?: boolean
  /*
   * Name of table used in data-testid name='transactions' -> data-testid='table-transactions'
   */
  name?: string
  /*
   * Text displayed when there are no results
   */
  noResultsMessage?: string
  /*
   * Callback for controlled row selection with IDs in sync with store
   */
  onSelectedRowsChange?: (rowIDs: Record<IdType<T>, boolean>) => void
  /*
   * Set with initially selected rowIDs or use onSelectedRowsChange to control with state
   */
  initiallySelectedRows?: Record<IdType<T>, boolean>
  /*
   * Options for select all checkbox to have options e.g Charging History
   * should be used with totalRecords
   */
  multiSelectOptions?: MultiSelectType[]
  /*
   * Number of all records received in api response under mobxStore.limits.totalRecords
   */
  totalRecords?: number
}

const emptyInitiallySelectedRows = {}

interface BaseRowData {
  key: string
  isSelectable?: boolean
}

export function ReactTable<T extends BaseRowData>({
  columns,
  data,
  pagination,
  search,
  actions,
  alert,
  handleRowClick,
  renderDetail,
  onSort,
  hasSubRows = renderDetail ? true : false,
  hasError,
  isLoading,
  hideCheckboxColumn = false,
  name,
  onSelectedRowsChange,
  autoResetSelectedRows = false,
  initiallySelectedRows = emptyInitiallySelectedRows as Record<IdType<T>, boolean>,
  multiSelectOptions = [],
  totalRecords = 0,
  noResultsMessage,
  showSelectAllCheckbox = true,
  disableRowSelection = false,
  expandSubRowOnSelect = true
}: Props<T>) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 90,
      maxWidth: 300
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds, sortBy },
    visibleColumns,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    toggleRowSelected,
    isAllRowsSelected
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: initiallySelectedRows
      },
      defaultColumn,
      /*
       * Defaults to client side sort if no onSort function
       */
      manualSortBy: Boolean(onSort),
      autoResetSelectedRows: Boolean(multiSelectOptions.length) || autoResetSelectedRows,
      autoResetSortBy: false,
      autoResetResize: false,
      autoResetExpanded: false,
      getRowId: row => row.key
    },
    useFlexLayout,
    useResizeColumns,
    useSticky,
    useSortBy,
    useExpanded,
    customUseRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...(hideCheckboxColumn
          ? []
          : [
              {
                id: 'selection',
                disableResizing: true,
                width: 'auto',
                minWidth: 0,
                sticky: 'left',
                Header: (props: React.PropsWithChildren<HeaderProps<T>>) => (
                  <MultiSelector
                    tableCheckboxProps={props.getToggleAllRowsSelectedProps}
                    selectOptions={multiSelectOptions}
                    selectedRowIds={Object.keys(initiallySelectedRows)}
                    totalRecords={totalRecords}
                    showSelectAllCheckbox={showSelectAllCheckbox}
                  />
                ),
                Cell: ({ row }: Cell<T>) => (
                  <TableCheckbox
                    data-testid='table-checkbox'
                    isSelectAllCheckbox={false}
                    isSelectable={!row.isSelected && disableRowSelection ? false : rowIsSelectable(row.original)}
                    expandSubRowOnSelect={() => expandSubRowOnSelect && hasSubRows && !row.isSelected && row.toggleRowExpanded(true)}
                    {...row.getToggleRowSelectedProps()}
                  />
                )
              }
            ]),
        ...columns,
        ...(renderDetail || hasSubRows
          ? [
              {
                Header: () => null,
                id: 'expander',
                disableResizing: true,
                width: 'auto',
                minWidth: 60,
                sticky: 'right',
                Cell: ({ row }: Cell<T>) =>
                  // Only show expander on parent rows
                  hasSubRows && row.depth < 1 ? <ExpanderCell<T> row={row} /> : null
              }
            ]
          : [])
      ])
    }
  )

  useMountedLayoutEffect(() => {
    onSelectedRowsChange?.(selectedRowIds)
  }, [onSelectedRowsChange, selectedRowIds])

  const tableProps = useMemo(
    () =>
      getTableProps({
        style: { pointerEvents: isLoading ? 'none' : undefined },
        className: 'react-table-useFlexLayout',
        'data-testid': `table-${name}`
      }),
    [getTableProps, isLoading, name]
  )

  const selectedRowIdArray = Object.keys(selectedRowIds)
  const tableActions = actions?.map(action => {
    if (action.enableOnSelection) {
      action.disabled = !selectedRowIdArray.length
    }
    if (action.items) {
      action.items = action.items.map(item => {
        if (item.enableOnSelection) {
          item.disabled = !selectedRowIdArray.length
        }

        return item
      })
    }
    return action
  })

  // Row checkbox selection when clicking outside the checkbox component but inside the containing cell
  const cellSelectClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, cellId: string, row?: Row<T>) => {
      if (cellId === 'selection') {
        e.stopPropagation()
        if (!showSelectAllCheckbox) return false

        if (row) {
          if (row.original.isSelectable === false) return
          // Expand sub component on select if subrows exist e.g Invoices
          if (!row.isSelected && hasSubRows) {
            row.toggleRowExpanded(true)
          }
          row.toggleRowSelected(!row.isSelected)
        } else if (multiSelectOptions.length === 0) {
          toggleAllRowsSelected() // Header row i.e select all
        }
      }
      // Expand on click behaviour
      if (cellId !== 'selection' && cellId !== 'expander') {
        row?.toggleRowExpanded()
      }
      return false
    },
    [hasSubRows, multiSelectOptions, toggleAllRowsSelected, showSelectAllCheckbox]
  )

  // Select all checkbox expands sub rows
  useEffect(() => {
    if (isAllRowsSelected && expandSubRowOnSelect) {
      hasSubRows && toggleAllRowsExpanded(true)
    }
  }, [isAllRowsSelected, toggleAllRowsExpanded.length, hasSubRows, toggleAllRowsExpanded, expandSubRowOnSelect])

  const renderRow = useCallback(
    ({ row, key, restRowProps, isEven, isSubRow = false }) => {
      return (
        <Table.Row
          key={key}
          {...restRowProps}
          onClick={e => handleRowClick?.(e, row.original)}
          data-testid={`${isLoading ? 'row-loading' : 'row-data'}`}
          className={`${!isSubRow && isEven ? 'isEven' : ''} ${isSubRow ? 'sub-row' : 'parent-row'}`}
          isClickable
          disableRowSelection={disableRowSelection && !row.isSelected}
        >
          {row.cells.map((cell: Cell<T>) => {
            const { key, ...restCellProps } = cell.getCellProps()
            const isString = typeof cell.value === 'string'
            const title = isString ? cell.value : undefined
            return (
              <Table.Cell
                {...restCellProps}
                key={key}
                title={title}
                onClick={e => cellSelectClick(e, cell.column.id, row)}
              >
                {isString ? <Truncate>{cell.render('Cell')}</Truncate> : cell.render('Cell')}
              </Table.Cell>
            )
          })}
        </Table.Row>
      )
    },
    [handleRowClick, cellSelectClick, isLoading, disableRowSelection]
  )

  // Use react-table sorting logic but with manual server side sort function provided
  useEffect(() => onSort?.(sortBy[0]?.id, sortBy[0]?.desc), [onSort, sortBy])

  return (
    <>
      {search && <TableControls search={search} actions={tableActions} selectedRows={selectedRowIdArray}/>}
      {alert && <TableAlert>{alert}</TableAlert>}
      <Table {...tableProps}>
        <Table.Head>
          {headerGroups.map(headerGroup => {
            const { key, ...restheaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restheaderGroupProps}>
                {headerGroup.headers.map((column, headerIndex) => {
                  const { key, ...restHeaderProps } = column.getHeaderProps({
                    style: { cursor: column.canSort ? 'pointer' : 'default' }
                  })
                  const { onClick: handleSortClick } = column.getSortByToggleProps()
                  const title = typeof column.Header === 'string' ? column.Header : undefined
                  return (
                    <Table.Header
                      {...restHeaderProps}
                      key={key}
                      title={title}
                      onClick={e => cellSelectClick(e, column.id) || handleSortClick?.(e)}
                      scope='col'
                      role='columnheader'
                    >
                      <HeaderCell
                        column={column}
                        headerIndex={headerIndex}
                        visibleColumnsLength={visibleColumns.length}
                      />
                    </Table.Header>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Head>

        {!isLoading && !hasError && rows.length === 0 && <NoResults message={noResultsMessage} />}

        {!isLoading && hasError && rows.length === 0 && <ErrorMessage />}

        <Table.Body {...getTableBodyProps()}>
          {rows
            // Do not render subrows with react-table as they render below the subcomponent
            // Manually render subRows from row.subRows.map(...)
            .filter(row => row.depth != 1)
            .map((row, idx) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Fragment key={`row-group-${key}`}>
                  {renderRow({ row, key, restRowProps, isEven: idx % 2 === 0 })}

                  {row.isExpanded && row.subRows
                    ? row.subRows.map((row: Row<T>) => {
                        prepareRow(row)
                        const { key, ...restRowProps } = row.getRowProps()
                        return renderRow({ row, key, restRowProps, isSubRow: true })
                      })
                    : null}

                  {renderDetail && row.isExpanded ? (
                    <Table.Row key={`EXPANDED_${key}`} className='detail-row'>
                      <Table.Cell colSpan={visibleColumns.length} style={{ padding: 0 }}>
                        {renderDetail(row, toggleRowSelected)}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Fragment>
              )
            })}
        </Table.Body>
      </Table>

      {pagination && (
        <TablePagination
          currentPage={pagination.currentPage}
          count={pagination.count || 1}
          siblingCount={getPaginationSiblingCount()}
          pageSize={pagination.pageSize}
          disabled={pagination.disabled}
          onChange={pagination.onChange}
          onSizeChange={pagination.onSizeChange}
        />
      )}
    </>
  )
}
