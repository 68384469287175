import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import GoogleMapReact from 'google-map-react'
import mapStyles from 'components/dataDisplay/Map/mapStyles'
import MapMarker from 'components/dataDisplay/Map/MapMarker'
import { ROUTE_CHARGE_POINT } from 'constants/routes'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import {
  MapContainer,
  ChargerDetailsContainer,
  SingleChargerContainer,
  ChargerDetails,
  SingleChargerViewWrapper
} from './SingleChargerView.styled.js'

const SingleChargerView = ({
  content,
  chargePointId,
  address,
  evseId,
  speed,
  mapCenter,
  siteId,
  cdrType,
  locale = 'en'
}) => {
  const defaultCenter = {
    lat: 41.27396379749638,
    lng: 45.240973003125376
  }
  const isPublicCDRType = content?.cpo_transactions_public.toLowerCase() === cdrType.toLowerCase()

  return (
    <SingleChargerViewWrapper>
      <SingleChargerContainer>
        <MapContainer>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyACfHLktkDCMANQyOksH_B66z9WZS7mWwQ',
              language: locale
            }}
            options={{
              styles: mapStyles
            }}
            defaultCenter={defaultCenter}
            center={mapCenter}
            defaultZoom={12}
          >
            <MapMarker
              lat={mapCenter?.lat || defaultCenter.lat}
              lng={mapCenter?.lng || defaultCenter.lng}
              marker={{ colour: 'blue' }}
            />
          </GoogleMapReact>
          <ChargerDetailsContainer data-testid='charger-session-details'>
            <Heading
              as='h4'
              dataAttributes={{
                'data-testid': 'session-detail-header',
                'data-content-key': 'cpo_charging_history_session_detail_header'
              }}
            >
              {content?.cpo_charging_history_session_detail_header || 'cpo_charging_history_session_detail_header'}
            </Heading>
            <ChargerDetails>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-address-label'
                }}
              >
                {content?.cpo_charging_history_address_label || 'cpo_charging_history_address_label'}
              </Text>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-address-value'
                }}
              >
                {address}
              </Text>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-evseid-label'
                }}
              >
                {content?.cpo_charging_history_evse_id_label || 'cpo_charging_history_evse_id_label'}
              </Text>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-evseid-value'
                }}
              >
                {evseId}
              </Text>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-speed-label'
                }}
              >
                {content?.cpo_charging_history_speed_label || 'cpo_charging_history_speed_label'}
              </Text>
              <Text
                as='p'
                dataAttributes={{
                  'data-testid': 'history-speed-value'
                }}
              >
                {`${speed} kW`}
              </Text>
              {!isPublicCDRType && (
                <>
                  <Text
                    as='p'
                    dataAttributes={{
                      'data-testid': 'history-chargerid-label'
                    }}
                  >
                    {content?.cpo_charging_history_charger_id_label || 'cpo_charging_history_charger_id_label'}
                  </Text>
                  <Text
                    as='p'
                    dataAttributes={{
                      'data-testid': 'history-chargerid-value'
                    }}
                  >
                    {chargePointId}
                  </Text>
                  <Button
                    to={`${ROUTE_CHARGE_POINT}?siteId=${siteId}&chargerId=${chargePointId}`}
                    dataAttributes={{ 'data-testid': 'charger-session-details-button' }}
                  >
                    {replaceVariablesInString(
                      content?.cpo_charging_history_charger_button || 'cpo_charging_history_charger_button',
                      {
                        chargerId: chargePointId
                      }
                    )}
                  </Button>
                </>
              )}
            </ChargerDetails>
          </ChargerDetailsContainer>
        </MapContainer>
      </SingleChargerContainer>
    </SingleChargerViewWrapper>
  )
}

export default SingleChargerView
