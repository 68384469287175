import { Badge } from '@bp-digital/component-badge'
import { getArrayWithoutDuplicates } from 'helpers/getArrayWithoutDuplicates'
import { getStateFromCardStatus } from 'helpers/getStateFromCardStatus'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import BaseDataStore from '../BaseDataStore'

// columns we must declare in request to get cards
// because UI/journeys beyond the columns shown in table
// require reading them, if not passed we don't get it
const requiredColumns = [
  'replaceCardOrderedOn',
  'replaceCardSerialNo',
  'cardStatusId',
  'authorityId',
  'cardSerialNumber',
  'costCenterName',
  'cardHolderName',
  'expiryDate',
  'internationalInd',
  'lastTransactionDate',
  'serviceCode',
  'vehicle',
  'cardEmbossName2',
  'odometer',
  'cardType',
  'bPMeLastUsed',
  'bPMeCardProvisioned',
  'bPMeCardDescription',
  'pinMethod',
  'replaceCardSerialNo',
  'replaceCardOrderedOn',
  'fullCardNumber'
]

class ViewManageCardStore extends BaseDataStore {
  columnSorting = ['cardSerialNumber', 'asc']
  defaultColumns = [
    'cardStatusId',
    'cardSerialNumber',
    'costCenterName',
    'cardHolderName',
    'expiryDate',
    'internationalInd',
    'lastTransactionDate',
    'serviceCode',
    'vehicle'
  ]

  constructor(root) {
    super(root, 'cards', 'cards-view')
  }

  setViewManageCardSearchTerm = searchTerm => {
    const dateRegex = /(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}/
    if (searchTerm && searchTerm.includes('0') && !dateRegex.test(searchTerm)) {
      // strip start and trailing 0's from search
      // to show more card numbers, API does not work with 0's...
      searchTerm = searchTerm.replace(/^0+|0+$/g, '')
    }

    this.setSearchTerm(searchTerm)
  }

  get rows() {
    const internationalContent = this.content?.advancedFilters_international_ind_Y
    const nationalContent = this.content?.advancedFilters_international_ind_N

    return this.results.map(card => {
      const status = this.content?.[`advancedFilters_cardStatusId_${card.cardStatusId}`] || card.cardStatus
      const state = getStateFromCardStatus(card.cardStatusId)
      const expiryDate = card.expiryDate ? `${card.expiryDate.substr(5, 2)}/${card.expiryDate.substr(2, 2)}` : ''

      let result = {
        type: 'MASTER',
        ...card,
        key: card.cardId,
        currentCardStatusId: card.cardStatusId,
        expiryDate: getUserFormattedDate(expiryDate, this.root.userStore.dateFormat),
        internationalInd: card.internationalInd === 'Y' ? internationalContent : nationalContent,
        cardStatusId: <Badge text={status} state={state} />,
        fuelAndCharge: card.fuelAndCharge === null ? '-' : card.fuelAndCharge
      }

      // Format last transaction date if it exists
      if (card.lastTransactionDate) {
        const lastTransactionDate = `${card.lastTransactionDate.substr(8, 2)}/${card.lastTransactionDate.substr(
          5,
          2
        )}/${card.lastTransactionDate.substr(0, 4)}`

        result.lastTransactionDate = getUserFormattedDate(lastTransactionDate, this.root.userStore.dateFormat)
      }

      // Format last used date if it exists
      if (card.bPMeLastUsed) {
        const bPMeLastUsed = `${card.bPMeLastUsed.substr(8, 2)}/${card.bPMeLastUsed.substr(
          5,
          2
        )}/${card.bPMeLastUsed.substr(0, 4)}`

        result.bPMeLastUsed = getUserFormattedDate(bPMeLastUsed, this.root.userStore.dateFormat)
      }

      return result
    })
  }

  get columns() {
    return (
      this.selectedColumns?.map(preference => {
        let contentKey = preference

        if (preference === 'cardStatus') {
          contentKey = 'cardStatusId' // hack for correct content
        }

        return {
          key: preference,
          name: this.content ? this.content[`columnHeading_${contentKey}`] : '...'
        }
      }) || [{ key: '...', name: '...' }]
    )
  }

  async getAvailableColumns(authorityId, removecolumn = false) {
    const params = {
      accessLevel: 'A',
      accessLevelCode: authorityId
    }

    let response = await super.getAvailableColumns({
      method: 'get',
      url: '/api/cards/cards/viewcols',
      availableColumnsKey: 'viewCardsHeader.viewCardsPreferenceColumns',
      extractNameFromPreferences: true,
      params
    })
    if(removecolumn)
      response = response.filter(column => column !== 'cardProfileName');
    return response
  }

  async getCards({
    authorityId = this.root.userStore.selectedAuthorityId,
    columnSorting = this.columnSorting,
    searchTerm = this.searchTerm,
    page = this.currentPage,
    limit = this.limits?.nextPageLimit || 15,
    forceRefresh
  }) {
    if (!columnSorting.length) {
      columnSorting = ['cardSerialNumber', 'asc']
    }

    const accessLevel = 'A'
    const params = {
      accessLevel,
      accessLevelCode: authorityId,
      accessId: `${accessLevel}${authorityId}`,
      activeFilters: [],
      sortBy: columnSorting[0],
      sortOrder: columnSorting[1],
      page,
      searchColumns: getArrayWithoutDuplicates([...this.selectedColumns, ...requiredColumns]),
      pageSize: limit,
      queries: [],
      statusGroupTypes: 'default',
      userLevel: accessLevel
    }

    if (searchTerm) params.searchBy = searchTerm

    await super.getData({
      method: 'get',
      url: '/api/cards/cards',
      params,
      totalRecordsKey: 'totalCards',
      responseKey: 'cards',
      page,
      searchTerm,
      forceRefresh
    })
  }
}

export default ViewManageCardStore
