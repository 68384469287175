import ReactDOM from 'react-dom'
import Skeleton from 'react-loading-skeleton'
import { Modal } from '@bp-digital/component-modal'
import { Alert } from '@bp-digital/component-alert'
import {
  TransactionDetailItem,
  Label,
  Value
} from 'src/pages/Transactions/TransactionsPage/components/SingleTransactionView/SingleTransactionView.styled'
import { Wrapper, Block, DetailHeader, DetailHeading } from './FullCardDetail.styled'
import { parseValue } from 'helpers/parseValue'
import { formatCardDetail } from '../../helpers'
import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRootStore } from 'src/contexts/StoreContext'


const root = document.getElementById('root')

const LoadingRow = () => (
  <TransactionDetailItem>
    <Label>
      <Skeleton />
    </Label>
    <Value>
      <Skeleton />
    </Value>
  </TransactionDetailItem>
)

const LoadingRows = () => (
  <>
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
  </>
)

const FullCardDetail = ({
  onClose,
  onEditDetail,
  content,
  cardNumber,
  cardStatus,
  internationalUse,
  lastUsed,
  cardDetails,
  isLoading,
  error
}) => {
  const {  userStore :{onefleetmigratedstatus}  } = useRootStore()
  const blockeditcarddetails = onefleetmigratedstatus > 1
  const flags = useFlags()
  const data = useMemo(
    () =>
      formatCardDetail(
        cardDetails,
        content,
        {
          cardStatus,
          lastUsed,
          internationalUse
        },
        flags
      ),
    [cardDetails, content, cardStatus, lastUsed, internationalUse, flags]
  )

  return ReactDOM.createPortal(
    <Modal
      title={`${content?.manage_cards_full_details_heading} ${cardNumber}`}
      primaryAction={{
        text: content?.manage_cards_full_details_buttons_close,
        onClick: onClose,
        iconName: 'RemoveLarge'
      }}
      secondaryAction={ blockeditcarddetails 
        ? undefined 
        :
          { appearance: 'tertiary',
          iconName: 'Edit',
          text: content?.manage_cards_full_details_buttons_edit_details,
          onClick: onEditDetail
          }}
      onDismiss={onClose}
      visible
      size='lg'
    >
      {error ? (
        <Alert text='Something went wrong. Please close and try again.' state='error' iconPrefix />
      ) : (
        <Wrapper>
          <Block>
            <DetailHeader>
              <DetailHeading>{content.manage_cards_full_details_section_accounts || '...'}</DetailHeading>
            </DetailHeader>
            {isLoading && <LoadingRows count={2} />}
            {data?.accountDetails?.map(item => (
              <TransactionDetailItem key={item.key || item.label}>
                <Label>{item.label}</Label>
                <Value>{parseValue(item.value)}</Value>
              </TransactionDetailItem>
            ))}
          </Block>
          <Block>
            <DetailHeader>
              <DetailHeading>{content.manage_cards_full_details_section_general || '...'}</DetailHeading>
            </DetailHeader>
            {isLoading && <LoadingRows count={4} />}
            {data?.general?.map(item => (
              <TransactionDetailItem key={item.key || item.label}>
                <Label>{item.label}</Label>
                <Value>{parseValue(item.value)}</Value>
              </TransactionDetailItem>
            ))}
          </Block>
          <Block>
            <DetailHeader>
              <DetailHeading>{content.manage_cards_full_details_section_restrictions || '...'}</DetailHeading>
            </DetailHeader>
            {isLoading && <LoadingRows count={2} />}
            {data?.restrictions?.map(item => (
              <TransactionDetailItem key={item.key || item.label}>
                <Label>{item.label}</Label>
                <Value>{parseValue(item.value)}</Value>
              </TransactionDetailItem>
            ))}
          </Block>
          <Block>
            <DetailHeader>
              <DetailHeading>{content.manage_cards_full_details_section_offers || '...'}</DetailHeading>
            </DetailHeader>
            {isLoading && <LoadingRows count={2} />}
            {data?.offers?.map(item => (
              <TransactionDetailItem key={item.key || item.label}>
                <Label>{item.label}</Label>
                <Value>{parseValue(item.value)}</Value>
              </TransactionDetailItem>
            ))}
          </Block>
          <Block>
            <DetailHeader>
              <DetailHeading>{content.manage_cards_full_details_section_card_details || '...'}</DetailHeading>
            </DetailHeader>
            {isLoading && <LoadingRows count={5} />}
            {data?.embossingDetails?.map(item => (
              <TransactionDetailItem key={item.key || item.label}>
                <Label>{item.label}</Label>
                <Value>{parseValue(item.value)}</Value>
              </TransactionDetailItem>
            ))}
          </Block>
        </Wrapper>
      )}
    </Modal>,
    root
  )
}

export default FullCardDetail
