import { useState } from 'react'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import SpendingLimitForm from 'components/cards/Restrictions/SpendingLimitForm'
import { Wrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { getViolationId } from 'helpers/restrictions'
import { Spacer } from 'styles/common.styled'
import { MODE } from 'constants/restrictions'
import { Heading } from '@bp-digital/component-typography'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { RESTRICTION_TYPES } from 'constants/restrictions'

const SpendingLimit = ({
  authorityId,
  content,
  data,
  currency,
  mode = MODE.EDIT,
  onBack,
  onNext,
  defaultValues,
  title
}) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { profileId, profileName, spendingLimitRestriction = {} } = data || {}
  const { daily, weekly, monthly, rollingLimit, acceptSpendingLimitViolation, alertSpendingLimitViolation } =
    spendingLimitRestriction || {}

  const [violationId, setViolationId] = useState(
    getViolationId(acceptSpendingLimitViolation, alertSpendingLimitViolation)
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const { handleSubmit, reset, control, watch, setValue } = useForm({
    defaultValues: {
      restrictionDetail: {
        saveProfile: true,
        profileId: Number(profileId) || '',
        profileName,
        restrictionTypes: [RESTRICTION_TYPES.SPENDING_LIMIT],
        existingProfile: true,
        spendingLimit: {
          restrictions: {
            daily: {
              dailySpendLimit: daily?.dailySpendLimit,
              dailyTxnLimit: daily?.dailyTxnLimit
            },
            weekly: {
              weeklySpendLimit: weekly?.weeklySpendLimit,
              weeklyTxnLimit: weekly?.weeklyTxnLimit
            },
            monthly: {
              monthlySpendLimit: monthly?.monthlySpendLimit,
              monthlyTxnLimit: monthly?.monthlyTxnLimit
            },
            rollingLimit: {
              rollingDaysCount: rollingLimit?.rollingDaysCount,
              rollingDaysSpendLimit: rollingLimit?.rollingDaysSpendLimit
            }
          },
          ...(defaultValues ? defaultValues : {})
        }
      }
    },
    mode: 'onBlur'
  })

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      setIsSubmitting(false)
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`)
    },
    error => {
      setIsSubmitting(false)
      setError(
        content?.restriction_profiles_update_notification_error_singular ||
          'restriction_profiles_update_notification_error_singular'
      )
      console.error(error)
    },
    authorityId
  )

  const onSubmit = formData => {
    formData.restrictionDetail.spendingLimit.acceptSpendingLimitViolation = violationId === 3 ? '0' : '1'
    formData.restrictionDetail.spendingLimit.alertSpendingLimitViolation = violationId === 1 ? '0' : '1'

    if (mode === MODE.EDIT) {
      setIsSubmitting(true)
      submitForm(formData)
      reset(formData)
    } else {
      onNext(formData)
    }
  }

  const weeklySpendLimit = watch('restrictionDetail.spendingLimit.restrictions.weekly.weeklySpendLimit')
  const monthlySpendLimit = watch('restrictionDetail.spendingLimit.restrictions.monthly.monthlySpendLimit')

  return (
    <Wrapper>
      {title && <Heading as='h3'>{title}</Heading>}
      <ViolationsSelector content={content} onChange={setViolationId} defaultSelectedId={violationId} />
      <Spacer />
      <SpendingLimitForm
        onSubmit={handleSubmit(onSubmit)}
        content={content}
        currency={currency}
        control={control}
        isSubmitting={isSubmitting}
        daily={daily || defaultValues?.restrictions.daily}
        weekly={weekly || defaultValues?.restrictions.weekly}
        monthly={monthly || defaultValues?.restrictions.monthly}
        weeklySpendLimit={weeklySpendLimit}
        monthlySpendLimit={monthlySpendLimit}
        rollingLimit={rollingLimit || defaultValues?.restrictions.rollingLimit}
        setValue={setValue}
        mode={mode}
        error={error}
        onBack={onBack}
      />
    </Wrapper>
  )
}

export default SpendingLimit
