import { END_TIME } from 'constants/restrictions'

export const formatTime = time => {
  if (/[0-9]{2}:[0-9]{2}/.test(time)) return time

  time = Number(time?.trim())

  if (time === 24) return END_TIME
  if (time < 10) return `0${time}:00`
  return `${time}:00`
}
