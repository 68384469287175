import useContent from 'hooks/useContent'
import styled from 'styled-components'

const StyledNoResults = styled.tbody`
  tr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
`

export const NoResults = ({ message }: { message?: string }) => {
  const content = useContent('table')

  return (
    <StyledNoResults data-testid='no-results'>
      <tr>
        <td>
          <div>{message ?? content?.no_results ?? 'No results found'}</div>
        </td>
      </tr>
    </StyledNoResults>
  )
}
