export const PIN_METHOD = {
  PIN_BY_POST: 0,
  CHOOSE_OWN_PIN: 1,
  CUSTOMER_WIDE_SELECTED_PIN: 2,
  CUSTOMER_WIDE_DEFAULT_PIN: 3
}

export const PIN_OPTION = {
  DRIVER_PIN: 'DRIVER_PIN',
  CHOOSE_OWN_PIN: 'CHOOSE_OWN_PIN',
  RANDOM_BY_POST: 'RANDOM_BY_POST',
  CUSTOMER_WIDE_PIN: 'CUSTOMER_WIDE_PIN',
  INVALID: 'INVALID'
}
