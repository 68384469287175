import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { StateMessage } from '@bp-digital/component-state-message'
import SpendingLimitField from 'components/cards/Restrictions/SpendingLimitField'
import { ButtonWrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { Spacer } from 'styles/common.styled'
import useContent from 'hooks/useContent'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import { MODE } from 'constants/restrictions'

const SpendingLimitForm = ({
  onSubmit,
  control,
  isSubmitting,
  daily,
  weekly,
  monthly,
  weeklySpendLimit,
  monthlySpendLimit,
  rollingLimit,
  setValue,
  dailyId = 'restrictionDetail.spendingLimit.restrictions.daily',
  weeklyId = 'restrictionDetail.spendingLimit.restrictions.weekly',
  monthlyId = 'restrictionDetail.spendingLimit.restrictions.monthly',
  rollingLimitId = 'restrictionDetail.spendingLimit.restrictions.rollingLimit',
  mode = MODE.EDIT,
  onBack,
  error,
  currency
}) => {
  const content = useContent('cards-restrictions')
  const validationMessages = {
    requiredValue: content?.validation_required || 'validation_required',
    negativeValue: content?.validation_positive_number || 'validation_positive_number',
    maxValue: content?.restriction_valiation_max || 'restriction_valiation_max',
    minValue: content?.restriction_valiation_min || 'restriction_valiation_min'
  }

  return (
    <form onSubmit={onSubmit}>
      <Text size='lg'>{content?.restriction_spending_limit_set || 'restriction_spending_limit_set'}</Text>
      <SpendingLimitField
        control={control}
        defaultChecked={!!daily?.dailySpendLimit}
        validationMessages={validationMessages}
        checkBoxLabel={
          content?.restriction_profiles_spending_limit_daily || 'restriction_profiles_spending_limit_daily'
        }
        validation={{
          positive: v => (v < 0 ? validationMessages.negativeValue : null),
          range: v =>
            v > 999999 || (weeklySpendLimit && Number(v) > Number(weeklySpendLimit))
              ? replaceVariablesInString(validationMessages.maxValue, {
                  max: weeklySpendLimit || 999999
                })
              : null
        }}
        clearValues={() => setValue(`${dailyId}.dailySpendLimit`, '')}
        popoverText={content?.restriction_spending_limit_daily_tooltip || 'restriction_spending_limit_daily_tooltip'}
        id={`${dailyId}.daily`}
        maxSpendLabel={
          `${content?.edit_spending_limit_max_spending}${currency && ` (${currency})`}` ||
          `Max spend${currency && ` (${currency})`}`
        }
      />
      <SpendingLimitField
        control={control}
        defaultChecked={!!weekly?.weeklySpendLimit}
        validationMessages={validationMessages}
        checkBoxLabel={
          content?.restriction_profiles_spending_limit_weekly || 'restriction_profiles_spending_limit_weekly'
        }
        validation={{
          positive: v => (v < 0 ? validationMessages.negativeValue : null),
          range: v =>
            v > 999999 || (monthlySpendLimit && Number(v) > Number(monthlySpendLimit))
              ? replaceVariablesInString(validationMessages.maxValue, {
                  max: monthlySpendLimit || 999999
                })
              : null
        }}
        clearValues={() => setValue(`${weeklyId}.weeklySpendLimit`, '')}
        popoverText={content?.restriction_spending_limit_weekly_tooltip || 'restriction_spending_limit_weekly_tooltip'}
        id={`${weeklyId}.weekly`}
        maxSpendLabel={
          `${content?.edit_spending_limit_max_spending}${currency && ` (${currency})`}` ||
          `Max spend${currency && ` (${currency})`}`
        }
      />
      <SpendingLimitField
        control={control}
        defaultChecked={!!monthly?.monthlySpendLimit}
        validationMessages={validationMessages}
        checkBoxLabel={
          content?.restriction_profiles_spending_limit_monthly || 'restriction_profiles_spending_limit_monthly'
        }
        validation={{
          positive: v => (v < 0 ? validationMessages.negativeValue : null),
          range: v =>
            v > 999999
              ? replaceVariablesInString(validationMessages.maxValue, {
                  max: 999999
                })
              : null
        }}
        clearValues={() => setValue(`${monthlyId}.monthlySpendLimit`, '')}
        popoverText={
          content?.restriction_spending_limit_monthly_tooltip || 'restriction_spending_limit_monthly_tooltip'
        }
        id={`${monthlyId}.monthly`}
        maxSpendLabel={
          `${content?.edit_spending_limit_max_spending}${currency && ` (${currency})`}` ||
          `Max spend${currency && ` (${currency})`}`
        }
      />
      <SpendingLimitField
        control={control}
        validationMessages={validationMessages}
        defaultChecked={!!rollingLimit?.rollingDaysCount || !!rollingLimit?.rollingDaysSpendLimit}
        id={`${rollingLimitId}.rollingDays`}
        checkBoxLabel={
          content?.restriction_profiles_spending_limit_rolling || 'restriction_profiles_spending_limit_rolling'
        }
        popoverText={
          content?.restriction_spending_limit_rolling_tooltip || 'restriction_spending_limit_rolling_tooltip'
        }
        validation={{
          positive: v => (v < 0 ? validationMessages.negativeValue : null),
          range: v =>
            v > 999999
              ? replaceVariablesInString(validationMessages.maxValue, {
                  max: 999999
                })
              : null
        }}
        numberOfDaysValidation={{
          positive: v => (v < 0 ? validationMessages.negativeValue : null),
          range: v =>
            v < 2
              ? replaceVariablesInString(validationMessages.minValue, {
                  min: 2
                })
              : null || v > 30
              ? replaceVariablesInString(validationMessages.maxValue, {
                  max: 30
                })
              : null
        }}
        clearValues={() => {
          setValue(`${rollingLimitId}.rollingDaysSpendLimit`, '')
          setValue(`${rollingLimitId}.rollingDaysCount`, '')
        }}
        numberOfDaysLabel={content?.restriction_spending_limit_no_days || 'restriction_spending_limit_no_days'}
        showNumberOfDaysField={true}
        maxSpendLabel={
          `${content?.edit_spending_limit_max_spending}${currency && ` (${currency})`}` ||
          `Max spend${currency && ` (${currency})`}`
        }
      />
      {error && (
        <>
          <Spacer />
          <StateMessage iconName='Alert' state='danger' text={error} />
        </>
      )}
      {mode === MODE.EDIT && (
        <ButtonWrapper>
          <Button
            type='submit'
            isLoading={isSubmitting}
            disabled={isSubmitting}
            dataAttributes={{ 'data-content-key': 'restriction_profiles_rename_profile_primary_button' }}
          >
            {content?.restriction_profiles_rename_profile_primary_button}
          </Button>
        </ButtonWrapper>
      )}
      {mode === MODE.CREATE && (
        <ButtonWrapper>
          <Button
            onClick={onBack}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            appearance='tertiary'
            dataAttributes={{ 'data-content-key': 'previous_step' }}
          >
            {content?.previous_step}
          </Button>
          <Button
            type='submit'
            isLoading={isSubmitting}
            disabled={isSubmitting}
            dataAttributes={{ 'data-content-key': 'next_step' }}
          >
            {content?.next_step}
          </Button>
        </ButtonWrapper>
      )}
    </form>
  )
}

export default SpendingLimitForm
