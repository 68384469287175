import styled from 'styled-components'

export const CardDetailWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px dashed var(--color-grey-regular-300);
  flex-direction: column;
  padding: 16px;
`

export const CardDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`

export const CardDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-top: 16px;
`

export const CardDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 8px;
  grid-template-rows: min-content min-content;

  &:last-child {
    display: flex;
    justify-content: ${props => (props.$align === 'left' ? 'left' : 'center')};
  }
`

export const CardDetailsActions = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0;
`

export const Label = styled.p`
  color: var(--color-grey-regular-500);
  margin: 0;
`

export const Value = styled.p`
  margin: 0;
`

export const TransactionMapContainer = styled.div`
  height: 350px;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`
