import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const TwoColumnLayout = styled.div`
  width: 100%;
  display: grid;
  padding: 16px 0;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media only screen and ${breakpoints.device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`
