import ReactDOM from 'react-dom'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GlobalStyles } from '@bp-digital/theme'
import { RootStoreProvider } from 'src/contexts/StoreContext'
import { refreshSession } from 'helpers/refreshSession'
import Routes from './routes'
import GlobalStyle from './styles/GlobalStyle'
import './styles/reset.css'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { getLaunchDarklyClientId } from 'helpers/getLaunchDarklyCliendId'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

export const queryClient = new QueryClient()

// Auto-refreshes JWT if it's expired on an action
createAuthRefreshInterceptor(axios, refreshSession, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true
})

const App = () => {
  if (!Cookies.get('bpf-cid')) {
    Cookies.set('bpf-cid', uuidv4(), { secure: true })
  }

  return (
    <GlobalStyles>
      <GlobalStyle />
      <RootStoreProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          <Routes />
        </QueryClientProvider>
      </RootStoreProvider>
    </GlobalStyles>
  )
}

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: getLaunchDarklyClientId()
  })

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  )
}

renderApp()
