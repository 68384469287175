import styled from 'styled-components'

export const HierarchyWrapper = styled.div`
  padding-top: 0.25em;
  padding-bottom: 0.5em;

  h3 {
    font-size: 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 10px 15px;
  }
`

export const HierarchyList = styled.ul`
  width: 100%;

  top: 38px;
  background-color: #fff;
`

export const HierarchyListItem = styled.li`
  padding: 0px;

  display: flex;
  gap: 10px;

  border-top: 1px var(--color-grey-regular-100) solid;

  button {
    flex: 0 0 100px;
    padding: 5px 2px;
    margin: 1px 0;
    height: auto;
  }
`

export const HierarchyLabel = styled.div`
  display: flex;
  align-items: center;
`

export const HierarchyWarning = styled.div`
  padding: 20px;
  text-align: center;
`

export const ChipContainer = styled.div`
  > div {
    width: fit-content;
    span {
      font-size: 0.85rem;
      text-transform: none;
    }
  }
`
