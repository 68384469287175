import { Wrapper, Heading } from './Title.styled'

interface TitleProps {
  children: string
}

const Title = ({ children }: TitleProps) => (
  <Wrapper>
    <Heading>{children}</Heading>
  </Wrapper>
)

export default Title
