import { Controller } from 'react-hook-form'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'

const YES = 'Y'
const NO = 'N'

const CardOffers = ({ control, content, isLoading, authorityDetails, showAustrianTax, showTargetNeutral }) => {
  return (
    <>
      {showAustrianTax && (
        <Controller
          name='offers.austriantax'
          control={control}
          rules={{
            required: content?.validation_required || 'validation_required'
          }}
          render={({ field: { name, value, onChange } }) => (
            <RadioButtonGroup
              id={name}
              options={[
                { id: YES, label: content?.manage_cards_yes },
                {
                  id: NO,
                  label: content?.manage_cards_no
                }
              ]}
              label={content?.manage_cards_full_details_labels_austrian_road_tax}
              onChange={onChange}
              defaultSelectedId={value}
              disabled={isLoading || !authorityDetails?.columnContext?.austrianRoadTax}
              horizontal
            />
          )}
        />
      )}
      {showTargetNeutral && (
        <Controller
          name='offers.targetNeutral'
          control={control}
          rules={{
            required: content?.validation_required || 'validation_required'
          }}
          render={({ field: { name, value, onChange } }) => (
            <RadioButtonGroup
              id={name}
              options={[
                { id: YES, label: content?.manage_cards_yes },
                {
                  id: NO,
                  label: content?.manage_cards_no
                }
              ]}
              label={content?.manage_cards_edit_full_details_offers_target_neutral_label}
              onChange={onChange}
              defaultSelectedId={value}
              disabled={isLoading}
              horizontal
            />
          )}
        />
      )}
    </>
  )
}

export default CardOffers
