import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useRootStore } from 'contexts/StoreContext'
import { Icon } from '@bp-digital/component-icon'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { ROUTE_ACCOUNT, ROUTE_AUDIT, ROUTE_LOGOUT } from 'src/constants/routes'
import { ROUTE_DASHBOARD } from 'src/constants/routes'
import AuthoritySelector from '../AuthoritySelector'
import UserMenu, { UserMenuItemProps } from '../../dataDisplay/UserMenu'
import {
  Wrapper,
  Logo,
  IconWrapper,
  IconLink,
  DesktopAuthoritySelectionWrapper,
  MobileAuthoritySelectionWrapper,
  PageOverlay
} from './TopNav.styled'
import bpLogo from 'src/assets/logos/bp-logo.svg'
import aralLogo from 'src/assets/logos/aral-logo.svg'
import useContent from 'hooks/useContent'

/* eslint-disable  @typescript-eslint/no-explicit-any */
declare const window: any

interface TopNavProps {
  hasUnreadNotification?: boolean
  toggleMobileMenu: () => void
  hasOpenMobileMenu?: boolean
  brand?: string
  name?: string
}

const TopNav = ({ toggleMobileMenu, hasOpenMobileMenu = false, brand = 'BP', name = '' }: TopNavProps) => {
  const { userStore } = useRootStore()
  const content = useContent('session')
  const [isLogoutModalShowing, setIsLogoutModalShowing] = useState(false)
  const [isPageOverlayVisible, setIsPageOverlayVisible] = useState(false)
  const history = useHistory()
  const logout = async () => {
    window.isLoggingOut = true
    history.push(ROUTE_LOGOUT)
  }

  const userMenuItems: UserMenuItemProps[] = [
    {
      iconName: 'User',
      text: content?.account_profile || 'Account profile',
      to: ROUTE_ACCOUNT,
      dataAttributes: {
        'data-testid': 'link-account-profile'
      }
    },
    {
      iconName: 'Lock',
      text: content?.logout_button || 'Logout',
      onClick: () => setIsLogoutModalShowing(true),
      dataAttributes: {
        'data-testid': 'link-logout'
      }
    }
  ]

  // we limit for now access to the audit page
  if (userStore.isAdmin) {
    userMenuItems.splice(1, 0, {
      iconName: 'List',
      text: 'Audit Logs',
      to: ROUTE_AUDIT,
      dataAttributes: {
        'data-testid': 'link-audit'
      }
    })
  }

  return (
    <>
      <PageOverlay $isVisible={isPageOverlayVisible} />
      <Wrapper>
        <Link to={ROUTE_DASHBOARD} data-testid='mobile-menu-logo'>
          <Logo src={brand.toUpperCase() === 'BP' ? bpLogo : aralLogo} />
        </Link>

        <DesktopAuthoritySelectionWrapper>
          <AuthoritySelector />
        </DesktopAuthoritySelectionWrapper>
        <AlertModal
          state='warning'
          title={content?.logout_heading}
          text={content?.logout_body}
          visible={isLogoutModalShowing}
          primaryAction={{
            text: content?.logout_button,
            onClick: async () => {
              await logout()
            },
            dataAttributes: {
              'data-testid': 'button-logout'
            }
          }}
          onDismiss={() => setIsLogoutModalShowing(false)}
          size='sm'
        />

        <IconWrapper>
          <UserMenu
            title='user-menu'
            name={name}
            items={userMenuItems}
            onOpen={() => setIsPageOverlayVisible(true)}
            onClose={() => setIsPageOverlayVisible(false)}
          />
          <IconLink onClick={toggleMobileMenu} $isMobileOnly data-testid='topnav-toggle-menu'>
            <Icon name={hasOpenMobileMenu ? 'RemoveLarge' : 'Hamburger'} size='xl' state='neutral' />
          </IconLink>
        </IconWrapper>
      </Wrapper>
      <MobileAuthoritySelectionWrapper>
        <AuthoritySelector />
      </MobileAuthoritySelectionWrapper>
    </>
  )
}

export default TopNav
