/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useContent from 'hooks/useContent'
import { useHistory, useLocation } from 'react-router'
import { SteppedProgress } from '@bp-digital/component-stepped-progress'
import { Heading } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import CardTypes from '../../../components/common/CardTypes'
import { InputControlWrapper } from '../../common/CardOrder.styled'
import { getPoolCardType, isUserEV } from 'pages/Cards/CardOrderPage/helpers'
import { GUIDED_CARD_ORDER_STEPS as Steps } from '../../../constants/constants'
import { ButtonWrapper, Spacer } from 'styles/common.styled'
import { ROUTE_CARDS_ORDER } from 'constants/routes'

const CardDetails = () => {
  const content = useContent('cards-order')
  const {
    state: { formData: previousFormData, authorityDetails = {} }
  } = useLocation()
  const history = useHistory()

  const { handleSubmit, unregister, setValue, getValues, control, watch } = useForm({
    mode: 'onBlur'
  })

  const EVUser = isUserEV(authorityDetails)
  const { cardTypes = [] } = authorityDetails
  const poolCardType = getPoolCardType(authorityDetails)

  // Card Type
  const cardTypeId = watch('cardDetails.cardTypeId')

  useEffect(() => {
    setValue(
      'cardDetails.cardTypeId',
      EVUser && poolCardType?.length ? poolCardType?.cardTypeId : cardTypes?.[0]?.cardTypeId
    )
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async formData => {
    history.push(Steps.step2, { state: { formData: { ...previousFormData, ...formData }, authorityDetails } })
  }

  const previousStep = () => {
    history.push(ROUTE_CARDS_ORDER)
  }

  const items = [
    content?.summary_section_2_title,
    content?.summary_section_3_title,
    content?.summary_section_4_title,
    content?.summary_section_5_title,
    content?.summary_section_6_title,
    content?.summary_title
  ]

  return (
    <InnerPageWrapper>
      <SteppedProgress items={items} selectedIndex={0} />

      <Spacer size='lg' />

      <Heading as='h1' size='h3' dataAttributes={{ 'data-xontent-key': 'card_details_title' }}>
        {content?.card_details_title}
      </Heading>

      {content?.summary_card_type_label}
      {content?.card_details_card_type_modal_button_text}

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Spacer size='lg' />
          <InputControlWrapper>
            {
              <Controller
                name='cardDetails.cardTypeId'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CardTypes cardTypes={cardTypes} cardTypeId={cardTypeId} {...field} content={content} />
                )}
              />
            }
          </InputControlWrapper>

          <Spacer size='xxl2' />

          <ButtonWrapper>
            <Button type='button' onClick={previousStep} dataAttributes={{ 'data-content-key': 'previous_step' }}>
              {content?.previous_step}
            </Button>

            <Button type='submit' dataAttributes={{ 'data-content-key': 'next_step' }}>
              {content?.next_step}
            </Button>
          </ButtonWrapper>
        </>
      </form>
    </InnerPageWrapper>
  )
}

export default CardDetails
