import axios from 'axios'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import PageHeader from 'src/components/layout/PageHeader'
import { ROUTE_ACCESS_ADMINISTRATION_USER, ROUTE_ADMIN } from 'src/constants/routes'
import GridLayout from 'src/components/layout/GridLayout'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { AlertWrapper } from './ViewUserPage.styled'
import AccountDetails from 'src/components/account/AccountDetails'
import UserAccess from './components/UserAccess'
import UserOverview from './components/UserOverview'
import { Alert } from '@bp-digital/component-alert'
import getCountryCodeList from 'helpers/getCountryCodeList'
import useContent from 'src/hooks/useContent'
import useApiGetUserDetails from 'hooks/api/useApiGetUserDetails'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { useRootStore } from 'contexts/StoreContext'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { Redirect, useHistory } from 'react-router-dom'

const ViewUserPage = () => {
  const params = useParams()
  const userId = parseInt(params.userId)
  const history = useHistory()
  const queryClient = useQueryClient()
  const [showRevokeUserConfirmModal, setShowRevokeUserConfirmModal] = useState(false)
  const [editAccessFormSubmitting, setEditAccessFormSubmitting] = useState(false)
  const [revokeAccessBtnLoading, setRevokeAccessBtnLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [hasResentInvite, setHasResentInvite] = useState(false)
  const { accessAdminUserStore, userStore } = useRootStore()
  const { isLoading: isUserLoading, data: user } = useApiGetUserDetails(userId, { staleTime: 120000 })
  const { user: loggedInUser, selectedAuthorityId } = userStore
  const [isFormValid, setIsFormValid] = useState(false)
  const content = useContent('access-users')

  const [alert, setAlert] = useState({
    visible: false,
    text: '',
    state: ''
  })
  const userRole = userStore?.user?.role[0]

  useEffect(() => {
    if (!loggedInUser) userStore.getUserDetails()
  }, [loggedInUser, userStore])

  const sanitiseAuthorityPayloadData = input => {
    const output = input.map(({ authorityId, roleId }) => ({
      authorityId,
      roleId,
      category: [],
      isCustom: false
    }))

    return output
  }

  const handleSave = async ({ added = [], deleted = [], updated = [] } = {}) => {
    setEditAccessFormSubmitting(true)

    try {
      const result = await axios({
        method: 'post',
        url: `/api/users/users/${userId ?? -1}`,
        data: {
          groupAdded: [],
          groupRemoved: [],
          groupEdited: [],
          parentAdded: [],
          parentEdited: [],
          parentRemoved: [],
          authoritiesAdded: sanitiseAuthorityPayloadData(added),
          authoritiesEdited: sanitiseAuthorityPayloadData(updated),
          authoritiesRemoved: deleted,
          uId: userId,
          user: {
            basicDetails: userDetails
              ? {
                  // null values gives a 400, set null values to empty string
                  firstName: encodeURIComponent(user.userDetails.firstName),
                  lastName: encodeURIComponent(user.userDetails.lastName),
                  mobileNumberIsdCode: user.userDetails.mobileNumberIsdCode || '',
                  mobileNumber: user.userDetails.mobileNumber || '',
                  landlineNumberIsdCode: user.userDetails.landlineNumberIsdCode || '',
                  landlineNumber: user.userDetails.landlineNumber || '',
                  jobTitle: encodeURIComponent(user.userDetails.jobTitle) || '',
                  ...userDetails
                }
              : null,
            preferences: null,
            contactVerified: false
          }
        }
      })

      if (result.status === 200) {
        accessAdminUserStore.reset()
        queryClient.invalidateQueries('access/user', { userId })
        queryClient.invalidateQueries('access/user-authorities')
        setAlert({
          visible: true,
          text: replaceVariablesInString(content?.view_edit_user_success_message, {
            userName: `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
          }),
          state: 'success'
        })
        setEditAccessFormSubmitting(false)
      }
    } catch (e) {
      console.warn(e)
      setAlert({
        visible: true,
        text: content?.error_default_message,
        state: 'error'
      })
      setEditAccessFormSubmitting(false)
    }
  }

  const handleValidateAccess = async () => {
    setShowRevokeUserConfirmModal(true)
  }

  const handleResendInvite = async e => {
    e.preventDefault()
    try {
      const result = await accessAdminUserStore.resendInvite(userId, userStore.selectedAuthorityId)

      if (result.status === 200) {
        setHasResentInvite(true)
        setAlert({
          visible: true,
          text: content?.add_user_view_user_resent_invite || 'add_user_view_user_resent_invite',
          state: 'success'
        })
      }
    } catch (error) {
      console.error(error)
      setAlert({
        visible: true,
        text: content?.error_default_message,
        state: 'error'
      })
    }
  }

  const handleRevokeAccess = async () => {
    setRevokeAccessBtnLoading(true)
    try {
      const result = await axios({
        method: 'post',
        url: `/api/users/users/${userId}/removeaccess`,
        data: {
          uIdBlock: userId
        }
      })
      if (result.status === 200) {
        setShowRevokeUserConfirmModal(false)
        accessAdminUserStore.reset()
        history.push(ROUTE_ACCESS_ADMINISTRATION_USER)
        setAlert({
          visible: true,
          text: replaceVariablesInString(content?.add_user_approve_user_successMessage, {
            name: `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
          }),
          state: 'success'
        })
      }
    } catch (e) {
      console.warn(e)
      setAlert({
        visible: true,
        text: content?.error_default_message,
        state: 'error'
      })
    }
    setRevokeAccessBtnLoading(false)
    setShowRevokeUserConfirmModal(false)
  }

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={isUserLoading ? '...' : `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`}
        subtitle={
          isUserLoading
            ? '...'
            : content?.[`status_${user?.userDetails?.status.toLowerCase().replace(' ', '_')}`] ||
              user?.userDetails?.status
        }
        breadcrumbs={[
          { to: ROUTE_ACCESS_ADMINISTRATION_USER, title: 'User administration', ariaLabel: 'User administration' },
          { to: `/access/users/${userId}`, title: 'View / Edit user', ariaLabel: 'View / Edit user' }
        ]}
        brand={userStore.brand}
      />
      <AlertModal
        state='warning'
        visible={showRevokeUserConfirmModal}
        onDismiss={() => setShowRevokeUserConfirmModal(false)}
        hasCloseButton={false}
        primaryAction={{
          text: content?.add_user_revoke_user_revoke,
          onClick: handleRevokeAccess,
          disabled: revokeAccessBtnLoading,
          isLoading: revokeAccessBtnLoading,
          dataAttributes: {
            'data-testid': 'button-block-user'
          }
        }}
        secondaryAction={{
          text: content?.add_user_revoke_user_cancel,
          onClick: () => setShowRevokeUserConfirmModal(false),
          dataAttributes: {
            'data-testid': 'button-block-user-cancel'
          }
        }}
        title={content?.add_user_adminMenu_revokeUser}
        text={`${replaceVariablesInString(content?.revoke_rights_warning_string1, {
          userName: `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
        })} ${content?.revoke_rights_warning_string2}`}
      />
      <InnerPageWrapper>
        <AlertWrapper $isVisible={alert.visible}>
          <Alert
            text={alert.text}
            visible={alert.visible}
            state={alert.state}
            iconPrefix
            onClose={() => setAlert({ ...alert, visible: false })}
          />
        </AlertWrapper>
        <GridLayout>
          <UserOverview
            overviewText={content?.add_user_view_user_overview || 'add_user_view_user_overview'}
            linkExpiredText={content?.add_user_view_user_link_expired || 'add_user_view_user_link_expired'}
            resendInviteText={content?.add_user_view_user_resend_invite || 'add_user_view_user_resend_invite'}
            lastLoginText={content?.columnHeading_lastLogin}
            groupsText={content?.add_user_groups}
            parentsText={content?.add_user_parents}
            authoritiesText={content?.add_user_permission_authorities}
            lastLogin={user?.userDetails?.lastLogin}
            accessCount={user?.accessAreaCount}
            revokeAccessBtnText={content?.add_user_revoke_user_revoke}
            onValidateAccess={handleValidateAccess}
            revokeAccessBtnLoading={revokeAccessBtnLoading}
            userStatus={user?.userDetails?.status}
            hasResentInvite={hasResentInvite}
            onResendInvite={e => handleResendInvite(e)}
          />
          <AccountDetails
            title={content?.add_user_user_details_title}
            showUserName={false}
            firstNameLabel={content?.add_user_name}
            lastNameLabel={content?.add_user_last_name}
            jobTitleLabel={content?.add_user_job_title}
            emailLabel={content?.add_user_email}
            mobileNumberLabel={content?.add_user_mobile_number}
            landlineNumberLabel={content?.add_user_landline}
            user={user?.userDetails}
            countryList={getCountryCodeList}
            content={content}
            setUserDetails={changes => {
              const newUserDetails = Object.assign({}, ...changes)
              setUserDetails({ ...userDetails, ...newUserDetails })
            }}
            onErrorsChanged={setIsFormValid}
            button={{
              text: content?.add_user_permissions_save || 'Save',
              iconName: 'Save',
              onClick: handleSave,
              disabled: !isFormValid | (userDetails === null) | editAccessFormSubmitting,
              isLoading: editAccessFormSubmitting,
              dataAttributes: {
                'data-testid': 'button-save-details'
              }
            }}
          />
          <UserAccess
            title={content?.add_user_view_user_edit_access || 'Edit Access'}
            authorityId={selectedAuthorityId}
            saveText={content?.add_user_permissions_save || 'Save'}
            authoritiesText={content?.add_user_authorities}
            userId={userId}
            userRole={userRole}
            user={user}
            content={content}
            onSave={data => handleSave(data)}
            isSubmitting={editAccessFormSubmitting}
          />
        </GridLayout>
      </InnerPageWrapper>
    </>
  )
}

export default observer(ViewUserPage)
