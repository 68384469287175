import { Controller } from 'react-hook-form'
import { InputWrapper } from '../CardFields.styled'
import { TextField } from '@bp-digital/component-text-field'
import { validateNonValidCharacters } from '../../../CardOrderPage/helpers'

const CardEmbossingLines = ({ control, content, isLoading, disableLineTwo }) => {
  return (
    <InputWrapper>
      <Controller
        name='cardEmbossing.line1'
        control={control}
        render={({ field: { value } }) => (
          <TextField
            label={
              content?.manage_cards_edit_full_details_card_embossing_line_1 ||
              'manage_cards_edit_full_details_card_embossing_line_1'
            }
            readOnly
            value={value}
            textTransform='uppercase'
            disabled={isLoading}
          />
        )}
      />
      <Controller
        name='cardEmbossing.line2'
        control={control}
        rules={{
          pattern: {
            value: validateNonValidCharacters,
            message: content?.manage_cards_edit_card_embossing_town_city_validation
          }
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextField
              label={
                content?.manage_cards_edit_full_details_card_embossing_line_2 ||
                'manage_cards_edit_full_details_card_embossing_line_2'
              }
              textChangeHandler={onChange}
              value={value}
              disabled={isLoading || disableLineTwo}
              textTransform='uppercase'
              maxLength={27}
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )
        }}
      />
      <Controller
        name='cardEmbossing.line3'
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            label={
              content?.manage_cards_edit_full_details_card_embossing_line_3 ||
              'manage_cards_edit_full_details_card_embossing_line_3'
            }
            textChangeHandler={onChange}
            value={value}
            disabled={isLoading}
            readOnly
          />
        )}
      />
    </InputWrapper>
  )
}

export default CardEmbossingLines
