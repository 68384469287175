import { FILTER_IDS } from './filterIds'
import { INSIGHTS_DATES } from './insightsDates'

export const PAYLOAD_DATE_RANGES = {
  [FILTER_IDS.CURRENT_MONTH]: {
    usageDateFrom: INSIGHTS_DATES.FIRST_DAY_OF_CURRENT_MONTH,
    usageDateTo: INSIGHTS_DATES.LAST_DAY_OF_CURRENT_MONTH
  },
  [FILTER_IDS.LAST_MONTH]: {
    usageDateFrom: INSIGHTS_DATES.FIRST_DAY_OF_LAST_MONTH,
    usageDateTo: INSIGHTS_DATES.LAST_DAY_OF_LAST_MONTH
  },
  [FILTER_IDS.LAST_QUARTER]: {
    usageDateFrom: INSIGHTS_DATES.FIRST_DAY_OF_LAST_QUARTER,
    usageDateTo: INSIGHTS_DATES.LAST_DAY_OF_LAST_QUARTER
  }
}
