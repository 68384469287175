import { CssVars } from '@bp-digital/theme'
import React from 'react'
import styled from 'styled-components'

const CheckboxInput = styled.input`
  display: none;

  &:checked {
    + span {
      background-color: white;
      border: 1px solid ${CssVars.color.grey.dark800};

      &:after {
        width: 4px;
        height: 8px;
        border: solid ${CssVars.color.grey.dark800};
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        transform-origin: center;
        margin-top: -1px;
      }
    }
  }

  &:disabled {
    + span {
      cursor: not-allowed;
      background-color: ${CssVars.color.grey.regular200};
      border: solid 1px ${CssVars.color.grey.regular200};
    }
  }
`

const CheckboxIcon = styled.span`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 18px;
  width: 18px;
  border: 1px solid ${CssVars.color.grey.light600};

  :hover {
    border: 1px solid ${CssVars.color.grey.dark800};
  }

  &:after {
    content: '';
  }
`
const Label = styled.label`
  &:focus {
    outline: none;
    span {
      border: solid 1px ${CssVars.palette.controls.focus};
    }
  }
`

interface CheckboxProps {
  title?: string | undefined
  checked?: boolean | undefined
  isSelectable?: boolean
  isSelectAllCheckbox: boolean
  expandSubRowOnSelect?: () => void
  'data-testid'?: string | undefined
  // react-table method
  onChange?: ((e: React.ChangeEvent<Element>) => void) | undefined
}

export const TableCheckbox = ({
  expandSubRowOnSelect,
  isSelectAllCheckbox,
  checked,
  isSelectable,
  onChange,
  title,
  ...rest
}: CheckboxProps) => {
  const handleToggleState = (e: React.KeyboardEvent | React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isSelectable === false) return
    expandSubRowOnSelect?.()
    onChange?.({
      ...e,
      target: {
        ...e.target,
        // https://github.com/tannerlinsley/react-table/blob/8ec25cabccf915991ec00cf67bfb26a83aa0c0b2/src/plugin-hooks/useRowSelect.js
        // e.target.checked is used to control table checkbox
        checked: !checked
      }
    } as unknown as React.ChangeEvent)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (isSelectable === false) return
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault()
      handleToggleState(e)
    }
  }

  return (
    <Label {...rest} tabIndex={0} onClick={handleToggleState} onKeyDown={handleKeyDown}>
      <CheckboxInput
        aria-label={title}
        type='checkbox'
        checked={isSelectable || isSelectAllCheckbox ? checked : false}
        disabled={isSelectable === false}
        role='checkbox'
        data-selected={checked}
        // Unnecessary but satisfies prop type errors
        onClick={handleToggleState}
        onChange={handleToggleState}
      />
      <CheckboxIcon />
    </Label>
  )
}
