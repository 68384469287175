import { useState } from 'react'

import { useRootStore } from 'contexts/StoreContext'

import { TextField } from '@bp-digital/component-text-field'
import { Button } from '@bp-digital/component-button'
import AuditChip from './../AuditChip/AuditChip'

import {
  HierarchyWrapper,
  HierarchyList,
  HierarchyListItem,
  HierarchyLabel,
  HierarchyWarning,
  ChipContainer
} from './HierarchyFilter.styled'

const HierarchyFilter = props => {
  const { userStore } = useRootStore()

  const [filter, setFilter] = useState('')

  const filteredHierarchy = JSON.parse(JSON.stringify(userStore.hierarchy))

  const parentCount = userStore.hierarchy.parents.length
  const authorityCount = userStore.hierarchy.authorities.length

  // filtering as per the filter value - except for selection
  filteredHierarchy.parents = filteredHierarchy.parents.filter(
    ({ parentName, parentId }) =>
      parentName.toLowerCase().includes(filter.toLowerCase()) || parentId.toLowerCase().includes(filter.toLowerCase())
  )
  filteredHierarchy.authorities = filteredHierarchy.authorities.filter(
    ({ customerName, authorityId }) =>
      customerName.toLowerCase().includes(filter.toLowerCase()) ||
      authorityId.toLowerCase().includes(filter.toLowerCase())
  )

  // sort by name and then by Id's
  filteredHierarchy.parents.sort((a, b) => {
    if (a.parentName < b.parentName) return -1
    if (a.parentName > b.parentName) return 1
    if (a.accessLevelCode < b.accessLevelCode) return -1
    return 1
  })
  filteredHierarchy.authorities.sort((a, b) => {
    if (a.customerName < b.customerName) return -1
    if (a.customerName > b.customerName) return 1
    if (a.accessLevelCode < b.accessLevelCode) return -1
    return 1
  })

  const searchLimit = 20
  const includeSearch = userStore.hierarchy.parents.length + userStore.hierarchy.authorities.length > searchLimit

  const handleSelection = hierarchyFilterValue => {
    let filters = props.filters
    const hierarchyFilter = filters.find(fltr => fltr.filterBy == 'hierarchy') || {
      filterBy: 'hierarchy',
      filterCriteria: []
    }
    if (
      !hierarchyFilterValue ||
      (hierarchyFilter.filterCriteria.length && hierarchyFilter.filterCriteria[0] == hierarchyFilterValue)
    ) {
      hierarchyFilter.filterCriteria = []
    } else {
      hierarchyFilter.filterCriteria = [hierarchyFilterValue]
    }
    filters = filters.filter(fltr => fltr.filterBy != 'hierarchy')
    filters.push(hierarchyFilter)
    props.setFilters(filters)
  }

  const hierarchyFilterValue = props.filters.find(fltr => fltr.filterBy == 'hierarchy')
    ? props.filters.find(fltr => fltr.filterBy == 'hierarchy').filterCriteria[0]
    : ''

  return (
    <>
      {includeSearch && hierarchyFilterValue && hierarchyFilterValue.length > 0 && (
        <ChipContainer>
          <AuditChip
            filterKey={'hierarchy'}
            value={[hierarchyFilterValue]}
            onClick={() => handleSelection(hierarchyFilterValue)}
          />
        </ChipContainer>
      )}

      {includeSearch && (
        <>
          <TextField
            placeholder={'Search'}
            value={filter}
            textChangeHandler={newVal => {
              setFilter(newVal)
            }}
          />
        </>
      )}

      {filteredHierarchy.parents.length + filteredHierarchy.authorities.length > searchLimit && (
        <HierarchyWarning>Too many items to display - please use the search</HierarchyWarning>
      )}

      {filteredHierarchy.parents.length + filteredHierarchy.authorities.length <= searchLimit &&
        filteredHierarchy.parents.length > 0 && (
          <HierarchyWrapper>
            <h3>
              {filteredHierarchy.parents.length > 1 ? 'Parents' : 'Parent'}
              {filteredHierarchy.parents.length != parentCount ? ` (${filteredHierarchy.parents.length}/` : ' ('}
              {parentCount})
            </h3>
            <HierarchyList>
              {filteredHierarchy.parents.map(parent => (
                <HierarchyListItem key={parent.parentId}>
                  <Button
                    key={`${parent.parentId}Btn`}
                    onClick={() =>
                      handleSelection(hierarchyFilterValue !== `P${parent.parentId}` ? `P${parent.parentId}` : '')
                    }
                    appearance={hierarchyFilterValue === `P${parent.parentId}` ? 'primary' : 'secondary'}
                    borderRadius='default'
                  >
                    {parent.parentId}
                  </Button>
                  <HierarchyLabel>{parent.parentName}</HierarchyLabel>
                </HierarchyListItem>
              ))}
            </HierarchyList>
          </HierarchyWrapper>
        )}

      {filteredHierarchy.parents.length + filteredHierarchy.authorities.length <= searchLimit && (
        <HierarchyWrapper>
          {
            <h3>
              {'Authorities'}
              {filteredHierarchy.authorities.length != authorityCount
                ? ` (${filteredHierarchy.authorities.length}/`
                : ' ('}
              {authorityCount})
            </h3>
          }
          <HierarchyList>
            {filteredHierarchy.authorities.map(auth => (
              <HierarchyListItem key={auth.authorityId}>
                <Button
                  key={`${auth.authorityId}Btn`}
                  onClick={() =>
                    handleSelection(hierarchyFilterValue !== `A${auth.authorityId}` ? `A${auth.authorityId}` : '')
                  }
                  appearance={hierarchyFilterValue === `A${auth.authorityId}` ? 'primary' : 'secondary'}
                  borderRadius='default'
                >
                  {auth.authorityId}
                </Button>
                <HierarchyLabel>{auth.customerName}</HierarchyLabel>
              </HierarchyListItem>
            ))}
          </HierarchyList>
        </HierarchyWrapper>
      )}
    </>
  )
}

export default HierarchyFilter
