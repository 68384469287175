const formatSiteLocations = (locations, content) => {
  return locations.map((site, index) => ({
    index,
    country: site.countryCode.toLowerCase(),
    countryName: site.countryName,
    region: site.areaId ? site.areaName : content?.restriction_site_all_region,
    site:
      site.siteLocation && site.siteLocation.siteName ? site.siteLocation.siteName : content?.restriction_site_all_site,
    allow: site.isAllow,
    selectedCountry: {
      countryCode: site.countryCode.toLowerCase(),
      countryName: site.countryName,
      countryId: site.countryId
    },
    selectedRegion: {
      areaId: site.areaId || 0,
      region: site.areaId ? site.areaName : content?.restriction_site_all_region
    },
    selectedSite: {
      siteTypeId: site.siteLocation ? site.siteLocation.siteTypeId : '',
      siteGroupTypeId: site.siteLocation ? site.siteLocation.siteGroupIds : '',
      globalSiteId: site.siteLocation ? site.siteLocation.globalSiteId : 0,
      siteName: site.siteLocation ? site.siteLocation.siteName : content?.restriction_site_all_site
    }
  }))
}

export default formatSiteLocations
