import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY_PREFIX = 'cards/site-groups'

type paramType = string | null

const queryFn = async (countryCode: paramType) => {
  const response = await axios({
    method: 'get',
    url: `/api/sites/sites/groups/${countryCode}`
  })

  return response.data.siteGroups
}

const useApiGetSiteGroups = (
  countryCode: paramType,
  region: paramType,
  customerId: paramType,
  options: { enabled?: boolean }
) => {
  return useQuery(QUERY_KEY_PREFIX + countryCode, () => queryFn(countryCode), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetSiteGroups
