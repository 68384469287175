import { Controller } from 'react-hook-form'
import { LabelWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { TextField } from '@bp-digital/component-text-field'
import { Icon } from '@bp-digital/component-icon'
import {
  validateRequiredVehicleRegistration,
  validateRequiredDriverName,
  validateNonValidCharacters
} from '../../../CardOrderPage/helpers'

const CardVehicleRegistration = ({ control, content, cardTypeId, driverName, isLoading, setValue }) => {
  return (
    <Controller
      name='cardDetails.vehicleregistration'
      control={control}
      rules={{
        required:
          (validateRequiredVehicleRegistration({ cardTypeId, driverName }) &&
            (content?.manage_card_details_vehicle_registration_validation_required ||
              'manage_card_details_vehicle_registration_validation_required')) ||
          (validateRequiredDriverName({ cardTypeId, driverName }) &&
            (content?.manage_card_details_drivers_name_validation_required ||
              'manage_card_details_drivers_name_validation_required')),
        pattern: {
          value: validateNonValidCharacters,
          message: content?.manage_card_details_vehicledriver_validation
        }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextField
          label={
            <LabelWrapper>
              <Text dataAttributes={{ 'data-content-key': 'fullDetails_customerCard_vehicleRegistrationNumber' }}>
                {content?.fullDetails_customerCard_vehicleRegistrationNumber}
              </Text>

              <Popover
                appearance='dark'
                text={content?.manage_cards_edit_full_details_card_embossing_vehicle_registration_tooltip}
                position='top'
                dataAttributes={{
                  ['data-content-key']: 'manage_cards_edit_full_details_card_embossing_vehicle_registration_tooltip'
                }}
              >
                <Icon name='Info' appearance='dark' size='md' state='neutral' />
              </Popover>
            </LabelWrapper>
          }
          textChangeHandler={text => {
            onChange(text)
            setValue('cardEmbossing.line3', text)
          }}
          textTransform='uppercase'
          value={value}
          maxLength={14}
          disabled={isLoading}
          placeholder={
            content?.manage_cards_edit_full_details_card_embossing_vehicle_registration_placeholder ||
            'manage_cards_edit_full_details_card_embossing_vehicle_registration_placeholder'
          }
          error={!!error?.message}
          errorMessage={error?.message}
        />
      )}
    />
  )
}

export default CardVehicleRegistration
