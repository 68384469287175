import { useEffect, useState } from 'react'
import useApiGetSiteCountries from 'hooks/api/useApiGetSiteCountries'
import useApiGetSites from 'hooks/api/useApiGetSites'
import useApiGetSiteGroups from 'hooks/api/useApiGetSiteGroups'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import useContent from 'hooks/useContent'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Heading, Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { Icon } from '@bp-digital/component-icon'
import { Popover } from '@bp-digital/component-popover'
import {
  ButtonWrapper,
  TextPopoverWrapper,
  Wrapper,
  TileWrapper
} from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { Spacer } from 'styles/common.styled'
import { TileSelector } from '@bp-digital/component-tile-selector'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { getViolationId } from 'helpers/restrictions'
import SelectLocationForm from './components/SelectLocationForm'
import RestrictionList from './components/RestrictionList'
import getSiteGroupIds from './helpers/getSiteGroupIds'
import getSiteTypes from './helpers/getSiteTypes'
import formatSiteLocations from './helpers/formatSiteLocations'
import buildRequest from './helpers/buildRequest'
import checkFEPLimit from './helpers/checkFEPLimit'
import countryNameToVernacularName from './helpers/countryNameToVernacularName'
import { StateMessage } from '@bp-digital/component-state-message'
import { MODE } from 'constants/restrictions'
import Skeleton from 'react-loading-skeleton'

const STEPS = {
  SITE_TYPES: 1,
  LOCATIONS: 2
}

const SiteLocations = ({ authorityId, content, data, mode = MODE.EDIT, onBack, onNext, defaultValues, title }) => {
  const queryClient = useQueryClient()
  const translatedCountries = useContent('countries')
  const history = useHistory()
  const {
    profileId,
    profileName,
    sitelocationRestrictions = {},
    opuCode,
    customerNumber,
    countryCode,
    region,
    customerId
  } = data || {}
  const { data: siteCountries, isSuccess: siteCountriesSuccess } = useApiGetSiteCountries()
  const { data: siteGroups } = useApiGetSiteGroups(countryCode, region, customerId, {
    enabled: !!countryCode || !!region || !!customerId
  })
  const {
    allowedSiteGroupIds,
    deniedSiteGroupIds,
    acceptSitesViolation,
    alertSitesViolation,
    siteLocations,
    isAllowNotListed
  } = sitelocationRestrictions || {}

  const [violationId, setViolationId] = useState(getViolationId(acceptSitesViolation, alertSitesViolation))
  const [allowNotListed, setAllowNotListed] = useState(isAllowNotListed || false)
  const [siteTypes, setSiteTypes] = useState([])
  const [activeStep, setActiveStep] = useState(STEPS.SITE_TYPES)
  const [locationData, setLocationData] = useState({
    distinctRegions: [],
    sites: []
  })

  const [updatedValues, setUpdatedValues] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [restrictionList, setRestrictionList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [countries, setCountries] = useState([])

  useEffect(() => {
    if (siteCountriesSuccess && siteCountries?.length && Object?.keys(translatedCountries)?.length) {
      setCountries(countryNameToVernacularName(siteCountries, translatedCountries))
    }
  }, [siteCountriesSuccess, siteCountries, translatedCountries])

  useEffect(() => {
    if (mode === MODE.EDIT && siteLocations && allowedSiteGroupIds) {
      setSiteTypes(getSiteTypes(allowedSiteGroupIds, deniedSiteGroupIds, content))
      setRestrictionList(formatSiteLocations(siteLocations, content))
    } else if (siteGroups) {
      setSiteTypes(
        siteGroups.map(site => ({
          siteGroupId: site.siteGroupId,
          siteGroupDesc: content
            ? content[`restriction_site_group_${site.siteGroupId}`]
            : `restriction_site_group_${site.siteGroupId}`,
          value: false
        }))
      )
    }

    if (defaultValues) {
      setViolationId(defaultValues.violationId)
      setAllowNotListed(defaultValues.allowNotListed)
      setRestrictionList(defaultValues.restrictionList)
      setSiteTypes(defaultValues.siteTypes)
    }
  }, [siteLocations, allowedSiteGroupIds, deniedSiteGroupIds, mode, content, siteGroups, defaultValues])

  const { mutate: getSiteLocations, isLoading: dropDownDataLoading } = useApiGetSites(
    response => {
      setLocationData(response)
    },
    error => console.error(error)
  )

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      setIsSubmitting(false)
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`)
    },
    error => {
      setIsSubmitting(false)
      setError(
        content?.restriction_profiles_update_notification_error_singular ||
          'restriction_profiles_update_notification_error_singular'
      )
      console.error(error)
    },
    authorityId
  )

  const fetchLocationData = (countryId, regionId) => {
    const payload = {
      siteGroupIds: getSiteGroupIds(siteTypes),
      countryId: Number(countryId),
      offeringId: [],
      opuCode: opuCode,
      customerNumber: customerNumber,
      rebateAgreementId: '',
      ...(regionId &&
        regionId !== 0 && {
          areaIds: Number(regionId)
        })
    }
    getSiteLocations(payload)
  }

  const handleAdd = data => {
    const { selectedCountry, selectedRegion, selectedSite, isAllow } = data

    if (checkFEPLimit(selectedSite, restrictionList)) {
      setError(content?.restriction_site_fep_limit_reached || 'restriction_site_fep_limit_reached')
      return
    } else if (error) {
      setError(null)
    }

    const alreadyExists =
      restrictionList &&
      restrictionList.find(
        l =>
          l.country === selectedCountry.countryCode.toLowerCase() &&
          l.region === (selectedRegion.region || content?.restriction_site_all_region) &&
          l.site === (selectedSite.siteName || content?.restriction_site_all_site)
      )

    if (alreadyExists) {
      setError(content?.restriction_profiles_update_notification_error_singular || 'restriction_site_duplicate')
    } else {
      const newEntry = {
        index: restrictionList ? restrictionList.length : 0,
        country: selectedCountry.countryCode.toLowerCase(),
        countryName: selectedCountry.countryName,
        region: selectedRegion.region,
        site: selectedSite.siteName,
        allow: isAllow === 1,
        selectedCountry: selectedCountry,
        selectedRegion: selectedRegion,
        selectedSite: selectedSite
      }
      restrictionList ? setRestrictionList([...restrictionList, newEntry]) : setRestrictionList([newEntry])
    }
  }

  const handleUpdate = data => {
    const { selectedCountry, selectedRegion, selectedSite, isAllow, editIndex } = data

    if (checkFEPLimit(selectedSite, restrictionList)) {
      setError(content?.restriction_site_fep_limit_reached || 'restriction_site_fep_limit_reached')
      return
    } else if (error) {
      setError(null)
    }

    const alreadyExists =
      restrictionList &&
      restrictionList.find(
        l =>
          l.country === selectedCountry.countryCode.toLowerCase() &&
          l.region === (selectedRegion.region || content?.restriction_site_all_region) &&
          l.site === (selectedSite.siteName || content?.restriction_site_all_site) &&
          l.index !== editIndex
      )

    if (alreadyExists) {
      setError(content?.restriction_profiles_update_notification_error_singular || 'restriction_site_duplicate')
    } else {
      const newEntry = {
        index: editIndex,
        country: selectedCountry.countryCode.toLowerCase(),
        countryName: selectedCountry.countryName,
        region: selectedRegion.region,
        site: selectedSite.siteName,
        allow: isAllow === 1,
        selectedCountry: selectedCountry,
        selectedRegion: selectedRegion,
        selectedSite: selectedSite
      }
      const newList = restrictionList.map(l => (l.index === editIndex ? newEntry : l))
      setRestrictionList(newList)
      setIsEditing(false)
    }
  }

  const handleEdit = site => {
    setIsEditing(true)
    fetchLocationData(site.selectedCountry.countryId, parseInt(site.selectedRegion.areaId, 10))
    setUpdatedValues({
      editIndex: site.index,
      country: site.selectedCountry.countryId,
      region: parseInt(site.selectedRegion.areaId, 10),
      site: site.selectedSite.globalSiteId,
      isAllow: site.allow ? 1 : 0,
      selectedCountry: site.selectedCountry,
      selectedRegion: site.selectedRegion,
      selectedSite: site.selectedSite
    })
  }

  const handleRemove = site => {
    const updatedList = restrictionList.filter(
      l =>
        !(
          l.country === site.country &&
          l.countryName === site.countryName &&
          l.region === site.region &&
          l.site === site.site
        )
    )
    setRestrictionList(updatedList)
  }

  const handleSiteTypeChange = (siteGroupId, value) => {
    const updateSiteType = siteTypes.map(s => (s.siteGroupId === siteGroupId ? { ...s, value } : s))
    setSiteTypes(updateSiteType)
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const payload = buildRequest({ profileId, profileName, siteTypes, restrictionList, violationId, allowNotListed })
    submitForm(payload)
  }
  return (
    <Wrapper>
      {title && <Heading as='h3'>{title}</Heading>}
      {activeStep === STEPS.SITE_TYPES && (
        <>
          <ViolationsSelector content={content} onChange={setViolationId} defaultSelectedId={violationId} />

          <Spacer size='xxl3' />
          <TextPopoverWrapper>
            <Text size='lg'>{content?.restriction_site_select_site_type || 'restriction_site_select_site_type'}</Text>
            <Popover
              appearance='dark'
              text={content?.restriction_site_select_site_type_tooltip || 'restriction_site_select_site_type_tooltip'}
              position='top'
            >
              <Icon name='Info' appearance='dark' size='md' state='neutral' />
            </Popover>
          </TextPopoverWrapper>
          <Spacer />
          <TileWrapper>
            {siteTypes.map(item => (
              <TileSelector
                key={item.siteGroupId}
                id={item.siteGroupId}
                text={item.siteGroupDesc}
                defaultChecked={item.value}
                onChange={value => handleSiteTypeChange(item.siteGroupId, value)}
              />
            ))}
            {siteTypes.length === 0 &&
              Array(5)
                .fill()
                .map((item, index) => <Skeleton key={index} height={140} width={140} />)}
          </TileWrapper>
          <ButtonWrapper>
            {mode === MODE.CREATE && (
              <Button onClick={onBack} appearance='tertiary'>
                {content?.previous_step}
              </Button>
            )}
            <Button onClick={() => setActiveStep(STEPS.LOCATIONS)} disabled={getSiteGroupIds(siteTypes).length === 0}>
              {content?.next_step}
            </Button>
          </ButtonWrapper>
        </>
      )}
      {activeStep === STEPS.LOCATIONS && (
        <>
          <Spacer />
          <SelectLocationForm
            content={content}
            onSubmit={data => handleAdd(data)}
            onUpdate={data => handleUpdate(data)}
            isEditing={isEditing}
            countries={countries}
            countriesSuccess={siteCountriesSuccess}
            updatedValues={updatedValues}
            setUpdatedValues={setUpdatedValues}
            locationData={locationData}
            dropDownDataLoading={dropDownDataLoading}
            fetchLocationData={fetchLocationData}
          />
          <Spacer />
          <RestrictionList
            restrictionList={countryNameToVernacularName(restrictionList, translatedCountries)}
            content={content}
            onEdit={handleEdit}
            onRemove={handleRemove}
          />
          <Spacer size='xxl3' />
          <RadioButtonGroup
            id='isAllowNotListed'
            horizontal
            label={content?.restriction_products_not_selected}
            options={[
              {
                label: content?.restriction_products_allow_transcation || 'restriction_products_allow_transcation',
                id: true
              },
              {
                label:
                  content?.restriction_products_not_allow_transcation || 'restriction_products_not_allow_transcation',
                id: false
              }
            ]}
            defaultSelectedId={allowNotListed}
            onChange={value => setAllowNotListed(value)}
          />
          {error && (
            <>
              <Spacer />
              <StateMessage iconName='Alert' state='danger' text={error} />
            </>
          )}
          <ButtonWrapper>
            <Button appearance='tertiary' onClick={() => setActiveStep(STEPS.SITE_TYPES)}>
              {content?.previous_step}
            </Button>
            {mode === MODE.EDIT && (
              <Button disabled={isSubmitting} onClick={() => handleSubmit()}>
                {content?.restriction_profiles_rename_profile_primary_button}
              </Button>
            )}
            {mode === MODE.CREATE && (
              <Button
                disabled={isSubmitting}
                onClick={() => onNext({ siteTypes, restrictionList, violationId, allowNotListed })}
              >
                {content?.next_step}
              </Button>
            )}
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default SiteLocations
