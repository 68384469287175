import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media only screen and ${breakpoints.device.lg} {
    padding: 40px 32px;
  }
`
