import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  padding: 0 ${CssVars.structure.spacing.xl} 0 ${CssVars.structure.spacing.xl};
  background-color: ${CssVars.palette.neutral.lightest};
  margin-bottom: 1rem;

  @media only screen and ${breakpoints.device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

export const Box = styled.div`
  .title {
    font-size: 1rem;
    color: ${CssVars.palette.neutral.default};
    margin: 0 0 ${CssVars.structure.spacing.xl} 0;
  }
`

export const Address = styled.address`
  font-style: normal;

  > ol,
  li {
    list-style: none;
    padding: 0;
  }
  li {
    line-height: 1.5rem;
  }
`
