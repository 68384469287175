import styled from 'styled-components'

export const FiltersContainer = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`

export const TabContainer = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`

export const ModalContentWrapper = styled.div`
  height: calc(100vh - 212px);
`

export const AppliedFilters = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-self: start;
  gap: 0.75rem;
  width: 100%;
  font-size: 0.75rem;

  > div > span {
    font-size: 0.85rem;
    letter-spacing: normal;
    text-transform: capitalize;
  }
  span > svg {
    width: 22px;
    height: 22px;
  }
`
