import getBrandImage from 'helpers/getBrandImage'
import { BrandWrapper, BrandImage } from './BrandIcon.styled'

const BrandIcon = ({ brand = '' }) => (
  <BrandWrapper>
    <BrandImage src={getBrandImage(brand)} alt={brand} />
  </BrandWrapper>
)

export default BrandIcon
