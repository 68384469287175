import ReactDOM from 'react-dom'
import { Modal } from '@bp-digital/component-modal'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Text } from '@bp-digital/component-typography'
import { DropDown } from '@bp-digital/component-drop-down'
import { StateMessage } from '@bp-digital/component-state-message'
import axios from 'axios'
import { useState } from 'react'
//import useApiGetCardCount from 'hooks/api/useApiGetCardsCount'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { Grid, AuthorityMessage } from './OverrideProfiles.styled'
import { Spacer } from 'styles/common.styled'
import { queryClient } from 'src'

const VIOLATION_ACCEPT = 'accept'
const VIOLATION_ACCEPT_ALERT = 'accept-alert'

const OverrideProfiles = ({ authorityName, authorityId, customerId, content = {}, onClose }) => {
  //const { data } = useApiGetCardCount(authorityId)
  const durations = [1, 2, 3]
  const [duration, setDuration] = useState(durations[0])
  const [violationType, setViolationType] = useState(VIOLATION_ACCEPT_ALERT)
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  //const numberOfCards = data?.totalCards || '...'

  const handleOverride = async () => {
    setIsUpdating(true)
    setError(null)

    try {
      await axios({
        method: 'post',
        url: '/api/cards/profile/overrides',
        data: {
          acceptViolation: 1,
          alertViolation: violationType === VIOLATION_ACCEPT_ALERT ? 1 : 0,
          authorityId,
          customerId,
          duration,
          parentId: ''
        }
      })

      setIsUpdating(false)
      queryClient.invalidateQueries('cards/profile-overrides')
      onClose()
    } catch (e) {
      console.warn('Error occurred overriding profile', e)
      setIsUpdating(false)
      setError(content.override_profile_applied_failure_singular || 'Error')
    }
  }

  return ReactDOM.createPortal(
    <Modal
      visible
      title={content.title || 'Override profiles'}
      onDismiss={onClose}
      size='md'
      primaryAction={{
        text: 'Apply',
        isLoading: isUpdating,
        onClick: handleOverride
      }}
      secondaryAction={{
        text: 'Cancel',
        onClick: onClose,
        appearance: 'tertiary'
      }}
    >
      {error && (
        <>
          <StateMessage iconName='Alert' state='danger' text={error} />
          <Spacer />
        </>
      )}

      <RadioButtonGroup
        horizontal
        label={content.override_profiles_violations || '...'}
        onChange={setViolationType}
        defaultSelectedId={violationType}
        options={[
          {
            id: VIOLATION_ACCEPT,
            label: 'Accept'
          },
          {
            id: VIOLATION_ACCEPT_ALERT,
            label: 'Accept and alert'
          }
        ]}
        dataAttributes={{
          'data-content-key': 'override_profiles_violations',
          'data-testid': 'radio-override-violation'
        }}
      />

      <Grid>
        <AuthorityMessage>
          <Text>{content.override_profiles_authority_override || '...'}</Text>
          <Text>
            {authorityName}, {authorityId}
          </Text>
        </AuthorityMessage>

        <DropDown
          label={content.override_profiles_choose_duration}
          onChange={setDuration}
          defaultSelectedId={duration}
          options={durations.map(duration => ({
            id: duration,
            label: `${duration} ${
              duration === 1
                ? content.override_profiles_duration_hour_singular
                : content.override_profiles_duration_hour_plural
            }`
          }))}
          dataAttributes={{
            'data-content-key': 'override_profiles_choose_duration',
            'data-testid': 'dropdown-override-duration'
          }}
        />
      </Grid>

      <StateMessage
        state='danger'
        iconName='Alert'
        text={replaceVariablesInString(content.override_profile_warning_message || '', {
          level: content.override_profile_warning_message_a || 'authority',
          // not found any use case below one that's why commenting
          //count: numberOfCards,
          // card:
          //   numberOfCards <= 1
          //     ? content.override_profile_warning_message_card_singular
          //     : content.override_profile_warning_message_card_plural,
          duration,
          hour:
            duration === 1
              ? content.override_profiles_duration_hour_singular
              : content.override_profiles_duration_hour_plural
        })}
      />
    </Modal>,
    document.getElementById('root')
  )
}

export default OverrideProfiles
