import { AccordionPanel } from '@bp-digital/component-accordion-panel'
import {
  getDayTimeRestrictions,
  getOdometerReading,
  getSpendingLimits,
  getProductsAndServices,
  getSiteLocationRestrictions
} from 'helpers/restrictions'
import useContent from 'hooks/useContent'
import SingleRestrictionDetails from 'pages/Cards/Restrictions/components/SingleRestrictionDetails'
import { Grid } from './AllRestrictionDetails.styled'

const AllRestrictionDetails = ({ profileDetails, currency = '€', distanceUnit = 'KMS' }) => {
  const content = useContent('cards-restrictions') || {}

  return (
    <Grid>
      {profileDetails.spendingLimitRestriction && (
        <AccordionPanel iconName='CvcCvv' title={content.restriction_spending_limit_title}>
          <SingleRestrictionDetails
            content={content}
            restriction={profileDetails.spendingLimitRestriction}
            violationName='SpendingLimit'
            formattedRestrictions={getSpendingLimits(
              profileDetails.spendingLimitRestriction,
              currency, // authorityDetails.restrictions.currency
              content
            )}
          />
        </AccordionPanel>
      )}
      {profileDetails.productAndServicesRestriction && (
        <AccordionPanel iconName='Date' title={content.restriction_products_title}>
          <SingleRestrictionDetails
            content={content}
            restriction={profileDetails.productAndServicesRestriction}
            violationName='ProductService'
            formattedRestrictions={getProductsAndServices(
              profileDetails.productAndServicesRestriction,
              currency, // authorityDetails.restrictions.currency
              content,
              'restriction_profiles_'
            )}
          />
        </AccordionPanel>
      )}
      {profileDetails.sitelocationRestrictions && (
        <AccordionPanel iconName='Pin' title={content.restriction_site_title}>
          <SingleRestrictionDetails
            content={content}
            restriction={profileDetails.sitelocationRestrictions}
            violationName='Sites'
            formattedRestrictions={getSiteLocationRestrictions(
              profileDetails.sitelocationRestrictions,
              content,
              'restriction_profiles_'
            )}
          />
        </AccordionPanel>
      )}
      {profileDetails.dayTimeRestriction && (
        <AccordionPanel iconName='Clock' title={content.restriction_date_time_title}>
          <SingleRestrictionDetails
            content={content}
            restriction={profileDetails.dayTimeRestriction}
            violationName='DayTime'
            formattedRestrictions={getDayTimeRestrictions(
              profileDetails.dayTimeRestriction,
              content,
              'restriction_profiles_'
            )}
          />
        </AccordionPanel>
      )}
      {profileDetails.odometerAndDistanceRestriction && (
        <AccordionPanel iconName='Settings' title={content.restriction_odometer_title}>
          <SingleRestrictionDetails
            content={content}
            restriction={profileDetails.odometerAndDistanceRestriction}
            violationName='Odometer'
            formattedRestrictions={getOdometerReading(
              profileDetails.odometerAndDistanceRestriction,
              distanceUnit, // distanceUnit from authorityDetails.restrictions
              content,
              'restriction_profiles_'
            )}
          />
        </AccordionPanel>
      )}
    </Grid>
  )
}

export default AllRestrictionDetails
