import { useState } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'
import { useHistory, Link } from 'react-router-dom'
// import Skeleton from 'react-loading-skeleton'
import { ROUTE_CHARGE_POINTS } from 'constants/routes'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import Paper from 'components/surfaces/Paper'
import { useRootStore } from 'contexts/StoreContext'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import useContent from 'hooks/useContent'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { Grid, BackButtonWrapper } from './ChargePointPage.styled'
import { useSiteDetails } from './hooks'
import evBanner from 'src/assets/headers/ev-banner.jpg'
import ChargePointInsights from './components/ChargePointInsights'
import ChargePointDetails from './components/ChargePointDetails'
import ChargePointConnectors from './components/ChargePointConnectors'
import ChargePointSessions from './components/ChargePointSessions'
import { PAYLOAD_DATE_RANGES } from './components/ChargePointInsights/constants'
import { Icon } from '@bp-digital/component-icon'

const ChargePointPage = () => {
  const [useageDateRange, setUseageDateRange] = useState({ ...PAYLOAD_DATE_RANGES.CURRENT_MONTH })
  const { userStore } = useRootStore()
  const content = useContent('chargepointoperations-charger') || {}
  const params = useUrlSearchParams()
  const chargerId = params.get('chargerId')
  const siteId = params.get('siteId')
  // const authorityId = userStore.selectedAuthorityId
  const selectedHierarchy = userStore.selectedHierarchy
  const { data, isFetching, refetch } = useSiteDetails({
    chargerId,
    siteId,
    accessLevel: selectedHierarchy?.accessLevel,
    accessLevelCode: selectedHierarchy?.accessLevelCode,
    ...useageDateRange
  })
  const chargePointName = data?.site?.siteAlias ? data?.site?.siteAlias : data?.site?.[0]?.chargePointId || '-'
  const history = useHistory()

  const getSubtitle = (site = {}) => {
    const { siteName = '', siteAddress, sitePostCode, siteCity, siteCountry } = site
    const address = [siteAddress, sitePostCode, siteCity, siteCountry].filter(field => !!field).join(', ') || ''

    return (
      replaceVariablesInString(content?.cpo_charger_details_header_copy, {
        siteName,
        siteId,
        address
      }) || '...'
    )
  }

  if (!chargerId || !siteId) {
    return <Redirect to={ROUTE_CHARGE_POINTS} />
  }

  return (
    <>
      <PageHeader
        title={replaceVariablesInString(content?.cpo_charger_details_title || '...', {
          chargerName: chargePointName || ''
        })}
        subtitle={getSubtitle(data?.site || {})}
        headerImage={evBanner}
        testId='charger-details-header'
        brand={userStore.brand}
      >
        <BackButtonWrapper data-testid={`charger-details-back`}>
          <Link onClick={history.goBack} to=''>
            <Icon name='LeftSmall' size='md' />
            {content?.cpo_charger_details_back || 'cpo_charger_details_back'}
          </Link>
        </BackButtonWrapper>
      </PageHeader>
      <InnerPageWrapper>
        <Grid>
          <ChargePointDetails
            content={content}
            site={data?.site}
            isLoading={isFetching}
            userLocale={userStore.locale}
            onChange={() => {
              refetch()
              // chargePointsStore.getChargePoints({ selectedHierarchy, forceRefresh: true }) // not needed for now as no caching on this call!
            }}
          />
          <ChargePointConnectors content={content} chargePoints={data?.site?.chargePoints || []} />
          <ChargePointInsights
            content={content}
            usageSummary={data?.usageSummary}
            onFilterChange={setUseageDateRange}
            isLoading={isFetching}
          />
          <Paper testId='charger-details-sessions' title={content.cpo_transactions_widget_title || '...'}>
            <ChargePointSessions
              content={content}
              selectedHierarchy={selectedHierarchy}
              siteId={siteId}
              chargePointId={chargerId}
              chargePointName={chargePointName}
              type='charger'
              testId='charger-sessions-1'
              noResultsMessage={
                content?.cpo_charger_transactions_no_transactions || 'cpo_charger_transactions_no_transactions'
              }
            />
            <ChargePointSessions
              content={content}
              selectedHierarchy={selectedHierarchy}
              siteId={siteId}
              siteName={data?.site?.siteName || '-'}
              testId='charger-sessions-1'
              noResultsMessage={
                content?.cpo_site_transactions_no_transactions || 'cpo_site_transactions_no_transactions'
              }
            />
          </Paper>
        </Grid>
      </InnerPageWrapper>
    </>
  )
}

export default observer(ChargePointPage)
