import { Controller } from 'react-hook-form'
import { LabelWrapper, InputWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Icon } from '@bp-digital/component-icon'

const NATIONAL = 'N'
const INTERNATIONAL = 'Y'

const CardRegion = ({ content, control, isLoading }) => {
  return (
    <InputWrapper>
      <Controller
        name='region.international'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <RadioButtonGroup
            id={name}
            label={
              <LabelWrapper>
                <Text
                  display='inline-block'
                  dataAttributes={{
                    'data-content-key': 'manage_cards_edit_full_details_card_embossing_national_international'
                  }}
                >
                  {content?.manage_cards_edit_full_details_card_embossing_national_international}
                </Text>

                <Popover
                  appearance='dark'
                  text={content?.manage_cards_national_international_tooltip}
                  position='top'
                  dataAttributes={{ 'data-content-key': 'manage_cards_national_international_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            }
            options={[
              { id: NATIONAL, label: content?.manage_cards_national || 'manage_cards_national' },
              {
                id: INTERNATIONAL,
                label: content?.manage_cards_national_international || 'manage_cards_national_international'
              }
            ]}
            onChange={onChange}
            defaultSelectedId={value}
            disabled={isLoading}
            horizontal
          />
        )}
      />
    </InputWrapper>
  )
}

export default CardRegion
