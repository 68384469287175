import axios from 'axios'
import Cookies from 'js-cookie'
import getValueFromMilliseconds from 'helpers/getValueFromMilliseconds'
import { EXPIRY_ID, IDP_REFRESH, IDP_TOKEN } from '../../constants/localStorage'
import { ROUTE_LOGOUT } from 'constants/routes'
import getFallbackLanguage, { getLanguageFromUrl } from 'helpers/getFallbackLanguage'
import getBrandFromDomain from 'helpers/getBrandFromDomain'


interface MyWindow extends Window {
  href: string
}

declare const window: MyWindow

const locale = Cookies.get('bpf-locale') || getLanguageFromUrl() || getFallbackLanguage()

export const refreshSession = async () => {
  try {
    const localStorage = window.localStorage
    const brand = Cookies.get('brandfromdomain') === undefined ?  getBrandFromDomain() : Cookies.get('brandfromdomain')
    const codeverifier= localStorage.getItem('code_verifier')
    const rtoken = localStorage.getItem(IDP_REFRESH)
    if(codeverifier && rtoken){
    const response =  await axios.post(
        `/api/users/token/gettokens?refresh_token=secure`,
        {
          brand:brand,
          rtoken: rtoken,
          codeverifier:codeverifier,
          isLocal: Cookies.get('islocal'),
          IsNewDomain: Cookies.get('bpf-Azure-platform') === 'true' ? true : false
        },
        {
          skipAuthRefresh: true,
          withCredentials: true
        }
      );
    
    window.localStorage.setItem(EXPIRY_ID, 'expiry')

    if (response?.data.refresh_token) {
      const refreshExpiry = Number(getValueFromMilliseconds(response.data.tokenExpirationTime, 'd'))
      localStorage.setItem(IDP_TOKEN, response?.data.access_token)
      localStorage.setItem(IDP_REFRESH, response?.data.refresh_token)
      Cookies.set('bpf-refresh-expiry', `expiry`, { expires: refreshExpiry, secure: true })
    }
    else
      window.location.assign(window.location.protocol + '//' + window.location.host + '/' + locale + ROUTE_LOGOUT)
    return Promise.resolve()
  }
  else
    window.location.assign(window.location.protocol + '//' + window.location.host + '/' + locale + ROUTE_LOGOUT)
  } catch (e) {
    console.warn('Failed to refresh token', e)
    window.location.assign(window.location.protocol + '//' + window.location.host + '/' + locale + ROUTE_LOGOUT)
  }
}
