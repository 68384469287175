import Skeleton from 'react-loading-skeleton'
import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'
import { Badge } from '@bp-digital/component-badge'
import { Popover } from '@bp-digital/component-popover'
import { Icon } from '@bp-digital/component-icon'
import TwoColumn from 'components/layout/TwoColumn/'
import { Wrapper, Box, Address } from './DeliveryAddress.styled'
import { CARD_ORDER_MODE, SEND_TO_ADDRESS } from '../constants/constants'
import { LabelWrapper } from './common/CardOrder.styled'

const DeliveryAddress = ({ content, authority, deliveryAddress, sendTo, mode, isLoading }) => {
  const getSendToAddress = () => {
    if (sendTo === SEND_TO_ADDRESS.COST_CENTRE) {
      if (mode === CARD_ORDER_MODE.EXPRESS)
        return (
          content?.setup_select_costcenter_delivery_address_express ||
          'setup_select_costcenter_delivery_address_express'
        )
      if (mode === CARD_ORDER_MODE.GUIDED)
        return content?.setup_select_costcenter_delivery_address || 'setup_select_costcenter_delivery_address'
    }
    if (sendTo === SEND_TO_ADDRESS.CARD_HOLDER) {
      return content?.setup_select_cardholder_delivery_address || 'setup_select_cardholder_delivery_address'
    }
  }

  const deliveryText =
    mode === CARD_ORDER_MODE.EXPRESS
      ? content?.setup_card_delivery_address_express || 'setup_card_delivery_address_express'
      : content?.setup_card_delivery_address || 'setup_card_delivery_address'

  const deliveryTooltipText =
    sendTo === SEND_TO_ADDRESS.PARENT
      ? content?.setup_card_delivery_parent_tooltip || 'setup_card_delivery_parent_tooltip'
      : sendTo === SEND_TO_ADDRESS.CUSTOMER
      ? content?.setup_card_delivery_customer_tooltip || 'setup_card_delivery_customer_tooltip'
      : sendTo === SEND_TO_ADDRESS.CARD_HOLDER
      ? content?.setup_select_cardholder_delivery_address || 'setup_select_cardholder_delivery_address'
      : null

  return (
    <Wrapper>
      <TwoColumn>
        <Box>
          <Heading as='h3' className='title' dataAttributes={{ 'data-content-key': 'setup_card_address_authority' }}>
            {content?.setup_card_address_authority}
          </Heading>
          <Badge text={authority.customerName} state='dark' />
        </Box>

        <Box>
          <LabelWrapper>
            <Heading as='h3' className='title'>
              {deliveryText}
            </Heading>
            <Popover text={deliveryTooltipText} appearance='dark' position='top'>
              <Icon name='Info' appearance='dark' size='md' state='neutral' />
            </Popover>
          </LabelWrapper>

          {getSendToAddress() ? (
            <Text>{getSendToAddress()}</Text>
          ) : (
            <Address>
              <ol>
                <li>{isLoading ? <Skeleton /> : deliveryAddress?.addressLine1}</li>
                <li>{isLoading ? <Skeleton /> : deliveryAddress?.addressLine2}</li>
                {deliveryAddress?.addressLine3 && <li>{isLoading ? <Skeleton /> : deliveryAddress?.addressLine3}</li>}
                {deliveryAddress?.addressLine4 && <li>{isLoading ? <Skeleton /> : deliveryAddress?.addressLine4}</li>}
                <li>{isLoading ? <Skeleton /> : deliveryAddress?.postCode}</li>
                <li>{isLoading ? <Skeleton /> : deliveryAddress?.country}</li>
                {isLoading && <Skeleton />}
              </ol>
            </Address>
          )}
        </Box>
      </TwoColumn>
    </Wrapper>
  )
}

export default DeliveryAddress
