const getValueFromMilliseconds = (miliseconds, format) => {
  const totalSeconds = parseInt(Math.floor(miliseconds / 1000))
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60))
  const totalHours = parseInt(Math.floor(totalMinutes / 60))
  const days = parseInt(Math.floor(totalHours / 24))
  const seconds = parseInt(totalSeconds % 60)
  const minutes = parseInt(totalMinutes % 60)
  const hours = parseInt(totalHours % 24)

  switch (format) {
    case 's':
      return totalSeconds
    case 'm':
      return totalMinutes
    case 'h':
      return totalHours
    case 'd':
      return days
    default:
      return { d: days, h: hours, m: minutes, s: seconds }
  }
}

export default getValueFromMilliseconds
