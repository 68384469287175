import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { Button } from '@bp-digital/component-button'
import { TextField } from '@bp-digital/component-text-field'
import { Heading } from '@bp-digital/component-typography'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import { Spacer } from 'styles/common.styled'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import { getViolationId } from 'helpers/restrictions'
import { ButtonWrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { StateMessage } from '@bp-digital/component-state-message'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { MODE, RESTRICTION_TYPES } from 'constants/restrictions'

const Odometer = ({ authorityId, data, content, mode = MODE.EDIT, onBack, onNext, defaultValues, title }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { profileId, profileName, accept = true, alert = true, distanceUnit = '', maximumDistance = '' } = data

  const [violationId, setViolationId] = useState(getViolationId(accept, alert))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const { handleSubmit, reset, control, watch } = useForm({
    defaultValues: {
      restrictionDetail: {
        saveProfile: true,
        profileId,
        profileName,
        restrictionTypes: [RESTRICTION_TYPES.ODOMETER],
        existingProfile: true,
        odometerAndDistance: {
          maximumDistance,
          ...(defaultValues ? defaultValues : {})
        }
      }
    },
    mode: 'onBlur'
  })

  useEffect(() => {
    if (defaultValues) {
      setViolationId(getViolationId(defaultValues.acceptOdometerViolation, defaultValues.alertOdometerViolation))
    }
  }, [defaultValues])

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      reset()
      setIsSubmitting(false)
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`)
    },
    error => {
      setIsSubmitting(false)
      setError(
        content?.restriction_profiles_update_notification_error_singular ||
          'restriction_profiles_update_notification_error_singular'
      )
      console.error(error)
    },
    authorityId
  )

  const onSubmit = formData => {
    formData.restrictionDetail.odometerAndDistance.acceptOdometerViolation = violationId === 3 ? '0' : '1'
    formData.restrictionDetail.odometerAndDistance.alertOdometerViolation = violationId === 1 ? '0' : '1'

    if (mode === MODE.EDIT) {
      setIsSubmitting(true)
      submitForm(formData)
    } else {
      onNext(formData)
    }
  }

  const validationMessages = {
    validValue: content?.restriction_odometer_max_distance_required || 'restriction_odometer_max_distance_required',
    validPattern:
      content?.restriction_odometer_max_distance_no_letters || 'restriction_odometer_max_distance_no_letters',
    negativeValue: content?.validation_positive_number || 'validation_positive_number'
  }

  const input = watch('restrictionDetail.odometerAndDistance.maximumDistance')

  return (
    <>
      {title && <Heading as='h3'>{title}</Heading>}
      <ViolationsSelector onChange={setViolationId} defaultSelectedId={violationId} content={content} />

      <Spacer size='xxl3' />

      <form>
        <Controller
          name='restrictionDetail.odometerAndDistance.maximumDistance'
          control={control}
          rules={{
            required: validationMessages.validValue,
            validate: {
              validValue: v => (isNaN(Number(v)) && !/^[0-9]\d*$/.test(v) ? validationMessages.validPattern : null),
              positive: v => (v < 0 ? validationMessages.negativeValue : null),
              range: v => (v === 0 || v > 999999 ? validationMessages.validValue : null)
            }
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              defaultValue={value}
              textChangeHandler={onChange}
              label={
                content?.restriction_odometer_max_distance_label?.replace('{{distanceUnit}}', distanceUnit) ||
                'restriction_odometer_max_distance_label'
              }
              placeholder={
                content?.restriction_odometer_max_distance_placeholder ||
                'restriction_odometer_max_distance_placeholder'
              }
              error={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />

        {error && (
          <>
            <Spacer />
            <StateMessage iconName='Alert' state='danger' text={error} />
          </>
        )}
        <Spacer size='xxl2' />
        {mode === MODE.EDIT && (
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            disabled={isSubmitting || input === String(maximumDistance)}
            dataAttributes={{ 'data-content-key': 'restriction_profiles_rename_profile_primary_button' }}
          >
            {content?.restriction_profiles_rename_profile_primary_button}
          </Button>
        )}
        {mode === MODE.CREATE && (
          <ButtonWrapper>
            <Button onClick={onBack} appearance='tertiary' dataAttributes={{ 'data-content-key': 'previous_step' }}>
              {content?.previous_step}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || input === String(maximumDistance)}
              dataAttributes={{ 'data-content-key': 'next_step' }}
            >
              {content?.next_step}
            </Button>
          </ButtonWrapper>
        )}
      </form>
    </>
  )
}

export default Odometer
