import { Controller } from 'react-hook-form'
import { LabelWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { TextField } from '@bp-digital/component-text-field'
import { Icon } from '@bp-digital/component-icon'
import {
  validateNonEmptyVehicleOrDriverField,
  validateRequiredDriverName,
  validateNonValidCharacters
} from '../../../CardOrderPage/helpers'

const CardDriverName = ({
  control,
  content,
  cardTypeId,
  vehicleRegistration,
  countryCode,
  sendToAddress,
  setValue,
  isLoading
}) => {
  return (
    <Controller
      name='cardDetails.drivername'
      control={control}
      rules={{
        required:
          (validateRequiredDriverName({ cardTypeId, countryCode, sendToAddress }) &&
            (content?.manage_card_details_drivers_name_validation_required ||
              'manage_card_details_drivers_name_validation_required')) ||
          (validateNonEmptyVehicleOrDriverField(vehicleRegistration) &&
            (content?.manage_card_details_drivers_name_validation || 'manage_card_details_drivers_name_validation')),
        pattern: {
          value: validateNonValidCharacters,
          message:
            content?.manage_card_details_vehicledriver_validation || 'manage_card_details_vehicledriver_validation'
        }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <TextField
            label={
              <LabelWrapper>
                <Text
                  dataAttributes={{ 'data-content-key': 'manage_cards_edit_full_details_card_embossing_driver_name' }}
                >
                  {content?.manage_cards_edit_full_details_card_embossing_driver_name}
                </Text>
                <Popover
                  appearance='dark'
                  text={content?.manage_card_edit_details_drivers_name_tooltip}
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'manage_card_edit_details_drivers_name_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            }
            textChangeHandler={text => {
              onChange(text)
              !vehicleRegistration.trim() && setValue('cardEmbossing.line3', text)
            }}
            textTransform='uppercase'
            value={value}
            maxLength={14}
            disabled={isLoading}
            error={!!error?.message}
            errorMessage={error?.message}
          />
        )
      }}
    />
  )
}

export default CardDriverName
