import { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { format, localeFormat } from 'light-date'
import Cookies from 'js-cookie'
import { Alert } from '@bp-digital/component-alert'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import {
  PERMISSION_TO_VIEW_EV_MENU,
  PERMISSION_TO_DOWNLOAD_PRICELIST,
  PERMISSION_TO_MANAGE_PRICELIST
} from 'constants/permissions'
import useContent from 'hooks/useContent'
import getGreetingFromTime from './helpers/getGreetingFromTime'
import { Wrapper, AlertWrapper } from './DashboardPage.styled'
import { UninvoicedWidget } from './components/UninvoicedWidget'
import DocumentsWidget from './components/DocumentsWidget'
import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN, ROUTE_CHARGE_POINTS } from 'constants/routes'

const getTranslatedDate = (date, locale) => {
  return format(date, `${localeFormat(date, '{EEEE}', locale)} {dd} ${localeFormat(date, '{MMMM}', locale)} {yyyy}`)
}

const DashboardPage = () => {
  const { userStore } = useRootStore()
  const content = useContent('userdashboard') || {}
  const [showAlert, setShowAlert] = useState(!Cookies.get('bpf-notified'))
  const [redirectEvis, setRedirectEvis] = useState(false)
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  const mountedRef = useRef(true)

  useEffect(() => {
    if (!mountedRef.current) return null
    const isEVUser = userStore.userPermissions?.includes(PERMISSION_TO_VIEW_EV_MENU)
    if (isEVUser) setRedirectEvis(true)
    if (userStore.isAdmin && !userStore.impersonatedUser) setRedirectAdmin(true)
  }, [userStore.userPermissions, userStore.isAdmin, userStore.impersonatedUser])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  if (!mountedRef.current) return

  let isDownloadDisabled = true
  let isManageDisabled = true
  if (userStore.selectedAuthority?.authorityId && userStore.userPermissions?.length) {
    const downloadPricelistFlag = `${PERMISSION_TO_DOWNLOAD_PRICELIST}_${userStore.selectedAuthority?.authorityId.substr(
      0,
      1
    )}` //downloadPriceList_x
    const managePricelistFlag = `${PERMISSION_TO_MANAGE_PRICELIST}_${userStore.selectedAuthority?.authorityId.substr(
      0,
      1
    )}` //'managePriceList_x

    isDownloadDisabled = !userStore.userPermissions?.includes(downloadPricelistFlag)
    isManageDisabled = !userStore.userPermissions?.includes(managePricelistFlag)
  }

  if (redirectAdmin) return <Redirect to={ROUTE_ADMIN} />
  if (redirectEvis) return <Redirect to={ROUTE_CHARGE_POINTS} />

  return (
    <>
      <PageHeader
        title={
          (getGreetingFromTime({
            morning: content.morning,
            afternoon: content.afternoon,
            evening: content.evening
          }) || '-') + (userStore.name?.firstName ? `, ${userStore.name?.firstName}` : '')
        }
        subtitle={getTranslatedDate(new Date(), content?.currentLocale || 'en-gb')}
        brand={userStore.brand}
      />

      <InnerPageWrapper>
        {userStore.userPermissions && showAlert && (
          <AlertWrapper>
            <Alert
              text={
                content?.alertBanner ||
                'A new interface has now been introduced which is faster and more convenient. It is designed for ease of use on both desktops and mobile devices.'
              }
              state='info'
              onClose={() => {
                Cookies.set('bpf-notified', 'true', { expires: 30, secure: true })
                setShowAlert(false)
              }}
            />
          </AlertWrapper>
        )}

        <Wrapper>
          {userStore.userPermissions && !userStore.userPermissions.includes(PERMISSION_TO_VIEW_EV_MENU) && (
            <UninvoicedWidget />
          )}
          {userStore.userPermissions && !isDownloadDisabled && (
            <DocumentsWidget isDownloadDisabled={isDownloadDisabled} isManageDisabled={isManageDisabled} />
          )}
        </Wrapper>
      </InnerPageWrapper>
    </>
  )
}

export default observer(DashboardPage)
