import { useState } from 'react'
import { Button } from '@bp-digital/component-button'
import { StateMessage } from '@bp-digital/component-state-message'
import { Modal } from '@bp-digital/component-modal'
import useApiGetRestrictionProfile from 'hooks/api/useApiGetRestrictionProfile'
import AllRestrictionDetails from 'pages/Cards/Restrictions/components/AllRestrictionDetails'

const RestrictionDetails = ({ content, profileId, currency, distanceUnit }) => {
  const [visible, setVisible] = useState(false)
  const { data, isLoading, isError } = useApiGetRestrictionProfile(profileId, { retry: false })
  return (
    <>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        onClick={() => setVisible(true)}
        dataAttributes={{ 'data-content-key': 'express_mode_view_restriction_details_button' }}
      >
        {content?.express_mode_view_restriction_details_button}
      </Button>

      <Modal
        visible={visible}
        title={content?.restriction_title || 'restriction_title'}
        primaryAction={{
          text: 'Close',
          onClick: () => setVisible(false),
          dataAttributes: {
            'data-testid': 'button-close-restrictions',
            'data-content-key': ''
          }
        }}
        size='md'
        onDismiss={() => setVisible(false)}
      >
        {isError ? (
          <StateMessage iconName='Alert' state='danger' text={content?.error} />
        ) : (
          <AllRestrictionDetails
            content={content}
            profileDetails={data?.restrictiondetails}
            currency={currency}
            distanceUnit={distanceUnit}
          />
        )}
      </Modal>
    </>
  )
}

export default RestrictionDetails
