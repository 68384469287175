import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const TransactionCardWithMap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const TransactionDetail = styled.div`
  position: absolute;
  display: flex;
  width: 360px;
  height: 286px;
  z-index: 100;
  top: 32px;
  left: 32px;
  flex: 1 0 auto;

  @media only screen and ${breakpoints.device.lg} {
    width: 380px;
  }
`

export const Heading = styled.p`
  margin: 0;
`

export const TransactionDetailHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border-bottom: 1px dashed var(--color-grey-regular-300);
`

export const TransactionDetailActions = styled.div`
  border-top: 1px dashed var(--color-grey-regular-300);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  padding: 8px;
  position: absolute;
  bottom: 0;
  width: 100%;

  & > * {
    width: 100%;
  }
`

// header height + button height + padding height + border width of actions for height
export const TransactionDetailBody = styled.ul`
  list-style: none;
  width: 100%;
  padding: 8px;
  height: calc(100% - (40px + 16px + 1px + 48px));
  overflow: auto;
`

export const TransactionDetailItem = styled.li`
  width: 100%;
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const MapContainer = styled.div`
  height: inherit;
  > div {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    > div {
      height: inherit;
    }
  }
`
export const ButtonCloseStyled = styled.div.attrs(() => ({ role: 'button' }))`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`
export const MapMarkerStyled = styled.div`
  @keyframes fadeInTrans {
    from {
      opacity: 0;
      transform: translate(-10%, -50%);
    }
    to {
      opacity: 1;
      transform: translate(0%, -50%);
    }
  }

  @keyframes popFade {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  position: relative;

  display: none;
  margin-left: 30rem;
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.2);
  animation: fadeInTrans 0.5s;
  animation-delay: 0;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translate(-10%, -50%);
  background: #fff;
  left: 4em;
  width: 12vw;
  padding: 1.2em 1.6em;
  z-index: 3;
  top: 50%;
  line-height: 1.5;

  @media only screen and ${breakpoints.device.lg} {
    display: block;
  }
`
export const Label = styled.p`
  color: var(--color-grey-regular-500);
  margin: 0;
`

export const Value = styled.p`
  margin: 0;
  text-align: right;
`
