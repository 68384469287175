import { BrowserRouter as Router } from 'react-router-dom'
import { Heading } from '@bp-digital/component-typography'
import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { ROUTE_CARDS_VIEW } from 'constants/routes'
import { useHistory } from 'react-router'
import { EMAILADDRESS } from 'constants/onefleetmigrated'

const RestrictCardOrder = ({ content, cardOrderSetting,blockCardOrder }) => {
  const history = useHistory() 
  if (!cardOrderSetting && !blockCardOrder){ 
    return <></>
}

  return (
    <div className='container'>
      <Heading as='h2' data-content-key='setup_no_authority_heading'>
        {content?.setup_no_authority_heading || ''}
      </Heading>
      {blockCardOrder ?(
         <Text as='p' data-content-key='onefleetmigrated_block_cardorder_message'>
         {content?.onefleetmigrated_block_cardorder_message|| 'content?.onefleetmigrated_block_cardorder_message'}
         <a href ={`mailto:${EMAILADDRESS}`}>{EMAILADDRESS}</a>
       </Text>
     ) : 
     (<>
      {cardOrderSetting.cardLimitReached ? (
        <Text as='p' data-content-key='setup_card_limit_reached'>
          {content?.setup_card_limit_reached || 'setup_card_limit_reached'}
        </Text>
      ) : (
        <></>
      )}
      {cardOrderSetting.authorityNotAllowed ? (
        <Text as='p' data-content-key='setup_card_authority_not_allowed'>
          {content?.setup_card_authority_not_allowed || 'setup_card_authority_not_allowed'}
        </Text>
      ) : (
        <></>
      )}
      {cardOrderSetting.customerNotAllowed ? (
        <Text as='p' data-content-key='setup_card_customer_not_allowed'>
          {content?.setup_card_customer_not_allowed || 'setup_card_customer_not_allowed'}
        </Text>
      ) : (
        <></>
      )}
      <>
        <div className='button-wrapper'>
          <Router>
            <Button
              onClick={() => history.replace(ROUTE_CARDS_VIEW, {})}
              dataAttributes={{ 'data-content-key': 'setup_card_limit_manage_card' }}
            >
              {content?.setup_card_limit_manage_card || 'setup_card_limit_manage_card'}
            </Button>
          </Router>
        </div>
      </>
      </>
    )}
    </div>
  )
}

export default RestrictCardOrder
