import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import debounce from 'lodash.debounce'
import { Toast } from '@bp-digital/component-toast'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import PreferencesEditor from 'src/components/dataDisplay/PreferencesEditor'
import { useRootStore } from 'src/contexts/StoreContext'
import { ROUTE_ARCHIVED_INVOICES, ROUTE_INVOICES, ROUTE_ADMIN } from 'src/constants/routes'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import InvoiceSummary from './components/InvoiceSummary'
import SimpleInvoice from './components/SimpleInvoice/'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import { StateMessage } from '@bp-digital/component-state-message'
import { Spacer } from 'styles/common.styled'
import { getSelectedDownload } from './helpers/getSelectedDownload'
import { INVOICE_TYPES } from './constants/constants'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { Redirect } from 'react-router-dom'

const MAX_DOWNLOAD_LIMIT = 10

const InvoicesPage = () => {
  const { invoicesStore, userStore, contentStore } = useRootStore()
  const content = contentStore.getPage('transactions-invoices')
  const [isEditingColumns, setIsEditingColumns] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadMessage, setDownloadMessage] = useState()
  const [selectedCount, setSelectedCount] = useState(0)
  const [showInvoiceDownloadModal, setShowInvoiceDownloadModal] = useState(false)
  const [showContinueButton, setshowContinueButton] = useState(false)
  let [invoiceDownloadrequest, setInvoiceDownloadRequest] = useState({})
  const [validationModalMessage, setValidationModalMessage] = useState(null)
  const authorityId = userStore.selectedAuthorityId
  const loadingRow = invoicesStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )
  let [unavailableInvoices, setUnavailableInvoices] = useState([])
  useEffect(() => {
    return () => invoicesStore.setSearchTerm('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userStore.preferences && authorityId) {
      invoicesStore.getInvoices({ authorityId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityId, userStore.preferences])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('transactions-invoices')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])
  useEffect(() => {
    if (showContinueButton) {
      setValidationModalMessage(
        content?.few_selected_invoice_unavailable_for_download != null
          ? replaceVariablesInString(content?.few_selected_invoice_unavailable_for_download, {
              unavailableInvoices: unavailableInvoices
            })
          : `few_selected_invoice_unavailable_for_download`
      )
    } else {
      setValidationModalMessage(
        content?.all_selected_invoice_unavailable_for_download != null
          ? content?.all_selected_invoice_unavailable_for_download
          : 'all_selected_invoice_unavailable_for_download'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContinueButton])

  const handleSubmitSearch = async () => {
    await invoicesStore.getInvoices({ authorityId })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      invoicesStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    invoicesStore.setSearchTerm('')
    invoicesStore.setSearchLimits({})
    invoicesStore.getInvoices({ authorityId })
  }

  const tableSearchProps = {
    searchTerm: invoicesStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.search_placeholder || '...',
    inputAriaLabel: content?.search_placeholder || '...',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  const handlePreferenceChange = async newlySelectedColumns => {
    await userStore.updatePreferences('invoices', newlySelectedColumns)
    await userStore.getPreferences()
    await invoicesStore.getInvoices({ authorityId })
  }

  const handleSelectedRowsChange = useCallback(
    rowIDs => {
      setSelectedCount(Object.keys(rowIDs).length)
    },
    [setSelectedCount]
  )

  const alertMessage =
    selectedCount > 10 ? (
      <div>
        <StateMessage
          iconName='Alert'
          state='danger'
          text={content?.more_than_limit_downloads || 'more_than_limit_downloads'}
        />
        <Spacer size='md' />
      </div>
    ) : null

  const tableActionProps = [
    {
      text: content?.tableFilter_ctaArchive || 'Invoice archive',
      iconName: 'Search',
      size: 'lg',
      to: ROUTE_ARCHIVED_INVOICES,
      dataAttributes: {
        'data-testid': 'button-archive'
      }
    },
    {
      text: content?.tableFilter_ctaColumn || 'Columns',
      iconName: 'Edit',
      size: 'lg',
      disabled: invoicesStore.isFetchingPreferences,
      onClick: () => {
        setIsEditingColumns(true)
      },
      dataAttributes: {
        'data-testid': 'button-edit-columns'
      }
    },
    {
      text: content?.archive_download?.replace('{{count}}', selectedCount) ?? 'Download',
      iconName: 'Download',
      size: 'lg',
      isLoading: isDownloading,
      disabled: selectedCount === 0 || selectedCount > MAX_DOWNLOAD_LIMIT,
      onClick: async selectedRows => {
        const { response, nextRequest } = await getSelectedDownload(
          selectedRows,
          isDownloading,
          setIsDownloading,
          setDownloadMessage,
          authorityId,
          content,
          invoicesStore,
          true
        )

        if (response?.missingInvoiceCount == 0) {
          setUnavailableInvoices([])
          await getInvoiceDownload(nextRequest)
        } else {
          if (response?.missingInvoiceCount == response?.totalInvoices) {
            setshowContinueButton(false)
            setValidationModalMessage(
              content?.all_selected_invoice_unavailable_for_download != null
                ? content?.all_selected_invoice_unavailable_for_download
                : 'all_selected_invoice_unavailable_for_download'
            )
          } else {
            setUnavailableInvoices(
              response.summaries
                .filter(sum => {
                  return !sum.inArchive
                })
                .map(items => items.documentNumber)
                .flat(1)
            )
            setshowContinueButton(true)
          }
          setIsDownloading(false)
          setShowInvoiceDownloadModal(true)
          setInvoiceDownloadRequest(nextRequest)
        }
      },
      dataAttributes: {
        'data-testid': 'button-download-invoices'
      }
    }
  ]
  const getInvoiceDownload = async request => {
    unavailableInvoices.forEach(docNo => {
      const index = request.findIndex(obj => obj.documentNumber == docNo)
      request.splice(index, 1)
    })
    await getSelectedDownload(
      request,
      isDownloading,
      setIsDownloading,
      setDownloadMessage,
      authorityId,
      content,
      invoicesStore,
      false
    )
    setShowInvoiceDownloadModal(false)
  }
  const tablePagination = {
    currentPage: invoicesStore.currentPage,
    count: invoicesStore.numberOfPages || 1,
    pageSize: invoicesStore.pageSize,
    disabled: !invoicesStore.data,
    onChange: page =>
      invoicesStore.getInvoices({
        authorityId,
        page,
        pageSize: invoicesStore.pageSize
      }),
    onSizeChange: pageSize =>
      invoicesStore.getInvoices({
        authorityId,
        page: 1,
        pageSize
      })
  }

  const data = useMemo(
    () => {
      return invoicesStore.isFetchingData
        ? Array(tablePagination.pageSize)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : invoicesStore.rows
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoicesStore.rows, invoicesStore.isFetchingData, tablePagination.currentPage, tablePagination.pageSize]
  )

  const columns = useMemo(
    () =>
      invoicesStore.isFetchingData
        ? invoicesStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : invoicesStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoicesStore.columns, invoicesStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortInvoices = async (key = 'invoiceDate', desc = true) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        invoicesStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!invoicesStore.results.length && invoicesStore.searchTerm.length) {
          await invoicesStore.getInvoices({
            authorityId,
            columnSorting,
            searchTerm: ''
          })
        }
        await invoicesStore.getInvoices({
          authorityId,
          columnSorting
        })
      }
      if (invoicesStore.rows.length > 0) {
        sortInvoices(key, desc)
      }
    },
    [authorityId, invoicesStore]
  )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.hero_title || '...'}
        subtitle={replaceVariablesInString(content?.hero_copy1 || '...', {
          totalNumberOfSummaries: invoicesStore.limits?.totalNumberOfSummaries || '0',
          totalNumberOfInvoices: invoicesStore.limits?.totalNumberOfInvoices || '0'
        })}
        breadcrumbs={[{ to: ROUTE_INVOICES, title: content?.hero_title, ariaLabel: content?.hero_title }]}
        brand={userStore.brand}
      />
      <AlertModal
        title={content?.modal_title != null ? content?.modal_title : ' content?.modal_title'}
        text={validationModalMessage}
        primaryAction={{
          text: content?.modal_button_contiue ? content?.modal_button_continue : 'Continue',
          onClick: () => {
            getInvoiceDownload(invoiceDownloadrequest)
          },
          disabled: !showContinueButton,
          isLoading: isDownloading
        }}
        secondaryAction={{
          text: content?.modal_button_cancel ? content?.modal_button_cancel : 'Cancel',
          onClick: () => {
            setShowInvoiceDownloadModal(false)
          },
          appearance: 'tertiary'
        }}
        onDismiss={() => {
          setShowInvoiceDownloadModal(false)
        }}
        state='warning'
        visible={showInvoiceDownloadModal}
        size='sm'
      />
      <InnerPageWrapper>
        {isEditingColumns && content && (
          <PreferencesEditor
            initialSelectedColumns={invoicesStore.selectedColumns}
            setIsOpen={setIsEditingColumns}
            onUpdate={handlePreferenceChange}
            sectionName='invoices'
            getAvailableColumns={invoicesStore.getAvailableColumns}
            content={content}
            locale={userStore.locale}
          />
        )}
        <ReactTable
          name='invoices'
          columns={columns}
          data={data}
          hasSubRows
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          alert={alertMessage}
          onSelectedRowsChange={handleSelectedRowsChange}
          renderDetail={(
            {
              isSelected,
              original: { summaryStatementId, invoiceNumber, invoiceDate, invoiceType, subRows, invoiceId }
            },
            instanceToggleRowSelected
          ) => {
            return !invoiceType.includes(INVOICE_TYPES.SUMMARY) && !subRows ? (
              <SimpleInvoice
                invoiceNumber={invoiceNumber}
                invoiceDate={invoiceDate}
                content={content}
                invoiceId={invoiceId}
              />
            ) : (
              <InvoiceSummary
                invoiceDate={invoiceDate}
                invoiceNumber={invoiceNumber}
                summaryStatementId={summaryStatementId}
                instanceToggleRowSelected={instanceToggleRowSelected}
                isParentRowSelected={isSelected}
                content={content}
              />
            )
          }}
          onSort={handleSort}
          isLoading={invoicesStore.isFetchingData}
          showSelectAllCheckbox={false}
          disableRowSelection={selectedCount > 10 ? true : false}
        />
        <Toast appearance='dark' toast={downloadMessage} />
      </InnerPageWrapper>
    </>
  )
}

export default observer(InvoicesPage)
