import React from 'react'
import { Icon } from '@bp-digital/component-icon'
import { Button } from '@bp-digital/component-button'
import { ErrorWrapper, ErrorText } from './GlobalErrorBoundary.styled'

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    }
  }

  componentDidCatch(error, errorInfo) {
    console.warn(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <Icon name='Alert' size='xl' state='danger' />
          <ErrorText>
            Error!
          </ErrorText>
          <Button dataAttributes={{ 'test-id': 'button-error-homepage' }} iconName='Home' href='/'>
            Homepage
          </Button>
        </ErrorWrapper>
      )
    }

    return this.props.children 
  }
}

export default GlobalErrorBoundary
