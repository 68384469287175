import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useRootStore } from 'src/contexts/StoreContext'
import TokenExpanderHeader from '../TokenExpanderHeader'
import TokenExpanderList from '../TokenExpanderList'
import { TokenExpanderWrapper } from './TokenRowExpander.styled'

const TokenRowExpander = ({ content, row }) => {
  const { depotsStore, userStore } = useRootStore()
  const selectedHierarchy = userStore.selectedHierarchy
  const totalDepots = depotsStore.rows?.length

  useEffect(() => {
    if (!depotsStore.data && selectedHierarchy) {
      depotsStore.getDepots({ selectedHierarchy })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHierarchy, depotsStore.data, depotsStore.isFetchingData])

  return (
    <TokenExpanderWrapper>
      <TokenExpanderHeader
        tokenModifiedDateCopy={content?.depot_permissions_card_modified_date || 'depot_permissions_card_modified_date'}
        depotsAllowedAtCopy={
          content?.depot_permissions_card_number_of_depots_allowed || 'depot_permissions_card_number_of_depots_allowed'
        }
        tokenModifiedDate={row.original.modifiedDate}
        totalDepots={totalDepots}
        totalDepotsAllowedAt={row.original.depotsAllowed ?? 0}
      />
      <TokenExpanderList depotsInfo={depotsStore.rows} tokenDepotsAllowedAt={row.original.allowedSiteIds} />
    </TokenExpanderWrapper>
  )
}

export default observer(TokenRowExpander)
