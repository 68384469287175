import MapPin from 'src/components/dataDisplay/Map/MapPin'
import { ReactElement } from 'react'
import { Transaction } from '../../TransactionMap.types'
import TransMapFlyout from '../TransMapFlyout'
import { CssVars } from '@bp-digital/theme'

export interface TransMapOverlayProps {
  transaction?: Transaction
  onFlyoutMouseEnter: () => void
  onFlyoutMouseLeave: () => void
}

const TransMapOverlay = ({
  transaction,
  onFlyoutMouseEnter,
  onFlyoutMouseLeave
}: TransMapOverlayProps): ReactElement => {
  if (!transaction) return <></>

  const isAuthorised = transaction.transactionStatus === 'Authorised'
  const pinColor = isAuthorised ? CssVars.palette.state.successDark : CssVars.palette.state.dangerDark

  return (
    <>
      <MapPin color={pinColor} />
      {transaction && (
        <TransMapFlyout transaction={transaction} onMouseEnter={onFlyoutMouseEnter} onMouseLeave={onFlyoutMouseLeave} />
      )}
    </>
  )
}

export default TransMapOverlay
