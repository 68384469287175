import Paper from 'components/surfaces/Paper'
import { Text } from '@bp-digital/component-typography'
import { Icon } from '@bp-digital/component-icon'
import { Row, Column, Wrapper, Container, Link } from './UserOverview.styled'
import { Alert } from '@bp-digital/component-alert'

const UserOverview = ({
  overviewText,
  linkExpiredText,
  resendInviteText,
  lastLoginText,
  lastLogin,
  groupsText,
  parentsText,
  authoritiesText,
  accessCount,
  revokeAccessBtnText,
  onValidateAccess,
  revokeAccessBtnLoading,
  userStatus,
  hasResentInvite,
  onResendInvite
}) => {
  const button = {
    text: revokeAccessBtnText || '...',
    onClick: onValidateAccess,
    disabled: revokeAccessBtnLoading,
    isLoading: revokeAccessBtnLoading,
    dataAttributes: {
      'data-testid': 'button-revoke-access'
    }
  }

  return (
    <Paper title={overviewText} button={button}>
      <Wrapper>
        <Container>
          <Alert
            visible={(userStatus == 'Invited' || userStatus == 'Link Expired') && !hasResentInvite}
            state='warning'
          >
            {userStatus == 'Link Expired' ? `${linkExpiredText} ` : ''}
            <Link onClick={onResendInvite}>{resendInviteText}</Link>
          </Alert>
          {lastLogin && (
            <>
              <Icon name='Clock' size='xxl' />
              <Text>{lastLoginText || '...'}</Text>
              <Text>{lastLogin ? new Date(lastLogin).toLocaleString() : '...'}</Text>
            </>
          )}
        </Container>
      </Wrapper>
      <Row>
        <Column>
          <Text textAlign='center'>{accessCount?.groups}</Text>
          <Text textAlign='center'>{groupsText || '...'}</Text>
        </Column>
        <Column>
          <Text textAlign='center'>{accessCount?.parents}</Text>
          <Text textAlign='center'>{parentsText || '...'}</Text>
        </Column>
        <Column>
          <Text textAlign='center'>{accessCount?.authorities}</Text>
          <Text textAlign='center'>{authoritiesText || '...'}</Text>
        </Column>
      </Row>
    </Paper>
  )
}

export default UserOverview
