import { Overlay, Banner, Container, Wrapper } from './CookieBanner.styled'
import useContent from 'src/hooks/useContent'
import { Text, Heading } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { Modal } from '@bp-digital/component-modal'
import { useState } from 'react'
import getConsentCookies from 'helpers/getConsentCookies'
import setConsentCookies from 'helpers/setConsentCookies'
import CookiePreferencesContent from 'components/cookieConsent/CookiePreferencesContent'

const CookieBanner = () => {
  const content = useContent('footer')
  const { functionalCookies, performanceCookies } = getConsentCookies()
  const [hideBanner, setHideBanner] = useState(functionalCookies)
  const [modalVisible, setModalVisible] = useState(false)
  const [enablePerformanceCookies, setEnablePerformanceCookies] = useState(performanceCookies)

  if (functionalCookies || hideBanner) return <></>

  const acceptAllCookies = () => {
    setConsentCookies()
    setHideBanner(true)
  }

  const saveConsentCookies = () => {
    setConsentCookies(enablePerformanceCookies)
    setHideBanner(true)
  }

  return (
    <Overlay>
      <Banner>
        <Wrapper>
          <Container>
            <Heading as='h5'>{content?.notification_header}</Heading>
            <Text>{content?.notification_paragraph}</Text>
          </Container>
          <Container>
            <Button appearance='quaternary' onClick={() => setModalVisible(true)}  dataAttributes={{ 'data-testid': 'button-manage-cookies' }}>
              {content?.notification_manage_button}
            </Button>
            <Button appearance='primary' onClick={acceptAllCookies} dataAttributes={{ 'data-testid': 'button-accept-cookies' }}>
              {content?.notification_agree_button}
            </Button>
          </Container>
        </Wrapper>
      </Banner>
      <Modal
        title={content?.notification_manage_button}
        size='lg'
        primaryAction={{ text: content?.modal_save_changes_button, onClick: saveConsentCookies }}
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
      >
        <CookiePreferencesContent
          enablePerformanceCookies={enablePerformanceCookies}
          setEnablePerformanceCookies={setEnablePerformanceCookies}
          content={content}
        />
      </Modal>
    </Overlay>
  )
}

export default CookieBanner
