import { HeaderWrapper } from './TokenExpanderHeader.styled'
import { Text } from '@bp-digital/component-typography'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import { useRootStore } from 'contexts/StoreContext'

const TokenExpanderHeader = ({
  tokenModifiedDateCopy,
  depotsAllowedAtCopy,
  tokenModifiedDate,
  totalDepots,
  totalDepotsAllowedAt
}) => {
  const { userStore } = useRootStore()
  const depotsAllowedCopyWithValues = depotsAllowedAtCopy
    .replace('{0}', totalDepotsAllowedAt)
    .replace('{1}', totalDepots)

  const cardModifiedDateCopyWithValues = tokenModifiedDateCopy
    .replace('{0}', getUserFormattedDate(tokenModifiedDate, userStore.dateFormat, true))
    .replace('{1}', getUserFormattedDate(tokenModifiedDate, userStore.dateFormat).split(' ')[1])

  return (
    <HeaderWrapper>
      <Text as='p'>{depotsAllowedCopyWithValues}</Text>
      <Text as='p'>{cardModifiedDateCopyWithValues}</Text>
    </HeaderWrapper>
  )
}

export default TokenExpanderHeader
