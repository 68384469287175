import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'access/user'
type userIdType = number | null

const queryFn = async (userId: userIdType) => {
  if (!userId) throw new Error('No User Id given to /user/details endpoint')

  const response = await axios({
    method: 'get',
    url: `/api/users/users/${userId ? userId : '-1'}/details`
  })

  return response.data
}

const useApiGetUserDetails = (userId: userIdType, options: { staleTime: number | typeof Infinity }) => {
  return useQuery([QUERY_KEY, { userId: userId }], () => queryFn(userId), {
    ...options
  })
}

export default useApiGetUserDetails
