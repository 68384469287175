import axios from 'axios'
import { useQuery } from 'react-query'

export const QUERY_KEY = 'cards/profile-overrides'

type paramType = string | null

const queryFn = async (authorityId: paramType) => {
  if (!authorityId) throw new Error('No Authority Id given to /cards/count endpoint')

  const response = await axios({
    method: 'get',
    url: `/api/cards/profile/overrides?AuthorityIds=${authorityId}`
  })

  return response.data?.overrides || []
}

const useApiGetProfileOverrides = (authorityId: paramType, options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY, () => queryFn(authorityId), {
    staleTime: 120000,
    enabled: !!authorityId,
    ...options
  })
}

export default useApiGetProfileOverrides
