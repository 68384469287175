import { observer } from 'mobx-react'
import Title from 'src/components/dataDisplay/Title'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import useContent from 'src/hooks/useContent'
import { useState } from 'react'
import { Button } from '@bp-digital/component-button'
import getConsentCookies from 'helpers/getConsentCookies'
import setConsentCookies from 'helpers/setConsentCookies'
import CookiePreferencesContent from 'components/cookieConsent/CookiePreferencesContent'
import { Wrapper } from './CookiePreferencesPage.styled'
import { Alert } from '@bp-digital/component-alert'
import ButtonsWrapper from 'components/layout/ButtonsWrapper'

const CookiePreferencesPage = () => {
  const content = useContent('footer') // this needs to change...
  const [alert, setAlert] = useState({
    visible: false,
    state: 'success',
    text: ''
  })
  const { performanceCookies } = getConsentCookies()
  const [enablePerformanceCookies, setEnablePerformanceCookies] = useState(performanceCookies)

  const saveConsentCookies = () => {
    setConsentCookies(enablePerformanceCookies)
    setAlert({
      visible: true,
      state: 'success',
      text: content?.modal_changes_saved_button
    })
  }

  return (
    <>
      <Title>{content?.cookie_preferences || 'Cookie Preferences'}</Title>

      <InnerPageWrapper>
        <Wrapper $isVisible={alert.visible}>
          <Alert
            text={alert.text}
            visible={alert.visible}
            state={alert.state}
            iconPrefix
            onClose={() => setAlert({ ...alert, visible: false })}
          />
          <ButtonsWrapper>
            <Button size='lg' onClick={saveConsentCookies} dataAttributes={{ 'data-testid': 'button-save-cookies' }}>
              {content?.modal_save_changes_button}
            </Button>
          </ButtonsWrapper>
        </Wrapper>
        <CookiePreferencesContent
          enablePerformanceCookies={enablePerformanceCookies}
          setEnablePerformanceCookies={setEnablePerformanceCookies}
          content={content}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(CookiePreferencesPage)
