import { HeaderGroup } from 'react-table'
import styled from 'styled-components'
import { SortIcon } from './SortIcon'
import { Truncate } from './Truncate'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  max-height: 16px;
  /* Flexbox child needs min-width for ellipsis */
  min-width: 0;
  column-gap: 10px;
`

const Resizer = styled.div<ResizerStyleProps>`
  display: ${props => (props.headerIndex === props.visibleColumnsLength - 1 ? 'none' : 'inline-block')};
  width: 2rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
`
interface ResizerStyleProps {
  headerIndex: number
  visibleColumnsLength: number
}

interface Props extends ResizerStyleProps {
  column: HeaderGroup<any> //eslint-disable-line @typescript-eslint/no-explicit-any
}

export const HeaderCell = ({ column, ...rest }: Props) => {
  const testId = `column-${column.id}-${rest.headerIndex}`
  if (column.id === 'selection' || column.id === 'expander') {
    return <StyledHeader data-testid={testId}>{column.render('Header')}</StyledHeader>
  }
  return (
    <StyledHeader data-testid={testId}>
      <Truncate>{column.render('Header')}</Truncate>
      {column.canSort && <SortIcon isSortedDesc={column.isSortedDesc} />}
      <Resizer {...column.getResizerProps?.()} onClick={e => e.stopPropagation()} {...rest}></Resizer>
    </StyledHeader>
  )
}
