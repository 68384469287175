import ReactDOM from 'react-dom'
import { Modal } from '@bp-digital/component-modal'
import useContent from 'hooks/useContent'
import { Text } from '@bp-digital/component-typography'
import { Table } from '@bp-digital/component-table'
import { StateMessage } from '@bp-digital/component-state-message'
import { Footnote, KeyItem, KeyItemContainer, CellWithIconAndText, IconRow } from './CardOrder.styled'
import { Icon } from '@bp-digital/component-icon'
import { CardPinBadge, VehiclesBadge, DriversPinBadge } from './ModalBadges'

const root = document.getElementById('root')

const getBadges = rowNum => {
  switch (rowNum) {
    case 1:
      return (
        <IconRow>
          <Icon name='User' />
          <Icon name='Delivery' />
          <CardPinBadge />
        </IconRow>
      )
    case 2:
      return (
        <IconRow>
          <DriversPinBadge />
          <Icon name='CvcCvv' />
          <VehiclesBadge />
        </IconRow>
      )
    case 3:
      return (
        <IconRow>
          <Icon name='User' />
          <CardPinBadge />
          <VehiclesBadge />
        </IconRow>
      )
    case 4:
      return (
        <IconRow>
          <Icon name='People' />
          <CardPinBadge />
          <VehiclesBadge />
        </IconRow>
      )

    default:
      return null
  }
}

export const CardTypesModal = ({ onClose }) => {
  const content = useContent('cards-order') || {}
  const createHeaders = () =>
    Array(4)
      .fill()
      .map((_, idx) => (
        <Table.Header key={idx} data-content-key={`card_type_modal_table_heading_${idx + 1}`}>
          {content[`card_type_modal_table_heading_${idx + 1}`]}
        </Table.Header>
      ))
  const createRows = () =>
    Array(4)
      .fill()
      .map((_, idx) => (
        <Table.Row key={idx}>
          <Table.Cell data-content-key={`card_type_modal_table_row${idx + 1}_column1`}>
            <CellWithIconAndText>
              <div>{content[`card_type_modal_table_row${idx + 1}_column1`]}</div>
              {getBadges(idx + 1)}
            </CellWithIconAndText>
          </Table.Cell>
          <Table.Cell data-content-key={`card_type_modal_table_row${idx + 1}_column2`}>
            <div>{content[`card_type_modal_table_row${idx + 1}_column2`]}</div>
          </Table.Cell>
          <Table.Cell data-content-key={`card_type_modal_table_row${idx + 1}_column3`}>
            <div>{content[`card_type_modal_table_row${idx + 1}_column3`]}</div>
          </Table.Cell>
          <Table.Cell data-content-key={`card_type_modal_table_row${idx + 1}_column4`}>
            <div>{content[`card_type_modal_table_row${idx + 1}_column4`]}</div>
          </Table.Cell>
        </Table.Row>
      ))

  return ReactDOM.createPortal(
    <Modal
      size='lg'
      title={content.card_type_modal_heading}
      dataAttributes={{ 'data-content-key': 'card_type_modal_heading' }}
      onDismiss={onClose}
      primaryAction={{ text: content.card_type_modal_close || 'Close', onClick: onClose }}
      hasCloseButton
      visible
    >
      <Text textAlign='center' data-content-key='card_type_modal_subheading'>
        {content.card_type_modal_subheading}
      </Text>
      <KeyItemContainer>
        <KeyItem>
          <Icon name='Delivery' />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_vehicle_icon_label' }}>
            {content.card_type_modal_vehicle_icon_label}
          </Text>
        </KeyItem>
        <KeyItem>
          <VehiclesBadge />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_vehicles_icon_label' }}>
            {content.card_type_modal_vehicles_icon_label}
          </Text>
        </KeyItem>
        <KeyItem>
          <Icon name='User' />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_driver_icon_label' }}>
            {content.card_type_modal_driver_icon_label}
          </Text>
        </KeyItem>
        <KeyItem>
          <Icon name='People' />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_drivers_icon_label' }}>
            {content.card_type_modal_drivers_icon_label}
          </Text>
        </KeyItem>
        <KeyItem>
          <Icon name='CvcCvv' />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_card_icon_label' }}>
            {content.card_type_modal_card_icon_label}
          </Text>
        </KeyItem>
        <KeyItem>
          <Icon name='Security' />
          <Text dataAttributes={{ 'data-content-key': 'card_type_modal_pin_icon_label' }}>
            {content.card_type_modal_pin_icon_label}
          </Text>
        </KeyItem>
      </KeyItemContainer>
      <Table>
        <Table.Head>
          <Table.Row>{createHeaders()}</Table.Row>
        </Table.Head>
        <Table.Body stripedRowStyle>{createRows()}</Table.Body>
      </Table>
      <Footnote>
        <StateMessage
          text={content.card_type_modal_footnote}
          state='info'
          iconName='Info'
          dataAttributes={{ 'data-content-key': 'card_type_modal_footnote' }}
        />
      </Footnote>
    </Modal>,
    root
  )
}
