import axios from 'axios'
import { useQuery } from 'react-query'

export const QUERY_KEY = 'cards/authority-details-'

type paramType = string | null

const queryFn = async (authorityId: paramType) => {
  if (!authorityId) throw new Error('No Authority Id given to /authoritydetails endpoint')

  try {
    const response = await axios({
      method: 'get',
      url: `/api/cards/authorities/${authorityId}`
    })
    return response?.data
  } catch (error) {
    console.error(error)
  }
}

const useApiGetAuthorityDetails = (authorityId: paramType, options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY + authorityId, () => queryFn(authorityId), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetAuthorityDetails
