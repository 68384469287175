import { action, makeObservable, observable } from 'mobx'
import BaseDataStore from '../BaseDataStore'

class DepotsStore extends BaseDataStore {
  columnSorting = ['endDate', 'desc']
  selectedRows = {}

  constructor(root) {
    super(root, 'depots', 'chargepointoperations-select-depots')

    makeObservable(this, {
      selectedRows: observable,
      setSelectedRows: action
    })
  }

  setSelectedRows(rows) {
    this.selectedRows = rows
  }

  buildDepotChargerRange = chargePoints => {
    if (!chargePoints?.length) return '-'

    const minimumCharge = chargePoints.reduce((acc, val) =>
      acc.lowestConnectorPower < val.lowestConnectorPower ? acc : val
    ).lowestConnectorPower
    const maxCharge = chargePoints.reduce((acc, val) =>
      acc.highestConnectorPower > val.highestConnectorPower ? acc : val
    ).highestConnectorPower

    if (!minimumCharge && !maxCharge) return '-'
    if ((minimumCharge && !maxCharge) || minimumCharge === maxCharge) return minimumCharge
    if (!minimumCharge && maxCharge) return maxCharge

    return `${minimumCharge} - ${maxCharge}`
  }

  get rows() {
    return this.results.map(result => {
      const range = this.buildDepotChargerRange(result.chargePoints)
      return {
        type: 'MASTER',
        key: result.siteId,
        ...result,
        chargers: result.chargePoints.length,
        kwRange: range
      }
    })
  }

  get columns() {
    return [
      { key: 'chargerName', name: this.content?.cpo_charger_name || '[cpo_charger_name]' },
      {
        key: 'authorityId',
        name: this.content?.depot_permissions_site_authority || '[depot_permissions_site_authority]'
      },
      { key: 'siteAddress', name: this.content?.depot_permissions_site_address || '[depot_permissions_site_address]' },
      { key: 'siteCity', name: this.content?.depot_permissions_site_city || '[depot_permissions_site_city]' },
      { key: 'kwRange', name: this.content?.depot_permissions_site_kw_range || '[depot_permissions_site_kw_range]' },
      { key: 'chargers', name: this.content?.depot_permissions_site_chargers || '[depot_permissions_site_chargers]' }
    ]
  }

  async getDepots({ selectedHierarchy, page = 1 }) {
    await super
      .getData({
        method: 'get',
        url: '/api/evis/v1/sites/admin',
        payload: {},
        params: {
          accessLevel: selectedHierarchy.accessLevel,
          accessLevelCode: selectedHierarchy.accessLevelCode
        },
        page
      })
      .then(() => this.setSelectedRows(this.rows.reduce((prev, cur) => ({ ...prev, [cur.key]: true }), {})))
  }
}

export default DepotsStore
