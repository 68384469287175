import { useState } from 'react'
import { Icon } from '@bp-digital/component-icon'
import getBrandImage from 'helpers/getBrandImage'
import { MapMarkerStyled, LocationPin, ButtonCloseStyled, BrandLogo } from './MapMarker.styled'

const MapMarker = ({ lat, lng, marker, brand }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const address = marker?.address?.filter(item => !!item) || []
  const noLocation = (!lat && !lng) || (!marker?.title && !address.length)

  return (
    <MapMarkerStyled>
      {brand && brand.props && brand.props.brand ? (
        <BrandLogo
          src={getBrandImage(brand.props.brand)}
          onClick={e => {
            e.stopPropagation()
            setIsExpanded(!isExpanded)
          }}
        />
      ) : (
        <LocationPin onClick={() => setIsExpanded(!isExpanded)} colour={marker.colour}>
          <Icon name='Pin' size='xxl' state='neutral' />
        </LocationPin>
      )}
      {marker && !noLocation && isExpanded && (
        <div className='marker'>
          <ButtonCloseStyled
            onClick={e => {
              e.stopPropagation()
              setIsExpanded(false)
            }}
          >
            <Icon name='RemoveLarge' size='sm' />
          </ButtonCloseStyled>
          <div>
            {marker.title && <h3>{marker.title}</h3>}
            {marker.address && address.map((line, i) => <p key={i}>{line}</p>)}
            {marker.phoneNumber && (
              <p>
                <br />
                {marker.phoneNumber}
              </p>
            )}
          </div>
        </div>
      )}
    </MapMarkerStyled>
  )
}

export default MapMarker
