import { Badge } from '@bp-digital/component-badge'
import axios from 'axios'
import getChargerType from 'helpers/getChargerType'
import getChargePointStatus from 'helpers/getChargePointStatus'
import BaseDataStore from '../BaseDataStore'
import {
  STATUS_AVAILABLE,
  STATUS_CHARGING,
  STATUS_PARTIALLY_AVAILABLE,
  STATUS_PLANNED,
  STATUS_RESERVED,
  STATUS_UNKNOWN
} from 'src/constants/chargerStatuses'
import FlexibleLink from 'components/navigation/FlexibleLink'
import { ROUTE_CHARGE_POINT } from 'constants/routes'

const getStateFromStatus = status => {
  if (status === STATUS_AVAILABLE) {
    return 'success'
  } else if (
    status === STATUS_CHARGING ||
    status === STATUS_PLANNED ||
    status == STATUS_RESERVED ||
    status === STATUS_UNKNOWN ||
    status === STATUS_PARTIALLY_AVAILABLE
  ) {
    return 'warning'
  }

  return 'danger'
}

class ChargePointsStore extends BaseDataStore {
  columnSorting = ['endDate', 'desc']
  reservedAliases = []

  constructor(root) {
    super(root, 'chargepoints', 'chargepointoperations-view')
  }

  get rows() {
    return (
      this.data
        ?.filter(row => row.chargePoints.length > 0)
        .map(result => {
          const chargePoint = result.chargePoints?.[0] || {}
          const statusAr = []
          chargePoint.evses.forEach(evse => {
            evse.connectors.forEach(connector => statusAr.push(connector.status))
          })

          return {
            type: 'MASTER',
            key: chargePoint.chargePointId,
            ...result,
            chargerName: (
              <FlexibleLink to={`${ROUTE_CHARGE_POINT}?siteId=${result.siteId}&chargerId=${chargePoint.chargePointId}`}>
                {result.siteAlias ? result.siteAlias : chargePoint.chargePointId}
              </FlexibleLink>
            ),
            location: [result.siteAddress, result.siteCity].join(', '),
            maxPower: chargePoint.highestConnectorPower ? chargePoint.highestConnectorPower + 'kW' : '-',
            chargerType: getChargerType(chargePoint.highestConnectorPower, this.content),
            chargerStatusValue: chargePoint.chargePointStatus,
            chargerStatus: (
              <Badge
                text={getChargePointStatus(statusAr, this.content)}
                state={getStateFromStatus(chargePoint.chargePointStatus)}
              />
            )
          }
        }) || []
    )
  }

  get columns() {
    return [
      { key: 'chargerName', name: this.content?.cpo_charger_name || '[cpo_charger_name]' },
      { key: 'location', name: this.content?.cpo_location || '[cpo_location]' },
      { key: 'maxPower', name: this.content?.cpo_max_power_columnheader || '[cpo_max_power_columnheader]' },
      { key: 'chargerType', name: this.content?.cpo_charger_type_columnheader || '[cpo_charger_type_columnheader]' },
      { key: 'chargerStatus', name: this.content?.cpo_status_columnheader || '[cpo_status_columnheader]' }
    ]
  }

  async writeSiteAlias(selectedHierarchy, siteId, siteAlias) {
    await axios({
      method: 'post',
      url: '/api/evis/v1/sites/details/alias',
      data: {
        accessLevel: selectedHierarchy.accessLevel,
        accessLevelCode: selectedHierarchy.accessLevelCode,
        siteId,
        siteAlias
      }
    })
  }

  getReservedAliases() {
    return this.reservedAliases
  }

  async getChargePoints({
    selectedHierarchy,
    searchTerm = this.searchTerm,
    columnSorting = this.columnSorting,
    page = 1
    // forceRefresh = false
    // limit = this.limits?.nextPageLimit || 15
  }) {
    this.setIsFetchingData(true)

    if (!columnSorting.length) {
      columnSorting = ['transactionDateTime', 'desc']
    }

    const params = {
      accessLevel: selectedHierarchy.accessLevel,
      accessLevelCode: selectedHierarchy.accessLevelCode
    }

    const response = await axios({
      method: 'get',
      url: '/api/evis/v1/sites/list',
      params,
      page,
      searchTerm
    })

    this.reservedAliases = response.data && response.data.length ? response.data.map(site => site.siteAlias) : []

    this.setData(response.data)

    this.setIsFetchingData(false)
  }
}

export default ChargePointsStore
