import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import { useRootStore } from 'contexts/StoreContext'
import { UserStoreContext } from 'contexts/StoreContext.types'
import { FilterContext, InvoiceFilterType } from 'pages/Transactions/ArchivedInvoicesPage/ArchivedInvoicesPage'
import { getFilterText } from 'pages/Transactions/ArchivedInvoicesPage/helpers/getAllAppliedFilterBadges'

import { Button } from '@bp-digital/component-button'
import { DropDown } from '@bp-digital/component-drop-down'

import { NumericRangeSlider } from '@bp-digital/component-numeric-range-slider'
import { NumericRange } from '@bp-digital/component-numeric-range-slider/dist/NumericRangeSlider.types'

import getFallbackLanguage from 'helpers/getFallbackLanguage'

import { useApiGetAvailableCurrencies } from 'hooks/api/useApiGetAvailableCurrencies'

const MIN_RANGE_VALUE = 0
const MAX_RANGE_VALUE = 100000
const DEFAULT_MIN_RANGE_VALUE = MIN_RANGE_VALUE + MAX_RANGE_VALUE / 4
const DEFAULT_MAX_RANGE_VALUE = MAX_RANGE_VALUE - MAX_RANGE_VALUE / 4
export const FILTER_NAME = 'amount'

interface IInvoiceAmountFilterProps {
  content: { [key: string]: string }
  removedFilterIds: string[]
}

const DropDownWrapper = styled.div`
  margin: 0 0 3.5rem 0;
  z-index: 2;
  position: relative;
`

const SliderWrapper = styled.div`
  margin: 0 3.5rem 0 1rem;
`

const ButtonWrapper = styled.div`
  /* background-color: purple; */
  margin: 2rem 0 0 0;
`

const InvoiceAmountFilter = ({ content, removedFilterIds }: IInvoiceAmountFilterProps) => {
  interface ICurrencyOptions {
    key: string
    id: string
    label: string
  }

  type TStateNumericRange = {
    from: number
    to: number | null
  }

  const locale = getFallbackLanguage()

  const {
    userStore
  }: {
    userStore: UserStoreContext
  } = useRootStore()
  const authorityId = userStore?.selectedAuthorityId ?? ''

  const { data, isLoading } = useApiGetAvailableCurrencies(authorityId)

  const DEFAULT_ID: string = (!isLoading && data[0]?.currency) || 'GBP'

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [selectedCurrency, setSelectedCurrency] = useState<string>(DEFAULT_ID)
  const [forceRerender, setForceRerender] = useState<boolean>(false)
  const [currencyOptions, setCurrencyOptions] = useState<ICurrencyOptions[]>([])
  const [selectedValues, setSelectedValues] = useState<NumericRange | TStateNumericRange>()
  const [filterAdded, setFilterAdded] = useState<boolean>(false)

  const NO_UPPER_LIMIT_MESSAGE = `${new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: selectedCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(MAX_RANGE_VALUE)}+`

  const { setFilterData } = useContext(FilterContext)

  const onChangeHandler = (values: NumericRange) => {
    setBtnDisabled(false)

    if (values.to === MAX_RANGE_VALUE) {
      setSelectedValues({
        from: values.from,
        to: null
      })
    } else {
      setSelectedValues(values)
    }
  }

  const addFilterHandler = () => {
    setForceRerender(true)

    const meta = {
      locale,
      option: {
        style: 'currency',
        currency: selectedCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      },
      upperLimitText: NO_UPPER_LIMIT_MESSAGE
    }

    const payload = {
      name: FILTER_NAME,
      meta,
      keyGroup: [
        {
          keys: ['from', 'to', 'currency'],
          values: [
            {
              from: String(selectedValues?.from),
              to: selectedValues?.to !== null ? String(selectedValues?.to) : null,
              currency: String(selectedCurrency)
            }
          ]
        }
      ]
    }

    setFilterData((filters: InvoiceFilterType[]) => [
      ...filters,
      {
        type: FILTER_NAME,
        text: getFilterText(payload, content),
        payload
      }
    ])
    setFilterAdded(true)
    setTimeout(() => setForceRerender(false), 0)
  }

  const currencyChangeHandler = (currency: string) => {
    setForceRerender(true)
    if (currency) {
      setSelectedCurrency(currency)
    }

    // setTimeout to help rerender
    setTimeout(() => setForceRerender(false), 0)
  }

  const reset = () => {
    setForceRerender(true)
    setSelectedCurrency(DEFAULT_ID)
    onChangeHandler({ from: DEFAULT_MIN_RANGE_VALUE, to: DEFAULT_MAX_RANGE_VALUE })

    setBtnDisabled(true)
    setFilterAdded(false)
    setTimeout(() => setForceRerender(false), 0)
  }

  useEffect(() => {
    reset()
  }, [removedFilterIds]) //eslint-disable-line

  useEffect(() => {
    if (!isLoading) {
      const options =
        data &&
        data.map(({ currency }: { currency: string }, ind: number) => {
          return {
            key: `${currency}-${ind}`,
            id: currency,
            label: currency
          }
        })
      currencyChangeHandler(options[0]?.id)
      setCurrencyOptions(options)
    }
  }, [isLoading]) // eslint-disable-line

  return (
    <>
      {(isLoading || forceRerender) && <Skeleton />}
      {!isLoading && (
        <>
          {!forceRerender && (
            <DropDownWrapper>
              <DropDown
                id='currency-type'
                selectedId={selectedCurrency}
                onChange={currencySelection => currencyChangeHandler(String(currencySelection))}
                options={currencyOptions}
                disabled={filterAdded}
                placeholder='Select your currency'
              />
            </DropDownWrapper>
          )}
          {!forceRerender && (
            <SliderWrapper>
              <NumericRangeSlider
                onChange={(v: NumericRange) => onChangeHandler(v)}
                from={MIN_RANGE_VALUE}
                to={MAX_RANGE_VALUE}
                defaultValue={{
                  from: selectedValues?.from || DEFAULT_MIN_RANGE_VALUE,
                  to: selectedValues?.to || DEFAULT_MAX_RANGE_VALUE
                }}
                numberFormatOptions={{
                  locale,
                  option: {
                    style: 'currency',
                    currency: selectedCurrency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }
                }}
                noUpperLimitMessage={NO_UPPER_LIMIT_MESSAGE}
                disabled={filterAdded}
              />
            </SliderWrapper>
          )}
          <ButtonWrapper>
            <Button appearance='primary' onClick={addFilterHandler} disabled={btnDisabled || filterAdded}>
              {content?.advancedFilters_add || 'advancedFilters_add'}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </>
  )
}

export default InvoiceAmountFilter
