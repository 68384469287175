import {
  TwoColumnWrapper,
  TagsWrapper,
  ButtonGroupWrapper
} from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { ButtonGroup } from '@bp-digital/component-button-group'
import ProductFields from '../ProductFields'
import { Heading } from '@bp-digital/component-typography'
import { Controller } from 'react-hook-form'
import { Button } from '@bp-digital/component-button'
import React, { useState } from 'react'
import { PRODUCTS_ALLOWED, PRODUCTS_APPLY } from 'constants/restrictions'
import { Switch } from '@bp-digital/component-switch'
import { getProductName } from 'helpers/restrictions'

const IndividualProducts = ({ categoryId, products, watch, content, control, setValue, currency }) => {
  const [selectedProductId, setSelectedProductId] = useState(products[0].productServiceId)
  const fieldPrefix = `categories.${categoryId}.products.${selectedProductId}`
  const isAllow = watch(`${fieldPrefix}.isAllow`) !== PRODUCTS_ALLOWED.NOT_ALLOW
  const isFieldsDisabled = !watch(`${fieldPrefix}.setRestriction`)

  const handleReset = () => {
    setValue(`${fieldPrefix}.dailyMaxVolume`, null)
    setValue(`${fieldPrefix}.dailyMaxValue`, null)
    setValue(`${fieldPrefix}.txnMaxVolume`, null)
    setValue(`${fieldPrefix}.txnMaxValue`, null)
  }

  return (
    <TwoColumnWrapper>
      <div>
        {products.map(p => (
          <TagsWrapper key={p.productServiceName}>
            <Button appearance='secondary' onClick={() => setSelectedProductId(p.productServiceId)}>
              {getProductName(p.productServiceId, content)}
            </Button>
          </TagsWrapper>
        ))}
      </div>
      <div>
        {selectedProductId &&
          products
            .filter(p => p.productServiceId === selectedProductId)
            .map(p => (
              <React.Fragment key={p.productServiceId}>
                <Heading as='h4'>{getProductName(p.productServiceId, content)}</Heading>
                <ButtonGroupWrapper>
                  <Controller
                    name={`${fieldPrefix}.isAllow`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <ButtonGroup
                        id={name}
                        disabled={isFieldsDisabled}
                        items={[
                          {
                            id: PRODUCTS_ALLOWED.ALLOW,
                            label: content?.restriction_site_allow || 'restriction_site_allow'
                          },
                          {
                            id: PRODUCTS_ALLOWED.NOT_ALLOW,
                            label: content?.restriction_site_not_allow || 'restriction_site_not_allow'
                          }
                        ]}
                        defaultSelectedId={value}
                        onChange={value => onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    name={`${fieldPrefix}.setRestriction`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <Switch
                        id={name}
                        name={name}
                        defaultValue={value}
                        text={content?.restriction_date_time_set_restriction || 'restriction_date_time_set_restriction'}
                        onChange={value => onChange(value)}
                      />
                    )}
                  />
                </ButtonGroupWrapper>
                {isAllow && (
                  <ProductFields
                    fieldPrefix={fieldPrefix}
                    content={content}
                    control={control}
                    productServiceType={p.productServiceType}
                    onReset={() => handleReset()}
                    disabled={isFieldsDisabled}
                    currency={currency}
                    isProductsAll={PRODUCTS_APPLY.EACH}
                  />
                )}
              </React.Fragment>
            ))}
      </div>
    </TwoColumnWrapper>
  )
}

export default IndividualProducts
