import { Controller } from 'react-hook-form'
import { LabelWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { TextField } from '@bp-digital/component-text-field'
import { Icon } from '@bp-digital/component-icon'
import { validateAlphaNumericAndSpecialCharacters } from '../../../CardOrderPage/helpers'
import { SEND_TO_ADDRESS } from 'pages/Cards/CardOrderPage/constants/constants'

const CardCostCentre = ({ control, content, isLoading, sendToAddress }) => {
  return (
    <>
      <Controller
        name='region.costcenterName'
        control={control}
        rules={{
          required:
            sendToAddress === SEND_TO_ADDRESS.COST_CENTRE
              ? content?.replacement_card_validation_required || 'replacement_card_validation_required'
              : false,
          maxLength: {
            value: 36,
            message:
              content?.replacement_card_validation_max_length?.replace('{{length}}', 36) ||
              'replacement_card_validation_max_length'
          },
          pattern: {
            value: validateAlphaNumericAndSpecialCharacters,
            message:
              content?.replacement_card_validation_alph_num_special || 'replacement_card_validation_alph_num_special'
          }
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            label={
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_cost_centre' }}>
                  {content?.replacement_card_region_cost_centre || 'replacement_card_region_cost_centre'}
                </Text>

                <Popover
                  appearance='dark'
                  text={
                    content?.replacement_card_region_cost_centre_tooltip ||
                    'replacement_card_region_cost_centre_tooltip'
                  }
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'replacement_card_region_cost_centre_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            }
            textChangeHandler={onChange}
            value={value}
            disabled={isLoading}
            error={!!error?.message}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name='region.subcostcenter'
        control={control}
        rules={{
          maxLength: {
            value: 20,
            message: content?.replacement_card_validation_max_20 || 'replacement_card_validation_max_20'
          },
          pattern: {
            value: validateAlphaNumericAndSpecialCharacters,
            message:
              content?.replacement_card_validation_alph_num_special || 'replacement_card_validation_alph_num_special'
          }
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            label={
              <LabelWrapper>
                <Text display='inline-block' dataAttributes={{ 'data-content-key': 'region_sub_cost_centre' }}>
                  {content?.replacement_card_region_sub_cost_centre || 'replacement_card_region_sub_cost_centre'}
                </Text>

                <Popover
                  appearance='dark'
                  text={
                    content?.replacement_card_region_sub_cost_centre_tooltip ||
                    'replacement_card_region_sub_cost_centre_tooltip'
                  }
                  position='top'
                  dataAttributes={{ ['data-content-key']: 'replacement_card_region_sub_cost_centre_tooltip' }}
                >
                  <Icon name='Info' appearance='dark' size='md' state='neutral' />
                </Popover>
              </LabelWrapper>
            }
            textChangeHandler={onChange}
            value={value}
            disabled={isLoading}
            error={!!error?.message}
            errorMessage={error?.message}
          />
        )}
      />
    </>
  )
}

export default CardCostCentre
