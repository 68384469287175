import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import useCountDown from 'react-countdown-hook'
import { Alert } from '@bp-digital/component-alert'
import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import { Modal } from '@bp-digital/component-modal'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { StateMessage } from '@bp-digital/component-state-message'
import { DropDown } from '@bp-digital/component-drop-down'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { getTimeFromMilliseconds } from 'helpers/getTimeFromMilliseconds'
import { Spacer } from 'styles/common.styled'
import { OverrideWrapper, DropDownWrapper } from './OverrideAlert.styled'
import { queryClient } from 'src'

const root = document.getElementById('root')
const ACTION_EXTEND = 'extend'
const ACTION_END = 'end'

const OverrideAlert = ({ content, override = {} }) => {
  const { endTime, customerName, authorityNumber, customerId, authorityId, acceptViolation, alertViolation } = override
  const durationsToExtend = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [timeLeft, { start }] = useCountDown(new Date(endTime).getTime() - Date.now(), 1000)
  const [hours, minutes, seconds] = getTimeFromMilliseconds(timeLeft).split(':')
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [duration, setDuration] = useState(durationsToExtend[0])
  const [selectedAction, setSelectedAction] = useState(ACTION_EXTEND)
  useEffect(start, []) //eslint-disable-line react-hooks/exhaustive-deps
  const overrideMessage =
    replaceVariablesInString(content?.override_list_authorityname_authoritynumber, {
      customerName,
      authorityNumber
    }) +
    replaceVariablesInString(content?.override_list_name_with_time, {
      displayName: '',
      hours,
      minutes,
      seconds
    })

  const handleUpdate = async () => {
    console.info('Update..')
    setIsUpdating(true)

    try {
      await axios({
        method: 'post',
        url: '/api/cards/profile/overrides',
        data: {
          acceptViolation,
          alertViolation,
          authorityId,
          customerId,
          duration: selectedAction === ACTION_END ? 0 : duration,
          parentId: ''
        }
      })

      setIsUpdating(false)
      setIsEditing(false)
      queryClient.invalidateQueries('cards/profile-overrides')
    } catch (e) {
      console.warn('Error occurred on override edit', e)
      setError('Error occurred')
    }
  }

  return (
    <>
      <Alert
        title={content?.override_list_heading}
        text={content?.override_list_Restriction_profiles_applied}
        iconPrefix
        state='danger'
      >
        <OverrideWrapper>
          <Text>{overrideMessage}</Text>
          <Button appearance='quaternary' onClick={() => setIsEditing(true)}>
            {content?.override_list_edit_override}
          </Button>
        </OverrideWrapper>
      </Alert>
      <Spacer />
      {ReactDOM.createPortal(
        <Modal
          visible={isEditing}
          title='Override profiles'
          primaryAction={{
            text: content.override_profile_apply_button || 'Apply',
            isLoading: isUpdating,
            onClick: handleUpdate
          }}
          secondaryAction={{
            appearance: 'tertiary',
            text: content.override_profile_cancel_button || 'Cancel',
            onClick: () => setIsEditing(false)
          }}
          onDismiss={() => setIsEditing(false)}
        >
          {!!error && <StateMessage state='danger' iconName='Alert' text={error} />}
          <Text>
            {overrideMessage} {content.override_profile_edit_override_title}
          </Text>
          <Spacer />
          <RadioButtonGroup
            options={[
              {
                id: ACTION_EXTEND,
                label: content.override_profile_extend_override || 'Extend override'
              },
              {
                id: ACTION_END,
                label: content.override_profile_end_override_now || 'End override now'
              }
            ]}
            horizontal
            defaultSelectedId={selectedAction}
            onChange={setSelectedAction}
          />
          <Spacer />
          {selectedAction === ACTION_EXTEND && (
            <DropDownWrapper>
              <DropDown
                options={durationsToExtend.map(duration => ({
                  id: duration,
                  label: `${duration} ${
                    duration === 1
                      ? content.override_profiles_duration_hour_singular
                      : content.override_profiles_duration_hour_plural
                  }`
                }))}
                dataAttributes={{
                  'data-testid': 'dropdown-extend-durations'
                }}
                defaultSelectedId={duration}
                onChange={setDuration}
              />
            </DropDownWrapper>
          )}
          {selectedAction === ACTION_END && (
            <StateMessage
              iconName='Alert'
              state='danger'
              text={replaceVariablesInString(content.override_profile_end_override_now_message, {
                level: content.override_profile_warning_message_a || 'authority'
              })}
            />
          )}
        </Modal>,
        root
      )}
    </>
  )
}

export default OverrideAlert
