import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { Heading, Text } from '@bp-digital/component-typography'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Icon } from '@bp-digital/component-icon'
import { Popover } from '@bp-digital/component-popover'
import { InputControlWrapper, LabelWrapper } from '../common/CardOrder.styled'
import DeliveryAddress from '../DeliveryAddress'
import { CARD_ORDER_MODE, GUIDED_CARD_ORDER_STEPS as Steps } from '../../constants/constants'
import Restrictions from './Restrictions'
import { Button } from '@bp-digital/component-button'
import { Spacer } from 'styles/common.styled'
import { StateMessage } from '@bp-digital/component-state-message'

const YES = 'Y'
const NO = 'N'

const Guided = ({
  content,
  authority,
  authorityDetails: { configuration: { cardSetup } = {}, deliveryAddress, sendToAddress, serviceCode } = {},
  authorityDetails,
  isLoading
}) => {
  const history = useHistory()

  const { handleSubmit, unregister, setValue, getValues, control, watch } = useForm({
    mode: 'onBlur'
  })

  useEffect(() => {
    if (!isLoading && authorityDetails) {
      const defaultValues = { cardSetup: { restriction: null } }

      defaultValues.cardSetup.restriction = cardSetup.restriction.defaultValue
      setValue('cardSetup.restriction', defaultValues.cardSetup.restriction)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, authorityDetails])

  // Card Restrictions
  const showRestrictions = cardSetup?.restriction?.shown === YES && getValues('cardSetup.restriction')
  const showRestrictionProfiles = watch('cardSetup.restriction', null) === YES

  const onSubmit = async formData => {
    history.push({
      pathname: Steps.step1,
      state: { formData, authorityDetails }
    })
  }

  return (
    <>
      <Heading as='h2' size='h5' dataAttributes={{ ['data-content-key']: 'setup_heading' }}>
        {content?.setup_heading}
      </Heading>

      <DeliveryAddress
        content={content}
        isLoading={isLoading}
        authority={authority}
        deliveryAddress={deliveryAddress}
        sendTo={sendToAddress}
        mode={CARD_ORDER_MODE.GUIDED}
      />

      <Heading as='h2' size='h5' dataAttributes={{ ['data-content-key']: 'setup_how_setup' }}>
        {content?.setup_how_setup}
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        {showRestrictions && (
          <>
            <LabelWrapper>
              <Text display='inline-block' dataAttributes={{ 'data-content-key': 'setup_set_restriction' }}>
                {content?.setup_set_restriction}
              </Text>

              <Popover
                appearance='dark'
                text={content?.setup_set_restriction_tooltip}
                position='top'
                dataAttributes={{ ['data-content-key']: 'setup_set_restriction_tooltip' }}
              >
                <Icon name='Info' appearance='dark' size='md' state='neutral' />
              </Popover>
            </LabelWrapper>

            <Controller
              name='cardSetup.restriction'
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <RadioButtonGroup
                  id={name}
                  options={[
                    { id: YES, label: content?.yes },
                    { id: NO, label: content?.no }
                  ]}
                  defaultSelectedId={value}
                  onChange={onChange}
                  disabled={isLoading}
                  size='sm'
                  horizontal
                />
              )}
            />

            {showRestrictionProfiles && (
              <Controller
                name='cardSetup.restrictionDetails'
                control={control}
                rules={{
                  required:
                    getValues('cardSetup.restrictionDetails') === undefined
                      ? content?.validation_required || 'validation_required'
                      : false
                }}
                render={({ field: { name, onChange }, fieldState: { error } }) => (
                  <InputControlWrapper>
                    <Restrictions
                      content={content}
                      data={{
                        currency: authorityDetails?.restrictions?.currency,
                        distanceUnit: authorityDetails?.restrictions?.distanceUnit,
                        params: {
                          authorityId: authority.authorityId,
                          groupId: null,
                          parentId: null,
                          searchText: null,
                          serviceCode: [serviceCode]
                        }
                      }}
                      onChange={onChange}
                      unregister={() => unregister(name)}
                      error={error}
                    />
                    {!!error && <StateMessage iconName='Alert' state='danger' text={error.message} />}
                  </InputControlWrapper>
                )}
              />
            )}

            <Spacer size='xxl2' />

            <Button type='submit' disabled={isLoading} dataAttributes={{ 'data-content-key': 'next-step' }}>
              {content?.next_step}
            </Button>
          </>
        )}
      </form>
    </>
  )
}

export default Guided
