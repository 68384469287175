import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const root = document.getElementById('root')

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    root.scrollTop = 0
  }, [pathname])

  return null
}
