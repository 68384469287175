import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const TableAlert = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: ${CssVars.structure.spacing.md};
`
export const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;

  > div > span  {
    font-size: 0.85rem;
    letter-spacing: normal;
    text-transform: capitalize;	
    font-weight: 500;
  }
`
