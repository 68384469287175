import Cookies from 'js-cookie'
import { FUNCTIONAL_COOKIE, PERFORMANCE_COOKIE } from 'constants/cookieConsentTypes'

const getConsentCookies = () => {
  const functionalCookies = !!Cookies.get(FUNCTIONAL_COOKIE)
  const performanceCookies = !!Cookies.get(PERFORMANCE_COOKIE)

  return { functionalCookies, performanceCookies }
}

export default getConsentCookies
