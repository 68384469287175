import {
  ALLOWED_DAYS,
  CUSTOM,
  END_TIME,
  RESTRICTION_TYPES,
  START_TIME,
  WEEKDAYS,
  WEEKENDS
} from 'constants/restrictions'
import { getDayAndTimeRestrictionCode } from 'helpers/restrictions'

const newDayItem = () => ({
  isAllow: ALLOWED_DAYS.ALLOW,
  startTime: START_TIME,
  endTime: END_TIME
})

const setDay = (day, index) => ({ dayOfWeek: index + 1, ...day })

const buildRequest = ({ profileId, profileName, formData: { customDays, daySelection, violationId } }) => {
  const request = {
    restrictionDetail: {
      profileName,
      profileId,
      restrictionTypes: [RESTRICTION_TYPES.DAY_AND_TIME],
      saveProfile: true,
      existingProfile: true,
      dayTime: {
        acceptDayTimeViolation: violationId === 3 ? '0' : '1',
        alertDayTimeViolation: violationId === 1 ? '0' : '1',
        type: getDayAndTimeRestrictionCode(daySelection)
      }
    }
  }

  switch (daySelection) {
    case WEEKDAYS:
      request.restrictionDetail.dayTime.daysWithTime = Array(5).fill(newDayItem()).map(setDay)
      break

    case WEEKENDS:
      request.restrictionDetail.dayTime.daysWithTime = Array(7).fill(newDayItem(), 5).map(setDay).filter(Boolean)
      break

    case CUSTOM:
      request.restrictionDetail.dayTime.daysWithTime = customDays.filter(day => {
        return !day.selectedForRemoval
      })
      break

    default:
      break
  }

  return request
}

export default buildRequest
