import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'

const queryFn = async (payload: unknown, authorityId: string) => {
  const params = {
    accessLevel: 'A',
    accessLevelCode: authorityId
  }
  const response = await axios({
    method: 'put',
    url: '/api/cards/restrictions/profile',
    data: payload,
    params
  })
  return response.data
}

const useApiPostRestrictionUpdate = (
  onSuccess: (arg0: Response) => void,
  onError: (message: string) => void,
  authorityId: string
) => {
  return useMutation<Response, AxiosError>(payload => queryFn(payload, authorityId), {
    mutationKey: 'restriction-update',
    onSuccess: response => {
      onSuccess(response)
    },
    onError: error => {
      const { message } = error
      onError(message)
    },
    retry: false
  })
}

export default useApiPostRestrictionUpdate
