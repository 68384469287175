const formatLocationData = (entry, content, keyPrefix) => {
  if (entry.siteLocation?.globalSiteId !== undefined && entry.siteLocation?.globalSiteId !== 0) {
    return [entry.countryName, entry.areaName, entry.siteLocation.siteName]
  }

  if (entry.areaId !== undefined && entry.areaId !== 0) {
    return [entry.countryName, entry.areaName, content[`${keyPrefix}site_all_sites`]]
  }

  return [entry.countryName, content[`${keyPrefix}site_all_regions`], content[`${keyPrefix}site_all_sites`]]
}

export const formatLocation = (sites, allow, isAllowNotListed, content, keyPrefix) => {
  const locations = sites.filter(site => Number(site.isAllow) === Number(allow))

  const unspecifiedSite = allow === isAllowNotListed ? content[`${keyPrefix}unspecified_sites`] : ['-']

  if (locations.length) {
    return [...locations.map(s => formatLocationData(s, content, keyPrefix)), unspecifiedSite]
  }

  return unspecifiedSite || ['-']
}
