import { ROUTE_CARDS_ORDER, ROUTE_CARDS_ORDER_CONFIRMATION } from 'constants/routes'

export const GUIDED_CARD_ORDER_STEPS = {
  step1: `${ROUTE_CARDS_ORDER}/carddetails`,
  step2: `${ROUTE_CARDS_ORDER}/cardembossing`,
  step3: `${ROUTE_CARDS_ORDER}/region`,
  step4: `${ROUTE_CARDS_ORDER}/pin`,
  step5: `${ROUTE_CARDS_ORDER}/restriction`,
  step6: `${ROUTE_CARDS_ORDER}/summary`,
  step7: ROUTE_CARDS_ORDER_CONFIRMATION
}

const restrictionSteps = {
  restriction1: `${GUIDED_CARD_ORDER_STEPS.step5}/limits`,
  restriction2: `${GUIDED_CARD_ORDER_STEPS.step5}/productscategory`,
  restriction2_1: `${GUIDED_CARD_ORDER_STEPS.step5}/products`,
  restriction3: `${GUIDED_CARD_ORDER_STEPS.step5}/sitecategory`,
  restriction3_1: `${GUIDED_CARD_ORDER_STEPS.step5}/site`,
  restriction4: `${GUIDED_CARD_ORDER_STEPS.step5}/dayTime`,
  restriction5: `${GUIDED_CARD_ORDER_STEPS.step5}/odometer`,
  restriction6: `${GUIDED_CARD_ORDER_STEPS.step5}/summary`,
  restriction7: `${GUIDED_CARD_ORDER_STEPS.step5}/managedepots`
}

GUIDED_CARD_ORDER_STEPS.restrictionSteps = restrictionSteps

export const CARD_ORDER_MODE = {
  GUIDED: 1,
  EXPRESS: 2,
  REPLACEMENT_CARD: 3
}

export const CARD_TYPE = {
  POOL_CARD: { id: 1, contentKey: 'pool_card' },
  DRIVER_CARD: { id: 2, contentKey: 'driver_card' },
  VEHICLE_CARD: { id: 3, contentKey: 'vehicle_card' },
  DRIVER_VEHICLE_CARD: { id: 4, contentKey: 'driver_vehicle_card' },
  DRIVER_PIN_CARD: { id: 5, contentKey: 'driver_pin_card' }
}

export const DRIVER_CARD_ERROR_TYPE = 2

export const COUNTRY_CODES = {
  GERMANY: 'DE',
  UNITED_KINGDOM: 'UK'
}

export const SEND_TO_ADDRESS = {
  CUSTOMER: '1',
  CARD_HOLDER: '2',
  COST_CENTRE: '6',
  PARENT: '7'
}

export const DISPATCH_ADDRESS_DETAILS = {
  addressLine1: 'line1',
  addressLine2: 'line2',
  addressLine3: 'line3',
  postCode: 'postCode'
}

export const NATIONAL = 'National'
export const INTERNATIONAL = 'Nationalinternational'
