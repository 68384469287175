import { observer } from 'mobx-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/navigation/AuthenticatedRoute'
import RedirectPage from './pages/Redirect'
import Dashboard from './pages/DashboardPage'
import Transactions from './pages/Transactions/TransactionsPage'
import Invoices from './pages/Transactions/InvoicesPage'
import ArchivedInvoices from './pages/Transactions/ArchivedInvoicesPage'
import LoginPage from './pages/Authentication/LoginPage'
import RegisterPage from './pages/Authentication/RegisterPage'
import RegisterDetailsPage from './pages/Authentication/RegisterDetailsPage'
import AccessUserPage from './pages/AccessAdministration/User/AccessUserPage'
import ViewUserPage from './pages/AccessAdministration/User/ViewUserPage'
import AddUserPage from './pages/AccessAdministration/User/AddUserPage'
import AddUserToGroupPage from './pages/AccessAdministration/User/AddUserToGroupPage'
import HandleLoginPage from './pages/Authentication/HandleLoginPage'
import {
  ROUTE_ACCESS_ADMINISTRATION_USER,
  ROUTE_ACCESS_ADMINISTRATION_USER_ADD,
  ROUTE_ACCESS_ADMINISTRATION_USER_ADD_TO_GROUP,
  ROUTE_ACCESS_ADMINISTRATION_GROUP,
  ROUTE_ACCOUNT,
  ROUTE_AUDIT,
  ROUTE_CARDS_ORDER,
  ROUTE_CARDS_ORDER_CONFIRMATION,
  ROUTE_CARDS_VIEW,
  ROUTE_CARDS_REISSUE,
  ROUTE_CARDS_RESTRICTIONS,
  ROUTE_CARDS_SPENDING_LIMITS,
  ROUTE_REDIRECT,
  ROUTE_DASHBOARD,
  ROUTE_INVOICES,
  ROUTE_ADMIN,
  ROUTE_ADMIN_SCENARIOS,
  ROUTE_ARCHIVED_INVOICES,
  ROUTE_LOGIN,
  ROUTE_LOGIN_INTERNAL,
  ROUTE_REGISTER,
  ROUTE_REGISTER_DETAILS,
  ROUTE_TRANSACTIONS,
  ROUTE_COOKIE_PREFERENCES,
  ROUTE_CHARGE_HISTORY,
  ROUTE_CHARGE_POINTS,
  ROUTE_CHARGE_POINT,
  ROUTE_DEPOT_PERMISSIONS,
  ROUTE_DEPOT_PERMISSIONS_SELECT_DEPOTS,
  ROUTE_CARDS_RESTRICTIONS_NEW,
  ROUTE_CARDS_RESTRICTIONS_EDIT,
  ROUTE_LOGOUT,
  ROUTE_EV_PRICE_LIST,
  ROUTE_ONEFLEET_MIGRATEDUSERSPAGE
} from './constants/routes'
import MasterLayout from './components/layout/MasterLayout'
import SessionExpiry from './components/feedback/SessionExpiry/SessionExpiry'
import AccountPage from './pages/AccountPage'
import AuditPage from './pages/AuditPage'
import CardOrderPage from './pages/Cards/CardOrderPage'
import CardOrderConfirmationPage from 'pages/Cards/CardOrderPage/components/CardOrderConfirmationPage'
import ViewManageCards from './pages/Cards/ViewManageCards'
import CardsForReissuePage from './pages/Cards/CardsForReissuePage'
import CardsRestrictionsPage from './pages/Cards/Restrictions/CardsRestrictionsPage'
import RestrictionProfilePage from 'pages/Cards/Restrictions/RestrictionProfilePage'
import CardsSpendingLimitsPage from './pages/Cards/CardsSpendingLimitsPage'
import CardsSpendingLimitsEditPage from './pages/Cards/CardsSpendingLimitsEditPage'
import GroupAdministrationPage from './pages/AccessAdministration/Group/GroupAdministrationPage'
import CookiePreferencesPage from './pages/CookiePreferencesPage'
import { useRootStore } from 'contexts/StoreContext'
import ChargeHistory from 'pages/Electric/ChargeHistory'
import ViewChargePoints from 'pages/Electric/ViewChargePoints'
import DepotPermissions from 'pages/Electric/DepotPermissions'
import SelectDepots from 'pages/Electric/SetDepotPermissions'
import ChargePointPage from 'pages/Electric/ChargePointPage'
import CookieBanner from 'components/cookieConsent/CookieBanner'
import NewRestrictionProfilePage from 'pages/Cards/Restrictions/NewRestrictionProfilePage'
import EditRestrictionProfilePage from 'pages/Cards/Restrictions/EditRestrictionProfilePage'
import CardDetails from 'pages/Cards/CardOrderPage/components/Guided/CardDetails'
import { GUIDED_CARD_ORDER_STEPS } from 'pages/Cards/CardOrderPage/constants/constants'
import ScrollToTop from 'components/navigation/ScrollToTop'
import AdminPage from 'pages/Admin/AdminPage'
import AdminScenariosPage from 'pages/Admin/AdminScenariosPage'
import LogoutPage from 'pages/Authentication/LogoutPage/LogoutPage'
import EVPriceListPage from 'pages/Transactions/EVPriceListPage'
import MigratedUsersPage from 'pages/Authentication/MigratedUsersPage'

const Routes = () => {
  const { userStore } = useRootStore()
  const allowwritepermissions = !(userStore.onefleetmigratedstatus > 0)
  const evPriceListScreen = true
  const allowAccess = !(userStore.onefleetmigratedstatus > 3 && userStore.isonefleetmigrated)
  return (
    <Router basename={userStore.locale} key={userStore.locale}>
      <ScrollToTop />
      <CookieBanner />
      <Switch>
        <Route exact path={[ROUTE_LOGIN, '/login.html']}>
          <LoginPage />
        </Route>
        <Route exact path={[ROUTE_LOGIN_INTERNAL]}>
          <LoginPage />
        </Route>
        <Route exact path={['/', '/securitycheck.html']}>
          <HandleLoginPage />
        </Route>
        <Route exact path={[ROUTE_REGISTER, '/register.html', '/registration.html']}>
          <RegisterPage />
        </Route>
        <Route exact path={ROUTE_REGISTER_DETAILS}>
          <RegisterDetailsPage />
        </Route>
        <AuthenticatedRoute exact path={ROUTE_ONEFLEET_MIGRATEDUSERSPAGE}>
          <MigratedUsersPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={ROUTE_LOGOUT}>
          <LogoutPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={ROUTE_ADMIN}>
          <SessionExpiry />
          <AdminPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={ROUTE_ADMIN_SCENARIOS}>
          <SessionExpiry />
          <AdminScenariosPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={ROUTE_REDIRECT}>
          <SessionExpiry />
          <RedirectPage />
        </AuthenticatedRoute>
        <>
    { allowAccess ? (
        <Route path='/:base'>
          <SessionExpiry />
          <MasterLayout>
            <Switch>
              <AuthenticatedRoute path={ROUTE_DASHBOARD}>
                <Dashboard />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={ROUTE_TRANSACTIONS}>
                <Transactions />
              </AuthenticatedRoute>
              {evPriceListScreen && (
                <AuthenticatedRoute path={ROUTE_EV_PRICE_LIST}>
                  <EVPriceListPage />
                </AuthenticatedRoute>
              )}
              <AuthenticatedRoute exact path={ROUTE_INVOICES}>
                <Invoices />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ARCHIVED_INVOICES}>
                <ArchivedInvoices />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CARDS_ORDER}>
                <CardOrderPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CARDS_ORDER_CONFIRMATION}>
                <CardOrderConfirmationPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step1}>
                <CardDetails />
              </AuthenticatedRoute>
              {/* <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step2}>

              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step3}>

              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step4}>

              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step5}>

              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step6}>

              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={GUIDED_CARD_ORDER_STEPS.step7}>

              </AuthenticatedRoute> */}
              <AuthenticatedRoute exact path={ROUTE_CARDS_VIEW}>
                <ViewManageCards />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CARDS_REISSUE}>
                <CardsForReissuePage />
              </AuthenticatedRoute>
              {allowwritepermissions && 
              (<AuthenticatedRoute exact path={ROUTE_CARDS_RESTRICTIONS}>
                <CardsRestrictionsPage />
              </AuthenticatedRoute>)}
              <AuthenticatedRoute exact path={ROUTE_CARDS_RESTRICTIONS_NEW}>
                <NewRestrictionProfilePage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={`${ROUTE_CARDS_RESTRICTIONS}/:profileId`}>
                <RestrictionProfilePage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CARDS_RESTRICTIONS_EDIT}>
                <EditRestrictionProfilePage />
              </AuthenticatedRoute>
              {allowwritepermissions && 
              (
              <AuthenticatedRoute exact path={ROUTE_CARDS_SPENDING_LIMITS}>
                <CardsSpendingLimitsPage />
              </AuthenticatedRoute>)}
              <AuthenticatedRoute exact path={`${ROUTE_CARDS_SPENDING_LIMITS}/:spendingLimitId`}>
                <CardsSpendingLimitsEditPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ACCESS_ADMINISTRATION_USER}>
                <AccessUserPage displayHeader={true} />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ACCESS_ADMINISTRATION_USER_ADD}>
                <AddUserPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ACCESS_ADMINISTRATION_USER_ADD_TO_GROUP}>
                <AddUserToGroupPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={`${ROUTE_ACCESS_ADMINISTRATION_USER}/:userId`}>
                <ViewUserPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ACCESS_ADMINISTRATION_GROUP}>
                <GroupAdministrationPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_ACCOUNT}>
                <AccountPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_AUDIT}>
                <AuditPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CHARGE_POINTS}>
                <ViewChargePoints />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CHARGE_POINT}>
                <ChargePointPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_DEPOT_PERMISSIONS}>
                <DepotPermissions />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_DEPOT_PERMISSIONS_SELECT_DEPOTS}>
                <SelectDepots />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path={ROUTE_CHARGE_HISTORY}>
                <ChargeHistory />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={ROUTE_COOKIE_PREFERENCES}>
                <CookiePreferencesPage />
              </AuthenticatedRoute>
            </Switch>
          </MasterLayout>
        </Route>
        ):(
          <AuthenticatedRoute path={ROUTE_ONEFLEET_MIGRATEDUSERSPAGE}>
            <MigratedUsersPage />
          </AuthenticatedRoute>
        )}
        </>
      </Switch>
    </Router>
  )
}

export default observer(Routes)
