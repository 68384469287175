import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  padding: 8px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
  }
`
export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px;
  margin: 8px;
  background-color: var(--color-grey-regular-50);
`

export const Link = styled.a`
  display: inline-block;
  cursor: pointer;
`
