import CountryFlag from 'components/dataDisplay/DataTable/components/CountryFlag'
import { ArchivedInvoice, SummaryStatement } from 'hooks/api/useApiGetArchivedInvoices'
import { InvoiceTableRow } from '../../ArchivedInvoicesPage.types'

export const buildTableDataFromSummaries = (isLoading: boolean, data?: SummaryStatement[]): InvoiceTableRow[] => {
  if (isLoading) {
    return Array(5)
      .fill({})
      .map((val, index) => ({ ...val, key: `loading-${index}` }))
  }

  const output = data
    ? data.map(item => {
        const subRows = item.invoices.map(invoice => ({
          ...invoice,
          key: `${invoice.invoiceNumber}-${invoice.invoiceType}`,
          supplyCountryImage: <CountryFlag countryCode={invoice.supplyCountry} altTag={invoice.supplyCountry} />
        }))
        return {
          ...item,
          key: `${item.invoiceNumber}-${item.invoiceType}`,
          supplyCountryImage: <CountryFlag countryCode={item.supplyCountry} altTag={item.supplyCountry} />,
          subRows
        }
      })
    : []

  return output
}

export const buildTableDataFromInvoices = (isLoading: boolean, data?: ArchivedInvoice[]): InvoiceTableRow[] => {
  if (isLoading) {
    return Array(5)
      .fill({})
      .map((val, index) => ({ ...val, key: `loading-${index}` }))
  }

  const output = data
    ? data.map(item => {
        return {
          ...item,
          key: `${item.invoiceNumber}-${item.invoiceType}`,
          supplyCountryImage: <CountryFlag countryCode={item.supplyCountry} altTag={item.supplyCountry} />,
          subRows: []
        }
      })
    : []

  return output
}
