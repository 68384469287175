import { useRootStore } from 'contexts/StoreContext'
import { Button } from '@bp-digital/component-button'
import { AUDIT_DOMAINS, AUDIT_ACTION_TYPES } from './../AuditFilters/AuditFiltersNodes'

import {
  ActionWrapper,
  DomainLabel,
  ActionList,
  ActionListItem,
  ActionLabel,
  ActionButtons
} from './UserActionTypeFilter.styled'

const UserActionTypeFilter = props => {
  const { userStore } = useRootStore()
  // filter domain as per user permissions
  const allowedDomains = AUDIT_DOMAINS.filter(domain => userStore.userPermissions.includes(domain.permissionKey))

  const allowedActionTypes = AUDIT_ACTION_TYPES.filter(action =>
    allowedDomains.find(domainObj => domainObj.domain == action.domain)
  )

  const handleSelection = userActionTypeId => {
    let filters = props.filters
    const userActionTypeFilter = filters.find(fltr => fltr.filterBy == 'useractiontypes') || {
      filterBy: 'useractiontypes',
      filterCriteria: []
    }
    if (userActionTypeFilter.filterCriteria.includes(userActionTypeId)) {
      userActionTypeFilter.filterCriteria = userActionTypeFilter.filterCriteria.filter(
        fltrCrt => fltrCrt != userActionTypeId
      )
    } else {
      userActionTypeFilter.filterCriteria.push(userActionTypeId)
    }
    filters = filters.filter(fltr => fltr.filterBy != 'useractiontypes')
    filters.push(userActionTypeFilter)
    props.setFilters(filters)
  }

  const currValues = props.filters.find(fltr => fltr.filterBy == 'useractiontypes')
    ? props.filters.find(fltr => fltr.filterBy == 'useractiontypes').filterCriteria
    : []

  return (
    <ActionWrapper>
      <div>
        {allowedDomains.map(domain => (
          <div key={`${domain.domain}Domain`}>
            <DomainLabel>{domain.label}</DomainLabel>
            <ActionList>
              {allowedActionTypes
                .filter(action => action.domain == domain.domain && !(action.key == 'USML' && props.displayLogins))
                .map(action => (
                  <ActionListItem key={action.key}>
                    <ActionLabel>{action.label}</ActionLabel>
                    <ActionButtons>
                      <div>
                        <Button
                          key={`${action.key}Success`}
                          onClick={() => handleSelection(`${action.key}_1`)}
                          appearance={
                            !currValues.length || currValues.includes(`${action.key}_1`) ? 'primary' : 'secondary'
                          }
                          borderRadius='default'
                        >
                          Success
                        </Button>
                      </div>
                      <div>
                        <Button
                          key={`${action.key}Failed`}
                          onClick={() => handleSelection(`${action.key}_0`)}
                          appearance={
                            !currValues.length || currValues.includes(`${action.key}_0`) ? 'primary' : 'secondary'
                          }
                          borderRadius='default'
                        >
                          Failed
                        </Button>
                      </div>
                    </ActionButtons>
                  </ActionListItem>
                ))}
            </ActionList>
          </div>
        ))}
      </div>
    </ActionWrapper>
  )
}

export default UserActionTypeFilter
