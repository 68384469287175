import styled from 'styled-components'

export const OverrideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DropDownWrapper = styled.div`
  max-width: 60%;
  margin-bottom: 15px;
`
