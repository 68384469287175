import useApiGetProductCategories from 'hooks/api/useApiGetProductCategories'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import { Heading, Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { ButtonWrapper, Wrapper, TileWrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { Spacer } from 'styles/common.styled'
import React, { useEffect, useState } from 'react'
import { TileSelector } from '@bp-digital/component-tile-selector'
import { getCategoryName, getViolationId } from 'helpers/restrictions'
import { AccordionPanel } from '@bp-digital/component-accordion-panel'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { useForm, Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import AllProducts from './components/AllProducts'
import IndividualProducts from './components/IndividualProducts'
import { PRODUCTS_ALLOWED, PRODUCTS_NOT_LISTED, PRODUCTS_APPLY, PRODUCTS_STEP } from 'constants/restrictions'
import formatProductsDefaultValues from './helpers/formatProductsDefaultValues'
import useApiPostRestrictionProfileUpdate from 'hooks/api/useApiPostRestrictionProfileUpdate'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import buildRequest from './helpers/buildRequest'
import { StateMessage } from '@bp-digital/component-state-message'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { MODE } from 'constants/restrictions'
import Skeleton from 'react-loading-skeleton'

const MAX_PRODUCT_SELECTION = 10

const ProductsAndServices = ({
  authorityId,
  content,
  data,
  mode = MODE.EDIT,
  onBack,
  onNext,
  defaultValues,
  title
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { profileId, profileName, currency, productAndServicesRestriction = {}, serviceCode } = data || {}
  const {
    categories,
    acceptProductServiceViolation,
    alertProductServiceViolation,
    isAllowNotListed: allowNotListed
  } = productAndServicesRestriction || {}
  const categoryIds = categories ? categories.map(category => category.categoryId) : []

  const {
    data: productCategories,
    isSuccess,
    isLoading
  } = useApiGetProductCategories(serviceCode, authorityId, {
    enabled: !!serviceCode
  })

  const [violationId, setViolationId] = useState(
    getViolationId(acceptProductServiceViolation, alertProductServiceViolation)
  )
  const [isAllowNotListed, setIsAllowNotListed] = useState(
    (allowNotListed === true && PRODUCTS_NOT_LISTED.ALLOW) || PRODUCTS_NOT_LISTED.NOT_ALLOW
  )

  const [selectedCategoryIds, setSelectedCategoryIds] = useState(categoryIds)
  const [activeStep, setActiveStep] = useState(PRODUCTS_STEP.ONE)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      categories: {
        ...(categories && categories.length !== 0 ? Object.assign(...formatProductsDefaultValues(categories)) : {})
      },
      ...(defaultValues ? defaultValues : {})
    }
  })

  useEffect(() => {
    if (defaultValues) {
      setViolationId(defaultValues.violationId)
      setIsAllowNotListed(defaultValues.isAllowNotListed)
      setSelectedCategoryIds(Object.keys(defaultValues.categories).map(Number))
    }
  }, [defaultValues])

  const applyForAllProducts = id => watch(`categories.${id}.applyForAllProductsInCategory`) === PRODUCTS_APPLY.ALL
  const getCurrentSelectedProducts = id => watch(`categories.${id}.products`)

  const handleTileChange = (value, category) => {
    if (!value) return setSelectedCategoryIds(selectedCategoryIds.filter(id => id !== category.categoryId))
    if (!selectedCategoryIds.includes(category.categoryId)) {
      setValue(`categories.${category.categoryId}.applyForAllProductsInCategory`, PRODUCTS_APPLY.ALL)
      setValue(`categories.${category.categoryId}.isAllow`, PRODUCTS_ALLOWED.ALLOW)
      return setSelectedCategoryIds([...selectedCategoryIds, category.categoryId])
    }
  }

  const { mutate: submitForm } = useApiPostRestrictionProfileUpdate(
    () => {
      setIsSubmitting(false)
      queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`)
    },
    error => {
      setIsSubmitting(false)
      setError(
        content?.restriction_profiles_update_notification_error_singular ||
          'restriction_profiles_update_notification_error_singular'
      )

      console.error(error)
    },
    authorityId
  )

  const checkMaxLimitReached = () => {
    let totalCount = 0
    selectedCategoryIds.map(id => {
      totalCount = totalCount + getSelectionCount(id)
    })

    return totalCount > MAX_PRODUCT_SELECTION ? true : false
  }

  const getSelectionCount = id => {
    let count = 0
    if (applyForAllProducts(id)) {
      count = 1
      return count
    }

    const products = getCurrentSelectedProducts(id)
    if (products) {
      Object.entries(products).forEach(([key, product]) => {
        if (key && product.setRestriction) {
          count = count + 1
        }
      })
    }
    return count
  }

  const onSubmit = formData => {
    if (checkMaxLimitReached()) {
      setError(
        replaceVariablesInString(content?.restriction_products_max_selection, {
          count: MAX_PRODUCT_SELECTION
        })
      )
      return
    } else if (error) {
      setError(false)
    }
    setIsSubmitting(true)
    const payload = buildRequest({
      profileId,
      profileName,
      formData,
      violationId,
      isAllowNotListed,
      productCategories,
      selectedCategoryIds
    })
    submitForm(payload)
  }

  const handleOnCreate = formData => {
    if (checkMaxLimitReached()) {
      setError(
        replaceVariablesInString(content?.restriction_products_max_selection, {
          count: MAX_PRODUCT_SELECTION
        })
      )
      return
    } else if (error) {
      setError(null)
    }
    onNext({ categories: formData.categories, violationId, isAllowNotListed, productCategories, selectedCategoryIds })
  }

  return (
    <Wrapper>
      {title && <Heading as='h3'>{title}</Heading>}
      {activeStep === PRODUCTS_STEP.ONE && (
        <>
          <ViolationsSelector content={content} onChange={id => setViolationId(id)} defaultSelectedId={violationId} />
          <Spacer />
          <Text size='lg'>
            {content?.restriction_products_select_category || 'restriction_products_select_category'}
          </Text>
          <Spacer />
          <TileWrapper>
            {isSuccess &&
              productCategories
                .filter(item => item.products.length > 0)
                .map(item => (
                  <TileSelector
                    key={item.categoryId}
                    id={item.categoryId}
                    text={`${getCategoryName(item.categoryId, content)} (${item.products.length})`}
                    defaultChecked={selectedCategoryIds.some(id => id === item.categoryId)}
                    onChange={value => handleTileChange(value, item)}
                  />
                ))}
            {isLoading &&
              Array(4)
                .fill()
                .map((item, index) => <Skeleton key={index} height={140} width={140} />)}
          </TileWrapper>
          <ButtonWrapper>
            {mode === MODE.CREATE && (
              <Button onClick={onBack} appearance='tertiary'>
                {content?.previous_step}
              </Button>
            )}
            <Button onClick={() => setActiveStep(PRODUCTS_STEP.TWO)} disabled={!isSuccess}>
              {content?.next_step}
            </Button>
          </ButtonWrapper>
        </>
      )}
      {activeStep === PRODUCTS_STEP.TWO && (
        <>
          <Spacer />
          {productCategories
            .filter(item => selectedCategoryIds.includes(item.categoryId))
            .map(item => (
              <React.Fragment key={item.categoryId}>
                <AccordionPanel
                  title={`${getCategoryName(item.categoryId, content)} (${getSelectionCount(item.categoryId)})`}
                >
                  <Controller
                    name={`categories.${item.categoryId}.applyForAllProductsInCategory`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <RadioButtonGroup
                        id={name}
                        horizontal
                        options={[
                          {
                            label: content?.restriction_products_apply_all || 'restriction_products_apply_all',
                            id: PRODUCTS_APPLY.ALL
                          },
                          {
                            label:
                              content?.restriction_products_apply_individual || 'restriction_products_apply_individual',
                            id: PRODUCTS_APPLY.EACH
                          }
                        ]}
                        defaultSelectedId={value}
                        onChange={value => {
                          onChange(value)
                          if (value === PRODUCTS_APPLY.EACH) {
                            item.products.forEach(p => {
                              setValue(
                                `categories.${item.categoryId}.products.${p.productServiceId}.setRestriction`,
                                true
                              )
                              setValue(
                                `categories.${item.categoryId}.products.${p.productServiceId}.isAllow`,
                                PRODUCTS_ALLOWED.ALLOW
                              )
                            })
                          }
                        }}
                      />
                    )}
                  />
                  {applyForAllProducts(item.categoryId) ? (
                    <AllProducts
                      category={item}
                      control={control}
                      content={content}
                      isAllow={watch(`categories.${item.categoryId}.isAllow`) === PRODUCTS_ALLOWED.ALLOW}
                      setValue={setValue}
                      currency={currency}
                    />
                  ) : (
                    <IndividualProducts
                      categoryId={item.categoryId}
                      products={item.products}
                      watch={watch}
                      control={control}
                      content={content}
                      setValue={setValue}
                      currency={currency}
                    />
                  )}
                </AccordionPanel>
                <Spacer />
              </React.Fragment>
            ))}

          <RadioButtonGroup
            id='isAllowNotListed'
            horizontal
            label={content?.restriction_products_not_selected}
            options={[
              {
                label: content?.restriction_products_allow_transcation || 'restriction_products_allow_transcation',
                id: PRODUCTS_NOT_LISTED.ALLOW
              },
              {
                label:
                  content?.restriction_products_not_allow_transcation || 'restriction_products_not_allow_transcation',
                id: PRODUCTS_NOT_LISTED.NOT_ALLOW
              }
            ]}
            defaultSelectedId={isAllowNotListed}
            onChange={value => setIsAllowNotListed(value)}
          />
          {error && (
            <>
              <Spacer />
              <StateMessage iconName='Alert' state='danger' text={error} />
            </>
          )}
          <ButtonWrapper>
            <Button appearance='tertiary' onClick={() => setActiveStep(PRODUCTS_STEP.ONE)}>
              {content?.previous_step}
            </Button>
            {mode === MODE.EDIT && (
              <Button onClick={handleSubmit(onSubmit)} isLoading={isSubmitting} disabled={isSubmitting || !isValid}>
                {content?.restriction_profiles_rename_profile_primary_button}
              </Button>
            )}
            {mode === MODE.CREATE && (
              <Button
                onClick={handleSubmit(handleOnCreate)}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid}
              >
                {content?.next_step}
              </Button>
            )}
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default ProductsAndServices
