import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY_PREFIX = 'cards/transactions-'

type paramType = string | null

const queryFn = async (authorityId: paramType, cardId: paramType) => {
  if (!authorityId) throw new Error('No Authority Id given to /cards/transactions endpoint')
  try {
    const response = await axios({
      method: 'get',
      url: `/api/transactions/transactions/card/${cardId}?count=3&AuthorityIds=${authorityId}`
    })

    return response.data
  } catch (error) {
    console.error(error)
  }
}

const useApiGetCardTransactions = (authorityId: paramType, cardId: paramType, options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY_PREFIX + cardId, () => queryFn(authorityId, cardId), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetCardTransactions
