import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const WidgetContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${CssVars.color.grey.regular300};
  padding: 16px;
`

export const WidgetHeader = styled.div`
  display: flex;
  border-bottom: 1px dashed ${CssVars.color.grey.regular300};
  padding-bottom: 16px;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
  }
`

export const WidgetTitles = styled.div`
  display: flex;
  flex-direction: column;
`

export const WidgetTitle = styled.h3`
  margin: 4px 0 4px 0;
  font-size: 24px;
`

export const WidgetSubtitle = styled.p`
  display: flex;
  margin: 0;
  font-size: 16px;
  font-weight: ${CssVars.typography.weight.light};
`

export const WidgetActions = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;

  @media only screen and ${breakpoints.device.lg} {
    padding-top: 0;
  }
`

export const WidgetBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
  }
`

export const Totals = styled.div`
  padding: 16px 0 0;
  text-align: center;
`

export const TotalVat = styled.p<{ $isOverall?: boolean }>`
  font-size: ${props => (props.$isOverall ? '28.5px' : '24px')};
  margin: 0 0 4px;

  grid-column: 2;
`

export const TotalExVat = styled.p`
  font-size: 16px;
  font-weight: ${CssVars.typography.weight.light};
  margin: 0;

  grid-column: 2;
`

export const ZeroMessage = styled(TotalExVat)`
  margin: 24px 0;
`

export const Recent = styled.div`
  display: flex;
`

export const Key = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  flex-direction: column;
`

export const KeyCircle = styled.span<{ $color: string }>`
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: ${props => props.$color};
`

export const ProductName = styled.p`
  margin: 2px 0 8px;
  grid-column: 2;
`

export const ProductQuantity = styled.p`
  margin: 8px 0;
  grid-column: 2;
`

export const Flag = styled.img`
  height: 18px;
  margin-right: 8px;
`
