import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;

  &:nth-child(even) {
    background-color: var(--color-grey-regular-50);
  }

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 16px;
  flex: 1;
`

export const Wrapper = styled.div`
  margin-top: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: column;
  padding: 0 16px;
`
