import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '@bp-digital/component-button'
import { useRootStore } from 'contexts/StoreContext'
import { ROUTE_TRANSACTIONS } from 'constants/routes'
import InvoiceSummaryDetails from '../InvoiceSummaryDetails'
import { LoadingSkeletonContainer, SummaryActions, SummaryTitle } from './InvoiceSummary.styled'
import Skeleton from 'react-loading-skeleton'

const InvoiceSummary = ({
  invoiceDate,
  summaryStatementId,
  invoiceNumber,
  content,
  isParentRowSelected,
  instanceToggleRowSelected
}) => {
  const { invoicesStore } = useRootStore()
  const data = invoicesStore.detail[summaryStatementId]
  const [isLoading, setIsLoading] = useState(!data)
  const [isViewingDetails, setIsViewingDetails] = useState(false)

  useEffect(() => {
    const getSummary = async () => {
      await invoicesStore.getSummary(summaryStatementId)
      // Toggle sub rows once loaded and ids are known
      if (isParentRowSelected) {
        const invoiceIds = invoicesStore.detail[summaryStatementId].reduce((prev, cur) => [...prev, cur.invoiceId], [])
        invoiceIds.forEach((_invoiceId, index) => {
          instanceToggleRowSelected(`${summaryStatementId}-child-${index}`)
        })
      }
      setIsLoading(false)
    }

    if (isLoading) {
      getSummary()
    }
  }, [summaryStatementId, instanceToggleRowSelected, invoicesStore, isLoading, isParentRowSelected])

  if (isLoading) {
    return (
      <LoadingSkeletonContainer>
        <Skeleton />
      </LoadingSkeletonContainer>
    )
  }

  return (
    <SummaryTitle id={summaryStatementId}>
      {isViewingDetails && (
        <InvoiceSummaryDetails id={summaryStatementId} content={content} onClose={() => setIsViewingDetails(false)} />
      )}

      <SummaryActions>
        <Button
          onClick={() => setIsViewingDetails(true)}
          dataAttributes={{
            'data-testid': 'button-view-summary-details'
          }}
        >
          {content?.view_summary_details || 'View details'}
        </Button>
        <Button
          to={`${ROUTE_TRANSACTIONS}?searchTerm=${invoiceNumber}&columnName=summaryStatementNumber&invoiceDate=${invoiceDate}`}
          dataAttributes={{
            'data-testid': 'button-view-summary-transactions'
          }}
        >
          {content?.summary_transactions || 'View transactions'}
        </Button>
      </SummaryActions>
    </SummaryTitle>
  )
}

export default observer(InvoiceSummary)
