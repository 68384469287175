/** PUBLIC **/
export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGIN_INTERNAL = '/login/internal'
export const ROUTE_LOGOUT = '/logout'
export const ROUTE_COOKIE_PREFERENCES = '/cookie-preferences'
export const ROUTE_REGISTER = '/register'
export const ROUTE_REGISTER_DETAILS = '/register/details'

/** AUTHENTICATED **/
export const ROUTE_REDIRECT = '/redirect'
export const ROUTE_DASHBOARD = '/dashboard'

export const ROUTE_ADMIN = '/admin'
export const ROUTE_ADMIN_SCENARIOS = '/admin/scenarios'
export const ROUTE_ONEFLEET_MIGRATEDUSERSPAGE = '/migratedusers'

// trans & invoices
export const ROUTE_TRANSACTIONS = '/transactions'
export const ROUTE_INVOICES = '/invoices'
export const ROUTE_ARCHIVED_INVOICES = '/invoices/archive'
export const ROUTE_EV_PRICE_LIST = '/evpricelist'

// account
export const ROUTE_ACCOUNT = '/account'

// audit
export const ROUTE_AUDIT = '/audit'

// notifications
export const ROUTE_NOTIFICATIONS = '/notifications'

// cards
export const ROUTE_CARDS = '/cards'
export const ROUTE_CARDS_VIEW = '/cards/view'
export const ROUTE_CARDS_ORDER = '/cards/order'
export const ROUTE_CARDS_ORDER_CONFIRMATION = '/cards/order/confirmation'
export const ROUTE_CARDS_REISSUE = '/cards/reissue'
export const ROUTE_CARDS_RESTRICTIONS = '/cards/restrictions'
export const ROUTE_CARDS_RESTRICTIONS_NEW = '/cards/restrictions/new'
export const ROUTE_CARDS_RESTRICTIONS_EDIT = '/cards/restrictions/:profileId/edit/:type?'
export const ROUTE_CARDS_SPENDING_LIMITS = '/cards/limits'

// access
export const ROUTE_ACCESS_ADMINISTRATION_USER = '/access/users'
export const ROUTE_ACCESS_ADMINISTRATION_USER_ADD = '/access/users/new'
export const ROUTE_ACCESS_ADMINISTRATION_USER_ADD_TO_GROUP = '/access/users/add-to-group'
export const ROUTE_ACCESS_ADMINISTRATION_GROUP = '/access/groups'

// ev
export const ROUTE_CHARGE_POINTS = '/charge-points'
export const ROUTE_CHARGE_POINT = '/charge-points/charger'
export const ROUTE_DEPOT_PERMISSIONS = '/depot-permissions/select-cards'
export const ROUTE_DEPOT_PERMISSIONS_SELECT_DEPOTS = '/depot-permissions/select-depots'
export const ROUTE_CHARGE_HISTORY = '/charging-history'
