import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const AlertWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => (props.$isVisible ? '16px' : '0px')};

  @media only screen and ${breakpoints.device.sm} {
    width: fit-content;
  }
`
