import { useMemo } from 'react'
import { useTable } from 'react-table'
import { Table } from '@bp-digital/component-table'
import './style.css'

// eslint-disable-next-line @typescript-eslint/member-delimiter-style
const EVTable = ({ data, date }: { data: any; date: any }) => {
  const columns = useMemo(
    () => [
      {
        Header: date,
        Cell: (item: any) => item.row.original.date
      },
      ...(data ?? []).map((item: any) => ({
        Header: item.productName,
        Cell: item.price + ' ' + item.unit
      }))
    ],
    [data]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <Table {...getTableProps()}>
      <Table.Head>
        {headerGroups?.map(headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map(column => (
              <Table.Header {...column.getHeaderProps()} key={column.id} scope='col' role='columnheader'>
                {column.render('Header')}
              </Table.Header>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <Table.Body className='ev-table-body' {...getTableBodyProps()}>
        {rows.slice(0, 1).map(row => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <Table.Row {...row.getRowProps()}>
              {row.cells.map(cell => (
                // eslint-disable-next-line react/jsx-key
                <Table.Cell className='ev-table-cell' {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </Table.Cell>
              ))}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default EVTable
