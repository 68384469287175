import { Link } from 'react-router-dom'

const FlexibleLink = ({ to, href, children, ...props }) => {
  if (to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <a href={href} {...props}>
      {children}
    </a>
  )
}

export default FlexibleLink
