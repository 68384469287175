import { useState } from 'react'
import { useQuery } from 'react-query'
import { Modal } from '@bp-digital/component-modal'
import { SortableList } from '@bp-digital/component-sortable-list'
import { Alert } from '@bp-digital/component-alert'
import { Checkbox } from '@bp-digital/component-checkbox'
import FormControl from '@mui/material/FormControl'
import { Text } from '@bp-digital/component-typography'
import { Wrapper, ItemWrapper, AlertWrapper } from './PreferencesEditor.styled'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import Skeleton from 'react-loading-skeleton'

const CheckboxLoader = () => <Skeleton width='100%' height={20} style={{ padding: '8px 0' }} />

const PreferencesEditor = ({
  setIsOpen,
  initialSelectedColumns,
  locale,
  onUpdate,
  content = {},
  sectionName,
  getAvailableColumns,
  columnLabelPrefix = 'columnHeading_'
}) => {
  let { data, isLoading, error } = useQuery(`${sectionName}-columns`, getAvailableColumns, { staleTime: 120000 })
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [hasExceededSelectionLimit, setHasExceededSelectionLimit] = useState(false)
  const [isSorting, setIsSorting] = useState(false)
  const [hasUpdateError, setHasUpdateError] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  // dirty, necessary hack to remove broken fields
  if (sectionName === 'cards' && data) {
    data = data.filter(name => name !== 'cardStatus')
  }

  const contentAndStateMapper = key => ({
    key,
    id: key,
    label: content[columnLabelPrefix + key] || '...',
    selected: selectedColumns.includes(key),
    disabled: false
  })

  const handleCheckboxChange = (checked, col) => {
    const isSelected = !checked
    const legalSelectedColumns = selectedColumns.filter(col => data.includes(col))

    if (isSelected) {
      const newColumns = legalSelectedColumns.filter(s => s !== col.key)
      setSelectedColumns(newColumns)

      if (newColumns.length <= 10) {
        setHasExceededSelectionLimit(false)
      }
    } else if (legalSelectedColumns.length < 10) {
      setSelectedColumns([...legalSelectedColumns, col.key])
      setHasExceededSelectionLimit(false)
    } else {
      setHasExceededSelectionLimit(true)
    }
  }

  return (
    <Modal
      visible
      hasCloseButton
      onDismiss={() => setIsOpen(false)}
      size='lg'
      title={isSorting ? content.columnsModal_step2_heading : content.columnsModal_step1_heading}
      primaryAction={{
        text: isSorting
          ? content.columnsModal_step2_nextButton || 'Update'
          : content.columnsModal_step1_nextButton || 'Next',
        dataAttributes: {
          'data-testid': isSorting ? 'button-update' : 'button-next'
        },
        isLoading: isUpdating,
        disabled: isLoading || error || isUpdating,
        iconName: isSorting ? 'Save' : 'RightLarge',
        iconPosition: isSorting ? 'start' : 'end',
        onClick: async () => {
          if (isSorting) {
            setIsUpdating(true)

            try {
              await onUpdate(selectedColumns)
              setIsSorting(false)
              setIsUpdating(false)
              setIsOpen(false)
            } catch (e) {
              console.warn('Something went wrong updating preferences', e)
              setIsUpdating(false)

              if (isSorting) {
                setHasUpdateError(true)
              }
            }
          } else {
            const legalSelectedColumns = selectedColumns.filter(col => data.includes(col))
            setSelectedColumns(legalSelectedColumns)
            setHasExceededSelectionLimit(false)
            setIsSorting(true)
          }
        }
      }}
      secondaryAction={{
        text: isSorting ? content.masthead_back || 'Back' : content.cancel_Waiting || 'Cancel',
        iconName: isSorting ? 'LeftLarge' : 'RemoveLarge',
        appearance: 'tertiary',
        onClick: () => (isSorting ? setIsSorting(false) : setIsOpen(false)),
        dataAttributes: {
          'data-testid': isSorting ? 'button-back' : 'button-cancel'
        }
      }}
      footerContent={
        hasExceededSelectionLimit && (
          <AlertWrapper $withHorizontalSpacing>
            <Alert
              text={replaceVariablesInString(content.columnsModal_warning || '', { maxSelectCount: 10 })}
              state='warning'
              iconPrefix
            />
          </AlertWrapper>
        )
      }
    >
      {!isSorting && (
        <>
          <Text>
            {replaceVariablesInString(content.columnsModal_step1_tooltip, {
              maxSelectCount: 10
            })}
          </Text>
          <FormControl style={{ display: 'flex' }}>
            <Wrapper>
              {(isLoading || error) && (
                <>
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                  <CheckboxLoader />
                </>
              )}
              {data &&
                data
                  .map(contentAndStateMapper)
                  .sort((a, b) => new Intl.Collator(locale).compare(a.label, b.label))
                  .sort((a, b) => b.selected - a.selected)
                  .map(col => (
                    <ItemWrapper key={col.key}>
                      <Checkbox
                        id={col.label}
                        label={col.label}
                        defaultChecked={col.selected}
                        onChange={newValue => handleCheckboxChange(newValue, col)}
                        name={col.label}
                        disabled={col.disabled}
                      />
                    </ItemWrapper>
                  ))}
            </Wrapper>
          </FormControl>
        </>
      )}

      {isSorting && (
        <>
          <SortableList
            key={`list-${data.length}`}
            label={content?.columnsModal_step2_subhead || 'Select a row to order it by moving it up or down'}
            options={selectedColumns.map(contentAndStateMapper)}
            onChange={columns => setSelectedColumns(columns.map(col => col.id))}
          />
          {hasUpdateError && (
            <AlertWrapper>
              <Alert
                text='Failed to update preferences. Please try again.'
                state='error'
                iconPrefix
                dataAttributes={{
                  'data-testid': 'alert-selection-limit-exceeded'
                }}
              />
            </AlertWrapper>
          )}
        </>
      )}
    </Modal>
  )
}

export default PreferencesEditor
