import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  background-color: var(--color-grey-regular-100);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const FooterNav = styled.nav`
  display: flex;
`

export const FooterMenu = styled.ul`
  list-style: none;
`

export const FooterMenuItem = styled.li`
  display: block;
  margin-top: 8px;
`

export const Title = styled.h6`
  color: #222;
  margin-top: 16px;
  margin-bottom: 8px;
`

export const Copyright = styled.p`
  margin: 0;
  color: var(--color-grey-regular-600);
  font-size: var(--font-size-small);
`

export const SocialIcons = styled.ul`
  display: flex;
  list-style: none;
  margin-top: 16px;

  @media only screen and ${breakpoints.device.lg} {
    margin-top: 0;
  }
`

export const SocialIconItem = styled.li`
  display: inline-block;
`

export const SocialIconLink = styled.a`
  display: block;
  color: var(--color-grey-regular-600);
  text-decoration: none;
`

export const FooterNotice = styled.div`
  text-align: left;

  @media only screen and ${breakpoints.device.lg} {
    text-align: right;
  }
`
