import Skeleton from 'react-loading-skeleton'

const getLoadingRows = (keysOfRow = [], numberOfRows = 15) => {
  const loadingRows = []

  for (let rows = 0; rows < numberOfRows; rows++) {
    const item = {
      type: 'MASTER',
      key: `loading-${rows}`
    }

    keysOfRow.forEach(rowKey => {
      item[rowKey] = <Skeleton width={50} />
    })

    loadingRows.push(item)
  }

  return loadingRows
}

export default getLoadingRows
