const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode) {
    case 'EUR':
      return '€'
    case 'GBP':
      return '£'
    case 'USD':
      return '$'
    case 'PLN':
      return 'PLN '
    default:
      return '€'
  }
}

export default getCurrencySymbol
