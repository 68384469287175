import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  width: 100%;
  display: block;

  @media only screen and ${breakpoints.device.lg} {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 16px;
  }
`

export const AlertWrapper = styled.div`
  margin-bottom: 20px;
`
