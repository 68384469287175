import { useState } from 'react'
import { Button } from '@bp-digital/component-button'
import { ROUTE_TRANSACTIONS } from 'constants/routes'
import { SummaryActions, SummaryTitle } from './SimpleInvoice.styled'
import SimpleInvoiceSummaryDetails from './SimpleInvoiceSummaryDetails'

const SimpleInvoice = ({ invoiceNumber, invoiceDate, content, invoiceId }) => {
  const [isViewingDetails, setIsViewingDetails] = useState(false)

  return (
    <SummaryTitle>
      <SummaryActions>
        <Button
          onClick={() => setIsViewingDetails(true)}
          dataAttributes={{
            'data-testid': 'button-view-invoice-details'
          }}
        >
          {content?.view_invoice_details || 'view_invoice_details'}
        </Button>
        <Button
          to={`${ROUTE_TRANSACTIONS}?searchTerm=${invoiceNumber}&columnName=invoiceNumber&invoiceDate=${invoiceDate}`}
          dataAttributes={{
            'data-testid': 'button-view-invoice-transactions'
          }}
        >
          {content?.invoice_transactions || 'invoice_transactions'}
        </Button>
      </SummaryActions>
      {isViewingDetails && (
        <SimpleInvoiceSummaryDetails id={invoiceId} content={content} onClose={() => setIsViewingDetails(false)} />
      )}
    </SummaryTitle>
  )
}

export default SimpleInvoice
