import { useEffect, useState } from 'react'
import { Wrapper, Link, SubSideNavItem, SubSideNav, SubSideNavItemLink } from './SideNavItem.styled'
import { Icon } from '@bp-digital/component-icon'

const SideNavItem = ({
  title,
  icon,
  testId,
  to,
  subItems = [],
  isActive = false,
  isChildOpen = false,
  brand,
  userPermissions = [],
  toggleMobileMenu
}) => {
  const hasChildren = !!subItems?.length
  const [isSubNavOpen, setIsSubNavOpen] = useState(false)

  useEffect(() => {
    if (isChildOpen) setIsSubNavOpen(true)
  }, [isChildOpen])

  const onClick = event => {
    if (hasChildren) {
      event.preventDefault()
      setIsSubNavOpen(!isSubNavOpen)
    } else {
      toggleMobileMenu()
    }
  }

  return (
    <Wrapper>
      <Link to={to} $isActive={isActive || isChildOpen} onClick={onClick} data-testid={testId} $brand={brand}>
        {icon && <Icon name={icon} />}
        {title}
        {hasChildren && <Icon name={isSubNavOpen ? 'DownSmall' : 'RightSmall'} />}
      </Link>
      {hasChildren && (
        <SubSideNav isOpen={isSubNavOpen}>
          {subItems
            .filter(item => {
              const hasPermissionForNavItem =
                !item.requiredPermissions ||
                item.requiredPermissions.every(requiredPermission => userPermissions.includes(requiredPermission))
              return hasPermissionForNavItem
            })
            .map(item => (
              <SubSideNavItem key={item.path + item.title}>
                <SubSideNavItemLink
                  to={item.path}
                  onClick={toggleMobileMenu}
                  data-testid={item.testId}
                  $isActive={window.location.pathname.includes(item.path)}
                  $brand={brand}
                >
                  {item.title}
                </SubSideNavItemLink>
              </SubSideNavItem>
            ))}
        </SubSideNav>
      )}
    </Wrapper>
  )
}

export default SideNavItem
