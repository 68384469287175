import styled from 'styled-components'

interface SortingDirection {
  isSortedDesc: boolean | undefined
}

const SortIconUp = styled.div<SortingDirection>`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${({ isSortedDesc }) => (isSortedDesc === undefined || isSortedDesc ? '#a1a1a1' : '#111111')};
  margin: 0 0 5px 0;
`
const SortIconDown = styled.div<SortingDirection>`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${({ isSortedDesc }) => (isSortedDesc ? '#111111' : '#a1a1a1')};
`

export const SortIcon = ({ isSortedDesc }: SortingDirection) => {
  return (
    <div>
      <SortIconUp isSortedDesc={isSortedDesc} />
      <SortIconDown isSortedDesc={isSortedDesc} />
    </div>
  )
}
