import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const AdminMenuContainer = styled.section`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 8px 16px;
  gap: 2px;
`

export const SingleUserViewWrapper = styled.div`
  position: relative;

  display: flex;
  width: 100%;
  padding: 5px 5px 25px;
  gap: 5px;
  flex-direction: column;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
  }
`

export const SingleUserRawDataWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border: 1px #ccc solid;
  padding: 5px;
  flex-direction: column;

  h2 {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
    font-weight: normal;
  }

  ul {
    display: flex;
    gap: 0px 20px;
    flex-wrap: wrap;
  }

  li {
    display: flex;
    margin-top: 12px;
    flex-direction: row;
    list-style: none;

    div:first-child {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  @media only screen and ${breakpoints.device.lg} {
    width: 350px;
    padding: 5px 5px 25px;

    ul {
      flex-direction: column;
      flex-wrap: no-wrap;
    }

    li {
      flex-direction: column;
    }
  }
`

export const NoDataWrapper = styled.div`
  padding: 0 40px 32px;
`

export const SingleUserMainDataWrapper = styled.div`
  position: relative;
  display: flex;

  border: 1px #ccc solid;
  padding: 10px;
  width: 100%;
  flex-direction: column;
`
export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
    margin-bottom: 16px;
  }
`

export const SearchWithTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  @media only screen and ${breakpoints.device.sm} {
    width: 300px;
  }

  /* Tooltip */
  span {
    flex-basis: 0;
  }

  span[aria-hidden='false'] {
    white-space: normal;
    width: 80vw;
  }

  @media only screen and ${breakpoints.device.sm} {
    span[aria-hidden='false'] {
      white-space: normal;
      width: 500px;
      max-width: 50vw;
    }
  }
`

export const LinkWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 8px;

  & > a {
    display: flex;
    align-items: center;
    color: ${CssVars.palette.neutral.white};

    &:visited {
      color: ${CssVars.palette.neutral.white};
    }
  }
`

export const BackButtonWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 8px;

  & > a {
    display: flex;
    align-items: center;
    margin-left: -8px;
    color: ${CssVars.palette.neutral.white};

    &:visited {
      color: ${CssVars.palette.neutral.white};
    }
  }
`

export const ScenarioWrapper = styled.div`
  padding: 20px 16px;

  > div:first-child {
    font-weight: 700;
    font-style: italic;
    border: 2px #666 solid;
    background-color: #666;
    color: white;
    padding: 5px 10px 2px;

    span {
      font-weight: normal;
    }
  }

  > div:not(:first-child) {
    padding: 10px;
    border: 2px #666 solid;
  }
`

export const DropDownWrapper = styled.div`
  padding: 20px 20px 4px;
`
