import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const RestrictionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media only screen and ${breakpoints.device.lg} {
    grid-template-columns: 1fr 1fr;
  }
`

export const BadgeList = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction === 'vertical' ? 'column' : '')};
  flex-wrap: wrap;
  gap: 0.75rem;
  width: fit-content;
`

export const TopActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.md};
  padding-top: ${CssVars.structure.spacing.md};

  @media only screen and ${breakpoints.device.lg} {
    flex-direction: row;
    padding-top: 0;
  }
`

export const CenteredText = styled.p`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`
