const getSiteTypes = (allowedSiteGroupIds, deniedSiteGroupIds, content) => {
  return [
    ...allowedSiteGroupIds.map(id => ({
      siteGroupId: id,
      siteGroupDesc: content ? content[`restriction_site_group_${id}`] : `restriction_site_group_${id}`,
      value: true
    })),
    ...(deniedSiteGroupIds.length > 0 &&
      deniedSiteGroupIds.map(id => ({
        siteGroupId: id,
        siteGroupDesc: content ? content[`restriction_site_group_${id}`] : `restriction_site_group_${id}`,
        value: false
      })))
  ]
}

export default getSiteTypes
