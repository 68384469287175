const getChargePointStatus = (statusArray = [], content) => {
    if (!statusArray.length) return ''
    const chargePointStatusSortOrder = ['AVAILABLE', 'CHARGING', 'RESERVED', 'BLOCKED', 'INOPERATIVE', 'OUTOFORDER', 'UNKNOWN']
    
    let combinedStatuses = chargePointStatusSortOrder.map(status => ({
        statusTxt: content?.[`cpo_charger_status_${status}`] || status,
        count: statusArray.filter(el => el.toUpperCase() === status).length 
    }))

    return combinedStatuses
        .filter(combinedStatus => combinedStatus.count > 0)
        .map(combinedStatus => (`${combinedStatus.statusTxt} (${combinedStatus.count})`))
        .join(', ')
}

export default getChargePointStatus
