import { Icon } from '@bp-digital/component-icon'
import useContent from 'hooks/useContent'
import { useCallback } from 'react'
import {
  FlowchartBranch,
  FlowchartHorizontalLine,
  FlowchartTitle,
  FlowchartVerticalLine,
  FlowchartWrapper,
  Answer
} from '../SetBaseProfile/SetBaseProfile.styled'

interface FlowchartQuestionAnswer {
  question: {
    'data-content-key': string
    title: string
  }
  answer: {
    'data-content-key': string
    title: string
  }
}

export const Flowchart = () => {
  const content = useContent('cards-restrictions') || {}

  const chartTemplate = useCallback(
    ({ question, answer }: FlowchartQuestionAnswer) => (
      <>
        <FlowchartTitle data-content-key={question['data-content-key']}>{question.title}</FlowchartTitle>
        <FlowchartVerticalLine margin='auto' />
        <FlowchartHorizontalLine />
        <FlowchartBranch>
          <FlowchartVerticalLine />
          <FlowchartVerticalLine />
        </FlowchartBranch>
        <FlowchartBranch margin='0 1.5%'>
          <Icon name='RemoveLarge' appearance='medium' size='md' state='neutral' />
          <Icon name='CheckLarge' appearance='medium' size='md' state='neutral' />
        </FlowchartBranch>
        <FlowchartBranch>
          <FlowchartVerticalLine />
          <FlowchartVerticalLine />
        </FlowchartBranch>
        <FlowchartBranch margin='0 0 0 5%'>
          <FlowchartVerticalLine />
          <Answer data-content-key={answer['data-content-key']}>{answer.title}</Answer>
        </FlowchartBranch>
        <FlowchartVerticalLine margin='0 0 0 5%' />
      </>
    ),
    []
  )

  return (
    <FlowchartWrapper>
      {/* Is there a profile directly applied to card? */}
      {chartTemplate({
        question: { 'data-content-key': 'flowChart_is_direct_profile', title: content.flowChart_is_direct_profile },
        answer: { 'data-content-key': 'flowChart_card_level_limiting', title: content.flowChart_card_level_limiting }
      })}

      {/* Is a base profile applied at authority level? */}
      {chartTemplate({
        question: {
          'data-content-key': 'flowChart_is_authority_level_profile',
          title: content.flowChart_is_authority_level_profile
        },
        answer: {
          'data-content-key': 'flowChart_authority_level_limiting',
          title: content.flowChart_authority_level_limiting
        }
      })}

      {/* {Is a base profile applied at parent level?} */}
      {chartTemplate({
        question: {
          'data-content-key': 'flowChart_is_parent_level_profile',
          title: content.flowChart_is_parent_level_profile
        },
        answer: {
          'data-content-key': 'flowChart_parent_level_limiting',
          title: content.flowChart_parent_level_limiting
        }
      })}

      {/* Default answer not limited by any profile */}
      <FlowchartTitle data-content-key='flowChart_not_limited_by_any_profile'>
        {content.flowChart_not_limited_by_any_profile}
      </FlowchartTitle>
    </FlowchartWrapper>
  )
}
