import Skeleton from 'react-loading-skeleton'
import { ActionCard } from '@bp-digital/component-action-card'
import SingleRestrictionDetails from 'pages/Cards/Restrictions/components/SingleRestrictionDetails'
import { CenteredText } from '../../RestrictionProfilePage.styled'
import { LoadingWrapper } from './RestrictionBlock.styled'

const RestrictionBlock = ({ title, buttons, isLoading = false, restriction, violationName, content = {}, formattedRestrictions = [] }) => {
  return (
    <ActionCard title={title} buttons={buttons}>
      {restriction ? (
        <SingleRestrictionDetails
          restriction={restriction}
          formattedRestrictions={formattedRestrictions}
          violationName={violationName}
          content={content}
        />
      ) : isLoading ? (
        <>
          <Skeleton width='80%' height={35} />

          <LoadingWrapper>
            <Skeleton width='75%' height={35} />
            <Skeleton width='75%' height={35} />
            <Skeleton width='75%' height={35} />
            <Skeleton width='75%' height={35} />
            <Skeleton width='75%' height={35} />
            <Skeleton width='75%' height={35} />
          </LoadingWrapper>
        </>
      ) : (
        <CenteredText>{content.no_restriction_applied}</CenteredText>
      )}
    </ActionCard>
  )
}

export default RestrictionBlock
