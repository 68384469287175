/**
 * Tabular data or downloaded data will sub in
 * a '-' when an API value is null or undefined
 * this helper can sensibly fall back to this 
 * for these cases, checking for non-positive
 * int values
 */
const getValueOrFallback = (value) => {
  if (value) {
    return value
  }

  const valueAsInt = parseInt(value, 10)

  if (!isNaN(valueAsInt)) {
    return value.toString()
  }

  return '-'
}

export default getValueOrFallback
