import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import { Icon } from '@bp-digital/component-icon'
import { Link } from '@bp-digital/component-link'
import useContent from 'src/hooks/useContent'
import {
  Wrapper,
  FooterNav,
  FooterMenu,
  FooterMenuItem,
  SocialIcons,
  SocialIconItem,
  SocialIconLink,
  Title,
  Copyright,
  FooterNotice
} from './Footer.styled'

const Footer = () => {
  const content = useContent('footer')

  if (!content) {
    return (
      <Wrapper>
        <Skeleton width={200} height={100} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <FooterNav aria-label='site-quick-links'>
        <FooterMenu>
          <FooterMenuItem>
            <Link href={content.contactus_url} dataAttributes={{ 'data-testid': 'footer-contact-us' }}>
              {content.contactus}
            </Link>
          </FooterMenuItem>
          <FooterMenuItem>
            <Link href={content.legalnotice_url} dataAttributes={{ 'data-testid': 'footer-legal-notice' }}>
              {content.legalnotice}
            </Link>
          </FooterMenuItem>
          <FooterMenuItem>
            <Link href={content.globalsite_url} dataAttributes={{ 'data-testid': 'footer-global-site' }}>
              {content.globalsite}
            </Link>
          </FooterMenuItem>
          <FooterMenuItem>
            <Link href={content.privacy_url} dataAttributes={{ 'data-testid': 'footer-privacy' }}>
              {content.privacy}
            </Link>
          </FooterMenuItem>
          <FooterMenuItem>
            <Link to='/cookie-preferences' dataAttributes={{ 'data-testid': 'footer-cookie-preferences' }}>
              {content.cookie_preferences}
            </Link>
          </FooterMenuItem>
        </FooterMenu>
      </FooterNav>

      <FooterNotice>
        <SocialIcons>
          <SocialIconItem>
            <SocialIconLink href={content.social_twitter_url} data-testid='footer-social-twitter'>
              <Icon name='SocialTwitter' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialIconLink href={content.social_linkedIn_url} data-testid='footer-social-linkedin'>
              <Icon name='SocialLinkedIn' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialIconLink href={content.social_instagram_url} data-testid='footer-social-instagram'>
              <Icon name='SocialInstagram' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialIconLink href={content.social_youtube_url} data-testid='footer-social-youtube'>
              <Icon name='SocialYoutube' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialIconLink href={content.social_flickr_url} data-testid='footer-social-flickr'>
              <Icon name='SocialFlickr' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialIconLink href={content.social_facebook_url} data-testid='footer-social-facebook'>
              <Icon name='SocialFacebook' size='xl' />
            </SocialIconLink>
          </SocialIconItem>
        </SocialIcons>

        <Title>{content.footertitle}</Title>
        <Copyright>{content.copyright}</Copyright>
      </FooterNotice>
    </Wrapper>
  )
}

export default observer(Footer)
