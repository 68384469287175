import ReactDOM from 'react-dom'
import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Button } from '@bp-digital/component-button'
import { AlertModal } from '@bp-digital/component-alert-modal'
import { useRootStore } from 'contexts/StoreContext'
import { ROUTE_CARDS_RESTRICTIONS } from 'constants/routes'
import { QUERY_KEY } from 'hooks/api/useApiGetRestrictionProfile'

const root = document.getElementById('root')

const UnlinkRestrictionProfile = ({
  content = {},
  profileId,
  profileName,
  authorityId,
  profile = {},
  serviceCode,
  notificationMessage = () => undefined
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { cardsRestrictionsStore } = useRootStore()
  const [isUnlinking, setIsUnlinking] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const confirmationText = content.restriction_profiles_update_message || 'restriction_profiles_update_message'

  const handleUnlinkProfile = async () => {
    setIsUnlinking(true)

    const payload = {
      cardRestrictionDetails: [
        {
          cardId: null,
          isUnlink: true,
          isDelete: false,
          isProfileNameChanged: false,
          profileId: +profileId,
          profileName,
          parentId: null,
          groupId: null,
          authorityId,
          userLevel: 'A',
          accessId: `A${authorityId}`,
          authorityServiceCode: +serviceCode,
          parentServiceCode: null
        }
      ]
    }

    try {
      const params = {
        accessLevel: 'A',
        accessLevelCode: authorityId
      }

      const response = await axios({
        method: 'put',
        url: '/api/cards/restrictions/profile',
        data: payload,
        params
      })

      const { successCardCount, successProfileCount: profileCount } = response.data

      let unlinkMessage =
        content?.restriction_profiles_unlink_notification_success_singular ||
        'restriction_profiles_unlink_notification_success_singular'

      if (profileCount === 1 && successCardCount === 1) {
        unlinkMessage =
          content?.restriction_profiles_unlink_notifcation_single_success_singular?.replace('{{amount}}', '1') ||
          'restriction_profiles_unlink_notifcation_single_success_singular'
      } else if (profileCount === 1 && successCardCount > 1) {
        unlinkMessage =
          content?.restriction_profiles_unlink_notifcation_single_success_plural?.replace(
            '{{amount}}',
            successCardCount
          ) || 'restriction_profiles_unlink_notifcation_single_success_plural'
      } else if (profileCount > 0 && successCardCount === 1) {
        unlinkMessage =
          content?.restriction_profiles_unlink_notifcation_single_card_multiple_success_plural?.replace(
            '{{amount}}',
            profileCount
          ) || 'restriction_profiles_unlink_notifcation_single_card_multiple_success_plural'
      } else if (profileCount > 0 && successCardCount > 1) {
        unlinkMessage =
          content?.restriction_profiles_unlink_notifcation_multiple_success_plural
            ?.replace('{{cardsCount}}', successCardCount)
            ?.replace('{{profileCount}}', profileCount) || 'restriction_profiles_unlink_notifcation_multiple_success'
      }

      queryClient.invalidateQueries(`${QUERY_KEY}${profileId}`)
      await cardsRestrictionsStore.getRestrictions({ authorityId, forceRefresh: true })

      setIsUnlinking(false)
      setIsOpen(false)

      history.push(ROUTE_CARDS_RESTRICTIONS, {
        globalNotification: {
          data: {
            title: profileName,
            content: unlinkMessage,
            autoClose: 5000
          }
        }
      })
    } catch (e) {
      setIsUnlinking(false)
      setIsOpen(false)

      notificationMessage({
        title: content?.try_again_text,
        content: content?.error || 'Sorry, an error occurred',
        autoClose: 5000
      })
      console.error(e)
    }
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        dataAttributes={{ 'data-content-key': 'restriction_profiles_unlink_profile' }}
        disabled={profile.noOfCards === 0}
        size='lg'
        iconName='Link'
      >
        {content.restriction_profiles_unlink_profile}
      </Button>
      {ReactDOM.createPortal(
        <AlertModal
          visible={isOpen}
          size='md'
          state='warning'
          title={content.restriction_profiles_unlink_message_singular || 'restriction_profiles_unlink_message_singular'}
          text={confirmationText}
          onDismiss={() => setIsOpen(false)}
          primaryAction={{
            text: content.restriction_removal_confirm || 'Confirm',
            onClick: handleUnlinkProfile,
            isLoading: isUnlinking
          }}
          secondaryAction={{
            text: content.restriction_removal_cancel || 'Cancel',
            appearance: 'secondary',
            onClick: () => setIsOpen(false)
          }}
        />,
        root
      )}
    </>
  )
}

export default UnlinkRestrictionProfile
