export const getTimeFromDate = (date, includeSeconds = false) => {
  const time = date.split(' ')[1]

  if (!time) return includeSeconds ? '00:00:00' : '00:00'

  if (includeSeconds) {
    return time
  } else {
    return time.split(':', 2).join(':')
  }
}
