import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { heightOfMobileTopNav } from '../TopNav/TopNav.styled'
import breakpoints from 'src/styles/breakpoints'

export const widthOfSideNav = '300px'

export const Wrapper = styled.nav`
  display: ${props => (props.isOpenOnMobile ? 'block' : 'none')};
  width: 100vw;
  height: calc(100vh - ${heightOfMobileTopNav});
  background-color: var(--color-grey-regular-700);
  overflow-y: auto;
  position: fixed;
  top: calc(${heightOfMobileTopNav} - 1px);
  z-index: 300;

  @media only screen and ${breakpoints.device.lg} {
    width: ${widthOfSideNav};
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
  }
`

export const StyledSkeleton = styled(Skeleton)`
  display: none !important;

  @media only screen and ${breakpoints.device.lg} {
    display: block !important;
    margin: calc(${heightOfMobileTopNav} + 8px) auto 24px;
  }
`

export const Logo = styled.img`
  display: none;

  @media only screen and ${breakpoints.device.lg} {
    display: block;
    width: 100px;
    padding: calc(${heightOfMobileTopNav} + 8px) 0 24px;
    margin: 0 auto;
    box-sizing: content-box;
  }
`

export const SideNavItems = styled.ul`
  list-style: none;
  display: block;
  width: 100%;
  margin-top: 24px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;

  > span {
    > span {
      opacity: 0;
      animation: fadeInOut 4s ease-in-out infinite;
    }

    > span:nth-child(2) {
      animation-delay: .5s;
    }

    > span:nth-child(3) {
      animation-delay: 1s;
    }

    > span:nth-child(4) {
      animation-delay: 1.5s;
    }

    > span:nth-child(5) {
      animation-delay: 2s;
    }
  }
`
