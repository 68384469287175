import { Heading, Text } from '@bp-digital/component-typography'
import { Controller, useForm } from 'react-hook-form'
import { DropDown } from '@bp-digital/component-drop-down'
import { Button } from '@bp-digital/component-button'
import Skeleton from 'react-loading-skeleton'
import { Container, Row, Column, ButtonWrapper } from '../../AddUserPage.styled'

const PermissionRoles = ({
  onBack,
  setActiveStep,
  setAuthoritiesWithRoles,
  authoritiesWithRoles,
  setFormData,
  formData,
  setIsLoading,
  isLoading,
  content
}) => {
  const defaultValues = {
    ...formData?.authorityRole
  }
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleStep = authorityRole => {
    setIsLoading(true)
    setFormData({ ...formData, authorityRole: authorityRole })

    setAuthoritiesWithRoles(
      authoritiesWithRoles.map(item => ({
        ...item,
        selectedRole: item.roles.find(role => role.id === authorityRole[item.authorityId])
      }))
    )

    setIsLoading(false)
    setActiveStep(3)
  }

  const getRoleById = role => ({
    ...role,
    label: content[`user_permission_role_${role.id}`] || `user_permission_role_${role.id}`
  })

  return (
    <form>
      <Heading as='h3'>{content?.add_user_permissions_roles || 'add_user_permissions_roles'}</Heading>
      <Text size='lg'>{content?.add_user_permissions_heading_1 || 'add_user_permissions_heading_1'}</Text>
      <Container $includeMarginTop={true}>
        {authoritiesWithRoles ? (
          authoritiesWithRoles.map(item => (
            <Row key={item.authorityId}>
              <Column>
                <Text as='span'>{item.authorityName}</Text>
              </Column>
              <Column>
                <Text as='span'>{item.authorityId}</Text>
              </Column>
              <Column>
                <Controller
                  name={item.authorityId}
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DropDown
                      label={content?.columnHeading_permission || 'columnHeading_permission'}
                      name={item.authorityId}
                      required
                      options={item.roles.map(getRoleById)}
                      onChange={onChange}
                      defaultSelectedId={value}
                      placeholder={content?.add_user_permissions_placeholder || 'add_user_permissions_placeholder'}
                    />
                  )}
                />
              </Column>
            </Row>
          ))
        ) : (
          <Row>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
            <Column>
              <Text as='span'>
                <Skeleton width={100} />
              </Text>
            </Column>
          </Row>
        )}
      </Container>
      <ButtonWrapper>
        <Button appearance='secondary' onClick={onBack} dataAttributes={{ 'data-testid': 'button-new-user-step-2' }}>
          {content?.add_user_back || 'add_user_back'}
        </Button>
        <Button
          isLoading={isLoading}
          disabled={!isValid || isLoading}
          onClick={handleSubmit(handleStep)}
          dataAttributes={{ 'data-testid': 'button-new-user-step-4' }}
        >
          {content?.add_user_next || 'add_user_next'}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default PermissionRoles
