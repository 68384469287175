import { Controller } from 'react-hook-form'
import { LabelWrapper, InputWrapper } from '../CardFields.styled'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { Icon } from '@bp-digital/component-icon'
import { CARD_GASOLEO_PROFESIONAL_Label } from 'constants/cards'

const YES = 'Y'
const NO = 'N'

const CardGasoleoProfessional = ({ control, content, isLoading, gasoleoChange }) => {
  return (
    <>
      <InputWrapper>
        <Controller
          name='gasoleoProfessional'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <RadioButtonGroup
              id={name}
              label={
                <LabelWrapper>
                  <Text
                    display='inline-block'
                    dataAttributes={{ 'data-content-key': 'manage_cards_full_details_gasoleo_professional' }}
                  >
                    {CARD_GASOLEO_PROFESIONAL_Label}?
                  </Text>

                  <Popover
                    appearance='dark'
                          text={CARD_GASOLEO_PROFESIONAL_Label + '?'}
                    position='top'
                    dataAttributes={{
                      ['data-content-key']: 'manage_cards_edit_full_details_gasoleo_professional_tooltip'
                    }}
                  >
                    <Icon name='Info' appearance='dark' size='md' state='neutral' />
                  </Popover>
                </LabelWrapper>
              }
              options={[
                { id: YES, label: content?.manage_cards_yes },
                { id: NO, label: content?.manage_cards_no }
              ]}
              onChange={id => {
                onChange(id)
                gasoleoChange(id)
              }}
              defaultSelectedId={value}
              disabled={isLoading}
              horizontal
            />
          )}
        />
      </InputWrapper>
    </>
  )
}

export default CardGasoleoProfessional
