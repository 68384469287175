import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { observer } from 'mobx-react'
import { Button } from '@bp-digital/component-button'
import { useRootStore } from 'src/contexts/StoreContext'
import { Wrapper, SkeletonWrapper } from './AuthoritySelector.styled'
import { Text } from '@bp-digital/component-typography'
import AuthoritySelectionModal from './AuthoritySelectionModal'
import { useLocation } from 'react-router-dom'

import useContent from 'hooks/useContent'

const AuthoritySelector = () => {
  const { userStore } = useRootStore()
  const content = useContent('masthead')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedHierarchy, setSelectedHierarchy] = useState(userStore.selectedHierarchy || {})
  const [hasMultiple, setHasMultiple] = useState(userStore.hierarchy?.authorities.length > 1)
  const [hasMastheadPage, setHasMastheadPage] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setHasMastheadPage(
      !location.pathname.includes('/access/') &&
        !location.pathname.includes('/account') &&
        !location.pathname.includes('/audit')
    )
  }, [location])

  useEffect(() => {
    setSelectedHierarchy(userStore.selectedHierarchy)
  }, [userStore.selectedHierarchy])

  useEffect(() => {
    setHasMultiple(userStore.hierarchy?.authorities.length > 1)
  }, [userStore.hierarchy?.authorities])

  if (!hasMastheadPage) return <SkeletonWrapper></SkeletonWrapper>

  if (!userStore.hierarchy) {
    return (
      <SkeletonWrapper>
        <Skeleton height={33} width={250} />
      </SkeletonWrapper>
    )
  }

  let label = ''

  if (selectedHierarchy?.groupId) {
    if (selectedHierarchy?.groupId == 'ALL') {
      label = `${content?.menu_top_menu_title_dashboard || 'Overview'} (${
        content?.masthead_group || '[masthead_group]'
      })`
    } else {
      label = `${selectedHierarchy?.groupName} (${content?.masthead_group || '[masthead_group]'})`
    }
  }
  if (selectedHierarchy?.parentId)
    label = `${selectedHierarchy?.parentName} (${content?.masthead_parent || '[masthead_parent]'} - ${
      selectedHierarchy?.accessLevelCode
    })`
  if (selectedHierarchy?.authorityId)
    label = `${selectedHierarchy?.customerName} (${selectedHierarchy?.accessLevelCode})`

  return (
    <Wrapper>
      {hasMultiple ? (
        <>
          {isOpen && <AuthoritySelectionModal setIsOpen={setIsOpen} />}

          <Button
            iconName='SwapUsers'
            onClick={() => setIsOpen(true)}
            dataAttributes={{
              'data-testid': 'topnav-authority-selector'
            }}
          >
            {label}
          </Button>
        </>
      ) : (
        <Text size='large'>{label}</Text>
      )}
    </Wrapper>
  )
}

export default observer(AuthoritySelector)
