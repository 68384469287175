import { action, makeObservable, observable } from 'mobx'
import axios from 'axios'
import { Badge } from '@bp-digital/component-badge'
import BaseDataStore from '../BaseDataStore'
import {
  STATUS_AVAILABLE,
  STATUS_UNKNOWN,
  STATUS_EXPIRED,
  STATUS_LOST,
  STATUS_NA,
  STATUS_STOLEN,
  STATUS_UNAVAILABLE
} from 'src/constants/cardStatuses'

const getStateFromStatus = status => {
  if (status === STATUS_AVAILABLE) {
    return 'success'
  } else if (status === STATUS_NA || status === STATUS_EXPIRED || status === STATUS_UNKNOWN) {
    return 'warning'
  } else if (status === STATUS_LOST || status === STATUS_STOLEN || status === STATUS_UNAVAILABLE) {
    return 'danger'
  }

  return 'warning'
}

class TokensStore extends BaseDataStore {
  columnSorting = ['endDate', 'desc']
  selectedRows = {}
  responseData = {}
  responseStatus = ''

  constructor(root) {
    super(root, 'tokens', 'chargepointoperations-select-cards')

    makeObservable(this, {
      selectedRows: observable,
      responseData: observable,
      responseStatus: observable,
      setSelectedRows: action,
      setResponseData: action,
      setResponseStatus: action
    })
  }

  setResponseData(data) {
    this.responseData = data
  }

  setResponseStatus(msg) {
    this.responseStatus = msg
  }

  setSelectedRows(rows) {
    this.selectedRows = rows
  }

  get hasSelectedRows() {
    return this.selectedRows && Object.keys(this.selectedRows).length
  }

  get rows() {
    return this.results.map(result => ({
      type: 'MASTER',
      key: result.id,
      isSelectable: result.cardEnabled,
      ...result,
      expiryDate: result.expiryDateEmbossed,
      cardStatus: (
        <Badge
          text={this.content?.[`${result.cardStatus || 'cpo_charger_status_UNKNOWN'}`] || '-'}
          state={getStateFromStatus(result.cardStatus)}
        />
      ),
      depotsAllowed: result.allowedSiteIds?.length
    }))
  }

  get columns() {
    return [
      { key: 'rfId', name: this.content?.depot_permissions_card_rfid || '...' },
      { key: 'cardTitle', name: this.content?.depot_permissions_card_title || '...' },
      { key: 'cardHolderName', name: this.content?.depot_permissions_card_cardholder_name || '...' },
      { key: 'cardNumberEnding', name: this.content?.depot_permissions_card_number_ending || '...' },
      { key: 'expiryDate', name: this.content?.depot_permissions_card_expiry_date || '...' },
      { key: 'cardStatus', name: this.content?.depot_permissions_card_status || '...' },
      { key: 'depotsAllowed', name: this.content?.depot_permissions_card_depots_allowed || '...' }
    ]
  }

  async getCards({
    selectedHierarchy,
    searchTerm = this.searchTerm,
    columnSorting = this.columnSorting,
    page = this.currentPage || 1,
    forceRefresh = false,
    limit = this.limits?.nextPageLimit || 15
  }) {
    if (!selectedHierarchy) return

    if (!columnSorting.length) {
      columnSorting = ['rfId', 'desc']
    }
    let paginationStart = (page - 1) * limit
    const params = {
      accessLevel: selectedHierarchy.accessLevel,
      accessLevelCode: selectedHierarchy.accessLevelCode,
      pageSize: limit,
      page: Math.floor(paginationStart / limit),
      SearchBy: searchTerm,
      sortBy: columnSorting[0],
      sortOrder: columnSorting[1] === 'asc' ? 'asc' : 'desc',
      sort: {}
    }
    this.setSelectedRows({})
    await super.getData({
      method: 'get',
      url: '/api/evis/v1/tokens/rfid-list',
      payload: {},
      params,
      searchTerm,
      forceRefresh,
      totalRecordsKey: 'totalRecords',
      responseKey: 'cards',
      page,
      pageSize: limit
    })
  }

  async postAuthorisations(selectedHierarchy, tokenIDs, sites) {
    const tokensBody = tokenIDs.map(tokenID => {
      const token = this.rows.filter(row => row.id === tokenID)[0]
      return { rfId: token.rfId, authorityID: token.authorityId, cardNumber: '', isActive: true }
    })
    const sitesBody = sites.map(site => {
      return { siteId: site, chargerId: '*', connectorId: '*' }
    })
    const publishedDate = new Date()
    const payload = {
      accessLevel: selectedHierarchy.accessLevel,
      accessLevelCode: selectedHierarchy.accessLevelCode,
      canUsePublicNetwork: true,
      publishedDate: publishedDate.toJSON(),
      issuer: '',
      cards: tokensBody,
      permissions: sitesBody
    }
    try {
      const response = await axios({
        method: 'post',
        url: '/api/evis/v1/tokens/restrictions/save',
        data: payload
      })
      this.setResponseData(response)
      if (response?.status === 200) {
        this.getCards({ selectedHierarchy, forceRefresh: true })
        this.setResponseStatus('success')
      } else {
        this.setResponseStatus('fail')
      }
    } catch (error) {
      this.setResponseStatus('error')
    }
  }
}

export default TokensStore
