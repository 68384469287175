import { useRootStore } from 'contexts/StoreContext'
import { ReactElement, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import { Button } from '@bp-digital/component-button'
import { ROUTE_TRANSACTIONS } from 'src/constants/routes'
import PieChart, { pieChartColors } from 'src/components/dataDisplay/PieChart'
import useApiGetWidgets from 'hooks/api/useApiGetWidgets'
import useContent from 'hooks/useContent'
import {
  WidgetHeader,
  WidgetTitles,
  WidgetTitle,
  WidgetSubtitle,
  WidgetActions,
  WidgetBody,
  Totals,
  TotalVat,
  TotalExVat,
  Recent,
  Key,
  ProductName,
  ProductQuantity,
  Flag,
  KeyCircle,
  WidgetContainer,
  ZeroMessage
} from './UninvoicedWidget.styled'
import { ErrorWidget } from './ErrorWidget'

const FIFTEEN_SECOND_TIMEOUT = 15000

function UninvoicedWidget(): ReactElement {
  const [selected, setSelected] = useState(0)
  const [requestTimedOut, setRequestTimeout] = useState(false)
  const history = useHistory()
  const {
    userStore: { selectedHierarchy }
  } = useRootStore()

  const content = useContent('userdashboard') || {}

  const payload = { parentId: '', authorityId: '' }
  if (selectedHierarchy) {
    payload.parentId = selectedHierarchy['parentId']
    payload.authorityId = selectedHierarchy['authorityId']
  }
  // if (selectedHierarchy && selectedHierarchy.hasOwnProperty('parentId')) payload.parentIds = [selectedHierarchy.parentId]
  //if (selectedHierarchy && selectedHierarchy.authorityId) payload.parentIds = [selectedHierarchy.parentId]

  const { data, error, isLoading, isSuccess } = useApiGetWidgets(payload)
  const currencySymbol = data?.currencySymbol
  const activeUninvoicedProduct = data?.chartData?.[selected]

  useEffect(() => {
    const timer = setTimeout(() => setRequestTimeout(true), FIFTEEN_SECOND_TIMEOUT)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <WidgetContainer>
        {isLoading && !requestTimedOut && (
          <>
            <Skeleton width='50%' />
            <br />
            <Skeleton width={120} height={120} circle style={{ margin: '16px 16px 0' }} />
          </>
        )}

        {(error || (requestTimedOut && !data && !isSuccess)) && <ErrorWidget />}

        {isSuccess && data && (
          <>
            <WidgetHeader>
              <WidgetTitles>
                <WidgetTitle>{content?.uninvoiced_widget_title}</WidgetTitle>
                <WidgetSubtitle>
                  <Flag src={`/assets/flags/${data.countryCode?.toLowerCase()}.svg`} />
                  {content.viewing_in} {data.currencyCode}
                </WidgetSubtitle>
              </WidgetTitles>
              <WidgetActions>
                {content.transactions_title && (
                  <Button
                    onClick={() => {
                      history.push({
                        pathname: ROUTE_TRANSACTIONS,
                        state: {
                          fromDashboard: true
                        }
                      })
                    }}
                  >
                    {content.transactions_title}
                  </Button>
                )}
              </WidgetActions>
            </WidgetHeader>
            <Totals>
              <TotalVat $isOverall>{currencySymbol + data.totalUninvoiceValue?.withVat}</TotalVat>
              <TotalExVat>
                {currencySymbol + data.totalUninvoiceValue?.withoutVat} {content.vat_ex}
              </TotalExVat>
            </Totals>
            <WidgetBody>
              {activeUninvoicedProduct ? (
                <>
                  <Recent>
                    <PieChart data={data.chartData} selected={selected} setSelected={setSelected} />
                  </Recent>
                  <Key>
                    <KeyCircle $color={pieChartColors[selected]} />
                    <ProductName>{activeUninvoicedProduct?.productName || ''}</ProductName>
                    <TotalVat>{`${currencySymbol}${activeUninvoicedProduct?.productValueWithVat || ''}`}</TotalVat>
                    <TotalExVat>
                      {`${currencySymbol}${activeUninvoicedProduct?.productValueWithoutVat || ''}`} {content.vat_ex}
                    </TotalExVat>
                    <ProductQuantity>
                      {activeUninvoicedProduct?.quantity} {activeUninvoicedProduct?.quantityUnits}
                    </ProductQuantity>
                  </Key>
                </>
              ) : (
                <ZeroMessage>{content?.uninvoiced_widget_zero_state}</ZeroMessage>
              )}
            </WidgetBody>
          </>
        )}
      </WidgetContainer>
    </>
  )
}

export default UninvoicedWidget
