import { Button } from '@bp-digital/component-button'
import { Text } from '@bp-digital/component-typography'
import useContent from 'hooks/useContent'
import { Content, Form, ButtonWrapper, AlertWrapper } from './RegisterDetailsPage.styled'
import { useForm, Controller } from 'react-hook-form'
import { TextField } from '@bp-digital/component-text-field'
import useApiGetRegisterUser from 'hooks/api/useApiGetRegisterUser'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import getCIPRegistrationLanguageFromURL from './helpers/getCIPRegistrationLanguageFromURL'
import axios from 'axios'
import { BRAND_BP } from 'constants/brands'
import { useState } from 'react'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import Skeleton from 'react-loading-skeleton'
import { Alert } from '@bp-digital/component-alert'
import PublicLayout from 'components/layout/PublicLayout'
import Cookies from 'js-cookie'
import { getCIPLoginPath } from 'helpers/getForgeRockvalue'
import getIDPRegistrationLanguageFromURL from './helpers/getIDPRegistrationLanguageFromURL'
import { Identity_Platform } from 'constants/identityPlatforms'

const RegisterDetailsPage = () => {
  const content = useContent('registration')
  const brand = getBrandFromDomain()
  const [disableBtn, setDisableBtn] = useState(false)
  const token = useUrlSearchParams().get('status')
  const { isLoading, isError, isSuccess, data } = useApiGetRegisterUser(token, { staleTime: 120000 })
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const userIdentityPlatform = Cookies.get('bpf-idp')
  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange' })
  async function url() {
    window.location.href = await getCIPLoginPath()
  }

  const onSubmit = async formData => {
    setDisableBtn(true)
    try {
      const language =
        userIdentityPlatform == Identity_Platform.CIP
          ? getCIPRegistrationLanguageFromURL
          : getIDPRegistrationLanguageFromURL()
      const params = { Token: token }
      const paramToIsoLanguages = [
        { in: 'en_US', out: 'EN' },
        { in: 'nl_NL', out: 'NL' },
        { in: 'de', out: 'DE' },
        { in: 'es', out: 'ES' },
        { in: 'pt_BR', out: 'PT' },
        { in: 'pl', out: 'PL' }
      ]
      const cip_ParamToIsoLanguages = [
        { in: 'en', out: 'EN' },
        { in: 'nl', out: 'NL' },
        { in: 'de', out: 'DE' },
        { in: 'es', out: 'ES' },
        { in: 'pt', out: 'PT' },
        { in: 'pl', out: 'PL' }
      ]
      const registerBody = {
        user: {
          firstName: encodeURIComponent(formData.firstName),
          lastName: encodeURIComponent(formData.lastName),
          userName: formData.email,
          email: formData.email,
          brand: brand === BRAND_BP ? '1' : '2',
          languageISOCode:
            userIdentityPlatform == Identity_Platform.CIP
              ? cip_ParamToIsoLanguages.find(map => map.in === language)
                ? cip_ParamToIsoLanguages.find(map => map.in === language).out
                : 'en'
              : paramToIsoLanguages.find(map => map.in === language)
              ? paramToIsoLanguages.find(map => map.in === language).out
              : 'en'
        }
      }
      const result = await axios.post('/api/users/users/register', registerBody, { params })
      if (result.status === 200) {
        setShowSuccessAlert(true)
      }
    } catch (e) {
      setShowErrorAlert(true)
      console.warn(e)
    }
    setDisableBtn(false)
  }

  return (
    <PublicLayout>
      <Content>
        <Text>{content?.pre_filled_data_confirmation}</Text>
      </Content>
      {showSuccessAlert && (
        <AlertWrapper>
          <Alert
            text={
              content?.check_email_message_r1 ||
              "We've sent an email to the address you provided with an activation link. Please click this link to continue."
            }
            visible={true}
            state='success'
          />
        </AlertWrapper>
      )}
      {(isError || showErrorAlert) && (
        <Content>
          <AlertWrapper>
            <Alert text={'A user with this email address already exists.'} visible={true} state='error' />
          </AlertWrapper>
          <ButtonWrapper>
            <Button
              size='lg'
              appearance='secondary'
              onClick={async () => {
                await url()
              }}
            >
              {content?.landing_login_button}
            </Button>
            <Button
              size='lg'
              appearance='secondary'
              onClick={async () => {
                await url()
              }}
            >
              {content?.landing_login_button}
            </Button>
          </ButtonWrapper>
        </Content>
      )}
      {isLoading && (
        <>
          <Skeleton height={50} width={270} />
          <Skeleton height={50} width={270} style={{ marginTop: '16px' }} />
          <Skeleton height={50} width={270} style={{ marginTop: '16px' }} />
          <ButtonWrapper>
            <Skeleton height={50} width={125} style={{ textAlign: 'left' }} />
          </ButtonWrapper>
        </>
      )}
      {isSuccess && !showSuccessAlert && !showErrorAlert && (
        <Form>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: {
                value: true,
                message: content?.error_name || 'You must enter your name'
              }
            }}
            defaultValue={data.firstName}
            render={({ field: { value, onChange } }) => (
              <TextField
                name='firstName'
                label={content?.form_label_name || 'form_label_name'}
                textChangeHandler={onChange}
                error={errors?.firstName && true}
                errorMessage={errors?.firstName?.message}
                defaultValue={value}
                required
              />
            )}
          />
          <Controller
            name='lastName'
            control={control}
            rules={{
              required: {
                value: true,
                message: content?.error_surname || 'You must enter your name'
              }
            }}
            defaultValue={data.lastName}
            render={({ field: { value, onChange } }) => (
              <TextField
                name='lastName'
                label={content?.form_label_surname || 'form_label_surname'}
                textChangeHandler={onChange}
                error={errors?.lastName && true}
                errorMessage={errors?.lastName?.message}
                defaultValue={value}
                required
              />
            )}
          />
          <Controller
            name='email'
            control={control}
            defaultValue={data.invitedEmail}
            render={({ field: { value, onChange } }) => (
              <TextField
                name='email'
                label={content?.form_label_emailaddress || 'form_label_emailaddress'}
                textChangeHandler={onChange}
                defaultValue={value}
                disabled={true}
                readOnly={true}
              />
            )}
          />
          <ButtonWrapper>
            <Button size='lg' onClick={handleSubmit(onSubmit)} disabled={!isValid || disableBtn}>
              {content?.continue_button}
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </PublicLayout>
  )
}

export default RegisterDetailsPage
