import { format } from 'light-date'

const parseValue = (key, value) => {
  if (key === 'transactionDate') {
    value = value ? format(new Date(value), '{dd}/{MM}/{yy}') : '-'
  }

  const type = typeof value

  if (['undefined', null].includes[type]) return '-'
  if (type === 'number') return parseInt(value, 10)
  return value
}

/**
 * Transforms the response of a full transaction dataset
 * to inject content
 *
 * @param {Object} data - the raw response from a full transaction request
 * @returns {Object}
 */
const cleanTransactionData = (data, content) => {
  const dataWithContent = {}

  Object.keys(data).map(parentKey => {
    if (Array.isArray(data[parentKey])) {
      dataWithContent[parentKey] = data[parentKey].map(item => {
        const itemKey = Object.keys(item)[0]
        const itemValue = Object.values(item)[0]

        return {
          key: itemKey,
          value: parseValue(itemKey, itemValue),
          label: content[`fullDetails_${parentKey}_${itemKey}`]
        }
      })
    } else {
      dataWithContent[parentKey] = data[parentKey]
    }
  })

  return dataWithContent
}

export default cleanTransactionData
