import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import ViolationsSelector from 'components/cards/Restrictions/ViolationsSelector'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { ROUTE_CARDS_SPENDING_LIMITS, ROUTE_ADMIN } from 'src/constants/routes'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import SpendingLimitForm from 'components/cards/Restrictions/SpendingLimitForm'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router'
import { getViolationId } from 'helpers/restrictions'
import useContent from 'hooks/useContent'
import axios from 'axios'
import { useRootStore } from 'src/contexts/StoreContext'
import { useHistory, Redirect } from 'react-router-dom'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'

const CardsSpendingLimitsEditPage = () => {
  const { state: { data } = {} } = useLocation()
  const [violationId, setViolationId] = useState(getViolationId(data.acceptViolation, data.alertViolation))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const spendingLimitData = {
    daily: {
      dailySpendLimit: data.dailySpendLimit
    },
    weekly: {
      weeklySpendLimit: data.weeklySpendLimit
    },
    monthly: {
      monthlySpendLimit: data.monthlySpendLimit
    },
    rollingLimit: {
      rollingDaysCount: data.rollingDays,
      rollingDaysSpendLimit: data.rollingSpendLimit
    }
  }

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      violationId: violationId,
      spendingLimit: spendingLimitData
    },
    mode: 'onBlur'
  })
  const [authorityDetailsData, setAuthorityDetailsData] = useState(null)
  const { cardsSpendingLimitStore, userStore } = useRootStore()

  const history = useHistory()
  const content = useContent('cards-limits')
  const restrictionsContent = useContent('cards-restrictions')

  const isAuthoritySelected =
    typeof userStore.selectedHierarchy !== 'undefined' && userStore.selectedHierarchy?.accessLevel == 'A'

  const { data: authorityDetails = {}, isLoading: isAuthorityLoading } = useApiGetAuthorityDetails(
    userStore.selectedHierarchy?.authorityId,
    {
      enabled: isAuthoritySelected
    }
  )

  useEffect(() => {
    if (isAuthorityLoading) return
    setAuthorityDetailsData(authorityDetails)
  }, [isAuthorityLoading, authorityDetails])

  const onSubmit = async formData => {
    setIsSubmitting(true)

    const { spendingLimit } = formData
    try {
      let params = {}

      params.authorityIds = data.authorityId
      params.parentIds = data.parentId ?? null
      const details = {
        parentId: null,
        authorityId: data.authorityId,
        customerId: data.customerId,
        dailySpendLimit: spendingLimit.daily.dailySpendLimit ? spendingLimit.daily.dailySpendLimit : null,
        weeklySpendLimit: spendingLimit.weekly.weeklySpendLimit ? spendingLimit.weekly.weeklySpendLimit : null,
        monthlySpendLimit: spendingLimit.monthly.monthlySpendLimit ? spendingLimit.monthly.monthlySpendLimit : null,
        rollingDays: spendingLimit.rollingLimit.rollingDaysCount ? spendingLimit.rollingLimit.rollingDaysCount : null,
        rollingSpendLimit: spendingLimit.rollingLimit.rollingDaysSpendLimit ? spendingLimit.rollingLimit.rollingDaysSpendLimit  :null,
        acceptViolation: violationId === 3 ? false : true,
        alertViolation: violationId === 1 ? false : true
      }

      const result = await axios({
        method: 'post',
        url: '/api/users/hierarchy/authorities/accountlimits',
        data: details,
        params
      })

      if (result.status === 200) {
        cardsSpendingLimitStore.getSpendingLimits({ authorityId: data.authorityId, forceRefresh: true })
        history.push(ROUTE_CARDS_SPENDING_LIMITS)
      }
    } catch (e) {
      setError(content?.edit_spending_limit_notification_failure || 'edit_spending_limit_notification_failure')
      console.warn(e)
    }
    setIsSubmitting(false)
  }

  const weeklySpendLimit = watch('spendingLimit.weekly.weeklySpendLimit')
  const monthlySpendLimit = watch('spendingLimit.monthly.monthlySpendLimit')

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.edit_spending_limit_title || '...'}
        subtitle={replaceVariablesInString(content?.edit_spending_limit_subheading_authority || '...', {
          authorityId: data.authorityId
        })}
        breadcrumbs={[
          {
            to: ROUTE_CARDS_SPENDING_LIMITS,
            title: content?.accountspendinglimits,
            ariaLabel: content?.accountspendinglimits
          }
        ]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <ViolationsSelector content={restrictionsContent} onChange={setViolationId} defaultSelectedId={violationId} />
        <SpendingLimitForm
          onSubmit={handleSubmit(onSubmit)}
          control={control}
          setValue={setValue}
          daily={spendingLimitData.daily}
          weekly={spendingLimitData.weekly}
          monthly={spendingLimitData.monthly}
          rollingLimit={spendingLimitData.rollingLimit}
          dailyId='spendingLimit.daily'
          weeklyId='spendingLimit.weekly'
          monthlyId='spendingLimit.monthly'
          rollingLimitId='spendingLimit.rollingLimit'
          weeklySpendLimit={weeklySpendLimit}
          monthlySpendLimit={monthlySpendLimit}
          isSubmitting={isSubmitting}
          error={error}
          currency={authorityDetailsData?.restrictions.currency}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(CardsSpendingLimitsEditPage)
