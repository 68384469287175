export const getHoursAndMinutes = value => {
  const hours = Math.floor(value) || 0
  const decimals = getDecimals(value)
  const minutes = Math.floor(decimals * 60) || 0

  return { hours, minutes }
}

const getDecimals = value => {
  if (isNaN(value)) return 0
  const hours = Math.floor(value)
  const stringValue = value.toString()
  let decimals = value - hours
  let decimalPlaces = 2

  if (stringValue.includes('.')) {
    decimalPlaces = stringValue.split('.')[1].length
  }

  decimals = decimals.toFixed(decimalPlaces)

  return decimals
}
