import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const SelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  border-bottom: 1px dashed var(--color-grey-regular-300);
  padding-bottom: 15px;
  margin-top: 1em;

  @media only screen and ${breakpoints.device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

export const SelectionMessage = styled.div`
  display: flex;
  min-height: 150px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
`

export const HelpMessage = styled.div`
  position: relative;
  font-size: ${CssVars.typography.size.md};
  font-weight: ${CssVars.typography.weight.light};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
`

export const FlowchartWrapper = styled.div`
  font-size: ${CssVars.typography.size.md};
  font-weight: ${CssVars.typography.weight.light};
  width: 385px;
  padding: 25px;
  margin: 0 auto 1em auto;
`
export const FlowchartTitle = styled.div`
  background: ${CssVars.palette.state.neutralLight};
  padding: 5px;
`

export const FlowchartBranch = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin: ${props => (props.margin ? props.margin : '0 5%')};
`

export const FlowchartVerticalLine = styled.div<{ margin?: string }>`
  background-color: ${CssVars.palette.state.neutralLight};
  width: 2px;
  height: 20px;
  margin: ${props => (props.margin ? props.margin : 'initial')};
`

export const FlowchartHorizontalLine = styled.div`
  width: 90%;
  margin: auto;
  height: 2px;
  background-color: ${CssVars.palette.state.neutralLight};
`

export const Answer = styled.div`
  background: #f4f4f4;
  padding: 5px;
`
