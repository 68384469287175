import { Checkbox } from '@bp-digital/component-checkbox'
import { TextField } from '@bp-digital/component-text-field'
import { Icon } from '@bp-digital/component-icon'
import { Popover } from '@bp-digital/component-popover'
import { Wrapper, CheckboxWrapper } from './SpendingLimitField.styled'
import TwoColumn from 'components/layout/TwoColumn'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

const SpendingLimitField = ({
  id,
  maxSpendLabel = 'Max Spend',
  checkBoxLabel = 'Daily',
  numberOfDaysLabel = 'Number of Days',
  showNumberOfDaysField = false,
  popoverText,
  control,
  defaultChecked = false,
  validationMessages,
  validation,
  numberOfDaysValidation,
  clearValues
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)
  return (
    <Wrapper>
      <CheckboxWrapper>
        <Checkbox
          id={`${id}CheckBox`}
          name={`${id}CheckBox`}
          label={checkBoxLabel}
          defaultChecked={isChecked}
          size='lg'
          onChange={value => {
            setIsChecked(value)
            if (!value) {
              clearValues()
            }
          }}
        />
        {popoverText && (
          <Popover appearance='dark' text={popoverText} position='top'>
            <Icon name='Info' appearance='dark' size='md' state='neutral' />
          </Popover>
        )}
      </CheckboxWrapper>
      {isChecked && (
        <TwoColumn>
          <Controller
            name={`${id}SpendLimit`}
            control={control}
            rules={{
              required: isChecked && validationMessages.requiredValue,
              validate: validation
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                name={`${id}SpendLimit`}
                label={maxSpendLabel}
                required
                textChangeHandler={onChange}
                defaultValue={value}
                error={!!error?.message}
                errorMessage={error?.message}
                type='number'
              />
            )}
          />
          {showNumberOfDaysField && (
            <Controller
              name={`${id}Count`}
              control={control}
              rules={{
                required: isChecked && validationMessages.requiredValue,
                validate: numberOfDaysValidation
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  name={`${id}Count`}
                  label={numberOfDaysLabel}
                  required
                  textChangeHandler={onChange}
                  defaultValue={value}
                  error={!!error?.message}
                  errorMessage={error?.message}
                  type='number'
                />
              )}
            />
          )}
        </TwoColumn>
      )}
    </Wrapper>
  )
}

export default SpendingLimitField
