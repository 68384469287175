import { CssVars } from '@bp-digital/theme'
import styled from 'styled-components'

export const FlyoutContainer = styled.div`
  font-size: ${CssVars.typography.size.md};
  transform: translate(50px, -145px);

  &::before {
    content: ' ';
    position: absolute;
    top: '50%';
    right: 93%;
    width: 1rem;
    height: 1rem;
    border-radius: 1px;
    background-color: white;
    transform: translate(10px, 100px) rotate(45deg);
  }
`

export const FlyoutContent = styled.div`
  position: relative;
  max-height: 150px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.md};
`

export const FlyoutLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${CssVars.structure.spacing.md};
`
export const FlyoutSublines = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.sm};
`

export const LineText = styled.div``

export const LineSubtext = styled.div`
  color: ${CssVars.palette.neutral.default};
`

export const IconWrap = styled.div`
  img {
    max-width: 2rem;
  }
`
