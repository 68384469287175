import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 32px auto;
  justify-content: center;

  > div {
    margin-left: auto;
  }
`
