/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
//import { Heading, Text } from '@bp-digital/component-typography'
//import { ButtonGroup } from '@bp-digital/component-button-group'
import { useRootStore } from 'contexts/StoreContext'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import Guided from './Guided/Guided'
import Express from './Express/Express'
import useContent from 'hooks/useContent'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'
import NoAuthority from './NoAuthority'
import AuthorityStopped from './AuthorityStopped'
import RestrictCardOrder from './RestrictCardOrder'
//import { isUserEV } from '../helpers'
import { CARD_ORDER_MODE } from '../constants/constants'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
//import { Container } from './common/CardOrder.styled'
//import { Spacer } from 'styles/common.styled'
import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN } from 'src/constants/routes'

const DEFAULT_MODE = CARD_ORDER_MODE.EXPRESS

const CardOrderPage = () => {
  const localStorage = window.localStorage
  const content = useContent('cards-order')
  const replacementCardContent = useContent('cards-view')

  /*   const items = [
    { id: 1, label: content?.setup_order_mode_guided || 'setup_order_mode_guided' },
    { id: 2, label: content?.setup_order_mode_express || 'setup_order_mode_express' }
  ] */

  const [cardOrderModeId, setCardOrderModeId] = useState(null)
  const [authorityDetailsData, setAuthorityDetailsData] = useState(null)
  const isReplacementCardEnabled = !!useUrlSearchParams().get('replaceCard')
  const replacementCardDetails = JSON.parse(localStorage.getItem('replacement-card-details'))
  const profileName = useUrlSearchParams().get('profileName')

  const {
    userStore: { selectedHierarchy, brand, isAdmin, impersonatedUser, onefleetmigratedstatus }
  } = useRootStore()
  const isAuthoritySelected = typeof selectedHierarchy !== 'undefined' && selectedHierarchy?.accessLevel == 'A'

  const { data: authorityDetails, isLoading: isAuthorityLoading } = useApiGetAuthorityDetails(
    selectedHierarchy?.accessLevelCode,
    {
      enabled: isAuthoritySelected
    }
  )

  useEffect(() => {
    if (isReplacementCardEnabled) setCardOrderModeId(CARD_ORDER_MODE.REPLACEMENT_CARD)
    else setCardOrderModeId(DEFAULT_MODE)
  }, [isReplacementCardEnabled])

  useEffect(() => {
    if (isAuthorityLoading) return
    setAuthorityDetailsData(authorityDetails)
  }, [isAuthorityLoading, authorityDetails])

  const isAuthorityStopped = authorityDetailsData?.authorityStopped === 'YES'
  const blockcardorder = (onefleetmigratedstatus > 1)
  const isCardOrderingAllowed = blockcardorder ? false : authorityDetailsData?.cardOrderSetting?.cardOrderingAllowed 
  //const EVUser = isUserEV(authorityDetails)

  const render = () => {
    if (isAdmin && !impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

    if (!isAuthoritySelected) return <NoAuthority content={content} />

    if (isAuthorityStopped) return <AuthorityStopped content={content} />

    if (!isCardOrderingAllowed) {
      return <RestrictCardOrder content={content} cardOrderSetting={authorityDetailsData?.cardOrderSetting} blockCardOrder={blockcardorder} />
    }

    if (cardOrderModeId === CARD_ORDER_MODE.GUIDED)
      return (
        <Guided
          content={content}
          authority={selectedHierarchy}
          authorityDetails={authorityDetailsData}
          isLoading={isAuthorityLoading}
        />
      )

    if (cardOrderModeId === CARD_ORDER_MODE.EXPRESS && !isAuthorityLoading)
      return (
        <Express
          content={content}
          authority={selectedHierarchy}
          authorityDetails={authorityDetailsData}
          isLoading={isAuthorityLoading}
          profileName={profileName}
        />
      )

    if (cardOrderModeId === CARD_ORDER_MODE.REPLACEMENT_CARD && isReplacementCardEnabled && !isAuthorityLoading)
      return (
        <Express
          content={content}
          authority={selectedHierarchy}
          authorityDetails={authorityDetailsData}
          isLoading={isAuthorityLoading}
          isReplacementCardEnabled={true}
          replacementCardDetails={replacementCardDetails}
        />
      )

    return null
  }

  return (
    <>
      <PageHeader
        title={
          isReplacementCardEnabled
            ? replacementCardContent?.order_replacement_card_modal_title || '...'
            : content?.setup_hero_title || '...'
        }
        subtitle={content?.express_mode_time_message}
        brand={brand}
      />
      <InnerPageWrapper>
        {/*         <Heading as='h2' size='h4'>
          {content?.setup_order_mode_heading || 'setup_order_mode_heading'}
        </Heading>

        {!EVUser && (
          <Container>
            <ButtonGroup
              defaultSelectedId={DEFAULT_MODE}
              direction='horizontal'
              items={items}
              onChange={setCardOrderModeId}
              size='sm'
            />

            <Spacer size='lg' />

            <Text size='md'>
              {cardOrderModeId === DEFAULT_MODE
                ? content?.setup_card_guided_mode_time_message || 'setup_card_guided_mode_time_message'
                : content?.express_mode_time_message || 'express_mode_time_message'}
            </Text>
          </Container>
        )} */}

        {render()}
      </InnerPageWrapper>
    </>
  )
}

export default observer(CardOrderPage)
