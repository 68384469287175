import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useRootStore } from 'contexts/StoreContext'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import { AlertWrapper } from './AccountPage.styled'
import GridLayout from 'components/layout/GridLayout'
import AccountAccess from './components/AccountAccess'
import AccountDetails from 'components/account/AccountDetails'
import AccountPreferences from './components/AccountPreferences'
import { Alert } from '@bp-digital/component-alert'
import getCountryCodeList from 'helpers/getCountryCodeList'
import useContent from 'src/hooks/useContent'
import PageHeader from 'components/layout/PageHeader'
import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN } from 'src/constants/routes'

const AccountPage = () => {
  const { userStore } = useRootStore()
  const contentProfile = useContent('profile-account') || {}
  const contentAccess = useContent('access-users') || {}

  const content = { ...contentAccess, ...contentProfile }
  const { user, selectedAuthorityId } = userStore
  const [showBtnLoading, setShowBtnLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [alert, setAlert] = useState({
    visible: false,
    text: '',
    state: ''
  })

  useEffect(() => {
    if (!user) userStore.getUserDetails()
  }, [user, userStore])

  const handleSave = async () => {
    setShowBtnLoading(true)
    try {
      const result = await userStore.updateUserDetails({
        basicDetails: {
          firstName: encodeURIComponent(user.firstName),
          lastName: encodeURIComponent(user.lastName),
          mobileNumberIsdCode: user.mobileNumberIsdCode || '',
          mobileNumber: user.mobileNumber || '',
          landlineNumberIsdCode: user.landlineNumberIsdCode || '',
          landlineNumber: user.landlineNumber || '',
          jobTitle: encodeURIComponent(user.jobTitle) || '',
          ...userDetails
        },
        preferences: null,
        contactVerified: false
      })

      if (result.status === 200) {
        const user = await userStore.getUserDetails()
        userStore.setUserDetails(user.data.userDetails)
        setUserDetails(null)
        setAlert({
          visible: true,
          text: content.edit_details_success || '...',
          state: 'success'
        })
      }
    } catch (e) {
      console.warn(e)
      setAlert({
        visible: true,
        text: content.error_portal_message || '...',
        state: 'error'
      })
    }
    setShowBtnLoading(false)
  }

  // const handleChangePassword = () =>
  //   (window.location = getSalesforcePasswordUrl(locale, window.location.host, getBrandFromDomain()))

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader title={content.hero_title || '...'} subtitle={content.yourDetails || '...'} brand={userStore.brand} />

      <InnerPageWrapper>
        <AlertWrapper $isVisible={alert.visible}>
          <Alert
            text={alert.text}
            visible={alert.visible}
            state={alert.state}
            iconPrefix
            onClose={() => setAlert({ ...alert, visible: false })}
          />
        </AlertWrapper>
        <GridLayout>
          <AccountDetails
            title={content.myDetails}
            userNameLabel={content.username}
            firstNameLabel={content.name}
            lastNameLabel={content.lastName}
            jobTitleLabel={content.jobTitle}
            emailLabel={content.email}
            mobileNumberLabel={content.mobileNumber}
            landlineNumberLabel={content.landlineNumber}
            changePasswordLabel={`${content.update || ''} ${content.password || ''}`.trim()}
            user={user}
            countryList={getCountryCodeList}
            onErrorsChanged={setIsFormValid}
            setUserDetails={changes => {
              const newUserDetails = Object.assign({}, ...changes)
              setUserDetails({ ...userDetails, ...newUserDetails })
            }}
            content={content}
            button={{
              text: content.update || 'Update',
              iconName: 'Save',
              onClick: handleSave,
              disabled: !isFormValid || userDetails === null || showBtnLoading,
              isLoading: showBtnLoading,
              dataAttributes: {
                'data-testid': 'button-save-account-details'
              }
            }}
          />
          <AccountPreferences setAlert={object => setAlert(object)} userStore={userStore} />
          <AccountAccess
            authorityId={selectedAuthorityId}
            content={content}
            userId={user?.uId}
            userRole={user?.role[0]}
          />
        </GridLayout>
      </InnerPageWrapper>
    </>
  )
}

export default observer(AccountPage)
