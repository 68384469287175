import { Table } from '@bp-digital/component-table'
import { Button } from '@bp-digital/component-button'

const RestrictionList = ({ restrictionList, content, onEdit, onRemove }) => (
  <Table>
    <Table.Head>
      <Table.Row>
        <Table.Header role='columnheader' scope='col'>
          {content?.restriction_site_table_country}
        </Table.Header>
        <Table.Header role='columnheader' scope='col'>
          {content?.restriction_site_table_region}
        </Table.Header>
        <Table.Header role='columnheader' scope='col'>
          {content?.restriction_site_table_site}
        </Table.Header>
        <Table.Header role='columnheader' scope='col'>
          {content?.restriction_site_table_action}
        </Table.Header>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      {restrictionList.map((item, index) => (
        <Table.Row key={index}>
          <Table.Cell>{item.countryName}</Table.Cell>
          <Table.Cell>{item.region}</Table.Cell>
          <Table.Cell>{item.site}</Table.Cell>
          <Table.Cell>{item.allow ? content?.restriction_site_allow : content?.restriction_site_not_allow}</Table.Cell>
          <Table.Cell>
            <Button appearance='tertiary' onClick={() => onEdit(item)}>
              {content?.restriction_site_edit}
            </Button>
          </Table.Cell>
          <Table.Cell>
            <Button appearance='tertiary' onClick={() => onRemove(item)}>
              {content?.restriction_site_remove}
            </Button>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default RestrictionList
