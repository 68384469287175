import { ButtonGroup } from '@bp-digital/component-button-group'
import { Heading } from '@bp-digital/component-typography'
import { VIOLATION_TYPE } from 'constants/restrictions'
import { Container } from './ViolationsSelector.styled'

const ViolationsSelector = ({ id = 'violations-selector', onChange, defaultSelectedId = 1, content }) => {
  return (
    <Container>
      <Heading as='h2' size='h5' dataAttributes={{ 'data-content-key': 'restriction_violation_description' }}>
        {content?.restriction_violation_description}
      </Heading>

      <ButtonGroup
        id={id}
        items={[
          {
            id: VIOLATION_TYPE.ACCEPT,
            label: content?.restriction_violation_accept || 'restriction_violation_accept'
          },
          {
            id: VIOLATION_TYPE.ACCEPT_AND_ALERT,
            label: content?.restriction_violation_accept_alert || 'restriction_violation_accept_alert'
          },
          {
            id: VIOLATION_TYPE.REJECT_AND_ALERT,
            label: content?.restriction_violation_reject_alert || 'restriction_violation_reject_alert'
          }
        ]}
        onChange={onChange}
        defaultSelectedId={defaultSelectedId}
      />
    </Container>
  )
}

export default ViolationsSelector
