import { useState } from 'react'
import ReactDOM from 'react-dom'
import Skeleton from 'react-loading-skeleton'
import { Modal } from '@bp-digital/component-modal'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { StateMessage } from '@bp-digital/component-state-message'
import { DropDown } from '@bp-digital/component-drop-down'
import { Grid } from './EditCardRestrictions.styled'
import AllRestrictionDetails from 'pages/Cards/Restrictions/components/AllRestrictionDetails'
import useApiGetRestrictionBaseProfiles from 'hooks/api/useApiGetRestrictionBaseProfiles'
import useApiGetRestrictionProfile from 'hooks/api/useApiGetRestrictionProfile'
import useApiPostRestrictionUpdate from 'hooks/api/useApiPostRestrictionUpdate'
import { useRootStore } from 'contexts/StoreContext'
import { Text } from '@bp-digital/component-typography'


const root = document.getElementById('root')

const EditCardRestrictions = ({
  currentProfileId,
  cardSerialNumber,
  onClose,
  content = {},
  authorityId,
  cardId,
  serviceCode,
  setToastData
}) => {
  const { viewManageCardStore,userStore: {  onefleetmigratedstatus } } = useRootStore()
  const [showRestrictionProfiles, setShowRestrictionProfiles] = useState(!!currentProfileId)
  const [profileId, setProfileId] = useState(currentProfileId)
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const editcardrestrictionallowed = !(onefleetmigratedstatus > 0)
  const { data: baseProfiles = [], isLoading } = useApiGetRestrictionBaseProfiles(
    {
      authorityId,
      groupId: null,
      parentId: null,
      searchText: null,
      serviceCode: [serviceCode]
    },
    {
      enabled: showRestrictionProfiles,
      staleTime: 50000,
      onSuccess: data => {
        if (!data.length) {
          setError(content.manage_cards_edit_restriction_no_restricton)
        } else if (!profileId) {
          setProfileId(data[0].profileId)
        }
      }
    }
  )
  const { data: profileDetails, isLoading: isLoadingProfile } = useApiGetRestrictionProfile(profileId, {
    enabled: !!profileId
  })
  const { mutate: updateCardRestriction } = useApiPostRestrictionUpdate(
    () => {
      viewManageCardStore.getCards({ forceRefresh: true })
      setToastData({
        id: Math.floor(Math.random() * 9999),
        position: 'right-bottom',
        autoClose: 5000,
        hasCloseButton: false,
        title: content.manage_cards_update_restriction_notification_success_singular,
        icon: 'CheckLargeSolid'
      })
      onClose()
    },
    error => {
      console.warn('Failed to update profile on card', error)
      setError(content.manage_cards_update_restriction_notification_error_singular)
    },
    authorityId
  )

  return ReactDOM.createPortal(
  <>
  {editcardrestrictionallowed ? (
    <Modal
      title={content?.manage_cards_edit_restriction_sub_title?.replace('{{number}}', cardSerialNumber)}
      primaryAction={{
        text: content?.editDetails_save || 'Save',
        onClick: () => {
          setIsUpdating(true)
          updateCardRestriction({
            cardRestrictionDetails: [
              {
                authorityId,
                cardId,
                isDelete: false,
                isProfileNameChanged: false,
                isUnlink: !showRestrictionProfiles,
                profileId: showRestrictionProfiles ? +profileId : +currentProfileId,
                profileName: profileDetails.restrictiondetails.profileName
              }
            ]
          })
        },
        iconName: 'Save',
        disabled: isUpdating,
        isLoading: isUpdating
      }}
      secondaryAction={{
        text: content?.manage_cards_full_details_buttons_close,
        onClick: onClose,
        iconName: 'RemoveLarge',
        appearance: 'tertiary'
      }}
      onDismiss={onClose}
      visible
      size='lg'
    >
      <Grid>
        <RadioButtonGroup
          id='restrictions'
          required
          disabled={isLoading || isUpdating}
          horizontal={true}
          defaultSelectedId={!!profileId}
          label={
            content?.manage_cards_edit_restriction_set_restriction || 'manage_cards_edit_restriction_set_restriction'
          }
          options={[
            { id: true, label: content.manage_cards_yes || 'manage_cards_yes' },
            { id: false, label: content.manage_cards_no || 'manage_cards_no' }
          ]}
          onChange={setShowRestrictionProfiles}
        />

        {showRestrictionProfiles ? (
          isLoading ? (
            <Skeleton width='100%' height={50} />
          ) : (
            <DropDown
              disabled={!showRestrictionProfiles || isUpdating || !baseProfiles?.length}
              id='restriction-profiles'
              label={content?.manage_cards_edit_restriction_profile || 'manage_cards_edit_restriction_profile'}
              options={
                baseProfiles?.map(({ profileId, profileName }) => ({
                  id: profileId,
                  label: profileName
                })) || []
              }
              defaultSelectedId={profileId || baseProfiles?.[0]?.profileId}
              placeholder={content?.dropdown_select || 'dropdown_select'}
              onChange={id => {
                setProfileId(Number(id))
              }}
            />
          )
        ) : null}
      </Grid>

      {showRestrictionProfiles && !profileDetails && (
        <>
          <Skeleton width='100%' height={60} style={{ margin: '16px 0 8px 0' }} />
          <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
          <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
          <Skeleton width='100%' height={60} style={{ margin: '8px 0' }} />
          <Skeleton width='100%' height={60} style={{ margin: '8px 0 16px 0' }} />
        </>
      )}

      {showRestrictionProfiles && profileDetails && !isLoadingProfile && (
        <AllRestrictionDetails
          profileDetails={profileDetails.restrictiondetails}
          // currency={}
          // distanceUnit={}
        />
      )}

      {!!error && <StateMessage iconName='Alert' state='danger' text={error} />}
    </Modal>) :
    (
      <Modal
      title={content?.manage_cards_edit_restriction_sub_title?.replace('{{number}}', cardSerialNumber)}
      primaryAction={{
        text: content.manage_cards_actions_cancel,
        onClick: onClose,
        appearance: 'tertiary',
        dataAttributes: {
          'data-testid': 'button-stop-card-cancel',
          'data-content-key': 'manage_cards_actions_cancel'
        }
      }}
      visible
      size='sm'
    >
      <Text>
        {content?.onefleetmigrated_block_editrestrictionprofile_message || 'content?.onefleetmigrated_block_editrestrictionprofile_message'}
      </Text>
    </Modal>
    )}
    </>,
    root
  )
}

export default EditCardRestrictions
