import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'cards/details'

type paramType = string | null

const queryFn = async (authorityId: paramType, cardNumber: paramType) => {
  if (!authorityId || !cardNumber)
    throw new Error('No Authority Id or Card Number given to /cards/carddetails endpoint')
  
  try{
  const response = await axios({
    method: 'get',
    url: `/api/cards/cards/${authorityId.substr(0, 1)}${cardNumber}/details`   
  })

  return response.data
}catch (error) {
  console.error(error)      
}

}

const useApiGetCardDetails = (authorityId: paramType, cardNumber: paramType, options: { enabled?: boolean }) => {
  return useQuery([QUERY_KEY, { cardNumber: cardNumber }], () => queryFn(authorityId, cardNumber), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetCardDetails
