//import { useState, memo, useRef } from 'react'
import { useEffect, useState, memo, useRef } from 'react'
import { useRootStore } from 'contexts/StoreContext'
import { Table } from '@bp-digital/component-table'
import { Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { TextField } from '@bp-digital/component-text-field'
import Skeleton from 'react-loading-skeleton'
import Paper from 'components/surfaces/Paper'
import { getDetails, getDaysOfTheWeek, getOpeningTime } from './helpers'
import {
  ActionsWrapper,
  SubtitleWrapper,
  TableContainer,
  ButtonsWrapper,
  CellContentWrapper,
  LastUpdatedLabel,
  PhoneMessage
} from './ChargePointDetails.styled'
import { Icon } from '@bp-digital/component-icon'
import { Modal } from '@bp-digital/component-modal'

const ChargePointDetails = ({
  site = {},
  content = {},
  isLoading,
  onChange = () => {
    null
  }
}) => {
  const [openAliasModal, setOpenAliasModal] = useState(false)
  const [openSupportModal, setOpenSupportModal] = useState(false)
  const [aliasModalErrorMsg, setAliasModalErrorMsg] = useState('')
  const [nameAliasValue, setNameAliasValue] = useState(site?.siteAlias)
  const mountedRef = useRef(true)

  const loadingCell = <Skeleton height={20} />
  const { chargePointsStore, userStore } = useRootStore()
  const selectedHierarchy = userStore.selectedHierarchy

  useEffect(() => {
    if (!mountedRef.current) return null
    setNameAliasValue(site.siteAlias)
  }, [site])

  const validateAliasInput = nameAliasValue => {
    let errorMsg = ''
    if (nameAliasValue.length < 1 || nameAliasValue.length > 120) {
      errorMsg = 'cpo_charger_name_form_err_msg'
    } else if (nameAliasValue != site?.siteAlias && chargePointsStore.getReservedAliases().includes(nameAliasValue)) {
      errorMsg = 'cpo_charger_name_form_err_unique_msg'
    } else {
      errorMsg = ''
    }
    return { isValid: errorMsg === '', errorMsg }
  }

  const getSiteAliasEditbtn = () => {
    if (!site.siteAlias)
      return (
        <Button
          appearance='primary'
          iconName='Edit'
          size='md'
          onClick={() => {
            setOpenAliasModal(true)
          }}
        >
          {content?.cpo_charger_name_form_label || '[cpo_charger_name_form_label]'}
        </Button>
      )
    return (
      <CellContentWrapper>
        <div>{site.siteAlias}</div>
        <button
          onClick={() => {
            setOpenAliasModal(true)
          }}
        >
          <Icon name='Edit' size='md'></Icon>
        </button>
      </CellContentWrapper>
    )
  }

  // const ref = useRef(null)
  return (
    <Paper title={content?.cpo_charger_details_details || '...'}>
      <br />

      <Table renderContainer={TableContainer}>
        <Table.Body>
          <Table.Row key={'chargerName'}>
            <Table.Cell>{isLoading ? loadingCell : content?.cpo_charger_name || '[cpo_charger_name]'}</Table.Cell>
            <Table.Cell>{isLoading ? loadingCell : getSiteAliasEditbtn()}</Table.Cell>
          </Table.Row>
          {getDetails(site, content).map((detail, index) => (
            <Table.Row key={`${detail.label}-${index}`}>
              <Table.Cell>{isLoading ? loadingCell : detail.label}</Table.Cell>
              <Table.Cell>{isLoading ? loadingCell : detail.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <ActionsWrapper>
        <Button
          iconName={'Email'}
          href={`https://service.aral.de/kontakt/pulse`}
          target='_blank'
          dataAttributes={{ 'data-testid': 'report-button' }}
        >
          {content?.cpo_charger_details_report_problem || '[Report a problem]'}
        </Button>
        <Button
          iconName={'Phone'}
          onClick={() => setOpenSupportModal(true)}
          dataAttributes={{
            'data-testid': 'support-center-button'
          }}
        >
          {content?.cpo_charger_details_support_center || '[Support center]'}
        </Button>
      </ActionsWrapper>

      <SubtitleWrapper>
        <Text size='lg'>{content?.cpo_charger_opening_times_title || ''}</Text>
      </SubtitleWrapper>

      <Table renderContainer={TableContainer}>
        <Table.Body stripedRowStyle={!isLoading}>
          {getDaysOfTheWeek(content).map((day, index) => (
            <Table.Row key={`${day}-${index}`}>
              <Table.Cell>{isLoading ? loadingCell : day}</Table.Cell>
              <Table.Cell>{isLoading ? loadingCell : getOpeningTime(site.openingTimes, index, content)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {openAliasModal && (
        <Modal
          title={content?.cpo_charger_name_form_header || '[cpo_charger_name_form_header]'}
          onDismiss={() => setOpenAliasModal(false)}
          visible
          size='lg'
        >
          <div>
            <TextField
              label={content?.cpo_charger_name || '[cpo_charger_name]'}
              placeholder={content?.cpo_charger_name_form_label || '[cpo_charger_name_form_label]'}
              defaultValue={site?.siteAlias || ''}
              textChangeHandler={newVal => {
                setNameAliasValue(newVal)
                setAliasModalErrorMsg(validateAliasInput(newVal).errorMsg)
              }}
              error={!!aliasModalErrorMsg}
              errorMessage={content && content[aliasModalErrorMsg] ? content[aliasModalErrorMsg] : aliasModalErrorMsg}
            />
            <LastUpdatedLabel>
              {site &&
                site.siteAliasLastUpdate &&
                `${content?.cpo_charger_name_last_updated_date} ${new Date(site.siteAliasLastUpdate).toLocaleString()}`}
            </LastUpdatedLabel>
          </div>
          <ButtonsWrapper>
            <Button
              appearance='secondary'
              iconName='Alert'
              size='lg'
              data-testid='button-charger-alias-save'
              disabled={!nameAliasValue || !nameAliasValue.length}
              onClick={async () => {
                await chargePointsStore.writeSiteAlias(selectedHierarchy, site.siteId, '')
                onChange()
                setOpenAliasModal(false)
              }}
            >
              {content?.cpo_charger_name_form_delete || '[cpo_charger_name_form_delete]'}
            </Button>

            <Button
              appearance='primary'
              iconName='Save'
              size='lg'
              data-testid='button-charger-alias-save'
              disabled={
                !nameAliasValue || !validateAliasInput(nameAliasValue).isValid || nameAliasValue == site.siteAlias
              }
              onClick={async () => {
                try {
                  await chargePointsStore.writeSiteAlias(selectedHierarchy, site.siteId, nameAliasValue)
                  onChange()
                  setOpenAliasModal(false)
                } catch (error) {
                  setAliasModalErrorMsg('cpo_charger_name_form_err_unique_msg')
                }
              }}
            >
              {content?.cpo_charger_name_form_save || '[cpo_charger_name_form_save]'}
            </Button>
          </ButtonsWrapper>
        </Modal>
      )}

      {
        openSupportModal && (
          <Modal
            title={content?.cpo_charger_details_support_center || '[cpo_charger_details_support_center]'}
            onDismiss={() => setOpenSupportModal(false)}
            visible
            size='lg'
          >
            <PhoneMessage>
              <span>
                {content?.cpo_charger_details_support_center_phone_msg ||
                  '[content?.cpo_charger_details_support_center_phone_msg]'}
              </span>
              <span>0800 1133531</span>
            </PhoneMessage>
          </Modal>
        )
        //
      }
    </Paper>
  )
}

export default memo(ChargePointDetails)
