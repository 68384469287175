import ReactDOM from 'react-dom'
import { useQueryClient } from 'react-query'
import { Modal } from '@bp-digital/component-modal'
import CardType from 'pages/Cards/components/EditCardDetailFields/CardType'
import CardVehicleRegistration from 'pages/Cards/components/EditCardDetailFields/CardVehicleRegistration'
import CardDriverName from 'pages/Cards/components/EditCardDetailFields/CardDriverName'
import CardEmbossingLines from 'pages/Cards/components/EditCardDetailFields/CardEmbossingLines'
import CardRegion from 'pages/Cards/components/EditCardDetailFields/CardRegion'
import CardCostCentre from 'pages/Cards/components/EditCardDetailFields/CardCostCentre'
import CardOffers from 'pages/Cards/components/EditCardDetailFields/CardOffers'
import CardOdometerAddInfo from 'pages/Cards/components/EditCardDetailFields/CardOdometerAddInfo'
import CardGasoleoProfessional from 'pages/Cards/components/EditCardDetailFields/CardGasoleoProfessional'
import TwoColumn from 'components/layout/TwoColumn'
import { useForm } from 'react-hook-form'
import { CARD_TYPE } from 'pages/Cards/CardOrderPage/constants/constants'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { StateMessage } from '@bp-digital/component-state-message'
import { ROUTE_CARDS_ORDER } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { CARD_GASOLEO_PROFESIONAL } from 'constants/cards'

const root = document.getElementById('root')

const YES = 'Y'
const NO = 'N'

const EditCardDetail = ({
  content,
  onBack,
  cardId,
  cardNumber,
  authorityId,
  cardDetails,
  isLoading,
  isSuccess,
  embossingLines,
  authorityDetails: {
    cardTypes = [],
    countryCode,
    sendToAddress,
    columnContext: { targetNeutral } = {},
    configuration: { region, costCenter, gasoleoProfessional } = {}
  },
  authorityDetails
}) => {
  const localStorage = window.localStorage
  const history = useHistory()
  const queryClient = useQueryClient()
  const { restrictions, embossingDetails, general, offers } = cardDetails || {}

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const showGasoleo = gasoleoProfessional && gasoleoProfessional.shown === 'Y'

  const defaultValues = {
    reasonCode: 31,
    cardDetails: {
      cardTypeId: embossingDetails.cardType || cardTypes[0]?.cardTypeId,
      vehicleregistration: embossingDetails.vehicleRegistration,
      drivername: embossingDetails.cardHolderName
    },
    cardEmbossing: {
      line1: embossingLines[0],
      line2: embossingLines[1],
      line3: embossingLines[2]
    },
    region: {
      international: restrictions.international,
      costcenterName: general.costCenter,
      subcostcenter: general.subCostCenter
    },
    offers: {
      austriantax: offers.austrianRoadTaxInd,
      targetNeutral: offers.targetNeutral
    },
    pin: {
      addinfo: general.addInfo,
      odometer: general.odometer
    },
    gasoleoProfessional: embossingDetails.gasoleoProfessional || NO
  }

  const {
    trigger,
    setValue,
    getValues,
    control,
    watch,
    handleSubmit,
    formState: { dirtyFields, isDirty, isValid }
  } = useForm({
    defaultValues,
    mode: 'onChange'
  })

  const cardTypeId = watch('cardDetails.cardTypeId')
  const driverName = watch('cardDetails.drivername')
  const vehicleRegistration = watch('cardDetails.vehicleregistration')
  const isGasoleoProfessionalCard = watch('gasoleoProfessional') === YES

  const showVehicleRegistration = [
    CARD_TYPE.POOL_CARD.id,
    CARD_TYPE.VEHICLE_CARD.id,
    CARD_TYPE.DRIVER_VEHICLE_CARD.id,
    CARD_TYPE.DRIVER_PIN_CARD.id
  ].includes(cardTypeId)
  const showDriverName = [CARD_TYPE.POOL_CARD.id, CARD_TYPE.DRIVER_CARD.id, CARD_TYPE.DRIVER_VEHICLE_CARD.id].includes(
    cardTypeId
  )
  const showAustrianTax = region?.austriantax?.shown === YES
  const showCostCentre = costCenter?.shown === YES
  const showTargetNeutral = targetNeutral
  const showRegion = region?.region?.shown === YES

  const hasEmbossingChanged = !!(
    dirtyFields.cardDetails?.vehicleregistration ||
    dirtyFields.cardDetails?.drivername ||
    dirtyFields.cardEmbossing?.line2 ||
    dirtyFields.cardEmbossing?.line3 ||
    dirtyFields.region?.international ||
    dirtyFields.gasoleoProfessional ||
    dirtyFields.pin?.odometer ||
    dirtyFields.pin?.addinfo
  )

  const gasoleoPropChange = val => {
    const value =
      authorityDetails.deliveryAddress.addressLine4 ||
      authorityDetails.deliveryAddress.addressLine3 ||
      authorityDetails.embossingLines.line2.content
    const embossLine2 = value.length > 27 ? value.substring(0, 27) : value

    val === YES
      ? setValue('cardEmbossing.line2', CARD_GASOLEO_PROFESIONAL)
      : setValue('cardEmbossing.line2', embossLine2)
  }

  useEffect(() => {
    const hasLineChanged = getValues('cardEmbossing.line3') !== embossingLines[2]
    if (showVehicleRegistration && vehicleRegistration) {
      setValue('cardEmbossing.line3', vehicleRegistration, { shouldDirty: hasLineChanged })
    } else {
      setValue('cardDetails.vehicleregistration', '')
      setValue('cardEmbossing.line3', driverName, { shouldDirty: hasLineChanged })
    }
  }, [driverName, embossingLines, getValues, setValue, showVehicleRegistration, vehicleRegistration])

  const onSubmit = async formData => {
    setIsSubmitting(true)
    const { cardDetails, cardEmbossing, region, offers: formOffers, pin, gasoleoProfessional } = formData

    if (hasEmbossingChanged) {
      localStorage.setItem(
        'replacement-card-details',
        JSON.stringify({
          cardSerialNumber: embossingDetails.cardSerialNumber,
          reasonCode: 31,
          cardTypeId: cardDetails.cardTypeId || cardTypes[0]?.cardTypeId,
          vehicleRegistration: cardDetails.vehicleregistration,
          driverName: cardDetails.drivername,
          line2: cardEmbossing.line2,
          line3: cardEmbossing.line3,
          region: region.international,
          addinfo: pin.addinfo,
          odometer: pin.odometer,
          fuelAndCharge: embossingDetails.fuelAndCharge,
          gasoleoProfessional: gasoleoProfessional
        })
      )
      history.push(`${ROUTE_CARDS_ORDER}?replaceCard=true`)
    } else {
      try {
        const response = await axios({
          method: 'patch',
          url: '/api/cards/card-action/update-cards',
          data: {
            authorityId: authorityId,
            countryCode: countryCode,
            cardUpdates: [
              {
                cardId: cardId,
                cardUpdateRequest: 'Y',
                currentCostCentre: region.costcenterName,
                fields: [
                  { name: 'EVCharging', value: '' },
                  { name: 'EmbossName2', value: cardEmbossing.line2 },
                  { name: 'CardTypeId', value: cardDetails.cardTypeId.toString() },
                  { name: 'SecondSortLabel', value: region.subcostcenter },
                  { name: 'SubCostCentre', value: region.costcenterName },
                  { name: 'Odometer', value: pin.odometer },
                  { name: 'AdditionalInfo', value: pin.addinfo },
                  { name: 'AustrianRoadTollInd', value: formOffers.austriantax },
                  { name: 'TargetNeutral', value: formOffers.targetNeutral },
                  { name: 'IvmhScheme', value: 'N' },
                  { name: 'InternationalInd', value: region.international },
                  { name: 'Vehicle', value: cardDetails.vehicleregistration },
                  { name: 'CardHolderName', value: cardDetails.drivername }
                ]
              }
            ]
          }
        })

        if (response.status === 200) {
          queryClient.invalidateQueries({ cardNumber: cardNumber })
          queryClient.invalidateQueries('cards/details', { cardNumber: cardNumber })
          onBack()
        }
      } catch (e) {
        setError('Something went wrong. Please close and try again.')
        console.warn(e)
      }
    }
    setIsSubmitting(false)
  }

  return ReactDOM.createPortal(
    <form>
      <Modal
        title={`${content?.manage_cards_edit_full_details_heading} ${cardNumber}`}
        primaryAction={{
          iconName: 'Save',
          text: content?.manage_cards_save,
          onClick: handleSubmit(onSubmit),
          isLoading: isSubmitting,
          disabled: !isDirty || isLoading || isSubmitting || !isValid
        }}
        secondaryAction={{
          appearance: 'tertiary',
          iconName: 'LeftLarge',
          text: content?.columnsModal_backButton,
          disabled: isSubmitting,
          onClick: onBack
        }}
        onDismiss={onBack}
        visible
        size='lg'
      >
        <TwoColumn>
          {isLoading && <Skeleton />}
          {isSuccess && (
            <CardType
              control={control}
              content={content}
              setValue={setValue}
              cardTypes={cardTypes}
              cardTypeId={cardTypeId}
              isLoading={false}
              trigger={trigger}
            />
          )}
        </TwoColumn>
        <TwoColumn>
          {isLoading && <Skeleton />}
          {isSuccess && showVehicleRegistration && (
            <CardVehicleRegistration
              control={control}
              content={content}
              setValue={setValue}
              cardTypeId={cardTypeId}
              driverName={driverName}
              isLoading={false}
            />
          )}
          {isLoading && <Skeleton />}
          {isSuccess && showDriverName && (
            <CardDriverName
              control={control}
              content={content}
              setValue={setValue}
              cardTypeId={cardTypeId}
              vehicleRegistration={vehicleRegistration}
              countryCode={countryCode}
              sendToAddress={sendToAddress}
              isLoading={false}
            />
          )}
        </TwoColumn>
        <TwoColumn>
          {isLoading && <Skeleton />}
          {isSuccess && (
            <CardEmbossingLines
              control={control}
              content={content}
              isLoading={false}
              disableLineTwo={isGasoleoProfessionalCard}
            />
          )}
          {isLoading && <Skeleton count={3} />}
          {isSuccess && (
            <div>
              {showGasoleo && (
                <CardGasoleoProfessional
                  control={control}
                  content={content}
                  isLoading={isLoading}
                  setValue={setValue}
                  gasoleoChange={gasoleoPropChange}
                />
              )}
              {showRegion && <CardRegion control={control} content={content} isLoading={false} />}
              <CardOdometerAddInfo control={control} content={content} isLoading={false} />
            </div>
          )}
        </TwoColumn>
        <TwoColumn>
          {isLoading && <Skeleton count={2} />}
          {isSuccess && showCostCentre && (
            <CardCostCentre control={control} content={content} isLoading={false} sendToAddress={sendToAddress} />
          )}
          {isLoading && <Skeleton count={2} />}
          {isSuccess && (
            <CardOffers
              control={control}
              content={content}
              isLoading={false}
              showAustrianTax={showAustrianTax}
              showTargetNeutral={showTargetNeutral}
              authorityDetails={authorityDetails}
            />
          )}
        </TwoColumn>
        {hasEmbossingChanged && (
          <StateMessage iconName='Info' state='info' text={content?.manage_cards_enter_replacement_card} />
        )}
        {error && <StateMessage iconName='Alert' state='danger' text={error} />}
      </Modal>
    </form>,
    root
  )
}

export default EditCardDetail
