import { Badge } from '@bp-digital/component-badge'
import { getStateFromCardStatus } from 'helpers/getStateFromCardStatus'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import BaseDataStore from '../BaseDataStore'

class CardsReissueStore extends BaseDataStore {
  simpleFilter = 'notForReissue'
  columnSorting = ['cardSerialNumber', 'asc']

  constructor(root) {
    super(root, 'cards', 'cards-reissue')
  }

  get rows() {
    const internationalContent = this.content?.advancedFilters_international_ind_Y
    const nationalContent = this.content?.advancedFilters_international_ind_N

    return this.results.map(card => {
      const status = this.content?.[`advancedFilters_cardStatusId_${card.cardStatusId}`] || card.cardStatus
      const state = getStateFromCardStatus(card.cardStatusId)
      const expiryDate = card.expiryDate ? `${card.expiryDate.substr(5, 2)}/${card.expiryDate.substr(2, 2)}` : ''

      let result = {
        type: 'MASTER',
        ...card,
        key: card.cardId,
        cardStatusId: <Badge text={status} state={state} />,
        expiryDate: getUserFormattedDate(expiryDate, this.root.userStore.dateFormat),
        cardStatusIdNumber: card.cardStatusId,
        internationalInd: card.internationalInd === 'Y' ? internationalContent : nationalContent
      }

      // Format last transaction date if it exists
      if (card.lastTransactionDate) {
        const lastTransactionDate = `${card.lastTransactionDate.substr(8, 2)}/${card.lastTransactionDate.substr(
          5,
          2
        )}/${card.lastTransactionDate.substr(0, 4)}`

        result.lastTransactionDate = getUserFormattedDate(lastTransactionDate, this.root.userStore.dateFormat)
      }

      // Format last used date if it exists
      if (card.bPMeLastUsed) {
        const bPMeLastUsed = `${card.bPMeLastUsed.substr(8, 2)}/${card.bPMeLastUsed.substr(
          5,
          2
        )}/${card.bPMeLastUsed.substr(0, 4)}`

        result.bPMeLastUsed = getUserFormattedDate(bPMeLastUsed, this.root.userStore.dateFormat)
      }

      return result
    })
  }

  get columns() {
    return (
      this.root.viewManageCardStore.selectedColumns?.map(preference => {
        let contentKey = preference

        if (preference === 'cardStatus') {
          contentKey = 'cardStatusId' // hack for correct content
        }

        return {
          key: preference,
          name: this.content ? this.content[`columnHeading_${contentKey}`] : '...'
        }
      }) || [{ key: '...', name: '...' }]
    )
  }

  async getCards({
    authorityId,
    columnSorting = this.columnSorting,
    searchTerm = this.searchTerm,
    page = 1,
    limit = this.limits?.nextPageLimit || 15,
    forceRefresh = false
  }) {
    if (!columnSorting.length) {
      columnSorting = ['cardSerialNumber', 'asc']
    }

    const accessLevel = 'A'
    const params = {
      accessLevel,
      accessLevelCode: authorityId,
      accessId: `${accessLevel}${authorityId}`,
      activeFilters: [],
      sortBy: columnSorting[0],
      sortOrder: columnSorting[1],
      page,
      searchColumns: this.root.viewManageCardStore.selectedColumns,
      pageSize: limit,
      queries: [],
      statusGroupTypes: this.simpleFilter,
      userLevel: accessLevel
    }

    if (searchTerm) params.searchBy = searchTerm

    await super.getData({
      method: 'get',
      url: '/api/cards/cards',
      params,
      totalRecordsKey: 'totalCards',
      responseKey: 'cards',
      page,
      searchTerm,
      forceRefresh
    })
  }
}

export default CardsReissueStore
