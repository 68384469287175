import locales, { DEFAULT_LOCALE } from 'src/constants/locales'

export const getLanguageFromUrl = () => {
  const possibleLanguage = window.location.pathname.split('/')[1]

  if (possibleLanguage && locales.includes(possibleLanguage)) {
    return possibleLanguage
  }
}

const getFallbackLanguage = (forceDefault) => {
  if (forceDefault) return DEFAULT_LOCALE
  return getLanguageFromUrl() || DEFAULT_LOCALE
}

export default getFallbackLanguage
