import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const OverlayContainer = styled.div`
  width: 350px;
  background-color: ${CssVars.palette.neutral.white};
  box-shadow: ${CssVars.elevation.light.md};
  border-radius: ${CssVars.structure.borderRadius.default};
`

export const OverlayHeader = styled.div`
  padding: ${CssVars.structure.spacing.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${CssVars.structure.spacing.md};
  border-bottom: dashed 1px ${CssVars.palette.neutral.lighter};
`

export const OverlayContent = styled.div`
  max-height: 180px;
  overflow-x: auto;
  padding: ${CssVars.structure.spacing.md};
  display: flex;
  flex-direction: column;
  gap: ${CssVars.structure.spacing.md};
`

export const OverlayActions = styled.div`
  border-top: dashed 1px ${CssVars.palette.neutral.lighter};
  padding: ${CssVars.structure.spacing.md};
`
