// To be removed once Feature Flagging tool has been implemented
import { DEV_ENV, TEST_ENV, PREPROD_ENV, PROD_ENV } from 'constants/environments'
import { getEnvironment } from 'helpers/getEnvironment'

const SHOW_CDR_DOWNLOAD = 'SHOW_CDR_DOWNLOAD'
const SHOW_SELECT_CARDS_SEARCH = 'SHOW_SELECT_CARDS_SEARCH'
const SHOW_MERGED_SIDE_NAV = 'SHOW_MERGED_SIDE_NAV'
const SHOW_CDR_DATE_COLUMN = 'SHOW_CDR_DATE_COLUMN'
const SHOW_CARD_TYPE = 'SHOW_CARD_TYPE'
const env = getEnvironment()

const featureFlags = {
  [DEV_ENV]: {
    [SHOW_CDR_DOWNLOAD]: true,
    [SHOW_SELECT_CARDS_SEARCH]: true,
    [SHOW_CDR_DATE_COLUMN]: true,
    [SHOW_MERGED_SIDE_NAV]: true,
    [SHOW_CARD_TYPE]: true
  },
  [TEST_ENV]: {
    [SHOW_CDR_DOWNLOAD]: true,
    [SHOW_SELECT_CARDS_SEARCH]: true,
    [SHOW_CDR_DATE_COLUMN]: true,
    [SHOW_MERGED_SIDE_NAV]: true,
    [SHOW_CARD_TYPE]: true
  },
  [PREPROD_ENV]: {
    [SHOW_CDR_DOWNLOAD]: true,
    [SHOW_SELECT_CARDS_SEARCH]: true,
    [SHOW_CDR_DATE_COLUMN]: true
  },
  [PROD_ENV]: {
    [SHOW_CDR_DOWNLOAD]: true,
    [SHOW_SELECT_CARDS_SEARCH]: true,
    [SHOW_CDR_DATE_COLUMN]: true
  }
}

export const FEATURE_FLAGS = featureFlags[env]
