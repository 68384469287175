import { DropDown } from '@bp-digital/component-drop-down'
import { Text } from '@bp-digital/component-typography'
import { Icon } from '@bp-digital/component-icon'
import { getCardTypeContent } from '../../helpers'
import { Messaging, WhatsThisMessage } from '../common/CardOrder.styled'
import { useState } from 'react'
import { CardTypesModal } from './CardTypesModal'

const CardTypes = ({ cardTypes, cardTypeId, name, value, onChange, content }) => {
  const [isViewingWhatsThisModal, setIsViewingWhatsThisModal] = useState(false)
  return (
    <>
      {isViewingWhatsThisModal && <CardTypesModal onClose={() => setIsViewingWhatsThisModal(false)} />}
      <WhatsThisMessage
        onClick={() => setIsViewingWhatsThisModal(true)}
        dataAttributes={{ 'data-content-key': 'card_details_card_type_modal_button_text' }}
      >
        {content?.card_details_card_type_modal_button_text}
      </WhatsThisMessage>
      <DropDown
        id={name}
        options={cardTypes?.map(({ cardTypeId }) => ({
          id: cardTypeId,
          label: getCardTypeContent(cardTypeId, 'card_details_type', content)
        }))}
        selectedId={value}
        onChange={onChange}
        placeholder={content?.dropdown_select || 'dropdown_select'}
      />
      <Messaging>
        <Icon name='Info' size='lg' className='icon' />
        <Text as='p' className='message'>
          {getCardTypeContent(cardTypeId, 'card_type_description', content)}
        </Text>
      </Messaging>
    </>
  )
}

export default CardTypes
