//import { format } from 'light-date'

/**
 * The invoice list response has data in a different structure
 * to what the download request expects. This converts it.
 *
 * @param {Object} invoice - an invoice from invoice list response
 * @returns
 */
const mapToDownloadFormat = (invoice = {}) => {
  const clientNr = (invoice.accountId || '').slice(1) // removes 'N' at start
  // SPEOS needs SUMMARY-STATEMENT for download, API currently has it as Summary(X)
  // temporary p1 fix, should shift to backend
  const type = invoice.invoiceType?.toLowerCase().includes('summary-statement')
    ? 'SUMMARY-STATEMENT'
    : invoice.invoiceType
  // const [dd, mm, yyyy] = invoice.invoiceDate.split('/')
  // const date = [yyyy, mm, dd].join('-')
  const countryData = [
    { name: 'Austria', iso: 'AT' },
    { name: 'Belgium', iso: 'BE' },
    { name: 'Poland', iso: 'PL' },
    { name: 'The Netherlands', iso: 'NL' },
    { name: 'United Kingdom', iso: 'GB' },
    { name: 'Germany', iso: 'DE' },
    { name: 'Portugal', iso: 'PT' },
    { name: 'Luxembourg', iso: 'LU' }
  ]
  const countryNameToIso = countryName => countryData.find(country => country.name == countryName).iso
  return {
    documentNumber: +invoice.invoiceNumber,
    clientISOCode: invoice.issuerCountry.length == 2 ? invoice.issuerCountry : countryNameToIso(invoice.issuerCountry),
    invoiceType: type,
    clientNumber: +clientNr,
    invoiceDate: invoice.speosInvoiceDate
  }
}
export default mapToDownloadFormat
