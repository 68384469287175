import BaseDataStore from '../BaseDataStore'
//temporary till content comes in
import { AUDIT_ACTION_TYPES } from './../../pages/AuditPage/components/AuditFilters/AuditFiltersNodes'

class AuditStore extends BaseDataStore {
  constructor(root) {
    super(root, 'auditLogs', 'audit')
  }

  get rows() {
    return this.results.map(log => {
      const logBody = JSON.parse(JSON.stringify(log))
      if (!logBody.authorityIds) delete logBody.authorityIds
      if (!logBody.parentIds) delete logBody.parentIds
      if (!logBody.actionUser) delete logBody.actionUser
      if (!logBody.impersonatingActor) delete logBody.impersonatingActor

      return {
        type: 'MASTER',
        key: log.loggingTime,
        displayTime: new Date(log.loggingTime).toLocaleString(),
        ...log,
        body: JSON.stringify(logBody),
        userActionTypeDescription: `${
          AUDIT_ACTION_TYPES.find(action => action.key == log.userActionType.substr(0, 4))
            ? AUDIT_ACTION_TYPES.find(action => action.key == log.userActionType.substr(0, 4)).label
            : log.userActionType.substr(0, 4)
        } ${log.userActionType.substr(5, 1) == '1' ? 'Success' : 'Failed'}`
      }
    })
  }

  getAuditLogs = async ({ filters, page = 1, pageSize = this.pageSize }) => {
    const params = {}

    if (filters.find(ftr => ftr.filterBy === 'hierarchy')) {
      // hierarchy filter criteria is a concatenation accessLevel and accessLevelCode
      const fltrCriteria = filters.find(ftr => ftr.filterBy === 'hierarchy').filterCriteria
      if (fltrCriteria.length) {
        params.accessLevel = fltrCriteria[0].substr(0, 1)
        params.accessLevelCode = fltrCriteria[0].substr(1)
      }
    }

    params.filters = filters
      .filter(fltr => fltr.filterBy !== 'hierarchy' && fltr.filterBy !== 'filtermode' && fltr.filterCriteria.length)
      .map(fltr => ({ ...fltr, filterCriteria: fltr.filterCriteria.join(',') }))
    params.page = page
    params.PageSize = pageSize

    await super.getData({
      method: 'get',
      url: '/api/audit/audit/logs',
      params,
      totalRecordsKey: 'total',
      responseKey: 'logs',
      page,
      pageSize,
      filters
    })
  }
}

export default AuditStore
