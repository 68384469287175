import { ReactElement } from 'react'
import { Transaction } from '../../../../TransactionMap.types'
import ListItem from './ListItem/ListItem'

export interface TransactionListProps {
  cardholderName: string
  transactions?: Transaction[]
  selectedTransactionId?: string
  onSelectTransaction?: (transaction: Transaction) => void
}

const TransactionList = ({
  cardholderName,
  transactions,
  selectedTransactionId = '',
  onSelectTransaction
}: TransactionListProps): ReactElement => {
  if (!transactions) return <></>

  const handleOnClick = (transaction: Transaction) => {
    onSelectTransaction && onSelectTransaction(transaction)
  }

  return (
    <>
      {transactions.map(t => {
        return (
          <ListItem
            key={t.transactionId}
            cardholderName={cardholderName}
            transaction={t}
            onSelectTransaction={handleOnClick}
            isSelected={t.transactionId === selectedTransactionId}
          />
        )
      })}
    </>
  )
}

export default TransactionList
