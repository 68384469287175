import BaseDataStore from '../BaseDataStore'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'

class CardsRestrictionProfilesStore extends BaseDataStore {
  columnSorting = ['profileName', 'desc']
  defaultColumns = [
    'profileName',
    'createdByUserName',
    'lastUpdatedByUserName',
    'noOfCards',
    'spendingLimits',
    'productsAndServices',
    'siteLocations',
    'dayTime',
    'odometer'
  ]

  constructor(root) {
    super(root, 'restrictions', 'cards-restrictions')
  }

  get rows() {
    return this.results.map(profile => ({
      type: 'MASTER',
      ...profile,
      key: profile.profileId,
      createdDate: getUserFormattedDate(profile.createdDate, this.root.userStore.dateFormat),
      lastUpdatedDate: getUserFormattedDate(profile.lastUpdatedDate, this.root.userStore.dateFormat)
    }))
  }

  async getAvailableColumns(authorityId) {
    const params = {
      accessLevel: 'A',
      accessLevelCode: authorityId
    }
    
    return await super.getAvailableColumns({
      method: 'get',
      url: '/api/cards/cards/profilecols',
      availableColumnsKey: 'viewProfilesHeader.viewProfilesPreferenceColumns',
      extractNameFromPreferences: true,
      params
    })
  }

  async getRestrictions({
    authorityId = this.root.userStore.selectedAuthorityId,
    searchTerm = this.searchTerm,
    columnSorting = this.columnSorting,
    page = 1,
    limit = this.limits?.nextPageLimit || 15,
    forceRefresh = false
  }) {
    if (!columnSorting.length) {
      columnSorting = ['profileName', 'desc']
    }

    const accessLevel = 'A'

    const params = {
      accessLevel,
      accessLevelCode: authorityId,
      accessId: `${accessLevel}${authorityId}`,
      sortBy: columnSorting[0],
      sortOrder: columnSorting[1],
      pageSize: limit,
      page,
      searchColumns: this.selectedColumns,
      userlevel: accessLevel,
    }

    if (searchTerm) {
      params.searchBy = searchTerm
    }

    await super.getData({
      method: 'get',
      url: '/api/cards/profile/all',
      params,
      totalRecordsKey: 'totalProfiles',
      responseKey: 'profiles',
      page,
      searchTerm,
      forceRefresh
    })
  }
}

export default CardsRestrictionProfilesStore
