import { Grid } from './GridLayout.styled'

const GridLayout = ({ children }) => {
  return (
    <Grid>
      {children}
    </Grid>
  )
}

export default GridLayout