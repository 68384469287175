import styled from 'styled-components'

export const ActionWrapper = styled.div`
  padding-top: 0.25em;
  padding-bottom: 0.5em;

  h3 {
    font-size: 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 10px 15px;
  }
`

export const ActionList = styled.ul`
  width: 100%;

  top: 38px;
  background-color: #fff;
`

export const ActionListItem = styled.li`
  padding: 2px;
  border-top: 1px var(--color-grey-regular-100) solid;
  display: flex;
  gap: 10px;

  justify-content: space-between;
  align-items: center;
`

export const ActionWarning = styled.div`
  padding: 20px;
  text-align: center;
`

export const ActionLabel = styled.div``

export const ActionButtons = styled.div`
  display: flex;

  > div {
    padding: 4px 20px;
    background-color: var(--palette-state-danger-light);
    &:first-child {
      background-color: var(--palette-state-success-light);
    }

    button {
      padding: 2px 5px;
      height: auto;
    }
  }
`

export const DomainLabel = styled.div`
  padding: 5px 0;
  font-weight: bold;
`
