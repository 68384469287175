/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from 'styled-components'
import { Icon } from '@bp-digital/component-icon'
import { useFocusRef } from '../useFocusRef'

const Cell = styled.div`
  float: right;
  display: table;
  height: 100%;
  cursor: pointer;

  > span {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }
`

const CellExpanderFormatter = ({ isCellSelected, expanded, onCellExpand }) => {
  const { tabIndex } = useFocusRef(isCellSelected)
  const iconName = expanded ? 'DownLarge' : 'RightLarge'

  function handleKeyDown(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault()
      onCellExpand()
    }
  }

  return (
    <Cell>
      <span onClick={onCellExpand} onKeyDown={handleKeyDown} data-testid='table-item-expand'>
        <Icon name={iconName} tabIndex={tabIndex} />
      </span>
    </Cell>
  )
}

export default CellExpanderFormatter
