import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'
import image from 'src/assets/landing.jpeg'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey-regular-100);
  background-image: url(${image});
  background-size: cover;
  padding: 32px;

  @media only screen and ${breakpoints.device.lg} {
    padding: 84px;
  }
`

export const Box = styled.div`
  display: flex;
  min-width: 360px;
  max-width: min-content;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 4px;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 56px;
  padding: 16px;
`
