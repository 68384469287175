import { Button } from '@bp-digital/component-button'
import { Icon } from '@bp-digital/component-icon'
import { Popover } from '@bp-digital/component-popover'
import { ButtonGroup } from '@bp-digital/component-button-group'
import { ReactElement, useState } from 'react'
import { Transaction } from '../../TransactionMap.types'
import { OverlayActions, OverlayContainer, OverlayContent, OverlayHeader } from './TransMapOverlay.styled'
import { OverlayPanel, OverlayPanelId } from './TransMapOverlay.types'
import { ROUTE_TRANSACTIONS } from 'constants/routes'
import TransactionList from './components/TransactionList'
import useContent from 'hooks/useContent'

export interface TransMapOverlayProps {
  cardNo: string
  cardholderName: string
  selectedTransaction?: Transaction
  recentTransactions?: Transaction[]
  alertTransactions?: Transaction[]
  onSelectTransaction?: (transaction: Transaction) => void
}

const TransMapOverlay = ({
  cardNo,
  cardholderName,
  selectedTransaction,
  recentTransactions = [],
  alertTransactions = [],
  onSelectTransaction
}: TransMapOverlayProps): ReactElement => {
  const content = useContent('cards-view')
  const [panel, setPanel] = useState<OverlayPanelId>('mostRecent')

  const panels: OverlayPanel[] = [
    { id: 'mostRecent', label: content?.card_summary_transactions_recent },
    { id: 'alerted', label: content?.card_summary_transactions_alerted }
  ]
  const handleTransactionClick = (trans: Transaction) => {
    onSelectTransaction && onSelectTransaction(trans)
  }

  return (
    <OverlayContainer>
      <OverlayHeader>
        <ButtonGroup id='transmap-btngroup' items={panels} onChange={(id: string) => setPanel(id as OverlayPanelId)} />
        <Popover
          title={content?.hero_title}
          text={content?.manage_cards_transactions_map_message}
          dataAttributes={{ 'data-content-key': 'manage_cards_transactions_map_message' }}
          position='bottom'
          appearance='dark'
        >
          <Icon name='Info' size='lg' />
        </Popover>
      </OverlayHeader>
      <OverlayContent>
        <TransactionList
          cardholderName={cardholderName}
          selectedTransactionId={selectedTransaction?.transactionId}
          transactions={panel === 'mostRecent' ? recentTransactions : alertTransactions}
          onSelectTransaction={handleTransactionClick}
        />
      </OverlayContent>
      <OverlayActions>
        <Button
          to={`${ROUTE_TRANSACTIONS}?searchTerm=${cardNo}`}
          text={content?.card_summary_view_all}
          dataAttributes={{ 'data-content-key': 'card_summary_view_all' }}
        />
      </OverlayActions>
    </OverlayContainer>
  )
}

export default TransMapOverlay
