import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react'
import { useRootStore } from 'contexts/StoreContext'
import { Toast } from '@bp-digital/component-toast'
import SideNav from 'components/navigation/SideNav'
import TopNav from 'components/navigation/TopNav'
import Footer from 'components/navigation/Footer'
import ContentWrapper from 'components/layout/ContentWrapper'
import LayoutContainer from 'components/layout/LayoutContainer'
import GlobalErrorBoundary from './GlobalErrorBoundary'
import { ExitImpersonateButton } from './MasterLayout.styled'
import { ONEFLEETMIGRATIONSTATUS,ISONEFLEETMIGRATED } from 'constants/localStorage'

const MasterLayout = ({ children }) => {
  const { userStore } = useRootStore()
  const name = `${userStore.name.firstName} ${userStore.name.lastName}`

  const [notificationData, setNotificationData] = useState({})
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { state: { globalNotification } = {} } = useLocation()

  const exitUserImpersonation = () => {
    userStore.setImpersonatedUser('')
    localStorage.removeItem(ONEFLEETMIGRATIONSTATUS)
    localStorage.removeItem(ISONEFLEETMIGRATED)
  }

  useEffect(() => {
    if (globalNotification) {
      setNotificationData(globalNotification.data)
    }

    return () => {
      setNotificationData({})
    }
  }, [globalNotification])

  return (
    <>
      <SideNav
        isOpenOnMobile={isMobileMenuOpen}
        toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        userPermissions={userStore.userPermissions}
        brand={userStore.brand}
      />
      <LayoutContainer>
        {userStore.isAdmin && (
          <ExitImpersonateButton onClick={exitUserImpersonation}>EXIT IMPERSONATION</ExitImpersonateButton>
        )}
        <TopNav
          hasOpenMobileMenu={isMobileMenuOpen}
          toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          brand={userStore.brand}
          name={name}
        />

        <ContentWrapper>
          <GlobalErrorBoundary>{children}</GlobalErrorBoundary>
        </ContentWrapper>

        <Footer />
      </LayoutContainer>

      <Toast appearance='dark' toast={notificationData} />
    </>
  )
}

export default observer(MasterLayout)
