export const KEYBOARD_KEYCODE = {
  TAB: 9,
  ENTER: 13,
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ESCAPE: 27
}

export const KEYBOARD_NAV_ACTIVATION = [KEYBOARD_KEYCODE.ENTER, KEYBOARD_KEYCODE.ARROW_DOWN]

export const isKeyDownNext = e =>
  e.keyCode === KEYBOARD_KEYCODE.ARROW_DOWN || (!e.shiftKey && e.keyCode === KEYBOARD_KEYCODE.TAB)

export const isKeyDownPrev = e =>
  e.keyCode === KEYBOARD_KEYCODE.ARROW_UP || (e.shiftKey && e.keyCode === KEYBOARD_KEYCODE.TAB)

export const SELECTION = {
  ALL: 'ALL',
  SOME: 'SOME',
  NONE: 'NONE'
}
