import { useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router'
import { Button } from '@bp-digital/component-button'
import { Toast } from '@bp-digital/component-toast'
import { useRootStore } from 'contexts/StoreContext'
import useApiGetAuthorityDetails from 'hooks/api/useApiGetAuthorityDetails'
import useApiGetRestrictionProfile from 'hooks/api/useApiGetRestrictionProfile'
import useContent from 'hooks/useContent'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import RestrictionOverview from './components/RestrictionOverview'
import RestrictionBlock from './components/RestrictionBlock'
import UnlinkRestrictionProfile from './components/UnlinkRestrictionProfile'
import DeleteRestrictionProfile from './components/DeleteRestrictionProfile'
import RemoveRestrictionModal from './components/RemoveRestrictionModal'
import {
  getSpendingLimits,
  getProductsAndServices,
  getSiteLocationRestrictions,
  getDayTimeRestrictions,
  getOdometerReading
} from 'helpers/restrictions'
import { RestrictionsWrapper, ButtonWrapper, TopActions } from './RestrictionProfilePage.styled'
import { ROUTE_CARDS_RESTRICTIONS_EDIT } from 'constants/routes'
import { RESTRICTION_TYPES } from 'constants/restrictions'
import getCurrencySymbol from 'helpers/getCurrencySymbol'

const RestrictionProfilePage = () => {
  const content = useContent('cards-restrictions')
  const history = useHistory()
  const [removingRestrictionType, setRemovingRestrictionType] = useState(null)
  const { userStore } = useRootStore()
  const { selectedAuthorityId } = userStore
  const { profileId } = useParams()
  const { state: { profileName: defaultProfileName, createdDate, lastUpdatedDate, profile } = {} } = useLocation()
  const [toastData, setToastData] = useState({})

  const { data = {}, isLoading } = useApiGetRestrictionProfile(Number(profileId))
  const { data: authorityDetails = {} } = useApiGetAuthorityDetails(selectedAuthorityId, {
    enabled: !!selectedAuthorityId
  })

  const profileName = data?.restrictiondetails?.profileName || defaultProfileName
  const { restrictions: { currency, distanceUnit } = {}, serviceCode } = authorityDetails
  const {
    restrictiondetails: {
      isReadOnly,
      spendingLimitRestriction,
      productAndServicesRestriction,
      sitelocationRestrictions,
      dayTimeRestriction,
      odometerAndDistanceRestriction
    } = {}
  } = data

  return (
    <>
      <PageHeader
        title={profileName}
        subtitle={content?.restriction_profiles_hero_title || '...'}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <TopActions>
          <Button
            dataAttributes={{ 'data-content-key': 'masthead_back', 'data-testid': 'button-back' }}
            size='lg'
            iconName='LeftLarge'
            appearance='tertiary'
            onClick={history.goBack}
          >
            {content?.masthead_back}
          </Button>
          <ButtonWrapper align='end'>
            <UnlinkRestrictionProfile
              content={content}
              profile={profile}
              profileId={profileId}
              profileName={profileName}
              authorityId={selectedAuthorityId}
              serviceCode={serviceCode}
              notificationMessage={setToastData}
            />

            <DeleteRestrictionProfile
              content={content}
              profileId={profileId}
              profileReadOnly={isLoading || isReadOnly}
              authorityId={selectedAuthorityId}
              serviceCode={serviceCode}
              profileName={profileName}
            />
          </ButtonWrapper>
        </TopActions>
        <RestrictionsWrapper>
          <RestrictionOverview
            content={content}
            profileName={profileName}
            profileReadOnly={isLoading || isReadOnly}
            profileId={profileId}
            authorityId={selectedAuthorityId}
            createdDate={createdDate}
            lastUpdatedDate={lastUpdatedDate}
          />

          <RestrictionBlock
            isLoading={isLoading}
            content={content}
            restriction={spendingLimitRestriction}
            violationName='SpendingLimit'
            formattedRestrictions={
              spendingLimitRestriction
                ? getSpendingLimits(spendingLimitRestriction, getCurrencySymbol(currency), content)
                : []
            }
            title={content?.restriction_spending_limit_title}
            buttons={[
              {
                text: content?.restriction_profiles_edit || 'Edit',
                disabled: isLoading || isReadOnly,
                to: `${ROUTE_CARDS_RESTRICTIONS_EDIT.replace(':profileId', profileId).replace(
                  ':type?',
                  'spending-limits'
                )}`,
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_edit',
                  'data-testid': 'button-edit-spending-limits'
                }
              },
              {
                text: content?.restriction_profiles_remove || 'Remove',
                disabled: isLoading || isReadOnly || !spendingLimitRestriction,
                onClick: () => setRemovingRestrictionType(RESTRICTION_TYPES.SPENDING_LIMIT),
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_remove',
                  'data-testid': 'button-remove-spending-limit'
                }
              }
            ]}
          />

          <RestrictionBlock
            isLoading={isLoading}
            content={content}
            restriction={productAndServicesRestriction}
            violationName='ProductService'
            formattedRestrictions={
              productAndServicesRestriction
                ? getProductsAndServices(
                    productAndServicesRestriction,
                    getCurrencySymbol(currency),
                    content,
                    'restriction_profiles_'
                  )
                : []
            }
            title={content?.restriction_products_title}
            buttons={[
              {
                text: content?.restriction_profiles_edit || 'Edit',
                disabled: isLoading || isReadOnly,
                to: `${ROUTE_CARDS_RESTRICTIONS_EDIT.replace(':profileId', profileId).replace(
                  ':type?',
                  'products-and-services'
                )}`,
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_edit',
                  'data-testid': 'button-edit-product-services'
                }
              },
              {
                text: content?.restriction_profiles_remove || 'Remove',
                disabled: isLoading || isReadOnly || !productAndServicesRestriction,
                onClick: () => setRemovingRestrictionType(RESTRICTION_TYPES.PRODUCTS_AND_SERVICES),
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_remove',
                  'data-testid': 'button-remove-product-services'
                }
              }
            ]}
          />

          <RestrictionBlock
            isLoading={isLoading}
            content={content}
            restriction={sitelocationRestrictions}
            violationName='Sites'
            formattedRestrictions={
              sitelocationRestrictions
                ? getSiteLocationRestrictions(sitelocationRestrictions, content, 'restriction_profiles_')
                : []
            }
            title={content?.restriction_site_title}
            buttons={[
              {
                text: content?.restriction_profiles_edit || 'Edit',
                disabled: isLoading || isReadOnly,
                to: `${ROUTE_CARDS_RESTRICTIONS_EDIT.replace(':profileId', profileId).replace(':type?', 'sites')}`,
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_edit',
                  'data-testid': 'button-edit-site-locations'
                }
              },
              {
                text: content?.restriction_profiles_remove || 'Remove',
                disabled: isLoading || isReadOnly || !sitelocationRestrictions,
                onClick: () => setRemovingRestrictionType(RESTRICTION_TYPES.SITE_LOCATIONS),
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_remove',
                  'data-testid': 'button-remove-sites'
                }
              }
            ]}
          />

          <RestrictionBlock
            isLoading={isLoading}
            content={content}
            restriction={dayTimeRestriction}
            violationName='DayTime'
            formattedRestrictions={
              dayTimeRestriction ? getDayTimeRestrictions(dayTimeRestriction, content, 'restriction_profiles_') : []
            }
            title={content?.restriction_date_time_title}
            buttons={[
              {
                text: content?.restriction_profiles_edit || 'Edit',
                disabled: isLoading || isReadOnly,
                to: `${ROUTE_CARDS_RESTRICTIONS_EDIT.replace(':profileId', profileId).replace(
                  ':type?',
                  'day-and-time'
                )}`,
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_edit',
                  'data-testid': 'button-edit-day-time'
                }
              },
              {
                text: content?.restriction_profiles_remove || 'Remove',
                disabled: isLoading || isReadOnly || !dayTimeRestriction,
                onClick: () => setRemovingRestrictionType(RESTRICTION_TYPES.DAY_AND_TIME),
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_remove',
                  'data-testid': 'button-remove-day-time'
                }
              }
            ]}
          />

          <RestrictionBlock
            isLoading={isLoading}
            content={content}
            restriction={odometerAndDistanceRestriction}
            violationName='Odometer'
            formattedRestrictions={
              odometerAndDistanceRestriction
                ? getOdometerReading(odometerAndDistanceRestriction, distanceUnit, content, 'restriction_profiles_')
                : []
            }
            title={content?.restriction_profiles_odometer_title}
            buttons={[
              {
                text: content?.restriction_profiles_edit || 'Edit',
                disabled: isLoading || isReadOnly,
                to: `${ROUTE_CARDS_RESTRICTIONS_EDIT.replace(':profileId', profileId).replace(':type?', 'odometer')}`,
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_edit',
                  'data-testid': 'button-edit-odometer'
                }
              },
              {
                text: content?.restriction_profiles_remove || 'Remove',
                disabled: isLoading || isReadOnly || !odometerAndDistanceRestriction,
                onClick: () => setRemovingRestrictionType(RESTRICTION_TYPES.ODOMETER),
                dataAttributes: {
                  'data-content-key': 'restriction_profiles_remove',
                  'data-testid': 'button-remove-odometer'
                }
              }
            ]}
          />
        </RestrictionsWrapper>
        {!!removingRestrictionType && (
          <RemoveRestrictionModal
            selectedAuthorityId={selectedAuthorityId}
            content={content}
            profileId={profileId}
            profileName={profileName}
            type={removingRestrictionType}
            onClose={() => setRemovingRestrictionType(null)}
          />
        )}
        <Toast appearance='dark' toast={toastData} />
      </InnerPageWrapper>
    </>
  )
}

export default RestrictionProfilePage
