import { observer } from 'mobx-react'
import Paper from 'components/surfaces/Paper'
import Skeleton from 'react-loading-skeleton'
import TwoColumn from 'components/layout/TwoColumn'
import { DropDown } from '@bp-digital/component-drop-down'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import useContent from 'hooks/useContent'
import { useState } from 'react'
import useApiGetPreferences from 'hooks/api/useApiGetPreferences'
import Cookies from 'js-cookie'

const getLocaleFromList = (listOfCodes, code) => {
  const label = listOfCodes.find(({ id }) => id === code)?.label
  const regex = /\(([^)]+)\)/ // Finds value between (BRACKETS)
  const locale = regex.exec(label)[1]
  return locale?.toLowerCase()
}

const AccountPreferences = ({ setAlert, userStore }) => {
  const { user, selectedAuthorityId } = userStore
  const content = useContent('profile-preferences')
  const [showBtnLoading, setShowBtnLoading] = useState(false)
  const [preferences, setPreferences] = useState(null)
  const { data: listOfPreferences } = useApiGetPreferences(selectedAuthorityId, {
    staleTime: Infinity,
    enabled: !!selectedAuthorityId
  })

  const handleSave = async () => {
    setShowBtnLoading(true)
    try {
      const result = await userStore.updateUserDetails({
        basicDetails: null,
        preferences: {
          dateFormat: '',
          cSVDelimiter: '',
          numberFormat: '',
          measurement: '',
          languageCode: '',
          emediaAttachSize: '',
          ...preferences
        },
        contactVerified: false
      })

      if (result.status === 200) {
        const hasChangedLanguage = !!preferences.languageCode
        await userStore.getUserLocale()
        const user = await userStore.getUserDetails()
        userStore.setUserDetails(user.data.userDetails)

        if (hasChangedLanguage) {
          const languageCode = preferences.languageCode
          const urlParts = window.location.pathname.split('/')
          const locale = getLocaleFromList(listOfPreferences.languageCodes, languageCode)
          urlParts[1] = locale
          Cookies.set('bpf-locale', locale, { secure: true })
          history.pushState({}, '', urlParts.join('/'))
          userStore.setLocale(locale)
        }

        setPreferences(null)
        setAlert({
          visible: true,
          text: content?.success_message || '...',
          state: 'success'
        })
      }
    } catch (e) {
      console.warn(e)
      setAlert({
        visible: true,
        text: content?.failure_message || '...',
        state: 'error'
      })
    }
    setShowBtnLoading(false)
  }

  const button = {
    text: content?.save || 'Update',
    iconName: 'Save',
    onClick: handleSave,
    disabled: (preferences === null) | showBtnLoading,
    isLoading: showBtnLoading,
    dataAttributes: {
      'data-testid': 'button-save-account-preferences'
    }
  }

  const getMeasurementOptions = content => [
    { label: content.measurment_option1, id: '1' },
    { label: content.measurment_option2, id: '2' },
    { label: content.measurment_option3, id: '3' }
  ]

  return (
    <Paper title={content?.account_preferences || '...'} button={button}>
      <TwoColumn>
        {listOfPreferences && user?.preferences && content ? (
          <>
            <DropDown
              label={content?.language || '...'}
              id='languageCode'
              placeholder={content?.dropdown_message_placeholder || 'dropdown_message_placeholder'}
              options={listOfPreferences.languageCodes}
              onChange={value => setPreferences({ ...preferences, languageCode: value })}
              defaultSelectedId={user?.preferences?.languageCode?.value}
            />
            <DropDown
              label={content?.numberFormat || '...'}
              placeholder={content?.dropdown_message_placeholder || 'dropdown_message_placeholder'}
              id='numberFormat'
              options={listOfPreferences.numberFormate}
              onChange={value => setPreferences({ ...preferences, numberFormat: value })}
              defaultSelectedId={user?.preferences?.numberFormat?.value}
            />
            <DropDown
              label={content?.dateFormat || '...'}
              id='dateFormat'
              placeholder={content?.dropdown_message_placeholder || 'dropdown_message_placeholder'}
              options={listOfPreferences.dateFormate}
              onChange={value => setPreferences({ ...preferences, dateFormat: value })}
              defaultSelectedId={user?.preferences?.dateFormat?.value}
            />
            <DropDown
              label={content?.csvDelimiter || '...'}
              id='cSVDelimiter'
              placeholder={content?.dropdown_message_placeholder || 'dropdown_message_placeholder'}
              options={listOfPreferences.csvDelimiter}
              onChange={value => setPreferences({ ...preferences, cSVDelimiter: value })}
              defaultSelectedId={user?.preferences?.cSVDelimiter?.value}
            />
            <RadioButtonGroup
              label={content?.measurements || '...'}
              id='measurement'
              options={getMeasurementOptions(content)}
              onChange={value => setPreferences({ ...preferences, measurement: value })}
              defaultSelectedId={user?.preferences?.measurement?.value}
            />
          </>
        ) : (
          <>
            <Skeleton count={2} height={70} style={{ margin: '8px 0' }} />
            <Skeleton count={2} height={70} style={{ margin: '8px 0' }} />
            <Skeleton count={1} height={170} />
          </>
        )}
      </TwoColumn>
    </Paper>
  )
}

export default observer(AccountPreferences)
