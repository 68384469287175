import { useState } from 'react'
import { Modal } from '@bp-digital/component-modal'
import { Button } from '@bp-digital/component-button'
import { TextField } from '@bp-digital/component-text-field'
import { useRootStore } from 'contexts/StoreContext'
import useContent from 'hooks/useContent'
import { useHistory } from 'react-router'
import { ROUTE_REDIRECT } from 'constants/routes'
import { Icon } from '@bp-digital/component-icon'
import {
  ModalContentWrapper,
  OverviewWrapper,
  SearchWrapper,
  HierarchyWrapper,
  HierarchyList,
  HierarchyListItem,
  Label
} from './AuthoritySelector.styled'

const AuthoritySelectionModal = ({ setIsOpen }) => {
  const { userStore } = useRootStore()
  const historyUse = useHistory()
  const content = useContent('masthead')
  const [filter, setFilter] = useState('')
  const [selection, setSelection] = useState('')

  const filteredHierarchy = JSON.parse(JSON.stringify(userStore.hierarchy))

  let groupCount = userStore.hierarchy.groups.filter(group => group.groupId != 'ALL').length
  const parentCount = userStore.hierarchy.parents.length
  const authorityCount = userStore.hierarchy.authorities.length

  if (selection) {
    // we are viewing a specific group or parent hierarchy tree
    if (selection.startsWith('G') && filteredHierarchy.groups.find(group => group.groupId === selection.substring(1))) {
      filteredHierarchy.groups = filteredHierarchy.groups.filter(({ groupId }) => groupId === selection.substring(1))
      filteredHierarchy.parents = filteredHierarchy.groups[0].parents
        ? filteredHierarchy.groups[0].parents.map(parentId =>
            filteredHierarchy.parents.find(parent => parent.parentId == parentId)
          )
        : []
      filteredHierarchy.authorities = filteredHierarchy.groups[0].authorityIds.map(authorityId =>
        filteredHierarchy.authorities.find(auth => auth.authorityId == authorityId)
      )
    }
    if (
      selection.startsWith('P') &&
      filteredHierarchy.parents.find(parent => parent.parentId === selection.substring(1))
    ) {
      filteredHierarchy.parents = filteredHierarchy.parents.filter(
        ({ parentId }) => parentId === selection.substring(1)
      )
      filteredHierarchy.authorities = filteredHierarchy.parents[0].authorityIds.map(authorityId =>
        filteredHierarchy.authorities.find(auth => auth.authorityId == authorityId)
      )
    }
  }

  // if group ALL is found - split to another display variable
  if (filteredHierarchy.groups.find(group => group.groupId == 'ALL')) {
    filteredHierarchy.overview = filteredHierarchy.groups.find(group => group.groupId == 'ALL')
    filteredHierarchy.groups = filteredHierarchy.groups.filter(group => group.groupId != 'ALL')
  }

  // filtering as per the filter value - except for selection
  filteredHierarchy.groups = filteredHierarchy.groups.filter(
    ({ groupName, groupId }) =>
      groupName.toLowerCase().includes(filter.toLowerCase()) ||
      groupId.toLowerCase().includes(filter.toLowerCase()) ||
      selection == `G${groupId}`
  )
  filteredHierarchy.parents = filteredHierarchy.parents.filter(
    ({ parentName, parentId }) =>
      parentName.toLowerCase().includes(filter.toLowerCase()) ||
      parentId.toLowerCase().includes(filter.toLowerCase()) ||
      selection == `P${parentId}`
  )
  filteredHierarchy.authorities = filteredHierarchy.authorities.filter(
    ({ customerName, authorityId }) =>
      customerName.toLowerCase().includes(filter.toLowerCase()) ||
      authorityId.toLowerCase().includes(filter.toLowerCase())
  )

  // sort by name and then by Id's
  filteredHierarchy.groups.sort((a, b) => {
    if (a.groupName < b.groupName) return -1
    if (a.groupName > b.groupName) return 1
    if (a.accessLevelCode < b.accessLevelCode) return -1
    return 1
  })
  filteredHierarchy.parents.sort((a, b) => {
    if (a.parentName < b.parentName) return -1
    if (a.parentName > b.parentName) return 1
    if (a.accessLevelCode < b.accessLevelCode) return -1
    return 1
  })
  filteredHierarchy.authorities.sort((a, b) => {
    if (a.customerName < b.customerName) return -1
    if (a.customerName > b.customerName) return 1
    if (a.accessLevelCode < b.accessLevelCode) return -1
    return 1
  })

  const includeSearch = userStore.hierarchy.parents.length + userStore.hierarchy.authorities.length > 20

  const mastHeadSelection = selectedHierarchy => {
    userStore.setSelectedHierarchy(selectedHierarchy)

    if (userStore.isAdmin && userStore.locale !== 'en') {
      userStore.setLocale('en')
      history.pushState({}, '', ['', 'en', 'redirect'].join('/'))
    } else {
      historyUse.push(ROUTE_REDIRECT)
    }
    userStore.init()
    setIsOpen(false)
  }

  return (
    <Modal
      onDismiss={() => setIsOpen(false)}
      visible={true}
      title={content?.masthead_change_authority_title}
      size='md'
      secondaryAction={{
        text: content?.masthead_change_authority_cancel || 'masthead_change_authority_cancel',
        appearance: 'tertiary',
        onClick: () => setIsOpen(false)
      }}
    >
      <ModalContentWrapper className={includeSearch ? 'full-height' : ''}>
        {filteredHierarchy.overview && (
          <OverviewWrapper>
            <Button
              appearance='primary'
              size='md'
              data-testid={`button-group-select-all`}
              disabled={
                userStore.selectedHierarchy.accessLevel === 'G' && userStore.selectedHierarchy.accessLevelCode === 'ALL'
              }
              onClick={() =>
                mastHeadSelection({
                  ...filteredHierarchy.overview,
                  accessLevel: 'G',
                  accessLevelCode: 'ALL'
                })
              }
            >
              {content?.menu_top_menu_title_dashboard || 'Overview'}
            </Button>
          </OverviewWrapper>
        )}

        {includeSearch && (
          <SearchWrapper>
            <TextField
              placeholder={content?.masthead_search || '[masthead_search]'}
              value={filter}
              textChangeHandler={newVal => {
                setFilter(newVal)
              }}
            />
          </SearchWrapper>
        )}

        {groupCount > 0 && !selection.startsWith('P') && (
          <HierarchyWrapper>
            <h3>
              {filteredHierarchy.groups.length > 1
                ? content?.masthead_groups || '[masthead_groups]'
                : content?.masthead_group || '[masthead_group]'}{' '}
              ({filteredHierarchy.groups.length != groupCount ? `${filteredHierarchy.groups.length} /` : ''}
              {groupCount})
            </h3>

            <HierarchyList>
              {filteredHierarchy.groups.map(group => (
                <HierarchyListItem key={group.groupId} className={selection === `G${group.groupId}` ? 'selected' : ''}>
                  <Button
                    appearance='primary'
                    size='md'
                    data-testid={`button-group-select-${group.groupId}`}
                    disabled={
                      userStore.selectedHierarchy.accessLevel === 'G' &&
                      userStore.selectedHierarchy.accessLevelCode === group.groupId
                    }
                    onClick={() => mastHeadSelection({ ...group, accessLevel: 'G', accessLevelCode: group.groupId })}
                  >
                    {`G-${group.groupId}`}
                  </Button>
                  <Label>{group.groupName}</Label>
                  <button
                    className='hierarchy-detail'
                    onClick={() => {
                      setFilter('')
                      setSelection(selection === `G${group.groupId}` ? '' : `G${group.groupId}`)
                    }}
                  >
                    <Icon name={selection === `G${group.groupId}` ? 'LeftLarge' : 'RightLarge'} size='md'></Icon>
                  </button>
                </HierarchyListItem>
              ))}
            </HierarchyList>
          </HierarchyWrapper>
        )}

        {filteredHierarchy.parents.length > 0 && (
          <HierarchyWrapper>
            <h3>
              {filteredHierarchy.parents.length > 1
                ? content?.masthead_parents || '[masthead_parents]'
                : content?.masthead_parent || '[masthead_parent]'}
              {filteredHierarchy.parents.length != parentCount ? ` (${filteredHierarchy.parents.length}/` : ' ('}
              {parentCount})
            </h3>
            <HierarchyList>
              {filteredHierarchy.parents.map(parent => (
                <HierarchyListItem
                  key={parent.parentId}
                  className={selection === `P${parent.parentId}` ? 'selected' : ''}
                >
                  <Button
                    appearance='primary'
                    size='md'
                    data-testid={`button-parent-select-${parent.parentId}`}
                    disabled={
                      userStore.selectedHierarchy.accessLevel === 'P' &&
                      userStore.selectedHierarchy.accessLevelCode === parent.parentId
                    }
                    onClick={() => mastHeadSelection({ ...parent, accessLevel: 'P', accessLevelCode: parent.parentId })}
                  >
                    {parent.parentId}
                  </Button>
                  <Label>{parent.parentName}</Label>

                  <button
                    className='hierarchy-detail'
                    onClick={() => {
                      setFilter('')
                      setSelection(selection === `P${parent.parentId}` ? '' : `P${parent.parentId}`)
                    }}
                  >
                    <Icon name={selection === `P${parent.parentId}` ? 'LeftLarge' : 'RightLarge'} size='md'></Icon>
                  </button>
                </HierarchyListItem>
              ))}
            </HierarchyList>
          </HierarchyWrapper>
        )}

        <HierarchyWrapper>
          {
            <h3>
              {content?.masthead_authorities || '[masthead_authorities]'}
              {filteredHierarchy.authorities.length != authorityCount
                ? ` (${filteredHierarchy.authorities.length}/`
                : ' ('}
              {authorityCount})
            </h3>
          }
          <HierarchyList>
            {filteredHierarchy.authorities.map(auth => (
              <HierarchyListItem key={auth.authorityId}>
                <Button
                  appearance='primary'
                  size='md'
                  data-testid={`button-auth-select-${auth.authorityId}`}
                  disabled={
                    userStore.selectedHierarchy.accessLevel === 'A' &&
                    userStore.selectedHierarchy.accessLevelCode === auth.authorityId
                  }
                  onClick={() => mastHeadSelection({ ...auth, accessLevel: 'A', accessLevelCode: auth.authorityId })}
                >
                  {auth.authorityId}
                </Button>
                <Label>{auth.customerName}</Label>
              </HierarchyListItem>
            ))}
          </HierarchyList>
        </HierarchyWrapper>
      </ModalContentWrapper>
    </Modal>
  )
}

export default AuthoritySelectionModal
