import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;
  height: calc(100vh - 65px - 208px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ErrorText = styled.p`
  margin: 16px auto;
  font-weight: bold;
`
