import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY_PREFIX = 'charger/transactions-'

type paramType = string | undefined
type params = {
  accessLevel: paramType
  accessLevelCode: paramType
  siteId: paramType
  chargePointId?: paramType
  dateFrom?: string
}

const queryFn = async ({ accessLevel, accessLevelCode, siteId, chargePointId }: params) => {
  if (!accessLevel || !accessLevelCode) throw new Error('No Authority Id given to /charger/transactions endpoint')

  const response = await axios({
    method: 'get',
    url: '/api/evis/v1/sites/details/charge-data',
    params: {
      accessLevel: accessLevel,
      accessLevelCode: accessLevelCode,
      chargePointId,
      dateFrom: new Date(new Date('1983-01-01T01:01:01+01:00').toUTCString()).toISOString(),
      pageSize: 10,
      page: 0,
      siteId
    }
  })

  return response.data
}

const useApiGetChargerTransactions = (
  { accessLevel, accessLevelCode, siteId, chargePointId }: params,
  options: { enabled?: boolean }
) => {
  return useQuery(
    QUERY_KEY_PREFIX + siteId + chargePointId,
    () => queryFn({ accessLevel, accessLevelCode, siteId, chargePointId }),
    {
      staleTime: 120000,
      ...options
    }
  )
}

export default useApiGetChargerTransactions
