import styled from 'styled-components'

export const CommandBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const CommandBarStart = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const CommandBarEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
`
