import { useState } from 'react'
import { TextField } from '@bp-digital/component-text-field'
import { Text } from '@bp-digital/component-typography'
import { ActionCard } from '@bp-digital/component-action-card'
import { IconList } from '@bp-digital/component-icon-list'
import axios from 'axios'
import { queryClient } from 'src'
import { Spacer } from 'styles/common.styled'

const RestrictionOverview = ({
  content = {},
  profileId,
  authorityId,
  profileName,
  profileReadOnly,
  createdDate,
  lastUpdatedDate
}) => {
  const [hasNameChanged, setHasNameChanged] = useState(false)
  const [error, setError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [name, setName] = useState(profileName)
  const genericErrorMessage = content.restriction_profiles_rename_profile_required_validation_message || 'Error'

  const handleSaveName = async () => {
    console.info('Name?', name, name.trim())
    if (name.trim() === '') {
      setError(genericErrorMessage)
    } else if (name.trim() === profileName) {
      const alreadyExistsError =
        content.restriction_profiles_notification_rename_already_exists_singular ||
        'restriction_profiles_notification_rename_already_exists_singular'
      setError(alreadyExistsError)
    } else {
      setIsSaving(true)

      try {
        const params = {
          accessLevel: 'A',
          accessLevelCode: authorityId
        }

        await axios({
          method: 'put',
          url: '/api/cards/restrictions/profile',
          data: {
            cardRestrictionDetails: [
              {
                profileId: +profileId,
                profileName: name,
                authorityId,
                cardId: null,
                isUnlink: false,
                isDelete: false,
                isProfileNameChanged: true
              }
            ]
          },
          params
        })

        queryClient.invalidateQueries(`cards/restriction-profile-${profileId}`)
        setIsSaving(false)
        setHasNameChanged(false)
      } catch (e) {
        console.warn('Error ocurred saving profile name', e)
        setError(genericErrorMessage)
        setIsSaving(false)
      }
    }
  }

  return (
    <ActionCard
      title={content.manage_cards_edit_restriction_title || '...'}
      button={{
        text: content.manage_cards_full_details_edit_buttons_save || 'Save',
        iconName: 'Save',
        disabled: !hasNameChanged,
        isLoading: isSaving,
        onClick: handleSaveName
      }}
    >
      <TextField
        label={content.restriction_profile_name_label || '...'}
        defaultValue={profileName}
        error={!!error}
        errorMessage={error}
        disabled={profileReadOnly}
        textChangeHandler={newValue => {
          console.info('New?', newValue)
          setError('')
          setHasNameChanged(true)
          setName(newValue)
        }}
        dataAttributes={{
          'data-testid': 'input-profile-name',
          'data-content-key': 'restriction_profile_name_label'
        }}
      />
      <Spacer />
      <IconList
        items={[
          {
            iconName: 'Clock',
            text: (
              <Text dataAttributes={{ 'data-content-key': 'restriction_profiles_expanded_created_by' }}>
                {content.restriction_profiles_expanded_created_by || ''} {createdDate}
              </Text>
            )
          },
          {
            iconName: 'Clock',
            text: (
              <Text dataAttributes={{ 'data-content-key': 'restriction_profiles_expanded_last_updated_by' }}>
                {content.restriction_profiles_expanded_last_updated_by || ''} {lastUpdatedDate}
              </Text>
            )
          }
        ]}
      />
    </ActionCard>
  )
}

export default RestrictionOverview
