import { Button } from '@bp-digital/component-button'
import { CommandMenu } from '@bp-digital/component-command-menu'
import breakpoints from 'src/styles/breakpoints'
import { Wrapper, TitleWrapper, Title, ButtonWrapper } from './Paper.styled'

const Paper = ({ title, button, commands, children, testId = 'paper-testid' }) => {
  const isMobile = window.matchMedia(`(max-width: ${breakpoints.size.sm})`).matches

  return (
    <Wrapper data-testid={testId}>
      {title && (
        <TitleWrapper>
          <Title>{title}</Title>
          {button && !isMobile && (
            <ButtonWrapper>
              <Button {...button} />
            </ButtonWrapper>
          )}
          {!!commands && (
            <ButtonWrapper>
              <CommandMenu items={commands.items} title={commands.title} onChange={commands.onChange} />
            </ButtonWrapper>
          )}
        </TitleWrapper>
      )}
      {children}
      {button && isMobile && (
        <ButtonWrapper>
          <Button {...button} />
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

export default Paper
