import styled from 'styled-components'
import { widthOfSideNav } from '../../navigation/SideNav/SideNav.styled'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media only screen and ${breakpoints.device.lg} {
    padding-left: ${widthOfSideNav};
  }
`
