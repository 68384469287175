import styled from 'styled-components'

import breakpoints from 'src/styles/breakpoints'

export const FakeButtonWrapper = styled.div``

export const FakeInputButton = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: rgb(64, 64, 64);
  cursor: pointer;
  border-radius: 5px;
  color: rgb(238, 238, 238);
  padding: 9px 22px;
  border: 1px solid rgb(64, 64, 64);
  display: inline-block;
  text-align: center;
  width:auto;
  border: 1px solid rgb(64, 64, 64);
  &:hover {
    background-color: rgb(17, 17, 17);
  }
  &.disabled {
    background-color: rgb(64, 64, 64);
    opacity: 0.3;
    &:hover {
      cursor: not-allowed;
      background-color: rgb(64, 64, 64);
    }
  }
  }
`
export const FakeFileLabel = styled.span`
  padding: 0 0 0 1.4rem;
`
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const UploadFileSelect = styled.div`
  width: 50%;
`

export const HiddenFileSelectInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const UploadFileButton = styled.div`
  display: flex;
  justify-content: end;
  width: 50%;

  button {
    width: auto;
  }
`

export const UploadRow = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid black;
  margin-bottom: 32px;
  padding-right: 16px;
`

export const DownloadRow = styled(UploadRow)`
  border-bottom: 0 none;
  @media only screen and ${breakpoints.device.sm} {
    padding-right: 0;
  }
`

export const FileColumn = styled.div`
  width: 50%;
  small {
    line-height: 1;
  }
`

export const FileDownloadColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  button {
    width: auto;
  }
`
export const FileItem = styled.div`
  padding: 4px 8px;
  height: 4rem;
  ${props => props?.alignRight && 'display: flex; justify-content:right;'};
`

export const Spacer = styled.span`
  margin-left: 2rem;
`
