import { Heading, Text } from '@bp-digital/component-typography'
import { Container, Row, Column, ButtonWrapper } from '../../AddUserPage.styled'
import { Button } from '@bp-digital/component-button'
import replaceVariablesInString from 'helpers/replaceVariablesInString'

const Summary = ({ userDetails, selectedAuthorities, onSubmit, onBack, isLoading, content }) => (
  <>
    <Heading as='h3'>{`${content?.add_user_summary_title || 'add_user_summary_title'} ${userDetails?.firstName} ${
      userDetails?.lastName
    }`}</Heading>
    <Text>
      {replaceVariablesInString(content?.add_user_summary_description, { username: userDetails?.email }) ||
        'add_user_summary_description'}
    </Text>
    <Heading as='h4'>{content?.add_user_steps_user_details || 'add_user_steps_user_details'}</Heading>
    <Container>
      <Row>
        <Column>
          <Text>{content?.add_user_name || 'add_user_name'}</Text>
        </Column>
        <Column>
          <Text>{userDetails?.firstName}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>{content?.add_user_last_name || 'add_user_last_name'}</Text>
        </Column>
        <Column>
          <Text>{userDetails?.lastName}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>{content?.add_user_job_title || 'add_user_job_title'}</Text>
        </Column>
        <Column>
          <Text>{userDetails?.jobTitle}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>{content?.add_user_email || 'add_user_email'}</Text>
        </Column>
        <Column>
          <Text>{userDetails?.email}</Text>
        </Column>
      </Row>
    </Container>
    <Heading as='h4'>{content?.add_user_steps_access || 'add_user_steps_access'}</Heading>
    <Container>
      {selectedAuthorities &&
        selectedAuthorities.map(item => (
          <Row key={item.authorityId}>
            <Column>
              <Text as='span'>{item.authorityName}</Text>
              {item.secondAuthorityName && <Text as='small'>{item.secondAuthorityName}</Text>}
            </Column>
            <Column>
              <Text as='span'>{item.authorityId}</Text>
            </Column>
            <Column>
              <Text as='span'>
                {content[`user_permission_role_${item.selectedRole.id}`] ||
                  `user_permission_role_${item.selectedRole.id}`}
              </Text>
            </Column>
          </Row>
        ))}
    </Container>
    <ButtonWrapper>
      <Button appearance='secondary' onClick={onBack} dataAttributes={{ 'data-testid': 'button-new-user-step-3' }}>
        {content?.add_user_back || 'add_user_back'}
      </Button>
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onSubmit}
        dataAttributes={{ 'data-testid': 'button-new-user-submit' }}
      >
        {content?.add_user_summary_invitation_sent || 'add_user_summary_invitation_sent'}
      </Button>
    </ButtonWrapper>
  </>
)

export default Summary
