import ReactDOM from 'react-dom'
import { useState } from 'react'
import { Modal } from '@bp-digital/component-modal'
import { Text } from '@bp-digital/component-typography'
import { Icon } from '@bp-digital/component-icon'
import { RadioButtonGroup } from '@bp-digital/component-radio-button-group'
import { TextField } from '@bp-digital/component-text-field'
import { STOP_CARD_REASONS } from 'constants/cards'
import getBrandFromDomain from 'helpers/getBrandFromDomain'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { Message } from './StopCard.styled'
import { useHistory } from 'react-router-dom'
import { ROUTE_CARDS_ORDER } from 'constants/routes'
import axios from 'axios'
import { useRootStore } from 'contexts/StoreContext'
import { EMAILADDRESS } from 'constants/onefleetmigrated'

const root = document.getElementById('root')

const STEPS = {
  FORM: 1,
  CONFIRM: 2
}

const StopCard = ({
  cardId,
  countryCode,
  cardStatusId,
  authorityId,
  onClose,
  content,
  setToastData,
  cardDetails,
  isLoading
}) => {
  const localStorage = window.localStorage
  const history = useHistory()
  const { userStore: {  onefleetmigratedstatus } } = useRootStore() 
  const brand = getBrandFromDomain()
  const noblockcardorder = !(onefleetmigratedstatus > 1)
  const noblockstopcard = !(onefleetmigratedstatus > 2)
  const [stolenCardPoliceRefNumber, setStolenCardPoliceRefNumber] = useState('')
  const [stopCardReason, setStopCardReason] = useState(null)
  const [orderReplacementCards, setOrderReplacementCards] = useState(noblockcardorder ? true : false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(STEPS.FORM)
  const { restrictions, embossingDetails, general } = cardDetails || {}
  const handleStopCard = async () => {
    setIsSubmitting(true)
    if (orderReplacementCards === true && noblockcardorder) {
      localStorage.setItem(
        'replacement-card-details',
        JSON.stringify({ 
          cardSerialNumber: embossingDetails.cardSerialNumber,
          reasonCode: stopCardReason,
          cardTypeId: embossingDetails.cardType || 4,
          vehicleRegistration: embossingDetails.vehicleRegistration,
          driverName: embossingDetails.cardHolderName,
          line3: embossingDetails.vehicleRegistration || embossingDetails.cardHolderName,
          region: restrictions.international,
          addinfo: general.addInfo,
          odometer: general.odometer,
          fuelAndCharge: embossingDetails.fuelAndCharge,
          gasoleoProfessional: embossingDetails.gasoleoProfessional
        })
      )
      history.push(`${ROUTE_CARDS_ORDER}?replaceCard=true`)
    } else {
      try {
        await axios({
          method: 'post',
          url: '/api/cards/card-action/stop-cards',
          data: {
            parentId: null,
            groupId: null,
            authorityId: authorityId,
            userLevel: 'A',
            stopCardsDetails: [
              {
                cardId: cardId,
                countryCode: countryCode,
                cardStatusId: stopCardReason,
                prevCardStatusId: cardStatusId,
                ...(stolenCardPoliceRefNumber && {
                  policeRefNumber: stolenCardPoliceRefNumber
                })
              }
            ]
          }
        })

        setToastData({
          id: 'stop-card-success',
          position: 'right-bottom',
          icon: 'CheckLargeSolid',
          title: content?.manage_cards_success,
          content: content?.manage_cards_stop_card_notification_success_singular,
          autoClose: 5000,
          hasCloseButton: true
        })
        onClose()
      } catch (e) {
        console.warn(e)
        // WIP: API always returns an error even when successful
        /*       setToastData({
          id: 'stop-card-error',
          position: 'right-bottom',
          icon: 'Alert',
          title: 'Error',
          content: content?.manage_cards_stop_card_notification_error_singular,
          autoClose: 10000,
          hasCloseButton: true
        }) */
        setToastData({
          id: 'stop-card-success',
          position: 'right-bottom',
          icon: 'CheckLargeSolid',
          title: content?.manage_cards_success,
          content: content?.manage_cards_stop_card_notification_success_singular,
          autoClose: 5000,
          hasCloseButton: true
        })
        onClose()
      }
    }
  }

  return ReactDOM.createPortal(
    <>
      {activeStep === STEPS.FORM && (
         <>
         { noblockstopcard ? ( 
          <>
        <Modal
          title={content?.manage_cards_stop_card}
          primaryAction={{
            text: content?.manage_cards_apply,
            onClick: () => setActiveStep(STEPS.CONFIRM),
            iconName: '',
            disabled: !stopCardReason || isSubmitting || isLoading,
            isLoading: isSubmitting,
            dataAttributes: {
              'data-testid': 'button-stop-card-complete',
              'data-content-key': 'manage_cards_apply'
            }
          }}
          secondaryAction={{
            text: content?.manage_cards_actions_cancel,
            onClick: onClose,
            appearance: 'tertiary',
            dataAttributes: {
              'data-testid': 'button-stop-card-cancel',
              'data-content-key': 'manage_cards_actions_cancel'
            }
          }}
          visible
          size='sm'
        >
          <RadioButtonGroup
            id='stopCardReason'
            required
            horizontal={true}
            defaultSelectedId={stopCardReason}
            label={content?.manage_cards_choose_reason_singluar}
            options={STOP_CARD_REASONS.map(reason => ({
              id: reason.id,
              label: content ? content[`manage_cards_card_staus_${reason.name}`] : '',
              disabled: reason.id === 31 && cardStatusId === 910 ? true : false // Disable damaged option for AWAITING_EMBOSSING status
            }))}
            onChange={id => setStopCardReason(id)}
          />
          {stopCardReason === STOP_CARD_REASONS[0].id && (
            <>
              <Message>
                <Icon name='Info' size='lg' className='icon' />
                <Text as='p' className='message'>
                  {content?.manage_cards_stolen_card_police_reference_number_label}
                </Text>
              </Message>
              <TextField
                defaultValue={stolenCardPoliceRefNumber}
                textChangeHandler={value => setStolenCardPoliceRefNumber(value)}
                label={content?.manage_cards_stolen_card_police_reference_number_input_placeholder}
              />
            </>
          )}
          {stopCardReason === STOP_CARD_REASONS[1].id && (
            <Message>
              <Icon name='Info' size='lg' className='icon' />
              <Text as='p' className='message'>
                {content?.manage_cards_stop_card_warning_singular}
              </Text>
            </Message>
          )}
          {stopCardReason === STOP_CARD_REASONS[2].id && (
            <Message>
              <Icon name='Info' size='lg' className='icon' />
              <Text as='p' className='message'>
                {replaceVariablesInString(content?.manage_cards_stop_card_warning_damaged, { brand: brand.toLowerCase() })}
              </Text>
            </Message>
          )}
          <br />
          { noblockcardorder && (

          <RadioButtonGroup
            id='stopCardReplacement'
            horizontal={true}
            defaultSelectedId={orderReplacementCards}
            label={content?.manage_cards_order_replacement_question_singular}
            options={[
              {
                id: true,
                label: content?.manage_cards_order_replacement_radio_yes
              },
              {
                id: false,
                label: content?.manage_cards_order_replacement_radio_no
              }
            ]}
            onChange={id => setOrderReplacementCards(id)}
          />)}
        </Modal></>) 
        : (<Modal
          title={content?.manage_cards_stop_card}
          primaryAction={{
            text: content?.manage_cards_actions_cancel,
            onClick: onClose,
            appearance: 'tertiary',
            dataAttributes: {
              'data-testid': 'button-stop-card-cancel',
              'data-content-key': 'manage_cards_actions_cancel'
            }
          }}
          visible
          size='sm'
        >
          <Text>
            {content?.onefleetmigrated_block_stopcard_message || 'content?.onefleetmigrated_block_stopcard_message'}
            <a href ={`mailto:${EMAILADDRESS}`}>{EMAILADDRESS}</a>
          </Text>
          <Text>{content?.onefleetmigrated_block_stopcard_submessage || 'content?.onefleetmigrated_block_stopcard_submessage'}</Text>
         </Modal>)
      }
      </>
      )}
      {activeStep === STEPS.CONFIRM && (
        <Modal
          title={content?.manage_cards_stop_card}
          primaryAction={{
            text: content?.manage_cards_yes,
            onClick: handleStopCard,
            iconName: '',
            disabled: !stopCardReason || isSubmitting || isLoading,
            isLoading: isSubmitting
          }}
          secondaryAction={{
            text: content?.manage_cards_no,
            onClick: () => setActiveStep(STEPS.FORM),
            appearance: 'tertiary',
            iconName: 'RemoveLarge',
            disabled: isSubmitting
          }}
          visible
          size='sm'
        >
          <Text>
            {orderReplacementCards
              ? replaceVariablesInString(content?.manage_cards_stop_card_replacement_confirm_singular, { count: '1' })
              : content?.manage_cards_stop_card_confirm_singular}
          </Text>
        </Modal>
      )}
    </>,
    root
  )
}

export default StopCard
