import styled from 'styled-components'

const StyledNoResults = styled.div`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
  }
`

type Props = {
  content: { [key: string]: string }
  filterText: string
}

export const NoResults = ({ content, filterText = '' }: Props) => {
  return (
    <StyledNoResults data-testid='edit-access-filter-no-results'>
      <span>{content?.noResults?.replace('{{searchText}}', `"${filterText.toUpperCase()}"`) ?? 'noResults'}</span>
    </StyledNoResults>
  )
}
