import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
`

export const Logo = styled.img`
  width: 100px;
`

export const Content = styled.p`
  padding: 16px 0;
  text-align: left;

  > span:first-child {
    font-weight: bold;
  }
`

export const Message = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  right: 32px;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  gap: 5px;

  @media only screen and ${breakpoints.device.lg} {
    right: auto;
    left: 84px;
    max-width: calc(100% - 168px);
  }

  span {
    display: flex;
    align-items: center;
  }

  svg {
    width: 60px;
    height: 60px;
    color: #bb2222;
  }
`
export const MessageText = styled.div`
  display: flex;
  line-height: 1.1em;
  font-size: 1em;
  align-items: center;
  white-space: pre-line;

  @media only screen and ${breakpoints.device.lg} {
    line-height: 1.1em;
    font-size: 1em;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`
