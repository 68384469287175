import { useCallback, useState, useEffect, createContext, useMemo, Dispatch, SetStateAction } from 'react'
import { useQueryClient } from 'react-query'
import uniqBy from 'lodash.uniqby'
import { StateMessage } from '@bp-digital/component-state-message'
import { Toast } from '@bp-digital/component-toast'
import { Chip } from '@bp-digital/component-chip'
import { Heading } from '@bp-digital/component-typography'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import PageHeader from 'components/layout/PageHeader'
import { useRootStore } from 'contexts/StoreContext'
import { UserStoreContext } from 'contexts/StoreContext.types'
import useApiGetArchivedInvoices, {
  QUERY_KEY,
  ArchivedInvoice,
  ArchivedInvoiceResponse,
  Filter,
  SummaryStatement
} from 'hooks/api/useApiGetArchivedInvoices'
import useContent from 'hooks/useContent'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import { Column } from 'react-table'
import { ReactTable } from 'src/components/dataDisplay/ReactTable/Table'
import InvoicesStore from 'stores/Transactions/InvoicesStore'
import { TableAlert, FilterList } from './ArchivedInvoicesPage.styled'
import { InvoiceTableRow } from './ArchivedInvoicesPage.types'
import InvoiceArchiveFilters from './components/InvoiceArchiveFilters'
import CommandBar from './components/CommandBar'
import { buildTableDataFromInvoices, buildTableDataFromSummaries } from './helpers/buildTableData/buildTableData'
import { getFilterText } from './helpers/getAllAppliedFilterBadges'
import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN } from 'src/constants/routes'

export type InvoiceFilterType = {
  type: string
  text: string
  payload: Filter
}

type FilterContextType = {
  filterData: InvoiceFilterType[]
  setFilterData: Dispatch<SetStateAction<InvoiceFilterType[]>>
  currentActiveFilters: InvoiceFilterType[]
}

export const FilterContext = createContext<FilterContextType>({
  filterData: [],
  setFilterData: () => undefined,
  currentActiveFilters: []
})

const uniqueKey = 'invoiceNumber'

const InvoicesPage = () => {
  const {
    userStore
  }: {
    userStore: UserStoreContext
    invoicesStore: InvoicesStore
  } = useRootStore()

  const content = useContent('transactions-invoices') || {}

  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([QUERY_KEY])
    }
  }, [QUERY_KEY])

  const [summaryData, setSummaryData] = useState<SummaryStatement[]>([])
  const [invoiceData, setInvoiceData] = useState<ArchivedInvoice[]>([])

  const [selectedSummaryIds, setSelectedSummaryIds] = useState<string[]>([])
  const [selectedSingleInvoiceIds, setSelectedSingleInvoiceIds] = useState<string[]>([])
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0)

  // const countryISO = userStore?.selectedAuthority?.country?.countryISO ?? ''
  const countryISO = userStore?.selectedAuthority?.ISOCountryCode ?? ''

  const authorityNumber = userStore?.selectedAuthority?.authorityId.substr(1) ?? ''
  const selectedAuthorityId = userStore?.selectedAuthorityId ?? ''

  // const invoiceLevel = userStore?.selectedAuthority?.invoiceLevel ?? ''
  const invoiceLevel = 'authority' // not relevant as blocked in the lambda -> cleanup!

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [isRequestReady, setIsRequestReady] = useState(true)
  const [filterData, setFilterData] = useState<InvoiceFilterType[]>([])
  const [currentActiveFilters, setCurrentActiveFilters] = useState<InvoiceFilterType[]>([])

  const [errorToastData, setErrorToastData] = useState({})

  const filterContextValue = useMemo(
    () => ({ filterData, setFilterData, currentActiveFilters }),
    [currentActiveFilters, filterData]
  )

  const customFilterPayloads = (filterData.length && filterData) || []

  const { isIdle, isLoading, isFetching, data } = useApiGetArchivedInvoices(
    {
      authorityId: selectedAuthorityId,
      authorityNumber,
      countryISO,
      invoiceLevel,
      ...(customFilterPayloads.length ? { customFilters: customFilterPayloads.map(filter => filter.payload) } : {})
    },
    {
      enabled: isRequestReady && !!selectedAuthorityId && !!countryISO && !!authorityNumber && !!invoiceLevel,
      onSuccess: (filteredInvoices: ArchivedInvoiceResponse) => {
        const { invoices, summaries } = filteredInvoices

        // Dedupe duplicated summary and invoice objects in array response
        // before setting state
        setSummaryData(uniqBy(summaries, uniqueKey))
        setInvoiceData(uniqBy(invoices, uniqueKey))

        setFilterData([])
      },
      onError: error => {
        console.error('Failed to update filters', error)

        setErrorToastData({
          id: Math.floor(Math.random() * 9999),
          title: content?.error_default_title ?? 'error_default_title',
          content: content?.error_default_message ?? 'error_default_message',
          autoClose: 5500
        })
      },
      onSettled: () => {
        setIsRequestReady(false)
        setShowFilterModal(false)
      }
    }
  )

  const tablePagination = {
    currentPage: 1,
    count: 1,
    disabled: true,
    onChange: () => undefined
  }

  const buildTableColumns = (isLoading: boolean): Column<InvoiceTableRow>[] => {
    return isLoading ? loadingColumns : defaultDataColumns
  }

  const defaultDataColumns: Column<InvoiceTableRow>[] = [
    {
      Header: content?.columnHeading_invoiceNumber ?? 'Invoice Number',
      accessor: 'invoiceNumber'
    },
    {
      Header: content?.columnHeading_invoiceDate ?? 'Invoice Date',
      accessor: 'invoiceDate'
    },
    {
      Header: content?.columnHeading_invoiceType ?? 'Invoice Type',
      accessor: 'invoiceType'
    },
    {
      Header: content?.columnHeading_amount ?? 'Amount',
      accessor: 'amount'
    },
    {
      Header: content?.columnHeading_customerName ?? 'Customer Name',
      accessor: 'customer'
    },
    {
      Header: content?.columnHeading_supplyCountry ?? 'Supply Country',
      accessor: 'supplyCountryImage'
    },
    {
      Header: content?.columnHeading_issuerCountry ?? 'Issue Country',
      accessor: 'issuerCountry'
    },
    {
      Header: content?.columnHeading_authorityNumber ?? 'Authority Number',
      accessor: 'authorityNumber'
    }
  ]

  const loadingColumns = defaultDataColumns.map(c => ({ ...c, Cell: <Skeleton width={100} /> }))

  const handleSelectedSummariesChange = useCallback(
    (rowIDs: Record<string, boolean>) => {
      setSelectedSummaryIds(Object.keys(rowIDs))
    },
    [setSelectedSummaryIds]
  )

  const handleSelectedInvoiceChange = useCallback(
    (rowIDs: Record<string, boolean>) => {
      setSelectedSingleInvoiceIds(Object.keys(rowIDs))
    },
    [setSelectedSingleInvoiceIds]
  )

  const selectedSummaries = summaryData?.filter(i => {
    return selectedSummaryIds.includes(`${i.invoiceNumber}-${i.invoiceType}`)
  }) as SummaryStatement[]

  const selectedSingleInvoices = invoiceData?.filter(i => {
    return selectedSingleInvoiceIds.includes(`${i.invoiceNumber}-${i.invoiceType}`)
  })

  const [removedSupplyCountry, setRemovedSupplyCountry] = useState<string>('')

  const selectedSummaryInvoices = summaryData
    .flatMap(s => (s.invoices ? s.invoices : (s.invoices = [])))
    .filter(i => selectedSummaryIds.includes(`${i.invoiceNumber}-${i.invoiceType}`))

  const selectedInvoices: ArchivedInvoice[] = selectedSingleInvoices.concat(selectedSummaryInvoices)

  useEffect(() => {
    setSelectedRowsCount(selectedSummaries.length + selectedInvoices.length)
  }, [selectedSummaries.length, selectedInvoices.length])

  useEffect(() => {
    if (currentActiveFilters.length) {
      setFilterData(currentActiveFilters)
      setIsRequestReady(true)
    }

    if (!isRequestReady && currentActiveFilters.length === 0) {
      setIsRequestReady(true)
    }
  }, [currentActiveFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  const alertMessage =
    selectedRowsCount > 10 ? (
      <StateMessage
        iconName='Alert'
        state='danger'
        text={content?.more_than_limit_downloads || 'more_than_limit_downloads'}
      />
    ) : null

  let hasSummaryStatementTypeFilterApplied = false

  currentActiveFilters.forEach((activeFilter: InvoiceFilterType) => {
    if (
      activeFilter.payload.name === 'invoiceType' &&
      activeFilter.payload.keyGroup[0].values[0]?.invoiceType === 'SUMMARY-STATEMENT'
    ) {
      hasSummaryStatementTypeFilterApplied = true
    }
  })

  type TItems = {
    countryCode?: string
    countryName?: string
  }

  useEffect(() => {
    if (currentActiveFilters.length) {
      const filterCopy = [...currentActiveFilters]

      const supplyCountryFilter = filterCopy.filter(
        (activeFilter: InvoiceFilterType) => activeFilter.type === 'supplyCountry'
      )[0].payload.keyGroup[0]

      const updatedValues: TItems[] | null = []

      supplyCountryFilter.values.map((items): TItems => {
        if (items.countryCode !== removedSupplyCountry) {
          const obj: TItems = {
            countryCode: String(items.countryCode),
            countryName: String(items.countryName)
          }
          updatedValues.push(obj)
        }
        return {}
      })

      supplyCountryFilter.values = updatedValues

      setCurrentActiveFilters(filterCopy)
    }
  }, [removedSupplyCountry]) // eslint-disable-line react-hooks/exhaustive-deps

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.archiveFiltersDownload_Header ?? '...'}
        subtitle={data ? `${data?.count} ${content?.archiveFiltersDownload_invoicesHeader}` : '...'}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <CommandBar
          selectedSummaries={selectedSummaries}
          selectedInvoices={selectedInvoices}
          openFilter={() => setShowFilterModal(true)}
          disableFilter={isIdle || isLoading || isFetching || !!selectedSummaries.length || !!selectedInvoices.length}
        />
        <TableAlert>{alertMessage}</TableAlert>

        <FilterList>
          {currentActiveFilters.map((filter: InvoiceFilterType) => {
            if (filter.type === 'supplyCountry') {
              return filter.payload.keyGroup[0].values.map(({ countryCode }) => {
                return (
                  <Chip
                    key={`${filter.type}-${countryCode}`}
                    text={`${content['advancedFilters_filterName_supplyCountry']}: ${
                      content[`advancedFilters_${countryCode}`]
                    }`}
                    appearance='primary'
                    state='success'
                    onClick={() => setRemovedSupplyCountry(String(countryCode))}
                  />
                )
              })
            } else {
              return (
                <Chip
                  key={filter.type}
                  text={getFilterText(filter.payload, content)}
                  appearance='primary'
                  state='success'
                  onClick={() => setCurrentActiveFilters(currentActiveFilters.filter(f => f.type !== filter.type))}
                />
              )
            }
          })}
        </FilterList>

        <Heading as='h3' display='inline'>
          {content?.archiveFiltersDownload_summariesHeader ?? 'Summaries'}
        </Heading>

        <ReactTable<InvoiceTableRow>
          name='invoiceArchiveSummaries'
          hasSubRows={!hasSummaryStatementTypeFilterApplied}
          columns={buildTableColumns(isLoading || isFetching)}
          data={buildTableDataFromSummaries(isLoading || isFetching, summaryData)}
          pagination={tablePagination}
          isLoading={isLoading}
          onSelectedRowsChange={handleSelectedSummariesChange}
          renderDetail={() => <></>}
          showSelectAllCheckbox={false}
          disableRowSelection={selectedRowsCount > 10 ? true : false}
        />

        {(isLoading || isFetching || invoiceData.length > 0) && (
          <>
            <Heading as='h3'>
              {content?.archiveFiltersDownload_invoicesHeader ?? 'archiveFiltersDownload_invoicesHeader'}
            </Heading>
            <ReactTable<InvoiceTableRow>
              name='invoiceArchiveSingles'
              columns={buildTableColumns(isLoading || isFetching)}
              data={buildTableDataFromInvoices(isLoading || isFetching, invoiceData)}
              pagination={tablePagination}
              isLoading={isLoading}
              onSelectedRowsChange={handleSelectedInvoiceChange}
              showSelectAllCheckbox={false}
              disableRowSelection={selectedRowsCount > 10 ? true : false}
            />
          </>
        )}

        {showFilterModal && (
          <FilterContext.Provider value={filterContextValue}>
            <InvoiceArchiveFilters
              content={content}
              setIsRequestReady={setIsRequestReady}
              onClose={() => setShowFilterModal(false)}
              setCurrentActiveFilters={setCurrentActiveFilters}
              removedSupplyCountry={removedSupplyCountry}
              setRemovedSupplyCountry={setRemovedSupplyCountry}
            />
          </FilterContext.Provider>
        )}
        <Toast appearance='dark' toast={errorToastData} />
      </InnerPageWrapper>
    </>
  )
}

export default observer(InvoicesPage)
