import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 0;
  column-gap: 15px;

  @media only screen and ${breakpoints.device.lg} {
    padding: 16px 0;
    grid-template-columns: 1fr 1fr;
  }
`
export const ItemWrapper = styled.div`
  margin-bottom: 8px;
`

export const AlertWrapper = styled.div`
  margin-left: ${props => (props.$withHorizontalSpacing ? '16px' : '0')};
  margin-right: ${props => (props.$withHorizontalSpacing ? '16px' : '0')};
  margin-top: 16px;
`
