import { useEffect, useState, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { observer } from 'mobx-react'
import { useRootStore } from 'contexts/StoreContext'
import { Button } from '@bp-digital/component-button'

import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import AuditFilters from './components/AuditFilters/AuditFilters'
import AuditChip from './components/AuditChip/AuditChip'

import { ReactTable } from 'src/components/dataDisplay/ReactTable/Table'

import PageHeader from 'components/layout/PageHeader'

import { Redirect } from 'react-router-dom'
import { ROUTE_ADMIN, ROUTE_DASHBOARD } from 'src/constants/routes'

import { FilterContainer, LogBody } from './AuditPage.styled'

const AuditPage = () => {
  const { auditStore, userStore } = useRootStore()
  const [filters, setFilters] = useState([])
  const [showFilterModal, setShowFilterModal] = useState(false)

  useEffect(() => {
    if (!filters.length && userStore.user && userStore.user.uniqueId) {
      const from = new Date()
      const to = new Date()
      from.setMonth(new Date().getMonth() - 3)
      setFilters([
        { filterBy: 'filtermode', filterCriteria: ['MY_LOGS'] },
        { filterBy: 'actors', filterCriteria: [userStore.user?.uniqueId] },
        {
          filterBy: 'date',
          filterCriteria: [from.toISOString().slice(0, -5) + 'Z', to.toISOString().slice(0, -5) + 'Z']
        }
      ])
    }
    if (filters.length && userStore.user && userStore.user.uniqueId) {
      auditStore.getAuditLogs({ filters })
    }
  }, [auditStore, userStore.user, userStore.user?.uniqueId, filters])

  const processFilters = filterData => {
    setFilters(filterData)
    setShowFilterModal(false)
  }

  const columnDefs = [
    {
      key: 'userActionTypeDescription',
      name: 'Log Type'
    },
    {
      key: 'actorEmail',
      name: 'Actor'
    },
    {
      key: 'displayTime',
      name: 'Time'
    },
    {
      key: 'body',
      name: 'Event Body'
    }
  ]

  const columns = useMemo(
    () =>
      auditStore.isFetchingData
        ? columnDefs.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />,
            disableSortBy: true
          }))
        : columnDefs.map(col => ({
            Header: col.name,
            accessor: col.key,
            disableSortBy: true,
            width: col.key == 'body' ? 400 : 100
          })),
    [auditStore.isFetchingData] //eslint-disable-line react-hooks/exhaustive-deps
  )

  const loadingRow = columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  const tablePagination = {
    currentPage: auditStore.currentPage,
    count: auditStore.numberOfPages || 1,
    pageSize: auditStore.pageSize,
    disabled: !auditStore.data,
    onChange: page =>
      auditStore.getAuditLogs({
        filters,
        page,
        pageSize: auditStore.pageSize
      }),
    onSizeChange: pageSize =>
      auditStore.getAuditLogs({
        filters,
        page: 1,
        pageSize
      })
  }

  const data = useMemo(
    () =>
      auditStore.isFetchingData
        ? Array(tablePagination.pageSize)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : auditStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auditStore.rows, auditStore.isFetchingData, tablePagination.currentPage, tablePagination.pageSize]
  )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  // temporary Admin only access
  if (!userStore.isAdmin) return <Redirect to={ROUTE_DASHBOARD} />

  return (
    <>
      <PageHeader title={'Audit Logs'} subtitle={''} brand={userStore.brand} />

      <InnerPageWrapper>
        <FilterContainer>
          <div>
            {filters.length > 0 &&
              filters.map(fltr => {
                return (
                  <AuditChip
                    key={fltr.filterBy}
                    filterKey={fltr.filterBy}
                    value={fltr.filterCriteria}
                    actionPageFilter={true}
                    onClick={() =>
                      setFilters(
                        filters
                          .filter(fltr2 => fltr.filterBy != fltr2.filterBy)
                          .concat({ filterBy: fltr.filterBy, filterCriteria: [] })
                      )
                    }
                  />
                )
              })}
          </div>

          <Button
            appearance='primary'
            iconName='Edit'
            onClick={async () => {
              setShowFilterModal(true)
            }}
            dataAttributes={{ 'data-testid': 'button-filter' }}
          >
            Filters
          </Button>
        </FilterContainer>

        <ReactTable
          name='auditlogs'
          columns={columns}
          hideCheckboxColumn
          data={data}
          pagination={tablePagination}
          showSelectAllCheckbox={false}
          expandSubRowOnSelect={false}
          isLoading={auditStore.isFetchingData}
          renderDetail={({ original: { body } }) => <LogBody>{body}</LogBody>}
        />

        {showFilterModal && (
          <AuditFilters
            filters={filters}
            setFilters={processFilters}
            onDismiss={() => setShowFilterModal(false)}
          ></AuditFilters>
        )}
      </InnerPageWrapper>
    </>
  )
}

export default observer(AuditPage)
