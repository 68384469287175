import axios from 'axios'
import Cookies from 'js-cookie'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import getFallbackLanguage from 'helpers/getFallbackLanguage'

class ContentStore {
  globalComponents = {}
  pageContents = {}

  constructor() {
    makeObservable(this, {
      globalComponents: observable,
      pageContents: observable,
      setContent: action,
      header: computed,
      footer: computed
    })
  }

  setContent(type, key, value) {
    this[type][key] = value
  }

  get header() {
    const locale = this.getLocale()
    // eslint-disable-next-line no-undef
    return this.globalComponents[`${header}${locale}`]
  }

  get footer() {
    const locale = this.getLocale()
    // eslint-disable-next-line no-undef
    return this.globalComponents[`${footer}${locale}`]
  }

  getPage(page) {
    const locale = this.getLocale()
    return this.pageContents[page + locale]
  }

  getLocale() {
    return Cookies.get('bpf-locale') || getFallbackLanguage()
  }

  async init() {
    const locale = this.getLocale()
    const responses = await Promise.all([this.getContent('header'), this.getContent('footer')])

    runInAction(() => {
      this.globalComponents = {
        // eslint-disable-next-line no-undef
        [header + locale]: responses[0],
        // eslint-disable-next-line no-undef
        [footer + locale]: responses[1]
      }
    })
  }

  async getContent(key, type = 'pageContents', locale = this.getLocale()) {
    if (this[type][key + locale]) return this[type][key + locale]
    const response = await axios.get(`/content/${locale}-${key}.json`)
    this.setContent(type, key + locale, response.data)
    return response.data
  }
}

export default ContentStore
