export default [
  'rfid',
  'cardTitle',
  'cardHolderName',
  'cardNumberEnding',
  'sessionStart',
  'sessionEnd',
  'name',
  'evseId',
  'totalEnergy',
  'cdrType'
]

export const contentKeyMap = {
  rfid: 'cpo_transactions_rfid_columnheader',
  cardTitle: 'cpo_transactions_card_title_columnheader',
  cardHolderName: 'cpo_transactions_card_holder_name_columnheader',
  cardNumberEnding: 'cpo_transactions_card_ending_columnheader',
  sessionStart: 'cpo_transactions_start_columnheader',
  sessionEnd: 'cpo_transactions_end_columnheader',
  name: 'cpo_transactions_site_name_columnheader',
  evseId: 'cpo_transactions_evseid_columnheader',
  totalEnergy: 'cpo_transactions_quantity_columnheader',
  cdrType: 'cpo_transactions_cdr_type_columnheader'
}
