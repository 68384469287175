const replaceKeywords = (contentKey, value, currency, content) => {
  return content[contentKey]?.replace('{{value}}', value)?.replace('{{currency}}', currency)
}

export const formatCategory = (category, currency, content, keyPrefix) => {
  const values = []

  if (category.isAllow) {
    const { categoryLimits: { daily, monthly, transaction } = {} } = category

    if (daily?.dailyMaxVolume && daily?.dailyMaxVolume !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_category_daily_max_volume`,
        daily.dailyMaxVolume,
        currency,
        content
      )
      values.push(value)
    }

    if (daily?.dailyMaxValue && daily?.dailyMaxValue !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_daily_max_value`,
        category.categoryLimits.daily.dailyMaxValue,
        currency,
        content
      )
      values.push(value)
    }

    if (transaction?.txnMaxVolume && transaction?.txnMaxVolume !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_category_txn_max_volume`,
        category.categoryLimits.transaction.txnMaxVolume,
        currency,
        content
      )
      values.push(value)
    }

    if (transaction?.txnMaxValue && transaction?.txnMaxValue !== 0) {
      const value = replaceKeywords(
        `${keyPrefix}product_txn_max_value`,
        category.categoryLimits.transaction.txnMaxValue,
        currency,
        content
      )
      values.push(value)
    }

    if (
      daily.dailyMaxValue === null &&
      daily.dailyMaxVolume === null &&
      monthly.monthlyMaxValue === null &&
      monthly.monthlyMaxVolume === null &&
      transaction.txnMaxValue === null &&
      transaction.txnMaxVolume === null
    ) {
      const value = content[`${keyPrefix}product_allowed`]
      values.push(value)
    }
  } else {
    const value = content[`${keyPrefix}product_not_allowed`]
    values.push(value)
  }

  return values
}
