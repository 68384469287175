import Cookies from 'js-cookie'
import axios from 'axios'
import { useQuery } from 'react-query'
import getFallbackLanguage from 'src/helpers/getFallbackLanguage'
import { getLanguageFromUrl } from 'src/helpers/getFallbackLanguage'

const useContent = (pageName, givenLocale) => {
  const locale = givenLocale || getLanguageFromUrl() || Cookies.get('bpf-locale') || getFallbackLanguage()
  Cookies.set('bpf-locale', locale, { secure: true })
  const fetchContent = async () => {
    const response = await axios(
      {
        method: 'get',
        url: `/content/${locale}-${pageName}.json`
      },
      { skipAuthRefresh: true }
    )

    return response.data
  }

  const { data } = useQuery(`content/${locale}/${pageName}`, fetchContent, {
    staleTime: 120000
  })

  return data
}

export default useContent
