import { useEffect, useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Button } from '@bp-digital/component-button'

import useApiGetSupplyCountries from 'hooks/api/useApiGetSupplyCountries'

import { useRootStore } from 'contexts/StoreContext'
import { UserStoreContext } from 'contexts/StoreContext.types'

import { getFilterText } from 'pages/Transactions/ArchivedInvoicesPage/helpers/getAllAppliedFilterBadges'
import { FilterContext, InvoiceFilterType } from 'pages/Transactions/ArchivedInvoicesPage/ArchivedInvoicesPage'

import { InvoiceFilterProps } from '../InvoiceArchiveFilters'

import { SupplyCountryFilterWrapper } from './InvoiceSuppyCountryFilter.styled'

export const FILTER_NAME = 'supplyCountry'

const InvoiceSupplyCountryFilter = ({
  removedFilterIds,
  setRemoveFilterId,
  content,
  removeSupplyCountryFilter
}: InvoiceFilterProps) => {
  const { setFilterData } = useContext(FilterContext)

  const {
    userStore
  }: {
    userStore: UserStoreContext
  } = useRootStore()

  const authorityId = userStore?.selectedAuthorityId ?? ''

  const { data, isLoading } = useApiGetSupplyCountries(authorityId)

  const getCountryName = (countryCode: string) => content[`advancedFilters_${countryCode}`]

  interface ISupplyCountry {
    id: string
    label: string
    isSelected: boolean
    countryCode: string
    countryName: string
  }

  const options: ISupplyCountry[] | null = []
  const [currentSelection, setCurrentSelection] = useState<ISupplyCountry[] | []>([])

  const handleCountrySelection = (selectedCountry: ISupplyCountry) =>
    setCurrentSelection(
      currentSelection.map(country => {
        if (country.id === selectedCountry.id) {
          country = {
            ...selectedCountry,
            isSelected: !selectedCountry.isSelected
          }
        }
        return country
      })
    )

  type TData = {
    countryCode: string
    countryName: string
  }

  data &&
    data.forEach(({ countryCode, countryName }: TData) => {
      options.push({
        id: `${countryCode}-${countryName}`,
        label: `${getCountryName(countryCode)}`,
        isSelected: false,
        countryCode: countryCode,
        countryName: countryName
      })
    })

  useEffect(() => {
    // clear single country from filter
    setCurrentSelection(
      currentSelection.map(country => {
        if (country.countryCode === removeSupplyCountryFilter) {
          country = {
            ...country,
            isSelected: false
          }
        }
        return country
      })
    )
  }, [removeSupplyCountryFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (removedFilterIds?.includes(FILTER_NAME)) {
      setRemoveFilterId(ids => ids.filter(id => id !== FILTER_NAME))

      // clear all selections
      setCurrentSelection(
        currentSelection.map(country => {
          country = {
            ...country,
            isSelected: false
          }
          return country
        })
      )
    }
  }, [removedFilterIds, setRemoveFilterId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const values = currentSelection
      .filter(item => {
        if (item.isSelected) {
          return {
            countryCode: item.countryCode,
            countryName: item.countryName
          }
        }
      })
      .map(item => ({
        countryCode: item.countryCode,
        countryName: item.countryName
      }))

    const payload = {
      name: FILTER_NAME,
      keyGroup: [
        {
          keys: [`${FILTER_NAME}`],
          values
        }
      ]
    }

    values &&
      setFilterData((filters: InvoiceFilterType[]) => {
        const filteredFilters = filters.filter(filter => filter.type !== FILTER_NAME)

        return [
          ...filteredFilters,
          {
            type: FILTER_NAME,
            text: getFilterText(payload, content),
            payload
          }
        ]
      })
  }, [currentSelection]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isLoading && setCurrentSelection(options)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <Skeleton height={70} />}
      {!isLoading && (
        <SupplyCountryFilterWrapper>
          {currentSelection.map(country => {
            return (
              <Button
                key={country.id}
                appearance={country.isSelected ? 'primary' : 'secondary'}
                borderRadius='default'
                onClick={() => handleCountrySelection(country)}
              >
                {country.label}
              </Button>
            )
          })}
        </SupplyCountryFilterWrapper>
      )}
    </>
  )
}

export default InvoiceSupplyCountryFilter
