import { IconName, IconState } from '@bp-digital/component-icon'
import { CssVars } from '@bp-digital/theme'
import { Transaction } from './TransactionMap.types'
import { TransactionAlertType } from './TransactionMap.types'

export const getTransactionOptions = (
  trans: Transaction
): {
  alertType: TransactionAlertType
  iconName: IconName
  iconState: IconState
  color: string
} => {
  const alertType = getAlertType(trans)
  switch (alertType) {
    case 'cleared':
      return { alertType, iconName: 'CheckLargeSolid', iconState: 'success', color: CssVars.palette.state.successDark }
    case 'alert':
      return { alertType, iconName: 'Alert', iconState: 'warning', color: CssVars.palette.state.warningDark }
    case 'critical':
      return { alertType, iconName: 'Alert', iconState: 'danger', color: CssVars.palette.state.dangerDark }
    default:
      return { alertType, iconName: 'Bullet', iconState: 'neutral', color: CssVars.palette.state.neutralDark }
  }
}

const getAlertType = (transaction: Transaction): TransactionAlertType => {
  const { suspiciousIndicator, alertIndicator, alertLevel, transactionStatus } = transaction

  if (transactionStatus === 'Declined') {
    return 'critical'
  }

  if (suspiciousIndicator === 'Y' || alertIndicator === 'Y' || alertLevel === 'Warning') {
    return 'alert'
  }

  return 'cleared'
}
