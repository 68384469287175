import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const SubtitleWrapper = styled.div`
  padding-bottom: 8px;
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid var(--color-grey-regular-200);
  border-bottom: 1px solid var(--color-grey-regular-200);
  gap: 16px;
  padding: 16px 0;
  margin: 8px 0;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
  }
`
export const TableContainer = styled.div`
  td {
    width: 50%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-top: 1px solid var(--color-grey-regular-200);
  border-bottom: 1px solid var(--color-grey-regular-200);
  gap: 16px;
  padding: 16px 0;
  margin: 8px 0;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
  }
`
export const CellContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: row;

  div {
    display: flex;
    align-items: center;
  }

  button {
    padding: 0;
    border: 0;
    cursor: pointer;

    > span {
      border-radius: 5px;
      background-color: var(--color-grey-regular-700);

      &:hover {
        background-color: var(--color-grey-regular-900);
      }

      > svg > g {
        fill: rgb(238, 238, 238);
      }
    }
  }
`

export const LastUpdatedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  font-size: 0.9rem;
`

export const PhoneMessage = styled.div`
  padding: 1rem 1rem 4rem;
  display: flex;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 1.2rem;
    font-weight: 700;

    &:first-child {
      font-weight: normal;
    }
  }
`
