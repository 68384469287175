import { useState, memo, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Text } from '@bp-digital/component-typography'
import Paper from 'components/surfaces/Paper'
import { Insight, InsightsWrapper, WeightedText, DatePickerWrapper } from './ChargePointInsights.styled'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { getDateFilters } from './helpers'
import { PAYLOAD_DATE_RANGES } from './constants'
import { getHoursAndMinutes } from 'helpers/getHoursAndMinutes'
import { useOnClickOutside } from '@bp-digital/hooks'
import { useRootStore } from 'contexts/StoreContext'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'

import { DatePicker } from '@bp-digital/component-date-picker'

const ChargePointInsights = ({ content, usageSummary = {}, onFilterChange, isLoading }) => {
  const { userStore } = useRootStore()
  const filters = getDateFilters(content)
  const { averageKwhSupplied = 0, averageChargeDuration = 0, chargeSessionsBelowTenMinutes = 0 } = usageSummary || {}
  const [activeFilterLabel, setActiveFilterLabel] = useState(filters[0].label)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const avgChargeInHoursAndMinutes = getHoursAndMinutes(averageChargeDuration)
  const ref = useRef(null)

  const handleFilterChange = filterId => {
    const activeFilter = filters.find(filter => filter.id === filterId)
    setActiveFilterLabel(activeFilter.label)
    if (activeFilter.id === 'CUSTOM_DATE_RANGE') {
      setIsDatePickerOpen(true)
    } else {
      onFilterChange(PAYLOAD_DATE_RANGES[activeFilter.id])
    }
  }

  useOnClickOutside(ref, () => isDatePickerOpen && setIsDatePickerOpen(false))

  const insights = [
    {
      value: `${averageKwhSupplied} kWh`,
      title: content?.cpo_charger_details_insight_average_kw_title || 'cpo_charger_details_insight_average_kw_title'
    },
    {
      value: `${
        replaceVariablesInString(content?.cpo_charger_details_insight_average_duration_hours, {
          hours: avgChargeInHoursAndMinutes.hours
        }) || 'hours'
      }  ${
        replaceVariablesInString(content?.cpo_charger_details_insight_average_duration_minutes, {
          minutes: avgChargeInHoursAndMinutes.minutes
        }) || 'mins'
      }`,
      title: content?.cpo_charger_details_insight_average_duration_title
    },
    {
      value: replaceVariablesInString(content?.cpo_charger_details_insight_short_sessions || '', {
        sessions: chargeSessionsBelowTenMinutes
      }),
      title:
        content?.cpo_charger_details_insight_short_sessions_title || 'cpo_charger_details_insight_short_sessions_title'
    }
  ]

  const onCustomDateChange = dates => {
    if (dates.length === 2) {
      const fromDate = dates[0]
      const toDate = dates[1].setHours(23, 59, 59, 999)
      const range = {
        usageDateFrom: fromDate.toJSON(),
        usageDateTo: new Date(toDate).toJSON()
      }
      setTimeout(() => {
        setIsDatePickerOpen(false)
        onFilterChange(range)
        setActiveFilterLabel(
          replaceVariablesInString(content?.cpo_custom_date_range || '{{dateFrom}} to {{dateTo}}', {
            dateFrom: getUserFormattedDate(range.usageDateFrom, userStore.dateFormat, true),
            dateTo: getUserFormattedDate(range.usageDateTo, userStore.dateFormat, true)
          })
        )
      }, 1500)
    }
  }

  const datePickerOptions = {
    onChange: onCustomDateChange,
    mode: 'range',
    maxDate: new Date()
  }

  return (
    <Paper
      title={content.cpo_charger_details_insights_title}
      commands={{
        title: activeFilterLabel || content.cpo_charger_details_insight_current_month,
        items: filters,
        onChange: handleFilterChange
      }}
    >
      {isLoading ? (
        <Skeleton height={100} />
      ) : (
        <InsightsWrapper>
          {isDatePickerOpen && (
            <DatePickerWrapper ref={ref}>
              <DatePicker options={datePickerOptions} locale={userStore.locale} />
            </DatePickerWrapper>
          )}
          {insights.map((insight, index) => (
            <Insight key={`${insight.value}-${index}`}>
              <WeightedText>{insight.value}</WeightedText>
              <Text>{insight.title}</Text>
            </Insight>
          ))}
        </InsightsWrapper>
      )}
    </Paper>
  )
}

export default memo(ChargePointInsights)
