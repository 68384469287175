const getQueryParameter = (param) => {
  // eslint-disable-next-line no-undef
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })

  return params[param]
}

export default getQueryParameter
