import { formatCategory, formatProduct, getCategoryName, getProductName } from '.'

export const getProductsAndServices = (restriction, currency, content, keyPrefix) => {
  const limits = []

  if (
    !restriction ||
    (!restriction?.acceptProductServiceViolation &&
      !restriction?.alertProductServiceViolation &&
      !restriction?.categories?.length)
  ) {
    return limits
  }

  const selectedCategories = restriction.categories.filter(
    category =>
      category.applyForAllProductsInCategory && category.categoryDetails && category.categoryDetails.categoryLimits
  )

  selectedCategories.forEach(category => {
    limits.push({
      label: getCategoryName(category.categoryId, content),
      values: formatCategory(category.categoryDetails, currency, content, keyPrefix)
    })
  })

  const products = restriction.categories
    .filter(category => !category.applyForAllProductsInCategory && category.categoryDetails?.products?.length > 0)
    .map(category => category.categoryDetails.products)
    .reduce((a, b) => [...a, ...b], [])

  products.forEach(product => {
    limits.push({
      label: getProductName(product.productServiceId, content),
      values: formatProduct(product, currency, content, keyPrefix)
    })
  })

  return limits
}
