import ReactDOM from 'react-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Modal } from '@bp-digital/component-modal'
import { Text } from '@bp-digital/component-typography'
import { InvoiceSummaryGrid, InvoiceSummaryBlock } from './SimpleInvoiceSummaryDetails.styled'
import Skeleton from 'react-loading-skeleton'

const root = document.getElementById('root')

const SimpleInvoiceSummaryDetails = ({ id, onClose, content }) => {
  const getDetails = async id => {
    try {
      const response = await axios({
        method: 'get',
        url: `/api/transactions/invoices/${id}`
      })

      response.data.invoiceDeliveryAddress = Object.values(response.data.invoiceDeliveryAddress)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const { data, isLoading, error } = useQuery(`${id}-details`, () => getDetails(id))
  const loading = isLoading || error // tactical, error state future work

  // Disgusting conversion, regretfully taken from Rio
  const paymentMethod = data?.summaryDetails?.paymentMethodDescription
    ?.toLowerCase()
    .replace(/([~!@#$%^&*()_+=`{}\]\\:;'<>,.? ])+/g, '_')
    .replace(/^(-)+|(-)+$/g, '_')
  const paymentMethodContent = content?.[paymentMethod] || data?.summaryDetails?.paymentMethodDescription || '-'

  return ReactDOM.createPortal(
    <Modal
      title={content?.advancedFilters_SUMMARY}
      primaryAction={{ text: content.close || 'Close', onClick: onClose }}
      onDismiss={onClose}
      visible
    >
      <InvoiceSummaryGrid>
        <InvoiceSummaryBlock>
          <Text size='lg' as='p'>
            {content.payment_method || 'Payment method'}
          </Text>
          {loading ? (
            <Skeleton width={120} height={23} />
          ) : (
            <Text size='md' as='p'>
              {paymentMethodContent}
            </Text>
          )}
        </InvoiceSummaryBlock>

        <InvoiceSummaryBlock>
          <Text size='lg' as='p'>
            {content.due_date || 'Due date'}
          </Text>
          {loading ? (
            <Skeleton width={120} height={23} />
          ) : (
            <Text size='md' as='p'>
              {data.summaryDetails.paymentDueDate}
            </Text>
          )}
        </InvoiceSummaryBlock>

        <InvoiceSummaryBlock>
          <Text size='lg' as='p'>
            {content.delivery_address || 'Delivery address'}
          </Text>
          {loading ? (
            <Skeleton width={200} height={23} count={5} />
          ) : (
            data.invoiceDeliveryAddress.map((line, index) => (
              <Text key={index} size='md' as='p'>
                {line}
              </Text>
            ))
          )}
        </InvoiceSummaryBlock>
      </InvoiceSummaryGrid>
    </Modal>,
    root
  )
}

export default SimpleInvoiceSummaryDetails
