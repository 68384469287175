import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY_PREFIX = 'cards/site-countries'

const queryFn = async () => {
  const reqConfig = {
    params: {}
  }
  const response = await axios.get(`/api/users/countries`, reqConfig)

  return response.data.countryList
}

const useApiGetSiteCountries = (options: { enabled?: boolean }) => {
  return useQuery(QUERY_KEY_PREFIX, () => queryFn(), {
    staleTime: 120000,
    ...options
  })
}

export default useApiGetSiteCountries
