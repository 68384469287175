import styled from 'styled-components'

export const ListWrapper = styled.ul`
  background: var(--palette-neutral-white);
  padding: 0;
  overflow-y: auto;
  border-bottom: 1px solid var(--color-grey-regular-300);

  li:nth-child(odd) {
    background-color: var(--color-grey-regular-100);
  }
  li:nth-child(even) {
    background-color: var(--palette-neutral-white);
  }
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`

export const DepotInfo = styled.p`
  flex: 1 1 90%;
  padding: 6px 8px;
  margin: 0;
`

export const DepotStatus = styled.span`
  flex: 1 1 10%;
  line-height: 1;
`
