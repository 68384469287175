import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

type Dates = {
  FIRST_DAY_OF_CURRENT_MONTH: string
  LAST_DAY_OF_CURRENT_MONTH: string
  LAST_MONTH: string
  FIRST_DAY_OF_LAST_MONTH: string
  LAST_DAY_OF_LAST_MONTH: string
  LAST_QUARTER_NUMBER: number
  FIRST_DAY_OF_LAST_QUARTER: string
  LAST_DAY_OF_LAST_QUARTER: string
}

const toString = 'YYYY-MM-DDTHH:mm:ssZ[Z]'

const getFirstDayOfLastQuarter = (LAST_QUARTER_NUMBER: number) => {
  if (LAST_QUARTER_NUMBER == 4) {
    return dayjs().subtract(1, 'years').quarter(LAST_QUARTER_NUMBER).startOf('quarter').format(toString)
  } else {
    return dayjs().quarter(LAST_QUARTER_NUMBER).startOf('quarter').format(toString)
  }
}

const getLastDayOfLastQuarter = (LAST_QUARTER_NUMBER: number) => {
  if (LAST_QUARTER_NUMBER == 4) {
    return dayjs().subtract(1, 'years').quarter(LAST_QUARTER_NUMBER).endOf('quarter').format(toString)
  } else {
    return dayjs().quarter(LAST_QUARTER_NUMBER).endOf('quarter').format(toString)
  }
}

export const getInsightsDates = (): Dates => {
  const FIRST_QUARTER = 1
  const LAST_QUARTER = 4
  const CURRENT_QUARTER = dayjs().quarter()

  // Current month
  const FIRST_DAY_OF_CURRENT_MONTH = dayjs().startOf('month').format(toString)
  const LAST_DAY_OF_CURRENT_MONTH = dayjs().endOf('month').format(toString)

  // Last month
  const FIRST_DAY_OF_LAST_MONTH = dayjs().subtract(1, 'month').startOf('month').format(toString)

  const LAST_DAY_OF_LAST_MONTH = dayjs().subtract(1, 'month').endOf('month').format(toString)

  const LAST_MONTH = dayjs().subtract(1, 'month').startOf('month').format('MMM YYYY')

  // Last quarter
  const LAST_QUARTER_NUMBER = CURRENT_QUARTER === FIRST_QUARTER ? LAST_QUARTER : CURRENT_QUARTER - 1
  const FIRST_DAY_OF_LAST_QUARTER = getFirstDayOfLastQuarter(LAST_QUARTER_NUMBER)
  const LAST_DAY_OF_LAST_QUARTER = getLastDayOfLastQuarter(LAST_QUARTER_NUMBER)

  return {
    FIRST_DAY_OF_CURRENT_MONTH,
    LAST_DAY_OF_CURRENT_MONTH,
    LAST_MONTH,
    FIRST_DAY_OF_LAST_MONTH,
    LAST_DAY_OF_LAST_MONTH,
    LAST_QUARTER_NUMBER,
    FIRST_DAY_OF_LAST_QUARTER,
    LAST_DAY_OF_LAST_QUARTER
  }
}
