import styled from 'styled-components'

export const CountryFlagWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > img {
    height: 25px;
  }
`
