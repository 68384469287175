/* eslint-disable no-useless-escape */
import { CssVars } from '@bp-digital/theme'
import styled, { css } from 'styled-components'
import { SELECTION } from './constants'

function getSelectionStateStyle(state) {
  switch (state) {
    case SELECTION.ALL:
      return css`
        border: 1px solid ${CssVars.color.grey.dark800};
        :after {
          content: '';
          width: 4px;
          height: 8px;
          border: solid ${CssVars.color.grey.dark800};
          border-width: 0 1px 1px 0px;
          position: absolute;
          left: 6px;
          top: 4px;
          transform: rotate(45deg);
        }
      `
    case SELECTION.SOME:
      return css`
        border: 1px solid ${CssVars.color.grey.dark800};
        :after {
          content: '';
          width: 50%;
          height: 50%;
          border: solid ${CssVars.color.grey.dark800};
          border-width: 0 0 1px 0;
          position: absolute;
          left: 25%;
          top: 1px;
        }
      `
    default:
      return ``
  }
}

export const BlankButton = styled.button`
  vertical-align: middle;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  color: inherit;
  text-align: inherit;
  cursor: pointer;
`

export const SelectionWrap = styled.div`
  position: relative;
`

export const SelectionState = styled.div`
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid ${CssVars.color.grey.light600};
  border-radius: 0px;
  overflow: hidden;

  ${props => getSelectionStateStyle(props.state)}
`

export const Closer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`

export const PopupBox = styled.div`
  position: absolute;
  font-size: ${CssVars.typography.size.sm};
  top: 110%;
  left: 0;
  z-index: 10;
  overflow: hidden;
  width: 20em;
  padding: 0.5em 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

export const Action = styled(BlankButton)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5em 0.75em;
  color: black;

  &:hover {
    background-color: #f7f7f7;
  }
`
export const SelectionButton = styled(BlankButton)`
  display: flex;
  align-items: center;
  outline: none;
  span[name='DownSmall'] {
    position: absolute;
    left: 100%;
  }
  :hover {
    div {
      border-color: solid ${CssVars.color.grey.dark800};
    }
  }
  :focus {
    div {
      border: solid 1px ${CssVars.palette.controls.focus};
    }
  }
`
