import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { Modal } from '@bp-digital/component-modal'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { format } from 'light-date'
import debounce from 'lodash.debounce'
import xlsx from 'json-as-xlsx'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import SingleTransactionView from './components/SingleTransactionView'
import PreferencesEditor from 'src/components/dataDisplay/PreferencesEditor'
import FullTransactionView from './components/FullTransactionView'
import PageHeader from 'src/components/layout/PageHeader'
import { ROUTE_TRANSACTIONS, ROUTE_ADMIN } from 'src/constants/routes'
import { MAX_TRANSACTION_COUNT_DOWNLOAD } from 'src/constants/transactions'
import replaceVariablesInString from 'src/helpers/replaceVariablesInString'
import { ReactTable } from 'src/components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
import {
  SingleTransactionViewWrapper,
  ButtonWrapper,
  AlertTypes,
  AlertTypeLabel,
  DownloadWarning,
  DownloadWaiting
} from './TransactionsPage.styled'
import { Text } from '@bp-digital/component-typography'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import DateRangeModal from './components/DateRangeModal'
import DATE_FILTERS from 'constants/dateFilters'
import { Button } from '@bp-digital/component-button'
import { CssVars } from '@bp-digital/theme'
import { Icon } from '@bp-digital/component-icon'

const TransactionsPage = () => {
  const location = useLocation()
  const history = useHistory()

  const resetLocation = useCallback(() => {
    history.replace({
      ...location,
      state: undefined
    })
  }, [history, location])

  useEffect(() => {
    window.addEventListener('beforeunload', resetLocation)

    return () => {
      window.removeEventListener('beforeunload', resetLocation)
    }
  }, [resetLocation])

  const { transactionsStore, contentStore, userStore } = useRootStore()
  const [isEditingColumns, setIsEditingColumns] = useState(false)
  const [transactionId, setTransactionId] = useState()
  const [showDateRangeModal, setShowDateRangeModal] = useState(false)
  const [dateRange, setDateRange] = useState(null)
  const [displayModal, setdisplayModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [isLoadingDownloadData, setIsLoadingDownloadData] = useState(false)

  const content = contentStore.getPage('transactions-transactions')
  const authorityId = userStore.selectedAuthorityId
  const selectedHierarchy = userStore.selectedHierarchy
  const loadingRow = transactionsStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  const initialSearchTerm = useUrlSearchParams().get('searchTerm') || ''
  const initialSearchTermColumnName = useUrlSearchParams().get('columnName') || ''
  const invoiceDate = useUrlSearchParams().get('invoiceDate') || ''

  const simpleFilters = [
    { id: DATE_FILTERS.THREE_MONTHS, label: content?.date_filter_three_months || 'date_filter_three_months' },
    { id: DATE_FILTERS.TWELVE_MONTHS, label: content?.date_filter_twelve_months || 'date_filter_twelve_months' },
    {
      id: DATE_FILTERS.CUSTOM_DATE,
      label: dateRange?.dateString || content?.date_filter_custom_date || 'date_filter_custom_date'
    }
  ]
  const activeFilter = simpleFilters.find(item => item.id === transactionsStore.simpleFilter) || simpleFilters[0]

  useEffect(() => {
    if (initialSearchTerm) transactionsStore.setSearchTerm(initialSearchTerm)

    if (initialSearchTermColumnName) {
      transactionsStore.setSearchColumnName(initialSearchTermColumnName)
    } else if (!initialSearchTermColumnName && transactionsStore.searchColumnName) {
      transactionsStore.setSearchTerm('')
      transactionsStore.setSearchColumnName('')
    }

    const safeToFetch = userStore.preferences && selectedHierarchy?.accessLevelCode
    const validSearchQuery = safeToFetch && initialSearchTerm && !transactionsStore.searchResults

    if (validSearchQuery || safeToFetch) {
      transactionsStore.getTransactions({
        selectedHierarchy,
        dateRange,
        invoiceDate,
        searchTerm: initialSearchTerm,
        columnName: initialSearchTermColumnName,
        transactionStatus: location?.state?.fromDashboard ? 'uninvoiced' : undefined,
        forceRefresh: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHierarchy, userStore.preferences, initialSearchTerm, initialSearchTermColumnName])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('transactions-transactions')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const handleSubmitSearch = async () => {
    await transactionsStore.getTransactions({ selectedHierarchy, dateRange })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      transactionsStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    transactionsStore.setSearchTerm('')
    transactionsStore.setSearchLimits({})
    transactionsStore.getTransactions({ selectedHierarchy, dateRange })

    if (initialSearchTerm)
      history.push({
        ...location,
        search: null
      })
  }

  const tableSearchProps = {
    searchTerm: transactionsStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.search_placeholder || '...',
    inputAriaLabel: content?.search_placeholder || '...',
    clearAriaLabel: content?.advancedFilters_clear || 'advancedFilters_clea',
    submitAriaLabel: content?.search || 'search',
    tooltipMessage: content?.search_tooltip || 'search_tooltip',
    hasBacklink: initialSearchTerm && initialSearchTermColumnName
  }

  const getTransactionsByIds = (transactionIds = []) => {
    const rawData = toJS(transactionsStore.data)
    const transactionPages = Object.values(rawData)

    const transactions = transactionIds
      .map(id => {
        return transactionPages
          .map(pageOfTransactions => {
            const transaction = pageOfTransactions.find(transaction => transaction.transactionUniqueId === id)
            return transaction
          })
          .find(val => !!val)
      })
      .filter(value => !!value)
    return transactions
  }

  const formatTransactionDownload = (transactions = []) => {
    let columns = transactionsStore.columns
    if (transactions.length) {
      columns = Object.keys(transactions[0]).map(key => ({
        key,
        name: content && content[`columnHeading_${key}`] ? content[`columnHeading_${key}`] : key
      }))
    }

    const transactionsWithFormattedDate = transactions.map(transaction => {
      ;['transactionDateTime', 'summaryStatementDate', 'invoiceDueDate', 'invoiceDate'].forEach(col => {
        if (transaction[col]) transaction[col] = new Date(transaction[col])
      })

      return transaction
    })

    const transactionsWithContent = transactionsWithFormattedDate.map(transaction => {
      const renamedTransaction = {}
      const keys = Object.keys(transaction)
      keys.forEach(originalKey => {
        const friendlyName =
          content && content[`columnHeading_${originalKey}`] ? content[`columnHeading_${originalKey}`] : originalKey
        renamedTransaction[friendlyName] = transaction[originalKey]
      })
      return renamedTransaction
    })

    return {
      sheet: 'Transactions',
      columns: columns.map(col => ({ label: col.name, value: col.name })),
      content: transactionsWithContent
    }
  }

  const handlePreferenceChange = async newlySelectedColumns => {
    await userStore.updatePreferences('transactions', newlySelectedColumns)
    await userStore.getPreferences()
    await transactionsStore.getTransactions({ selectedHierarchy, dateRange, forceRefresh: true })
  }

  const tableActionProps = [
    {
      text: content?.tableFilter_ctaColumn || 'tableFilter_ctaColumn',
      iconName: 'Edit',
      size: 'lg',
      disabled: transactionsStore.isFetchingData,
      onClick: () => {
        setIsEditingColumns(true)
      },
      dataAttributes: {
        'data-testid': 'button-edit-columns'
      }
    },
    {
      text: content?.tableFilter_ctaDownload || 'tableFilter_ctaDownload',
      iconName: 'Download',
      size: 'lg',
      onClick: selectedRows => {
        setSelectedRows(selectedRows)
        setdisplayModal(true)
      },
      dataAttributes: {
        'data-testid': 'button-download-transactions'
      }
    },
    {
      title: activeFilter.label,
      items: simpleFilters,
      disabled: !!initialSearchTermColumnName,
      onChange: newFilter => {
        if (newFilter === DATE_FILTERS.THREE_MONTHS || newFilter === DATE_FILTERS.TWELVE_MONTHS) {
          transactionsStore.setSimpleFilter(newFilter)
          transactionsStore.getTransactions({ selectedHierarchy, forceRefresh: true })
        } else {
          setShowDateRangeModal(true)
        }
      },
      size: 'lg'
    }
  ]

  const tablePagination = {
    currentPage: transactionsStore.currentPage,
    count: transactionsStore.numberOfPages || 1,
    pageSize: transactionsStore.pageSize,
    disabled: !transactionsStore.data,
    onChange: page =>
      transactionsStore.getTransactions({
        selectedHierarchy,
        dateRange,
        page,
        pageSize: transactionsStore.pageSize,
        invoiceDate
      }),
    onSizeChange: pageSize =>
      transactionsStore.getTransactions({
        selectedHierarchy,
        dateRange,
        page: 1,
        pageSize,
        invoiceDate
      })
  }

  const data = useMemo(
    () =>
      transactionsStore.isFetchingData
        ? Array(tablePagination.pageSize)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : transactionsStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionsStore.rows, transactionsStore.isFetchingData, tablePagination.currentPage, tablePagination.pageSize]
  )

  const columns = useMemo(
    () =>
      transactionsStore.isFetchingData
        ? transactionsStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : transactionsStore.columns.map(col => {
            if (col.key === 'transactionStatus') {
              return {
                Header: col.name,
                accessor: col.key,
                // Now that its a Badge component instead of plain string put this in store somehow?
                sortType: (rowA, rowB, id) => {
                  if (rowA.original[id].props?.text > rowB.original[id].props?.text) return -1
                  if (rowB.original[id].props?.text > rowA.original[id].props?.text) return 1
                  return 0
                }
              }
            }
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionsStore.columns, transactionsStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortTransactions = async (key = 'transactionDateTime', desc = true) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        transactionsStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!transactionsStore.results.length && transactionsStore.searchTerm.length) {
          await transactionsStore.getTransactions({
            selectedHierarchy,
            dateRange,
            invoiceDate,
            columnSorting,
            searchTerm: ''
          })
        }
        await transactionsStore.getTransactions({
          selectedHierarchy,
          dateRange,
          invoiceDate,
          columnSorting,
          forceRefresh: true
        })
      }
      if (transactionsStore.rows.length > 0) {
        sortTransactions(key, desc)
      }
    },
    [selectedHierarchy, dateRange, invoiceDate, transactionsStore]
  )

  useEffect(() => {
    return () => {
      transactionsStore.setSimpleFilter('')
      transactionsStore.setSearchTerm('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.hero_title || '...'}
        subtitle={replaceVariablesInString(content?.hero_copy1 || '...', {
          numberOfTransactions: transactionsStore.rows.length,
          totalNumberOfTransactions:
            (transactionsStore.searchTerm && transactionsStore.searchResults
              ? transactionsStore.searchLimits?.totalRecords || transactionsStore.limits?.totalRecords
              : transactionsStore.limits?.totalRecords) || '0'
        })}
        breadcrumbs={[{ to: ROUTE_TRANSACTIONS, title: content?.hero_title, ariaLabel: content?.hero_title }]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        {content && isEditingColumns && (
          <PreferencesEditor
            initialSelectedColumns={transactionsStore.selectedColumns}
            setIsOpen={setIsEditingColumns}
            onUpdate={handlePreferenceChange}
            sectionName='transactions'
            getAvailableColumns={() => transactionsStore.getAvailableColumns(authorityId)}
            content={content}
            locale={userStore.locale}
          />
        )}
        {transactionId && (
          <FullTransactionView
            transactionIds={transactionId}
            setTransactionId={setTransactionId}
            content={content}
            selectedHierarchy={selectedHierarchy}
          />
        )}
        {showDateRangeModal && (
          <DateRangeModal
            content={content}
            selectedHierarchy={selectedHierarchy}
            setFilter={filter => transactionsStore.setSimpleFilter(filter)}
            getTransactions={data => transactionsStore.getTransactions(data)}
            onClose={() => setShowDateRangeModal(false)}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        <ReactTable
          name='transactions'
          columns={columns}
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          showSelectAllCheckbox={true}
          expandSubRowOnSelect={false}
          renderDetail={({ original: { transactionUniqueId, transactionId, brand, fullCardNumber } }) => (
            <SingleTransactionViewWrapper>
              <SingleTransactionView
                id={transactionUniqueId}
                onExpand={() => setTransactionId({ transactionUniqueId, transactionId })}
                content={content}
                brand={brand}
                cardNumber={fullCardNumber}
              />
            </SingleTransactionViewWrapper>
          )}
          onSort={handleSort}
          isLoading={transactionsStore.isFetchingData}
        />
        {displayModal && (
          <Modal
            title={content?.tableFilter_ctaDownload || 'tableFilter_ctaDownload'}
            onDismiss={() => setdisplayModal(false)}
            visible
            size='lg'
          >
            <ButtonWrapper>
              <div>
                <Button
                  appearance='primary'
                  onClick={() => {
                    const transactions = getTransactionsByIds(selectedRows)
                    const formatedTransactionDownload = formatTransactionDownload(transactions)
                    const date = format(new Date(), '{dd}{MM}{yy}')

                    xlsx([formatedTransactionDownload], {
                      fileName: `${content?.hero_title}_${selectedHierarchy.accessLevelCode}_${date}`,
                      extraLength: 3,
                      writeOptions: {}
                    })
                  }}
                  disabled={!selectedRows.length}
                  iconName='Download'
                  size='lg'
                  data-testid='button-download-transactions-selected'
                >
                  {`${content?.downloadModal_subtitle || 'downloadModal_subtitle'} (${selectedRows.length})`}
                </Button>
              </div>
              <div>
                {transactionsStore.limits.totalRecords > MAX_TRANSACTION_COUNT_DOWNLOAD && (
                  <DownloadWarning>
                    <AlertTypes>
                      <AlertTypeLabel>
                        <Icon name='Alert' style={{ color: CssVars.palette.state.dangerDark }} />
                        <Text as='span'>{`${
                          content?.downloadModal_max_transactions || 'downloadModal_max_transactions'
                        }: ${MAX_TRANSACTION_COUNT_DOWNLOAD}`}</Text>
                      </AlertTypeLabel>
                    </AlertTypes>
                  </DownloadWarning>
                )}
                <Button
                  appearance='primary'
                  onClick={async () => {
                    setIsLoadingDownloadData(true)
                    const transactions = await transactionsStore.getDownloadData({
                      selectedHierarchy,
                      dateRange,
                      invoiceDate,
                      transactionStatus: location?.state?.fromDashboard ? 'uninvoiced' : undefined
                    })
                    const formatedTransactionDownload = formatTransactionDownload(transactions)
                    const date = format(new Date(), '{dd}{MM}{yy}')

                    xlsx([formatedTransactionDownload], {
                      fileName: `${content?.hero_title}_${selectedHierarchy.accessLevelCode}_${date}`,
                      extraLength: 3,
                      writeOptions: {}
                    })

                    setIsLoadingDownloadData(false)
                  }}
                  disabled={
                    isLoadingDownloadData ||
                    !transactionsStore.limits ||
                    !transactionsStore.limits.totalRecords ||
                    transactionsStore.limits.totalRecords > MAX_TRANSACTION_COUNT_DOWNLOAD
                  }
                  iconName={isLoadingDownloadData ? 'SpinnerCircle' : 'Download'}
                  size='lg'
                  data-testid='button-download-transactions-all'
                >
                  {`${content?.downloadModal_all || 'downloadModal_all'} (${transactionsStore.limits.totalRecords})`}
                </Button>
              </div>
            </ButtonWrapper>
            {isLoadingDownloadData && (
              <DownloadWaiting>{content?.download_awaiting || 'download_awaiting'}</DownloadWaiting>
            )}
          </Modal>
        )}
      </InnerPageWrapper>
    </>
  )
}

export default observer(TransactionsPage)
