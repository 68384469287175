import { useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

export const pieChartColors = [
  'var(--color-green-regular-300)',
  'var(--color-green-regular-900)',
  'var(--color-blue-regular-900)'
]

function FullOption(props) {
  const [hovered, setHovered] = useState(undefined)

  const data = props.data.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: 'grey'
      }
    }

    return {
      color: pieChartColors[i] || 'green',
      ...entry
    }
  })

  const lineWidth = 60

  return (
    <PieChart
      style={{
        fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
        fontSize: '8px'
      }}
      data={data}
      radius={38}
      lineWidth={100}
      segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
      segmentsShift={index => (index === props.selected ? 6 : 1)}
      animate
      label={({ dataEntry }) => {
        if (Math.round(dataEntry.percentage) !== 0) {
          return Math.round(dataEntry.percentage) + '%'
        }
      }}
      labelPosition={data.length > 1 ? 100 - lineWidth / 2 : 0}
      labelStyle={{
        fill: '#fff',
        opacity: 0.75,
        pointerEvents: 'none'
      }}
      onClick={(_, index) => {
        props.setSelected(index)
      }}
      onMouseOver={(_, index) => {
        setHovered(index)
      }}
      onMouseOut={() => {
        setHovered(undefined)
      }}
    />
  )
}

export default FullOption
