import { RESTRICTION_TYPES } from 'constants/restrictions'
import { TileSelector } from '@bp-digital/component-tile-selector'
import { Heading, Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { Spacer } from 'styles/common.styled'
import { TileWrapper, ButtonWrapper } from 'pages/Cards/Restrictions/components/EditRestrictions.styled'

const SelectRestrictionTypes = ({ content, onBack, onNext, restrictionTypes, setRestrictionTypes }) => {
  const restrictionTypesData = [
    {
      id: RESTRICTION_TYPES.SPENDING_LIMIT,
      icon: 'CvcCvv',
      text: content?.restriction_spending_limit_title
    },
    {
      id: RESTRICTION_TYPES.PRODUCTS_AND_SERVICES,
      icon: 'Date',
      text: content?.restriction_products_title
    },
    {
      id: RESTRICTION_TYPES.SITE_LOCATIONS,
      icon: 'Pin',
      text: content?.restriction_site_title
    },
    {
      id: RESTRICTION_TYPES.DAY_AND_TIME,
      icon: 'Clock',
      text: content?.restriction_date_time_title
    },
    {
      id: RESTRICTION_TYPES.ODOMETER,
      icon: 'Settings',
      text: content?.restriction_odometer_title
    }
  ]

  return (
    <>
      <Heading as='h3'>{content?.restriction_title}</Heading>
      <Text size='lg'>{content?.restriction_heading}</Text>
      <Spacer />
      <TileWrapper>
        {restrictionTypesData.map(type => (
          <TileSelector
            key={type.id}
            id={type.id}
            text={type.text}
            defaultChecked={restrictionTypes.includes(type.id)}
            iconName={type.icon}
            onChange={value =>
              value
                ? setRestrictionTypes([...restrictionTypes, type.id])
                : setRestrictionTypes(restrictionTypes.filter(id => id !== type.id))
            }
          />
        ))}
      </TileWrapper>
      <ButtonWrapper>
        <Button
          dataAttributes={{ 'data-content-key': 'masthead_back', 'data-testid': 'button-back' }}
          iconName='LeftLarge'
          appearance='tertiary'
          onClick={onBack}
        >
          {content?.masthead_back}
        </Button>
        <Button disabled={restrictionTypes.length === 0} onClick={onNext}>
          {content?.next_step}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default SelectRestrictionTypes
