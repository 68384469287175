import {
  TwoColumnWrapper,
  TagsWrapper,
  ButtonGroupWrapper
} from 'pages/Cards/Restrictions/components/EditRestrictions.styled'
import { ButtonGroup } from '@bp-digital/component-button-group'
import ProductFields from '../ProductFields'
import { Heading } from '@bp-digital/component-typography'
import { Controller } from 'react-hook-form'
import { Button } from '@bp-digital/component-button'
import { PRODUCTS_ALLOWED, PRODUCTS_APPLY } from 'constants/restrictions'
import { getProductName } from 'helpers/restrictions'

const AllProducts = ({ category, content, control, isAllow, setValue, currency }) => {
  const fieldPrefix = `categories.${category.categoryId}`
  const handleReset = () => {
    setValue(`${fieldPrefix}.dailyMaxVolume`, null)
    setValue(`${fieldPrefix}.dailyMaxValue`, null)
    setValue(`${fieldPrefix}.txnMaxVolume`, null)
    setValue(`${fieldPrefix}.txnMaxValue`, null)
  }

  return (
    <TwoColumnWrapper>
      <div>
        {category.products.map(product => (
          <TagsWrapper key={product.productServiceName}>
            <Button appearance='secondary' disabled={true}>
              {getProductName(product.productServiceId, content)}
            </Button>
          </TagsWrapper>
        ))}
      </div>
      <div>
        <Heading as='h4'>{content?.restriction_products_all_products || 'restriction_products_all_products'}</Heading>
        <ButtonGroupWrapper>
          <Controller
            name={`${fieldPrefix}.isAllow`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ButtonGroup
                id={name}
                items={[
                  {
                    id: PRODUCTS_ALLOWED.ALLOW,
                    label: content?.restriction_site_allow || 'restriction_site_allow'
                  },
                  {
                    id: PRODUCTS_ALLOWED.NOT_ALLOW,
                    label: content?.restriction_site_not_allow || 'restriction_site_not_allow'
                  }
                ]}
                defaultSelectedId={value}
                onChange={onChange}
              />
            )}
          />
        </ButtonGroupWrapper>
        {isAllow && (
          <ProductFields
            fieldPrefix={fieldPrefix}
            content={content}
            control={control}
            onReset={() => handleReset()}
            currency={currency}
            isProductsAll={PRODUCTS_APPLY.ALL}
          />
        )}
      </div>
    </TwoColumnWrapper>
  )
}

export default AllProducts
