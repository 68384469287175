import { Badge } from '@bp-digital/component-badge'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import BaseDataStore from '../BaseDataStore'
import { action, makeObservable, observable } from 'mobx'
import axios from 'axios'

const getStateFromStatus = (status = '') => {
  status = status?.toLowerCase()

  if (status === 'active') {
    return 'success'
  } else if (status === 'inactive' || status === 'link expired') {
    return 'danger'
  }

  return 'warning'
}

class AccessAdminUserStore extends BaseDataStore {
  columnSorting = []
  simpleFilter = ''
  defaultColumns = [
    'groupselection_description', // should be status, temp work-around to correct content
    'name',
    'email',
    'mobileNumber',
    'groups',
    'parents',
    'authorities',
    'lastLogin'
  ]
  authIdRegEx = new RegExp('^[A-Za-z]?[0-9]{3,7}$')
  adminView = false

  constructor(root) {
    super(root, 'access', 'access-users')

    makeObservable(this, {
      adminView: observable,
      setAdminView: action
    })
  }

  get rows() {
    return this.results.map(user => {
      const status = user.status?.toLowerCase().replace(' ', '_')
      const statusContent = this.content?.[`status_${status}`]
      const dateFormat = this.root.userStore.dateFormat
      let mobileNumber

      if (user.mobileNumber && user.mobileNumber !== '0') {
        const prefix = user.mobileNumberIsdCode || ''
        mobileNumber = `${prefix}${user.mobileNumber}`
      }

      let row = {
        type: 'MASTER',
        key: user.uId?.toString(),
        impersonationId: status === 'active' ? user.userUniqueId : '',
        name: user.name,
        email: user.email,
        mobileNumber: mobileNumber || '-',
        groupselection_description: <Badge text={statusContent} state={getStateFromStatus(user.status)} />, // should be status, temp workaround for content
        groups: user.groupsCount,
        parents: user.parentsCount,
        authorities: user.authoritiesCount,
        lastLogin: getUserFormattedDate(user.lastLogin, dateFormat) || '-',
        status: user.status,
        userUniqueId: user.userUniqueId,
        accessExpiryDateTime: getUserFormattedDate(user.accessExpiryDateTime, dateFormat) || '-'
      }

      if (this.adminView) {
        row.inviteEmail = user.inviteEmail || '-'
        row.invitationSentDateTime = getUserFormattedDate(user.invitationSentDateTime, dateFormat) || '-'
        row.activationDateTime = getUserFormattedDate(user.activationDateTime, dateFormat) || '-'
      }

      return row
    })
  }

  setAdminView(adminView) {
    this.adminView = adminView
  }

  async getUsers({
    columnSorting = this.columnSorting,
    searchTerm = this.searchTerm,
    page = 1,
    pageSize = 15,
    forceRefresh = false
  } = {}) {
    const statusFilter = []

    if (this.simpleFilter) {
      statusFilter.push(this.simpleFilter)
    }

    const params = {
      pageSize,
      page
    }

    if (columnSorting && columnSorting.length === 2) {
      params.sortBy = columnSorting[0]
      params.sortOrder = columnSorting[1]
    }

    if (searchTerm) {
      const searchColumn = this.authIdRegEx.test(searchTerm) && this.adminView ? ['authorityId'] : ['name', 'email']
      const searchText = searchTerm

      if (searchColumn.length == 1 && searchColumn[0] === 'authorityId') {
        params.searchByAuthorityId = searchText
      } else {
        params.searchBy = searchText
      }
    }

    if (statusFilter.length === 1) {
      params.filterby = 'UserStatus'
      params.filterCriteria = statusFilter[0]
    }

    await super.getData({
      method: 'get',
      url: '/api/users/users/accessibleusers',
      totalRecordsKey: 'totalRecords',
      responseKey: 'users',
      page,
      params,
      searchTerm,
      forceRefresh
    })
  }

  async resendInvite(userId, authorityId) {
    return await axios({
      method: 'post',
      url: `/api/users/users/${userId}/resendinvite`,
      data: {
        uId: userId,
        sourceSystem: 'CSS'
      },
      params: {
        accessLevel: 'A',
        accessLevelCode: authorityId
      }
    })
  }

  async updateInviteEmail(userId, email) {
    return await axios({
      method: 'put',
      url: `/api/users/users/${userId}/update-email`,
      data: {
        Email: email
      }
    })
  }
}

export default AccessAdminUserStore
