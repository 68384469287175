import { RESTRICTION_TYPES } from 'constants/restrictions'

const buildRequest = ({ profileId, profileName, siteTypes, restrictionList, violationId, allowNotListed }) => {
  return {
    restrictionDetail: {
      saveProfile: true,
      profileName: profileName,
      profileId: profileId,
      restrictionTypes: [RESTRICTION_TYPES.SITE_LOCATIONS],
      existingProfile: true,
      siteLocations: {
        acceptSitesViolation: violationId === 3 ? '0' : '1',
        alertSitesViolation: violationId === 1 ? '0' : '1',
        isAllowNotListed: allowNotListed ? 1 : 0,
        allowedSiteGroupIds: siteTypes.filter(type => type.value).map(({ siteGroupId }) => siteGroupId),
        deniedSiteGroupIds: siteTypes.filter(type => !type.value).map(({ siteGroupId }) => siteGroupId),
        sites: restrictionList.map(site => ({
          isAllow: site.allow ? 1 : 0,
          countryCode: site.selectedCountry.countryCode,
          countryId: site.selectedCountry.countryId,
          areaId: site.selectedRegion.areaId,
          site: {
            globalSiteId: site.selectedSite.globalSiteId,
            siteTypeId: site.selectedSite.siteTypeId || null,
            siteGroupId: site.selectedSite.siteGroupTypeId || null
          }
        }))
      }
    }
  }
}

export default buildRequest
