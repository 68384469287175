/**
 * Refactors Preferences Data for DropDown options
 *
 * @param {Object} data - the raw response from a get preferences request
 * @returns {Object}
 */
const refactorPreferencesData = data => {
  const refactoredData = {}

  Object.keys(data).map(key => {
    if (Array.isArray(data[key])) {
      refactoredData[key] = data[key].map(item => ({
        label: item.description,
        id: item.value
      }))
    }
  })

  return refactoredData
}

export default refactorPreferencesData
