import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1500px;
  column-gap: 16px;
  row-gap: 16px;

  @media only screen and ${breakpoints.device.lg} {
    column-gap: 32px;
    row-gap: 16px;
  }

  > * {
    &:nth-child(1) {
      grid-column: 1/3;
      grid-row: 1;

      @media only screen and ${breakpoints.device.lg} {
        grid-column: 1/2;
      }
    }

    &:nth-child(2) {
      grid-column: 1/3;
      grid-row: 2;

      @media only screen and ${breakpoints.device.lg} {
        grid-column: 2;
        grid-row: 1;
      }
    }

    &:nth-child(3) {
      grid-column: 1/3;
      grid-row: 3;
    }
  }
`
