import { CARD_TYPE, COUNTRY_CODES, SEND_TO_ADDRESS, DISPATCH_ADDRESS_DETAILS } from '../constants/constants'

export const validateRequiredVehicleRegistration = ({ cardTypeId, driverName }) =>
  !driverName?.trim() ||
  cardTypeId === CARD_TYPE.DRIVER_CARD.id ||
  cardTypeId === CARD_TYPE.VEHICLE_CARD.id ||
  cardTypeId === CARD_TYPE.DRIVER_PIN_CARD.id ||
  cardTypeId === CARD_TYPE.DRIVER_VEHICLE_CARD.id

export const validateRequiredDriverName = ({ cardTypeId, countryCode, sendToAddress }) =>
  cardTypeId === CARD_TYPE.DRIVER_CARD.id ||
  cardTypeId === CARD_TYPE.DRIVER_VEHICLE_CARD.id ||
  (cardTypeId === CARD_TYPE.POOL_CARD.id && sendToAddress === SEND_TO_ADDRESS.CARD_HOLDER) ||
  (cardTypeId === CARD_TYPE.POOL_CARD.id && countryCode === COUNTRY_CODES.GERMANY)

export const validateNonEmptyVehicleOrDriverField = vehicleRegistration => !vehicleRegistration?.trim()

export const validateNonValidCharacters = /^[0-9A-Z()%,.&'/\- ]+$/

export const validateAlphaNumericAndSpecialCharacters =
  /^[0-9A-Za-zÉËÏÓÖÜéëïóöüÄÖÜẞäöüß \\\]\\[&_"!#$%'()*+,-./";:<@>=^]+$/

export const validateAlphaNumericAndSpecialCharactersExtended =
  /^[0-9A-Za-zÁÂÄÇÉËÍÎÓÔÖ×ÚÜÝßáâäçéëíîóôö÷úüý\\\][_ !"#$%&'()*+,-./:;<=>@^]+$/ // VALUE 32-62, VALUE 64-95, VALUE 97-122, VALUE 192-255

export const validateAlphaNumericAndSpecialCharactersUpdated = /^[0-9A-Za-z\\\][_ !"#$%&'()*+,-./:;<=>@^]+$/ // VALUE 32-62, VALUE 64-95, VALUE 97-122.

export const validateDeliveryAddress = (sendToAddress, addressLine1, addressLine2, addressLine3, postCode, field) => {
  if (sendToAddress === SEND_TO_ADDRESS.CARD_HOLDER) {
    return true
  }

  switch (field) {
    case DISPATCH_ADDRESS_DETAILS.addressLine1:
      return addressLine1 != ''
    case DISPATCH_ADDRESS_DETAILS.addressLine2:
      return addressLine2 != ''
    case DISPATCH_ADDRESS_DETAILS.addressLine3:
      return addressLine3 != ''
    case DISPATCH_ADDRESS_DETAILS.postCode:
      return postCode != ''

    default:
      return false
  }
}
