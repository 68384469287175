import styled from 'styled-components'

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0;
`

export const List = styled.ul`
  list-style: disc;
  padding-left: 25px;
  font-weight: 300;

  span {
    list-style: none;
  }
`
