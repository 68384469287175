import BaseDataStore from '../BaseDataStore'

const defaultColumns = ['dailySpendLimit', 'weeklySpendLimit', 'monthlySpendLimit', 'rollingDays', 'validationMethod']

const getViolationMethod = (content, acceptViolation, alertViolation) => {
  if (acceptViolation && alertViolation) {
    return content?.spending_limits_accept_alert
  } else if (alertViolation) {
    return content?.edit_spending_limit_reject_and_alart
  } else if (acceptViolation) {
    return content?.spending_limits_accept
  }

  return content?.spending_limits_accept // default to 'Accept' for empty record
}

class CardsSpendingLimitStore extends BaseDataStore {
  columnSorting = ['parentNumber', 'asc']

  constructor(root) {
    super(root, 'cards', 'cards-limits')
  }

  get rows() {
    return this.results.map(limit => ({
      type: 'MASTER',
      ...limit,
      key: limit.customerId + limit.authorityId,
      validationMethod: getViolationMethod(this.content, limit.acceptViolation, limit.alertViolation)
    }))
  }

  get columns() {
    return (
      defaultColumns?.map(preference => ({
        key: preference,
        name: this.content ? this.content[`columnHeading_${preference}`] : '...'
      })) || [{ key: '...', name: '...' }]
    )
  }

  async getSpendingLimits({
    authorityId,
    columnSorting = this.columnSorting,
    searchTerm = this.searchTerm,
    page = 1,
    limit = this.limits?.nextPageLimit || 15,
    forceRefresh
  }) {
    const accessLevel = 'A'
    const payload = {
      accessId: `${accessLevel}${authorityId}`,
      applyLevel: accessLevel,
      userLevel: accessLevel,
      authorityId: authorityId,
      limit,
      start: (page - 1) * limit + 1,
      searchText: null,
      sortCriteria: {
        sortBy: columnSorting[0],
        sortOrder: columnSorting[1]
      }
    }

    if (searchTerm) {
      payload.searchText = searchTerm
    }
    let params = {}
    params.authorityIds = authorityId
    await super.getData({
      method: 'get',
      url: '/api/users/hierarchy/authorities/accountlimits',
      params,
      payload,
      totalRecordsKey: 'totalCards',
      responseKey: 'accountSpendingLimits',
      page,
      searchTerm,
      forceRefresh
    })
  }
}

export default CardsSpendingLimitStore
