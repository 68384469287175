import PublicLayout from 'components/layout/PublicLayout'
import styled from 'styled-components'

const Content = styled.p`
  padding: 16px 0;
  text-align: left;

  > span:first-child {
    font-weight: bold;
  }
`
const Text = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.2em;
  color: black;
`
interface props {
    text: string
  }

const ErrorDisplay = (props: props) => {
 return (
    <PublicLayout>
        <Content>
            {props.text && <Text>{props.text}</Text>}
        </Content>
    </PublicLayout>
 )
}

export default ErrorDisplay