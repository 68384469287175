import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'
import { Text } from '@bp-digital/component-typography'
import breakpoints from 'src/styles/breakpoints'

interface UserMenuStyleProps {
  $image?: string
}

const UserMenuContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

const UserMenuToggle = styled.button<UserMenuStyleProps>`
  /* Clear button style */
  border: none;
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  column-gap: ${CssVars.structure.spacing.lg};
  color: ${CssVars.palette.neutral.default};
  cursor: pointer;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :focus-visible {
    outline: 2px solid ${CssVars.palette.controls.focusSelected};
  }

  @media only screen and ${breakpoints.device.lg} {
    :hover {
      background-color: ${CssVars.palette.neutral.lightest};
    }
  }
`

const UserMenuProfileImage = styled.img`
  border-radius: ${CssVars.structure.borderRadius.circle};
  width: 40px;
  height: 40px;
`

const UserMenuLinksContainer = styled.div<UserMenuStyleProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 65px;
  background-color: ${CssVars.palette.neutral.white};
  box-shadow: 0 ${CssVars.structure.spacing.xl} ${CssVars.structure.spacing.xxl2} 0 ${CssVars.palette.neutral.dark};
  width: 100%;

  @media only screen and ${breakpoints.device.lg} {
    margin-top: 65px;
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    width: 256px;
  }
`

const UserMenuName = styled(Text)`
  font-size: 16px;
`

export { UserMenuContainer, UserMenuToggle, UserMenuProfileImage, UserMenuLinksContainer, UserMenuName }
