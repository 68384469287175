export const LOCALE_EN = 'en'
export const LOCALE_ES = 'es'
export const LOCALE_PT = 'pt'
export const LOCALE_PL = 'pl'
export const LOCALE_DE = 'de'
export const LOCALE_NL = 'nl'

export const DEFAULT_LOCALE = LOCALE_EN

export default [
  LOCALE_EN,
  LOCALE_ES,
  LOCALE_DE,
  LOCALE_NL,
  LOCALE_PT,
  LOCALE_PL
]
