import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { CssVars } from '@bp-digital/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:nth-child(1) {
    flex: 1;
    padding-bottom: 16px;

    @media only screen and ${breakpoints.device.sm} {
      padding-bottom: 32px;
    }
  }

  &:nth-child(2) {
    flex: 0.3;
    align-items: center;
    gap: 16px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px 32px;

  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
    gap: 16px;
    margin: 0 16px;
  }
`

export const Banner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  padding: 0 16px;
  background-color: ${CssVars.palette.neutral.white};
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9995;
  background-color: rgba(0, 0, 0, 0.5);
`
