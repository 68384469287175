const titleCase = str => {
  const splitStr = str.toLowerCase().split(' ')

  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

const getSiteLocationLabel = site => {
  const label = []

  if (site.siteName) {
    label.push(titleCase(site.siteName))
  }
  if (site.address.addressLine1) {
    label.push(titleCase(site.address.addressLine1))
  }
  if (site.address.addressLine2) {
    label.push(titleCase(site.address.addressLine2))
  }
  if (site.address.addressLine3) {
    label.push(titleCase(site.address.addressLine3))
  }
  if (site.address.town) {
    label.push(titleCase(site.address.town))
  }
  if (site.address.postCode) {
    label.push(site.address.postCode)
  }
  return label.join(',')
}

export default getSiteLocationLabel
