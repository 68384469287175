import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { TableCheckbox } from '../TableCheckbox'
import { SELECTION } from './constants'
import Selector from './Selector'

const MultiSelector = ({ selectOptions, selectedRowIds, totalRecords, tableCheckboxProps, showSelectAllCheckbox }) => {
  const selectionState = useMemo(() => {
    if (selectedRowIds.length === 0) return SELECTION.NONE
    if (selectedRowIds.length === totalRecords) return SELECTION.ALL
    return SELECTION.SOME
  }, [totalRecords, selectedRowIds])

  if (!showSelectAllCheckbox) return null

  if (selectOptions.length === 0) {
    return <TableCheckbox {...tableCheckboxProps()} isSelectAllCheckbox />
  }

  return <Selector selectionOptions={selectOptions} selectionState={selectionState} />
}

MultiSelector.propTypes = {
  selectedRowIds: PropTypes.array,
  totalRecords: PropTypes.number,
  tableCheckboxProps: PropTypes.func,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      action: PropTypes.func.isRequired
    })
  )
}

export default MultiSelector
