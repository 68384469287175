import { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { useRootStore } from 'src/contexts/StoreContext'
import { Redirect } from 'react-router-dom'
import { PERMISSION_TO_VIEW_EV_MENU } from 'constants/permissions'
import { ROUTE_ADMIN, ROUTE_CHARGE_POINTS, ROUTE_DASHBOARD } from '../../constants/routes'
import SplashScreen from 'src/components/feedback/SplashScreen'

const RedirectPage = () => {
  const { userStore } = useRootStore()
  const [redirectDashboard, setRedirectDashboard] = useState(false)
  const [redirectEvis, setRedirectEvis] = useState(false)
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  const mountedRef = useRef(true)

  useEffect(() => {
    if (!mountedRef.current) return null
    const loaded = userStore.userIsReady
    const isEVUser = userStore.userPermissions?.includes(PERMISSION_TO_VIEW_EV_MENU)
    if (loaded && isEVUser) return setRedirectEvis(true)
    if (loaded && !isEVUser && (!userStore.isAdmin || userStore.impersonatedUser)) return setRedirectDashboard(true)
    if (userStore.isAdmin && !userStore.impersonatedUser) return setRedirectAdmin(true)
    userStore.init()
  }, [userStore, userStore.userIsReady, userStore.userPermissions, userStore.isAdmin, userStore.impersonatedUser])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  if (!mountedRef.current) return null

  if (redirectAdmin) return <Redirect to={ROUTE_ADMIN} />
  if (redirectEvis) return <Redirect to={ROUTE_CHARGE_POINTS} />
  if (redirectDashboard) return <Redirect to={ROUTE_DASHBOARD} />

  return <SplashScreen />
}

export default observer(RedirectPage)
