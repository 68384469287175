const getSelectedSummaries = (selectedRows, selectedInvoices = []) => {
  const summaries = {}

  selectedRows.forEach(row => {
    if (!row.includes('child')) {
      summaries[row] = []
    } else if (row.includes('child') && !selectedInvoices.includes(row)) {
      const parentKey = row.split('-')[0]
      summaries[parentKey] = summaries[parentKey] || []
      summaries[parentKey].push(row)
    }
  })

  return summaries
}

export default getSelectedSummaries
