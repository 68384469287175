import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import DataTable from 'src/components/dataDisplay/DataTable'
import { ROUTE_ACCESS_ADMINISTRATION_USER_ADD, ROUTE_ADMIN } from 'src/constants/routes'
import { Redirect } from 'react-router-dom'

const tableColumns = [
  { key: 'name', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'mobile', name: 'Mobile phone' },
  { key: 'status', name: 'Status' },
  { key: 'groups', name: 'Groups' },
  { key: 'parents', name: 'Parents' },
  { key: 'authorities', name: 'Authorities' },
  { key: 'login', name: 'Last login' }
]

function getComparatorForColumnSorting(sortColumn) {
  switch (sortColumn) {
    case 'name':
    case 'email':
    case 'status':
    case 'login':
      return (a, b) => {
        return a[sortColumn]?.localeCompare(b[sortColumn])
      }
    case 'mobile':
    case 'groups':
    case 'parents':
    case 'authorities':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn]
      }
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`)
  }
}

const GroupAdministrationPage = () => {
  const { groupAdminStore, contentStore, userStore, brand } = useRootStore()
  const content = contentStore.getPage('access-groups')

  useEffect(() => {
    if (!content) {
      contentStore.getContent('access-groups')
    }

    if (!groupAdminStore.data) {
      groupAdminStore.getGroups()
    }
  }, [content, contentStore, groupAdminStore])

  const handleSubmitSearch = async () => {
    await groupAdminStore.getGroups(groupAdminStore.searchTerm)
  }

  const tableSearchProps = {
    searchTerm: groupAdminStore.searchTerm,
    setSearchTerm: groupAdminStore.setSearchTerm,
    onSubmit: handleSubmitSearch,
    placeholder: 'Find a group',
    inputAriaLabel: 'Find a group',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  const tableActionProps = [
    { to: ROUTE_ACCESS_ADMINISTRATION_USER_ADD, text: 'New group', iconName: 'People', size: 'lg' }
  ]

  const tablePagination = {
    currentPage: groupAdminStore.currentPage,
    count: groupAdminStore.numberOfPages || 1,
    disabled: !groupAdminStore.data,
    onChange: value => groupAdminStore.getGroups(null, value)
  }

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.add_group_groups || '...'}
        subtitle='You have no groups yet'
        breadcrumbs={[{ to: '/', title: 'Group administration', ariaLabel: 'Group administration' }]}
        brand={brand}
      />
      <InnerPageWrapper>
        <DataTable
          rows={groupAdminStore.rows}
          columns={tableColumns}
          columnSorter={getComparatorForColumnSorting}
          search={tableSearchProps}
          actions={tableActionProps}
          pagination={tablePagination}
          isLoading={groupAdminStore.isFetchingData}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(GroupAdministrationPage)
