import axios from 'axios'
import { useQuery } from 'react-query'
import getCurrencySymbol from 'helpers/getCurrencySymbol'

type Payload = {
  authorityId?: string | null
  groupId?: string | null
  parentId?: string | null
}

interface Widgets {
  uniqueId: string
  correlationId: string
  totalUninvoiceValueWithVat: TotalUninvoiceValueWithVat[]
  totalUninvoiceValueWithoutVat: TotalUninvoiceValueWithoutVat[]
  issuerCurrency: IssuerCurrency[]
  productDetailList: ProductDetail[]
}

interface TotalUninvoiceValueWithVat {
  total: string
  currencyCode: string
}

interface TotalUninvoiceValueWithoutVat {
  total: string
  currencyCode: string
}

interface IssuerCurrency {
  currencyCode: string
  countryCode: string
}

interface ProductDetail {
  productName: string
  productValueWithVat: string
  productValueWithoutVat: string
  quantity: string
  quantityUnits: string
}

const getChartData = (widgets: Widgets) => {
  return (
    widgets?.productDetailList?.map(product => {
      return {
        title: product.productName,
        value: parseInt(product.productValueWithVat, 10),
        ...product,
        productValueWithVat: parseFloat(product.productValueWithVat || '0').toFixed(2),
        productValueWithoutVat: parseFloat(product.productValueWithoutVat || '0').toFixed(2)
      }
    }) || []
  )
}

const getTotalUninvoiceValue = (widgets: Widgets) => {
  const withVat = widgets?.totalUninvoiceValueWithVat[0] || {}
  const withoutVat = widgets?.totalUninvoiceValueWithoutVat[0] || {}
  const withVatTotal = parseFloat(withVat.total || '0').toFixed(2)
  const withoutVatTotal = parseFloat(withoutVat.total || '0').toFixed(2)

  return {
    withVat: `${withVatTotal} ${withVat.currencyCode || ''}`,
    withoutVat: `${withoutVatTotal} ${withoutVat.currencyCode || ''}`
  }
}

const getComputedValues = (widgets: Widgets) => {
  const currencyCode = widgets?.issuerCurrency?.[0]?.currencyCode
  const currencySymbol = getCurrencySymbol(currencyCode)

  const countryCode = widgets?.issuerCurrency?.[0]?.countryCode
  const chartData = getChartData(widgets)
  const totalUninvoiceValue = getTotalUninvoiceValue(widgets)

  return {
    currencySymbol,
    currencyCode,
    countryCode,
    totalUninvoiceValue,
    chartData
  }
}

const queryFn = async ({ authorityId, parentId = null }: Payload) => {
  const params = {
    accessLevel: 'A',
    accessLevelCode: authorityId,
    authorityIds: [authorityId],
    parentIds: [parentId],
    widgetType: 'uninvoice'
  }

  const response = await axios.get('/api/transactions/dashboard/widget', { params })
  return response.data
}

const useApiGetWidgets = (payload: Payload) => {
  const payloadKeys = Object.keys(payload) as Array<keyof Payload>
  const QUERY_KEY = payloadKeys.reduce((key, id) => (payload[id] ? `${key}-${payload[id]}` : key), 'widgets')
  const response = useQuery([QUERY_KEY, payload], () => queryFn(payload), {
    staleTime: 120000,
    retry: false, // Handle the error or the request timeout after 15 seconds in component
    select: getComputedValues
  })

  return response
}

export default useApiGetWidgets
