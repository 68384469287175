export const formatCategoriesAndProducts = (categories, fullCategoriesData) => {
  let arrayOfObjects = []

  Object.entries(categories).forEach(([key, category]) => {
    let productsArray = []

    if (category.products) {
      const fullCategoryData = fullCategoriesData.find(c => c.categoryId == key)
      Object.entries(category.products).forEach(([key, product]) => {
        const fullProductData = fullCategoryData.products.find(p => p.productServiceId == key)
        if (fullProductData) {
          productsArray = [
            ...productsArray,
            {
              ...product,
              productId: key,
              name: fullProductData.productServiceName,
              type: fullProductData.productServiceType
            }
          ]
        }
      })
    }
    arrayOfObjects = [
      ...arrayOfObjects,
      {
        ...category,
        categoryId: key,
        products: productsArray
      }
    ]
  })

  return arrayOfObjects
}
