const URL_PARAM_TO_LANGUAGE_MAPPING = {
  en: 'en_US',
  nl: 'nl_NL',
  de: 'de',
  es: 'es',
  pt: 'pt_BR',
  pl: 'pl'
}

const DEFAULT_LANGUAGE = URL_PARAM_TO_LANGUAGE_MAPPING.en

const getIDPRegistrationLanguageFromURL = () => {
  const langParam = window.location.pathname.split('/')[1]
  const lang = URL_PARAM_TO_LANGUAGE_MAPPING[langParam] || DEFAULT_LANGUAGE

  return lang
}

export default getIDPRegistrationLanguageFromURL
