import React from 'react'
import PropTypes from 'prop-types'
import { SelectionWrap, SelectionState, SelectionButton, PopupBox, Closer, Action } from './Selector.styled'
import { KEYBOARD_KEYCODE, KEYBOARD_NAV_ACTIVATION, isKeyDownNext, isKeyDownPrev, SELECTION } from './constants'
import { Icon } from '@bp-digital/component-icon'

function renderSelectionItem(selection, index) {
  return (
    <Action
      type='button'
      key={selection.key || selection.text}
      onClick={() => this.close(selection.action)}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={index === this.focusedItem}
      onKeyDown={this.onKeyDown}
      ref={elem => {
        this.listItemRefs[index] = elem
        return true
      }}
    >
      <span>{selection.text}</span>
      <span>{selection.count}</span>
    </Action>
  )
}

function renderSelectionPopup(selectionOptions, close, focusedItem, onKeyDown, listItemRefs) {
  return (
    <React.Fragment>
      <PopupBox>{selectionOptions.map(renderSelectionItem, { close, focusedItem, onKeyDown, listItemRefs })}</PopupBox>
      <Closer onClick={close} />
    </React.Fragment>
  )
}

class MultiSelect extends React.Component {
  state = {
    open: false,
    focusedItem: 0
  }

  onKeyDownItem = e => {
    let nextFocused
    e.preventDefault()

    if (isKeyDownNext(e)) {
      nextFocused = this.state.focusedItem + 1
    } else if (isKeyDownPrev(e)) {
      nextFocused = this.state.focusedItem - 1
    } else if (e.keyCode === KEYBOARD_KEYCODE.ENTER) {
      this.close(this.props.selectionOptions[this.state.focusedItem].action)
    } else if (e.keyCode === KEYBOARD_KEYCODE.ESCAPE) {
      this.close()
    }

    if (nextFocused === undefined) return
    if (nextFocused < 0) return
    if (nextFocused >= this.props.selectionOptions.length) return

    this.setState({ focusedItem: nextFocused }, () => {
      this.listItemRefs[nextFocused].focus()
    })
  }

  listItemRefs = []

  open = () => {
    this.setState({ open: true })
  }

  close = next => {
    this.setState({ open: false, focusedItem: 0 })
    if (typeof next === 'function') {
      next()
    }
  }

  render() {
    const { isSelectAllVisible, selectionState = SELECTION.NONE, selectionOptions, disableTabIndex } = this.props
    return (
      <SelectionWrap>
        <SelectionButton
          onClick={this.open}
          onKeyDown={e => {
            if (KEYBOARD_NAV_ACTIVATION.includes(e.keyCode)) {
              this.open(true)
              e.preventDefault()
            }
          }}
          tabIndex={disableTabIndex ? '-1' : '0'}
        >
          <SelectionState state={selectionState} />
          <Icon className={`${this.state.open ? 'open' : 'closed'}`} name='DownSmall' size='sm' />
        </SelectionButton>
        {this.state.open &&
          !isSelectAllVisible &&
          renderSelectionPopup(
            selectionOptions,
            this.close,
            this.state.focusedItem,
            this.onKeyDownItem,
            this.listItemRefs
          )}
      </SelectionWrap>
    )
  }
}

MultiSelect.propTypes = {
  disableTabIndex: PropTypes.bool,
  isSelectAllVisible: PropTypes.bool,
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string.isRequired,
      count: PropTypes.number,
      action: PropTypes.func.isRequired
    })
  ),
  selectionState: PropTypes.oneOf(['ALL', 'SOME', 'NONE']).isRequired
}

export default MultiSelect
