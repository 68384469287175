import { Map } from '@bp-digital/component-map'
import { ReactElement, useState } from 'react'
import { Transaction } from './TransactionMap.types'
import TransMapMarker from './components/TransMapMarker'
import TransMapOverlay from './components/TransMapOverlay'
import { useRootStore } from 'contexts/StoreContext'

export interface TransMapOverlayProps {
  cardNo: string
  cardholderName: string
  recentTransactions?: Transaction[]
  alertTransactions?: Transaction[]
}

const TransactionMap = ({
  cardNo,
  cardholderName,
  recentTransactions = [],
  alertTransactions = []
}: TransMapOverlayProps): ReactElement => {
  const { contentStore } = useRootStore()
  const content = contentStore.getPage('cards-view')

  const [disableGestures, setDisableGestures] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>(
    recentTransactions[0] ?? alertTransactions[0]
  )

  const hasTransactions = recentTransactions.length > 0 || alertTransactions.length > 0
  if (!hasTransactions) return <>{content?.transactions_empty}</>

  if (!selectedTransaction) return <></>

  const handleSelectTransaction = (trans: Transaction) => {
    setSelectedTransaction(trans)
  }

  const renderOverlay = () => (
    <TransMapOverlay
      cardholderName={cardholderName}
      cardNo={cardNo}
      selectedTransaction={selectedTransaction}
      recentTransactions={recentTransactions}
      alertTransactions={alertTransactions}
      onSelectTransaction={handleSelectTransaction}
    />
  )

  return (
    <>
      <Map<Transaction>
        height={'100%'}
        zoom={10}
        location={{
          lat: +selectedTransaction.mapDetails.lat,
          lng: +selectedTransaction.mapDetails.log
        }}
        disableGestures={disableGestures}
        renderOverlay={renderOverlay}
        cluster={false}
        markers={[
          {
            id: selectedTransaction.transactionId,
            lat: +selectedTransaction.mapDetails.lat,
            lng: +selectedTransaction.mapDetails.log,
            renderMarker: () => (
              <TransMapMarker
                onFlyoutMouseEnter={() => setDisableGestures(true)}
                onFlyoutMouseLeave={() => setDisableGestures(false)}
                transaction={selectedTransaction}
              />
            ),
            data: selectedTransaction
          }
        ]}
      />
    </>
  )
}

export default TransactionMap
