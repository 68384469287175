import { useContext, useState, useEffect } from 'react'
import { DropDown } from '@bp-digital/component-drop-down'
import { FilterContext, InvoiceFilterType } from 'pages/Transactions/ArchivedInvoicesPage/ArchivedInvoicesPage'
import { getFilterText } from 'pages/Transactions/ArchivedInvoicesPage/helpers/getAllAppliedFilterBadges'
import { InvoiceFilterProps } from '../InvoiceArchiveFilters'

export const FILTER_NAME = 'invoiceType'
const DEFAULT_ID = 'DEFAULT'

const InvoiceTypeFilter = ({ removedFilterIds, setRemoveFilterId, content }: InvoiceFilterProps) => {
  const { filterData, setFilterData } = useContext(FilterContext)

  const selectedInvoiceTypeFilter = filterData?.filter(filter => filter.type === FILTER_NAME)[0]
  const { invoiceType = DEFAULT_ID } = selectedInvoiceTypeFilter?.payload?.keyGroup?.[0]?.values?.[0] ?? {}

  const [selectedFilterId, setSelectedFilterId] = useState<string>(invoiceType as string)

  useEffect(() => {
    if (removedFilterIds?.includes(FILTER_NAME)) {
      setRemoveFilterId(ids => ids.filter(id => id !== FILTER_NAME))
      setSelectedFilterId(DEFAULT_ID)
    }
  }, [removedFilterIds, setRemoveFilterId])

  return (
    <DropDown
      id='invoice-type'
      selectedId={selectedFilterId}
      onChange={id => {
        if (id === DEFAULT_ID) return

        const payload = {
          name: FILTER_NAME,
          keyGroup: [
            {
              keys: [`${FILTER_NAME}`],
              values: [
                {
                  invoiceType: `${id}`
                }
              ]
            }
          ]
        }

        setFilterData((filters: InvoiceFilterType[]) => [
          ...filters,
          {
            type: FILTER_NAME,
            text: getFilterText(payload, content),
            payload
          }
        ])
        setSelectedFilterId(`${id}`)
      }}
      options={[
        {
          id: DEFAULT_ID,
          label: 'Please select an option'
        },
        {
          id: 'SUMMARY-STATEMENT',
          label: content?.archiveFilter_invoiceType_summary_statement ?? 'archiveFilter_invoiceType_summary_statement'
        },
        {
          id: 'NATIONAL-INVOICE',
          label: content?.archiveFilter_invoiceType_national_invoice ?? 'archiveFilter_invoiceType_national_invoice'
        },
        {
          id: 'INTERNATIONAL-INVOICE',
          label:
            content?.archiveFilter_invoiceType_international_invoice ??
            'archiveFilter_invoiceType_international_invoice'
        }
      ]}
      disabled={selectedFilterId !== DEFAULT_ID}
    />
  )
}

export default InvoiceTypeFilter
