import ReactDOM from 'react-dom'
import { DatePicker } from '@bp-digital/component-date-picker'
import { Modal } from '@bp-digital/component-modal'
import { DatePickerWrapper } from './DateRangeModal.styled'
import { getLanguageFromUrl } from 'helpers/getFallbackLanguage'
import { format } from 'light-date'
import DATE_FILTERS from 'constants/dateFilters'

const root = document.getElementById('root')

const DateRangeModal = ({
  setFilter,
  getTransactions,
  onClose,
  selectedHierarchy,
  setDateRange,
  dateRange,
  content
}) => {
  const clearAndClose = () => {
    setDateRange(null)
    setFilter(DATE_FILTERS.THREE_MONTHS)
    getTransactions({ selectedHierarchy, forceRefresh: true })
    onClose()
  }

  return ReactDOM.createPortal(
    <Modal
      visible
      hasCloseButton
      onDismiss={clearAndClose}
      size='sm'
      title={content?.date_filter_modal_title || 'date_filter_modal_title'}
      primaryAction={{
        text: content?.advancedFilters_apply || 'advancedFilters_apply',
        disabled: !dateRange,
        onClick: () => {
          setFilter(DATE_FILTERS.CUSTOM_DATE)
          getTransactions({
            selectedHierarchy,
            dateRange,
            forceRefresh: true
          })
          onClose()
        }
      }}
      secondaryAction={{
        text: content?.advancedFilters_clear || 'advancedFilters_clear',
        appearance: 'tertiary',
        onClick: clearAndClose
      }}
    >
      <DatePickerWrapper>
        <DatePicker
          hideBoxShadow
          locale={getLanguageFromUrl()}
          options={{
            dateFormat: 'd/m/Y',
            defaultDate: dateRange?.dateArray || null,
            maxDate: new Date(),
            onChange: (selectedDates, dateString) => {
              if (selectedDates?.length === 2) {
                setDateRange({
                  dateString: dateString,
                  dateArray: [
                    format(selectedDates[0], '{dd}/{MM}/{yyyy}'),
                    format(selectedDates[1], '{dd}/{MM}/{yyyy}')
                  ]
                })
              }
            }
          }}
        />
      </DatePickerWrapper>
    </Modal>,
    root
  )
}

export default DateRangeModal
