import styled, { css } from 'styled-components'
import { CssVars } from '@bp-digital/theme'
import FlexibleLink from '../../../navigation/FlexibleLink'

const userMenuLinkStyles = css`
  display: flex;
  align-items: center;
  column-gap: ${CssVars.structure.spacing.sm};
  padding: 16px;

  :focus-visible {
    outline: 2px solid ${CssVars.palette.controls.focusSelected};
  }
`

const UserMenuLink = styled(FlexibleLink)`
  ${userMenuLinkStyles}
  text-decoration: none;
  color: ${CssVars.palette.neutral.default};
  cursor: pointer;

  :hover {
    background-color: ${CssVars.palette.neutral.lightest};
  }
`

const UserMenuButton = styled.button`
  /* reset button styles */
  border: none;
  background-color: transparent;

  ${userMenuLinkStyles}
  text-decoration: none;
  color: ${CssVars.palette.neutral.default};
  cursor: pointer;

  :hover {
    background-color: ${CssVars.palette.neutral.lightest};
  }
`

const UserMenuDisabledLink = styled.div`
  ${userMenuLinkStyles}
  color: ${CssVars.palette.neutral.light};
  cursor: not-allowed;
`

const UserMenuItemIconContainer = styled.span`
  display: flex;
  align-items: center;
`

export { UserMenuDisabledLink, UserMenuLink, UserMenuButton, UserMenuItemIconContainer }
