import { Button } from '@bp-digital/component-button'
import { Icon } from '@bp-digital/component-icon'
import BrandIcon from 'components/dataDisplay/BrandIcon'
import { useRootStore } from 'contexts/StoreContext'
import { ReactElement } from 'react'
import MapFlyout from 'src/components/dataDisplay/Map/MapFlyout'
import { Transaction } from '../../TransactionMap.types'
import { getTransactionOptions } from '../../TransactionMap.utils'
import { TransMapAddress } from './components/TransMapAddress'
import {
  FlyoutContainer,
  FlyoutContent,
  FlyoutLine,
  FlyoutSublines,
  IconWrap,
  LineSubtext,
  LineText
} from './TransMapFlyout.styled'

export interface TransMapFlyoutProps {
  transaction?: Transaction
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const TransMapFlyout = ({ transaction, onMouseEnter, onMouseLeave }: TransMapFlyoutProps): ReactElement => {
  const { contentStore } = useRootStore()

  if (!transaction) return <></>

  const content = contentStore.getPage('cards-view')
  const styles = getTransactionOptions(transaction)

  const handleMouseEnter = () => {
    onMouseEnter && onMouseEnter()
  }

  const handleMouseLeave = () => {
    onMouseLeave && onMouseLeave()
  }

  return (
    <FlyoutContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MapFlyout color={styles.color}>
        <FlyoutContent>
          <FlyoutLine>
            <IconWrap>
              <BrandIcon brand={transaction.mapDetails.brand} />
            </IconWrap>
            <LineText>
              {transaction.mapDetails.siteName}
              <LineSubtext>
                <TransMapAddress transaction={transaction} />
              </LineSubtext>
            </LineText>
          </FlyoutLine>
          <FlyoutLine>
            <Icon name={styles.iconName} state={styles.iconState} appearance='dark' size='lg' />
            <LineText>
              {transaction.transactionStatus}
              <LineSubtext>{transaction.transactionDateTime}</LineSubtext>
            </LineText>
          </FlyoutLine>
          {styles.alertType === 'cleared' && (
            <FlyoutLine>
              <Icon name='Breadsticks' size='lg' />
              <FlyoutSublines>
                {transaction.transactedProducts.map((item, index) => (
                  <div key={`${index}_transproducts`}>
                    {item.quantity}&nbsp;{item.unitofMeasure}
                    <LineSubtext>{item.productCodeDescription}</LineSubtext>
                  </div>
                ))}
              </FlyoutSublines>
            </FlyoutLine>
          )}
        </FlyoutContent>
        <Button
          dataAttributes={{ 'data-content-key': 'card_summary_view_transaction_details' }}
          text={content?.card_summary_view_transaction_details}
          iconPosition='end'
          iconName='RightSmall'
          appearance='secondary'
        />
      </MapFlyout>
    </FlyoutContainer>
  )
}

export default TransMapFlyout
